import { LocalTaskLoaderType } from '../constants/common';
import { ROUTES } from '../constants/routes';

import * as webMenuItems from '../../web/core/constants/menu-items';
import * as wmMenuItems from '../../webapp/core/constants/menu-items';

const MENU_ITEMS_BY_APP = {
  tools: webMenuItems,
  webmail: wmMenuItems
};

const menuItems = MENU_ITEMS_BY_APP[CONFIG.APP];

export const pathnameMatchingtoolId = (toolId: string, pathname: string): boolean =>
  ROUTES[toolId] === pathname || '/' + toolId === pathname;

export const findGroupItemBytoolId = (group, toolId: string) => group.items.find((item) => item.toolId === toolId);

export const pathnameMatchingSubState = (substates: string[] = [], pathname): boolean =>
  substates.filter((toolId: string) => pathnameMatchingtoolId(toolId, pathname)).length > 0;

export const getApiEndpointsForCurrentRoute = (pathname: string) => {
  let res = [];

  menuItems.groups.forEach((group: any) => {
    group.items.forEach((item) => {
      if (!item.pageEndpoint || !item.apiEndpoints) {
        return;
      }

      const isRelatedSubstate = pathnameMatchingSubState(item.subStates, pathname);
      const isRelatedState = pathnameMatchingtoolId(item.toolId, pathname);

      if (isRelatedState || isRelatedSubstate) {
        res = [...item.apiEndpoints, item.pageEndpoint];
      }
    });
  });

  return res;
};

export const getLocalTaskLoaderEndpointsForCurrentRoute = (
  pathname: string,
  localTaskLoaderType?: LocalTaskLoaderType
) => {
  const pageGroup: any = menuItems.groups.find((group: any) =>
    group.items.find(
      ({ toolId, subStates = [] }) =>
        pathnameMatchingtoolId(toolId, pathname) || pathnameMatchingSubState(subStates, pathname)
    )
  );

  if (pageGroup && pageGroup.items) {
    const pageData = pageGroup.items.find(
      ({ toolId, subStates = [] }) =>
        pathnameMatchingtoolId(toolId, pathname) || pathnameMatchingSubState(subStates, pathname)
    );

    if (localTaskLoaderType && pageData.localPageTaskLoaders) {
      return {
        [localTaskLoaderType]: pageData.localPageTaskLoaders[localTaskLoaderType] || []
      };
    }

    return pageData.localPageTaskLoaders || {};
  }

  return {};
};

export const getToolMainEndpoint = (toolId: string) => {
  const itemGroup = menuItems.groups.find((group: any) => findGroupItemBytoolId(group, toolId));
  const item = itemGroup && findGroupItemBytoolId(itemGroup, toolId);

  if (item && item.pageEndpoint) {
    return item.pageEndpoint;
  }

  return null;
};
