import * as React from 'react';
import { connect } from 'react-redux';
import { dispatchRemoteFormSubmit } from './remote-submit-util';
import { isPristine, isValid } from 'redux-form';

export type ChildrenProps = {
  valid: boolean;
  pristine: boolean;
  spanelFormSubmit: Function;
};

type Props = {
  formName: string;
  children: ({ valid, pristine, spanelFormSubmit }: ChildrenProps) => any;

  // flags, connected to redux-form selectors
  valid: boolean;
  pristine: boolean;
  // connected util action
  submit: Function;
};

export const RemoteSubmit = ({ formName, children, valid, pristine, submit }: Props) =>
  children({
    valid,
    pristine,
    spanelFormSubmit: submit(formName)
  });

export default connect(
  (store, { formName }: Props) => ({
    valid: isValid(formName)(store),
    pristine: isPristine(formName)(store)
  }),
  (dispatch) =>
    ({
      submit: dispatchRemoteFormSubmit(dispatch)
    } as Partial<Props>)
)(RemoteSubmit) as any;
