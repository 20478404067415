import { ToolId } from '../constants/route-info';

/* tslint:disable */
const TOOL_KEYWORDS = {
  [ToolId.backup]: {
    en: ['backup', 'back up', 'restore', 'revert', 'download'],
    it: ['backup', 'back up', 'ripristina', 'inverti', 'scarica'],
    es: ['copia de seguridad', 'copia de seguridad', 'restaurar', 'revertir', 'descargar'],
    de: ['Backup', 'sichern', 'wiederherstellen', 'rückgängig machen', 'herunterladen']
  },
  [ToolId.cloudflare]: {
    en: ['cdn', 'cache', 'cloudflare', 'caching', 'bandwidth', 'speed', 'fast', 'waf'],
    it: ['cdn', 'cache', 'cloudflare', 'caching', 'larghezza di banda', 'velocità', 'veloce', 'firewall'],
    es: ['cdn', 'caché', 'cloudflare', 'caching', 'ancho de banda', 'velocidad', 'veloz', 'firewall'],
    de: ['CDN', 'Cache', 'Cloudflare', 'Caching', 'Bandbreite', 'Geschwindigkeit', 'schnell', 'WAF']
  },
  [ToolId.cdn]: {
    en: ['cdn', 'cache', 'cloudflare', 'caching', 'bandwidth', 'speed', 'fast', 'waf'],
    it: ['cdn', 'cache', 'cloudflare', 'caching', 'larghezza di banda', 'velocità', 'veloce', 'firewall'],
    es: ['cdn', 'caché', 'cloudflare', 'caching', 'ancho de banda', 'velocidad', 'veloz', 'firewall'],
    de: ['CDN', 'Cache', 'Cloudflare', 'Caching', 'Bandbreite', 'Geschwindigkeit', 'schnell', 'WAF']
  },
  [ToolId.email]: {
    en: ['email', 'mail', 'mail box', 'mailbox', 'mail address', 'address', 'inbox', 'emails', 'mails', 'box'],
    it: [
      'email',
      'posta',
      'caselle di posta',
      'account mail',
      'indirizzo mail',
      'indirizzo',
      'casella',
      'email',
      'messaggi',
      'box'
    ],
    es: [
      'cuentas',
      'e-mail',
      'correo',
      'buzón de correo',
      'buzón de correo',
      'dirección de correo',
      'dirección',
      'buzón de entrada',
      'e-mails',
      'correos',
      'buzón'
    ],
    de: [
      'E-Mail',
      'Mail',
      'E-Mail Postfach',
      'Mailbox',
      'E-Mail Adresse',
      'Adress',
      'Posteingang',
      'E-Mails',
      'Mails',
      'Postfach'
    ]
  },
  [ToolId.emailAuth]: {
    en: ['Authenticate', 'Spoof', 'Spoofing', 'SPF', 'Dkim', 'Spam'],
    it: ['Autenticazione', 'spoof', 'spoofing', 'SPF', 'DKIM', 'Spam'],
    es: ['Autenticación', 'Spoof', 'Spoofing', 'SPF', 'Dkim', 'Spam'],
    de: ['Authentifizieren', 'Spoof', 'Spoofing', 'SPF', 'DKIM', 'Spam']
  },
  [ToolId.googleWorkspace]: {
    en: ['Gmail', 'Google', 'Workspace', 'Email'],
    it: ['Gmail', 'Google', 'Workspace', 'Email'],
    es: ['Gmail', 'Google', 'Workspace', 'Email', 'E-mail'],
    de: ['Gmail', 'Google', 'Workspace', 'Email', 'E-mail']
  },
  [ToolId.emailAutoresponder]: {
    en: [
      'Filter',
      'Filters',
      'Conditions',
      'Rules',
      'Automate',
      'auto',
      'Email rules',
      'Email filter',
      'Filtering',
      'Discard',
      'Forward'
    ],
    it: [
      'risposta',
      'automatica',
      'filtro',
      'filtri',
      'condizioni',
      'regole',
      'automatizzare',
      'auto',
      'regole email',
      'filtri email',
      'filtraggio',
      'scartare',
      'inoltrare'
    ],
    es: [
      'respuesta',
      'automática',
      'Filtro',
      'Filtros',
      'Condiciones',
      'Reglas',
      'Automatizar',
      'auto',
      'Reglas de e-mail',
      'Filtro de e-mail',
      'Filtrado',
      'Descartar',
      'Reenviar'
    ],
    de: [
      'Filter',
      'Filters',
      'Bedingungen',
      'Regeln',
      'Automatisieren',
      'automatisch',
      'E-Mail Regeln',
      'E-Mail Filter',
      'Filterung',
      'Verwerfen',
      'Weiterleiten'
    ]
  },
  [ToolId.emailFilters]: {
    en: [
      'Filter',
      'Filters',
      'Conditions',
      'Rules',
      'Automate',
      'auto',
      'Email rules',
      'Email filter',
      'Filtering',
      'Discard',
      'Forward'
    ],
    it: [
      'filtro',
      'filtri',
      'condizioni',
      'regole',
      'automatizzare',
      'auto',
      'regole email',
      'filtri email',
      'filtraggio',
      'scartare',
      'inoltrare'
    ],
    es: [
      'Filtros',
      'Condiciones',
      'Reglas',
      'Automatizar',
      'auto',
      'Reglas de e-mail',
      'Filtro de e-mail',
      'Filtrado',
      'Descartar',
      'Reenviar'
    ],
    de: [
      'Filter',
      'Filters',
      'Bedingungen',
      'Regeln',
      'Automatisieren',
      'automatisch',
      'E-Mail Regeln',
      'E-Mail Filter',
      'Filterung',
      'Verwerfen',
      'Weiterleiten'
    ]
  },
  [ToolId.emailForward]: {
    en: ['Forward', 'Discard', 'Forwarder', 'Discarder', 'Filter', 'Rule'],
    it: ['inoltrare', 'scartare', 'inoltro', 'inoltri', 'scarto', 'filtro', 'regola'],
    es: ['Reenvíos', 'Reenviar', 'Reenviador', 'Eliminar', 'Descartar', 'Filtro', 'Regla'],
    de: ['Weiterleiten', 'Verwerfen', 'Weiterleitung', 'Verwerfer', 'Filter', 'Regel']
  },
  [ToolId.spamExperts]: {
    en: ['Spam', 'Antispam', 'Anti-spam', 'Spam prevention', 'Prevention', 'Virus'],
    it: ['Spam', 'Antispam', 'Anti-spam', 'Prevenire spam', 'Prevenzione', 'Virus'],
    es: ['Spam', 'Antispam', 'Anti-spam', 'Prevención de spam', 'Prevención', 'Virus'],
    de: ['Spam', 'Antispam', 'Anti-Spam', 'Spam Prävention', 'Prävention', 'Virus']
  },
  [ToolId.ssh]: {
    en: ['keys', 'secure', 'shell', 'ssh', 'ip access', 'private key', 'public key', 'console', 'terminal', 'ssh key'],
    it: [
      'chiavi',
      'sicuro',
      'shell',
      'ssh',
      'accesso Ip',
      'chiave privata',
      'chiave pubblica',
      'console',
      'terminale',
      'chiave ssh'
    ],
    es: [
      'claves',
      'seguro',
      'shell',
      'ssh',
      'acceso ip',
      'clave privada',
      'clave pública',
      'consola',
      'terminal',
      'clave ssh'
    ],
    de: [
      'Schlüsseln',
      'sicher',
      'Shell',
      'SSH',
      'IP-Zugriff',
      'privater Schlüssel',
      'öffentlicher Schlüssel',
      'Konsole',
      'Terminal',
      'SSH-Schlüssel'
    ]
  },
  [ToolId.cron]: {
    en: ['job', 'schedule', 'automated', 'command', 'time'],
    it: ['processo', 'programma', 'automatizzato', 'comando', 'tempo'],
    es: ['trabajos', 'proceso', 'programar', 'automarizado', 'comando', 'tiempo'],
    de: ['Job', 'Plan', 'automatisiert', 'Befehl', 'Zeit']
  },
  [ToolId.phpSettings]: {
    en: ['php', 'php versions', 'php variables', 'variables', 'php 7'],
    it: ['php', 'versioni php', 'variabili php', 'variabili', 'php 7'],
    es: ['php', 'versiones php', 'variables php', 'variables', 'php 7'],
    de: ['PHP', 'PHP Versionen', 'PHP Variablen', 'Variablen', 'PHP 7']
  },
  [ToolId.blockIPs]: {
    en: ['block', 'prevent', 'ip'],
    it: ['bloccati', 'impedisci', 'ip'],
    es: ['bloqueadas', 'bloquear', 'prevenir', 'ip'],
    de: ['blockieren', 'hindern', 'IP']
  },
  [ToolId.redirect]: {
    en: ['redirects', '301', '302', 'forward', 'domain redirect', 'redirecting'],
    it: ['Reindirizzamenti', '301', '302', 'inoltra', 'reindirizza dominio', 'reindirizzamento'],
    es: ['Redirecciones', 'Redireccionamientos', '301', '302', 'reenviar', 'redirigir dominio', 'redirigiendo'],
    de: ['Umleitungen', '301', '302', 'weiterleiten', 'Domain-Umleitung', 'umleiten']
  },
  [ToolId.cacher]: {
    en: ['cache', 'caching', 'memcached', 'fast', 'optimize', 'optimise', 'speed', 'speed up', 'cach'],
    it: ['cache', 'caching', 'memcached', 'veloce', 'ottimizzare', 'ottimizzare', 'velocità', 'velocizzare', 'cach'],
    es: [
      'Almacenamiento',
      'caché',
      'caching',
      'memcached',
      'veloz',
      'optimizar',
      'optimizar',
      'velocidad',
      'acelerar',
      'cachear'
    ],
    de: [
      'Cache',
      'Caching',
      'Memcached',
      'schnell',
      'optimisieren',
      'optimisieren',
      'Geschwindigkeit',
      'beschleunigen',
      'cach'
    ]
  },
  [ToolId.dns]: {
    en: ['dns', 'ns', 'domain', 'records', 'a record'],
    it: ['Editor', 'Puntamento', 'dns', 'ns', 'dominio', 'record', 'a record'],
    es: ['Editor', 'Apuntar', 'dns', 'ns', 'dominio', 'registros', 'un registro'],
    de: ['DNS', 'NS', 'Domain', 'Einträge', 'ein Eintrag']
  },
  [ToolId.mysql]: {
    en: ['Mysql', 'Sql', 'Db', 'Database', 'Dbs', 'phpmyadmin'],
    it: ['Mysql', 'Sql', 'DB', 'Database', 'Dbs', 'phpmyadmin'],
    es: ['Mysql', 'Sql', 'DB', 'Base de datos', 'Dbs', 'phpmyadmin'],
    de: ['Mysql', 'SQL', 'DB', 'Datenbank', 'DBs', 'PHPMyAdmin']
  },
  [ToolId.pgsql]: {
    en: ['Postgre', 'PostgreSQL', 'Sql', 'Db', 'Database', 'Dbs'],
    it: ['Postgre', 'PostgreSQL', 'Sql', 'Db', 'Database', 'Dbs'],
    es: ['Postgre', 'PostgreSQL', 'Sql', 'Db', 'Base de datos', 'Dbs'],
    de: ['Postgre', 'PostgreSQL', 'SQL', 'DB', 'Datenbank', 'DBs']
  },
  [ToolId.protected]: {
    en: ['protected', 'url', 'location', 'addr', 'directory', 'directories'],
    it: ['protetti', 'url', 'posizione', 'indirizzo', 'cartella', 'cartelle'],
    es: ['protegidas', 'protegido', 'url', 'ubicación', 'dirección', 'directorio', 'directorios'],
    de: ['geschützt', 'URL', 'Standort', 'Adr.', 'Verzeichnis', 'Verzeichnisse']
  },
  [ToolId.subdomain]: {
    en: ['subdomain', 'sub', 'domain', 'alias'],
    it: ['Sottodominio'],
    es: ['Subdominios'],
    de: ['Subdomain', 'Sub', 'Domain', 'Alias']
  },
  [ToolId.parkedDomains]: {
    en: ['park', 'parked', 'domain', 'alias', 'forward'],
    it: ['parcheggiare', 'parcheggiato', 'dominio', 'alias', 'inoltra'],
    es: ['aparcados', 'parquear', 'parqueado', 'dominios', 'alias', 'reenviar'],
    de: ['parken', 'geparkt', 'Domain', 'Alias', 'weiterleiten']
  },
  [ToolId.ftp]: {
    en: ['ftp', 'file', 'upload', 'download', 'backup', 'quota'],
    it: ['ftp', 'file', 'carica', 'scarica', 'backup', 'quota'],
    es: ['ftp', 'archivo', 'subir', 'descarga', 'copia de seguridad', 'cuota'],
    de: ['FTP', 'Datei', 'Upload', 'Download', 'Backup', 'Quote']
  },
  [ToolId.filemanager]: {
    en: ['file', 'upload', 'editor', 'copy', 'move', 'folder', 'permission', 'archive', 'code'],
    it: ['file', 'carica', 'editor', 'copia', 'sposta', 'cartella', 'permessi', 'archivia', 'codice'],
    es: ['archivos', 'subir', 'editor', 'copiar', 'mover', 'carpeta', 'permiso', 'archivo', 'código'],
    de: ['Datei', 'Upload', 'Editor', 'kopieren', 'bewegen', 'Ordner', 'Berechtigung', 'Archiv', 'Code']
  },
  [ToolId.wpAutoupdate]: {
    en: ['autoupdate', 'update', 'wordpress', 'wp update', 'word press', 'wp'],
    it: ['auto-aggiornamento', 'autoaggiornamento', 'aggiornamento', 'wordpress', 'word press', 'wp'],
    es: [
      'actualización automática',
      'actualizar',
      'actualizaciones',
      'wordpress',
      'actualización wp',
      'word press',
      'wp'
    ],
    de: ['Autoupdate', 'Update', 'WordPress', 'WP Update', 'Word Press', 'WP']
  },
  [ToolId.wpManage]: {
    en: ['wordpress', 'wp', 'woocommerce', 'woo', 'install', 'wp-admin', 'wp admin'],
    it: ['wordpress', 'wp', 'woocommerce', 'woo', 'installare', 'wp-admin', 'wp admin'],
    es: ['wordpress', 'administrar', 'wp', 'woocommerce', 'woo', 'instalar', 'wp-admin', 'wp admin'],
    de: ['WordPress', 'WP', 'WooCommerce', 'Woo', 'installieren', 'WP-Admin', 'WP Admin']
  },
  [ToolId.wpStaging]: {
    en: ['staging', 'development', 'stage', 'deploy', 'push', 'production', 'replicate', 'test', 'git', 'live'],
    it: ['staging', 'sviluppo', 'stage', 'lancia', 'pubblica', 'produzione', 'copia', 'test', 'git', 'live'],
    es: [
      'staging',
      'desarrollo',
      'stage',
      'desplegar',
      'publicar',
      'producción',
      'replicar',
      'pruebas',
      'git',
      'en producción'
    ],
    de: [
      'Staging',
      'Entwicklung',
      'Stage',
      'bereitstellen',
      'puschen',
      'Produktion',
      'replizieren',
      'testen',
      'git',
      'live'
    ]
  },
  [ToolId.wpTransfer]: {
    en: ['transfer', 'migrate', 'migrator', 'wordPress', 'wp', 'move'],
    it: ['trasferire', 'migrare', 'migrator', 'wordPress', 'wp', 'sposta'],
    es: ['transferir', 'migrar', 'migrator', 'wordPress', 'wp', 'mover'],
    de: ['Transfer', 'migrieren', 'Migrator', 'WordPress', 'WP', 'übertragen']
  },
  [ToolId.wpSearchAndReplace]: {
    en: ['replace', 'search & replace', 'search and replace', 'change string', 'wp search', 'wp replace', 'wordpress'],
    it: ['sostituisci', 'cerca e sostituisci', 'cambia stringa', 'ricerca wp', 'sostituisci wp', 'wordpress'],
    es: ['reemplazar', 'buscar y reemplazar', 'cambiar cadena', 'búsqueda wp', 'reemplazar wp', 'wordpress'],
    de: [
      'ersetzen',
      'suchen u. ersetzen',
      'suchen und ersetzen',
      'Zeichenfolge ändern',
      'WP Suche',
      'WP ersetzen',
      'WordPress'
    ]
  },
  [ToolId.ssl]: {
    en: ['SSL', 'HTTPS', 'certificate', 'encrypt', 'Let’s Encrypt', 'Lets encrypt', 'Let s encrypt'],
    it: ['SSL', 'HTTPS', 'certificato', 'encrizione', 'Let’s Encrypt', 'Lets encrypt', 'Let s encrypt'],
    es: ['SSL', 'HTTPS', 'certificado', 'cifrado', 'Let’s Encrypt', 'Lets encrypt', 'Let s encrypt'],
    de: ['SSL', 'HTTPS', 'Zertifikat', 'verschlüsseln', 'Let’s Encrypt', 'Lets encrypt', 'Let s encrypt']
  },
  [ToolId.statistics]: {
    en: [
      'statistics',
      'stats',
      'traffic',
      'bandwidth',
      'visits',
      'visitors',
      'hits',
      'Conditions',
      'Email rules',
      'Email filter',
      'Filtering',
      'Discard',
      'Forward'
    ],
    it: [
      'Statistiche',
      'Stats',
      'traffico',
      'larghezza di banda',
      'visite',
      'visitatori',
      'hits',
      'condizioni',
      'regole email',
      'filtri email',
      'filtraggio',
      'scarto',
      'inoltro'
    ],
    es: [
      'Estadísticas',
      'Estadísticas',
      'tráfico',
      'ancho de banda',
      'visitas',
      'visitantes',
      'impactos',
      'condiciones',
      'Reglas de e-mail',
      'Filtros de e-mail',
      'Filtrado',
      'Descartar',
      'Reenviar'
    ],
    de: [
      'Statistiken',
      'Statistiken',
      'Traffik',
      'Bandbreite',
      'Besuche',
      'Besucher',
      'Treffer',
      'Bedingungen',
      'E-Mail Regeln',
      'E-Mail Filter',
      'Filterung',
      'verwerfen',
      'weiterleiten'
    ]
  },
  [ToolId.httpsEnforce]: {
    en: ['https', 'enforce', 'domain', 'external', 'links', 'rewrite'],
    it: ['https', 'forzatura', 'dominio', 'esterno', 'link', 'riscrivi'],
    es: ['https', 'forzar', 'dominio', 'externo', 'enlace', 'reescribir'],
    de: ['https', 'erzwingen', 'Domain', 'extern', 'Links', 'umschreiben']
  },
  [ToolId.errorLog]: {
    en: ['error', 'error log', 'log', 'weblog', 'web log', 'server log'],
    it: ['errori', 'registro errori', 'registro errori', 'weblog', 'web log', 'registro server'],
    es: ['errores', 'registro de errores', 'registro', 'registro web', 'registro web', 'registro del servidor'],
    de: ['Fehler', 'Fehlerprotokoll', 'Protokoll', 'Weblog', 'Web Log', 'Server Log']
  },
  [ToolId.accessLog]: {
    en: ['access', 'access log', 'log', 'weblog', 'web log', 'server log'],
    it: ['accessi', 'log accessi ', 'log', 'weblog', 'web log', 'registro server'],
    es: ['acceso', 'registro de acceso', 'registro', 'registro web', 'registro web', 'registro del servidor'],
    de: ['Zugriff', 'Zugriff Log', 'Log', 'Weblog', 'Web Log', 'Server Log']
  },
  [ToolId.appManager]: {
    en: [
      'application',
      'app',
      'install',
      'site install',
      'site',
      'platform',
      'wordpress',
      'drupal',
      'magento',
      'wiki',
      'prestashop',
      'zencart',
      'opencart',
      'phpbb',
      'woocommerce',
      'smf',
      'moodle',
      'mediawiki',
      'vtiger',
      'osticket',
      'crm',
      'limesurvey',
      'survey',
      'phplist',
      'weebly',
      'joomla',
      'piwigo'
    ],
    it: [
      'applicazione',
      'app',
      'installa',
      'installa sito',
      'sito',
      'piattaforma',
      'wordpress',
      'drupal',
      'magento',
      'wiki',
      'prestashop',
      'zencart',
      'opencart',
      'phpbb',
      'woocommerce',
      'installazioni',
      'smf',
      'moodle',
      'mediawiki',
      'vtiger',
      'osticket',
      'crm',
      'limesurvey',
      'survey',
      'phplist',
      'weebly',
      'joomla',
      'plwigo'
    ],
    es: [
      'aplicación',
      'app',
      'instala',
      'instala sitio',
      'sitio',
      'plataforma',
      'wordpress',
      'drupal',
      'magento',
      'wiki',
      'prestashop',
      'zencart',
      'aplicaciones',
      'instalaciones',
      'opencart',
      'phpbb',
      'woocommerce',
      'smf',
      'moodle',
      'mediawiki',
      'vtiger',
      'osticket',
      'crm',
      'limesurvey',
      'survey',
      'phplist',
      'weebly',
      'joomla',
      'piwigo'
    ],
    de: [
      'Anwendung',
      'App',
      'installieren',
      'Site Installation',
      'Site',
      'Plattform',
      'wordpress',
      'drupal',
      'magento',
      'wiki',
      'prestashop',
      'zencart',
      'opencart',
      'phpbb',
      'woocommerce',
      'smf',
      'moodle',
      'mediawiki',
      'vtiger',
      'osticket',
      'crm',
      'limesurvey',
      'survey',
      'phplist',
      'weebly',
      'joomla',
      'piwigo'
    ]
  },
  [ToolId.git]: {
    en: ['Git', 'Stage', 'Repo', 'Staging', 'Branch', 'Branching', 'Production', 'Development', 'Develop'],
    it: ['Git', 'Stage', 'Repo', 'Staging', 'Branch', 'Branching', 'Produzione', 'Sviluppo', 'Sviluppare'],
    es: ['Git', 'Stage', 'Repo', 'Staging', 'Branch', 'Branching', 'Producción', 'Desarrollo', 'Desarrollar'],
    de: ['Git', 'Stage', 'Repo', 'Staging', 'Zweig', 'Branching', 'Produktion', 'Entwicklung', 'entwickeln']
  },
  [ToolId.sitescanner]: {
    en: [],
    it: [],
    es: [],
    de: []
  }
};
/* tslint:enable */

export const getToolKeywords = (toolId: ToolId) => {
  if (TOOL_KEYWORDS[toolId]) {
    const { en = [], it = [], es = [], de = [] } = TOOL_KEYWORDS[toolId];

    return [...en, ...it, ...es, ...de];
  }

  return [];
};
