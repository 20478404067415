import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Icon, Link, List, ListItem, Text } from '@siteground/styleguide';
import { openNewTab } from '@siteground/styleguide/lib/utils';
import { OpenNewTabPayload } from '../../../core/actions/open-new-tab';
import { FilescanReportEntry, MalwareReportEntry, NormalizedReport } from '../../../core/definitions/sg-sitescanner';

type Props = {
  report: NormalizedReport;
  icon: string;
  iconSize?: string;
  onMalwareDetails?: (malware: MalwareReportEntry[] | FilescanReportEntry[]) => any;
  openNewTab?: (payload: OpenNewTabPayload) => any;
  children?: any;
};

export const WarningsList = ({ report, icon, iconSize, onMalwareDetails, openNewTab }: Props) => {
  const reportTimeout = report.status === 'timeout';
  const intl = useIntl();

  return (
    <List type="warning" padding="none">
      {reportTimeout && (
        <ListItem icon={icon} iconSize={iconSize}>
          {intl.formatMessage({ id: 'translate.page.sg-sitescanner.report-timeout.label' })}
        </ListItem>
      )}
      {!reportTimeout && !!report?.malware?.length && (
        <ListItem icon={icon} iconSize={iconSize}>
          {intl.formatMessage({
            id: 'translate.page.sg-sitescanner.malware-found.label'
          })}{' '}
          <Link onClick={() => onMalwareDetails(report.malware)} weight="bold">
            {intl.formatMessage({
              id: 'translate.page.sg-sitescanner.view-details.label'
            })}{' '}
            <Icon name="external-link" size="16" />
          </Link>
        </ListItem>
      )}
      {!reportTimeout && !!report?.filescan?.length && (
        <ListItem icon={icon} iconSize={iconSize}>
          {intl.formatMessage({
            id: 'translate.page.sg-sitescanner.filescan-malware-found.label'
          })}{' '}
          <Link onClick={() => onMalwareDetails(report.filescan)} weight="bold">
            {intl.formatMessage({ id: 'translate.page.sg-sitescanner.view-details.label' })}{' '}
            <Icon name="external-link" size="16" />
          </Link>
        </ListItem>
      )}
      {!reportTimeout &&
        report &&
        report.blacklist &&
        report.blacklist.map(({ details, url }) => (
          <ListItem key={url} icon={icon} iconSize={iconSize}>
            {details}{' '}
            <Link weight="bold" onClick={() => openNewTab({ url })}>
              {intl.formatMessage({
                id: 'translate.page.sg-sitescanner.view-details.label'
              })}{' '}
              <Text color="light" tag="span">
                <Icon name="external-link" size="16" />
              </Text>
            </Link>
          </ListItem>
        ))}
    </List>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openNewTab: (payload) => dispatch(openNewTab(payload))
});

export default connect<{}, {}, Props>(undefined, mapDispatchToProps)(WarningsList);
