import { REQUEST_AUTOCONFIGURE_DATA, REQUEST_AUTO_LOGIN_IN_WEBAPP } from '../../constants/actions';
import customRequestTypes from '../../constants/custom-request-types';

export const requestAutoconfigureData = (payload, onComplete?) => ({
  type: REQUEST_AUTOCONFIGURE_DATA,
  payload,
  requestTypeName: customRequestTypes.EMAIL_AUTOCONFIGURE_DATA,
  onComplete
});

export const autoLoginInWebApp = (payload, onComplete?) => ({
  type: REQUEST_AUTO_LOGIN_IN_WEBAPP,
  payload,
  requestTypeName: customRequestTypes.GET_WEBMAIL_LOGIN_DATA,
  onComplete
});
