import * as Actions from '../constants/actions';
import customRequestTypes from '../constants/custom-request-types';

export const pageLoad = () => ({
  type: Actions.PAGE_LOAD,
  requestTypeName: customRequestTypes.PAGE_LOAD
});

export const retryPageLoad = () => ({
  type: Actions.RETRY_PAGE_LOAD,
  requestTypeName: customRequestTypes.RETRY_PAGE_LOAD
});

export const verifyUser = ({ username, onComplete, onFailure }): NemoRequestAction => ({
  type: Actions.VERIFY_USER,
  payload: { username },
  requestTypeName: customRequestTypes.VERIFY_USER,
  onComplete,
  onFailure
});

export const signIn = ({ username, password, remember_me, redirectUrl, onFailure }): NemoRequestAction => ({
  type: Actions.SIGN_IN,
  payload: {
    username,
    password,
    remember_me,
    redirectUrl
  },
  requestTypeName: customRequestTypes.SIGN_IN,
  onFailure
});

export const storeSessionData = (session) => ({
  type: Actions.STORE_SESSION_DATA,
  payload: {
    session
  }
});

export const storeSessionToken = (token) => ({
  type: Actions.STORE_SESSION_TOKEN,
  token
});

export const storeSessionProfile = (user) => ({
  type: Actions.STORE_SESSION_PROFILE,
  user
});

export const changeCurrentSite = (siteId: string) => ({
  type: Actions.CHANGE_CURRENT_SITE,
  payload: {
    siteId
  },
  requestTypeName: customRequestTypes.CHANGE_CURRENT_SITE
});

export const clearSessionData = () => ({ type: Actions.CLEAR_SESSION_DATA });

export const logoutUser = ({ sessionExpired = false } = {}) => ({
  type: Actions.LOGOUT,
  requestTypeName: customRequestTypes.LOGOUT,
  payload: {
    sessionExpired
  }
});

export const refreshClientToken = () => ({ type: Actions.REFRESH_CLIENT_TOKEN });

export const refreshClientTokenSucceeded = (clientTokenData) => ({
  type: Actions.REFRESH_CLIENT_TOKEN_SUCCEEDED,
  session: clientTokenData
});

export const refreshClientTokenFailed = () => ({ type: Actions.REFRESH_CLIENT_TOKEN_FAILED });

export const siteRequestAuthorizationFailed = (actionToRetry = null) => ({
  type: Actions.SITE_REQUEST_AUTHORIZATION_FAILED,
  payload: { actionToRetry }
});

export const refreshSiteToken = () => ({ type: Actions.REFRESH_SITE_TOKEN });

export const saveSiteToken = (siteId, siteToken) => ({
  type: Actions.SAVE_SITE_TOKEN,
  payload: {
    siteId,
    siteToken
  }
});

export const refreshSiteTokenFailed = (siteId) => ({
  type: Actions.REFRESH_SITE_TOKEN_FAILED,
  payload: {
    siteId
  }
});

export const saveRedirectData = (data = {}) => ({
  type: Actions.SAVE_REDIRECT_DATA,
  payload: {
    ...data
  }
});

export const updateUserLanguage = (language) => ({
  type: Actions.UPDATE_USER_LANGUAGE,
  payload: { language }
});

export const updateUserPreferences = (payload) => ({
  type: Actions.UPDATE_USER_PREFERENCES,
  payload
});

export const pageLoadFailed = () => ({
  type: Actions.PAGE_LOAD_FAILED
});
