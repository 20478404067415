import * as React from 'react';
import { Grid, Section } from '@siteground/styleguide';
import { requestDomainStats } from '../../../core/actions/pages/resource-stats';
import { API_RESOURCE } from '../../../core/constants/api';
import { RootState } from '../../reducers';
import { findMainDomain, userDaysAfterCreation, getDnsServers } from '../../../core/selectors';
import { getStats } from '../../../core/selectors/resource-stats';
import { getDateId } from '../../../core/utils/stats-format';
import indexWithCRUD from '../../components/indexWithCRUD';
import SiteInfoSection from './site-info-section';
import StatisticSection from './statistic-section';
import TileSection from './tile-section';
import WelcomeSection from './welcome-section';
import UkraineSection from './ukraine-section';

type Props = {
  intl: Intl;
  router: any;
  dnsServers: string[];
  domainName: string;
  locationSearch: string;
  session: {
    user: {
      first_name: string;
      preferences: {
        sitetools: {
          hide_domain_not_pointed_msg: string[];
        };
      };
    };
  };
  stats: any[];
  currentDateId: string;
  userDaysAfterCreation: number;
  mainDomain: {
    sg_pointed: number;
    id?: string;
    name: string;
  };
  requestDomainStats: (domainId: any, dateId: string) => any;
  canViewStatistics: boolean;
  siteMetaApi: any;
};

class Dashboard extends React.Component<Props, any> {
  fetchStatistics() {
    const { domainName, currentDateId, requestDomainStats } = this.props;
    requestDomainStats(domainName, currentDateId);
  }

  fetchAll() {
    this.fetchStatistics();
  }

  componentDidMount() {
    const { domainName } = this.props;

    if (domainName) {
      this.fetchAll();
    }
  }

  componentDidUpdate(prevProps) {
    const { domainName } = this.props;

    if (prevProps.domainName !== domainName) {
      this.fetchAll();
    }
  }

  canViewStatistics = () => {
    const { siteMetaApi } = this.props;

    return (
      siteMetaApi &&
      siteMetaApi.endpoints &&
      siteMetaApi.endpoints.webstats &&
      siteMetaApi.endpoints.webstats.methods &&
      Boolean(siteMetaApi.endpoints.webstats.methods.GET)
    );
  };

  getDomainsWithHiddenMsg = () => {
    const { session } = this.props;

    return (
      session &&
      session.user &&
      session.user.preferences &&
      session.user.preferences.sitetools &&
      session.user.preferences.sitetools.hide_domain_not_pointed_msg
    );
  };

  render() {
    const { router, session, domainName, stats, locationSearch, userDaysAfterCreation, dnsServers, mainDomain, intl } =
      this.props;

    const firstName = session && session.user && session.user.first_name;
    const messageHiddenForDomains = this.getDomainsWithHiddenMsg();
    return (
      <Section>
        <Grid>
          <WelcomeSection
            name={firstName}
            mainDomain={mainDomain}
            messageHiddenForDomains={messageHiddenForDomains}
            userDaysAfterCreation={userDaysAfterCreation}
          />

          <UkraineSection />

          <TileSection router={router} />

          <SiteInfoSection />

          {this.canViewStatistics() && (
            <StatisticSection domain={domainName} stats={stats} router={router} locationSearch={locationSearch} />
          )}
        </Grid>
      </Section>
    );
  }
}

const mapStateToProps = ({ environment, session, webstats, sites, routing, siteMetaApi, pageItems }: RootState) => {
  const domainName = sites && sites.currentDomainName;
  const currentDateId = getDateId(+new Date() / 1000);

  const stats = getStats({ webstats })(currentDateId, domainName, 'days');

  return {
    environment,
    session,
    stats,
    currentDateId,
    domainName,
    siteMetaApi,
    mainDomain: findMainDomain({ pageItems }, API_RESOURCE.DOMAIN_ALL.resourceName),
    dnsServers: getDnsServers({ siteMetaApi }),
    locationSearch: routing.locationBeforeTransitions.search,
    userDaysAfterCreation: userDaysAfterCreation({ session })
  };
};

export default indexWithCRUD(mapStateToProps, {
  requestDomainStats
})(Dashboard, API_RESOURCE.DOMAIN_ALL);
