import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown } from '@siteground/styleguide';
import { updateItem } from '../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../core/constants/api';
import { ZONE_SETTING_ID } from '../constants';
import SettingListItem, { SettingListItemProps } from './setting-list-item';

const { CACHE_LEVEL, SECURITY_LEVEL, SSL, POLISH } = ZONE_SETTING_ID;

const notification = {
  [CACHE_LEVEL]: {
    success: 'translate.page.cloudflare.cache.level.success.message',
    error: 'translate.page.cloudflare.cache.level.error.message'
  },
  [SECURITY_LEVEL]: {
    success: 'translate.page.cloudflare.security.level.success.message',
    error: 'translate.page.cloudflare.security.level.error.message'
  },
  [SSL]: {
    success: 'translate.page.cloudflare.ssl.success.message',
    error: 'translate.page.cloudflare.ssl.error.message'
  },
  [POLISH]: {
    success: 'translate.page.cloudflare.polish.success.message',
    error: 'translate.page.cloudflare.polish.error.message'
  }
};

const getDropdownOptions = (id, intl) => {
  let options = [];

  switch (id) {
    case CACHE_LEVEL:
      options = [
        {
          value: 'basic',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.basic' })
        },
        {
          value: 'simplified',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.simple' })
        },
        {
          value: 'aggressive',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.aggressive' })
        }
      ];
      break;
    case SECURITY_LEVEL:
      options = [
        {
          value: 'essentially_off',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.essentially.off' })
        },
        {
          value: 'low',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.low' })
        },
        {
          value: 'medium',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.medium' })
        },
        {
          value: 'high',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.high' })
        },
        {
          value: 'under_attack',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.under.attack' })
        }
      ];
      break;
    case SSL:
      options = [
        {
          value: 'off',
          label: intl.formatMessage({ id: 'translate.generic.off' })
        },
        {
          value: 'flexible',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.flexible' })
        },
        {
          value: 'full',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.full' })
        },
        {
          value: 'strict',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.strict' })
        }
      ];
      break;
    case POLISH:
      options = [
        {
          value: 'off',
          label: intl.formatMessage({ id: 'translate.generic.off' })
        },
        {
          value: 'lossless',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.lossless' })
        },
        {
          value: 'lossy',
          label: intl.formatMessage({ id: 'translate.page.cloudflare.dropdown.lossy' })
        }
      ];
      break;
    default:
      break;
  }

  return options;
};

const getDropdownMetaFields = (id) => {
  const metaFields: any = {
    ...API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS
  };

  const meta = {
    notification: {
      type: 'generic',
      success: {
        intlKey: null
      },
      error: {
        intlKey: null
      }
    }
  };

  if (id) {
    meta.notification.success.intlKey = notification[id].success;
    meta.notification.error.intlKey = notification[id].error;
  }

  return {
    _meta: meta,
    _metaFields: metaFields
  };
};

type SettingListDropdownProps = SettingListItemProps & { updateItem: Function };

const SettingListDropdown = (props: SettingListDropdownProps) => {
  const { intl, updateItem, setting } = props;

  return (
    <SettingListItem {...props}>
      <div style={{ minWidth: '150px' }}>
        <Dropdown
          options={getDropdownOptions(setting.id, intl)}
          optionValue="value"
          optionLabel="label"
          selectedValue={setting.value}
          size="small"
          data-e2e={setting.id}
          onChange={(value) =>
            updateItem({
              ...setting,
              value,
              ...getDropdownMetaFields(setting.id)
            })
          }
        />
      </div>
    </SettingListItem>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateItem: bindActionCreators(updateItem, dispatch)
});

const mapStateToProps = (state) => ({});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl<any, any>(SettingListDropdown)) as any;
