import * as React from 'react';
import { useIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

type Props = {
  children?: any;
  renderBeforeBoxChildren?: any;
  createState?: any;
};

const { resourceName } = API_RESOURCE.EMAIL_FORWARD;

const CreateContainer = ({ createState, children, renderBeforeBoxChildren }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceName} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.emailForward.create.title' })}
        renderBeforeBoxChildren={renderBeforeBoxChildren}
        resourceName={resourceName}
        formName={createState === 'forward' ? REDUX_FORM.CREATE_EMAIL_FORWARD : REDUX_FORM.CREATE_EMAIL_DISCARD}
        resources={[
          {
            resourceName,
            methods: ['POST']
          },
          {
            resourceName: API_RESOURCE.DOMAIN.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName,
            methods: ['GET']
          }
        ]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default CreateContainer;
