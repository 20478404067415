import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@siteground/styleguide';
import RemoteSubmit from '../sg-form-util/remote-submit';
import { ButtonType } from '@siteground/styleguide/lib/components/button/types';

interface Props {
  formName: string;
  label?: string;
  e2eAttr?: string;
  type?: ButtonType;
  children?: any;
}

const SubmitButton = ({ formName, label, e2eAttr = 'create-box-submit', type }: Props) => {
  const intl = useIntl();
  return (
    <RemoteSubmit
      formName={formName}
      children={({ valid, pristine, spanelFormSubmit }) => (
        <Button type={type} color="primary" action="button" data-e2e={e2eAttr} onClick={spanelFormSubmit}>
          {label || intl.formatMessage({ id: 'translate.generic.create' })}
        </Button>
      )}
    />
  );
};

export default SubmitButton;
