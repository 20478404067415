import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { downloadFile } from '../../../../core/api/download-file';

import { siteApi } from '../../../../core/api/site';
import * as BaseConstantsForActions from '../../../../core/constants/actions';
import { DIALOGS } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import handleAvalonApiRequest from '../../../../core/sagas/handle-avalon-api-request';
import { fetchDir } from '../actions/file-manager';
import * as FileManagerConstants from '../constants/actions';
import { getPrioritizedEntities } from '../selectors/get-prioritized-entities';
import { getEntityPath } from '../utils';
import { isApiDisabled } from '../../../../core/selectors';

const Actions = { ...BaseConstantsForActions, ...FileManagerConstants };

interface FetchArgs {
  payload: {
    urlParams: {
      filename: string;
      dir_sizes?: 1;
    };
    entity: any;
  };
}

function* fetchDirSaga(action: FetchArgs) {
  const state = yield select();
  const shouldFetchDirWithSize = yield select(isApiDisabled);

  let urlParams = action.payload.urlParams;

  if (shouldFetchDirWithSize) {
    urlParams = {
      ...urlParams,
      dir_sizes: 1
    };
  }

  const response = yield call(
    siteApi({
      endpoint: '/dir',
      method: 'GET',
      state,
      urlParams
    })
  );

  yield put({ type: Actions.FILE_MANAGER_FETCH_DIR_SUCCEEDED, items: response.data, urlParams });

  return response;
}

// TODO types
function* fetchDirsSaga(action) {
  const state = yield select();
  const { entries } = action.payload;

  const response = yield call(
    siteApi({
      endpoint: '/dir-list',
      method: 'POST',
      state,
      body: {
        entries
      }
    })
  );

  yield put({ type: Actions.FILE_MANAGER_FETCH_DIRS_SUCCEEDED, data: response.data });

  return response;
}

function* fetchFileSaga(action: FetchArgs) {
  const state = yield select();
  const { urlParams, entity } = action.payload;

  const response = yield call(
    siteApi({
      endpoint: '/file',
      method: 'GET',
      state,
      urlParams
    })
  );

  yield put({
    type: Actions.FILE_MANAGER_FETCH_FILE_SUCCEEDED,
    payload: {
      ...entity,
      _meta: {
        ...entity._meta,
        content: response,
        updatedContent: response,
        isActiveInCodeEditor: true
      }
    },
    urlParams
  });

  return response;
}

function* downloadSelectedFilesSaga(action: FetchArgs) {
  const state = yield select();
  const filesToDownload = getPrioritizedEntities(state.fileManager);

  for (const entity of filesToDownload) {
    const isNewTabOpened = downloadFile('/file', state, {
      filename: getEntityPath(entity)
    });

    if (!isNewTabOpened) {
      yield put(sgDialogActions.openSGDialog(DIALOGS.NEW_TAB_DIALOG));
    }
  }
}

function* fetchDirWithFolderSize() {
  const { fileManager } = yield select();
  const { contextNavigationEntity, selectedNavigationEntity } = fileManager;

  yield put(
    fetchDir(
      {
        urlParams: {
          id: getEntityPath(contextNavigationEntity ? contextNavigationEntity : selectedNavigationEntity),
          dir_sizes: 1
        },
        _meta: {
          notification: {
            type: 'generic',
            error: {
              intlKey: 'translate.file.manager.show.folder.details.error.message'
            }
          }
        }
      },
      customRequestTypes.FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZES
    )
  );
}

function* fetchDirSagas(): any {
  yield takeEvery(Actions.FILE_MANAGER_FETCH_DIR_REQUESTED, handleAvalonApiRequest(fetchDirSaga));
  yield takeEvery(Actions.FILE_MANAGER_FETCH_DIRS_REQUESTED, handleAvalonApiRequest(fetchDirsSaga));
  yield takeEvery(Actions.FILE_MANAGER_FETCH_FILE_REQUESTED, handleAvalonApiRequest(fetchFileSaga));
  yield takeEvery(Actions.FILE_MANAGER_DOWNLOAD_FILE, handleAvalonApiRequest(downloadSelectedFilesSaga));
  yield takeEvery(Actions.FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZE, fetchDirWithFolderSize);
}

export default fetchDirSagas;
