import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Column, Container, Grid, LoaderProps } from '@siteground/styleguide';
import { uniqueId } from '@siteground/styleguide/lib/utils/unique-id';
import { LocalTaskLoaderType } from '../../../core/constants/common';
import { findFormNotification } from '../../../core/selectors';
import { HideOnTablet } from '../device';
import { default as FormNotification, RenderCustomNotification } from '../form-notification';
import PartialLoader from '../partial-loader';
import { SecondLevelTitle } from '../titles';

import './create-box.scss';
import Tutorials, { TutorialsProps } from './tutorials';

type StateProps = {
  formNotification: SpanelNotification;
};

type OwnProps = {
  children?: any;
  hideTutorials?: boolean;
  formName?: string;
  title?: string;
  resourceName?: string;
  resources?: LoaderResource[];
  renderBeforeBoxChildren?: Function;
  renderCustomNotification?: (params: RenderCustomNotification) => any;
  renderTutorials?: TutorialsProps['renderTutorials'];
  loaderMessage?: string;
  hideContent?: boolean;
  loaderPosition?: LoaderProps['position'];
};

interface CreateBoxProps extends StateProps, OwnProps {}

const generateStyles = (pageNotification): any =>
  pageNotification
    ? {
        position: 'absolute',
        visibility: 'hidden'
      }
    : null;

class CreateBox extends React.Component<CreateBoxProps & WrappedComponentProps, null> {
  tutorialsId = null;

  constructor(props) {
    super(props);

    this.tutorialsId = uniqueId('sg-create-form-tutorials-');
  }

  renderFormNotification = () => {
    const { resourceName, formNotification, renderCustomNotification } = this.props;

    return (
      <FormNotification
        resourceName={resourceName}
        notification={formNotification}
        renderCustomNotification={renderCustomNotification}
      />
    );
  };

  renderChildren() {
    const { children, renderBeforeBoxChildren } = this.props;

    return (
      <React.Fragment>
        {renderBeforeBoxChildren}
        <div className="create-box__content">{children}</div>
      </React.Fragment>
    );
  }

  renderCreateBoxTutorial() {
    if (this.props.hideTutorials) {
      return null;
    }

    return (
      <HideOnTablet>
        <Column id={this.tutorialsId} smSpan="4" className="create-box__tutorial">
          <Tutorials renderTutorials={this.props.renderTutorials} />
        </Column>
      </HideOnTablet>
    );
  }

  render() {
    const {
      title,
      formNotification,
      resources = [],
      hideTutorials,
      loaderMessage,
      hideContent,
      loaderPosition
    } = this.props;

    return (
      <section>
        <SecondLevelTitle>{title}</SecondLevelTitle>

        <Container
          className="create-box"
          padding="none"
          data-e2e="create-box"
          tabIndex={0}
          aria-label={title}
          aria-describedby={this.tutorialsId}
          role="region"
        >
          <Grid sm="12" gap="none">
            <Column smSpan="12" lgSpan={hideTutorials ? '12' : '8'} style={{ position: 'relative' }}>
              <PartialLoader
                message={loaderMessage}
                resources={resources}
                position={loaderPosition}
                localTaskLoaderType={LocalTaskLoaderType.CREATE_BOX}
                hideLoader={Boolean(formNotification)}
                hideContent={hideContent}
              >
                <div style={generateStyles(formNotification)}>{this.renderChildren()}</div>
                {formNotification && this.renderFormNotification()}
              </PartialLoader>
            </Column>
            {this.renderCreateBoxTutorial()}
          </Grid>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state, { formName }) => ({
  formNotification: findFormNotification(state, { formName })
});

export default connect<StateProps, any, OwnProps>(mapStateToProps)(injectIntl(CreateBox));
