import { PhpModType } from '../../../core/selectors/php-settings';

export type PhpVersion = {
  version: string;
  id: number;
  default?: 1;
  disabled?: 1;
  recommended?: 1;
  mod_php?: 1;
};

export type PhpLocationVersion = {
  domain_name: string;
  domain_id: number;
  id: number;
  path: string;
  location_id: number;
  version_id: string | number;
};

export type SettingApiType = 'number' | 'bool' | 'string' | 'path' | 'decimal' | 'size' | 'color' | string;

export interface PHPVariable {
  can_set: 0 | 1;
  id: string;
  user_value?: string;
  value: string;
}

export enum PhpVersionManageType {
  Auto = 'recommended',
  Manual = 'manual'
}

export const enum PhpManagerState {
  ModPhpDisabled = 'mod_php_disabled',

  ModPhpMainSelected = 'mod_php_main_selected',

  StandardPhpMainSelected = 'standard_php_main_selected',
  StandardPhpSubDomainSelected = 'standard_php_sub_domain_selected',
  StandardPhpStagingSelected = 'standard_php_staging_selected'
}

export const enum SiteTypes {
  LiveSites = 'live-sites',
  StagingSites = 'staging-sites'
}

export const enum PhpManagerDomainTypes {
  Main = 'main',
  SubDomain = 'sub_domain',
  Staging = 'staging'
}

export type PhpManagerStateInput = {
  phpMod: PhpModType;
  domainType: PhpManagerDomainTypes;
};
