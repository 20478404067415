import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getFormAsyncErrors, clearAsyncError } from 'redux-form';
import { Dropdown } from '@siteground/styleguide';
import { FORM_DROPDOWN_PREFIX } from '../../core/constants/sg-form-dropdown';

type Props = {
  intl: Intl;
  enableBackendValidation: boolean;
  optionDisabled: string;
  getAsyncErrors: Function;
  clearAsyncError: typeof clearAsyncError;
  options: any[];
  disableAutoSelect: boolean;
  optionValue: any;
  optionLabel: string;
  input: any;
  placeholder: string;
  meta: {
    form: string;
    valid: boolean;
    touched: boolean;
    active: boolean;
    error: string;
  };
};

class FormDropdown extends React.Component<Props, any> {
  componentDidUpdate() {
    const { enableBackendValidation } = this.props;

    if (!Boolean(enableBackendValidation)) {
      this.clearAsyncErrors();
    }

    this.selectDefaultValue();
  }

  componentDidMount() {
    this.selectDefaultValue();
  }

  clearAsyncErrors = () => {
    const { input, meta, getAsyncErrors, clearAsyncError } = this.props;
    const { name } = input;
    const { form, valid } = meta;

    if (!valid) {
      const asyncErrors = getAsyncErrors(form);

      if (asyncErrors && asyncErrors[name]) {
        clearAsyncError(form, name);
      }
    }
  };

  selectDefaultValue() {
    const { input, ...props } = this.props;
    const defaultOptionCanBeSelected = Boolean(
      !props.disableAutoSelect || (props.options && props.options.length === 1)
    );

    /* eslint-disable */
    if (input.value === undefined || input.value === null || input.value === '') {
      if (props.options && props.options[0] && defaultOptionCanBeSelected) {
        if (!props.options[0][props.optionDisabled]) {
          input.onChange(props.options[0][props.optionValue]);
        }
      }
    }
    /* eslint-enable */
  }

  render() {
    const { intl, input, meta, placeholder, ...props } = this.props;
    let status;
    let error;

    const touchedOrActive = meta.touched || meta.active;
    const touchedOrHasValue = meta.touched || Boolean(input.value);

    if (!meta.valid && touchedOrActive && touchedOrHasValue) {
      status = 'error';
      error = meta.error;
    }

    return (
      <Dropdown
        data={props.options}
        indexKey={props.optionLabel}
        state={status}
        selectedValue={input.value}
        validationMessage={error}
        data-e2e={`${FORM_DROPDOWN_PREFIX}${input.name}`}
        data-component={`${FORM_DROPDOWN_PREFIX}${input.name}`}
        placeholder={placeholder || intl.formatMessage({ id: 'translate.generic.please_select' })}
        {...input}
        {...props}
      />
    );
  }
}

export default connect<any, any, Props>(
  (state) => ({
    getAsyncErrors: (formName) => getFormAsyncErrors(formName)(state)
  }),
  { clearAsyncError }
)(injectIntl<any, any>(FormDropdown));
