export const WP_LANGUAGES = [
  {
    id: 'en_GB',
    label: 'English (UK)'
  },
  {
    id: 'en_US',
    label: 'English (United States)'
  },
  {
    id: 'ar',
    label: 'Arabic'
  },
  {
    id: 'sq',
    label: 'Albanian'
  },
  {
    id: 'bg_BG',
    label: 'Bulgarian'
  },
  {
    id: 'ca',
    label: 'Catalan'
  },
  {
    id: 'cs_CZ',
    label: 'Czech'
  },
  {
    id: 'da_DK',
    label: 'Danish'
  },
  {
    id: 'nl_NL',
    label: 'Dutch'
  },
  {
    id: 'fi',
    label: 'Finnish'
  },
  {
    id: 'fr_FR',
    label: 'French (France)'
  },
  {
    id: 'de_DE',
    label: 'German'
  },
  {
    id: 'el',
    label: 'Greek'
  },
  {
    id: 'he_IL',
    label: 'Hebrew'
  },
  {
    id: 'hu_HU',
    label: 'Hungarian'
  },
  {
    id: 'it_IT',
    label: 'Italian'
  },
  {
    id: 'ja',
    label: 'Japanese'
  },
  {
    id: 'nn_NO',
    label: 'Norwegian (Nynorsk)'
  },
  {
    id: 'fa_IR',
    label: 'Persian'
  },
  {
    id: 'pl_PL',
    label: 'Polish'
  },
  {
    id: 'pt_BR',
    label: 'Portuguese (Brazil)'
  },
  {
    id: 'pt_PT',
    label: 'Portuguese (Portugal)'
  },
  {
    id: 'ro_RO',
    label: 'Romanian'
  },
  {
    id: 'ru_RU',
    label: 'Russian'
  },
  {
    id: 'sl_SI',
    label: 'Slovenian'
  },
  {
    id: 'sk_SK',
    label: 'Slovak'
  },
  {
    id: 'es_ES',
    label: 'Spanish (Spain)'
  },
  {
    id: 'sv_SE',
    label: 'Swedish'
  },
  {
    id: 'tr_TR',
    label: 'Turkish'
  },
  {
    id: 'uk',
    label: 'Ukrainian'
  },
  {
    id: 'zh_CN',
    label: 'Chinese (China)'
  },
  {
    id: 'zh_HK',
    label: 'Chinese (Hong Kong)'
  },
  {
    id: 'zh_TW',
    label: 'Chinese (Taiwan)'
  }
];

export const JOOMLA_LANGUAGES = [
  {
    id: 'en_US',
    label: 'English'
  },
  {
    id: 'es_ES',
    label: 'Spanish'
  },
  {
    id: 'it_IT',
    label: 'Italian'
  },
  {
    id: 'de_DE',
    label: 'German'
  }
];
