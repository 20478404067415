export interface NemoRequestData {
  endpoint: string;
  method: 'GET' | 'POST' | 'PUT';
  body?: any;
  itemId?: any;
  requestTypeName?: string;
}

export enum NemoActionTypes {
  CheckOrderStatus = 'CHECK_ORDER_STATUS'
}

export enum OrderStatus {
  Failed = -1,
  Pending = 0,
  Started = 1,
  LxcSuccessfullyCalledKyup = 40,
  LxcContainerUp = 41,
  Successful = 100
}

export type CheckOrderStatus = {
  type: NemoActionTypes.CheckOrderStatus;
  payload: {
    orderId: string;
    getInstalledExtras: () => void;
    clearState: () => void;
  };
  notification: SpanelNotification;
};
