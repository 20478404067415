import * as React from 'react';
import { useIntl } from 'react-intl';
import { Flex, Text } from '@siteground/styleguide';

import { ShowOnMobile } from '../../../components/device';

export const VariablesMobileFilter = ({ renderFilterButton }) => {
  const intl = useIntl();
  return (
    <ShowOnMobile>
      <Flex padding="none" margin="medium" justify="flex-end" align="center">
        <Text>{intl.formatMessage({ id: 'translate.generic.filter' })}</Text>
        {renderFilterButton()}
      </Flex>
    </ShowOnMobile>
  );
};

export default VariablesMobileFilter as typeof VariablesMobileFilter;
