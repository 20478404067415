import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';
import { DeleteDialog } from '../../../components/dialogs';
import { codeEditorOnTabClose, deleteDir } from '../../core/actions/file-manager';
import { DeleteDialogPayload } from '../../core/selectors/get-delete-dialog-payload';
import { getEntityByPath, getEntityParentPath, getEntityPath, getEntityReadableName } from '../../core/utils';

type Props = {
  closeSGDialog: typeof closeSGDialog;
  codeEditorOnTabClose: typeof codeEditorOnTabClose;
  deleteDir: typeof deleteDir;
  intl: Intl;
  deleteDialogPayload: DeleteDialogPayload;
};

const onDeleteSubmit = ({
  entities,
  selectedNavigationEntity,
  fileManagerState,
  deleteDir,
  codeEditorEntitiesThatNeedToBeClosed,
  codeEditorOnTabClose,
  closeSGDialog
}) => {
  const clearFileManagerStore: any = { selectedContentEntities: [] };
  const entries = entities.map((entity) => getEntityPath(entity));

  const selectedNavigationEntityForDelete = entities.find(
    (entity) => getEntityPath(entity) === getEntityPath(selectedNavigationEntity)
  );

  if (selectedNavigationEntityForDelete) {
    const entityParentPath = getEntityParentPath(selectedNavigationEntityForDelete);
    clearFileManagerStore.selectedNavigationEntity = getEntityByPath(entityParentPath, fileManagerState);
  }

  codeEditorEntitiesThatNeedToBeClosed.forEach((entity) => {
    codeEditorOnTabClose(entity);
  });

  deleteDir(
    {
      urlParams: {
        entries
      },
      entity: entities[0],
      clearFileManagerStore,
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.delete.success.message',
            intlValues: { total: entries.length }
          },
          error: {
            intlKey: 'translate.file.manager.delete.error.message',
            intlValues: { total: entries.length }
          }
        }
      }
    },
    () => closeSGDialog(DIALOGS.FILE_MANAGER_DELETE)
  );
};

const Delete = (props: Props) => {
  const { closeSGDialog, codeEditorOnTabClose, deleteDir, intl, deleteDialogPayload } = props;

  const { codeEditorEntitiesThatNeedToBeClosed, fileManagerState, prioritizedEntities, selectedNavigationEntity } =
    deleteDialogPayload;

  const entities = prioritizedEntities;
  const entitiesCount = entities && entities.length;
  const title = intl.formatMessage(
    {
      id:
        entitiesCount === 1
          ? 'translate.file.manager.delete.dialog.title.single.entity'
          : 'translate.file.manager.delete.dialog.title.multiple.entities'
    },
    {
      entityName: entities && getEntityReadableName(entities[0]),
      count: entitiesCount
    }
  );

  return (
    <DeleteDialog
      id={DIALOGS.FILE_MANAGER_DELETE}
      title={title}
      onSubmit={() =>
        onDeleteSubmit({
          entities,
          selectedNavigationEntity,
          fileManagerState,
          codeEditorEntitiesThatNeedToBeClosed,
          codeEditorOnTabClose,
          deleteDir,
          closeSGDialog
        })
      }
    />
  );
};

export default connect<{}, {}, Partial<Props>>(
  (state) => ({
    deleteDialogPayload: state.dialog[DIALOGS.FILE_MANAGER_DELETE] || {}
  }),
  {
    codeEditorOnTabClose,
    closeSGDialog,
    deleteDir
  }
)(injectIntl<any, any>(Delete));
