import { call, put, select, takeLatest } from 'redux-saga/effects';
import { clientApi } from '../api/client';
import { GET_COUNTRIES } from '../constants/actions';
import { getCountriesSucceeded } from '../actions/countries';
import handleAvalonApiRequest from './handle-avalon-api-request';
import { transformCountriesData } from '../api/data-transformer';
import { handleNemoApiRequest } from './handle-nemo-api-request';

function* getCountriesSaga() {
  const { session } = yield select();

  return yield call(clientApi(`/locale/list_countries?_client_token=${session.clientToken}`, 'GET'));
}

function* getCountries(action: NemoRequestAction) {
  const response = yield call(handleNemoApiRequest(getCountriesSaga, action));

  const validResponse = Boolean(response && response.status === 200 && response.data);

  if (validResponse) {
    yield put(getCountriesSucceeded(transformCountriesData(response.data)));
  }

  return response;
}

function* countries(): any {
  yield takeLatest(GET_COUNTRIES, getCountries);
}

export default countries;
