import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ContextMenu, ContextMenuItem, Flex, IconButton } from '@siteground/styleguide';
import { addSGTableRowSelection } from '../../../core/actions/sg-table';
import VCS from '../visibility-control-service';
import './table-context-menu.scss';

type ActionItem = {
  vcsMethod?: string;
  visibleOnDesktop?: boolean;
  disabled?: boolean;
  icon: string;
  label: string;
  e2eAttr?: string;
  onClick: Function;
  shortcut?: any;
  // Add prop for resourceName on each item if needed !!!
};

type Props = {
  table?: any;
  environment?: {
    isPhone: boolean;
  };
  // config data
  resourceName: string;
  items: ActionItem[];
  entity?: any;
  addSGTableRowSelection?: Function;
};

type DispatchedProps = {
  intl: Intl;
};

type MenuConfigData = {
  vcsAllMethods: string[];
  visibleOnDesktop: ActionItem[];
};

export const renderInVCSWrapper = (resourceName: string, vcsMethod = null, renderComponentItem: () => JSX.Element) => {
  if (vcsMethod) {
    return (
      <VCS resourceName={resourceName} hasMethod={vcsMethod}>
        {renderComponentItem()}
      </VCS>
    );
  }

  return renderComponentItem();
};

export class TableContextMenu extends React.Component<Props & DispatchedProps, any> {
  handleContextMenuItemMouseOver = () => {
    const { addSGTableRowSelection, table, entity } = this.props;

    if (table.selectedRows.length === 0) {
      addSGTableRowSelection(entity);
    }
  };

  renderIconsVisibleOnDesktop = (menuConfiguration: MenuConfigData) =>
    menuConfiguration.visibleOnDesktop.map(
      ({ vcsMethod = null, icon, label, e2eAttr = '', onClick, disabled = false }, index) => {
        // render on dekstop view, actions marked as visible on desktop
        return (
          <React.Fragment key={`desktop-visible-${index}`}>
            {renderInVCSWrapper(this.props.resourceName, vcsMethod, () => (
              <IconButton
                shape="circle"
                size="medium"
                icon={icon}
                disabled={disabled}
                tooltip={label}
                data-e2e={e2eAttr}
                onClick={onClick}
                tabIndex={-1}
                aria-label={label}
              />
            ))}
          </React.Fragment>
        );
      }
    );

  renderSingleIconButton = () =>
    // render on mobile view, actions as IconButton, if it is only 1
    renderInVCSWrapper(this.props.resourceName, this.props.items[0].vcsMethod, () => (
      <IconButton
        shape="circle"
        size="medium"
        icon={this.props.items[0].icon}
        disabled={Boolean(this.props.items[0].disabled)}
        tooltip={this.props.items[0].label}
        data-e2e={this.props.items[0].e2eAttr}
        onClick={this.props.items[0].onClick}
        aria-label={this.props.items[0].label}
        tabIndex={-1}
      />
    ));

  renderContextMenu = () => (
    <ContextMenu
      opener={
        <IconButton
          onClick={() => this.props.addSGTableRowSelection(this.props.entity)}
          shape="circle"
          size="medium"
          icon="dots"
          data-e2e="table-actions"
          tabIndex={-1}
          aria-label={this.props.intl.formatMessage({ id: 'translate.aria-label.actions' })}
        />
      }
    >
      {this.props.items.map(
        (
          {
            vcsMethod = null,
            visibleOnDesktop = false,
            icon,
            label,
            e2eAttr,
            onClick,
            disabled = false,
            shortcut = null
          },
          index
        ) => {
          // on mobileView (isPhone) all items are rendered in context menu
          const shownOutsideContextMenu = visibleOnDesktop && !this.props.environment.isPhone;

          if (!shownOutsideContextMenu) {
            return (
              <React.Fragment key={`context-menu-visible-${index}`}>
                {renderInVCSWrapper(this.props.resourceName, vcsMethod, () => (
                  <ContextMenuItem
                    onMouseOver={this.handleContextMenuItemMouseOver}
                    disabled={disabled}
                    icon={icon}
                    onClick={onClick}
                    shortcut={shortcut}
                    data-e2e={e2eAttr}
                  >
                    {label}
                  </ContextMenuItem>
                ))}
              </React.Fragment>
            );
          }

          // item already rendered outside of the context menu
          return null;
        }
      )}
    </ContextMenu>
  );

  render() {
    const { environment, resourceName, items = [] } = this.props;
    const menuConfiguration: MenuConfigData = items.reduce(
      (output: MenuConfigData, itemData: ActionItem) => {
        const { vcsMethod, visibleOnDesktop = false } = itemData;
        const visibilityMethod = vcsMethod && !output.vcsAllMethods.includes(vcsMethod);
        return {
          vcsAllMethods: visibilityMethod ? [...output.vcsAllMethods, vcsMethod] : output.vcsAllMethods,
          visibleOnDesktop: visibleOnDesktop ? [...output.visibleOnDesktop, itemData] : output.visibleOnDesktop
        };
      },
      {
        vcsAllMethods: [],
        visibleOnDesktop: []
      }
    );

    const allVisibleOnDesktop = menuConfiguration.visibleOnDesktop.length === items.length;
    const hasContextMenu = (!allVisibleOnDesktop || environment.isPhone) && items.length > 1;

    return (
      <VCS resourceName={resourceName} hasOneOfMethods={menuConfiguration.vcsAllMethods}>
        <Flex justify="flex-end" wrap="nowrap" className="table-context-menu">
          {!environment.isPhone && this.renderIconsVisibleOnDesktop(menuConfiguration)}
          {(environment.isPhone && items.length) === 1 && this.renderSingleIconButton()}
          {hasContextMenu && this.renderContextMenu()}
        </Flex>
      </VCS>
    );
  }
}

export default connect<any, DispatchedProps, Props>(({ environment, table }) => ({ environment, table }), {
  addSGTableRowSelection
})(injectIntl(TableContextMenu));
