import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DIALOGS } from '../../../../core/constants/common';
import { RootState } from '../../../reducers';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';

type Props = {
  dialog: {
    [otherProps: string]: {
      message: string;
      onConfirm: Function;
      title: string;
    };
  };
};

const MessageDialog = ({ dialog }: Props) => {
  const intl = useIntl();
  const title =
    dialog[DIALOGS.FILE_MANAGER_MESSAGE] && intl.formatMessage({ id: dialog[DIALOGS.FILE_MANAGER_MESSAGE].title });
  const message = dialog[DIALOGS.FILE_MANAGER_MESSAGE] && dialog[DIALOGS.FILE_MANAGER_MESSAGE].message;

  return (
    <SGDialog
      id={DIALOGS.FILE_MANAGER_MESSAGE}
      icon="warning"
      state="warning"
      title={title}
      footer={<SGDialogCancel id={DIALOGS.FILE_MANAGER_MESSAGE} />}
    >
      {message}
    </SGDialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  dialog: state.dialog
});

export default connect<{}, any, Partial<Props>>(mapStateToProps)(MessageDialog);
