import * as React from 'react';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { Grid } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';
import { DNSTypes } from '../constants';
import DNSFieldsEditRecord from '../dns-fields-edit-record';

type Props = {
  intl: Intl;
  onSubmit: Function;
  sPanelFormSubmit: (formName) => null;
  type: DNSTypes;
  getFormValues: Function;
  domainName: string;
  validationUtils: ValidationUtils;
  listedDomains: string[];
};

const formName = REDUX_FORM.CHANGE_DNS_DIALOG;

class ChangeDNS extends React.Component<Props, any> {
  render() {
    const { domainName, intl, type, validationUtils, getFormValues, listedDomains } = this.props;

    return (
      <Grid>
        <DNSFieldsEditRecord
          listedDomains={listedDomains}
          domainName={domainName}
          dnsType={type}
          validationUtils={validationUtils}
          intl={intl}
          formName={formName}
          getFormValues={getFormValues}
        />
      </Grid>
    );
  }
}

export default withSpanelForm(
  reduxForm({
    form: formName
  })(injectIntl(ChangeDNS))
);
