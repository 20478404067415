import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDate, getTime, Table, Text } from '@siteground/styleguide';
import bytesToSize from '../../../core/common/size-converter';
import { getTranslations } from '../../components/sg-table/translation';
import { RootState } from '../../reducers';
import Entity from '../content/entity';
import { selectContentRows } from '../core/actions/file-manager';
import { searchForInput } from '../core/actions/search-view';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../core/constants/common';
import { getEntityType } from '../core/utils';
import SearchForm from './search-form';

interface StateProps {
  searchResults: any[];
  selectedContentEntities: any[];
}

type DispatchProps = {
  selectContentRows: typeof selectContentRows;
  searchForInput: typeof searchForInput;
  intl: Intl;
};

type OwnProps = {
  openContextMenu: Function;
};

interface Props extends StateProps, DispatchProps, OwnProps {}

enum TableNoData {
  noSearchTrigger = 'no-search-trigger',
  noSearchResults = 'no-search-results'
}

type State = {
  tableNoDataToShow: TableNoData;
};

class FileManagerSearchView extends React.Component<Props, State> {
  readonly state = {
    tableNoDataToShow: TableNoData.noSearchTrigger
  };

  onRowSelection = (selectedContentEntities) => {
    const { selectContentRows } = this.props;

    const selectedRows = selectedContentEntities.filter((entity) =>
      Boolean(getEntityType(entity) !== FILE_MANAGER_API_RESPONSE_DIR.SYMLINK)
    );

    selectContentRows(selectedRows);
  };

  renderNameColumn = (n, entity) => {
    return <Entity entity={entity} nameTextTruncate={false} showFullPath />;
  };

  renderModifiedDateColumn(dm, entity) {
    return (
      <div>
        <Text color="light">{`${getDate(dm)} ${getTime(dm)}`}</Text>
      </div>
    );
  }

  renderPermissionsColumn = (permissions, entity) => {
    return (
      <div>
        <Text color="light">{permissions}</Text>
      </div>
    );
  };

  renderSizeColumn = (size, entity) => {
    const isFile = getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
    const renderInfo = isFile || (!isFile && size > 0) ? bytesToSize(size) : '-';

    return (
      <div style={{ justifyContent: 'flex-end' }}>
        <Text color="light">{renderInfo}</Text>
      </div>
    );
  };

  render() {
    const { intl, searchForInput, searchResults } = this.props;
    const { tableNoDataToShow } = this.state;
    const isInitialSearch = tableNoDataToShow === TableNoData.noSearchTrigger;

    const noDataProps = {
      noDataProps: {
        title: intl.formatMessage({
          id: isInitialSearch
            ? 'translate.file.manager.search.initial.placeholder.title'
            : 'translate.file.manager.search.no.data'
        }),
        message: isInitialSearch
          ? intl.formatMessage({ id: 'translate.file.manager.search.initial.placeholder.message' })
          : null,
        icon: isInitialSearch ? 'presentational-search-for-a-file' : 'presentational-no-results-found'
      }
    };

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.name' }),
        accessor: FILE_MANAGER_API_RESPONSE_DIR.NAME,
        render: this.renderNameColumn
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.date.modified' }),
        accessor: FILE_MANAGER_API_RESPONSE_DIR.MODIFIED_TIME,
        render: this.renderModifiedDateColumn,
        style: {
          width: '200px'
        }
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.permissions' }),
        accessor: FILE_MANAGER_API_RESPONSE_DIR.PERMISSIONS,
        render: this.renderPermissionsColumn,
        style: {
          width: '110px'
        }
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.size' }),
        accessor: FILE_MANAGER_API_RESPONSE_DIR.SIZE,
        render: this.renderSizeColumn,
        style: {
          textAlign: 'right',
          width: '100px'
        }
      }
    ];

    return (
      <React.Fragment>
        <SearchForm
          onSubmit={(data) =>
            searchForInput(
              {
                root: data.root,
                search: data.search || '',
                _meta: {
                  notification: {
                    type: 'generic',
                    error: {
                      intlKey: 'translate.file.manager.search.error.message'
                    }
                  }
                }
              },
              () => this.setState({ tableNoDataToShow: TableNoData.noSearchResults })
            )
          }
        />

        <Table
          className="fm-search-table"
          selectable
          showHeaderWithNoData
          cellHeight="small"
          data={searchResults}
          columns={columns}
          mobileLayout="row"
          rowKey={FILE_MANAGER_API_RESPONSE_DIR.INFO_NUMBER}
          onRowSelection={(rows) => this.onRowSelection(rows)}
          translation={{ ...getTranslations(intl) }}
          {...noDataProps}
          // TODO remove this if it's deprecated
          onRowContextMenu={({ event, selectedRows }) => {
            event.preventDefault();
            event.stopPropagation();
            this.onRowSelection(selectedRows);
            this.props.openContextMenu(event);
          }}
        />

        {!isInitialSearch && (
          <div className="file-manager-footer" data-e2e="file-manager-footer">
            <Text color="light" className="file-manager-footer__content">
              {searchResults.length <= 99
                ? intl.formatMessage(
                    {
                      id: 'translate.file.manager.search.footer.under.limit.message'
                    },
                    { results: searchResults.length }
                  )
                : intl.formatMessage({ id: 'translate.file.manager.search.footer.over.limit.message' })}
            </Text>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  searchResults: state.fileManager.search.results,
  selectedContentEntities: state.fileManager.selectedContentEntities
});

const mapDispatchToProps = {
  searchForInput,
  selectContentRows
};

export default connect<StateProps, any, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FileManagerSearchView));
