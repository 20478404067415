import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Button } from '@siteground/styleguide';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import SelectPath from '../../../components/dialogs/select-path';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';

type Props = {
  change: Function;
  openSGDialog: Function;
};

const MoveForm = ({ change, openSGDialog }: Props) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <Field
        name="dest"
        label={intl.formatMessage({ id: 'translate.file.manager.folder' })}
        autoFocus
        suffix={
          <Button
            action="button"
            color="dark"
            size="small"
            onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY)}
            data-e2e="footer-button-browse"
          >
            {intl.formatMessage({ id: 'translate.generic.browse' })}
          </Button>
        }
        component={FormInput}
      />

      <SelectPath initialPath="/" onChange={(path) => change('dest', path.replace('//', '/'))} />
    </React.Fragment>
  );
};

export default withSpanelForm(reduxForm({ form: REDUX_FORM.FILE_MANAGER_MOVE_ENTITY })(MoveForm));
