import { LocalTaskLoaderType } from '../constants/common';
import { RootState } from '../../web/reducers';
import { formatMessage } from '../translate';
import { getApiEndpointsForCurrentRoute, getLocalTaskLoaderEndpointsForCurrentRoute } from './menu-items';

export const getTaskNotificationData = (task: Task): SpanelNotification =>
  task.options.meta && task.options.meta.notification;

export const isTaskUnfinished = (task: Task): boolean => Boolean(task.options && task.options.completed === 0);

export const isTaskCompleted = (task: Task): boolean => !isTaskUnfinished(task);

export const isTaskSuccessful = (task: Task): boolean => parseInt(task.response.err_code, 10) === 0;

export const getTaskSuccessMessage = (task: Task): string => {
  const notification = getTaskNotificationData(task);

  const taskValues = {
    action: task.options.action,
    endpoint: task.options.endpoint
  };

  if (notification && notification.success) {
    return formatMessage(
      {
        id: notification.success.intlKey
      },
      {
        ...taskValues,
        ...notification.success.intlValues
      }
    );
  }

  return formatMessage({ id: 'translate.generic.task_finished_successfully' }, taskValues);
};

export const getTaskErrorMessage = (task: Task): string => {
  const notification = getTaskNotificationData(task);

  if (notification && notification.error) {
    return formatMessage(
      {
        id: notification.error.intlKey
      },
      {
        ...notification.error.intlValues,
        backendError: task.response.message || ''
      }
    );
  }

  return task.response.message;
};

export const getTaskTitle = (task: Task): string => {
  const notification = getTaskNotificationData(task);

  return formatMessage({
    id:
      notification && notification.taskTitle
        ? notification.taskTitle
        : 'translate.generic.task_running_longer_than_expected'
  });
};

export const isTaskLocalForPage = (task: Task, pathname: string, localTaskLoaderType?: LocalTaskLoaderType) => {
  const localTaskLoaderEndpoints = getLocalTaskLoaderEndpointsForCurrentRoute(pathname, localTaskLoaderType);

  return Boolean(
    task.options &&
      task.options.meta &&
      task.options.endpoint &&
      task.options.meta.localPageTask &&
      task.options.meta.localPageTask.type &&
      localTaskLoaderEndpoints[task.options.meta.localPageTask.type] &&
      localTaskLoaderEndpoints[task.options.meta.localPageTask.type].includes(task.options.endpoint)
  );
};

export const getTasksIds = (response) => {
  return Boolean(response && response.tasklist && response.tasklist.length > 0) ? response.tasklist : [];
};

export const isTaskMatchingPathname = (task: Task, pathname) => {
  const apiEndpointsForCurrentRoute = getApiEndpointsForCurrentRoute(pathname);

  if (!task.options && !task.options.endpoint) {
    return false;
  }

  if (apiEndpointsForCurrentRoute.includes(task.options.endpoint)) {
    return true;
  }
};

export const getRouteTasks = ({ tasks }: Partial<RootState>, pathname: string) =>
  tasks.filter((task) => isTaskMatchingPathname(task, pathname));

export const getPendingLocalTasks = (
  { tasks }: Partial<RootState>,
  pathname: string,
  localTaskLoaderType?: LocalTaskLoaderType
) =>
  getRouteTasks({ tasks }, pathname).filter(
    (task: Task) => isTaskUnfinished(task) && isTaskLocalForPage(task, pathname, localTaskLoaderType)
  );

export const getPendingTasks = ({ tasks }: Partial<RootState>, pathname) =>
  getRouteTasks({ tasks }, pathname).filter(isTaskUnfinished);

export const getTaskNotifications = ({ notifications }: Partial<RootState>, pathname) => {
  const tasks: Task[] = Object.values(notifications.task);
  return getRouteTasks({ tasks }, pathname);
};
