import ErrorStackParser from 'error-stack-parser';
import { sendLog } from '@siteground/styleguide/lib/utils';

export const SendErrorLog = ({ error, errorInfo, session, projectName, lastActions, uid = null }) => {
  const parsedError = ErrorStackParser.parse(error);
  const parsedErrorInfo = {
    componentStack: parsedError ? JSON.stringify(parsedError) : errorInfo.componentStack
  };

  console.error(error);

  let username = null;
  if (CONFIG.ERROR_LOG_USER && session && session.user && session.user.username) {
    username = session.user.username;
  }

  sendLog({
    uid: uid,
    app: projectName,
    error,
    errorInfo: parsedErrorInfo,
    data: JSON.stringify({
      username,
      url: window.location.href,
      actions: lastActions,
      agent: window.navigator.userAgent
    })
  });
};
