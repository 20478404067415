const PRIORITY = {
  f: 0,
  d: 1,
  l: 1
};

export const sortByTypeAndName = (a, b) => {
  if (PRIORITY[a.t] < PRIORITY[b.t]) {
    return 1;
  }
  if (PRIORITY[a.t] > PRIORITY[b.t]) {
    return -1;
  }

  if (PRIORITY[a.t] === PRIORITY[b.t]) {
    if (a.n < b.n) {
      return -1;
    }
    if (a.n > b.n) {
      return 1;
    }
    return 0;
  }
};

export const addMetaInformation = (item, base) => ({
  ...item,
  _meta: {
    path: (base + '/' + item.n).replace('//', '/')
  }
});

const formatBackupRestoreFiles = ({ state, resourceName, items }) => ({
  ...state[resourceName],
  [items.base]: items.data.sort(sortByTypeAndName).map((item) => addMetaInformation(item, items.base))
});

export default formatBackupRestoreFiles;
