import * as React from 'react';
import { Button, Colors, Flex, Grid, Icon, Label, Link, Switch, Text } from '@siteground/styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS } from '../../../../core/constants/common';
import { findMainDomain } from '../../../../core/selectors';
import indexWithCRUD from '../../../components/indexWithCRUD';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import SGTable from '../../../components/sg-table';
import VCS from '../../../components/visibility-control-service';

class CloudflareSubdomains extends React.Component<any, any> {
  readonly state = {
    cnameDialog: null
  };

  getSubDomainZoneName(name) {
    const { selectedZone } = this.props;

    return name.split(`.${selectedZone.name}`).shift();
  }

  activateZone = (zoneName) => {
    const { actions, selectedZone, domain, mainDomain } = this.props;
    const selectedDomain = domain.find((d) => d.name === `${zoneName}.${mainDomain.name}`);

    if (selectedDomain.settings.cdn_enabled) {
      return this.props.openSGDialog(DIALOGS.CLOUDFLARE_CANNOT_BE_ACTIVATED);
    }

    actions.updateItem({
      id: selectedZone.id,
      _metaFields: {
        ...API_RESOURCE.CLOUDFLARE_ZONE
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.cloudflare.subdomain.activate.zone.success.message',
            intlValues: { name: `${zoneName}.${selectedZone.name}` }
          },
          error: {
            intlKey: 'translate.page.cloudflare.subdomain.activate.zone.error.message',
            intlValues: { name: `${zoneName}.${selectedZone.name}` }
          }
        }
      },
      name: selectedZone.name,
      sub_domains: selectedZone.sub_domains.concat([zoneName])
    });
  };

  deactivateZone = (zoneName) => {
    const { actions, selectedZone } = this.props;

    actions.updateItem({
      id: selectedZone.id,
      _metaFields: {
        ...API_RESOURCE.CLOUDFLARE_ZONE
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.cloudflare.subdomain.deactivate.zone.success.message',
            intlValues: { name: `${zoneName}.${selectedZone.name}` }
          },
          error: {
            intlKey: 'translate.page.cloudflare.subdomain.deactivate.zone.error.message',
            intlValues: { name: `${zoneName}.${selectedZone.name}` }
          }
        }
      },
      name: selectedZone.name,
      sub_domains: selectedZone.sub_domains.filter((zone) => zone !== zoneName)
    });
  };

  isZoneDataFull(zoneName) {
    const { selectedZone } = this.props;

    if (
      !selectedZone ||
      !selectedZone.zone_check_data ||
      !selectedZone.zone_check_data.sub_domains ||
      !selectedZone.zone_check_data.sub_domains[zoneName]
    ) {
      return false;
    }

    return Boolean(selectedZone.zone_check_data.sub_domains[zoneName]);
  }

  isCNAMEConfigured(zoneName) {
    const { selectedZone } = this.props;

    return this.isZoneDataFull(zoneName) && Boolean(selectedZone.zone_check_data.sub_domains[zoneName].cname);
  }

  isCFResolveConfigured(zoneName) {
    const { selectedZone } = this.props;

    return this.isZoneDataFull(zoneName) && Boolean(selectedZone.zone_check_data.cf_resolve_to);
  }

  isWWWConfigured(zoneName) {
    return this.isCNAMEConfigured(zoneName) && this.isCFResolveConfigured(zoneName);
  }

  isZoneStatusPending(zoneName) {
    return !this.isWWWConfigured(zoneName);
  }

  renderStatusLabel = (name) => {
    const { intl, selectedZone } = this.props;
    const zoneName = this.getSubDomainZoneName(name);
    const isZoneActive = selectedZone.sub_domains.includes(zoneName);
    const isZoneStatusPending = this.isZoneStatusPending(zoneName);
    let labelColor: Colors = 'error';
    let labelId = 'translate.generic.inactive';

    if (isZoneActive) {
      labelColor = 'success';
      labelId = isZoneStatusPending ? 'translate.generic.pending' : 'translate.generic.active';
    }

    if (isZoneActive && isZoneStatusPending) {
      labelColor = undefined;
    }

    return (
      <Flex margin="medium" align="center">
        <Label color={labelColor} type="link" size="medium" padding={['inherit', 'medium', 'inherit', 'none']}>
          {intl.formatMessage({ id: labelId })}
        </Label>

        {isZoneActive && isZoneStatusPending && (
          <Grid>
            <Flex align="center">
              <Icon name="warning" color="warning" size="18" />
              &nbsp;
              <Text color="warning">
                {intl.formatMessage({ id: 'translate.page.cloudflare.www.check.notice.title' })}.
              </Text>
              &nbsp;
              <Link
                color="ocean"
                onClick={() =>
                  this.setState({ cnameDialog: { domain: name, zoneName } }, () =>
                    this.props.openSGDialog(DIALOGS.CLOUDFLARE_CNAME)
                  )
                }
              >
                <Text color="ocean" weight="bold">
                  {intl.formatMessage({ id: 'translate.generic.view.details' })}
                </Text>
              </Link>
            </Flex>
          </Grid>
        )}
      </Flex>
    );
  };

  renderDesktopActionMenu = ({ zoneName, isZoneActive }) => {
    const { intl } = this.props;

    return (
      <React.Fragment>
        {!isZoneActive && (
          <Button color="secondary" type="outlined" data-e2e="activate" onClick={() => this.activateZone(zoneName)}>
            {intl.formatMessage({ id: 'translate.generic.activate' })}
          </Button>
        )}

        {isZoneActive && (
          <Button color="secondary" type="outlined" data-e2e="deactivate" onClick={() => this.deactivateZone(zoneName)}>
            {intl.formatMessage({ id: 'translate.generic.deactivate' })}
          </Button>
        )}
      </React.Fragment>
    );
  };

  renderMobileActionMenu = ({ zoneName, isZoneActive }) => {
    return (
      <Switch
        checked={isZoneActive}
        onChange={(event) => (isZoneActive ? this.deactivateZone(zoneName) : this.activateZone(zoneName))}
      />
    );
  };

  renderActionsMenu = (name) => {
    const { environment, selectedZone } = this.props;
    const zoneName = this.getSubDomainZoneName(name);
    const isZoneActive = selectedZone.sub_domains.includes(zoneName);

    return (
      <VCS resourceName={API_RESOURCE.CLOUDFLARE_ZONE.resourceNameMetaApi} hasOneOfMethods={['PUT']}>
        {environment.isPhone
          ? this.renderMobileActionMenu({ zoneName, isZoneActive })
          : this.renderDesktopActionMenu({ zoneName, isZoneActive })}
      </VCS>
    );
  };

  renderCNAMECheckDialog() {
    const { intl, selectedZone } = this.props;
    const { cnameDialog } = this.state;
    const domain = cnameDialog ? cnameDialog.domain : '';
    const zoneName = cnameDialog ? cnameDialog.zoneName : '';
    const selectedZoneName = selectedZone && selectedZone.name;

    return (
      <SGDialog
        id={DIALOGS.CLOUDFLARE_CNAME}
        title={intl.formatMessage({ id: 'translate.page.cloudflare.www.check.notice.title' })}
        icon="warning"
        state="warning"
        footer={
          <SGDialogCancel id={DIALOGS.CLOUDFLARE_CNAME} label={intl.formatMessage({ id: 'translate.generic.ok' })} />
        }
      >
        <Text color="dark" align="left">
          {intl.formatMessage({ id: 'translate.page.cloudflare.www.check.subdomain.notice.text' })}
        </Text>

        <br />

        {!this.isCNAMEConfigured(zoneName) && (
          <Text weight="bold" align="left">
            {intl.formatMessage(
              { id: 'translate.page.cloudflare.dialog.www.check.notice.cname.text' },
              { domain, zoneName }
            )}
          </Text>
        )}

        {!this.isCFResolveConfigured(zoneName) && (
          <Text weight="bold" align="left">
            {intl.formatMessage(
              { id: 'translate.page.cloudflare.www.check.notice.resolve.text' },
              { zoneName: selectedZoneName }
            )}
          </Text>
        )}
      </SGDialog>
    );
  }

  render() {
    const { intl, domain, mainDomain, selectedZone } = this.props;
    let subdomains = [];

    if (selectedZone && mainDomain.name === selectedZone.name) {
      subdomains = domain.filter((dmn) => dmn.main !== 1);
    }

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.name' }),
        accessor: 'name'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.status' }),
        accessor: 'name',
        render: this.renderStatusLabel
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'name',
        mSize: '200px',
        render: this.renderActionsMenu
      }
    ];

    return (
      <React.Fragment>
        <SGTable
          data={subdomains}
          columns={columns}
          mobileLayout="card-flat"
          shadow={false}
          addOffsetOnMobile
          resources={[
            { resourceName: API_RESOURCE.DOMAIN.resourceName, methods: ['GET'] },
            { resourceName: API_RESOURCE.CLOUDFLARE_ZONE.resourceName, methods: ['GET'] },
            { resourceName: API_RESOURCE.CLOUDFLARE_ZONE.resourceName, methods: ['PUT'] }
          ]}
        />

        {this.renderCNAMECheckDialog()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  environment: state.environment,
  domain: state.pageItems.domain || [],
  mainDomain: findMainDomain(state)
});

export default indexWithCRUD(
  mapStateToProps,
  { ...sgDialogActions },
  { triggerReloadOnTaskCompleted: false }
)(CloudflareSubdomains, API_RESOURCE.CLOUDFLARE_ZONE);
