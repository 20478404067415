import * as React from 'react';

import { Icon } from '@siteground/styleguide';

import './information-icon.scss';

const InformationIcon = (props) => {
  return <Icon color="dark" name="information" className="info-icon" size="12" {...props} />;
};

export default InformationIcon;
