import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ToolId } from '../../../core/constants/route-info';
import {
  BorderType,
  Button,
  Card,
  Container,
  Grid,
  Link,
  Section,
  Spacer,
  Tile,
  Toolbar
} from '@siteground/styleguide';
import { Context } from '@siteground/styleguide/lib/components/context/index';

import PageHeader from '../../components/page-header';
import { API_RESOURCE } from '../../../core/constants/api';
import { SecondLevelTitle } from '../../components/titles';
import VCS from '../../components/visibility-control-service';
import { navigateToUA } from '../../../core/actions/nemo-store';

type OwnProps = {};

type StateProps = {};

type DispatchProps = {
  navigateToUA?: typeof navigateToUA;
};

interface Props extends StateProps, DispatchProps, OwnProps {}

const GoogleWorkspacePage = ({ navigateToUA }: Props) => {
  const intl = useIntl();
  const { device } = React.useContext(Context);
  const tileBorders: BorderType = device.isPhone
    ? ['none', 'none', 'medium', 'none']
    : ['none', 'medium', 'none', 'none'];

  return (
    <div>
      <PageHeader
        id={ToolId.googleWorkspace}
        instructions={intl.formatMessage(
          { id: 'translate.page.google.workspace.instructions' },
          {
            linebreak: (
              <React.Fragment>
                <br />
                <br />
              </React.Fragment>
            ),
            strong: (text) => <strong>{text}</strong>,
            href: (url) => url,
            a: (href, text) => (
              <Link href={href} target="_blank">
                {text}
              </Link>
            )
          }
        )}
      />

      <Section>
        <VCS resourceName={API_RESOURCE.CLOUDFLARE.resourceNameMetaApi} hasMethod="GET">
          <SecondLevelTitle>
            {intl.formatMessage({ id: 'translate.page.google.workspace.section.title' })}
          </SecondLevelTitle>

          <Container padding="none">
            <Grid gap="none" sm="3" padding="responsive">
              <Tile border={tileBorders} padding="none">
                <Card
                  iconName="product-envelope"
                  iconColor="salmon"
                  title={intl.formatMessage({ id: 'translate.page.google.workspace.gmail.tile.title' })}
                  text={intl.formatMessage({ id: 'translate.page.google.workspace.gmail.tile.text' })}
                  layout="vertical"
                  outline="none"
                />
              </Tile>

              <Tile border={tileBorders} padding="none">
                <Card
                  iconName="product-link-users"
                  iconColor="ocean"
                  title={intl.formatMessage({ id: 'translate.page.google.workspace.collaboration.tile.title' })}
                  text={intl.formatMessage({ id: 'translate.page.google.workspace.collaboration.tile.text' })}
                  layout="vertical"
                  outline="none"
                />
              </Tile>

              <Tile border="none" padding="none">
                <Card
                  iconName="product-banner-promo"
                  iconColor="mint"
                  title={intl.formatMessage({ id: 'translate.page.google.workspace.discount.tile.title' })}
                  text={intl.formatMessage({ id: 'translate.page.google.workspace.discount.tile.text' })}
                  layout="vertical"
                  outline="none"
                />
              </Tile>
            </Grid>

            <Toolbar style={{ marginTop: 0 }}>
              <Spacer />
              <Button
                color="primary"
                onClick={() =>
                  navigateToUA({
                    command: 'redirect',
                    page: 'googleworkspace',
                    tracking: {
                      utm_source: 'site_tools',
                      utm_medium: 'button',
                      utm_campaign: 'google_workspace'
                    }
                  })
                }
              >
                {intl.formatMessage({ id: 'translate.page.google.workspace.discount.code.button' })}
              </Button>
            </Toolbar>
          </Container>
        </VCS>
      </Section>
    </div>
  );
};

export const GoogleWorkspace = connect<StateProps, DispatchProps, OwnProps>(undefined, { navigateToUA })(
  GoogleWorkspacePage
);
