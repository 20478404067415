import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import { getCurrentSiteId } from '../../../core/selectors';
import { Label, Spacer } from '@siteground/styleguide';
import RenderIf from './../render-if';
import './sg-link.scss';

interface SGLinkProps {
  text: any;
  label?: any;
  to: any;
  onClick?: any;
  activeClassName?: any;
  siteId?: string;
  'data-e2e'?: string;
  // for unit testing
  store?: any;
  tabIndex?: number;
}

class SGLink extends React.Component<SGLinkProps, any> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { onClick, tabIndex } = this.props;
    let to: any = {};

    if (typeof this.props.to === 'string') {
      to = { pathname: this.props.to };
      // is object
    } else {
      to = this.props.to;
    }

    if (to.query === undefined) {
      to.query = {};
    }

    // append siteId to query
    if (to.query.siteId === undefined) {
      to.query.siteId = this.props.siteId;
    }

    return (
      <Link
        to={to}
        activeClassName={this.props.activeClassName}
        onClick={onClick}
        data-e2e={this.props['data-e2e']}
        tabIndex={tabIndex}
      >
        {this.props.text}
        <RenderIf condition={this.props.label}>
          &nbsp;
          <Label className="sg-link__label" color={this.props.label?.color}>
            <FormattedMessage id={this.props.label?.text} />
          </Label>
        </RenderIf>
      </Link>
    );
  }
}

export default connect<{}, {}, SGLinkProps>((state: RootState) => ({
  siteId: getCurrentSiteId(state)
}))(withRouter(SGLink));
