import * as React from 'react';
import { Button, ButtonProps, Grid, Notice, Section, Tab, Tabs, Text } from '@siteground/styleguide';
import { navigateToUA } from '../../../core/actions/nemo-store';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import { findMainDomain, getCurrentSiteId } from '../../../core/selectors';
import { getToolMainEndpoint } from '../../../core/selectors/menu-items';
import { isACLActionEnabled } from '../../../core/selectors/site-meta-api';
import { DeleteDialog, FeatureNotAvailable } from '../../components/dialogs';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import { SGDialog, SGDialogCancel } from '../../components/sg-dialog';
import { SecondLevelTitle } from '../../components/titles';
import VCS from '../../components/visibility-control-service';
import './cloudflare.scss';
import { DOMAIN_SELECT_NAME } from './constants';
import Create from './create';
import CloudflareIpAccessControl from './ip-access-control';
import CloudflareZoneSettings from './settings';
import CloudflareSubdomains from './subdomains';
import CloudflareCannotBeActivatedDialog from './dialogs/cloudflare-cannot-be-activated';

type CloudflareProps = {
  actions?: CrudActions;
  items?: any;
  intl: Intl;
  siteId: string;
  isUAEnabled: boolean;
  cloudflare?: any;
  domainAlias?: any;
  mainDomain?: any;
  cloudflareZone: any;
  environment?: any;
  openSGDialog?: Function;
  navigateToUA?: Function;
};

type CloudflareState = {
  activeTab: string;
  selectedDomainName: string;
};

const TAB = {
  SETTINGS: 'SETTINGS',
  IP_ACCESS_CONTROL: 'IP_ACCESS_CONTROL',
  SUBDOMAINS: 'SUBDOMAINS'
};

export const getDomainPickerOptions = (props: CloudflareProps) => {
  const { items, mainDomain } = props;
  const { domainAlias = [] } = items;
  return mainDomain ? [mainDomain, ...domainAlias] : [...domainAlias];
};

class CloudflarePage extends React.Component<CloudflareProps, CloudflareState> {
  readonly state: CloudflareState = {
    activeTab: TAB.SETTINGS,
    selectedDomainName: null
  };

  static getDerivedStateFromProps(nextProps: CloudflareProps, prevState: CloudflareState) {
    if (!prevState.selectedDomainName) {
      const domainOptions = getDomainPickerOptions(nextProps);

      // If there is only one pending zone, we want to preselect it.
      if (nextProps.items.cloudflareZonePending && nextProps.items.cloudflareZonePending.length === 1) {
        return {
          selectedDomainName: nextProps.items.cloudflareZonePending[0].name
        };
      }

      return {
        selectedDomainName: domainOptions[0] ? domainOptions[0][DOMAIN_SELECT_NAME] : null
      };
    }

    return null;
  }

  getSelectedZone({ cloudflareZone }, selectedDomainName: string) {
    return selectedDomainName ? cloudflareZone.find((zone) => zone[DOMAIN_SELECT_NAME] === selectedDomainName) : null;
  }

  goToUA = () => {
    const { selectedDomainName } = this.state;
    const { siteId, isUAEnabled, openSGDialog } = this.props;

    if (!isUAEnabled) {
      return openSGDialog(DIALOGS.FEATURE_NOT_AVAILABLE);
    }

    this.props.navigateToUA({
      page: 'cloudflare', //  TODO way to share constants between projects ?
      siteId,
      domainName: selectedDomainName,
      plan: 'upgrade'
    });
  };

  openDeleteZoneDialog = () => {
    const { openSGDialog, cloudflareZone } = this.props;
    const { selectedDomainName } = this.state;
    const selectedZone = this.getSelectedZone({ cloudflareZone }, selectedDomainName);

    return selectedZone.cf_plus
      ? openSGDialog(DIALOGS.CLOUDFLARE_DEACTIVATE_PLUS_ZONE)
      : openSGDialog(DIALOGS.CLOUDFLARE_DEACTIVATE_ZONE);
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem(
      {
        ...formData,
        force_www: Number(Boolean(formData.force_www)),
        _meta: {
          notification: {
            type: 'form',
            formName: REDUX_FORM.CREATE_CLOUDFLARE_ZONE,
            success: {
              intlKey: 'translate.page.cloudflare.create.activete.zone.success.message',
              intlValues: { domain: formData.name }
            },
            error: {
              intlKey: 'translate.page.cloudflare.create.activete.zone.error.message',
              intlValues: { domain: formData.name }
            }
          }
        }
      },
      () => {
        this.props.actions.fetchItems(API_RESOURCE.CLOUDFLARE_ZONE);
        this.props.actions.fetchItems(API_RESOURCE.CLOUDFLARE_ZONE_PENDING);
      }
    );
  };

  renderDeactivateZoneDialog = () => {
    const { actions, cloudflareZone, intl } = this.props;
    const { selectedDomainName } = this.state;

    const selectedZone = this.getSelectedZone({ cloudflareZone }, selectedDomainName);

    return (
      <DeleteDialog
        id={DIALOGS.CLOUDFLARE_DEACTIVATE_ZONE}
        onSubmit={() => {
          actions.deleteItem(
            {
              _metaFields: {
                ...API_RESOURCE.CLOUDFLARE_ZONE
              },
              _meta: {
                notification: {
                  type: 'form',
                  formName: REDUX_FORM.CREATE_CLOUDFLARE_ZONE,
                  success: {
                    intlKey: 'translate.page.cloudflare.create.deactivete.zone.success.message',
                    intlValues: { domain: selectedDomainName }
                  },
                  error: {
                    intlKey: 'translate.page.cloudflare.create.deactivete.zone.error.message',
                    intlValues: { domain: selectedDomainName }
                  }
                }
              },
              itemId: selectedZone.id
            },
            () => actions.fetchItems(API_RESOURCE.CLOUDFLARE_ZONE)
          );
        }}
      >
        {intl.formatMessage(
          { id: 'translate.page.cloudflare.delete.zone.dialog.message' },
          { name: selectedDomainName }
        )}
      </DeleteDialog>
    );
  };

  renderDeactivatePlusZoneDialog = () => {
    const { intl } = this.props;

    return (
      <SGDialog
        id={DIALOGS.CLOUDFLARE_DEACTIVATE_PLUS_ZONE}
        icon="trash"
        state="inactive"
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={DIALOGS.CLOUDFLARE_DEACTIVATE_PLUS_ZONE}
              label={intl.formatMessage({ id: 'translate.generic.ok' })}
            />
          </React.Fragment>
        }
      >
        {intl.formatMessage({ id: 'translate.page.cloudflare.delete.plus.zone.dialog.message' })}
      </SGDialog>
    );
  };

  renderCloudflareItems = () => {
    const { intl, actions, cloudflareZone } = this.props;
    const { selectedDomainName } = this.state;

    return (
      <div>
        <PageHeader
          id={ToolId.cloudflare}
          instructions={
            <Text color="dark">{intl.formatMessage({ id: 'translate.page.cloudflare.landing.info' })}</Text>
          }
        />
        <Section>
          <Grid>
            <Create
              defaultSelectedDomainName={selectedDomainName}
              goToUA={this.goToUA}
              selectedZone={this.getSelectedZone({ cloudflareZone }, selectedDomainName)}
              domainPickerOptions={getDomainPickerOptions(this.props)}
              onChangeDomain={(name: string) => this.setState({ selectedDomainName: name })}
              onDeleteCloudflareZone={this.openDeleteZoneDialog}
              onSubmit={this.onCreateFormSubmit}
            />
            <VCS resourceName={API_RESOURCE.CLOUDFLARE.resourceNameMetaApi} hasMethod="GET">
              <div>
                <SecondLevelTitle>
                  {intl.formatMessage({ id: 'translate.page.cloudflare.list.title' })}
                </SecondLevelTitle>

                <div className="cloudflare-settings">
                  {this.renderTabs()}
                  {this.renderTabContent()}
                </div>
              </div>
            </VCS>
          </Grid>
        </Section>

        <FeatureNotAvailable />
        <CloudflareCannotBeActivatedDialog />

        {this.renderDeactivateZoneDialog()}
        {this.renderDeactivatePlusZoneDialog()}
      </div>
    );
  };

  renderTabs() {
    const { environment, intl, cloudflareZone } = this.props;
    const { activeTab, selectedDomainName } = this.state;
    const selectedZone = this.getSelectedZone({ cloudflareZone }, selectedDomainName);

    interface Props extends Pick<ButtonProps, 'color'> {
      [key: string]: any;
    }

    const ipRangeButtonProps: Props = {
      color: 'secondary',
      size: 'medium',
      type: 'outlined',
      'data-e2e': 'button-add-ip',
      disabled: !selectedZone,
      onClick: () =>
        this.props.openSGDialog(REDUX_FORM.CLOUDFLARE_ADD_IP_RANGE_DIALOG, {
          zone_id: selectedZone.id,
          _metaFields: {
            ...API_RESOURCE.CLOUDFLARE_FIREWALL
          },
          _meta: {
            notification: {
              type: 'generic',
              success: {
                intlKey: 'translate.page.cloudflare.add.ip.range.success.message'
              },
              error: {
                intlKey: 'translate.page.cloudflare.add.ip.range.error.message'
              }
            }
          }
        })
    };

    return (
      <div>
        <Tabs border="light">
          <Tab
            active={activeTab === TAB.SETTINGS}
            data-e2e="settings"
            onClick={() => this.setState({ activeTab: TAB.SETTINGS })}
          >
            {intl.formatMessage({ id: 'translate.page.cloudflare.tab.setting' })}
          </Tab>

          <Tab
            active={activeTab === TAB.IP_ACCESS_CONTROL}
            data-e2e="ip-access-control"
            onClick={() => this.setState({ activeTab: TAB.IP_ACCESS_CONTROL })}
          >
            {intl.formatMessage({ id: 'translate.page.cloudflare.tab.ip.access.control' })}
          </Tab>

          <Tab
            active={activeTab === TAB.SUBDOMAINS}
            data-e2e="subdomains"
            onClick={() => this.setState({ activeTab: TAB.SUBDOMAINS })}
          >
            {intl.formatMessage({ id: 'translate.page.cloudflare.tab.subdomains' })}
          </Tab>
        </Tabs>

        {activeTab === TAB.IP_ACCESS_CONTROL && (
          <Grid padding={environment.isPhone ? ['medium', 'medium', 'none', 'medium'] : 'responsive'}>
            <div>
              <Button {...ipRangeButtonProps}>
                {intl.formatMessage({ id: 'translate.page.cloudflare.add.ip.range' })}
              </Button>
            </div>
          </Grid>
        )}
      </div>
    );
  }

  renderTabContent() {
    const { activeTab, selectedDomainName } = this.state;
    const { cloudflareZone } = this.props;
    const selectedZone = this.getSelectedZone({ cloudflareZone }, selectedDomainName);

    switch (activeTab) {
      case TAB.SETTINGS:
        return <CloudflareZoneSettings selectedZone={selectedZone} goToUA={this.goToUA} />;
      case TAB.IP_ACCESS_CONTROL:
        return <CloudflareIpAccessControl selectedZone={selectedZone} />;
      case TAB.SUBDOMAINS:
        return <CloudflareSubdomains selectedZone={selectedZone} />;
      default:
        return null;
    }
  }

  render() {
    return this.renderCloudflareItems();
  }

  componentDidUpdate(prevProps: CloudflareProps, prevState: CloudflareState) {
    // Handle selected Zone update
    const prevSelectedZone = this.getSelectedZone(prevProps, prevState.selectedDomainName);
    const currentSelectedZone = this.getSelectedZone(this.props, this.state.selectedDomainName);

    if (currentSelectedZone && prevSelectedZone !== currentSelectedZone) {
      this.props.actions.fetchItems({
        ...API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS,
        urlParams: {
          zone_id: currentSelectedZone.id
        }
      });
    }
  }
}

const mapStateToProps = (state) => ({
  environment: state.environment,
  cloudflareZone: state.pageItems.cloudflareZone || [],
  domainAlias: state.pageItems.domainAlias || [],
  mainDomain: findMainDomain(state),
  siteId: getCurrentSiteId(state),
  isUAEnabled: isACLActionEnabled(state, getToolMainEndpoint(ToolId.cloudflare), 'can_order')
});

export default indexWithCRUD(mapStateToProps, { ...sgDialogActions, navigateToUA })(CloudflarePage);
