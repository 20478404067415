import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Grid, Link, Text, Title } from '@siteground/styleguide';
// eslint-disable-next-line no-duplicate-imports
import { useEffect, useState } from 'react';
import { RootState } from '../../../reducers';
import CreateBox from '../../../components/create-box/create-box';
import { REDUX_FORM } from '../../../../core/constants/common';
import indexWithCRUD from '../../../components/indexWithCRUD';
import { API_RESOURCE } from '../../../../core/constants/api';
import { createNotification } from '../../../../core/actions/notifications';

import SGImageWithPreview from '../../../components/sg-image-with-preview';

import './ukraine-section.scss';
import VCS from '../../../components/visibility-control-service';
import { decodeJWT } from '@siteground/styleguide/lib/utils';
import { getCurrentSiteToken } from '../../../../core/selectors';

const imgPath = process.env.IMG_PATH;

interface IGeoIp {
  block: number;
  country: string;
  domain_id: string;
  domain_name: string;
  id: number;
  label: string;
  location_id: number;
  path: string;
}

type Props = {
  actions: any;
  domain: string;
  createNotification: any;
  geoIps: IGeoIp[];
  siteToken: any;
};

const HIDE_UKRAINE_SECTION_LC_KEY = 'spanel_hide_dashboard_ukraine_section';
const HIDE_SECTION_FOR_DOMAINS = JSON.parse(localStorage.getItem(HIDE_UKRAINE_SECTION_LC_KEY)) || [];

const UkraineSection = ({ domain, actions, createNotification, geoIps, siteToken }: Props) => {
  const [hideSectionDomains, setHideSectionDomains] = useState(HIDE_SECTION_FOR_DOMAINS);
  const [geoIpId, setGeoIpId] = useState(null);
  const isDeactivated = hideSectionDomains.includes(domain);
  const intl = useIntl();

  const decodeToken = siteToken && decodeJWT(siteToken);
  const isOwner = decodeToken && decodeToken.roles.includes(1);

  useEffect(() => {
    const geoIpId = geoIps?.find((geoIp) => geoIp.domain_name === domain)?.id;
    setGeoIpId(geoIpId);
  }, [geoIps]);

  useEffect(() => {
    actions.fetchItems({
      _metaFields: {
        ...API_RESOURCE.GEOIP
      },
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.UKRAINE,
          error: {
            intlKey: 'translate.page.dashboard.stand.with.ukraine.fetch-geos.error.message',
            intlValues: { domain: domain }
          }
        }
      }
    });
  }, [actions]);

  const onActivate = () => {
    actions.createItem({
      _metaFields: {
        ...API_RESOURCE.GEOIP
      },
      domain_name: domain,
      country: 'ru',
      path: '/',
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.UKRAINE,
          success: {
            intlKey: 'translate.page.dashboard.stand.with.ukraine.created-geos.success.message',
            intlValues: { domain: domain }
          },
          error: {
            intlKey: 'translate.page.dashboard.stand.with.ukraine.created-geos.error.message',
            intlValues: { domain: domain }
          }
        }
      }
    });
  };

  const onDeactivate = () => {
    actions.deleteItem({
      _metaFields: {
        ...API_RESOURCE.GEOIP
      },
      itemId: geoIpId,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.UKRAINE,
          success: {
            intlKey: 'translate.page.dashboard.stand.with.ukraine.removed-geos.success.message',
            intlValues: { domain: domain }
          },
          error: {
            intlKey: 'translate.page.dashboard.stand.with.ukraine.removed-geos.error.message',
            intlValues: { domain: domain }
          }
        }
      }
    });
  };

  const onRemove = () => {
    const newDomainsToHideTheSectionFor = [...hideSectionDomains, domain];
    setHideSectionDomains(newDomainsToHideTheSectionFor);
    localStorage.setItem(HIDE_UKRAINE_SECTION_LC_KEY, JSON.stringify(newDomainsToHideTheSectionFor));

    createNotification({
      type: 'generic',
      state: 'success',
      success: {
        intlKey: 'translate.page.dashboard.stand.with.ukraine.hidden-section.success.message',
        intlValues: { domain: domain }
      }
    });
  };

  if (isDeactivated || !isOwner) {
    return null;
  }

  return (
    <VCS resourceName={API_RESOURCE.GEOIP.resourceName} hasMethod="GET">
      <CreateBox
        hideTutorials
        resourceName={API_RESOURCE.GEOIP.resourceName}
        formName={REDUX_FORM.UKRAINE}
        resources={[
          {
            resourceName: API_RESOURCE.GEOIP.resourceName,
            methods: ['GET', 'POST', 'DELETE']
          }
        ]}
      >
        <Grid padding="responsive" gap="responsive" className="dashboard-ukraine-card-grid">
          <SGImageWithPreview />
          <Grid padding="none" gap="responsive">
            <Title level="3" density="none">
              {intl.formatMessage({ id: 'translate.page.dashboard.stand.with.ukraine.section.title' })}
            </Title>
            <Text>
              <FormattedMessage
                id={
                  geoIpId
                    ? 'translate.page.dashboard.stand.with.ukraine.active.section.text'
                    : 'translate.page.dashboard.stand.with.ukraine.section.text'
                }
                values={{
                  a: (text) => <Link onClick={onRemove}>{text}</Link>,
                  linebreak: (
                    <React.Fragment>
                      <br />
                      <br />
                    </React.Fragment>
                  )
                }}
              />
            </Text>
            <div>
              {geoIpId && (
                <Button type="outlined" color="primary" onClick={onDeactivate}>
                  {intl.formatMessage({ id: 'translate.generic.deactivate' })}
                </Button>
              )}

              {!geoIpId && (
                <Button color="primary" onClick={onActivate}>
                  {intl.formatMessage({ id: 'translate.generic.activate' })}
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </CreateBox>
    </VCS>
  );
};

const mapStateToProps = (state: RootState) => ({
  domain: state.sites.currentDomainName,
  geoIps: state.pageItems['location-geoip'],
  siteToken: getCurrentSiteToken(state)
});

export default indexWithCRUD(mapStateToProps, { createNotification })(UkraineSection, API_RESOURCE.GEOIP);
