export const formatCheckboxesData = (checkboxes: any[]) => {
  const filterData = checkboxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.label);

  if (filterData.length === checkboxes.length) {
    return {
      all: 1
    };
  }

  return {
    perms: filterData
  };
};
