import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Text } from '@siteground/styleguide';
import { LoginCard, LoginIcon } from '@siteground/styleguide/lib/composite';
import LoginFooter from './login-footer';

import withResellerLoginPage from './with-reseller-login-page';

type Props = {
  intl: Intl;
  router: {
    goBack: any;
  };
};

class Login extends React.Component<Props> {
  render() {
    const { intl, router } = this.props;

    return (
      <LoginCard
        title={intl.formatMessage({ id: 'translate.reseller-login.site-panel.recover-password.link' })}
        data-e2e="recover-password"
        handleBack={router.goBack}
        afterCardContent={<LoginFooter intl={intl} />}
      >
        <LoginIcon icon="mail" size="40" />

        <Text padding={['small', 'none']}>
          {intl.formatMessage({ id: 'translate.reseller-login.site-panel.recover-password.text' })}
        </Text>
      </LoginCard>
    );
  }
}

export default withResellerLoginPage(injectIntl(Login));
