import { REQUEST_DATA, REQUEST_NEMO_DATA } from '../constants/actions';
import customRequestTypes from '../constants/custom-request-types';

export const requestData: RequestData = ({ payload, requestTypeName }, onComplete = null, onFailure = null) => ({
  type: REQUEST_DATA,
  payload,
  onComplete,
  onFailure,
  requestTypeName: requestTypeName ? requestTypeName : customRequestTypes.REQUEST_DATA
});

export const requestNemoData: RequestNemoData = (
  { endpoint, method = 'GET', body = {}, notification, requestTypeName, itemId },
  onComplete = null,
  onFailure = null
) => ({
  type: REQUEST_NEMO_DATA,
  payload: {
    endpoint,
    method,
    body,
    itemId
  },
  notification,
  onComplete,
  onFailure,
  requestTypeName
});
