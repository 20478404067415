export const APP_TYPES = {
  WORDPRESS: 'wordpress',
  JOOMLA: 'joomla',
  DRUPAL: 'drupal',
  WEEBLY: 'weebly_sitebuilder',
  WOO_COMMERCE: 'woocommerce',
  PRESTASHOP: 'prestashop',
  OPEN_CART: 'opencart',
  PHP_BB: 'phpbb',
  MEDIAWIKI: 'mediawiki',
  MOODLE: 'moodle',
  OS_TICKET: 'osticket',
  V_TIGER: 'vtiger',
  LIME_SURVEY: 'limesurvey'
};

export const APP_TYPES_INFO_TEXT_BY_ID = {
  [APP_TYPES.WORDPRESS]: 'translate.page.app-manager.app-type-wordpress.text',
  [APP_TYPES.JOOMLA]: 'translate.page.app-manager.app-type-joomla.text',
  [APP_TYPES.DRUPAL]: 'translate.page.app-manager.app-type-drupal.text',
  [APP_TYPES.WEEBLY]: 'translate.page.app-manager.app-type-weebly.text',
  [APP_TYPES.WOO_COMMERCE]: 'translate.page.app-manager.app-type-woocommerce.text',
  [APP_TYPES.PRESTASHOP]: 'translate.page.app-manager.app-type-prestashop.text',
  [APP_TYPES.OPEN_CART]: 'translate.page.app-manager.app-type-opencart.text',
  [APP_TYPES.PHP_BB]: 'translate.page.app-manager.app-type-phpbb.text',
  [APP_TYPES.MEDIAWIKI]: 'translate.page.app-manager.app-type-mediawiki.text',
  [APP_TYPES.MOODLE]: 'translate.page.app-manager.app-type-moodle.text',
  [APP_TYPES.OS_TICKET]: 'translate.page.app-manager.app-type-osticket.text',
  [APP_TYPES.V_TIGER]: 'translate.page.app-manager.app-type-vtiger.text',
  [APP_TYPES.LIME_SURVEY]: 'translate.page.app-manager.app-type-limesurvey.text'
};

export const APP_ICONS_BY_TYPE = {
  [APP_TYPES.WORDPRESS]: 'logo-wordpress',
  [APP_TYPES.JOOMLA]: 'logo-joomla',
  [APP_TYPES.DRUPAL]: 'logo-drupal',
  [APP_TYPES.WEEBLY]: 'logo-weebly',
  [APP_TYPES.WOO_COMMERCE]: 'logo-woocommerce',
  [APP_TYPES.PRESTASHOP]: 'logo-prestashop',
  [APP_TYPES.OPEN_CART]: 'logo-opencart',
  [APP_TYPES.PHP_BB]: 'logo-phpbb',
  [APP_TYPES.MEDIAWIKI]: 'logo-mediawiki',
  [APP_TYPES.MOODLE]: 'logo-moodle',
  [APP_TYPES.OS_TICKET]: 'logo-osticket',
  [APP_TYPES.V_TIGER]: 'logo-vtiger',
  [APP_TYPES.LIME_SURVEY]: 'logo-limesurvey',
  presta: 'logo-prestashop'
};

export const APP_TYPES_LABELS_BY_ID = {
  [APP_TYPES.WORDPRESS]: 'translate.page.app-manager-app.wordpress',
  [APP_TYPES.JOOMLA]: 'translate.page.app-manager-app.joomla',
  [APP_TYPES.DRUPAL]: 'translate.page.app-manager-app.drupal',
  [APP_TYPES.WEEBLY]: 'translate.page.app-manager-app.weebly',
  [APP_TYPES.WOO_COMMERCE]: 'translate.page.app-manager-app.wordpress-and-woo',
  [APP_TYPES.PRESTASHOP]: 'translate.page.app-manager-app.prestashop',
  [APP_TYPES.OPEN_CART]: 'translate.page.app-manager.app-opencart',
  [APP_TYPES.PHP_BB]: 'translate.page.app-manager.app-phpbb',
  [APP_TYPES.MEDIAWIKI]: 'translate.page.app-manager.app-mediawiki',
  [APP_TYPES.MOODLE]: 'translate.page.app-manager.app-moodle',
  [APP_TYPES.OS_TICKET]: 'translate.page.app-manager.app-osticket',
  [APP_TYPES.V_TIGER]: 'translate.page.app-manager.app-vtiger',
  [APP_TYPES.LIME_SURVEY]: 'translate.page.app-manager.app-limesurvey',
  presta: 'translate.page.app-manager-app.prestashop'
};

export const APPS_LIST = [
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.WORDPRESS],
    id: 'wordpress',
    group: 'translate.page.app-manager-app-group-blogs-cms.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.JOOMLA],
    id: APP_TYPES.JOOMLA,
    group: 'translate.page.app-manager-app-group-blogs-cms.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.DRUPAL],
    id: APP_TYPES.DRUPAL,
    group: 'translate.page.app-manager-app-group-blogs-cms.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.WEEBLY],
    id: APP_TYPES.WEEBLY,
    group: 'translate.page.app-manager-app-group-blogs-cms.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.WOO_COMMERCE],
    id: APP_TYPES.WOO_COMMERCE,
    group: 'translate.page.app-manager-app-shopping-carts.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.PRESTASHOP],
    id: APP_TYPES.PRESTASHOP,
    group: 'translate.page.app-manager-app-shopping-carts.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.OPEN_CART],
    id: APP_TYPES.OPEN_CART,
    group: 'translate.page.app-manager-app-shopping-carts.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.PHP_BB],
    id: APP_TYPES.PHP_BB,
    group: 'translate.page.app-manager-app-forums.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.MEDIAWIKI],
    id: APP_TYPES.MEDIAWIKI,
    group: 'translate.page.app-manager-app-wiki-education.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.MOODLE],
    id: APP_TYPES.MOODLE,
    group: 'translate.page.app-manager-app-wiki-education.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.OS_TICKET],
    id: APP_TYPES.OS_TICKET,
    group: 'translate.page.app-manager-app-help-desk.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.V_TIGER],
    id: APP_TYPES.V_TIGER,
    group: 'translate.page.app-manager-app-crm.label'
  },
  {
    label: APP_TYPES_LABELS_BY_ID[APP_TYPES.LIME_SURVEY],
    id: APP_TYPES.LIME_SURVEY,
    group: 'translate.page.app-manager-app-other.label'
  }
];
