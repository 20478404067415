import { call, put, select, takeEvery } from 'redux-saga/effects';
import { siteApi } from '../../api/site';
import * as Actions from '../../constants/actions';
import { canDoCRUD } from '../../utils/can-do-crud';
import handleAvalonApiRequest from '../handle-avalon-api-request';

function* fetchItemsSaga(action: FetchItemsAction) {
  const state = yield select();
  const { resourceName, resourceNameMetaApi, endpoint, urlParams } = action.payload;

  const canDoFetch = canDoCRUD({ siteMetaApi: state.siteMetaApi, resourceNameMetaApi, method: 'GET' });

  if (!canDoFetch) {
    return;
  }

  const response = yield call(
    siteApi({
      endpoint,
      method: 'GET',
      body: { _metaResourceName: resourceName },
      state,
      urlParams
    })
  );

  yield put({ type: Actions.FETCH_ITEMS_SUCCEEDED, items: response.data, resourceName });

  return response;
}

function* fetchItems(): any {
  yield takeEvery(Actions.FETCH_ITEMS_REQUESTED, handleAvalonApiRequest(fetchItemsSaga));
}

export default fetchItems;
