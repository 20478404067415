import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, SizeType, DialogDensityType } from '@siteground/styleguide';
import { closeSGDialog } from '../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../sg-dialog';

type Props = {
  id?: string;
  title?: string;
  density?: DialogDensityType;
  icon?: string;
  size?: SizeType;
  children?: any;
  onSubmit: Function;
  closeSGDialog?: (id) => void;
};

const DeleteDialog = ({
  children,
  density,
  closeSGDialog,
  icon = 'trash',
  size,
  onSubmit,
  title,
  id = DIALOGS.GENERIC_DELETE
}: Props) => {
  const intl = useIntl();
  return (
    <SGDialog
      id={id}
      icon={icon}
      density={density}
      title={title}
      size={size}
      state="inactive"
      footer={
        <React.Fragment>
          <SGDialogCancel id={id} />

          <Button
            data-e2e="dialog-submit"
            color="primary"
            onClick={() => {
              onSubmit();
              closeSGDialog(id);
            }}
          >
            {intl.formatMessage({ id: 'translate.generic.confirm' })}
          </Button>
        </React.Fragment>
      }
    >
      {children || intl.formatMessage({ id: 'translate.conformation.dialog.message' })}
    </SGDialog>
  );
};

export default connect<{}, {}, Props>(undefined, (dispatch) => ({
  closeSGDialog: (id) => dispatch(closeSGDialog(id))
}))(DeleteDialog);
