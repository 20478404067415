import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, FieldArray, reduxForm } from 'redux-form';
import {
  Column,
  Context,
  ContextMenu,
  ContextMenuItem,
  Flex,
  Grid,
  IconButton,
  Input,
  Spacer,
  Text
} from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  validationUtils: ValidationUtils;
  info: string;
  fieldName: string;
  fieldLabel: string;
};

class UpdateForm extends React.Component<Props, any> {
  renderEntityRow = ({ fields }) => {
    const { intl, fieldLabel, validationUtils } = this.props;

    return fields.map((name, index, array) => {
      return (
        <Column key={index} smSpan="12">
          <Flex wrap="nowrap" align="flex-end" key={`left-${index}`}>
            <Field
              name={name}
              label={index === 0 && fieldLabel}
              type="text"
              validate={[validationUtils.validationWithMetaApi]}
              component={FormInput}
            />

            <Spacer size="x-small" />

            <Context.Consumer>
              {({ device }) =>
                device.isPhone ? (
                  <React.Fragment>
                    <ContextMenu
                      opener={
                        <IconButton
                          aria-label={intl.formatMessage({ id: 'translate.aria-label.actions' })}
                          shape="circle"
                          icon="dots"
                        />
                      }
                    >
                      <ContextMenuItem icon="circle-plus" onClick={() => fields.insert(index + 1, '')}>
                        {intl.formatMessage({ id: 'translate.page.email.auth.manage.ip.add.new' })}
                      </ContextMenuItem>

                      <ContextMenuItem
                        icon="circle-minus"
                        onClick={() => {
                          if (index === 0 && array.length === 1) {
                            fields.insert(index + 1, '');
                          }

                          fields.remove(index);
                        }}
                      >
                        {intl.formatMessage({ id: 'translate.page.email.auth.manage.ip.remove' })}
                      </ContextMenuItem>
                    </ContextMenu>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <IconButton
                      aria-label={intl.formatMessage({ id: 'translate.aria-label.minus' })}
                      icon="circle-minus"
                      shape="circle"
                      data-e2e="remove-field"
                      onClick={() => {
                        if (index === 0 && array.length === 1) {
                          fields.insert(index + 1, '');
                        }

                        fields.remove(index);
                      }}
                    />

                    <IconButton
                      aria-label={intl.formatMessage({ id: 'translate.aria-label.plus' })}
                      icon="circle-plus"
                      shape="circle"
                      data-e2e="add-field"
                      onClick={() => fields.insert(index + 1, '')}
                    />
                  </React.Fragment>
                )
              }
            </Context.Consumer>
          </Flex>
        </Column>
      );
    });
  };

  render() {
    const { fieldName, info } = this.props;
    return (
      <Grid>
        <Column smSpan="12">
          <Text>{info}</Text>
        </Column>

        <FieldArray name={fieldName} component={this.renderEntityRow} />
      </Grid>
    );
  }
}

const formToExport = (form) => withSpanelForm(reduxForm({ form })(injectIntl(UpdateForm)));

export const UpdateApprovedHostsForm = formToExport(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_HOSTS);
export const UpdateApprovedMXRecordsForm = formToExport(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_MX_RECORDS);
export const UpdateApprovedIPBlocksForm = formToExport(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_IP_BLOCKS);
export const UpdateApprovedIncludeListForm = formToExport(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_INCLUDE_LIST);
