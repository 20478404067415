import * as React from 'react';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Grid } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../components/sg-spanel-forms';
import CronForm from '../cron-form';

type Props = {
  initialInterval: string;
  validationUtils: ValidationUtils;
  form: string;
  pristine: boolean;
  submitFailed: boolean;
  change: (field: string, value: string) => void;
  formErrors: (formName: string) => object;
  intl: Intl;
};

const formName = REDUX_FORM.CHANGE_CRON_DIALOG;

export const Form = ({
  initialInterval = '',
  validationUtils,
  intl,
  formErrors,
  submitFailed,
  pristine,
  change
}: Props) => (
  <Grid>
    <CronForm
      initialInterval={initialInterval}
      validationUtils={validationUtils}
      intl={intl}
      formName={formName}
      formErrors={formErrors}
      submitFailed={submitFailed}
      pristine={pristine}
      change={change}
      fieldsExpanded
    />
  </Grid>
);

const UpdateCronJob = reduxForm({ form: formName })(Form);

export default withSpanelForm(injectIntl(UpdateCronJob));
