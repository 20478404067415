import { reset } from 'redux-form';
import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { httpRequestFailed, httpRequestStarted, httpRequestSucceeded } from '../../actions/fetch';
import { CheckOrderStatus, OrderStatus } from '../../actions/nemo/types';
import { REDUX_FORM } from '../../constants/common';
import { handleNemoApiRequest } from '../handle-nemo-api-request';
import { getNemoData } from './get-nemo-data';
import { getOrderStatusRequest } from './requests';
import { ItemActivations, WeeblyOrderStatusResponse } from './types';

const MAX_NUMBER_OF_TRIES = 5;

function weeblyActivationSucceeds(orderActivations: ItemActivations[]) {
  return orderActivations.every(({ status }) => status === OrderStatus.Successful);
}

export function* checkOrderStatus(action: CheckOrderStatus) {
  let counter = 0;
  let orderStatus: WeeblyOrderStatusResponse = null;
  const { orderId, getInstalledExtras, clearState } = action.payload;

  yield put(httpRequestStarted(action));

  while (counter < MAX_NUMBER_OF_TRIES) {
    orderStatus = yield call(
      handleNemoApiRequest(getNemoData, {
        type: action.type,
        ...getOrderStatusRequest({ orderId })
      }),
      getOrderStatusRequest({ orderId })
    );

    yield delay(3000);

    if (weeblyActivationSucceeds(orderStatus.data.order_item_activations)) {
      getInstalledExtras();
      yield put(reset(REDUX_FORM.CREATE_APP_INSTALL));
      clearState();

      yield put(httpRequestSucceeded(action));

      return;
    }

    counter += 1;
  }

  yield put(httpRequestFailed(action));
}
