import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as Actions from '../../constants/actions';
import { siteApi } from '../../api/site';
import handleAvalonApiRequest from '../handle-avalon-api-request';
import { fetchArray } from './update-item';

export function* createItemSaga({ payload }: CreateItemAction) {
  const state = yield select();
  const {
    resourceName,
    endpoint,
    dontChangeItemsState,
    fetchItemsOnSuccess = [],
    parentResourceName
  } = payload._metaFields;

  const method = payload._metaFields.method || 'POST';
  const addIdFromMeta = payload._metaFields.id ? '/' + payload._metaFields.id : '';

  const response = yield call(
    siteApi({
      endpoint: endpoint + addIdFromMeta,
      method,
      body: {
        ...payload // includes _meta
      },
      state
    })
  );

  yield fetchArray(fetchItemsOnSuccess);

  yield put({
    type: Actions.CREATE_ITEM_SUCCEEDED,
    item: response.data,
    resourceName,
    dontChangeItemsState,
    parentResourceName
  });

  return response;
}

function* createItem(): any {
  yield takeEvery(Actions.CREATE_ITEM_REQUESTED, handleAvalonApiRequest(createItemSaga));
}

export default createItem;
