import { Flex, Link, Section, Switch, Text, Title } from '@siteground/styleguide';
import * as React from 'react';
import { API_RESOURCE } from '../../../core/constants/api';
import { ToolId } from '../../../core/constants/route-info';
import { ROUTES } from '../../../core/constants/routes';
// COMPONENTS
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import VCS from '../../components/visibility-control-service';
import { RootState } from '../../reducers';

type FlagValue = 0 | 1 | number;

type Domain = {
  id: string;
  name: string;
  main: FlagValue;
  cert_id?: number;
  cert_wild_id?: number;
  settings: {
    https_rewrite_links: FlagValue;
    https_enforce: FlagValue;
  };
};

type Props = {
  items: {
    domainAll: Domain[];
  };
  actions: {
    updateItem: UpdateItem;
  };
  environment: {
    isPhone: boolean;
  };
  intl: Intl;
  location: {
    search: string;
  };
  router: {
    push: Function;
  };
};

type State = {
  openedParkedDomainsList: boolean;
};

const DOMAIN_HTTPS_ENFORCE_SETTINGS = 'DOMAIN_HTTPS_ENFORCE_SETTINGS';

// 0 -> 1, 1 -> 0
const invertFlagValue = (value: FlagValue): FlagValue => Number(!Boolean(value));

export const isDomainHavingSSL = ({ cert_id, cert_wild_id }: Partial<Domain>) => Boolean(cert_id || cert_wild_id);

class HttpsEnforce extends React.Component<Props, State> {
  readonly state = {
    openedParkedDomainsList: false
  };

  updateSettings = ({ id, name }, { https_enforce, https_rewrite_links }, { successMessageKey, errorMessageKey }) =>
    this.props.actions.updateItem({
      id,
      settings: { https_enforce, https_rewrite_links },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: successMessageKey,
            intlValues: { domain: name }
          },
          error: {
            intlKey: errorMessageKey,
            intlValues: { domain: name }
          }
        }
      },
      _metaFields: { ...API_RESOURCE.DOMAIN_ALL }
    });

  renderHttpEnforceColumn = (
    { https_enforce, https_rewrite_links }: Domain['settings'],
    { id, name, cert_id, cert_wild_id }: Domain
  ) => {
    const { intl, router, location } = this.props;

    if (isDomainHavingSSL({ cert_id, cert_wild_id })) {
      return (
        <Switch
          disabled={!isDomainHavingSSL({ cert_id, cert_wild_id })}
          checked={Boolean(https_enforce)}
          data-e2e={`https-enforce-switch-https-${id}`}
          onChange={() =>
            this.updateSettings(
              {
                id,
                name
              },
              {
                https_enforce: invertFlagValue(https_enforce),
                // https_rewrite_links cannot be 1 if https_enforce is 0 (1 && 0 -> 0)
                https_rewrite_links: https_rewrite_links && invertFlagValue(https_enforce)
              },
              {
                successMessageKey: Boolean(https_enforce)
                  ? 'translate.page.https-enforce.table.https-enforce-disable.success'
                  : 'translate.page.https-enforce.table.https-enforce-enable.success',
                errorMessageKey: Boolean(https_enforce)
                  ? 'translate.page.https-enforce.table.https-enforce-disable.fail'
                  : 'translate.page.https-enforce.table.https-enforce-enable.fail'
              }
            )
          }
        />
      );
    }

    return (
      <Link onClick={() => router.push(`${ROUTES[ToolId.ssl]}${location.search}`)}>
        {intl.formatMessage({ id: 'translate.page.https-enforce.ssl.table.link.label' })}
      </Link>
    );
  };

  render() {
    const { intl, environment } = this.props;
    const { domainAll } = this.props.items;
    const switchColumnsStyles = !environment.isPhone ? { width: '180px' } : null;

    return (
      <React.Fragment>
        <PageHeader
          id={ToolId.httpsEnforce}
          instructions={intl.formatMessage({ id: 'translate.page.https-enforce.instructions' })}
        />
        <VCS resourceName={API_RESOURCE.DOMAIN.resourceNameMetaApi} hasMethods={['GET', 'PUT']}>
          <Section>
            <SGTable
              title={intl.formatMessage({ id: 'translate.page.https-enforce.table.title' })}
              data={domainAll}
              resources={[
                {
                  resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
                  methods: ['GET']
                }
              ]}
              rowResources={[
                {
                  resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
                  methods: ['PUT']
                }
              ]}
              columns={[
                {
                  header: intl.formatMessage({ id: 'translate.page.https-enforce.table.header.domain' }),
                  accessor: 'name',
                  render: (name: string) => (
                    <VCS resourceName={API_RESOURCE.DOMAIN_ALL.resourceNameMetaApi} hasMethod="GET">
                      <Flex wrap="nowrap">
                        {environment.isPhone ? (
                          <Title level="4" density="none" truncate>
                            {name}
                          </Title>
                        ) : (
                          <Text truncate>{name}</Text>
                        )}
                      </Flex>
                    </VCS>
                  )
                },
                {
                  header: intl.formatMessage({ id: 'translate.page.https-enforce.table.header.https-enforce' }),
                  accessor: 'settings',
                  style: switchColumnsStyles,
                  render: this.renderHttpEnforceColumn
                },
                /* (Removed external links rewrite option for now) {
                header: intl.formatMessage({ id: 'translate.page.https-enforce.table.header.external-links-rewrite' }),
                accessor: 'settings',
                style: switchColumnsStyles,
                render: (
                  { https_enforce, https_rewrite_links }: Domain['settings'],
                  { id, name, cert_id, cert_wild_id }: Domain
                ) => (
                  <Switch
                    disabled={Boolean(!isDomainHavingSSL({ cert_id, cert_wild_id })) || !Boolean(https_enforce)}
                    checked={Boolean(https_rewrite_links)}
                    data-e2e={`https-enforce-switch-external-link-${id}`}
                    onChange={() => this.updateSettings({
                      id,
                      name
                    }, {
                      https_enforce,
                      https_rewrite_links: invertFlagValue(https_rewrite_links)
                    }, {
                      successMessageKey: Boolean(https_rewrite_links) ?
                        'translate.page.https-enforce.table.external-links-rewrite-disable.success' :
                        'translate.page.https-enforce.table.external-links-rewrite-enable.success',
                      errorMessageKey: Boolean(https_rewrite_links) ?
                        'translate.page.https-enforce.table.external-links-rewrite-disable.fail' :
                        'translate.page.https-enforce.table.external-links-rewrite-enable.fail'
                    })}
                  />
                )
              }, */ {
                  header: '',
                  acceessor: 'settings',
                  style: {
                    width: 0
                  }
                }
              ]}
            />
          </Section>
        </VCS>
      </React.Fragment>
    );
  }
}

export default indexWithCRUD(({ environment }: RootState) => ({ environment }))(HttpsEnforce, API_RESOURCE.DOMAIN_ALL);
