import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';
import { useIntl } from 'react-intl';

import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';

import FormDropdown from '../../../components/form-dropdown';
import FormInput from '../../../components/form-input';

type Props = {
  apps: WordpressApp[];
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => any;
};

const formName = REDUX_FORM.WP_REPLACE;

const Form: React.FC<Props> = ({ apps, validationUtils, sPanelFormSubmit }) => {
  const intl = useIntl();
  const { required } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="12">
        <Field
          name="id"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.wp.replace.select.label' })}
          searchable
          optionValue="id"
          optionLabel="app_url"
          options={apps}
          validate={[required]}
          component={FormDropdown}
        />
      </Column>
      <Column smSpan="6">
        <Field
          name="search"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.wp.replace.from.label' })}
          component={FormInput}
          validate={[required]}
        />
      </Column>
      <Column smSpan="6">
        <Field
          name="replace"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.wp.replace.to.label' })}
          component={FormInput}
          validate={[required]}
        />
      </Column>
      <Column smSpan="12">
        <SGSubmitButton
          formName={formName}
          label={intl.formatMessage({ id: 'translate.page.wp.replace.create.title' })}
        />
      </Column>
    </SGForm>
  );
};

const WpStagingCreate = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.WORDPRESS,
      formName
    }
  }
})(Form);

export default withSpanelForm(WpStagingCreate);
