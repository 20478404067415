import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Text } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

type Props = {
  children: any;
  hostname: string;
  port: string;
};

const { resourceName } = API_RESOURCE.FTP;
const formName = REDUX_FORM.CREATE_FTP;

const CreateContainer = ({ children, hostname, port }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceName} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.ftp.create.title' })}
        formName={formName}
        resourceName={resourceName}
        resources={[
          {
            resourceName: API_RESOURCE.FTP.resourceName,
            methods: ['POST']
          }
        ]}
        renderTutorials={({ DefaultRender, textProps }) => (
          <DefaultRender
            title={intl.formatMessage({ id: 'translate.tips_and_tutorials.ftp.title' })}
            text={
              <FormattedMessage
                id="translate.tips_and_tutorials.ftp.content"
                values={{
                  link: (
                    <Link href="https://filezilla-project.org" target="_blank">
                      FileZilla
                    </Link>
                  )
                }}
              />
            }
          >
            <div>
              <Text {...textProps}>
                <Text {...textProps} tag="span" weight="extra-bold">
                  {intl.formatMessage({ id: 'translate.page.ftp.hostname' })}:&nbsp;
                </Text>
                {hostname}
              </Text>

              <Text {...textProps} tag="span" weight="extra-bold">
                {intl.formatMessage({ id: 'translate.page.ftp.port' })}:&nbsp;
                {port}
              </Text>
            </div>
          </DefaultRender>
        )}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default CreateContainer;
