import { call, put, select, takeEvery } from 'redux-saga/effects';
import { createNotification } from '../../../../core/actions/notifications';
import { openSGDialog } from '../../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';
import handleAvalonApiRequest from '../../../../core/sagas/handle-avalon-api-request';
import { fileManagerPostRequest, markForCopy, paste } from '../actions/file-manager';
import {
  FILE_MANAGER_COPY,
  FILE_MANAGER_PASTE,
  FILE_MANAGER_REQUEST_PASTE,
  FILE_MANGER_API_DIR_COPY
} from '../constants/actions';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../constants/common';
import { getPrioritizedEntities } from '../selectors/get-prioritized-entities';
import {
  getEntityParentPath,
  getEntityPath,
  getEntityReadableName,
  getEntityReadableNameByPath,
  getEntityType
} from '../utils';
import { loadExistingFilesData } from './check-for-existing-files';

function* getDataForCopy() {
  const { fileManager } = yield select();
  const prioritizedEntity = getPrioritizedEntities(fileManager)[0];
  const isEntityFile = getEntityType(prioritizedEntity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
  const dest = isEntityFile ? getEntityParentPath(prioritizedEntity) : getEntityPath(prioritizedEntity);

  const entries = fileManager.markedForCopy.map((entity) => getEntityPath(entity));

  return {
    dest,
    entries,
    entities: fileManager.markedForCopy
  };
}

function* copyEntities() {
  const { fileManager } = yield select();
  const entities = getPrioritizedEntities(fileManager);

  yield put(markForCopy(entities));

  yield put(
    createNotification({
      type: 'generic',
      state: 'success',
      success: {
        intlKey: 'translate.file.manager.marked.for.copy.success',
        intlValues: { total: entities.length }
      },
      error: {}
    })
  );
}

function* pasteEntities({ payload }) {
  const { dest, entries = [] } = payload.urlParams;

  let urlParams = {
    safe: 1,
    entries,
    dest
  };

  if (payload && payload.urlParams) {
    urlParams = {
      ...urlParams,
      ...payload.urlParams
    };
  }

  yield put(
    fileManagerPostRequest({
      endpoint: FILE_MANGER_API_DIR_COPY,
      urlParams,
      clearFileManagerStore: {
        markedForCopy: null
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.paste.success.message',
            intlValues: { total: entries.length }
          },
          error: {
            intlKey: 'translate.file.manager.paste.error.message',
            intlValues: { total: entries.length }
          }
        }
      }
    })
  );
}

function* handlePaste() {
  const { dest, entries, entities } = yield call(getDataForCopy);
  const entriesNames = entries.map((path) => getEntityReadableNameByPath(path));
  const existingEntriesPayload = entriesNames.map((name) => `${dest}/${name}`);

  const getExistingFilesResponse = yield call(loadExistingFilesData, existingEntriesPayload);

  const existingEntities = getExistingFilesResponse.data
    ? getExistingFilesResponse.data.map((f) => getEntityReadableNameByPath(f.n))
    : [];

  if (existingEntities.length > 0) {
    const existingEntitiesFromStore = entities.filter((entity) =>
      existingEntities.includes(getEntityReadableName(entity))
    );

    yield put(
      openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_COPY, {
        dest,
        entries,
        existingEntities: existingEntitiesFromStore
      })
    );
  } else {
    yield put(paste({ urlParams: { dest, entries } }));
  }
}

function* copyPaste(): any {
  yield takeEvery(FILE_MANAGER_COPY, handleAvalonApiRequest(copyEntities));
  yield takeEvery(FILE_MANAGER_PASTE, pasteEntities);
  yield takeEvery(FILE_MANAGER_REQUEST_PASTE, handlePaste);
}

export default copyPaste;
