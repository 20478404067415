import * as React from 'react';
import { useIntl } from 'react-intl';

import { Button, Text } from '@siteground/styleguide';

import { DIALOGS } from '../../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';

type Props = {
  onDeleteCloudflareZone: Function;
};

const CNDActivateFreeCF: React.FC<Props> = (props) => {
  const intl = useIntl();

  return (
    <SGDialog
      id={DIALOGS.CDN_ACTIVATE_FREE_CF}
      title={intl.formatMessage({ id: 'translate.page.cdn.confirm.activation' })}
      icon="warning"
      state="warning"
      resources={[{ resourceName: API_RESOURCE.CLOUDFLARE_ZONE.resourceName, methods: ['DELETE'] }]}
      footer={
        <React.Fragment>
          <SGDialogCancel
            id={DIALOGS.CDN_ACTIVATE_FREE_CF}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
          <Button onClick={props.onDeleteCloudflareZone} color="primary">
            {intl.formatMessage({ id: 'translate.generic.continue' })}
          </Button>
        </React.Fragment>
      }
    >
      <Text>{intl.formatMessage({ id: 'translate.page.cdn.confirm.activation.description' })}</Text>
    </SGDialog>
  );
};

export default CNDActivateFreeCF;
