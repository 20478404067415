import { HTTP_REQUEST_FAILED, SAVE_REDIRECT_DATA } from '../constants/actions';

const getSafeActionToLog = ({ type, ...otherActionProps }) => {
  if (/token|support-center/i.test(type)) {
    return { type, info: 'Sensitive info deleted from action payload.' };
  }

  return {
    type,
    ...otherActionProps
  };
};

const getLastNActions = (nActions: number, arrayOfActions = []) => {
  const outputArray = [...arrayOfActions];

  while (outputArray.length > nActions) {
    outputArray.shift();
  }

  return outputArray;
};

export type LastActions = {
  redirectData: any;
  otherActions: any[];
  failedRequestActions: any[];
};

const initialState: LastActions = {
  redirectData: null,
  otherActions: [],
  failedRequestActions: []
};

function lastActions(state = initialState, action) {
  let safeActionToLog: any = getSafeActionToLog(action);

  const requestedAction = safeActionToLog && safeActionToLog.payload && safeActionToLog.payload.requestedAction;

  if (typeof requestedAction === 'object' && requestedAction !== null) {
    safeActionToLog = {
      ...safeActionToLog,
      payload: {
        ...safeActionToLog.payload,
        requestedAction: getSafeActionToLog(requestedAction)
      }
    };
  }

  if (action.type === HTTP_REQUEST_FAILED) {
    return {
      ...state,
      failedRequestActions: getLastNActions(3, [...state.failedRequestActions, action])
    };
  }

  if (action.type === SAVE_REDIRECT_DATA) {
    return {
      ...state,
      redirectData: {
        ...action.payload
      }
    };
  }

  return {
    ...state,
    otherActions: getLastNActions(3, [...state.otherActions, action])
  };
}

export default lastActions;
