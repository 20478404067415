import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';
import * as Constants from '../../core/constants/actions';

type Props = {
  validationUtils: ValidationUtils;
};

const ArhiveForm = ({ validationUtils }: Props) => {
  const intl = useIntl();
  return (
    <Field
      autoFocus
      name="filename"
      label={intl.formatMessage({ id: 'translate.file.manager.archive.label' })}
      validate={[validationUtils.required]}
      component={FormInput}
    />
  );
};

export default withSpanelForm(
  reduxForm({
    form: REDUX_FORM.FILE_MANAGER_ARCHIVE,
    initialValues: {
      _metaFields: {
        resourceNameMetaApi: 'file',
        endpoint: Constants.FILE_MANGER_API_DIR_ARCHIVE
      }
    }
  })(ArhiveForm)
);
