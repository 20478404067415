import {
  FORCE_SCAN,
  REQUEST_SG_SITESCANNER_DATA,
  STORE_SITESCANNER_DATA,
  REQUEST_SG_SITESCANNER_REPORTS,
  SET_SG_SITESCANNER_REPORTS,
  CHECK_REPORT_STATUS
} from '../../constants/actions';

import customRequestTypes from '../../constants/custom-request-types';

import { sgSiteScannerDataTypes, EMAIL_NOTIFICATIONS_ROW_ID } from '../../constants/sg-sitescanner';

type RequestSgSiteScannerDataParams = {
  siteId: string;
  mainDomainName: string;
  onSiteScannerAvailabilityReady: (upgradeId: string) => any;
};

export const requestSgSitescannerData = ({
  siteId,
  mainDomainName,
  onSiteScannerAvailabilityReady
}: RequestSgSiteScannerDataParams): NemoRequestAction => ({
  type: REQUEST_SG_SITESCANNER_DATA,
  payload: {
    siteId,
    mainDomainName,
    onSiteScannerAvailabilityReady
  },
  requestTypeName: customRequestTypes.GET_SITE_SCANNER_UPGRADES
});

export const storeSgSitescannerData = (key: sgSiteScannerDataTypes, data: any): NemoRequestAction => ({
  type: STORE_SITESCANNER_DATA,
  payload: {
    key,
    data
  }
});

export const forceScan = (forceScanOptions, notification: SpanelNotification): NemoRequestAction => ({
  type: FORCE_SCAN,
  payload: forceScanOptions,
  requestTypeName: customRequestTypes.REQUEST_FORCE_SCAN,
  notification
});

export const checkReportStatus = (upgradeId: string, task_id: string) => ({
  type: CHECK_REPORT_STATUS,
  payload: {
    upgradeId,
    task_id
  },
  requestTypeName: customRequestTypes.GET_SITE_SCANNER_REPORTS_STATUS
});

export const requestSgSitescannerReports = (upgradeId: string): NemoRequestAction => ({
  type: REQUEST_SG_SITESCANNER_REPORTS,
  payload: { upgradeId },
  requestTypeName: customRequestTypes.GET_SITE_SCANNER_REPORTS
});

export const setSgSitescannerEmailReporting = (
  upgradeId: string,
  enable: boolean,
  notification: SpanelNotification
): NemoRequestAction => ({
  type: SET_SG_SITESCANNER_REPORTS,
  payload: {
    id: EMAIL_NOTIFICATIONS_ROW_ID, // for the table row loader
    upgradeId,
    enable
  },
  requestTypeName: customRequestTypes.REQUEST_SET_SG_SITESCANNER_NOTIFICATIONS,
  notification
});
