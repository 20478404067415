import { put, select, takeEvery } from 'redux-saga/effects';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { REDUX_FORM } from '../../../../core/constants/common';
import { clearFileManagerStore, requestFilesMove } from '../actions/file-manager';
import * as Constants from '../constants/actions';
import { initialFileManagerState } from '../reducers/utils/initial-state';
import { getEntityPath } from '../utils';

function* moveByDragAndDrop({ payload }) {
  const { dragSourceEntity, dropTargetEntity } = payload;

  const { fileManager } = yield select();
  const { selectedContentEntities } = fileManager;
  const entries =
    selectedContentEntities.length === 0
      ? [getEntityPath(dragSourceEntity)]
      : selectedContentEntities.map((entity) => getEntityPath(entity));

  yield put(
    requestFilesMove(
      {
        endpoint: Constants.FILE_MANGER_API_DIR_MOVE,
        urlParams: {
          safe: 1,
          entries,
          dest: getEntityPath(dropTargetEntity)
        },

        // TODO refactor with better way for updateing the parent dir (like getEntityParentPath)
        entity: selectedContentEntities.length === 0 ? dragSourceEntity : selectedContentEntities[0],
        clearFileManagerStore: {}
      },
      () => {
        clearFileManagerStore({ selectedContentEntities: initialFileManagerState.selectedContentEntities });
        closeSGDialog(REDUX_FORM.FILE_MANAGER_MOVE_ENTITY);
      }
    )
  );
}

function* dragAndDrop(): Generator {
  yield takeEvery(Constants.FILE_MANAGER_MOVE_BY_DRAG_AND_DROP, moveByDragAndDrop);
}

export default dragAndDrop;
