import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { FETCH_DIR_BY_PATH_SUCCEEDED } from '../constants/fetch-dir-by-path';
import { sortByTypeAndName } from '../../web/file-manager/core/reducers/utils/sort';

const initialState: any = {};

const reducerMap: any = {
  [FETCH_DIR_BY_PATH_SUCCEEDED]: (state, action: any) => {
    const { items } = action;
    const newState = { ...state };

    items.forEach((item) => {
      if (item.data.length === undefined) {
        return;
      }

      newState[item.base] = item.data.sort(sortByTypeAndName).map((entity) => {
        return {
          ...entity,
          _meta: {
            path: `${item.base}${entity.n}`.replace('//', '/')
          }
        };
      });
    });

    return newState;
  },
  [Actions.LOGOUT]: () => initialState
};

export default handleActions(reducerMap, initialState);
