import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Text } from '@siteground/styleguide';

import { requestNemoData } from '../../../../core/actions/request-data';
import { updateUserPreferences } from '../../../../core/actions/session';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import DomainNotConnectedNotice from '../../../components/domain-not-connected-notice';
import FirstLevelTitle from '../../../components/titles/first-level-title';

import './welcome-section.scss';

type Props = {
  name: string;
  mainDomain: {
    id?: string;
    sg_pointed: number;
    sg_dns?: number;
    name: string;
  };
  userDaysAfterCreation: number;
  messageHiddenForDomains: string[];
  requestNemoData: typeof requestNemoData;
  updateUserPreferences: typeof updateUserPreferences;
};

const WelcomeSection = ({
  name,
  mainDomain,
  userDaysAfterCreation,
  messageHiddenForDomains = [],
  requestNemoData,
  updateUserPreferences
}: Props) => {
  const intl = useIntl();
  const imgPath = process.env.IMG_PATH;
  const hideMessage = mainDomain && messageHiddenForDomains.includes(mainDomain.name);
  const shouldShowNotPointedNotification = mainDomain && !mainDomain.sg_pointed && !mainDomain.sg_dns && !hideMessage;
  const setMessageVisibility = () => {
    requestNemoData(
      {
        endpoint: '/user/set/preferences',
        method: 'POST',
        body: {
          sitetools: {
            hide_domain_not_pointed_msg: [...messageHiddenForDomains, mainDomain.name]
          }
        },
        requestTypeName: customRequestTypes.SET_USER_PREFERENCES,
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.appearance.update.domain.message.success.text',
            intlValues: { domain: mainDomain.name }
          },
          error: {
            intlKey: 'translate.page.appearance.update.domain.message.error.text'
          }
        }
      },
      (data) => {
        updateUserPreferences(data);
      }
    );
  };

  return (
    <div>
      {name && (
        <FirstLevelTitle>{intl.formatMessage({ id: 'translate.page.dashboard.title' }, { name })}</FirstLevelTitle>
      )}

      {shouldShowNotPointedNotification && (
        <DomainNotConnectedNotice
          domainId={mainDomain.id}
          noticeTitle={intl.formatMessage({ id: 'translate.page.dashboard.not-pointed-notice.title' })}
        >
          <Text>
            <FormattedMessage
              id="translate.page.dashboard.not-pointed-notice.hide.message"
              values={{
                a: (text) => <Link onClick={setMessageVisibility}>{text}</Link>
              }}
            />
          </Text>
        </DomainNotConnectedNotice>
      )}
    </div>
  );

  // Temporary hidden Welcome section banner

  /* return (
    <div>
      <FirstLevelTitle>
        {intl.formatMessage({ id: 'translate.page.dashboard.title' }, { name })}
      </FirstLevelTitle>

      {
        userDaysAfterCreation <= 7 && (
          <div className="welcome__content-wrapper">
            <div className="welcome__background-wrapper">
              <img
                className="welcome__background-image"
                sizes="100vw"
                srcSet={`
                  /${imgPath}/dashboard/header_768.jpg 768w,
                  /${imgPath}/dashboard/header_1200.jpg 768w,
                  /${imgPath}/dashboard/header_768@2x.jpg 1536w,
                  /${imgPath}/dashboard/header_1200@2x.jpg 2400w`}
                src={`/${imgPath}/dashboard/header_1200.jpg`}
                alt="Dashboard header image"
              />
            </div>

           <div className="welcome__content">
             <Title className="welcome__title" color="white" align="center" density="none">
               {intl.formatMessage({ id: 'translate.page.dashboard.welcome.title' })}
             </Title>

             <Text className="welcome__text" color="white" align="center">
               {intl.formatMessage({ id: 'translate.page.dashboard.welcome.message' })}
             </Text>

             <Button className="welcome__button" color="light" size="large">
               {intl.formatMessage({ id: 'translate.page.dashboard.welcome.button' })}
             </Button>
           </div>
          </div>
        )
      }
    </div>
  ); */
};

export default connect<{}, any, Partial<Props>>(undefined, {
  requestNemoData,
  updateUserPreferences
})(WelcomeSection);
