import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

type Props = {
  children: any;
  intl: any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.APP;
const formName = REDUX_FORM.CREATE_WP_AUTOUPDATE;

const CreateContainer = ({ children, intl }: Props) => (
  <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.wp.autoupdate.create.title' })}
      formName={formName}
      resourceName={resourceName}
      resources={[
        {
          resourceName: API_RESOURCE.APP.resourceName,
          methods: ['GET', 'PUT']
        },
        {
          resourceName: API_RESOURCE.APP_SETTINGS.resourceName,
          methods: ['PUT'],
          id: 'skip_version'
        },
        {
          resourceName: API_RESOURCE.APP_UPDATE_CORE.resourceName,
          methods: ['PUT']
        }
      ]}
      {...API_RESOURCE.APP}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
