import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Checkbox, Flex, Grid, Input, InputState, Link, Text } from '@siteground/styleguide';
import { LoginIcon } from '@siteground/styleguide/lib/composite';
import { focusFormInput } from '../../../../core/common/dom-utils';

export type LoginStep = 'username' | 'password';

type Props = {
  step: LoginStep;
  subTitle: string;
  usernameMessage: string;
  usernameInputState: InputState;
  passwordMessage: string;
  passwordInputState: InputState;
  onVerifyUser: ({ username }) => any;
  onLogin: ({ username, password, remember_me }) => any;
  onRecoverPassword: Function;
  environment?: {
    isPhone: boolean;
  };
};

type State = {
  username: string;
  password: string;
  remember_me: boolean;
};

type DispatchProps = {
  intl: Intl;
};

enum FormNames {
  VerifyUsername = 'username',
  Password = 'password'
}

class LoginForm extends React.Component<Props & DispatchProps, State> {
  input = null;

  readonly state = {
    username: '',
    password: '',
    remember_me: false
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.passwordMessage !== this.props.passwordMessage) {
      focusFormInput(FormNames.Password, 'password');
    }

    if (prevProps.usernameMessage !== this.props.usernameMessage) {
      focusFormInput(FormNames.VerifyUsername, 'username');
    }
  }

  onSubmit = () => {
    const { step, onVerifyUser, onLogin } = this.props;
    const { username, password, remember_me } = this.state;

    switch (step) {
      case 'password':
        return onLogin({ username, password, remember_me });
      case 'username':
        return onVerifyUser({ username });
      default:
        return;
    }
  };

  renderVerifyUser = () => {
    const { usernameMessage, usernameInputState, intl } = this.props;

    return (
      <Input
        onRefsReady={(input) => (this.input = input)}
        autoFocus
        state={usernameInputState}
        validationMessage={usernameMessage && intl.formatMessage({ id: usernameMessage })}
        size="x-large"
        name="username"
        label={intl.formatMessage({ id: 'translate.reseller-login.site-panel.login.username.label' })}
        data-e2e="text-input-username"
      />
    );
  };

  renderPassword = () => {
    const { passwordMessage, passwordInputState, intl } = this.props;

    return (
      <Input
        autoFocus
        type="password"
        name="password"
        state={passwordInputState}
        validationMessage={passwordMessage && intl.formatMessage({ id: passwordMessage })}
        label={intl.formatMessage({ id: 'translate.reseller-login.create.site-panel.password.input.label' })}
        onChange={(ev) => this.setState({ password: ev.target.value })}
        size="x-large"
        data-e2e="text-input-password"
      />
    );
  };

  render() {
    const { step, intl, subTitle, onRecoverPassword, environment } = this.props;
    const { remember_me } = this.state;
    const username = this.input && this.input.value;

    return (
      <form
        name={step === 'username' ? FormNames.VerifyUsername : FormNames.Password}
        onSubmit={(e) => {
          e.preventDefault();
          if (step === 'username') {
            return this.setState({ username: this.input.value }, this.onSubmit);
          }
          return this.onSubmit();
        }}
        data-e2e="login"
      >
        <Grid gap="x-large">
          <Grid gap="small">
            <LoginIcon icon="plus" size="40" />
            <Text align="center">{step === 'username' ? subTitle : username}</Text>
          </Grid>
          <Grid gap="large">
            {step === 'username' && this.renderVerifyUser()}
            {step === 'password' && this.renderPassword()}

            <Flex align="flex-end" direction="column">
              <Button
                id="login-submit"
                color="secondary"
                size="x-large"
                action="submit"
                style={{ alignSelf: 'stretch' }}
              >
                {step === 'username'
                  ? intl.formatMessage({ id: 'translate.reseller-login.site-panel.login.button.next' })
                  : intl.formatMessage({ id: 'translate.reseller-login.site-panel.login.button.login' })}
              </Button>
              <Flex
                // on username step, only recover password is presented
                justify={step === 'username' ? 'flex-end' : 'space-between'}
                align="center"
                direction="row"
                style={{ width: '100%' }}
              >
                {step === 'password' && (
                  <Checkbox
                    checked={remember_me}
                    style={{ whiteSpace: 'nowrap' }}
                    onChange={(e) => this.setState({ remember_me: e.target.checked })}
                  >
                    {intl.formatMessage({ id: 'translate.reseller-login.site-panel.login.remember-me.text' })}
                  </Checkbox>
                )}
                <Flex gutter="medium" padding={['small', 'none']}>
                  <Link onClick={onRecoverPassword}>
                    {intl.formatMessage({ id: 'translate.reseller-login.site-panel.recover-password.link' })}
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default connect<any, DispatchProps, Props>((state) => ({
  environment: state.environment
}))(injectIntl(LoginForm));
