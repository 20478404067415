import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  BorderType,
  Button,
  Colors,
  Card,
  Column,
  Container,
  Context,
  Flex,
  Grid,
  Icon,
  Padding,
  ProductTable,
  Section,
  Spacer,
  Text,
  Tile,
  TileProps,
  Title,
  Toolbar,
  Notice
} from '@siteground/styleguide';
import * as actions from '../../../../core/actions/crud';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import PageHeader from '../../../components/page-header';
import { SGDialogForm } from '../../../components/sg-dialog';
import { withFormMetadata } from '../../../components/sg-spanel-forms';
import { SecondLevelTitle } from '../../../components/titles';
import VCS from '../../../components/visibility-control-service';
import './landing-page.scss';
import { ConnectAccount } from './update';
import { ToolId } from '../../../../core/constants/route-info';

interface CloudflareProps {
  actions?: any;
  nemoStoreRetrieve?: {
    domain?: string;
    page?: string;
  };
  cloudflareZonePending?: any[];
  environment?: any;
  pageNotification?: any;
  intl: Intl;
  getFormValue: Function;
  openSGDialog?: Function;
  closeSGDialog?: Function;
}

interface IconProps {
  name: string;
  color: Colors;
  size: any;
}

interface State {
  accountDialog: {
    actionType: string;
    [key: string]: any;
  };
}

const cloudflarePlanData = [
  { id: 1, feature: 'translate.page.cloudflare.landing.table.speed.ssl.text', basic: true, premium: true },
  { id: 2, feature: 'translate.page.cloudflare.landing.table.security.text', basic: true, premium: true },
  { id: 3, feature: 'translate.page.cloudflare.landing.table.cache.text', basic: true, premium: true },
  { id: 5, feature: 'translate.page.cloudflare.landing.table.page.rules.text', basic: 3, premium: 30 },
  { id: 6, feature: 'translate.page.cloudflare.landing.table.wap.text', basic: false, premium: true },
  { id: 7, feature: 'translate.page.cloudflare.landing.table.advanced.features.text', basic: false, premium: true }
];

class CloudflarePage extends React.Component<CloudflareProps, State> {
  readonly state = {
    accountDialog: null
  };

  componentWillUnmount() {
    this.props.actions.fetchItems({
      ...API_RESOURCE.CLOUDFLARE_ZONE
    });
    this.props.actions.fetchItems({
      ...API_RESOURCE.CLOUDFLARE_ZONE_PENDING
    });
  }

  shouldShowProductsComparison() {
    const { nemoStoreRetrieve, cloudflareZonePending = [] } = this.props;

    // TODO page constant
    if (nemoStoreRetrieve && nemoStoreRetrieve.page === 'cloudflare') {
      return false;
    }

    if (cloudflareZonePending.length > 0) {
      return false;
    }

    return true;
  }

  renderNotice = () => {
    const { intl } = this.props;

    return (
      <Notice
        title={intl.formatMessage({ id: 'translate.cloudflare.notice.configuration' })}
        background="light"
        type="instruction"
        shadow={false}
      >
        <Text>{intl.formatMessage({ id: 'translate.extras.cloudflare.intro-disclaimer.note' })}</Text>
      </Notice>
    );
  };

  renderPlanData = (cellData) => {
    const { intl } = this.props;

    if (typeof cellData === 'boolean') {
      const iconProps: IconProps = {
        name: cellData ? 'check' : 'cross',
        color: cellData ? 'mint' : 'light',
        size: cellData ? '18' : '14'
      };

      return <Icon {...iconProps} aria-label={cellData ? 'Included' : 'Not included'} />;
    }
    return (
      <Text weight="regular" color="darkest">
        {typeof cellData === 'number' ? cellData : intl.formatMessage({ id: cellData })}
      </Text>
    );
  };

  renderHeaderInfo() {
    const { intl, environment } = this.props;

    return (
      <React.Fragment>
        <Flex
          wrap="nowrap"
          align={environment.isPhone ? 'flex-start' : 'center'}
          direction={environment.isPhone ? 'column' : 'row'}
        >
          <Icon name="logo-cloudflare" size="140" multicolor style={{ marginRight: '20px' }} />
          <Title level="3" color="dark" weight="regular">
            {intl.formatMessage({ id: 'translate.page.cloudflare.landing.title' })}
          </Title>
        </Flex>

        <Text>{intl.formatMessage({ id: 'translate.page.cloudflare.info' })}</Text>
        <Text padding={['medium', 'none', 'none', 'none']}>
          {intl.formatMessage({ id: 'translate.page.cloudflare.info.additional' })}
        </Text>
      </React.Fragment>
    );
  }

  renderProductTable() {
    const { intl } = this.props;

    const columns = [
      {
        header: ' ',
        accessor: 'feature',
        render: this.renderPlanData
      },
      {
        header: (
          <Title density="none" level="3" align="center">
            {intl.formatMessage({ id: 'translate.page.cloudflare.plan.free' })}
          </Title>
        ),
        accessor: 'basic',
        render: this.renderPlanData,
        style: {
          textAlign: 'center'
        }
      },
      {
        header: (
          <Title density="none" level="3" align="center">
            {intl.formatMessage({ id: 'translate.page.cloudflare.plan.paid' })}
          </Title>
        ),
        accessor: 'premium',
        render: this.renderPlanData,
        style: {
          textAlign: 'center'
        }
      }
    ];

    return (
      <ProductTable
        className="cloudflare-header-table"
        data={cloudflarePlanData}
        columns={columns}
        headerBackground="white"
      />
    );
  }

  renderCustomPageHeader = () => {
    const { environment, intl } = this.props;

    if (environment.isPhone) {
      return null;
    }

    return (
      <Container padding="xx-large" tabIndex={0} role="figure">
        <Grid>
          <Grid sm="12" gap="xx-large">
            <Column smSpan="5">{this.renderHeaderInfo()}</Column>

            <Column smSpan="7">{this.renderProductTable()}</Column>
          </Grid>
          {this.renderNotice()}
        </Grid>
      </Container>
    );
  };

  renderTileSection() {
    const { environment, intl, openSGDialog } = this.props;
    const shouldShowProductsComparison = this.shouldShowProductsComparison();
    const accountDialogPayload = {
      terms: false,
      actionType: 'CREATE',
      _metaFields: {
        ...API_RESOURCE.CLOUDFLARE
      },
      _meta: {
        notification: {
          type: 'generic',
          // type: 'form',
          // formName: REDUX_FORM.CREATE_CLOUDFLARE_ACCOUNT,
          success: {
            intlKey: 'translate.page.translate.cloudflare.create.account.success'
          },
          error: {
            intlKey: 'translate.page.translate.cloudflare.create.account.error'
          }
        }
      }
    };
    const tileProps: Partial<TileProps> = {
      className: 'cloudflare-tile',
      padding: environment.isPhone ? ['large', 'none'] : 'large',
      style: environment.isPhone
        ? {
            margin: '20px'
          }
        : undefined
    };

    const tiles = [
      {
        icon: 'product-cloudflare-link-account',
        titleId: 'translate.page.cloudflare.landing.tile.connect.title',
        textId: 'translate.page.cloudflare.landing.tile.connect.text'
      },
      {
        icon: 'product-cloudflare-configure',
        titleId: 'translate.page.cloudflare.landing.tile.аctivate.title',
        textId: shouldShowProductsComparison
          ? 'translate.page.cloudflare.landing.tile.аctivate.text'
          : 'translate.page.cloudflare.landing.tile.аctivate.redirected.text'
      },
      {
        icon: 'product-cloudflare-activate-domain',
        titleId: shouldShowProductsComparison
          ? 'translate.page.cloudflare.landing.tile.configure.title'
          : 'translate.page.cloudflare.landing.tile.configure.redirected.title',
        textId: shouldShowProductsComparison
          ? 'translate.page.cloudflare.landing.tile.configure.text'
          : 'translate.page.cloudflare.landing.tile.configure.redirected.text'
      }
    ];

    return (
      <Context.Consumer>
        {({ device }) => {
          const MOBILE_PADDING: Padding[] = ['x-large', 'medium'];
          const DESKTOP_PADDING: Padding[] = ['x-large', 'large'];

          const DESKTOP_TILE_BORDER: BorderType = ['none', 'small', 'none', 'none'];
          const MOBILE_TILE_BORDER: BorderType = ['none', 'none', 'small', 'none'];

          return (
            <Container padding="none">
              <Grid padding={device.isPhone ? MOBILE_PADDING : DESKTOP_PADDING}>
                <Grid gap="none" sm="3">
                  {tiles.map((tile, index, array) => {
                    const hasBorder = index !== array.length - 1;
                    const border = hasBorder && (device.isPhone ? MOBILE_TILE_BORDER : DESKTOP_TILE_BORDER);

                    return (
                      <Tile key={tile.titleId} border={border} padding="none">
                        <Card
                          iconName={tile.icon}
                          iconColor="salmon"
                          title={intl.formatMessage({ id: tile.titleId })}
                          text={intl.formatMessage({ id: tile.textId })}
                          layout="vertical"
                          outline="none"
                        />
                      </Tile>
                    );
                  })}
                </Grid>
              </Grid>

              <Toolbar style={{ marginTop: 0 }}>
                <Spacer />

                <Button
                  color="primary"
                  onClick={() =>
                    this.setState(
                      {
                        accountDialog: accountDialogPayload
                      },
                      () => openSGDialog(REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT)
                    )
                  }
                >
                  {intl.formatMessage({ id: 'translate.generic.set.up' })}
                </Button>
              </Toolbar>
            </Container>
          );
        }}
      </Context.Consumer>
    );
  }

  render() {
    const { environment, intl } = this.props;
    const shouldShowProductsComparison = this.shouldShowProductsComparison();

    return (
      <div>
        {shouldShowProductsComparison && <PageHeader id={ToolId.cloudflare} render={this.renderCustomPageHeader} />}

        <Section>
          {environment.isPhone && shouldShowProductsComparison && (
            <Grid>
              <Container>
                <Grid>
                  {this.renderHeaderInfo()}
                  {this.renderNotice()}
                </Grid>
              </Container>

              {this.renderProductTable()}
            </Grid>
          )}

          <VCS resourceName={API_RESOURCE.CLOUDFLARE.resourceNameMetaApi} hasMethod="GET">
            <SecondLevelTitle>
              {intl.formatMessage({ id: 'translate.page.cloudflare.landing.list.title' })}
            </SecondLevelTitle>

            {this.renderTileSection()}

            {this.renderAccountDialog()}
          </VCS>
        </Section>
      </div>
    );
  }

  renderAccountDialog = () => {
    const { intl, actions, getFormValue } = this.props;
    const { accountDialog } = this.state;
    const selectedAction = getFormValue(REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT, 'actionType');

    return (
      <SGDialogForm
        name={REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT}
        icon="product-cloudflare"
        iconColor="salmon"
        iconSize="72"
        state="presentational"
        size="large"
        title={intl.formatMessage({ id: 'translate.page.cloudflare.landing.connect.account.dialog.title' })}
        submitLabel={intl.formatMessage({
          id: selectedAction === 'CREATE' ? 'translate.generic.create' : 'translate.generic.connect'
        })}
        resources={[
          {
            resourceName: API_RESOURCE.CLOUDFLARE.resourceName,
            methods: ['POST']
          }
        ]}
      >
        <ConnectAccount
          initialValues={accountDialog}
          onSubmit={({ terms, actionType, ...data }) => actions.createItem(data)}
        />
      </SGDialogForm>
    );
  };
}

const mapStateToProps = (state) => ({
  nemoStoreRetrieve: state.nemoStore.retrieve,
  cloudflareZonePending: state.pageItems.cloudflareZonePending || [],
  environment: state.environment
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions as any, dispatch),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withFormMetadata(CloudflarePage, [REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT])));
