import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Flex, FormLabel, Grid, Icon, Text, Tile, Tree } from '@siteground/styleguide';
import { fetchDirByPath } from '../../../core/actions/fetch-dir-by-path';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { ENTITY_ICONS } from '../../core/constants/entity-icons';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../../file-manager/core/constants/common';
import { SGDialog, SGDialogCancel } from '../sg-dialog';

export enum SelectPathType {
  folder = 'folder',
  file = 'file',
  all = 'all'
}

const DIALOG_ICON = {
  [SelectPathType.file]: 'file',
  [SelectPathType.folder]: 'folder',
  [SelectPathType.all]: 'folder'
};

type Props = {
  id?: string;
  fetchDirByPath: Function;
  onChange: Function;
  intl: Intl;
  dialog: any;
  dir: any;
  handleClose: Function;
  initialPath: string;
  expandedNodes?: string[];
  prefetch?: string[];
  closeSGDialog: Function;
  normalizeDirectoryData?: Function;
  title?: string;
  label?: string;
  pathType: SelectPathType;
  nodesParentFolder?: {
    name: string;
    path: string;
    value: string;
  };
};

type State = {
  selectedFolder: string;
  expanded: any[];
};

const initialState = {
  selectedFolder: '',
  expanded: []
};

class SelectPath extends React.Component<Props, State> {
  readonly state = initialState;

  readonly dialogId = this.props.id || DIALOGS.SELECT_DIRECTORY;

  static defaultProps: Partial<Props> = {
    pathType: SelectPathType.folder
  };

  componentDidMount() {
    const { dialog } = this.props;

    if (dialog[this.dialogId]) {
      this.fetchInitialDir();
    }
  }

  componentDidUpdate(prevProps) {
    const { dialog } = this.props;

    if (dialog[this.dialogId] && dialog[this.dialogId] !== prevProps.dialog[this.dialogId]) {
      this.fetchInitialDir();
    }
  }

  fetchInitialDir() {
    const { expandedNodes, initialPath, fetchDirByPath, prefetch } = this.props;

    if (initialPath) {
      fetchDirByPath(initialPath);
    }

    if (prefetch) {
      prefetch.forEach((path) => fetchDirByPath(path));
    }

    if (expandedNodes) {
      this.setState({ expanded: expandedNodes });
    }
  }

  filterEntitiesByType = (ent) => {
    const { pathType } = this.props;

    if (pathType === SelectPathType.folder) {
      return ent.t !== FILE_MANAGER_API_RESPONSE_DIR.FILE;
    }

    return true;
  };

  mapDataToTree = (entity, data) => {
    const entityPath = entity._meta.path;
    const items = data[entityPath];

    return {
      entity,
      value: entity.n,
      label: this.renderTreeNode,
      forceShowToggleIcon: entity.t !== 'f',
      showCheckbox: false,
      children: items && items.filter(this.filterEntitiesByType).map((ent) => this.mapDataToTree(ent, data))
    };
  };

  renderTreeNode = (entity) => {
    const isSelected = entity._meta.path === this.state.selectedFolder;
    const isFile = entity.t === FILE_MANAGER_API_RESPONSE_DIR.FILE;

    return (
      <Flex
        align="center"
        wrap="nowrap"
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={(event) => {
          if (this.props.pathType === SelectPathType.file && !isFile) {
            return;
          }

          this.setState({ selectedFolder: entity._meta.path });
        }}
      >
        <Icon name={ENTITY_ICONS[entity.t]} size="18" color={isSelected ? 'ocean' : 'lighter'} />

        <Text color={isSelected ? 'ocean' : undefined} style={{ paddingLeft: '10px', whiteSpace: 'nowrap' }}>
          {entity.n.length > 1 ? entity.n.replace(/.*?\//, '') : entity.n}
        </Text>
      </Flex>
    );
  };

  wrapNodesInParentFolder = (nodes) => [
    {
      entity: {
        n: this.props.nodesParentFolder.name,
        t: 'f',
        _meta: {
          path: this.props.nodesParentFolder.path
        }
      },
      value: this.props.nodesParentFolder.value,
      label: this.renderTreeNode,
      forceShowToggleIcon: true,
      showCheckbox: false,
      children: nodes
    }
  ];

  render() {
    const { dir, initialPath, intl, closeSGDialog, normalizeDirectoryData, nodesParentFolder, title, label, pathType } =
      this.props;

    const { selectedFolder } = this.state;

    if (!dir[initialPath]) {
      return null;
    }

    let nodes = dir[initialPath].filter(this.filterEntitiesByType).map((ent) => this.mapDataToTree(ent, dir));

    if (nodesParentFolder) {
      nodes = this.wrapNodesInParentFolder(nodes);
    }

    if (normalizeDirectoryData) {
      nodes = normalizeDirectoryData(nodes);
    }

    return (
      <SGDialog
        id={this.dialogId}
        icon={DIALOG_ICON[pathType]}
        state="warning"
        size="x-large"
        title={title || intl.formatMessage({ id: 'translate.browse.folder.dialog.title' })}
        footer={
          <div>
            <SGDialogCancel id={this.dialogId} />

            <Button
              color="primary"
              data-e2e="dialog-submit"
              disabled={!selectedFolder}
              onClick={() => {
                this.props.onChange(selectedFolder.replace('//', '/'));
                closeSGDialog(this.dialogId);
              }}
            >
              {intl.formatMessage({ id: 'translate.generic.select' })}
            </Button>
          </div>
        }
        resources={[{ requestTypeName: customRequestTypes.SELECT_DIRECTORY_FETCH }]}
        onCloseHandler={() => this.setState({ ...initialState })}
      >
        <Grid gap="none">
          <FormLabel>{label || intl.formatMessage({ id: 'translate.generic.select.folder' })}</FormLabel>

          <Tile>
            <Tree
              nodes={nodes}
              expanded={this.state.expanded}
              onExpand={(expanded, data) => {
                if (data.expanded) {
                  this.props.fetchDirByPath(data.entity._meta.path);
                }

                this.setState({ expanded });
              }}
              onCheck={(checked, data) => this.setState({ selectedFolder: data.entity._meta.path })}
            />
          </Tile>
        </Grid>
      </SGDialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchDirByPath: bindActionCreators(fetchDirByPath, dispatch),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

const mapStateToProps = (store) => ({
  dir: store.dir,
  dialog: store.dialog
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl<any, any>(SelectPath));
