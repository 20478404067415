import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cn, IconButton, Text } from '@siteground/styleguide/';
import { RootState } from '../../reducers';
import * as fileManagerActions from '../core/actions/file-manager';
import { FILE_MANAGER_CONTEXT_MENU_TYPE } from '../core/constants/common';
import { getFileMangerAllUploadEntities } from '../core/reducers/utils/upload';
import { shouldRenderMobile } from '../core/utils';
import { CommonFMProps, Uploader } from '../types';
import UploadProgress from '../upload-progress';
import './footer.scss';

interface Props extends CommonFMProps {
  time: number;
  uploader: Uploader;
  allUploaderEntities: any;
  selectedContentEntities: any;
  onFailedClick: Function;
  actions: {
    clearUploadProgress: typeof fileManagerActions.clearUploadProgress;
  };
  intl: Intl;
}

class FileManagerFooter extends React.Component<Props> {
  isUploadProgressVisible() {
    return this.props.allUploaderEntities.length !== 0;
  }

  renderActionButton(intl) {
    const { environment, openContextMenu, codeEditorIsVisible, selectedContentEntities, selectedNavigationEntity } =
      this.props;
    const isActionMenuVisible = selectedContentEntities.length === 0;
    const isUploadProgressVisible = this.isUploadProgressVisible();

    if (!shouldRenderMobile(environment)) {
      return null;
    }

    if (!isActionMenuVisible || !selectedNavigationEntity) {
      return null;
    }

    return (
      <IconButton
        icon={codeEditorIsVisible ? 'horizontal-dots' : 'plus'}
        aria-label={intl.formatMessage({ id: 'translate.aria-label.actions' })}
        color="primary"
        shape="circle"
        size="x-large"
        className={cn(
          'mobile-base-context-menu-opener',
          isUploadProgressVisible && 'mobile-base-context-menu-opener--above-uploader-progress'
        )}
        data-e2e="context-menu-opener"
        onClick={(event) => {
          const contextMenuType = codeEditorIsVisible
            ? FILE_MANAGER_CONTEXT_MENU_TYPE.CODE_EDITOR
            : FILE_MANAGER_CONTEXT_MENU_TYPE.BASE;

          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
          openContextMenu(event, contextMenuType);
        }}
      />
    );
  }

  render() {
    const { environment, intl, selectedContentEntities, onFailedClick } = this.props;
    const isUploadProgressVisible = this.isUploadProgressVisible();
    const count = selectedContentEntities.length;

    return (
      <div className="file-manager-footer" data-e2e="file-manager-footer">
        {!isUploadProgressVisible && !environment.isPhone && (
          <Text color="light" className="file-manager-footer__content">
            {intl.formatMessage({ id: 'translate.file.manager.toolbar.selected.entities' }, { count })}
          </Text>
        )}

        {this.renderActionButton(intl)}

        {isUploadProgressVisible && (
          <UploadProgress onClose={this.props.actions.clearUploadProgress} onFailedClick={onFailedClick} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  time: state.fileManager.time,
  environment: state.environment,
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible,
  selectedContentEntities: state.fileManager.selectedContentEntities,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  uploader: state.fileManager.uploader,
  allUploaderEntities: getFileMangerAllUploadEntities(state)
});

export default connect<{}, any, Partial<Props>>(mapStateToProps, mapDispatchToProps)(injectIntl(FileManagerFooter));
