import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import SettingList from '../../../components/setting-list';
import './settings.scss';
import { CDN_SETTING_ID, DEFAULT_TTL_VALUE } from '../constants';
import { findDomainById } from '../../php-settings/utils';
import SettingListDropdown from './../components/setting-dropdown';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { SettingSwitch } from '../components';

type Props = {
  domain: any;
  items: any;
  domainLocation: any;
  openSGDialog: Function;
  actions: {
    updateItem: Function;
    deleteItem: Function;
    createItem: Function;
  };
  intl: Intl;
  setZoneDomain: Function;
  navigateToUA: Function;
};

class CDNSettingsPremium extends React.Component<Props, any> {
  render() {
    const { domain, intl, items, domainLocation } = this.props;
    const { domainCDN } = items;
    const cdnDomain = findDomainById({ domainId: domain?.id, domain: domainCDN });
    const DNSRecords = domain?.sg_dns;
    const ttlValue = domainLocation?.default_cache_ttl || DEFAULT_TTL_VALUE;
    const dynamicCache = domain?.settings?.dynamic_cache;

    return (
      <SettingList title={intl.formatMessage({ id: 'translate.page.cdn.tab.setting.premium.features' })}>
        <SettingSwitch
          title={intl.formatMessage({ id: 'translate.page.cdn.tab.setting.dynamic.cashing.title' })}
          info={intl.formatMessage({ id: 'translate.page.cdn.tab.setting.dynamic.cashing.desc' })}
          resources={[
            {
              resourceName: API_RESOURCE.DOMAIN.resourceName,
              methods: ['PUT']
            }
          ]}
          setting={{ id: CDN_SETTING_ID.DYNAMIC_CACHE, value: dynamicCache }}
          DNSRecords={DNSRecords}
          cdnDomain={cdnDomain}
          domainLocation={domainLocation}
          domain={domain}
        />
        <SettingListDropdown
          title={intl.formatMessage({ id: 'translate.page.cdn.tab.setting.ttl.title' })}
          info={intl.formatMessage({ id: 'translate.page.cdn.tab.setting.ttl.desc' })}
          resources={[{ requestTypeName: customRequestTypes.UPDATE_TTL }]}
          DNSRecords={DNSRecords}
          cdnDomain={cdnDomain}
          domainLocation={domainLocation}
          domain={domain}
          setting={{ id: CDN_SETTING_ID.TTL, value: ttlValue }}
        />
        <SettingSwitch
          title={intl.formatMessage({ id: 'translate.page.cdn.tab.setting.always.online.title' })}
          info={intl.formatMessage({ id: 'translate.page.cdn.tab.setting.always.online.desc' })}
          resources={[{ requestTypeName: customRequestTypes.UPDATE_ALWAYS_ONLINE }]}
          setting={{ id: CDN_SETTING_ID.ALWAYS_ONLINE, value: domainLocation?.use_stale_cache }}
          DNSRecords={DNSRecords}
          cdnDomain={cdnDomain}
          domainLocation={domainLocation}
          domain={domain}
        />
      </SettingList>
    );
  }
}

export default injectIntl(CDNSettingsPremium) as any;
