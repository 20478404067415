import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Layout, Page } from '@siteground/styleguide';
import { ErrorPage } from '@siteground/styleguide/lib/composite';

import '@siteground/clients-frontend/dist/main.css';
import '@siteground/styleguide/lib/styles/main.scss';

import { getDeviceInformation } from '@siteground/styleguide/lib/utils';
import * as deviceActions from '../../../core/actions/device';
import * as fetchActions from '../../../core/actions/fetch';
import * as i18nActions from '../../../core/actions/i18n';
import * as sessionActions from '../../../core/actions/session';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { isCmdCtrlKey } from '../../../core/common/key-code-combinations';
import { DIALOGS, PAGE_LOAD_REQUESTS } from '../../../core/constants/common';
import { isSitesListEmpty } from '../../../core/selectors';
import { getCurrentSiteToken } from '../../../core/selectors/session';
import { isResellerLoginRoute, MatchedRoute } from '../../../core/utils/routes';
import { SendErrorLog } from '../../../core/utils/send-error-log';
import '../../assets/styles/main.scss';
import DeleteIndexLoader from '../../components/delete-index-loader';
import Notifications from '../../components/notifications';
import PartialLoader from '../../components/partial-loader';
import SessionReady from '../../components/session-ready';
import { SGDialog, SGDialogCancel } from '../../components/sg-dialog';
import TaskLoader from '../../components/task-loader';
import ToolFinder from '../../components/tool-finder/tool-finder';
import { default as withDrawerState, WithDrawerInjectedProps } from '../../components/with-drawer';
import { RootState } from '../../reducers';
import InlineErrorPage from '../inline-error-page';
import DebugDialog from './debug-dialog';
import SGHeader from './header';
import Navigation from './navigation';
import NoSitePlaceholder from './no-site-placeholder';
import HandleDiskQuoataExceeded from './handle-disk-quota-exceeded';
import ToolFinderButton from './tool-finder-button';
import { getInitialLang } from '../resseller-login/utils';

type OwnProps = {
  session: any;
  lastActions: any;
  deviceActions: any;
  sessionActions: typeof sessionActions;
  fetchActions: typeof fetchActions;
  i18nMessages: any;
  i18nShowMessages: boolean;
  i18nActions: typeof i18nActions;
  notifications: any;
  pageItems: any;
  router: any;
  routes: MatchedRoute[];
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  sitesListEmpty: boolean;
  siteToken: string;
  siteUnavailable: boolean;
  username?: string;
};

type Props = OwnProps & WithDrawerInjectedProps;

type State = {
  toolFinderIsOpen: boolean;
  error: string;
  errorInfo: string;
  sgContext: any;
  pageMounted: boolean;
};

const deviceInformation = getDeviceInformation();
const sgContext = {
  config: {
    assetsPath: process.env.ASSETS_PATH
  }
};

class App extends React.Component<Props, State> {
  state = {
    toolFinderIsOpen: false,
    error: null,
    errorInfo: null,
    sgContext: {
      config: {
        assetsPath: process.env.ASSETS_PATH
      }
    },
    pageMounted: false
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });

    SendErrorLog({
      error,
      errorInfo,
      session: this.props.session,
      lastActions: this.props.lastActions,
      projectName: 'spanel'
    });
  }

  componentDidMount() {
    const { routes, sessionActions, i18nActions } = this.props;

    if (isResellerLoginRoute(routes)) {
      const lang = getInitialLang(this.props);
      return i18nActions.loadTranslations({ fileName: lang });
    }

    sessionActions.pageLoad();
    window.addEventListener('keydown', this.onKeyDownHandler.bind(this));

    this.setState({ pageMounted: true });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDownHandler.bind(this));
  }

  closeToolFinder = () => {
    this.setState({ toolFinderIsOpen: false });
  };

  renderPageContent = () => {
    const { children, sitesListEmpty, i18nMessages } = this.props;

    if (sitesListEmpty) {
      return <NoSitePlaceholder />;
    }

    return <HandleDiskQuoataExceeded>{children}</HandleDiskQuoataExceeded>;
  };

  renderPage() {
    const { i18nMessages, siteUnavailable, sitesListEmpty } = this.props;

    return (
      <React.Fragment>
        <Page padding={['inherit', 'inherit', 'large', 'inherit']} tabIndex={0}>
          {this.renderPageContent()}
        </Page>

        {siteUnavailable && (
          <div
            style={{
              overflow: 'auto',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2
            }}
          >
            <ErrorPage
              type="something-went-wrong"
              title={i18nMessages['translate.site.unavailable.title']}
              message={i18nMessages['translate.site.unavailable.message']}
            >
              <Button color="secondary" onClick={() => this.props.sessionActions.retryPageLoad()}>
                {i18nMessages['translate.site.unavailable.try.again']}
              </Button>
            </ErrorPage>
          </div>
        )}

        <DeleteIndexLoader />

        <SGDialog
          id={DIALOGS.NEW_TAB_DIALOG}
          icon="warning"
          state="warning"
          title={i18nMessages['translate.new.tab.blocked.title']}
          footer={<SGDialogCancel id={DIALOGS.NEW_TAB_DIALOG} label={i18nMessages['translate.generic.close']} />}
        >
          {i18nMessages['translate.new.tab.blocked.message']}
        </SGDialog>

        <SGDialog
          id={DIALOGS.TOO_MANY_REQUEST_DIALOG}
          icon="information"
          state="inactive"
          title={i18nMessages['translate.too.many.request.dialog.title']}
          disableClose
          footer={<Button onClick={() => window.location.reload()}>{i18nMessages['translate.generic.refresh']}</Button>}
        >
          {i18nMessages['translate.too.many.request.dialog.message']}
        </SGDialog>
      </React.Fragment>
    );
  }

  renderContent() {
    const { i18nMessages } = this.props;
    const { error } = this.state;
    const isSupported = deviceInformation.browser && deviceInformation.browser.isSupported;

    if (error) {
      return (
        <div
          style={{
            overflow: 'auto',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          }}
        >
          <ErrorPage
            type="something-went-wrong"
            title={i18nMessages['translate.generic.error.page.title']}
            message={i18nMessages['translate.generic.error.page.subtitle']}
          />
        </div>
      );
    }

    return isSupported ? this.renderPage() : <InlineErrorPage type="not-supported-browser" />;
  }

  renderDrawer() {
    const { routes, siteToken, drawerIsOpen, toggleDrawer } = this.props;

    if (isResellerLoginRoute(routes)) {
      return null;
    }

    return (
      <React.Fragment>
        <Navigation toggleDrawer={toggleDrawer} tabIndex={drawerIsOpen ? 0 : null} />
        <SessionReady>
          <ToolFinderButton onClick={() => this.setState({ toolFinderIsOpen: true })} />
        </SessionReady>
      </React.Fragment>
    );
  }

  renderHeader() {
    const { drawerIsOpen, toggleDrawer } = this.props;
    const { toolFinderIsOpen } = this.state;

    return (
      <React.Fragment>
        {toolFinderIsOpen && <ToolFinder handleClose={this.closeToolFinder} router={this.props.router} />}

        <Notifications />

        <TaskLoader />

        <SGHeader open={drawerIsOpen} toggleDrawer={toggleDrawer} />
      </React.Fragment>
    );
  }

  render() {
    const { i18nMessages, i18nShowMessages, drawerIsOpen, toggleDrawer } = this.props;
    const { pageMounted } = this.state;
    let messages = i18nMessages;

    if (process.env.APP_ENV !== 'production' && !i18nShowMessages) {
      messages = Object.keys(i18nMessages).map((m) => ({ m }));
    }

    return (
      <IntlProvider locale="en" messages={messages}>
        <PartialLoader resources={PAGE_LOAD_REQUESTS} hideContent initialLoadingState={!Boolean(pageMounted)}>
          <Layout
            context={sgContext}
            onInit={(device) => this.props.deviceActions.onPageInit(device)}
            onResize={(device) => this.props.deviceActions.onPageResize(device)}
            drawerContent={this.renderDrawer()}
            isDrawerOpened={drawerIsOpen}
            onDrawerOverlayClick={toggleDrawer}
            headerContent={this.renderHeader()}
            pageContent={this.renderContent()}
          >
            <DebugDialog />
          </Layout>
        </PartialLoader>
      </IntlProvider>
    );
  }

  onKeyDownHandler(event) {
    const isCmdCtrlKeyPressed = isCmdCtrlKey(event);

    if (event.keyCode === 27) {
      this.setState({ toolFinderIsOpen: false });
    } else if (event.keyCode === 75 && isCmdCtrlKeyPressed) {
      this.setState({ toolFinderIsOpen: true });
      event.preventDefault();
    } else if (event.keyCode === 83 && isCmdCtrlKeyPressed && event.shiftKey && event.altKey) {
      // CTRL + (ALT | OPTION) + SHIFT + S
      this.props.openSGDialog(DIALOGS.DEBUG_DIALOG);
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  session: state.session,
  dialog: state.dialog,
  routing: state.routing,
  i18nMessages: state.i18n.messages,
  i18nShowMessages: state.i18n.showMessages,
  notifications: state.notifications,
  pageItems: state.pageItems,
  lastActions: state.lastActions,
  sitesListEmpty: isSitesListEmpty(state),
  siteToken: getCurrentSiteToken(state),
  siteUnavailable: state.session.siteUnavailable
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(sgDialogActions, dispatch),
  deviceActions: bindActionCreators(deviceActions as any, dispatch),
  sessionActions: bindActionCreators(sessionActions as any, dispatch),
  fetchActions: bindActionCreators(fetchActions as any, dispatch),
  i18nActions: bindActionCreators(i18nActions as any, dispatch)
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(withDrawerState(App));
