import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Column, Grid, Icon, Text, Title } from '@siteground/styleguide';
import LogoTile from '../../../pages/wp-manage/logo-tile';
import { WebMail } from '../index';

type Props = {
  selectedWebmail: WebMail;
  onToggleWebmail: (webmail: WebMail) => any;
  showTextDescription?: boolean;
};

const SelectDefaultWebmail = ({ selectedWebmail, showTextDescription = false, onToggleWebmail }: Props) => {
  return (
    <Grid sm="12" padding="medium">
      <Column smSpan="6">
        <LogoTile
          selected={selectedWebmail === WebMail.Horde}
          onClick={() => onToggleWebmail(WebMail.Horde)}
          icon={<Icon multicolor name="logo-horde" width="200" height="90" />}
          title={
            <Title level="3" color="dark">
              <FormattedMessage id="translate.page.email.webmail-horder.label" />
            </Title>
          }
        />
      </Column>
      <Column smSpan="6">
        <LogoTile
          selected={selectedWebmail === WebMail.RoundCube}
          onClick={() => onToggleWebmail(WebMail.RoundCube)}
          icon={<Icon multicolor name="logo-roundcube" width="200" height="90" />}
          title={
            <Title level="3" color="dark">
              <FormattedMessage id="translate.page.email.webmail-roundcube.label" />
            </Title>
          }
        />
      </Column>
      <Column smSpan="12">
        {showTextDescription && (
          <Text color="light" align="left">
            <FormattedMessage id="translate.page.email.webmail-select.desc" />
          </Text>
        )}
      </Column>
    </Grid>
  );
};

export default SelectDefaultWebmail;
