import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import { siteApi } from '../../../../core/api/site';
import handleAvalonApiRequest from '../../../../core/sagas/handle-avalon-api-request';
import { clearFileManagerStore, fetchDir } from '../../core/actions/file-manager';
import { openFile } from '../actions/code-editor';
import { fetchDirs } from '../actions/file-manager';
import { closeSearchView, SearchForInputPayload, showEntityInExplorer } from '../actions/search-view';
import {
  FILE_MANAGER_FETCH_DIRS_SUCCEEDED,
  FILE_MANAGER_OPEN_FILE_FROM_SEARCH_VIEW,
  FILE_MANAGER_SEARCH_FOR_INPUT,
  FILE_MANAGER_SEARCH_FOR_INPUT_SUCCEEDED,
  FILE_MANAGER_SELECT_ENTITY_IN_NAVIGATION,
  FILE_MANAGER_SHOW_ENTITY_IN_EXPLORER,
  FILE_MANAGER_CLOSE_SEARCH_VIEW
} from '../constants/actions';
import { initialFileManagerState } from '../reducers/utils/initial-state';
import { getPrioritizedEntities } from '../selectors/get-prioritized-entities';
import { getEntityPath, getEntityType } from '../utils';
import { convertPathToFolderArray } from '../utils/convert-path-to-folder-array';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../constants/common';

function* searchSaga({ payload }: { payload: SearchForInputPayload }) {
  const state = yield select();

  yield put(clearFileManagerStore({ selectedContentEntities: initialFileManagerState.selectedContentEntities }));
  const response = yield call(
    siteApi({
      endpoint: '/dir',
      method: 'GET',
      state,
      urlParams: {
        id: payload.root,
        search: payload.search,
        max_entries: 100
      }
    })
  );

  yield put({ type: FILE_MANAGER_SEARCH_FOR_INPUT_SUCCEEDED, data: response.data, root: payload.root });

  return response;
}

function* showEntityInExplorerSaga(action) {
  const { entity } = action.payload;

  const response = yield put(
    fetchDirs({
      entries: convertPathToFolderArray(getEntityPath(entity))
    })
  );

  yield take(FILE_MANAGER_FETCH_DIRS_SUCCEEDED);

  yield put(closeSearchView());

  yield put({ type: FILE_MANAGER_SELECT_ENTITY_IN_NAVIGATION, payload: entity });

  return response;
}

function* openFileFromSearchViewSaga(action) {
  const { entity } = action.payload;

  const { fileManager } = yield select();
  const prioritizedEntities = getPrioritizedEntities(fileManager);
  const file = entity ? entity : prioritizedEntities[0];

  yield put(showEntityInExplorer(file));
  yield put(openFile(file));
}

function* closeSearchViewSaga() {
  const { fileManager } = yield select();
  const selectedNavigationEntity = fileManager.selectedNavigationEntity;
  const selectedNavigationEntityType = getEntityType(selectedNavigationEntity);

  if (selectedNavigationEntityType !== FILE_MANAGER_API_RESPONSE_DIR.FILE) {
    yield put(
      fetchDir({
        urlParams: {
          id: getEntityPath(selectedNavigationEntity)
        }
      })
    );
  }
}

function* searchViewSaga() {
  yield takeEvery(FILE_MANAGER_SEARCH_FOR_INPUT, handleAvalonApiRequest(searchSaga));
  yield takeEvery(FILE_MANAGER_OPEN_FILE_FROM_SEARCH_VIEW, handleAvalonApiRequest(openFileFromSearchViewSaga));
  yield takeEvery(FILE_MANAGER_SHOW_ENTITY_IN_EXPLORER, handleAvalonApiRequest(showEntityInExplorerSaga));
  yield takeEvery(FILE_MANAGER_CLOSE_SEARCH_VIEW, closeSearchViewSaga);
}

export default searchViewSaga;
