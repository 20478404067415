import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import FormInput from '../../../components/form-input';
import FormDropdown from '../../../components/form-dropdown';

type Props = {
  domain: any[];
  intl: Intl;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => void;
};

const formName = REDUX_FORM.CREATE_LOCATION_IP_BLOCK;

const Form = ({ domain, intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required, ipWithExtras } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          name="domain_id"
          type="text"
          options={domain}
          optionValue="id"
          optionLabel="name"
          label={intl.formatMessage({ id: 'translate.page.locationIpBlock.domain_id' })}
          placeholder={intl.formatMessage({ id: 'translate.page.locationIpBlock.domain_id' })}
          searchable
          validate={[required]}
          component={FormDropdown}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="addr"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.ip.ip.range' })}
          validate={[required, ipWithExtras]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.block' })} />
      </Column>
    </SGForm>
  );
};

const LocationIpBlockForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.LOCATION_IP_BLOCK,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(LocationIpBlockForm));
