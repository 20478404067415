import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { PING_AVALON_SUCCESS, PING_NEMO_SUCCESS } from '../constants/ping-backend';

export type PingBackend = {
  isAvalonWorking: boolean;
  isNemoWorking: boolean;
};

const initialState: PingBackend = {
  isAvalonWorking: false,
  isNemoWorking: false
};

export default handleActions<PingBackend, boolean>(
  {
    [PING_AVALON_SUCCESS]: (state: PingBackend, { payload }) => ({
      ...state,
      isAvalonWorking: payload
    }),
    [PING_NEMO_SUCCESS]: (state: PingBackend, { payload }) => ({
      ...state,
      isNemoWorking: payload
    }),
    [Actions.LOGOUT]: () => initialState
  },
  initialState
);
