import {
  UpgradeEntries,
  ReportsResponse,
  NormalizedReports,
  ReportDataRaw,
  MalwareReportEntry,
  BlacklistReportEntry,
  FilescanReportEntry,
  FileScanDataRaw,
  Reports
} from '../definitions/sg-sitescanner';

const URL_MATCHER =
  /: ((https?:\/\/(www\.)?)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/;

const DETAILS_MATCHER = /(\*?)(.+)(Details:) ([\s\S]+)/;

const findMatchingDomain =
  (primaryDomainName: string) =>
  ({ domain = '' }) => {
    const validDomain = domain.replace(/https:\/\/|http:\/\//gi, () => '');
    return Boolean(validDomain && validDomain === primaryDomainName);
  };

export const getSiteScannerUpgradeData = (
  entries: UpgradeEntries[] = [],
  primaryDomainName: string
): {
  upgradeId: string;
  emailReports: boolean;
} => {
  const mainDomainUpgradeEntry = entries.find(findMatchingDomain(primaryDomainName));
  const [defaultEntry, ...others] = entries;

  const upgradeEntry = mainDomainUpgradeEntry || defaultEntry;

  return {
    upgradeId: upgradeEntry && upgradeEntry.upgrade,
    emailReports: upgradeEntry && upgradeEntry.email_reporting
  };
};

export const transformDate = (date: string | number): number => {
  const cleanDate = date.toString();
  return parseInt(cleanDate.split('.')[0], 10);
};

export const normalizeMalware = (malwareReport): MalwareReportEntry[] =>
  malwareReport
    ? malwareReport.map(([urlData, threatAndDetails]) => {
        const urlMatched = urlData && urlData.match(URL_MATCHER);
        const threatDataMatched = threatAndDetails && threatAndDetails.match(DETAILS_MATCHER);

        return {
          url: urlMatched && urlMatched[1],
          threat: threatDataMatched && threatDataMatched[2],
          details: threatDataMatched ? `MATCHED: ${threatDataMatched[4]}` : threatAndDetails
        };
      })
    : [];

export const normalizeBlacklist = (blacklistReport): BlacklistReportEntry[] =>
  blacklistReport
    ? blacklistReport.map(([reportText, url]) => ({
        url,
        details: reportText
      }))
    : [];

export const normalizeFilescanlist = (filescanReport: FileScanDataRaw): FilescanReportEntry[] => {
  return filescanReport
    ? [].concat(filescanReport).map(
        (report): FilescanReportEntry => ({
          suspiciousFiles: report.hits.toString(),
          details: report.content.replaceAll(' [', '\n[').replaceAll('\\n', '\n')
        })
      )
    : [];
};

export const normalizeReportsData = (response: ReportsResponse): NormalizedReports => {
  let output: NormalizedReports = {};

  if (!response.data || !response.data[0]) {
    return output;
  }

  const { data } = response;

  output = data.reduce((outputData, { malware_report, blacklist_report, filescan, date, status, is_manual }) => {
    return {
      ...outputData,
      [`report-${date}`]: {
        date: transformDate(date),
        malware: status === 'timeout' ? [] : normalizeMalware(malware_report),
        blacklist: status === 'timeout' ? [] : normalizeBlacklist(blacklist_report),
        filescan: status === 'timeout' ? [] : normalizeFilescanlist(filescan),
        status,
        is_manual: is_manual || false
      }
    };
  }, {});

  return output;
};
