import { handleActions } from 'redux-actions';
import { sortAscendingByid, sortAscendingByMain } from '../common/sort-by-id';
import * as Actions from '../constants/actions';
import { API_RESOURCE } from '../constants/api';
import formatBackupRestoreFiles from './utils/format-backup-restore-files';

export const initialState: any = {};

const endpointsWithoutId = [API_RESOURCE.EMAIL_FILTER.resourceName];

const endpointsSortableByMainDomain = [
  API_RESOURCE.DOMAIN_ALL.resourceName,
  API_RESOURCE.DOMAIN.resourceName,
  API_RESOURCE.DOMAIN_CACHE.resourceName
];

const reducerMap: any = {
  [Actions.FETCH_ITEMS_SUCCEEDED]: (state, action: any) => {
    let items = action.items;
    const { resourceName } = action;

    if (!resourceName) {
      return state;
    }

    const shouldSortItemsByIdAndMainDomain = Boolean(items && endpointsSortableByMainDomain.includes(resourceName));

    const shouldSortItemsById = Boolean(items && !endpointsWithoutId.includes(resourceName));

    if (resourceName === API_RESOURCE.BACKUP_RESTORE_FILES.resourceName) {
      items = formatBackupRestoreFiles({ state, resourceName, items });
    } else if (shouldSortItemsByIdAndMainDomain) {
      items = items.length ? sortAscendingByMain(sortAscendingByid(items)) : [].concat(items);
    } else if (shouldSortItemsById) {
      items = items.length ? sortAscendingByid(items) : [].concat(items);
    }

    return Object.assign({}, state, {
      [resourceName]: items
    });
  },

  [Actions.FETCH_ITEM_SUCCEEDED]: (state, action: any) => {
    const { item, resourceName } = action;
    const resources = state[resourceName] === undefined ? [] : [].concat(state[resourceName]);
    const overwriteResourceIndex = resources.findIndex((r) =>
      r.id ? r.id === item.id : JSON.stringify(r) === JSON.stringify(item)
    );

    if (overwriteResourceIndex > -1) {
      resources[overwriteResourceIndex] = item;
    } else {
      resources.unshift(item);
    }

    return {
      ...state,
      [action.resourceName]: resources
    };
  },

  [Actions.CREATE_ITEM_SUCCEEDED]: (state, action) => {
    const resourceName = action.parentResourceName ? action.parentResourceName : action.resourceName;

    if (action.dontChangeItemsState || state[resourceName] === undefined) {
      return state;
    }

    const prependToResourceName = [].concat(state[resourceName]);
    prependToResourceName.unshift(action.item);

    const newState = Object.assign({}, state, {
      [resourceName]: prependToResourceName
    });

    const removeDuplicates = (myArr, prop) => {
      return myArr.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
      });
    };

    if (action.resourceName && action.resourceName === 'phpUrlVariable') {
      newState[resourceName] = removeDuplicates(newState[resourceName], 'id');
    }

    return newState;
  },

  [Actions.DELETE_ITEM_SUCCEEDED]: (state, action: DeleteItemSuccessAction) => {
    const resourceName = action.parentResourceName ? action.parentResourceName : action.resourceName;

    return Array.isArray(state[resourceName])
      ? Object.assign({}, state, {
          [resourceName]: state[resourceName].filter((item) => item.id.toString() !== action.item.id.toString())
        })
      : state;
  },

  [Actions.UPDATE_ITEM_SUCCEEDED]: (state, action: any) => {
    if (action.dontChangeItemsState) {
      return state;
    }

    const resourceName = action.parentResourceName ? action.parentResourceName : action.resourceName;
    const newState = Object.assign({}, state);

    if (Array.isArray(newState[resourceName])) {
      newState[resourceName] = newState[resourceName].map((object) => {
        // detects strings that are not able to be casted to int, this hack is needed because some of the backend APIs
        // are responding with ids like '1', '2' ... strings
        if (Number.isNaN(parseInt(object.id, 10))) {
          return object.id === action.item.id ? action.item : object;
        }
        return parseInt(object.id, 10) === parseInt(action.item.id, 10) ? action.item : object;
      });
    } else {
      newState[resourceName] = Array.isArray(action.item) ? action.item : [action.item];
    }

    return newState;
  },

  [Actions.DISABLE_ALL_DOMAIN_CACHE]: (state, action: any) => ({
    ...state,
    domainCache: state.domainCache.map((s) => ({ ...s, static_cache_enabled: 0 }))
  }),

  [Actions.CLEAR_RESOURCE]: (state, { type, payload }) => ({
    ...state,
    [payload.resourceName]: payload.initialState ? payload.initialState : []
  }),

  [Actions.LOGOUT]: () => initialState
};

export default handleActions(reducerMap, initialState);
