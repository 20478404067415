import { browserHistory } from 'react-router';

type Props = {
  pathname?: string;
  urlParam?: any;
  urlParamsToRemove?: any;
};

export const redirectToURL = ({ pathname, urlParam = {}, urlParamsToRemove = [] }: Props) => {
  const currentLocation = browserHistory.getCurrentLocation();
  const params = {
    ...currentLocation.query,
    ...urlParam
  };

  urlParamsToRemove.forEach((key) => {
    delete params[key];
  });

  const search = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    search.append(key, params[key]);
  });

  browserHistory.push({
    pathname: pathname || currentLocation.pathname,
    search: '?' + search.toString()
  });
};

export const redirectLocation = (url: string) => (window.location.href = url);
