import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Column, Grid, Notice, Text } from '@siteground/styleguide';
import { MalwareReportEntry, NormalizedReport } from '../../../../core/definitions/sg-sitescanner';
import customRequestTypes from '../../../../core/constants/custom-request-types';

import CreateBox from '../../../components/create-box';
import WarningsList from '../warnings-list';

type Props = {
  lastReport: NormalizedReport;
  clean: boolean;
  environment?: {
    isPhone: boolean;
  };
  onMalwareDetails: (malware: MalwareReportEntry[]) => any;
  onForceScan: Function;
  onCleanUp: Function;
  loaderMessage?: string;
  canScanFiles: boolean;
};

export const SITESCANNER_SCAN_FORM = 'SITESCANNER_SCAN_FORM';

type Notices = {
  advancedNotice: React.ReactElement;
  legacyNotice: React.ReactElement;
  timeoutNotice: React.ReactElement;
  successNotice: React.ReactElement;
  malwareNotice: React.ReactElement;
};

const getNotices = (props, intl): Notices => {
  const { lastReport, onMalwareDetails } = props;

  return {
    advancedNotice: (
      <Notice
        title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.advanced.scan.label' })}
        background="light"
        type="info"
        shadow={false}
      >
        <Text>{intl.formatMessage({ id: 'translate.page.sg-sitescanner.advanced.scan.desc' })}</Text>
      </Notice>
    ),
    legacyNotice: (
      <Notice
        title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.advanced.scan.legacy.label' })}
        background="light"
        type="info"
        shadow={false}
      >
        <Text>{intl.formatMessage({ id: 'translate.page.sg-sitescanner.advanced.scan.legacy.desc' })}</Text>
      </Notice>
    ),
    timeoutNotice: (
      <Notice
        title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.timeout.label' })}
        type="warning"
        background="light"
        shadow={false}
      >
        <Text>{intl.formatMessage({ id: 'translate.page.sg-sitescanner.report-timeout.label' })}</Text>
      </Notice>
    ),
    successNotice: (
      <Notice
        title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.scan.completed.label' })}
        background="light"
        type="success"
        shadow={false}
      >
        <Text>{intl.formatMessage({ id: 'translate.page.sg-sitescanner.scan.completed.desc' })}</Text>
      </Notice>
    ),
    malwareNotice: (
      <Notice
        title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.threat-found.label' })}
        type="warning"
        background="light"
        shadow={false}
      >
        <WarningsList icon="dot" onMalwareDetails={onMalwareDetails} report={lastReport} />
      </Notice>
    )
  };
};

const CreateContainer = (props: Props) => {
  const { onCleanUp, onForceScan, clean, loaderMessage, lastReport, canScanFiles } = props;
  const [preferInitialNotice, setPreferInitialNotice] = React.useState(false);
  const [scanWasStarted, setScanWasStarted] = React.useState(false);
  const intl = useIntl();
  const { advancedNotice, legacyNotice, timeoutNotice, successNotice, malwareNotice } = getNotices(props, intl);

  const confirmScanButton = (
    <Button
      color="primary"
      action="button"
      data-e2e="create-box-force-scan"
      onClick={() => {
        setPreferInitialNotice(true);
      }}
    >
      {intl.formatMessage({ id: 'translate.page.sg-sitescanner.confirm-scan.label' })}
    </Button>
  );

  const cleanButton = (
    <Button color="primary" action="button" data-e2e="create-box-clean-up" onClick={onCleanUp}>
      {intl.formatMessage({ id: 'translate.page.sg-sitescanner.clean-up.label' })}
    </Button>
  );

  const scanButton = (
    <Button
      color="primary"
      action="button"
      data-e2e="create-box-force-scan"
      onClick={() => {
        const wasForceScanTrigger = onForceScan();

        if (wasForceScanTrigger) {
          setPreferInitialNotice(false);
          setScanWasStarted(true);
        }
      }}
    >
      {lastReport?.status === 'malware' || lastReport?.status === 'blacklisted'
        ? intl.formatMessage({ id: 'translate.page.sg-sitescanner.new-scan.label' })
        : intl.formatMessage({ id: 'translate.page.sg-sitescanner.start-scan.label' })}
    </Button>
  );

  const isTimeout = lastReport?.status === 'timeout';
  const isMalwareFound = lastReport?.status === 'malware' || lastReport?.status === 'blacklisted';
  const initialNotice = canScanFiles ? advancedNotice : legacyNotice;

  let notice = null;
  let buttons = null;

  switch (true) {
    case preferInitialNotice:
      notice = initialNotice;
      buttons = [scanButton];
      break;
    case isTimeout:
      notice = timeoutNotice;
      buttons = [scanButton];
      break;
    case clean && scanWasStarted:
      notice = successNotice;
      buttons = [confirmScanButton];
      break;
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case clean:
      notice = initialNotice;
      buttons = [scanButton];
      break;
    case isMalwareFound:
      notice = malwareNotice;
      buttons = [cleanButton, scanButton];
      break;
    default:
  }

  return (
    <CreateBox
      loaderMessage={loaderMessage}
      title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.create-box.title' })}
      formName={SITESCANNER_SCAN_FORM}
      hideContent
      loaderPosition="relative"
      resources={[
        { requestTypeName: customRequestTypes.GET_SITE_SCANNER_UPGRADES },
        { requestTypeName: customRequestTypes.GET_SITE_SCANNER_REPORTS },
        { requestTypeName: customRequestTypes.REQUEST_FORCE_SCAN },
        { requestTypeName: customRequestTypes.GET_SITE_SCANNER_REPORTS_STATUS }
      ]}
    >
      <Grid sm="12" padding="responsive" gap="responsive">
        <React.Fragment>
          <Column smSpan="12">{notice}</Column>
          <Column smSpan="12">{buttons}</Column>
        </React.Fragment>
      </Grid>
    </CreateBox>
  );
};

export default connect<any, any, Props>((state) => ({
  environment: state.environment
}))(CreateContainer);
