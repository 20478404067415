import * as React from 'react';
import { Button, Context, Grid, Link } from '@siteground/styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import { generateEntitiesCountLabel, getDbEntityLabelByName } from '../../../../core/utils/db-entities';
import { formatCheckboxesData } from '../../../../core/utils/db-forms';
import { AddDbToUser, ChangePassword, EditDbLabel, EditDbUser } from '../../../components/common-forms';
import DbGrantsList from '../../../components/db-grants-list';
import { DeleteDialog } from '../../../components/dialogs';
import indexWithCRUD from '../../../components/indexWithCRUD';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../../components/sg-dialog';
import SGTable from '../../../components/sg-table';
import TableContextMenu from '../../../components/table-context-menu/table-context-menu';
import VCS from '../../../components/visibility-control-service';
import withRouteTo from '../../../components/with-route-to';
import Create from './create';

type DbMysqlUserProps = {
  actions: CrudActions;
  items: any;
  location: any;
  intl: Intl;
  openSGDialog: Function;
  closeSGDialog: Function;
};

const { endpoint, resourceName, resourceNameMetaApi } = API_RESOURCE.MYSQL_DB_USER;
const RouteButton = withRouteTo(Button);

class DbMysqlUserPage extends React.Component<DbMysqlUserProps, any> {
  readonly state = {
    currentUpdatePayload: null,
    currentDeletePayload: null,
    currentChangePasswordPayload: null,
    addDatabaseToUserPayload: null,
    editUserPayload: null,
    selectedUserPayload: null
  };

  openManageDatabasesDialog = ({ id, name }) => {
    this.setState({ selectedUserPayload: { id, name } }, () => this.props.openSGDialog(DIALOGS.LIST_DB_ENTITIES));
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_MYSQL_USERS,
          success: {
            intlKey: 'translate.page.database_user.created_msg'
          },
          error: {
            intlKey: 'translate.page.database_user.created.error.message'
          }
        }
      }
    });
  };

  render() {
    const { intl, items, openSGDialog } = this.props;
    const { dbMysqlGrant = [], dbMysqlUser } = items;
    const users =
      dbMysqlUser &&
      dbMysqlUser.map((user) => ({
        ...user,
        usersDatabases: dbMysqlGrant.filter(({ dbuser_id }) => dbuser_id === user.id)
      }));

    return (
      <Grid>
        <Create
          onSubmit={this.onCreateFormSubmit}
          onAddDatabaseToUser={(entity) =>
            this.setState(
              {
                addDatabaseToUserPayload: entity
              },
              () => openSGDialog(REDUX_FORM.ADD_DB_TO_USER)
            )
          }
        />

        <VCS resourceName={resourceNameMetaApi} hasMethod="GET">
          <SGTable
            title={intl.formatMessage({ id: 'translate.page.database_user.list.title' })}
            data={users}
            columns={[
              { header: intl.formatMessage({ id: 'translate.generic.name' }), accessor: 'name', mSize: '30%' },
              {
                header: intl.formatMessage({ id: 'translate.generic.label' }),
                accessor: 'label',
                mSize: '50%'
              },
              {
                header: intl.formatMessage({ id: 'translate.page.database_user.database.access' }),
                accessor: 'id',
                mSize: '180px',
                render: (id, { name, usersDatabases }) => (
                  <Link
                    onClick={() =>
                      this.setState(
                        {
                          selectedUserPayload: { id, name }
                        },
                        () => openSGDialog(DIALOGS.LIST_DB_ENTITIES)
                      )
                    }
                  >
                    {generateEntitiesCountLabel(
                      usersDatabases,
                      intl.formatMessage({ id: 'translate.generic.database' }),
                      intl.formatMessage({ id: 'translate.generic.databases' })
                    )}
                  </Link>
                )
              },
              {
                header: intl.formatMessage({ id: 'translate.generic.actions' }),
                accessor: 'id',
                render: this.renderContextMenu
              }
            ]}
            resources={[
              {
                resourceName: API_RESOURCE.MYSQL_DB.resourceName,
                methods: ['GET']
              },
              {
                resourceName: API_RESOURCE.MYSQL_DB_GRANT.resourceName,
                methods: ['GET']
              },
              {
                resourceName: API_RESOURCE.MYSQL_DB_USER.resourceName,
                methods: ['GET']
              }
            ]}
            noDataMessage="translate.page.database_user.sg-table.no-data.message"
          />
        </VCS>

        {this.renderUpdateComponent()}
        {this.renderUserDatabases()}
        {this.renderDatabaseEditUserComponent()}
        {this.renderChangePasswordComponent()}
        {this.renderDialogAddDatabaseToUser()}
        {this.renderDeleteConformationDialogComponent()}
        {this.renderNoDatabaseDialog()}
      </Grid>
    );
  }

  renderUserDatabases = () => {
    const { intl, items, openSGDialog } = this.props;
    const { selectedUserPayload } = this.state;
    const userId = selectedUserPayload && selectedUserPayload.id;
    const userName = selectedUserPayload && selectedUserPayload.name;
    const grants = (items && items.dbMysqlGrant) || [];
    const databases = grants.filter(({ dbuser_id }) => dbuser_id === userId);

    return (
      <Context.Consumer>
        {({ device }) => (
          <SGDialog
            id={DIALOGS.LIST_DB_ENTITIES}
            title={intl.formatMessage({ id: 'translate.page.mysql.db-list-dialog.title' }, { user: userName })}
            size="x-large"
            density={device.isPhone ? 'medium' : 'small'}
            icon="edit"
            state="warning"
            footer={
              <SGDialogCancel
                id={DIALOGS.LIST_DB_ENTITIES}
                label={intl.formatMessage({ id: 'translate.generic.close' })}
              />
            }
          >
            <DbGrantsList
              entityType="database"
              entities={databases}
              getLabelByName={(label) => getDbEntityLabelByName(label, items.dbMysqlDb)}
              renderContextMenu={(db) => (
                <TableContextMenu
                  resourceName={API_RESOURCE.MYSQL_DB_GRANT.resourceNameMetaApi}
                  items={[
                    {
                      vcsMethod: 'PUT',
                      icon: 'product-server',
                      label: intl.formatMessage({ id: 'translate.page.protected.user.manage.access' }),
                      e2eAttr: 'table-action-provide-database',
                      visibleOnDesktop: true,
                      onClick: () =>
                        this.setState(
                          {
                            editUserPayload: {
                              grant: db,
                              db_id: db.db_id,
                              reversed: 1
                            }
                          },
                          () => openSGDialog(REDUX_FORM.EDIT_DB_USER)
                        )
                    }
                  ]}
                />
              )}
            />
          </SGDialog>
        )}
      </Context.Consumer>
    );
  };

  renderUpdateComponent = () => {
    const { actions, intl, closeSGDialog } = this.props;
    const { currentUpdatePayload } = this.state;
    const name = currentUpdatePayload && currentUpdatePayload.name;

    return (
      <SGDialogForm
        name={REDUX_FORM.EDIT_DB_LABEL}
        title={intl.formatMessage({ id: 'translate.page.user.update.title' }, { name })}
        resources={[
          {
            resourceName: API_RESOURCE.MYSQL_DB_USER.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <EditDbLabel
          initialValues={currentUpdatePayload}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.EDIT_DB_LABEL))}
        />
      </SGDialogForm>
    );
  };

  renderDatabaseEditUserComponent = () => {
    const { intl, items, actions, closeSGDialog } = this.props;
    const grant = this.state.editUserPayload && this.state.editUserPayload.grant;
    const name = grant && grant.db_name;
    const userName = grant && grant.dbuser_name;

    return (
      <SGDialogForm
        name={REDUX_FORM.EDIT_DB_USER}
        icon="profile"
        title={intl.formatMessage({ id: 'translate.page.database_user.edit_access_by_user' }, { name })}
        resources={[
          {
            resourceName: API_RESOURCE.MYSQL_DB_GRANT.resourceName,
            methods: ['PUT', 'DELETE']
          }
        ]}
      >
        <EditDbUser
          userData={this.state.editUserPayload}
          dbUsers={items[API_RESOURCE.MYSQL_DB_USER.resourceName]}
          dbGrant={items[API_RESOURCE.MYSQL_DB_GRANT.resourceName]}
          onSubmit={({ grantForEdit, checkboxes }) => {
            const grants = formatCheckboxesData(checkboxes);
            const shouldUpdatePerms = grants.all || grants.perms.length > 0;

            if (shouldUpdatePerms) {
              actions.updateItem(
                {
                  id: grantForEdit.id,
                  db_id: grantForEdit.db_id,
                  dbuser_id: grantForEdit.dbuser_id,
                  grants,
                  _metaFields: {
                    ...API_RESOURCE.MYSQL_DB_GRANT
                  },
                  _meta: {
                    notification: {
                      type: 'generic',
                      success: {
                        intlKey: 'translate.page.database_user.edit_access_by_user.success.message',
                        intlValues: { name: userName }
                      },
                      error: {
                        intlKey: 'translate.page.database_user.edit_access_by_user.error.message',
                        intlValues: { name: userName }
                      }
                    }
                  }
                },
                () => closeSGDialog(REDUX_FORM.EDIT_DB_USER)
              );
            } else {
              actions.deleteItem(
                {
                  ...grantForEdit,
                  itemId: grantForEdit.id,
                  _metaFields: { ...API_RESOURCE.MYSQL_DB_GRANT },
                  _meta: {
                    notification: {
                      type: 'generic',
                      success: {
                        intlKey: 'translate.page.database_user.edit_access_by_user.success.message',
                        intlValues: { name: userName }
                      },
                      error: {
                        intlKey: 'translate.page.database_user.edit_access_by_user.error.message',
                        intlValues: { name: userName }
                      }
                    }
                  }
                },
                () => closeSGDialog(REDUX_FORM.EDIT_DB_USER)
              );
            }
          }}
        />
      </SGDialogForm>
    );
  };

  renderChangePasswordComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentChangePasswordPayload } = this.state;
    const name = currentChangePasswordPayload && currentChangePasswordPayload.name;

    return (
      <SGDialogForm
        name={REDUX_FORM.GENERIC_PASSWORD}
        icon="lock"
        title={intl.formatMessage({ id: 'translate.page.database.change.password.title' }, { name })}
        resources={[
          {
            resourceName: API_RESOURCE.MYSQL_DB_USER.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <ChangePassword
          initialValues={currentChangePasswordPayload}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.GENERIC_PASSWORD))}
        />
      </SGDialogForm>
    );
  };

  renderDialogAddDatabaseToUser = () => {
    const { intl, items, actions, closeSGDialog } = this.props;
    const { addDatabaseToUserPayload } = this.state;
    const name = addDatabaseToUserPayload && addDatabaseToUserPayload.name;

    return (
      <SGDialogForm
        name={REDUX_FORM.ADD_DB_TO_USER}
        icon="product-server"
        title={intl.formatMessage({ id: 'translate.page.database.add.database.title' }, { name })}
        resources={[
          {
            resourceName: API_RESOURCE.MYSQL_DB_GRANT.resourceName,
            methods: ['POST']
          }
        ]}
      >
        <AddDbToUser
          rowData={addDatabaseToUserPayload}
          dbData={items[API_RESOURCE.MYSQL_DB.resourceName]}
          dbGrant={items[API_RESOURCE.MYSQL_DB_GRANT.resourceName]}
          onSubmit={({ db_id, checkboxes }) => {
            actions.createItem(
              {
                _metaFields: {
                  ...API_RESOURCE.MYSQL_DB_GRANT
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.database_user.add.db.success.message',
                      intlValues: { name }
                    },
                    error: {
                      intlKey: 'translate.page.database_user.add.db.error.message',
                      intlValues: { name }
                    }
                  }
                },
                dbuser_id: addDatabaseToUserPayload && addDatabaseToUserPayload.id,
                db_id,
                grants: formatCheckboxesData(checkboxes)
              },
              () => closeSGDialog(REDUX_FORM.ADD_DB_TO_USER)
            );
          }}
        />
      </SGDialogForm>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeletePayload;
    const entityName = deletePayload && deletePayload.name;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.database_user.delete.dialog.title' }, { name: entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderNoDatabaseDialog = () => {
    const { intl, closeSGDialog } = this.props;

    return (
      <SGDialog
        id={DIALOGS.MYSQL_NO_DATABASES}
        title={intl.formatMessage({ id: 'translate.page.database_user.no.database.title' })}
        icon="add-user"
        state="info"
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={DIALOGS.MYSQL_NO_DATABASES}
              label={intl.formatMessage({ id: 'translate.generic.cancel' })}
            />

            <RouteButton
              color="primary"
              toPage={ROUTES[ToolId.mysqlDatabase]}
              onClick={() => closeSGDialog(DIALOGS.MYSQL_NO_DATABASES)}
            >
              {intl.formatMessage({ id: 'translate.page.database.create.button.database' })}
            </RouteButton>
          </React.Fragment>
        }
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const name = entity.name;
    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name,
      _metaFields: { ...API_RESOURCE.MYSQL_DB_USER },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.database_user.delete.success.message',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_user.delete.error.message',
            intlValues: { name }
          }
        }
      }
    };
    const updatePayload: UpdateItemPayload = {
      _metaFields: { ...API_RESOURCE.MYSQL_DB_USER },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.database_user.change.label.success.message',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_user.change.label.error.message',
            intlValues: { name }
          }
        }
      },
      ...entity
    };
    const changePasswordPayload: UpdateItemPayload = {
      _metaFields: { ...API_RESOURCE.MYSQL_DB_USER },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.database_user.change.password.success.message',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_user.change.password.error.message',
            intlValues: { name }
          }
        }
      },
      id: entity.id,
      name: entity.name
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'POST',
            icon: 'product-server',
            label: intl.formatMessage({ id: 'translate.page.database_user.user.add.access' }),
            e2eAttr: 'table-action-provide-database',
            visibleOnDesktop: true,
            onClick: () => {
              if (this.props.items.dbMysqlDb.length === 0) {
                openSGDialog(DIALOGS.MYSQL_NO_DATABASES);

                return;
              }

              if (entity.usersDatabases.length === this.props.items.dbMysqlDb.length) {
                this.openManageDatabasesDialog({ id, name });

                return;
              }

              this.setState({ addDatabaseToUserPayload: entity }, () => openSGDialog(REDUX_FORM.ADD_DB_TO_USER));
            }
          },
          {
            vcsMethod: 'PUT',
            icon: 'lock',
            label: intl.formatMessage({ id: 'translate.generic.change.password' }),
            e2eAttr: 'table-action-edit-password',
            onClick: () =>
              this.setState(
                {
                  currentChangePasswordPayload: changePasswordPayload
                },
                () => openSGDialog(REDUX_FORM.GENERIC_PASSWORD)
              )
          },
          {
            vcsMethod: 'PUT',
            icon: 'edit',
            label: intl.formatMessage({ id: 'translate.page.database.edit' }),
            e2eAttr: 'table-action-edit',
            onClick: () =>
              this.setState(
                {
                  currentUpdatePayload: updatePayload
                },
                () => openSGDialog(REDUX_FORM.EDIT_DB_LABEL)
              )
          },
          {
            vcsMethod: 'DELETE',
            icon: 'trash',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-delete',
            onClick: () =>
              this.setState(
                {
                  currentDeletePayload: deletePayload
                },
                () => openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };
}

export default indexWithCRUD(undefined, { ...sgDialogActions })(
  DbMysqlUserPage,
  API_RESOURCE.MYSQL_DB_USER,
  API_RESOURCE.MYSQL_DB_GRANT,
  API_RESOURCE.MYSQL_DB
);
