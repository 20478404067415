import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Column, Flex, Title } from '@siteground/styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import { WP_LANGUAGES } from '../../../../core/constants/languages';
import { handleDefaultPathSelection, normalizeDirectoryData, normalizePathInput } from '../../../../core/utils/paths';
import SelectPath from '../../../components/dialogs/select-path';
import FormCheckbox from '../../../components/form-checkbox';
import FormDropdown from '../../../components/form-dropdown';
import FormInput from '../../../components/form-input';
import FormPassword from '../../../components/form-password';
import InformationIcon from '../../../components/information-icon';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import { FIELDS } from '../../app-manager/constants';
import { WORDPRESS, WORDPRESS_WOOCOMMERCE } from '../constants';
import LogoTile from '../logo-tile';
import {
  getCustomPathOptions,
  getInstallationPathMessage,
  getIsCustomPathSelected
} from '../../app-manager/create/form';

type Props = {
  intl: Intl;
  change: Function;
  formValues: Function;
  domain: any[];
  sPanelFormSubmit: (formName) => null;
  setParentState: (object) => null;
  selectedInstallation: string;
  userLang: string;
  validationUtils: ValidationUtils;
  openSGDialog: typeof sgDialogActions.openSGDialog;
};

const formName = REDUX_FORM.CREATE_WP_MANAGE;

class Form extends React.Component<Props, any> {
  componentDidMount() {
    const { domain, userLang, change } = this.props;
    const lang = userLang === 'en' ? 'en_US' : userLang;

    change('lang', lang);
    change(FIELDS.INSTALL_WP_STARTER, !CONFIG.IS_RESELLER);
  }

  validateFolder = (name, fields, formProps, fieldName) => {
    const { validationUtils } = this.props;
    const validation = validationUtils.folder(name, fields, formProps, fieldName);

    return validation && this.props.intl.formatMessage({ id: 'translate.validations.invalid_subfolder' });
  };

  getHintMessage = (): string => {
    const { intl, domain } = this.props;
    const values = this.props.formValues(formName);

    return getInstallationPathMessage(intl, values, domain);
  };

  renderFields() {
    const { domain, intl, validationUtils, formValues } = this.props;
    const { email, required, password, validationWithMetaApi, adminName } = validationUtils;

    const values = this.props.formValues(formName);
    const customPathOptions = getCustomPathOptions(intl);

    const selectedDomainUrl = domain.find((d) => values.domain_id === d.id)?.name + '/';
    const isCustomPathSelected = getIsCustomPathSelected(values[FIELDS.URL_PATH_OPTION], customPathOptions);

    return (
      <React.Fragment>
        <Column smSpan="12">
          <Title level="6" color="dark" density="none">
            {intl.formatMessage({ id: 'translate.page.wp.manage.create.application.setup' })}
          </Title>
        </Column>

        <Column smSpan="6">
          <Field
            name="domain_id"
            label={intl.formatMessage({ id: 'translate.page.wp.manage.create.select.domain' })}
            options={domain}
            optionValue="id"
            optionLabel="name"
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="lang"
            label={intl.formatMessage({ id: 'translate.page.wp.manage.create.select.language' })}
            options={WP_LANGUAGES}
            optionValue="id"
            optionLabel="label"
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            label={
              <Flex align="center">
                {/* TODO: refactor through the project. There are multiple code blocks like this through the project.  */}
                {intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.path' })}
                &nbsp;
                <InformationIcon
                  tooltip={intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.path.tooltip' })}
                />
              </Flex>
            }
            name={FIELDS.URL_PATH_OPTION}
            options={customPathOptions}
            optionValue="id"
            optionLabel="name"
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          {isCustomPathSelected && (
            <Field
              label={intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.folder' })}
              name={FIELDS.URL_PATH}
              component={FormInput}
              validate={[required, this.validateFolder]}
              placeholder={intl.formatMessage({ id: 'translate.page.wp.manage.path.placeholder' })}
              normalize={normalizePathInput}
              prefix={selectedDomainUrl}
            />
          )}
        </Column>

        <Column smSpan="12">
          <Title level="6" color="dark" density="none">
            {intl.formatMessage({ id: 'translate.page.wp.manage.create.admin.info' })}
          </Title>
        </Column>

        <Column smSpan="6">
          <Field
            name="admin_name"
            label={intl.formatMessage({ id: 'translate.generic.username' })}
            validate={[required, adminName, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            style={{ display: 'none' }}
            name="admin_pass"
            label="fake_password_to_prevent_autofill"
            data-e2e="fake-password"
            component={FormPassword}
            aria-hidden
          />
          <Field
            name="admin_pass"
            label={intl.formatMessage({ id: 'translate.generic.password' })}
            validate={[required, password]}
            component={FormPassword}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="admin_email"
            label={intl.formatMessage({ id: 'translate.generic.email' })}
            validate={[required, email]}
            normalize={(value) => value.toLowerCase()}
            component={FormInput}
          />
        </Column>

        <Column smSpan="12">
          <Title level="6" color="dark" density="none">
            {intl.formatMessage({ id: 'translate.page.wp.manage.create.add.ons' })}
          </Title>
        </Column>

        <Column smSpan="12">
          {!CONFIG.IS_RESELLER && (
            <Flex align="flex-end">
              <Field name={FIELDS.INSTALL_WP_STARTER} decoration="dotted" component={FormCheckbox} expand>
                {intl.formatMessage({ id: 'translate.page.wp.manage.create.enable.wp-starter' })}
                &nbsp;
                <InformationIcon
                  tooltip={intl.formatMessage({ id: 'translate.page.wp.manage.create.enable.wp-starter.text.tooltip' })}
                />
              </Field>
            </Flex>
          )}
          <Flex align="flex-end">
            <Field name={FIELDS.IS_WPMU} decoration="dotted" component={FormCheckbox} expand>
              {intl.formatMessage({ id: 'translate.page.wp.manage.create.install.with.wpmu' })}
              &nbsp;
              <InformationIcon
                tooltip={intl.formatMessage({ id: 'translate.page.wp.manage.create.install.with.wpmu.tooltip' })}
              />
            </Field>
          </Flex>
        </Column>

        <Column smSpan="12">
          <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.install' })} />
        </Column>
      </React.Fragment>
    );
  }

  render() {
    const { change, domain, sPanelFormSubmit, selectedInstallation, setParentState } = this.props;
    const formValues = this.props.formValues(formName);
    const selectedDomain = domain.find((d) => formValues.domain_id === d.id);
    const publicHTML = '/public_html';
    const initialPath = selectedDomain && selectedDomain.name && `/${selectedDomain.name}`;
    const prefetchPaths = initialPath && [`${initialPath}${publicHTML}`];
    const pathToReplace = selectedDomain && `/${selectedDomain.name}${publicHTML}`;

    return (
      <SGForm onSubmit={sPanelFormSubmit(formName)}>
        <Column smSpan="6" flex>
          <LogoTile
            type={WORDPRESS}
            selected={selectedInstallation === WORDPRESS}
            onClick={() => setParentState({ selectedInstallation: WORDPRESS })}
          />
        </Column>

        <Column smSpan="6" flex>
          <LogoTile
            type={WORDPRESS_WOOCOMMERCE}
            selected={selectedInstallation === WORDPRESS_WOOCOMMERCE}
            onClick={() => setParentState({ selectedInstallation: WORDPRESS_WOOCOMMERCE })}
          />
        </Column>

        {selectedInstallation && this.renderFields()}

        <SelectPath
          initialPath={initialPath}
          prefetch={prefetchPaths}
          expandedNodes={[publicHTML]}
          normalizeDirectoryData={normalizeDirectoryData}
          onChange={(path) => this.props.change('path', handleDefaultPathSelection(path, pathToReplace))}
        />
      </SGForm>
    );
  }
}

const WpMangeForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.WORDPRESS,
      fetchItemsOnSuccess: [API_RESOURCE.APP],
      formName
    }
  }
})(Form);

export default connect<{}, {}, any>(undefined, { ...sgDialogActions })(withSpanelForm(injectIntl(WpMangeForm)));
