import * as React from 'react';
import {
  Container,
  Grid,
  Notice,
  Section,
  Tabs,
  Tab,
  Text,
  textToHTML,
  Button,
  Flex,
  Checkbox,
  Link
} from '@siteground/styleguide';
import { ToolId } from '../../../core/constants/route-info';
import PageHeader from '../../components/page-header';
import { SecondLevelTitle } from '../../components/titles';
import indexWithCRUD from '../../components/indexWithCRUD';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { RootState } from '../../reducers';
import { findDomainById } from '../php-settings/utils';
import CDNCannotBeActivatedCentralDNS from './dialogs/cdn-cannot-be-activated-central-dns';
import CNDActivateFreeCF from './dialogs/cdn-activate-free-cf';
import CNDActivatePaidCF from './dialogs/cdn-activate-paid-cf';
import { DIALOGS } from '../../../core/constants/common';
import { getCurrentSiteToken, getLanguagePrefix } from '../../../core/selectors';
import CDNNotWorkingCentralDNS from './dialogs/cdn-not-pointed-central-dns';
import CDNSettings from './settings';
import CDNNotAvailableBeta from './dialogs/cdn-not-available-beta';
import { navigateToUA } from '../../../core/actions/nemo-store';
import { findDomainByNameInAll } from './utils';
import PartialLoader from '../../components/partial-loader';
import DomainSelect from '../../components/domain-select/domain-select';
import { getCurrencyData } from '../../../core/selectors/session';
import { getCDNRenewPrice, getCDNTermsLink } from './constants';
import { FormattedMessage } from 'react-intl';
import { createNotification } from '../../../core/actions/notifications';
import { getScoId } from '../../../core/selectors/site-meta-api';

type Props = {
  intl: Intl;
  domainName: any;
  actions: CrudActions;
  items: any;
  openSGDialog: Function;
  closeSGDialog: Function;
  siteToken: any;
  language: string;
  scoId: number;
  currency: any;
  navigateToUA: typeof navigateToUA;
  createNotification: typeof createNotification;
};

type State = {
  domain: any;
  zoneDomain: any;
  activeTab: string;
  betaTOS: boolean;
};

const TAB = {
  SETTINGS: 'SETTINGS'
};

export class CDN extends React.Component<Props, State> {
  readonly state = {
    domain: { name: null, id: null, extra: null, domain_type: null },
    zoneDomain: { id: null },
    activeTab: TAB.SETTINGS,
    betaTOS: false
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.items === this.props.items) {
      return null;
    }

    const { domainName, items } = this.props;
    const domain = findDomainByNameInAll({ domainName, domains: items.domainAll });

    this.setState({ domain });
  }

  enableCDN = (cacheEnabled, id) => {
    const { actions } = this.props;
    const meta: { notification: SpanelNotification } = {
      notification: {
        type: 'generic',
        success: {
          intlKey: cacheEnabled
            ? 'translate.page.cdn.enable.success.notification'
            : 'translate.page.cdn.disable.success.notification'
        },
        error: {
          intlKey: cacheEnabled
            ? 'translate.page.cdn.enable.fail.notification'
            : 'translate.page.cdn.disable.fail.notification'
        }
      }
    };

    actions.updateItem({
      id,
      _metaFields: {
        ...API_RESOURCE.DOMAIN_CDN
      },
      _meta: meta,
      cdn_enabled: Number(cacheEnabled)
    });
  };

  renderTabs() {
    const { intl } = this.props;

    return (
      <div>
        <Tabs border="light">
          <Tab
            active={this.state.activeTab === TAB.SETTINGS}
            data-e2e="settings"
            onClick={() => this.setState({ activeTab: TAB.SETTINGS })}
          >
            {intl.formatMessage({ id: 'translate.page.cloudflare.tab.setting' })}
          </Tab>
        </Tabs>
      </div>
    );
  }

  renderBetaConsent() {
    const { intl, language, currency, scoId } = this.props;

    return (
      <Section>
        <Notice
          title={intl.formatMessage({ id: 'translate.page.cdn.try.new.feature.beta.title' })}
          background="light"
          type="instruction"
          shadow={false}
          border={false}
        >
          <Grid gap="responsive" justifyItems="flex-start">
            <Text>{textToHTML(intl.formatMessage({ id: 'translate.page.cdn.try.new.feature.beta.desc' }))}</Text>
            <Text italic>
              {textToHTML(
                intl.formatMessage(
                  { id: 'translate.page.cdn.try.new.feature.beta.desc.footer' },
                  { price: getCDNRenewPrice(currency) }
                )
              )}
            </Text>

            <Flex background="light">
              <Checkbox
                value="all"
                checked={this.state.betaTOS}
                onChange={(event) => this.setState({ betaTOS: !this.state.betaTOS })}
              >
                <Text>
                  {intl.formatMessage(
                    { id: 'translate.page.cdn.try.new.feature.beta.tos' },
                    {
                      link: (
                        <Link href={getCDNTermsLink(language, scoId)} target="_blank">
                          <FormattedMessage id="translate.page.cdn.try.new.feature.beta.tos.link" />
                        </Link>
                      )
                    }
                  )}
                </Text>
              </Checkbox>
            </Flex>

            <Button
              type="outlined"
              color="secondary"
              onClick={() => {
                const { betaTOS, domain } = this.state;

                if (betaTOS) {
                  const { actions } = this.props;
                  const meta: { notification: SpanelNotification } = {
                    notification: {
                      type: 'generic',
                      success: {
                        intlKey: 'translate.page.cdn.beta.consent.enable.success.notification'
                      },
                      error: {
                        intlKey: 'translate.page.cdn.beta.consent.enable.fail.notification'
                      }
                    }
                  };

                  actions.updateItem({
                    id: domain.id,
                    _metaFields: {
                      ...API_RESOURCE.DOMAIN_ALL,
                      fetchItemsOnSuccess: [API_RESOURCE.DOMAIN_ALL]
                    },
                    _meta: meta,
                    extra_public: {
                      cdn_consent: Date.now()
                    }
                  });
                } else {
                  this.props.createNotification({
                    type: 'generic',
                    state: 'error',
                    error: {
                      intlKey: 'translate.page.cdn.beta.consent.enable.fail.notification'
                    }
                  });
                }
              }}
            >
              {intl.formatMessage({ id: 'translate.page.try.cdn.new.feature.beta.button' })}
            </Button>
          </Grid>
        </Notice>
      </Section>
    );
  }

  renderContent() {
    const { intl, items, openSGDialog, language, currency } = this.props;
    const domains = items.domainCDN ? [].concat(items.domainCDN).reverse() : [];

    return (
      <React.Fragment>
        <Section>
          <Notice type="warning">
            {intl.formatMessage({ id: 'translate.navigation.page.content.delivery.network.notice' })}
          </Notice>
        </Section>
        <Section>
          <DomainSelect
            mainDomain={this.state.domain?.id}
            options={domains}
            domainResourceName={API_RESOURCE.DOMAIN_ALL.resourceName}
            selectedValue={this.state.domain?.id}
            optionValue="id"
            optionLabel="name"
            onChange={(id) => {
              const domain = findDomainById({ domainId: id, domain: this.props.items.domainAll });

              if (domain.domain_type !== 'main_domain') {
                this.setState({ domain: { id: id, name: null, extra: null } });
                return openSGDialog(DIALOGS.CDN_NOT_AVAILABLE_BETA);
              }

              this.setState({ domain });
            }}
          />
        </Section>

        <Section>
          <SecondLevelTitle>
            {intl.formatMessage({ id: 'translate.navigation.page.content.delivery.network.table.title' })}
          </SecondLevelTitle>

          <Container padding="none">
            {this.renderTabs()}
            <CDNSettings
              navigateToUA={this.props.navigateToUA}
              domain={this.state.domain}
              items={items}
              openSGDialog={openSGDialog}
              setZoneDomain={(zoneDomain, domain) => this.setState({ zoneDomain, domain })}
              language={language}
              currency={currency}
              actions={this.props.actions}
            />
          </Container>
        </Section>
        <CNDActivatePaidCF siteToken={this.props.siteToken} />
        <CDNCannotBeActivatedCentralDNS />
        <CDNNotWorkingCentralDNS />
        <CDNNotAvailableBeta />
        <CNDActivateFreeCF
          onDeleteCloudflareZone={() => {
            const { actions } = this.props;
            const { zoneDomain, domain } = this.state;

            actions.deleteItem(
              {
                _metaFields: {
                  ...API_RESOURCE.CLOUDFLARE_ZONE
                },
                itemId: zoneDomain.id
              },
              () =>
                actions.fetchItems(API_RESOURCE.CLOUDFLARE_ZONE, () => {
                  this.enableCDN(1, domain.id);
                  this.props.closeSGDialog(DIALOGS.CDN_ACTIVATE_FREE_CF);
                })
            );
          }}
        />
      </React.Fragment>
    );
  }

  render() {
    const { intl } = this.props;
    const { domain } = this.state;

    return (
      <React.Fragment>
        <PartialLoader
          hideContent
          resources={[
            {
              resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
              methods: ['PUT']
            },
            {
              resourceName: API_RESOURCE.DOMAIN_CDN.resourceName,
              methods: ['GET']
            },
            {
              resourceName: API_RESOURCE.LOCATION.resourceName,
              methods: ['GET']
            }
          ]}
        >
          <PageHeader
            id={ToolId.cdn}
            instructions={intl.formatMessage({ id: 'translate.navigation.page.content.delivery.network.description' })}
          />

          <Grid padding={['medium', 'none', 'none', 'none']}>
            {domain && domain.domain_type === 'main_domain' && !domain.extra?.cdn_consent
              ? this.renderBetaConsent()
              : this.renderContent()}
          </Grid>
        </PartialLoader>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  currency: getCurrencyData(state),
  language: getLanguagePrefix(state),
  siteToken: getCurrentSiteToken(state),
  scoId: getScoId(state),
  items: state.pageItems,
  domainName: state.sites.currentDomainName
});

export default indexWithCRUD(mapStateToProps, { ...sgDialogActions, navigateToUA, createNotification })(
  CDN,
  API_RESOURCE.DOMAIN_CDN,
  API_RESOURCE.DOMAIN_ALL,
  API_RESOURCE.CLOUDFLARE_ZONE,
  API_RESOURCE.LOCATION
);
