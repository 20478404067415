import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as fetchActions from '../../actions/fetch';

import * as sgSiteScannerActions from '../../actions/pages/sg-sitescanner';
import { clientApi } from '../../api/client';

import {
  CHECK_REPORT_STATUS,
  FORCE_SCAN,
  REQUEST_SG_SITESCANNER_DATA,
  REQUEST_SG_SITESCANNER_REPORTS,
  SET_SG_SITESCANNER_REPORTS
} from '../../constants/actions';

import { sgSiteScannerDataTypes } from '../../constants/sg-sitescanner';

import * as sgSiteScannerTypes from '../../definitions/sg-sitescanner';
import { isACLActionEnabled } from '../../selectors/site-meta-api';
import { getToolMainEndpoint } from '../../selectors/menu-items';

import { getSiteScannerUpgradeData, normalizeReportsData } from '../../utils/sg-sitescanner';
import { handleNemoApiRequest } from '../handle-nemo-api-request';
import { delay } from 'redux-saga';

function* getSgSiteScannerUpgrades({ siteId }) {
  const { session } = yield select();

  return yield call(clientApi(`/sg-site-scanner/upgrades/${siteId}?_client_token=${session.clientToken}`, 'GET'));
}

function* getSgSiteScannerReports({ upgradeId }) {
  const { session } = yield select();

  return yield call(clientApi(`/sg-site-scanner/reports/${upgradeId}?_client_token=${session.clientToken}`, 'GET'));
}

function* requestForceScan({ upgradeId, filescan }) {
  const { session } = yield select();
  const fileScamParam = filescan ? 'filescan=1&' : '';

  const responce = yield call(
    clientApi(`/sg-site-scanner/force-scan/${upgradeId}?${fileScamParam}_client_token=${session.clientToken}`, 'GET')
  );

  const task_id = responce.data?.task_id || responce.data?.taskId;

  if (task_id) {
    yield put(sgSiteScannerActions.checkReportStatus(upgradeId, task_id));
  }

  return responce;
}

function* requestSetSgSitescannerReports({ enable, upgradeId }) {
  const { session } = yield select();
  const endpointSuffix = enable ? 'enable' : 'disable';

  return yield call(
    clientApi(
      `/sg-site-scanner/email-reporting/${upgradeId}/${endpointSuffix}?_client_token=${session.clientToken}`,
      'GET'
    )
  );
}

function* requestCheckSgSitescannerReportsStatus(data) {
  const { session } = yield select();
  const pingInterval = 3000; //ms

  yield put(fetchActions.httpRequestStarted(data));

  try {
    const response = yield call(
      clientApi(`/sg-site-scanner/report/${data.task_id}?_client_token=${session.clientToken}`, 'GET')
    );

    if (response) {
      yield put(fetchActions.httpRequestSucceeded(data, response));
    }

    if (response?.data.status === 'pending') {
      yield delay(pingInterval);
      yield call(handleNemoApiRequest(requestCheckSgSitescannerReportsStatus, data), data);
    } else {
      // When the scan is done get the reports to update the data and check if there is other scans in progress
      yield put(sgSiteScannerActions.requestSgSitescannerReports(data.upgradeId));
    }
  } catch (e) {
    yield put(fetchActions.httpRequestFailed(data, e));
  }
}

function* requestPageData(action: NemoRequestAction) {
  const { siteId, mainDomainName, onSiteScannerAvailabilityReady } = action.payload;

  // trigger request for site upgrades
  const upgradesResponse: sgSiteScannerTypes.UpgradeEntriesResponse = yield call(
    handleNemoApiRequest(getSgSiteScannerUpgrades, action),
    { siteId }
  );

  if (upgradesResponse) {
    const { upgradeId = null, emailReports = false } = getSiteScannerUpgradeData(
      upgradesResponse.data.upgrade_entries,
      mainDomainName
    );

    onSiteScannerAvailabilityReady(upgradeId);

    yield put(sgSiteScannerActions.storeSgSitescannerData(sgSiteScannerDataTypes.emailReporting, emailReports));

    if (upgradeId) {
      const isUAAvailable = yield select(isACLActionEnabled, getToolMainEndpoint('domain'), 'can_order');

      yield put(
        sgSiteScannerActions.storeSgSitescannerData(
          sgSiteScannerDataTypes.upgradeEntries,
          upgradesResponse.data.upgrade_entries
        )
      );

      yield put(sgSiteScannerActions.requestSgSitescannerReports(upgradeId));
    }
  }
}

function* checkReportStatus(action) {
  const { session } = yield select();
  yield call(handleNemoApiRequest(requestCheckSgSitescannerReportsStatus, action), action.payload);
}

function* forceScan(action: NemoRequestAction) {
  const { onComplete, payload } = action;
  const { session } = yield select();

  const response = yield call(handleNemoApiRequest(requestForceScan, action), payload);
}

function* requestReports(action: NemoRequestAction) {
  const { upgradeId } = action.payload;

  const reportsResponse: sgSiteScannerTypes.ReportsResponse = yield call(
    handleNemoApiRequest(getSgSiteScannerReports, action),
    { upgradeId }
  );

  if (reportsResponse) {
    yield put(
      sgSiteScannerActions.storeSgSitescannerData(sgSiteScannerDataTypes.reports, normalizeReportsData(reportsResponse))
    );

    const pendingReport = reportsResponse.data?.find((report) => report.status === 'pending');
    if (pendingReport) {
      yield put(sgSiteScannerActions.checkReportStatus(upgradeId, pendingReport.task_id));
    }
  }
}

function* setSgSitescannerReports(action: NemoRequestAction) {
  const { payload } = action;
  const { enable, upgradeId, successMessage, errorMessage } = payload;

  const response = yield call(handleNemoApiRequest(requestSetSgSitescannerReports, action), { upgradeId, enable });

  if (response) {
    yield put(sgSiteScannerActions.storeSgSitescannerData(sgSiteScannerDataTypes.emailReporting, enable));
  }
}

function* sgSiteScanner(): any {
  yield takeLatest(REQUEST_SG_SITESCANNER_DATA, requestPageData);
  yield takeLatest(REQUEST_SG_SITESCANNER_REPORTS, requestReports);
  yield takeLatest(FORCE_SCAN, forceScan);
  yield takeLatest(CHECK_REPORT_STATUS, checkReportStatus);
  yield takeLatest(SET_SG_SITESCANNER_REPORTS, setSgSitescannerReports);
}

export default sgSiteScanner;
