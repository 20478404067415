import { applyMiddleware, compose, createStore, Store } from 'redux';
import { persistState } from '@siteground/redux-localstorage-async';
import createSagaMiddleware from 'redux-saga';
import { createStateSyncMiddleware } from 'redux-state-sync';
import invalidateLocalStorage from '@siteground/styleguide/lib/utils/invalidate-local-storage';

import { setStore } from '../core/store';
import { LOGOUT } from '../core/constants/actions';
import rootReducer, { RootState } from './reducers';
import sagas from './sagas';

// eslint-disable-next-line babel/no-invalid-this
const environment: any = window || this;

const reduxStateSyncConfig = {
  // actions will be triggered in other tabs
  whitelist: [LOGOUT]
};

function configureStore(initialState?: RootState): Store<RootState> {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [createStateSyncMiddleware(reduxStateSyncConfig), sagaMiddleware];

  // increase revision you want to invalidate by 1
  invalidateLocalStorage(
    {
      spanel_session: 1,
      spanel_i18n: 3,
      spanel_sites: 3,
      spanel_preferences: 2
    },
    'spanel_invalidation'
  );

  let enhancer;
  if (CONFIG.PLATFORM === 'native') {
    enhancer = compose(
      applyMiddleware(sagaMiddleware),
      environment.devToolsExtension ? environment.devToolsExtension() : (f) => f
    );
  } else {
    enhancer = compose(
      applyMiddleware(...middlewares),
      persistState('session', { key: 'spanel_session' }),
      persistState('i18n', { key: 'spanel_i18n' }),
      persistState('sites', { key: 'spanel_sites' }),
      persistState('preferences', { key: 'spanel_preferences' }),
      environment.devToolsExtension ? environment.devToolsExtension() : (f) => f
    );
  }

  const store = createStore(rootReducer, initialState, enhancer);
  setStore(store);

  sagaMiddleware.run(sagas as any);

  // Allowing redux dev tools to trigger sagas middleware
  const { devToolsExtension } = window as any;
  if (devToolsExtension) {
    devToolsExtension.updateStore(store);
  }

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextReducer = require('./reducers');
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

export default configureStore();
