import * as React from 'react';
import Dropdown from '@siteground/styleguide/lib/components/dropdown';
import { Flex } from '@siteground/styleguide/lib/components/flex-box';
import { LanguageContext } from './language-context';
import { LANGUAGES } from './utils';

type Props = {
  intl: any;
};

const LoginFooter: React.FunctionComponent<Props> = ({ intl }) => (
  <LanguageContext.Consumer>
    {({ setLanguage, selectedLanguage }) => (
      <Flex justify="space-between" align="center">
        <Dropdown
          transparent
          style={{ width: '120px' }}
          name="language"
          selectedValue={selectedLanguage}
          options={[
            {
              label: intl.formatMessage({ id: 'translate.english' }),
              value: LANGUAGES.ENGLISH
            },
            {
              label: intl.formatMessage({ id: 'translate.spanish' }),
              value: LANGUAGES.SPANISH
            },
            {
              label: intl.formatMessage({ id: 'translate.italian' }),
              value: LANGUAGES.ITALIAN
            },
            {
              label: intl.formatMessage({ id: 'translate.german' }),
              value: LANGUAGES.GERMAN
            }
          ]}
          optionLabel="label"
          optionValue="value"
          onChange={setLanguage}
        />
      </Flex>
    )}
  </LanguageContext.Consumer>
);

export default LoginFooter;
