import * as React from 'react';
import Input from '@siteground/styleguide/lib/components/input/input';

const FormInput = ({ input, meta, valueTemplate = (v) => v, onChangeTemplate, ...props }) => {
  const { value, onChange, onBlur, ...inputProps } = input;
  const touchedOrActive = meta.touched || meta.active;
  const touchedOrHasValue = meta.touched || Boolean(input.value);

  let status;
  let error;

  if (touchedOrActive && meta.valid && meta.dirty) {
    status = 'success';
  }

  if (!meta.valid && touchedOrActive && touchedOrHasValue) {
    status = 'error';
    error = meta.error;
  }

  const handleChange = onChangeTemplate ? (event) => onChange(onChangeTemplate(event)) : onChange;
  const handleBlur = onChangeTemplate ? (event) => onBlur(onChangeTemplate(event)) : onBlur;

  return (
    <Input
      state={status}
      validationMessage={error}
      value={valueTemplate(value)}
      onChange={handleChange}
      onBlur={handleBlur}
      data-e2e={`text-input-${input.name}`}
      {...inputProps}
      {...props}
    />
  );
};

export default FormInput;
