import {
  PING_AVALON,
  PING_AVALON_SUCCESS,
  PING_NEMO,
  PING_NEMO_SUCCESS,
  STOP_PING_AVALON,
  STOP_PING_NEMO
} from '../constants/ping-backend';

export const pingAvalon = () => ({ type: PING_AVALON });
export const pingAvalonSucceed = (payload) => ({ type: PING_AVALON_SUCCESS, payload });
export const stopPingAvalon = () => ({ type: STOP_PING_AVALON });

export const pingNemo = () => ({ type: PING_NEMO });
export const pingNemoSucceed = (payload) => ({ type: PING_NEMO_SUCCESS, payload });
export const stopPingNemo = () => ({ type: STOP_PING_NEMO });
