import * as React from 'react';
import { Field } from 'redux-form';
import { withRouter } from 'react-router';
import { Button, Column, Notice, Text } from '@siteground/styleguide';
import FormDropdown from '../../components/form-dropdown';
import FormInput from '../../components/form-input';
import { CREATE_NEW_DOMAIN_OPTIONS_NAME, DNSTypes } from './constants';
import { generateTTLOptions } from './utils';
import { ROUTES } from '../../../core/constants/routes';
import { ToolId } from '../../../core/constants/route-info';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { SGSubmitButton } from '../../components/sg-form';
import areEqual from '@siteground/styleguide/lib/utils/are-equal';
import DnsSRVField from './dns-srv-field';
import { findDomainByName } from '../php-settings/utils';

type Props = {
  locationSearch: string;
  dnsType: DNSTypes;
  validationUtils: ValidationUtils;
  getFormValues: Function;
  domainName: string;
  formName: string;
  listedDomains: any[];
  intl: Intl;
  router: {
    push: Function;
  };
};

type State = {
  selectedDomain: any;
  selectedOtherValue: string;
};

export class DNSFields extends React.Component<Props, State> {
  readonly state = {
    selectedDomain: null,
    selectedOtherValue: ''
  };

  getSelectedId = () => {
    return (
      this.props.listedDomains.length > 0 &&
      this.props.listedDomains.find((domain) => domain.name === this.props.domainName).id
    );
  };

  domainCDNEnabled = (id = null) => {
    const { listedDomains } = this.props;
    const { selectedDomain } = this.state;
    const selectedId = id || selectedDomain || this.getSelectedId();

    const cdnEnabled = listedDomains.filter((domain) => domain.id === selectedId && domain.settings.cdn_enabled).length;

    return Boolean(cdnEnabled);
  };

  getDomainNameValidations = () => {
    const { validationUtils } = this.props;
    const {
      required,
      validationWithMetaApi,
      doesDomainExistInDNS,
      doesDomainStartsWithWWWInDNS,
      doesSubDomainStartsWithWWWInDNS
    } = validationUtils;

    const nameValidation = [validationWithMetaApi, doesDomainExistInDNS, required];
    const mainDomainId = this.getSelectedId();
    const otherValue = this.state.selectedOtherValue.split('www.');
    const subDomainName = otherValue.length === 1 ? otherValue[0] : otherValue[1];
    const subDomain = findDomainByName({
      domainName: subDomainName,
      domains: this.props.listedDomains
    });

    if (this.domainCDNEnabled(mainDomainId)) {
      nameValidation.push(doesDomainStartsWithWWWInDNS);
    }

    if (subDomain && this.domainCDNEnabled(subDomain.id)) {
      nameValidation.push(doesSubDomainStartsWithWWWInDNS);
    }

    return nameValidation;
  };

  getDomainsOptions = () => {
    const { listedDomains, intl } = this.props;

    return [
      ...listedDomains,
      {
        name: intl.formatMessage({ id: 'translate.page.dns.dropdown.other' }),
        id: CREATE_NEW_DOMAIN_OPTIONS_NAME
      }
    ];
  };

  shouldRenderMainNotice = () => {
    const { listedDomains } = this.props;
    return this.domainCDNEnabled() && listedDomains.length === 1;
  };

  renderNotice = () => {
    const { intl, router, locationSearch } = this.props;

    return (
      <Column smSpan="12">
        <Notice
          type="warning"
          background="light"
          shadow={false}
          title={intl.formatMessage({ id: 'translate.page.dns.cdn.notice.title' })}
        >
          <Text padding={['none', 'none', 'medium', 'none']}>
            {intl.formatMessage({ id: 'translate.page.dns.cdn.notice.desc' })}
          </Text>
          <Button
            color="primary"
            onClick={() => {
              router.push(`${ROUTES[ToolId.cdn]}${locationSearch}`);
            }}
          >
            {intl.formatMessage({ id: 'translate.page.dns.cdn.notice.btn' })}
          </Button>
        </Notice>
      </Column>
    );
  };

  renderAField({ showSubdomainField }) {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;
    const shouldViewNotice = this.shouldRenderMainNotice();
    const cdnEnabled = this.domainCDNEnabled();

    const renderSelectNameDropdown = () => (
      <Column smSpan="6">
        <Field
          name="domain_choice"
          label={intl.formatMessage({ id: 'translate.page.select.domain.field.name.label' })}
          options={this.getDomainsOptions()}
          optionValue="id"
          optionLabel="name"
          validate={[required]}
          component={FormDropdown}
          onChange={(e, value) =>
            this.setState({
              selectedDomain: value
            })
          }
        />
      </Column>
    );

    if (shouldViewNotice) {
      return (
        <React.Fragment>
          {renderSelectNameDropdown()}
          {this.renderNotice()}
        </React.Fragment>
      );
    }

    const nameValidation = this.getDomainNameValidations();

    return (
      <React.Fragment>
        {renderSelectNameDropdown()}

        {showSubdomainField && (
          <Column smSpan="6">
            <Field
              name="name"
              type="text"
              label={intl.formatMessage({ id: 'translate.generic.name' })}
              validate={nameValidation}
              normalize={(value) => {
                this.setState({
                  selectedOtherValue: value
                });
                return value.toLowerCase();
              }}
              suffix={`.${this.props.domainName}.`}
              component={FormInput}
            />
          </Column>
        )}

        {!cdnEnabled && (
          <React.Fragment>
            <Column smSpan="6">
              <Field
                name="ttl"
                label={intl.formatMessage({ id: 'translate.generic.ttl' })}
                options={generateTTLOptions(this.props)}
                optionValue="seconds"
                optionLabel="label"
                validate={[required, validationWithMetaApi]}
                component={FormDropdown}
              />
            </Column>

            <Column smSpan="6">
              <Field
                name="value"
                type="text"
                label={intl.formatMessage({ id: 'translate.page.dns.field.ipv4-address.label' })}
                validate={[required, validationWithMetaApi]}
                normalize={(value) => value.toLowerCase()}
                component={FormInput}
              />
            </Column>
          </React.Fragment>
        )}

        {cdnEnabled && this.renderNotice()}
      </React.Fragment>
    );
  }

  renderAAAAField({ showSubdomainField }) {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;
    const shouldViewNotice = this.shouldRenderMainNotice();
    const cdnEnabled = this.domainCDNEnabled();
    const renderSelectNameDropdown = () => (
      <Column smSpan="6">
        <Field
          name="domain_choice"
          label={intl.formatMessage({ id: 'translate.page.select.domain.field.name.label' })}
          options={this.getDomainsOptions()}
          optionValue="id"
          optionLabel="name"
          validate={[required]}
          component={FormDropdown}
          onChange={(e, value) =>
            this.setState({
              selectedDomain: value
            })
          }
        />
      </Column>
    );

    if (shouldViewNotice) {
      return (
        <React.Fragment>
          {renderSelectNameDropdown()}
          {this.renderNotice()}
        </React.Fragment>
      );
    }

    const nameValidation = this.getDomainNameValidations();

    return (
      <React.Fragment>
        {renderSelectNameDropdown()}
        {showSubdomainField && (
          <Column smSpan="6">
            <Field
              name="name"
              type="text"
              label={intl.formatMessage({ id: 'translate.generic.name' })}
              validate={nameValidation}
              normalize={(value) => {
                this.setState({
                  selectedOtherValue: value
                });
                return value.toLowerCase();
              }}
              suffix={`.${this.props.domainName}.`}
              component={FormInput}
            />
          </Column>
        )}

        {!cdnEnabled && (
          <React.Fragment>
            <Column smSpan="6">
              <Field
                name="ttl"
                label={intl.formatMessage({ id: 'translate.generic.ttl' })}
                options={generateTTLOptions(this.props)}
                optionValue="seconds"
                optionLabel="label"
                validate={[required, validationWithMetaApi]}
                component={FormDropdown}
              />
            </Column>
            <Column smSpan="6">
              <Field
                name="value"
                type="text"
                label={intl.formatMessage({ id: 'translate.page.dns.field.ipv6-address.label' })}
                validate={[required, validationWithMetaApi]}
                normalize={(value) => value.toLowerCase()}
                component={FormInput}
              />
            </Column>
          </React.Fragment>
        )}
        {cdnEnabled && this.renderNotice()}
      </React.Fragment>
    );
  }

  renderCNAMEField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi, domain } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="name"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[required, validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
            suffix={`.${this.props.domainName}.`}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={generateTTLOptions(this.props)}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.dns.field.resolves-to.label' })}
            validate={[required, validationWithMetaApi, domain]}
            normalize={(value) => value.toLowerCase()}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  renderMXField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="name"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
            suffix={`.${this.props.domainName}.`}
            component={FormInput}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={generateTTLOptions(this.props)}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="prio"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.prio' })}
            validate={[required, validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
            component={FormInput}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.dns.field.destination.label' })}
            validate={[required, validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  renderSRVField() {
    return <DnsSRVField props={this.props} />;
  }

  renderTXTField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="name"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[validationWithMetaApi]}
            suffix={`.${this.props.domainName}.`}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={generateTTLOptions(this.props)}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.value' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  shouldShowCreateBtn = () => {
    const { dnsType } = this.props;

    if (!this.domainCDNEnabled()) {
      return true;
    }

    return dnsType !== DNSTypes.DNS_A && dnsType !== DNSTypes.DNS_AAAA;
  };

  componentDidMount() {
    this.setState({ selectedDomain: this.getSelectedId() });
  }

  render() {
    const { dnsType, formName, intl } = this.props;
    const showSubdomainField = this.state.selectedDomain === CREATE_NEW_DOMAIN_OPTIONS_NAME;
    const showCreateBtn = this.shouldShowCreateBtn();
    let fields = null;
    const data = {
      showSubdomainField
    };

    switch (dnsType) {
      case DNSTypes.DNS_A:
        fields = this.renderAField(data);
        break;
      case DNSTypes.DNS_AAAA:
        fields = this.renderAAAAField(data);
        break;
      case DNSTypes.DNS_CNAME:
        fields = this.renderCNAMEField();
        break;
      case DNSTypes.DNS_MX:
        fields = this.renderMXField();
        break;
      case DNSTypes.DNS_SRV:
        fields = this.renderSRVField();
        break;
      case DNSTypes.DNS_TXT:
        fields = this.renderTXTField();
        break;
      default:
        fields = this.renderAField(data);
        break;
    }

    return (
      <React.Fragment>
        {fields}
        {showCreateBtn && (
          <Column smSpan="12">
            <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.create' })} />
          </Column>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  locationSearch: store.routing.locationBeforeTransitions.search
});

export default connect<{}, {}, any>(mapStateToProps, {})(withRouter(DNSFields));
