import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { openSGDialog } from '../../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';
import getStore from '../../../../core/store';
import { uploadFiles, validationBeforeUploadFiles } from '../actions/file-manager';
import {
  FILE_MANAGER_BROWSER_FILE_UPLOAD,
  FILE_MANAGER_DRAG_AND_DROP_FILE_UPLOAD,
  FILE_MANAGER_VALIDATION_BEFORE_UPLOAD_FILES
} from '../constants/actions';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../constants/common';
import { getConfirmFilesUploadDialog } from '../selectors/get-confirm-files-upload-dialog-payload';
import { getEntityPath } from '../utils';
import { loadExistingFilesData } from './check-for-existing-files';

const UPLOAD_INPUT_ID = 'fm-upload';

export type BrowserFileUploadPayload = {
  multiple?: boolean;
};

export type UploadFilesPayload = {
  files: {
    [key: string]: {
      webkitRelativePath: string;
      spanelFullPathForAPI: string;
      name: string;
      size: number;
      _meta: {
        isValid?: boolean;
      };
    };
  };
  onFilesReadComplete?: Function;
};

export type UploadingFileObjectType = {
  urlParams: {
    safe: 1;
    filename: string;
  };
  file: {
    name: string;
    size: number;
  };
  _meta: {
    isValid: boolean;
    name: string;
    path: string;
  };
  t: string;
  n: string;
};

export const validateFile =
  ({ existingFiles }) =>
  (fileData: UploadingFileObjectType) => {
    const fileToValidate = { ...fileData };

    if (existingFiles.includes(fileData._meta.path)) {
      fileToValidate._meta.isValid = false;
    }

    return fileToValidate;
  };

export const constructFileForUpload =
  ({ selectedNavigationEntity }) =>
  (file): UploadingFileObjectType => {
    const fileName = file.webkitRelativePath || file.spanelFullPathForAPI || file.name;
    const fullPath = `${getEntityPath(selectedNavigationEntity)}/${fileName}`.replace('//', '/');

    return {
      urlParams: {
        safe: 1,
        filename: fullPath
      },
      file,
      _meta: {
        isValid: true,
        name: fileName,
        path: fullPath
      },
      t: FILE_MANAGER_API_RESPONSE_DIR.FILE,
      n: fileName // TODO needed for getEntity... think a way to refactor
    };
  };

export const fileIsInvalidForUpload = (file: UploadingFileObjectType) => Boolean(file._meta.isValid === false);

function* handleValidationBeforeUploadFiles(action) {
  const state = yield select();
  const { payload } = action;
  const { files, onFilesReadComplete = () => null }: UploadFilesPayload = payload;

  const { fileManager } = yield select();
  const { selectedNavigationEntity } = fileManager;

  // The map is needed when the files comes from native input.
  // Then they are FileList not an array
  const filesAsArray = Object.keys(files).map((key) => files[key]);

  const constructedFilesData = filesAsArray.map(constructFileForUpload({ selectedNavigationEntity }));

  const entries = constructedFilesData.map((f) => f._meta.path);

  const getExistingFilesResponse = yield call(loadExistingFilesData, entries);

  const existingFiles = getExistingFilesResponse.data ? getExistingFilesResponse.data.map((f) => f.n) : [];

  const validatedFilesData = constructedFilesData.map(validateFile({ existingFiles }));

  onFilesReadComplete();

  const someFilesAreInvalidForUpload = Boolean(validatedFilesData.find(fileIsInvalidForUpload));

  if (someFilesAreInvalidForUpload) {
    return yield put(
      openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD, getConfirmFilesUploadDialog({ files: validatedFilesData }))
    );
  }

  yield put(uploadFiles(constructedFilesData));
}

function* browserFileUpload({ payload }: { payload: BrowserFileUploadPayload }) {
  const state = yield select();
  const multiple = payload && payload.multiple;

  const tempInput = document.createElement('input');
  tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px';
  tempInput.id = UPLOAD_INPUT_ID;
  tempInput.type = 'file';

  if (multiple) {
    // https://github.com/facebook/react/issues/3468
    tempInput.setAttribute('multiple', 'true');
    tempInput.setAttribute('directory', 'true');
    tempInput.setAttribute('webkitdirectory', 'true');
  }

  tempInput.onchange = (event) =>
    getStore().dispatch(
      validationBeforeUploadFiles({
        files: (event.target as any).files,
        onFilesReadComplete: () => document.body.removeChild(document.getElementById(UPLOAD_INPUT_ID))
      })
    );

  document.body.appendChild(tempInput);
  tempInput.click();
}

function* dragAndDropUpload({ payload }) {
  yield put(
    validationBeforeUploadFiles({
      files: payload
    })
  );
}

function* browserUpload(): any {
  yield takeEvery(FILE_MANAGER_BROWSER_FILE_UPLOAD, browserFileUpload);
  yield takeEvery(FILE_MANAGER_DRAG_AND_DROP_FILE_UPLOAD, dragAndDropUpload);
  yield takeLatest(FILE_MANAGER_VALIDATION_BEFORE_UPLOAD_FILES, handleValidationBeforeUploadFiles);
}

export default browserUpload;
