import * as React from 'react';
import { useIntl } from 'react-intl';

import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

const { resourceName, resourceNameMetaApi } = API_RESOURCE.WORDPRESS;
const formName = REDUX_FORM.CREATE_STAGING;

const CreateContainer: React.FC = ({ children }) => {
  const intl = useIntl();

  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="PUT">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.wp.replace.create.title' })}
        resourceName={resourceName}
        formName={formName}
        renderTutorials={({ DefaultRender }) => (
          <DefaultRender
            title={intl.formatMessage({ id: 'translate.tips_and_tutorials.wordpress_replace.title' })}
            text={intl.formatMessage({ id: 'translate.tips_and_tutorials.wordpress_replace.content' })}
          />
        )}
        resources={[
          {
            resourceName: resourceName,
            methods: ['GET', 'PUT']
          }
        ]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default CreateContainer;
