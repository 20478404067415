import * as React from 'react';
import { Flex, FlexProps, Sort, SortProps } from '@siteground/styleguide';

interface TableHeaderSortProps extends Partial<SortProps> {
  label: string;
  flexProps?: Partial<FlexProps>;
}

const TableHeaderSort = (props: TableHeaderSortProps) => {
  const { label, flexProps, ...sortProps } = props;

  return (
    <Flex align="center" wrap="nowrap" {...flexProps}>
      {label}
      &nbsp;
      <Sort {...sortProps} />
    </Flex>
  );
};

export default TableHeaderSort;
