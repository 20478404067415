import areEqual from '@siteground/styleguide/lib/utils/are-equal';
import {
  REQUEST_ARTICLE_SUCCESS,
  REQUEST_ARTICLES_SUCCESS,
  REQUEST_SUPPORT_SEARCH_SUCCESS,
  REQUEST_SUPPORT_SUGGESTIONS_SUCCESS,
  SAVE_SUPPORT_STATE,
  SET_QUERY,
  SUPPORT_HISTORY_BACK,
  SUPPORT_HISTORY_CLEAR
} from '../constants/actions';
import { RETRIEVE_DATA_FROM_NEMO_SUCCEEDED } from '../constants/nemo-store';

const omitKey = <T>(data: T, keysToOmit: string[]): T =>
  Object.keys(data).reduce((accumulator: any, key) => {
    if (keysToOmit.includes(key)) {
      return accumulator;
    }

    return {
      ...accumulator,
      [key]: data[key]
    };
  }, {} as T);

const supportPopupState: SupportPopupState = {
  article: null,
  inquiry: '',
  pinned: null,
  popular: [],
  showDirectDisplayView: false,
  suggestions: [],
  searchResults: [],
  popup: null,
  status: 'initial'
};

// tslint:disable-next-line:cyclomatic-complexity
function support(state = supportPopupState, action) {
  switch (action.type) {
    case SET_QUERY:
      return {
        ...state,
        inquiry: action.payload.query
      };

    case REQUEST_ARTICLES_SUCCESS:
      return {
        ...state,
        pinned: action.articles.top && action.articles.top.title ? action.articles.top : state.pinned,
        popular: action.articles.list
      };

    case REQUEST_ARTICLE_SUCCESS:
      return {
        ...state,
        article: {
          ...action.data,
          related_posts: action.aiMatches
            ? action.aiMatches.filter((match) => match.id !== action.data.id)
            : action.data.related_posts
        },
        showDirectDisplayView: !!action.aiMatches,
        status: 'show_article'
      };

    case REQUEST_SUPPORT_SEARCH_SUCCESS:
      return {
        ...state,
        article: supportPopupState.article,
        searchResults: action.results,
        status: 'search_results'
      };

    case REQUEST_SUPPORT_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions: action.suggestions
      };

    case RETRIEVE_DATA_FROM_NEMO_SUCCEEDED: {
      if (!action.data || !action.data.popup) {
        return state;
      }

      const popup = action.data.popup;
      const last = popup.state.history[popup.state.history.length - 1];

      return {
        ...state,
        article: last.article,
        pinned: last.pinned,
        popular: last.popular,
        suggestions: last.suggestions,
        searchResults: last.searchResults,
        popup
      };
    }

    case SAVE_SUPPORT_STATE:
      return {
        ...state,
        popup: action.payload.popup
      };

    default:
      return state;
  }
}

function popupHistory(reducer) {
  const initialState = {
    past: [],
    present: reducer(undefined, {})
  };

  return function (state = initialState, action) {
    const { past, present } = state;

    switch (action.type) {
      case SUPPORT_HISTORY_BACK: {
        const previous = past[past.length - 1];
        const newPast = past.slice(0, past.length - 1);
        return {
          past: newPast,
          present: previous
        };
      }

      case SUPPORT_HISTORY_CLEAR:
        return { ...initialState };

      default: {
        const newPresent = reducer(present, action);
        const keysToOmit = ['popup', 'suggestions', 'inquiry'];

        if (areEqual(omitKey(present, keysToOmit), omitKey(newPresent, keysToOmit))) {
          if (areEqual(present.suggestions, newPresent.suggestions) && present.inquiry === newPresent.inquiry) {
            return state;
          }

          return {
            ...state,
            present: newPresent
          };
        }

        return {
          past: [...past, omitKey(present, ['popup', 'suggestions'])],
          present: newPresent
        };
      }
    }
  };
}

export default popupHistory(support);
