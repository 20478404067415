import * as React from 'react';

const DeleteIndexLoader = () => {
  React.useEffect(() => {
    const loader = document.getElementById('initial-loader');

    if (loader) {
      loader.parentElement.removeChild(loader);
    }
  }, []);

  return null;
};

export default DeleteIndexLoader;
