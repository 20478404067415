import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Link, Section, Text } from '@siteground/styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import { RootState } from '../../reducers';
import { ChangePassword, ChangeQuota } from '../../components/common-forms';
import { DeleteDialog } from '../../components/dialogs';
import indexWithCRUD from '../../components/indexWithCRUD';
import ListBox from '../../components/list-box';
import PageHeader from '../../components/page-header';
import QuotaProgress from '../../components/quota-progress/quota-progress';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../components/sg-dialog';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../components/visibility-control-service';
import { CreateBox, CreateForm } from './create/';
import { ChangeHome, ChangeName } from './update';

type FtpProps = {
  actions: {
    createItem: CreateItem;
    updateItem: UpdateItem;
    deleteItem: DeleteItem;
    changeQuotaItem: UpdateItem;
    fetchItem: FetchItem;
  };
  items: any;
  domainName: string;
  intl: Intl;
  location: any;
  openSGDialog: Function;
  closeSGDialog: Function;
};

type FtpState = {
  accountSettingsPayload: {
    entity: any;
    account: string;
  };
  currentChangeNamePayload: UpdateItemPayload;
  currentChangeHomePayload: UpdateItemPayload;
  currentChangePasswordPayload: UpdateItemPayload;
  currentChangeQuotaPayload: UpdateItemPayload;
  currentDeleteConformationDialogPayload: DeleteItemPayload;
};

const { resourceName } = API_RESOURCE.FTP;
const PORT = '21';
const LABEL_HIDDEN = 'hidden';

class FtpPage extends React.Component<FtpProps, FtpState> {
  readonly state = {
    accountSettingsPayload: null,
    currentChangeNamePayload: null,
    currentChangeHomePayload: null,
    currentChangePasswordPayload: null,
    currentChangeQuotaPayload: null,
    currentDeleteConformationDialogPayload: null
  };

  getHostName = () => {
    const { items, domainName } = this.props;
    const currentDomain = items.domainAll && items.domainAll.find((domain) => domain.name === domainName);
    return currentDomain && currentDomain.config.ftp_host;
  };

  onCreateFormSubmit = (formData) => {
    const { domainName } = this.props;
    const entityName = `${formData.name}@${domainName}`;

    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_FTP,
          success: {
            intlKey: 'translate.page.ftp.created_msg',
            intlValues: { account: entityName }
          },
          error: {
            intlKey: 'translate.page.ftp.failed_create_msg',
            intlValues: { account: entityName }
          }
        }
      }
    });
  };

  render() {
    const { domainName, items, intl } = this.props;
    const data = items.ftp && items.ftp.filter((f) => f.label !== LABEL_HIDDEN);
    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.account.name' }),
        accessor: 'name',
        render: (cellText) => `${cellText}@${domainName}`
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.home' }),
        accessor: 'home'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.quota' }),
        accessor: 'quota_size',
        render: (quota, entity) => (
          <QuotaProgress quota={quota} max={entity.quota_size_max} usedQuota={entity.used_size} />
        )
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <div>
        <PageHeader
          id={ToolId.ftp}
          instructions={
            <FormattedMessage
              id="translate.page.ftp.info"
              values={{
                domain: (
                  <Text tag="span" weight="bold">
                    {domainName}
                  </Text>
                ),
                FileZilla: (
                  <Link href="https://filezilla-project.org/download.php" target="_blank">
                    FileZilla
                  </Link>
                )
              }}
            />
          }
        />
        <Section>
          <Grid>
            <CreateBox hostname={this.getHostName()} port={PORT}>
              <CreateForm domainName={domainName} onSubmit={this.onCreateFormSubmit} />
            </CreateBox>
            <VCS resourceName={resourceName} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.ftp.list.title' })}
                data={data}
                columns={columns}
                resources={[{ resourceName: API_RESOURCE.FTP.resourceName, methods: ['GET'] }]}
                noDataMessage="translate.page.ftp.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>

        {this.renderFTPAccountSettingsDialog()}
        {this.renderChangeAccountNameComponent()}
        {this.renderChangeHomeComponent()}
        {this.renderChangePasswordComponent()}
        {this.renderChangeQuotaComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  }

  renderFTPAccountSettingsDialog = () => {
    const { intl } = this.props;
    const { accountSettingsPayload } = this.state;

    return (
      <SGDialog
        id={DIALOGS.FTP_SETTING_DIALOG}
        icon="ftp"
        state="warning"
        title={intl.formatMessage({ id: 'translate.page.ftp.account.settings.dialog.title' })}
        subTitle={intl.formatMessage({ id: 'translate.page.ftp.account.settings.dialog.message' })}
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={DIALOGS.FTP_SETTING_DIALOG}
              label={intl.formatMessage({ id: 'translate.generic.close' })}
            />
          </React.Fragment>
        }
        resources={[
          {
            resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
            methods: ['GET']
          }
        ]}
      >
        <ListBox
          data={[
            {
              label: intl.formatMessage({ id: 'translate.page.ftp.account.settings.hostname' }),
              value: this.getHostName()
            },
            {
              label: intl.formatMessage({ id: 'translate.page.ftp.account.settings.username' }),
              value: accountSettingsPayload && accountSettingsPayload.account
            },
            {
              label: intl.formatMessage({ id: 'translate.page.ftp.account.settings.password' }),
              value: intl.formatMessage({ id: 'translate.page.ftp.account.settings.password.value' })
            },
            {
              label: intl.formatMessage({ id: 'translate.page.ftp.account.settings.port' }),
              value: PORT
            }
          ]}
        />
      </SGDialog>
    );
  };

  renderChangeAccountNameComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentChangeNamePayload } = this.state;
    const entityName = currentChangeNamePayload && currentChangeNamePayload._metaFields.entityName;

    return (
      <SGDialogForm
        icon="rename"
        name={REDUX_FORM.CHANGE_NAME_DIALOG}
        title={intl.formatMessage({ id: 'translate.dialog.title.change.account.name' }, { account: entityName })}
        resources={[
          {
            resourceName: API_RESOURCE.FTP.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <ChangeName
          initialValues={currentChangeNamePayload}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.CHANGE_NAME_DIALOG))}
        />
      </SGDialogForm>
    );
  };

  renderChangeHomeComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentChangeHomePayload } = this.state;
    const entityName = currentChangeHomePayload && currentChangeHomePayload._metaFields.entityName;

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_HOME_DIALOG}
        icon="house"
        title={intl.formatMessage({ id: 'translate.dialog.title.change.home' }, { account: entityName })}
        resources={[
          {
            resourceName: API_RESOURCE.FTP.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <ChangeHome
          initialValues={currentChangeHomePayload}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.CHANGE_HOME_DIALOG))}
        />
      </SGDialogForm>
    );
  };

  renderChangeQuotaComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentChangeQuotaPayload } = this.state;
    const entityName = currentChangeQuotaPayload && currentChangeQuotaPayload._metaFields.entityName;

    return (
      <SGDialogForm
        name={REDUX_FORM.GENERIC_QUOTA}
        icon="settings"
        title={intl.formatMessage({ id: 'translate.dialog.title.change.quota' }, { account: entityName })}
        resources={[
          {
            resourceName: API_RESOURCE.FTP.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <ChangeQuota
          type="ftp"
          siteMetaApiKey="max_ftp_quota_size"
          initialValues={currentChangeQuotaPayload}
          onSubmit={(data) => actions.changeQuotaItem(data, () => closeSGDialog(REDUX_FORM.GENERIC_QUOTA))}
        />
      </SGDialogForm>
    );
  };

  renderChangePasswordComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentChangePasswordPayload } = this.state;
    const entityName = currentChangePasswordPayload && currentChangePasswordPayload._metaFields.entityName;

    return (
      <SGDialogForm
        name={REDUX_FORM.GENERIC_PASSWORD}
        icon="lock"
        title={intl.formatMessage({ id: 'translate.dialog.title.change.password' }, { account: entityName })}
        resources={[
          {
            resourceName: API_RESOURCE.FTP.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <ChangePassword
          initialValues={currentChangePasswordPayload}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.GENERIC_PASSWORD))}
        />
      </SGDialogForm>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const name = deletePayload && deletePayload.entityName;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.ftp.delete.title' }, { name })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const account = `${entity.name}@${this.props.domainName}`;
    const entityInfo = {
      domainName: this.props.domainName,
      maxQuotaSize: entity.quota_size_max,
      entityName: account
    };

    const accountSettingsPayload = {
      entity,
      account
    };

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      ...entityInfo,
      _metaFields: { ...API_RESOURCE.FTP },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.ftp.deleted_msg',
            intlValues: { account }
          },
          error: {
            intlKey: 'translate.page.ftp.failed_delete_msg',
            intlValues: { account }
          }
        }
      }
    };

    const changePasswordPayload: UpdateItemPayload = {
      id: entity.id,
      _metaFields: {
        ...entityInfo,
        ...API_RESOURCE.FTP
      },
      _meta: {
        notification: {
          type: 'generic',
          success: { intlKey: 'translate.generic.password_changed', intlValues: { account } },
          error: { intlKey: 'translate.generic.password_failed', intlValues: { account } }
        }
      }
    };

    const changeQuotaPayload: UpdateItemPayload = {
      _metaFields: {
        ...entityInfo,
        ...API_RESOURCE.FTP
      },
      _meta: {
        notification: {
          type: 'generic',
          success: { intlKey: 'translate.generic.quota_changed', intlValues: { account } },
          error: { intlKey: 'translate.generic.quota_failed', intlValues: { account } }
        }
      },
      quota_size: entity.quota_size,
      id: entity.id
    };

    const changeHomePayload: UpdateItemPayload = {
      _metaFields: {
        ...entityInfo,
        ...API_RESOURCE.FTP
      },
      _meta: {
        notification: {
          type: 'generic',
          success: { intlKey: 'translate.page.ftp.home.update_msg', intlValues: { account } },
          error: { intlKey: 'translate.page.ftp.home.failed_update_msg', intlValues: { account } }
        }
      },
      ...entity
    };

    const changeNamePayload: UpdateItemPayload = {
      _metaFields: {
        ...entityInfo,
        ...API_RESOURCE.FTP
      },
      _meta: {
        notification: {
          type: 'generic',
          success: { intlKey: 'translate.page.ftp.name.update_msg', intlValues: { account } },
          error: { intlKey: 'translate.page.ftp.name.failed_update_msg', intlValues: { account } }
        }
      },
      ...entity
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={resourceName}
        items={[
          {
            vcsMethod: 'GET',
            icon: 'ftp',
            label: intl.formatMessage({ id: 'translate.ftp.credentials' }),
            e2eAttr: 'table-action-ftp-credentials',
            onClick: () => {
              this.setState({ accountSettingsPayload }, () => openSGDialog(DIALOGS.FTP_SETTING_DIALOG));
            }
          },
          {
            vcsMethod: 'PUT',
            icon: 'rename',
            label: intl.formatMessage({ id: 'translate.generic.change.name' }),
            e2eAttr: 'table-action-change-name',
            onClick: () =>
              this.setState({ currentChangeNamePayload: changeNamePayload }, () =>
                openSGDialog(REDUX_FORM.CHANGE_NAME_DIALOG)
              )
          },
          {
            vcsMethod: 'PUT',
            icon: 'house',
            label: intl.formatMessage({ id: 'translate.generic.change.home' }),
            e2eAttr: 'table-action-change-home',
            onClick: () =>
              this.setState({ currentChangeHomePayload: changeHomePayload }, () =>
                openSGDialog(REDUX_FORM.CHANGE_HOME_DIALOG)
              )
          },
          {
            vcsMethod: 'PUT',
            icon: 'settings',
            label: intl.formatMessage({ id: 'translate.generic.change.quota' }),
            e2eAttr: 'table-action-change-quota',
            onClick: () =>
              this.setState({ currentChangeQuotaPayload: changeQuotaPayload }, () =>
                openSGDialog(REDUX_FORM.GENERIC_QUOTA)
              )
          },
          {
            vcsMethod: 'PUT',
            icon: 'lock',
            label: intl.formatMessage({ id: 'translate.generic.change.password' }),
            e2eAttr: 'table-action-change-password',
            onClick: () =>
              this.setState({ currentChangePasswordPayload: changePasswordPayload }, () =>
                openSGDialog(REDUX_FORM.GENERIC_PASSWORD)
              )
          },
          {
            vcsMethod: 'DELETE',
            icon: 'trash',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-delete',
            onClick: () =>
              this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
                openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  items: state.pageItems,
  domainName: state.sites.currentDomainName
});

export default indexWithCRUD(mapStateToProps, { ...sgDialogActions })(
  FtpPage,
  API_RESOURCE.FTP,
  API_RESOURCE.DOMAIN_ALL
);
