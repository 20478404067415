import { TableProps } from '@siteground/styleguide/lib/components/table/table';
import { getCalendarTranslations } from '../../components/form-date-picker/form-date-picker';

export const TABLE_VIEW_PROPS: Partial<TableProps> = {
  cellHeight: 'small',
  mobileLayout: 'card-flat',
  border: 'small',
  shadow: false
};

const languageToLocaleMapping = {
  en: 'en',
  es_ES: 'es',
  it_IT: 'it',
  de_DE: 'de'
};

export const createFormatter = (language: string) =>
  new Intl.DateTimeFormat(languageToLocaleMapping[language] || languageToLocaleMapping.en, { month: 'short' });

export const formatDate = (date: string, intl: any): string => {
  const [year, month, day] = date.split('-');

  return `${getCalendarTranslations(intl).abbreviatedMonthsNames[parseInt(month) - 1]} ${String(day).padStart(2, '0')}`;
};

export const formatDateToMonthYear = (date: string | number, language) => {
  const dateInstance = new Date(date);
  const formatter = createFormatter(language);

  return `${formatter.format(dateInstance)}, ${dateInstance.getFullYear()}`;
};

export const formatBandwidthLabel = (sizeMB: number): string => {
  const gbSize = 1024;

  if (sizeMB > gbSize) {
    const valueAsString = (sizeMB / gbSize).toFixed(1);
    const gbValue = parseFloat(valueAsString);
    const gbValueInt = parseInt(valueAsString, 10);

    return gbValueInt === gbValue ? `${gbValueInt} GB` : `${gbValue} GB`;
  }

  return `${parseFloat(sizeMB.toFixed(1))} MB`;
};
