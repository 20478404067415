import customRequestTypes from '../../constants/custom-request-types';
import { NemoActionTypes } from './types';

export const checkOrderStatus = (payload: {
  orderId: string;
  getInstalledExtras: () => void;
  clearState: () => void;
  notification: SpanelNotification;
}) => ({
  type: NemoActionTypes.CheckOrderStatus,
  requestTypeName: customRequestTypes.CHECK_ORDER_STATUS,
  notification: payload.notification,
  payload
});
