import { WmToolId } from '../../../core/constants/route-info';

export const WEBMAIL_PATH_PREFIX = 'webmail';

export const WM_ROUTES = {
  [WmToolId.webmail]: `/${WEBMAIL_PATH_PREFIX}/mail`,
  [WmToolId.wmLogin]: `/${WEBMAIL_PATH_PREFIX}/log-in`,
  [WmToolId.wmLoginCredentials]: `/${WEBMAIL_PATH_PREFIX}/login-credentials`,
  [WmToolId.wmEmailConfig]: `/${WEBMAIL_PATH_PREFIX}/email-config`,
  [WmToolId.wmEmailAutoresponder]: `/${WEBMAIL_PATH_PREFIX}/email-autoresponder`,
  [WmToolId.wmEmailFilters]: `/${WEBMAIL_PATH_PREFIX}/email-filters`,
  [WmToolId.wmEmailForward]: `/${WEBMAIL_PATH_PREFIX}/email-forward`
};
