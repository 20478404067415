import * as React from 'react';
import { connect } from 'react-redux';
import { Grid, GridColumns, Text } from '@siteground/styleguide';
import { HostingPlanCard } from '@siteground/styleguide/lib/composite/hosting-plan-card/hosting-plan-card';
import { formatCurrency } from '@siteground/styleguide/lib/utils';

import { BuyBackupTypes } from '../../../../../core/constants/common';
import { PLAN_CARD_ICON_PROPS } from '../../../../../core/constants/icon-props';
import { getCurrencyData } from '../../../../../core/selectors/session';
import { PlanCard } from '../../../../components/plan-card';
import { getVatText } from '../../../../components/plan-card/utils';

interface BuyBackupConnectedProps {
  currencyProps: ConnectedCurrencyProps;
}

interface BuyBackupParentProps {
  backupOrderData: BackupOrderData;
  selectedType: BuyBackupTypes;
  intl: Intl;
  onSelection: (type: BuyBackupTypes) => void;
}

type Props = BuyBackupParentProps & BuyBackupConnectedProps;

const BuyBackupComponent: React.FunctionComponent<Props> = (props: Props) => {
  const { backupOrderData, selectedType, intl, onSelection, currencyProps } = props;
  const { upgrade_plan, single_backup_price, current_plan } = backupOrderData;

  const cycle = upgrade_plan && Object.keys(upgrade_plan)[0];
  const upgradePlanPaymentData = upgrade_plan && Object.values(upgrade_plan.price)[0];
  const upgradeDataAvailable = Boolean(upgrade_plan && cycle && upgradePlanPaymentData);

  const planCards = [single_backup_price, upgrade_plan].filter((value) => value !== undefined);

  const singleBackupPlan = {
    description: intl.formatMessage({ id: 'translate.page.backup.buy-backup.single-backup.title' }),
    subtitle: intl.formatMessage({ id: 'translate.page.backup.buy-backup.single-backup.info-text' }),
    features: [
      {
        text: intl.formatMessage(
          { id: 'translate.page.backup.buy-backup.single-backup.instant-backups-count.text' },
          { count: 1 }
        )
      },
      {
        text: intl.formatMessage({ id: 'translate.page.backup.buy-backup.single-backup.restores.text' })
      }
    ]
  };

  return (
    <React.Fragment>
      <Text weight="bold">{intl.formatMessage({ id: 'translate.page.backup.restore.buy.backups.text' })}</Text>
      <br />
      <Grid gap="responsive" sm={String(planCards.length) as GridColumns}>
        <HostingPlanCard
          title={singleBackupPlan.description}
          subtitle={singleBackupPlan.subtitle}
          priceProps={{
            currentPriceText: `${formatCurrency({
              price: single_backup_price,
              currencySymbol: currencyProps.currencySymbol,
              currencySymbolBefore: currencyProps.currencySymbolBefore
            })}`,
            vatText: getVatText({
              showPricesWithVat: false,
              showFullText: false,
              taxName: currencyProps.taxName,
              intlProvider: intl
            })
          }}
          iconProps={{
            name: 'product-file-backup-instant',
            color: 'royal',
            fill: 'light',
            size: '50'
          }}
          featureProps={{
            features: singleBackupPlan.features
          }}
          dialogProps={{
            closeButtonText: intl.formatMessage({ id: 'translate.generic.close' })
          }}
          buttonProps={{
            selected: selectedType === BuyBackupTypes.singleBackup,
            text: intl.formatMessage({ id: 'translate.generic.select' }),
            textSelected: intl.formatMessage({ id: 'translate.generic.selected' }),
            onClick: () => onSelection(BuyBackupTypes.singleBackup)
          }}
        />

        {upgradeDataAvailable && (
          <PlanCard
            intl={intl}
            labelProps={{
              color: 'success',
              value: intl.formatMessage({ id: 'translate.generic.recommended' })
            }}
            plan={upgrade_plan}
            iconProps={{ ...PLAN_CARD_ICON_PROPS[upgrade_plan.name], size: '50' }}
            buttonProps={{
              selected: selectedType === BuyBackupTypes.growBig,
              text: intl.formatMessage({ id: 'translate.generic.select' }),
              textSelected: intl.formatMessage({ id: 'translate.generic.selected' }),
              onClick: () => onSelection(BuyBackupTypes.growBig)
            }}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};

function mapStateToProps(store) {
  return {
    currencyProps: getCurrencyData(store)
  };
}

export const BuyBackup: React.ComponentClass<BuyBackupParentProps, any> = connect<
  BuyBackupConnectedProps,
  Props,
  BuyBackupParentProps
>(
  mapStateToProps,
  null
)(BuyBackupComponent);
