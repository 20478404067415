import * as React from 'react';
import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import SGTable from '../../../components/sg-table';
import VCS from '../../../components/visibility-control-service';

export const VariablesTable = ({
  phpVersionData,
  filteredData,
  renderPhpVariablesMobileFilter,
  resetPhpVariableSearch,
  columns
}) => {
  const intl = useIntl();
  return (
    <VCS resourceName={API_RESOURCE.PHP_LOCATION_INI.resourceNameMetaApi} hasMethod="GET">
      {Boolean(phpVersionData && phpVersionData.disabled) ? (
        <SGTable
          shadow={false}
          data={[]}
          columns={columns}
          mobileLayout="card-flat"
          noDataTitle="translate.page.phpSettings.missing.php-version-table.label"
          resources={[
            {
              resourceName: API_RESOURCE.USERVICE.resourceName,
              methods: ['GET', 'POST', 'DELETE']
            }
          ]}
        />
      ) : (
        <SGTable
          shadow={false}
          renderBeforeTableContent={renderPhpVariablesMobileFilter}
          data={filteredData}
          showHeaderWithNoData
          noDataProps={{
            icon: 'presentational-no-results-found',
            title: intl.formatMessage({ id: 'translate.no-results-found' }),
            message: (
              <FormattedMessage
                id="translate.php-variables.clear-filter"
                values={{
                  link: (
                    <Link onClick={resetPhpVariableSearch}>
                      {intl.formatMessage({ id: 'translate.clear-filters' })}
                    </Link>
                  )
                }}
              />
            )
          }}
          columns={columns}
          mobileLayout="card-flat"
          resources={[
            {
              resourceName: API_RESOURCE.PHP_LOCATION_INI_SETTINGS.resourceName,
              methods: ['GET', 'DELETE']
            },
            {
              resourceName: API_RESOURCE.PHP_LOCATION_INI.resourceName,
              methods: ['GET', 'DELETE', 'POST', 'PUT']
            },
            {
              resourceName: API_RESOURCE.USERVICE.resourceName,
              methods: ['GET', 'POST', 'DELETE']
            }
          ]}
        />
      )}
    </VCS>
  );
};

export default VariablesTable;
