import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column, Notice } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-spanel-forms';

type Props = {
  domainName: string;
  intl: Intl;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CREATE_DOMAIN_ALIAS;

const Form = ({ domainName, intl, validationUtils }: Props) => {
  const { required, domain } = validationUtils;

  return (
    <SGForm>
      <Column smSpan="12">
        <Notice background="light" shadow={false} border={false} type="info">
          {intl.formatMessage({ id: 'translate.page.domainAlias.create.notice' })}
        </Notice>
      </Column>

      <Column smSpan="6">
        <Field
          name="name"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.domain' })}
          validate={[required, domain]}
          normalize={(value) => value.toLowerCase()}
          component={FormInput}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.add' })} />
      </Column>
    </SGForm>
  );
};

const DomainAliasForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.DOMAIN_ALIAS,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(DomainAliasForm));
