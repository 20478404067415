import * as React from 'react';
import { Button, Column, Grid, Notice, Text } from '@siteground/styleguide';
import { openNewTabAction } from '../../../../core/actions/open-new-tab';
import { API_RESOURCE } from '../../../../core/constants/api';
import { RootState } from '../../../reducers';
import CreateBox from '../../../components/create-box';
import indexWithCRUD from '../../../components/indexWithCRUD';
import VCS from '../../../components/visibility-control-service';

type Props = {
  items: any;
  intl: Intl;
  actions?: {
    fetchItems: FetchItems;
  };
  extapp: [
    {
      app_login: string;
    }
  ];
  environment?: any;
  openNewTabAction?: typeof openNewTabAction;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.EXTAPP;

class MysqlPHPMyAdmin extends React.Component<Props, any> {
  componentDidMount() {
    if (this.hasDatabases(this.props)) {
      this.fetchURL();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.hasDatabases(prevProps) !== this.hasDatabases(this.props)) {
      this.fetchURL();
    }
  }

  fetchURL() {
    this.props.actions.fetchItems({
      ...API_RESOURCE.EXTAPP,
      urlParams: {
        id: 'phpmyadmin'
      }
    });
  }

  hasDatabases(props) {
    const { items } = props;
    const { dbMysqlGrant = [], dbMysqlDb = [] } = items;
    const databases = dbMysqlDb.map((database) => ({
      ...database,
      usersWithAccess: dbMysqlGrant.filter(({ db_id }) => db_id === database.id)
    }));

    return databases.length > 0;
  }

  render() {
    const { environment, extapp, intl, items } = this.props;
    const { dbMysqlGrant = [], dbMysqlDb = [] } = items;
    const databases = dbMysqlDb.map((database) => ({
      ...database,
      usersWithAccess: dbMysqlGrant.filter(({ db_id }) => db_id === database.id)
    }));
    const hasNoDatabases = databases.length === 0;

    return (
      <VCS resourceName={resourceNameMetaApi} hasMethod="GET">
        <CreateBox
          title={intl.formatMessage({ id: 'translate.page.mysql.phpmyadmin.title' })}
          resourceName={resourceName}
          resources={[{ resourceName, methods: ['GET'] }]}
        >
          <Grid sm="12" padding="responsive" gap="responsive">
            <Column smSpan="12">
              <Notice type="info" background="light" shadow={false}>
                <Text>{intl.formatMessage({ id: 'translate.page.mysql.phpmyadmin.notice.text' })}</Text>

                {hasNoDatabases && (
                  <Text>{intl.formatMessage({ id: 'translate.page.mysql.phpmyadmin.notice.no.database.text' })}</Text>
                )}
              </Notice>
            </Column>

            {!hasNoDatabases && extapp.length > 0 && (
              <Column smSpan="12">
                <Button color="primary" onClick={() => this.props.openNewTabAction({ url: extapp[0].app_login })}>
                  {intl.formatMessage({ id: 'translate.page.mysql.phpmyadmin.notice.button' })}
                </Button>
              </Column>
            )}
          </Grid>
        </CreateBox>
      </VCS>
    );
  }
}

export default indexWithCRUD(
  (store: RootState) => ({
    environment: store.environment,
    extapp: store.pageItems[resourceName] || []
  }),
  { openNewTabAction }
)(MysqlPHPMyAdmin, API_RESOURCE.MYSQL_DB);
