import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import * as Actions from '../constants/actions';
import * as fetchActions from '../actions/fetch';
import { fetchI18N } from '../api/i18n';
import * as i18nActions from '../actions/i18n';
import { updateIntlInstance } from '../translate';

function* loadTranslations(action) {
  const { payload } = action;
  const { fileName } = payload;

  yield put(fetchActions.httpRequestStarted(action));

  try {
    const translations = yield call(fetchI18N(fileName));
    yield put(i18nActions.changeI18N({ locale: fileName, messages: translations }));
    yield put(fetchActions.httpRequestSucceeded(action));
  } catch (e) {
    yield put(fetchActions.httpRequestFailed(action, 'CANNOT_LOAD_TRANSLATIONS'));
    console.error(e);
  }
}

export default function* translations() {
  yield takeLatest(Actions.LOAD_TRANSLATIONS, loadTranslations);

  yield takeEvery(Actions.CHANGE_I18N, function* () {
    // update intl instance, used outside of the components tree
    updateIntlInstance();
  });
}
