import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Dropdown, Grid, Section, Tab, Tabs } from '@siteground/styleguide';

import * as emailFiltersActions from '../../../core/actions/pages/email-filters';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { ToolId } from '../../../core/constants/route-info';
import { filterStagingDomains } from '../../../core/selectors';
import { moveEntity } from '../../../core/utils/array';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../components/dialogs';
import { SGDialogForm } from '../../components/sg-dialog';
import SecondLevelTitle from '../../components/titles/second-level-title';
import VCS from '../../components/visibility-control-service';
import { CreateBox, CreateForm } from './create';
import './email-filters.scss';
import UpdateFieldsForm from './update/fields';
import EmailToolNotAvailable from '../../components/email-tool-not-available';
import { isTemporaryDomain } from '../dns/utils';

const USER_FILTERS = 'USER_FILTERS';
const DOMAIN_FILTERS = 'DOMAIN_FILTERS';

interface EmailFilterProps {
  actions: CrudActions;
  emailFiltersActions: any;
  environment: {
    isPhone: boolean;
    isDevicePhone: boolean;
    isDeviceTablet: boolean;
  };
  email: any;
  items: any;
  listedDomains: any[];
  location: any;
  intl: Intl;
  closeSGDialog: Function;
  openSGDialog: Function;
}

class EmailFilterPage extends React.Component<EmailFilterProps, any> {
  constructor(props) {
    super(props);

    this.state = {
      currentUpdatePayload: null,
      currentDeleteConformationDialogPayload: null,
      selectedFilterEntity: null,
      filterType: USER_FILTERS
    };
  }

  isTouchDevice = () => {
    const { environment } = this.props;

    return environment.isDevicePhone || environment.isDeviceTablet;
  };

  getFilteredEmailFilters() {
    const { items } = this.props;
    const { filterType, selectedFilterEntity } = this.state;

    if (!selectedFilterEntity) {
      return;
    }

    return items.emailFilter
      .filter((filter) => {
        const { name, id, domain_id } = this.state.selectedFilterEntity;

        if (filterType === USER_FILTERS) {
          return filter.domain_id === domain_id && filter.name === name;
        }

        return filter.domain_id === id && filter.name === '*';
      })
      .sort((a, b) => {
        if (a.filter_order > b.filter_order) {
          return 1;
        }

        if (a.filter_order < b.filter_order) {
          return -1;
        }

        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
  }

  onCreateFormSubmit = (filterType: string) => (formData) => {
    const { selectedFilterEntity } = this.state;
    const { domain_id, id, name } = selectedFilterEntity;
    const domainId = domain_id ? domain_id : id;

    if (filterType === USER_FILTERS) {
      formData.name = name;
    } else {
      formData.name = '*';
    }

    formData.domain_id = domainId;

    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_EMAIL_FILTER_ITEM,
          success: {
            intlKey: 'translate.page.emailFilter.created_msg',
            intlValues: { name: formData.filter_name }
          },
          error: {
            intlKey: 'translate.page.emailFilter.failed_create_msg',
            intlValues: { name: formData.filter_name }
          }
        }
      }
    });
  };

  handleReorderedData = (data) => {
    const allFilters = this.getFilteredEmailFilters();
    // Due to partially loading the table data, when we have more than 20 rows, we need to add the missing IDs manually.
    const missingIDs = allFilters.length > data.length ? allFilters.slice(data.length) : [];
    const reorderedFilters = [...data, ...missingIDs];

    const { selectedFilterEntity, filterType } = this.state;
    const { domain_id, id, name } = selectedFilterEntity;
    const domainId = domain_id ? domain_id : id;

    this.props.emailFiltersActions.updateEmailFilterOrder({
      name: filterType === DOMAIN_FILTERS ? '*' : name,
      domain_id: domainId,
      filter_ids: reorderedFilters.map((entity) => entity.id),
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.emailFilter.update.filter.order.message'
          },
          error: {
            intlKey: 'translate.page.emailFilter.failed.update.filter.order.message'
          }
        }
      }
    });
  };

  renderPageContent = ({ columns, filterType, emailSelectOptions, email, listedDomains, selectedFilterEntity }) => {
    const { intl } = this.props;

    const isSelectedDomainFilters = Boolean(filterType === DOMAIN_FILTERS);
    const selectedDomain = isSelectedDomainFilters && selectedFilterEntity;

    const isSelectedDomainTemp = Boolean(
      isSelectedDomainFilters && selectedDomain && isTemporaryDomain(selectedDomain.name)
    );

    let contentToRender = null;

    switch (true) {
      case isSelectedDomainTemp:
        contentToRender = <EmailToolNotAvailable />;
        break;
      case Boolean(selectedFilterEntity):
        contentToRender = (
          <React.Fragment>
            <CreateBox>
              <CreateForm
                isDomainLevelFilter={this.state.filterType === DOMAIN_FILTERS}
                selectedFilterEntity={selectedFilterEntity}
                onSubmit={this.onCreateFormSubmit(this.state.filterType)}
              />
            </CreateBox>
            <VCS resourceName={API_RESOURCE.EMAIL_FILTER.resourceNameMetaApi} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.emailFilter.list.title' })}
                data={this.getFilteredEmailFilters()}
                columns={columns}
                reordable={this.isTouchDevice() ? undefined : true}
                onReorder={(data) => this.isTouchDevice() === false && this.handleReorderedData(data)}
                resources={[
                  { requestTypeName: customRequestTypes.EMAIL_FILTER_ORDER },
                  { resourceName: API_RESOURCE.EMAIL_FILTER.resourceName, methods: ['GET'] }
                ]}
                noDataMessage="translate.page.emailFilter.sg-table.no-data.message"
              />
            </VCS>
          </React.Fragment>
        );
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        {filterType === USER_FILTERS && (
          <div>
            <SecondLevelTitle>{intl.formatMessage({ id: 'translate.generic.select.email.user' })}</SecondLevelTitle>

            <Dropdown
              placeholder={intl.formatMessage({ id: 'translate.page.emailFilter.email.select.placeholder' })}
              options={emailSelectOptions}
              optionValue="id"
              optionLabel="fullEmail"
              groupBy="domain_name"
              size="x-large"
              searchable={emailSelectOptions.length > 1}
              enableShadow
              data-e2e="email-filters-users"
              translation={{
                nothingFound: intl.formatMessage({ id: 'translate.nothing_was_found' })
              }}
              onChange={(id) => this.setState({ selectedFilterEntity: email.find((mail) => mail.id === id) })}
            />
          </div>
        )}

        {filterType === DOMAIN_FILTERS && (
          <div>
            <SecondLevelTitle>{intl.formatMessage({ id: 'translate.generic.select.domain' })}</SecondLevelTitle>

            <Dropdown
              placeholder={intl.formatMessage({ id: 'translate.page.emailFilter.domain.select.placeholder' })}
              options={listedDomains}
              optionValue="id"
              optionLabel="name"
              size="x-large"
              searchable={listedDomains.length > 1}
              enableShadow
              data-e2e="email-filters-domains"
              onChange={(id) => this.setState({ selectedFilterEntity: listedDomains.find((dmn) => dmn.id === id) })}
            />
          </div>
        )}

        {contentToRender}
      </React.Fragment>
    );
  };

  render() {
    if (!this.props.items.emailFilter) {
      return null;
    }

    return this.renderEmailFilterItems();
  }

  renderEmailFilterItems = () => {
    const { intl, email, listedDomains } = this.props;
    const { selectedFilterEntity, filterType } = this.state;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.page.emailFilter.filter.name' }),
        accessor: 'filter_name'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    const emailSelectOptions = email.map((mail) => ({
      ...mail,
      fullEmail: `${mail.name}@${mail.domain_name}`
    }));

    return (
      <div>
        <PageHeader
          id={ToolId.emailFilters}
          instructions={intl.formatMessage({ id: 'translate.page.emailFilter.info' })}
        />

        <Section>
          <Tabs border="light">
            <Tab
              data-e2e={`email-filter-tab-${USER_FILTERS}`}
              active={filterType === USER_FILTERS}
              onClick={() => this.setState({ filterType: USER_FILTERS, selectedFilterEntity: null })}
            >
              {intl.formatMessage({ id: 'translate.page.emailFilter.user.filters' })}
            </Tab>

            <Tab
              data-e2e={`email-filter-tab-${DOMAIN_FILTERS}`}
              active={filterType === DOMAIN_FILTERS}
              onClick={() => this.setState({ filterType: DOMAIN_FILTERS, selectedFilterEntity: null })}
            >
              {intl.formatMessage({ id: 'translate.page.emailFilter.domain.filters' })}
            </Tab>
          </Tabs>
        </Section>

        <Section>
          <Grid>
            {this.renderPageContent({
              columns,
              filterType,
              emailSelectOptions,
              email,
              listedDomains,
              selectedFilterEntity
            })}
          </Grid>
        </Section>

        {this.renderUpdateComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.entityName;

    return (
      <DeleteDialog
        title={this.props.intl.formatMessage({ id: 'translate.page.emailFilter.delete.dialog.title' }, { entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderUpdateComponent = () => {
    const { actions, closeSGDialog, intl } = this.props;
    const { currentUpdatePayload, selectedFilterEntity } = this.state;
    const entityName = currentUpdatePayload && currentUpdatePayload.filter_name;

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_EMAIL_FILTERS_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.emailFilter.update.title' }, { filterName: entityName })}
        size="x-large"
        resources={[
          {
            resourceName: API_RESOURCE.EMAIL_FILTER.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <UpdateFieldsForm
          isDomainLevelFilter={this.state.filterType === DOMAIN_FILTERS}
          initialValues={currentUpdatePayload}
          selectedFilterEntity={selectedFilterEntity}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.CHANGE_EMAIL_FILTERS_DIALOG))}
        />
      </SGDialogForm>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const filters = this.getFilteredEmailFilters();
    const isTouchDevice = this.isTouchDevice();

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      entityName: entity.filter_name,
      _metaFields: { ...API_RESOURCE.EMAIL_FILTER },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.emailFilter.deleted_msg',
            intlValues: { account: entity.filter_name }
          },
          error: {
            intlKey: 'translate.page.emailFilter.failed_delete_msg',
            intlValues: { account: entity.filter_name }
          }
        }
      }
    };
    const updatePayload = {
      _metaFields: {
        ...API_RESOURCE.EMAIL_FILTER
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.emailFilter.update_msg',
            intlValues: { account: entity.filter_name }
          },
          error: {
            intlKey: 'translate.page.emailFilter.failed_update_msg',
            intlValues: { account: entity.filter_name }
          }
        }
      },
      ...entity
    };

    let buttonsToUse: any = [
      {
        vcsMethod: 'PUT',
        icon: 'edit',
        label: intl.formatMessage({ id: 'translate.generic.edit' }),
        e2eAttr: 'table-action-edit',
        visibleOnDesktop: !isTouchDevice,
        onClick: () =>
          this.setState({ currentUpdatePayload: updatePayload }, () =>
            openSGDialog(REDUX_FORM.CHANGE_EMAIL_FILTERS_DIALOG)
          )
      },
      {
        vcsMethod: 'DELETE',
        icon: 'trash',
        label: intl.formatMessage({ id: 'translate.generic.delete' }),
        e2eAttr: 'table-action-delete',
        visibleOnDesktop: !isTouchDevice,
        onClick: () =>
          this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
            openSGDialog(DIALOGS.GENERIC_DELETE)
          )
      }
    ];

    if (isTouchDevice) {
      const currentFilterIndex = filters.findIndex((e) => e.id === entity.id);

      if (currentFilterIndex !== 0) {
        buttonsToUse = buttonsToUse.concat({
          vcsMethod: 'PUT',
          icon: 'arrow-up',
          label: intl.formatMessage({ id: 'translate.page.emailFilter.move.up' }),
          e2eAttr: 'table-action-move-up',
          onClick: () => {
            const data = moveEntity(filters, currentFilterIndex, currentFilterIndex - 1);
            this.handleReorderedData(data);
          }
        });
      }

      if (currentFilterIndex !== filters.length - 1) {
        buttonsToUse = buttonsToUse.concat({
          vcsMethod: 'PUT',
          icon: 'arrow-down',
          label: intl.formatMessage({ id: 'translate.page.emailFilter.move.down' }),
          e2eAttr: 'table-action-move-down',
          onClick: () => {
            const data = moveEntity(filters, currentFilterIndex, currentFilterIndex + 1);
            this.handleReorderedData(data);
          }
        });
      }
    }

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.EMAIL_FILTER.resourceNameMetaApi}
        items={buttonsToUse}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  email: state.pageItems.email || [],
  listedDomains: filterStagingDomains(state, API_RESOURCE.DOMAIN_ALL.resourceName),
  environment: state.environment
});

export default indexWithCRUD(mapStateToProps, (dispatch) => ({
  ...bindActionCreators(sgDialogActions, dispatch),
  emailFiltersActions: bindActionCreators(emailFiltersActions as any, dispatch)
}))(EmailFilterPage, API_RESOURCE.EMAIL_FILTER, API_RESOURCE.EMAIL, API_RESOURCE.DOMAIN_ALL);
