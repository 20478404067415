import * as React from 'react';
import Textarea from '@siteground/styleguide/lib/components/textarea';

const FormTextarea = ({ input, meta, requiered, ...props }) => {
  let status;
  let error;

  const touchedOrActive = meta.touched || meta.active;
  const touchedOrHasValue = meta.touched || Boolean(input.value);

  if (touchedOrActive && meta.valid && meta.dirty) {
    status = 'success';
  }

  if (!meta.valid && touchedOrActive && touchedOrHasValue) {
    status = 'error';
    error = meta.error;
  }

  return (
    <Textarea
      data-e2e={`form-textarea-${input.name}`}
      rows="8"
      state={status}
      validationMessage={error}
      {...input}
      {...props}
    />
  );
};

export default FormTextarea;
