import * as React from 'react';
import { injectIntl } from 'react-intl';
import { List, ListItem, Text, Notice } from '@siteground/styleguide';

type Props = {
  intl: Intl;
};

const MEMCACHED_IP = '127.0.0.1';
const MEMCACHED_PORT = '11211';

export const MemCacheNotice = ({ intl }: Props) => (
  <Notice
    type="instruction"
    title={intl.formatMessage({ id: 'translate.page.memcached.notice.title' })}
    shadow={false}
    background="light"
  >
    <Text>{intl.formatMessage({ id: 'translate.page.memcached.notice.description' })}</Text>
    <br />
    <Text>{intl.formatMessage({ id: 'translate.page.memcached.notice.list.description' })}</Text>
    <List type="instruction">
      <ListItem icon="dot">
        <strong>{intl.formatMessage({ id: 'translate.page.superCacher.memcached.ip.label' })}</strong> {MEMCACHED_IP}
      </ListItem>
      <ListItem icon="dot">
        <strong>{intl.formatMessage({ id: 'translate.page.superCacher.memcached.port.label' })}</strong>{' '}
        {MEMCACHED_PORT}
      </ListItem>
    </List>
  </Notice>
);

export default injectIntl(MemCacheNotice);
