import * as React from 'react';
import { Grid, Table } from '@siteground/styleguide';
import { BarChart, ChartContainer, ChartLegend, ChartTooltip } from '@siteground/styleguide/lib/components/charts';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { Countries, StatsType } from '../../../../core/definitions/stats';
import PartialLoader from '../../../components/partial-loader';
import SGTable from '../../../components/sg-table';
import { ControlOptions, StatsViewIndex } from '../index';
import { TABLE_VIEW_PROPS } from '../utils';

type Props = {
  statsMissing: boolean;
  getStats: (statsKey: StatsType) => any[];
  getCountryNameByCode: (countryCode: string) => string;
  renderControls: (option?: ControlOptions) => any;
  statisticViewIndex: StatsViewIndex;
  intl: Intl;
};

class Audience extends React.Component<Props, {}> {
  renderStatistics = () => {
    const { statisticViewIndex } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <PartialLoader resources={[{ requestTypeName: customRequestTypes.COUNTRIES }]}>
          {statisticViewIndex === StatsViewIndex.Chart ? this.renderChartView() : this.renderTableView()}
        </PartialLoader>
      </div>
    );
  };

  renderChartView = () => {
    const { getStats, getCountryNameByCode, intl } = this.props;
    const countriesStats: Countries[] = getStats('country');
    const elementsIds = ['pages', 'hits'];

    return (
      <ChartContainer
        legendCols="2"
        chartCols="10"
        title={intl.formatMessage({ id: 'translate.page.stats.countries.label' })}
        renderCustomLegendContent={() => (
          <ChartLegend
            data={[
              {
                id: 'pages',
                label: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
                checked: true
              },
              {
                id: 'hits',
                label: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
                checked: true
              }
            ]}
            checkboxIcon="empty"
            labelKey="label"
          />
        )}
        renderChildren={() => {
          return (
            <BarChart
              data={countriesStats}
              yAxisWidth={80}
              axisData={{
                y: 'country'
              }}
              tickFormatterY={getCountryNameByCode}
              elementsIds={elementsIds}
              filteredElementsIds={elementsIds}
              renderTooltip={({ payload }: { payload: Countries }) => (
                <ChartTooltip
                  elementsIds={elementsIds}
                  filteredElementsIds={elementsIds}
                  payload={payload}
                  title={getCountryNameByCode(payload.country)}
                  elementLabels={{
                    pages: `${intl.formatMessage({ id: 'translate.page.stats.pageviews.label' })}: `,
                    hits: `${intl.formatMessage({ id: 'translate.page.stats.hits.label' })}: `
                  }}
                />
              )}
            />
          );
        }}
      />
    );
  };

  renderTableView = () => {
    const { getStats, getCountryNameByCode, intl } = this.props;
    const countriesStats: Countries[] = getStats('country');

    return (
      <Table
        data={countriesStats}
        columns={[
          {
            header: intl.formatMessage({ id: 'translate.page.stats.country.label' }),
            accessor: 'country',
            render: (country) => getCountryNameByCode(country)
          },
          {
            header: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
            accessor: 'hits'
          },
          {
            header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
            accessor: 'pages'
          }
        ]}
        {...TABLE_VIEW_PROPS}
      />
    );
  };

  render() {
    const { statsMissing, renderControls } = this.props;

    if (statsMissing) {
      return (
        <SGTable
          data={[]}
          border="small"
          shadow={false}
          noDataMessage="translate.page.stats.no-stats.placeholder.message"
        />
      );
    }

    return (
      <Grid gap="responsive">
        {renderControls()}

        {this.renderStatistics()}
      </Grid>
    );
  }
}

export default Audience;
