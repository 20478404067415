import { Button, Context, Label, Flex, Grid, Link, Progress, Spacer } from '@siteground/styleguide';
import { HostingTypes } from '@siteground/styleguide/lib/utils';
import * as React from 'react';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import { getCurrentSite } from '../../../../core/selectors';
import { generateEntitiesCountLabel, getDbEntityLabelByName } from '../../../../core/utils/db-entities';
import { formatCheckboxesData } from '../../../../core/utils/db-forms';
import { KBToMB } from '../../../../core/utils/stats-format';
import { AddUserToDb, EditDbLabel, EditDbUser } from '../../../components/common-forms';
import DbGrantsList from '../../../components/db-grants-list';
import { DeleteDialog } from '../../../components/dialogs';
import indexWithCRUD from '../../../components/indexWithCRUD';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../../components/sg-dialog';
import SGTable from '../../../components/sg-table';
import TableContextMenu from '../../../components/table-context-menu/table-context-menu';
import VCS from '../../../components/visibility-control-service';
import withRouteTo from '../../../components/with-route-to';
import { RootState } from '../../../reducers';
import Create from './create';
import SelectDatabaseDump from './select-database-dump';

type Props = {
  actions: CrudActions;
  items: any;
  location: any;
  selectedSite: SiteItem;
  intl: Intl;
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  currentUpdatePayload: any;
  currentSelectDBDumpPayload: any;
  currentDeletePayload: any;
  addUserPayload: any;
  editUserPayload: any;
  selectedDbPayload: any;
};

const RouteButton = withRouteTo(Button);

class DbMysqlDbPage extends React.Component<Props, State> {
  readonly state = {
    currentUpdatePayload: null,
    currentSelectDBDumpPayload: null,
    currentDeletePayload: null,
    addUserPayload: null,
    editUserPayload: null,
    selectedDbPayload: null
  };

  openManageUsersDialog = ({ id, name }) => {
    this.setState(
      {
        selectedDbPayload: { id, name }
      },
      () => this.props.openSGDialog(DIALOGS.LIST_DB_ENTITIES)
    );
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_MYSQL_DATABASE,
          success: {
            intlKey: 'translate.page.database.created_msg'
          },
          error: {
            intlKey: 'translate.page.database.created.error.message'
          }
        }
      }
    });
  };

  renderDatabaseSize = (data) => {
    const { selectedSite, intl } = this.props;
    const usedBytes = KBToMB(data.mysql_used).toFixed(0);
    const maxBytes = KBToMB(data.mysql_size_max).toFixed(0);
    const percent = Math.min((data.mysql_used / data.mysql_size_max) * 100, 100);

    if (selectedSite.account_type === HostingTypes.CLOUD) {
      return (
        <Flex align="center" justify="space-between">
          {usedBytes} MB
          <Label color="ocean" type="outlined">
            {intl.formatMessage({ id: 'translate.generic.quota.unlimited' })}
          </Label>
        </Flex>
      );
    }

    return (
      <Flex align="center" justify="space-between">
        {usedBytes} MB &nbsp;
        <Progress color="blue" percent={percent} tabIndex={-1} />
        &nbsp;
        {maxBytes} MB
      </Flex>
    );
  };

  render() {
    const { intl, items, openSGDialog } = this.props;
    const { dbMysqlGrant = [], dbMysqlDb } = items;
    const databases =
      dbMysqlDb &&
      dbMysqlDb.map((database) => ({
        ...database,
        usersWithAccess: dbMysqlGrant.filter(({ db_id }) => db_id === database.id)
      }));

    return (
      <Grid>
        <Create
          onSubmit={this.onCreateFormSubmit}
          onAddUserToDatabase={(entity) =>
            this.setState(
              {
                addUserPayload: entity
              },
              () => openSGDialog(REDUX_FORM.ADD_USER_TO_DB)
            )
          }
        />

        <VCS resourceName={API_RESOURCE.MYSQL_DB.resourceNameMetaApi} hasMethod="GET">
          <SGTable
            title={intl.formatMessage({ id: 'translate.page.database.list.title' })}
            data={databases}
            columns={[
              {
                header: intl.formatMessage({ id: 'translate.generic.name' }),
                accessor: 'name',
                mSize: '25%'
              },
              {
                header: intl.formatMessage({ id: 'translate.generic.label' }),
                accessor: 'label',
                mSize: '25%'
              },
              {
                header: intl.formatMessage({ id: 'translate.generic.size' }),
                accessor: 'mysql_used',
                mSize: '40%',
                render: (mysql_used, data) => this.renderDatabaseSize(data)
              },
              {
                header: intl.formatMessage({ id: 'translate.generic.users' }),
                accessor: 'id',
                mSize: '10%',
                render: (id, { name, usersWithAccess }) => (
                  <Link onClick={() => this.openManageUsersDialog({ id, name })}>
                    {generateEntitiesCountLabel(
                      usersWithAccess,
                      intl.formatMessage({ id: 'translate.generic.user' }),
                      intl.formatMessage({ id: 'translate.generic.users' })
                    )}
                  </Link>
                )
              },
              {
                header: intl.formatMessage({ id: 'translate.generic.actions' }),
                accessor: 'id',
                render: this.renderContextMenu
              }
            ]}
            resources={[
              {
                resourceName: API_RESOURCE.MYSQL_DB.resourceName,
                methods: ['GET']
              },
              {
                resourceName: API_RESOURCE.MYSQL_DB_GRANT.resourceName,
                methods: ['GET']
              },
              {
                resourceName: API_RESOURCE.MYSQL_DB_USER.resourceName,
                methods: ['GET']
              }
            ]}
            noDataMessage="translate.page.database.sg-table.no-data.message"
          />
        </VCS>

        {this.renderUpdateComponent()}
        {this.renderDatabaseDumpDialog()}
        {this.renderDatabaseUsers()}
        {this.renderDatabaseAddUserComponent()}
        {this.renderDatabaseEditUserComponent()}
        {this.renderDeleteConfirmation()}
        {this.renderNoUsersDialog()}
      </Grid>
    );
  }

  renderDatabaseUsers = () => {
    const { intl, items, openSGDialog } = this.props;
    const { selectedDbPayload } = this.state;
    const dbId = selectedDbPayload && selectedDbPayload.id;
    const dbName = selectedDbPayload && selectedDbPayload.name;
    const grants = (items && items.dbMysqlGrant) || [];
    const users = grants.filter(({ db_id }) => db_id === dbId);

    return (
      <Context.Consumer>
        {({ device }) => (
          <SGDialog
            id={DIALOGS.LIST_DB_ENTITIES}
            title={intl.formatMessage({ id: 'translate.page.mysql.users-list-dialog.title' }, { name: dbName })}
            density={device.isPhone ? 'medium' : 'small'}
            size="x-large"
            icon="edit"
            state="warning"
            footer={
              <SGDialogCancel
                id={DIALOGS.LIST_DB_ENTITIES}
                label={intl.formatMessage({ id: 'translate.generic.close' })}
              />
            }
          >
            <DbGrantsList
              entityType="user"
              entities={users}
              getLabelByName={(label) => getDbEntityLabelByName(label, items.dbMysqlUser)}
              renderContextMenu={(user) => (
                <TableContextMenu
                  resourceName={API_RESOURCE.MYSQL_DB_GRANT.resourceNameMetaApi}
                  items={[
                    {
                      vcsMethod: 'PUT',
                      icon: 'product-server',
                      label: intl.formatMessage({ id: 'translate.page.protected.user.manage.access' }),
                      e2eAttr: 'table-action-provide-user',
                      visibleOnDesktop: true,
                      onClick: () =>
                        this.setState(
                          {
                            editUserPayload: {
                              grant: user,
                              db_id: user.db_id,
                              reversed: 1
                            }
                          },
                          () => openSGDialog(REDUX_FORM.EDIT_DB_USER)
                        )
                    }
                  ]}
                />
              )}
            />
          </SGDialog>
        )}
      </Context.Consumer>
    );
  };

  renderUpdateComponent = () => {
    const { currentUpdatePayload } = this.state;
    const { intl, closeSGDialog } = this.props;
    const name = currentUpdatePayload && currentUpdatePayload.name;

    return (
      <SGDialogForm
        name={REDUX_FORM.EDIT_DB_LABEL}
        title={intl.formatMessage({ id: 'translate.page.database.update.title' }, { name })}
        resources={[
          {
            resourceName: API_RESOURCE.MYSQL_DB.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <EditDbLabel
          initialValues={currentUpdatePayload}
          onSubmit={(data) => this.props.actions.updateItem(data, () => closeSGDialog(REDUX_FORM.EDIT_DB_LABEL))}
        />
      </SGDialogForm>
    );
  };

  renderDatabaseDumpDialog = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentSelectDBDumpPayload } = this.state;
    const name = currentSelectDBDumpPayload && currentSelectDBDumpPayload.name;

    const initialValues = {
      db_name: name,
      filename: '',
      _metaFields: {
        ...API_RESOURCE.DBIMPORT
      }
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.SELECT_DB_DUMP}
        icon="database"
        size="large"
        title={intl.formatMessage({ id: 'translate.page.database.select.db.dump.title' })}
        subTitle={intl.formatMessage({ id: 'translate.page.database.select.db.dump.subtitle' })}
        submitLabel={intl.formatMessage({ id: 'translate.generic.import' })}
        resources={[
          {
            resourceName: API_RESOURCE.DBIMPORT.resourceName,
            methods: ['POST']
          }
        ]}
      >
        <SelectDatabaseDump
          initialValues={initialValues}
          onSubmit={(data) => {
            const modifiedData = {
              ...data,
              filename: `www${data.filename}`,
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.page.database.db.dump.success.message',
                    intlValues: { name: data.filename }
                  },
                  error: {
                    intlKey: 'translate.page.database.db.dump.error.message',
                    intlValues: { name: data.filename }
                  }
                }
              }
            };

            actions.createItem(modifiedData, () => closeSGDialog(REDUX_FORM.SELECT_DB_DUMP));
          }}
        />
      </SGDialogForm>
    );
  };

  renderDatabaseAddUserComponent = () => {
    const { intl, items, actions, closeSGDialog } = this.props;
    const { addUserPayload } = this.state;
    const name = addUserPayload && addUserPayload.name;

    return (
      <SGDialogForm
        name={REDUX_FORM.ADD_USER_TO_DB}
        icon="product-server"
        title={intl.formatMessage({ id: 'translate.page.database.add.user.title' }, { name })}
        resources={[
          {
            resourceName: API_RESOURCE.MYSQL_DB_GRANT.resourceName,
            methods: ['POST']
          }
        ]}
      >
        <AddUserToDb
          rowData={addUserPayload}
          dbUsers={items[API_RESOURCE.MYSQL_DB_USER.resourceName]}
          dbGrant={items[API_RESOURCE.MYSQL_DB_GRANT.resourceName]}
          onSubmit={({ checkboxes, dbuser_id }) => {
            actions.createItem(
              {
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.database_access.add.user.success.message',
                      intlValues: { name }
                    },
                    error: {
                      intlKey: 'translate.page.database_access.add.user.error.message',
                      intlValues: { name }
                    }
                  }
                },
                _metaFields: {
                  ...API_RESOURCE.MYSQL_DB_GRANT
                },
                db_id: addUserPayload && addUserPayload.id,
                dbuser_id,
                grants: formatCheckboxesData(checkboxes)
              },
              () => closeSGDialog(REDUX_FORM.ADD_USER_TO_DB)
            );
          }}
        />
      </SGDialogForm>
    );
  };

  renderDatabaseEditUserComponent = () => {
    const { intl, items, actions, closeSGDialog } = this.props;
    const grant = this.state.editUserPayload && this.state.editUserPayload.grant;
    const name = grant && grant.db_name;
    const userName = grant && grant.dbuser_name;

    return (
      <SGDialogForm
        name={REDUX_FORM.EDIT_DB_USER}
        icon="profile"
        title={intl.formatMessage({ id: 'translate.page.database_user.edit_access_by_user' }, { name })}
        resources={[
          {
            resourceName: API_RESOURCE.MYSQL_DB_GRANT.resourceName,
            methods: ['PUT', 'DELETE']
          }
        ]}
      >
        <EditDbUser
          userData={this.state.editUserPayload}
          dbUsers={items[API_RESOURCE.MYSQL_DB_USER.resourceName]}
          dbGrant={items[API_RESOURCE.MYSQL_DB_GRANT.resourceName]}
          onSubmit={({ grantForEdit, checkboxes }) => {
            const grants = formatCheckboxesData(checkboxes);
            const shouldUpdatePerms = grants.all || grants.perms.length > 0;

            if (shouldUpdatePerms) {
              actions.updateItem(
                {
                  id: grantForEdit.id,
                  db_id: grantForEdit.db_id,
                  dbuser_id: grantForEdit.dbuser_id,
                  grants,
                  _meta: {
                    notification: {
                      type: 'generic',
                      success: {
                        intlKey: 'translate.page.database_access.add.user.success.message',
                        intlValues: { name: userName }
                      },
                      error: {
                        intlKey: 'translate.page.database_access.add.user.error.message',
                        intlValues: { name: userName }
                      }
                    }
                  },
                  _metaFields: {
                    ...API_RESOURCE.MYSQL_DB_GRANT
                  }
                },
                () => closeSGDialog(REDUX_FORM.EDIT_DB_USER)
              );
            } else {
              actions.deleteItem(
                {
                  ...grantForEdit,
                  itemId: grantForEdit.id,
                  _metaFields: { ...API_RESOURCE.MYSQL_DB_GRANT },
                  _meta: {
                    notification: {
                      type: 'generic',
                      success: {
                        intlKey: 'translate.page.database_access.add.user.success.message',
                        intlValues: { name }
                      },
                      error: {
                        intlKey: 'translate.page.database_access.add.user.error.message',
                        intlValues: { name }
                      }
                    }
                  }
                },
                () => closeSGDialog(REDUX_FORM.EDIT_DB_USER)
              );
            }
          }}
        />
      </SGDialogForm>
    );
  };

  renderDeleteConfirmation = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeletePayload;
    const entityName = deletePayload && deletePayload.name;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.database.delete.dialog.title' }, { name: entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderNoUsersDialog = () => {
    const { intl, closeSGDialog } = this.props;

    return (
      <SGDialog
        id={DIALOGS.MYSQL_NO_USERS}
        title={intl.formatMessage({ id: 'translate.page.database.no.users.title' })}
        icon="add-user"
        state="info"
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={DIALOGS.MYSQL_NO_USERS}
              label={intl.formatMessage({ id: 'translate.generic.cancel' })}
            />

            <RouteButton
              color="primary"
              toPage={ROUTES[ToolId.mysqlUser]}
              onClick={() => closeSGDialog(DIALOGS.MYSQL_NO_USERS)}
            >
              {intl.formatMessage({ id: 'translate.page.database.create.button.create.user' })}
            </RouteButton>
          </React.Fragment>
        }
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const name = entity.name;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name,
      _metaFields: { ...API_RESOURCE.MYSQL_DB },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.database_access.delete.success.message',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_access.delete.error.message',
            intlValues: { name }
          }
        }
      }
    };

    const updatePayload: UpdateItemPayload = {
      _metaFields: { ...API_RESOURCE.MYSQL_DB },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.database_access.change.label.success.message',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_access.change.label.error.message',
            intlValues: { name }
          }
        }
      },
      ...entity
    };
    const addPayload = { ...entity };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.MYSQL_DB.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'POST',
            icon: 'add-user',
            label: intl.formatMessage({ id: 'translate.page.database.add.user' }),
            e2eAttr: 'table-action-add-user',
            visibleOnDesktop: true,
            onClick: () => {
              if (this.props.items.dbMysqlUser.length === 0) {
                openSGDialog(DIALOGS.MYSQL_NO_USERS);

                return;
              }

              if (entity.usersWithAccess.length === this.props.items.dbMysqlUser.length) {
                this.openManageUsersDialog({ id, name });

                return;
              }

              this.setState({ addUserPayload: addPayload }, () => openSGDialog(REDUX_FORM.ADD_USER_TO_DB));
            }
          },
          {
            vcsMethod: 'PUT',
            icon: 'edit',
            label: intl.formatMessage({ id: 'translate.page.database.edit' }),
            e2eAttr: 'table-action-edit',
            onClick: () =>
              this.setState({ currentUpdatePayload: updatePayload }, () => openSGDialog(REDUX_FORM.EDIT_DB_LABEL))
          },
          {
            vcsMethod: 'POST',
            icon: 'database',
            label: intl.formatMessage({ id: 'translate.page.database.select.db.dump.action' }),
            e2eAttr: 'table-action-select-db-dump',
            onClick: () =>
              this.setState({ currentSelectDBDumpPayload: entity }, () => openSGDialog(REDUX_FORM.SELECT_DB_DUMP))
          },
          {
            vcsMethod: 'DELETE',
            icon: 'trash',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-delete',
            onClick: () =>
              this.setState(
                {
                  currentDeletePayload: deletePayload
                },
                () => openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };
}

export default indexWithCRUD(
  (store: RootState) => ({
    selectedSite: getCurrentSite(store)
  }),
  { ...sgDialogActions }
)(DbMysqlDbPage, API_RESOURCE.MYSQL_DB, API_RESOURCE.MYSQL_DB_GRANT, API_RESOURCE.MYSQL_DB_USER);
