import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Notice, Text, Textarea, Grid, Link } from '@siteground/styleguide';

import { RootState } from '../reducers';
import PartialLoader from './partial-loader';
import { API_RESOURCE } from '../../core/constants/api';
import { updateItem } from '../../core/actions/crud';
import { getNsInfo } from '../../core/selectors/site-meta-api';

type Props = {
  domainId: any;
  noticeTitle: string;
  apiResource?: typeof API_RESOURCE.DOMAIN_ALL;
  updateItem?: typeof updateItem;
  dnsServers?: string[];
  children?: any;
};

const DomainNotConnectedNotice = ({
  apiResource = API_RESOURCE.DOMAIN_ALL,
  domainId,
  noticeTitle,
  updateItem,
  dnsServers,
  children
}: Props) => {
  const intl = useIntl();
  return (
    <Notice type="warning" title={noticeTitle}>
      <PartialLoader resources={[{ resourceName: API_RESOURCE.DOMAIN_ALL.resourceName, methods: ['PUT'] }]}>
        <Grid>
          <Text>{intl.formatMessage({ id: 'translate.page.dashboard.not-pointed-notice.text' })}</Text>
          <Textarea
            readOnly
            label={intl.formatMessage({ id: 'translate.page.dashboard.ns-records.label' })}
            value={dnsServers.join('\n')}
          />
          <Text>
            <FormattedMessage
              id="translate.page.dashboard.not-pointed-notice.please-contact-registrar.text.refresh-link"
              values={{
                refresh: (
                  <Link
                    onClick={() =>
                      updateItem({
                        _metaFields: {
                          ...apiResource
                        },
                        _meta: {
                          notification: {
                            type: 'generic',
                            success: {
                              intlKey: 'translate.notification.internal-check-completed'
                            },
                            error: {
                              intlKey: 'translate.failed-to-complete-internal-check'
                            }
                          }
                        },
                        id: domainId,
                        sync_sg_flags: 1
                      })
                    }
                  >
                    <FormattedMessage id="translate.generic.refresh" />
                  </Link>
                )
              }}
            />
          </Text>
          {children}
        </Grid>
      </PartialLoader>
    </Notice>
  );
};

export default connect<{}, any, Props>(
  ({ siteMetaApi }: Partial<RootState>) => ({
    dnsServers: getNsInfo(siteMetaApi)
  }),
  { updateItem }
)(DomainNotConnectedNotice);
