import punycode from 'punycode';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { siteApi } from '../../api/site';
import * as Actions from '../../constants/actions';
import { canDoCRUD } from '../../utils/can-do-crud';
import handleAvalonApiRequest from '../handle-avalon-api-request';

function* fetchItemSaga(action: FetchItemAction) {
  const state = yield select();
  const { resourceName, resourceNameMetaApi, endpoint, itemId, urlParams = {} } = action.payload;

  const canDoFetch = canDoCRUD({ siteMetaApi: state.siteMetaApi, resourceNameMetaApi, method: 'GET' });

  if (!canDoFetch) {
    return;
  }

  const endpointToCall =
    typeof itemId === 'string'
      ? endpoint + '/' + encodeURIComponent(punycode.toASCII(itemId))
      : endpoint + '/' + encodeURIComponent(itemId);

  const response = yield call(
    siteApi({
      endpoint: endpointToCall,
      method: 'GET',
      body: { _metaResourceName: resourceName },
      state,
      urlParams
    })
  );

  yield put({ type: Actions.FETCH_ITEM_SUCCEEDED, item: response.data, resourceName });

  return response;
}

function* fetchItem(): any {
  yield takeEvery(Actions.FETCH_ITEM_REQUESTED, handleAvalonApiRequest(fetchItemSaga));
}

export default fetchItem;
