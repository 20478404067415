import * as React from 'react';
import { useIntl } from 'react-intl';
import { DIALOGS } from '../../../core/constants/common';

import { SGDialog, SGDialogCancel } from '../../components/sg-dialog';

export const QuotaReached = () => {
  const intl = useIntl();

  return (
    <SGDialog
      id={DIALOGS.SG_SITESCANNER_QUOTA_REACHED}
      state="warning"
      icon="exclamation"
      size="large"
      title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.quota-reached.title' })}
      subTitle={intl.formatMessage({ id: 'translate.page.sg-sitescanner.quota-reached.subtitle' })}
      footer={
        <SGDialogCancel
          id={DIALOGS.SG_SITESCANNER_QUOTA_REACHED}
          label={intl.formatMessage({ id: 'translate.generic.close' })}
        />
      }
    ></SGDialog>
  );
};
