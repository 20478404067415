import { call, put, select, takeLatest } from 'redux-saga/effects';
import 'whatwg-fetch';
import * as httpRequestActions from '../actions/fetch';
import { clientApi } from '../api/client';

import {
  REQUEST_ARTICLE,
  REQUEST_ARTICLE_SUCCESS,
  REQUEST_ARTICLES,
  REQUEST_ARTICLES_SUCCESS,
  REQUEST_SUPPORT_SEARCH,
  REQUEST_SUPPORT_SEARCH_SUCCESS,
  REQUEST_SUPPORT_SUGGESTIONS,
  REQUEST_SUPPORT_SUGGESTIONS_SUCCESS
} from '../constants/actions';
import { getClientToken, getLanguagePrefix, getUserLanguage } from '../selectors/session';

import { handleNemoApiRequest } from './handle-nemo-api-request';
import customRequestTypes from '../constants/custom-request-types';

const supportUrl = {
  en: 'https://en.newsupport.siteground.com/wp-json/siteground-support/v1',
  de: 'https://de.newsupport.siteground.com/wp-json/siteground-support/v1',
  es: 'https://es.newsupport.siteground.com/wp-json/siteground-support/v1',
  it: 'https://it.newsupport.siteground.com/wp-json/siteground-support/v1'
};

enum KbType {
  Post = 'sg_new_kb',
  Tutorial = 'sg_new_tutorial'
}

const UA_DASHBOARD_CONTEXT = '/';
const ST_DASHBOARD_CONTEXT = '/dashboard';

const appendResellerFlag = (requestUrl: string) => (CONFIG.IS_RESELLER ? `${requestUrl}?whitelabel=1` : requestUrl);

const hasDirectDisplayArticle = <T extends { label?: string }>(data: T[]): T => {
  const hyperMatchArticle = data.find((result) => result.label === 'hyper_match');

  if (hyperMatchArticle) {
    return hyperMatchArticle;
  }

  const exactMatchArticles = data.filter((result) => result.label === 'exact');

  if (exactMatchArticles.length === 1) {
    return exactMatchArticles[0];
  }
};

function* requestArticle(action) {
  yield put(httpRequestActions.httpRequestStarted(action));

  const { article } = action.payload;

  let requestUrl;

  const languagePrefix = yield select(getLanguagePrefix);
  const type = (article.api_host && article.api_host.split('/').pop()) || article.type;

  if (type === KbType.Post) {
    requestUrl = `${supportUrl[languagePrefix]}/kb/sg_new_kb`;
  } else {
    requestUrl = `${supportUrl[languagePrefix]}/tutorials/sg_new_tutorial`;
  }

  try {
    const response = yield call(fetch, appendResellerFlag(requestUrl), {
      headers: { Accept: 'application/json' },
      method: 'POST',
      body: JSON.stringify({ id: article.id })
    });

    const json = yield response.json();

    yield put({ type: REQUEST_ARTICLE_SUCCESS, data: json.data.post, aiMatches: action.aiMatches });
    yield put(httpRequestActions.httpRequestSucceeded(action));
  } catch (e) {
    console.error('Failed to load article');
    yield put(httpRequestActions.httpRequestFailed(action));
  }
}

function* getPinnedSaga({ context }) {
  const store = yield select();
  const clientToken = getClientToken(store);
  const lang = getUserLanguage(store);

  const endpoint = CONFIG.IS_RESELLER ? '/support/anonymous/getpinned' : '/support/getpinned';

  /**
   *  SPA-1825
   *  context is shared between UA and ST and '/' exists as UA context
   *  Currently there is no way to use same context and have different
   *  results for each app.
   */
  const bypassedContext = context === UA_DASHBOARD_CONTEXT ? ST_DASHBOARD_CONTEXT : context;

  return yield call(
    clientApi(`${endpoint}?context=${bypassedContext}&_client_token=${clientToken}&lang=${lang}`, 'GET')
  );
}

function* requestArticles(action) {
  const store = yield select();

  const response = yield call(handleNemoApiRequest(getPinnedSaga, action), { context: action.payload.context });

  if (response) {
    yield put({ type: REQUEST_ARTICLES_SUCCESS, articles: response.data });
  }
}

function* aisuggestSaga({ payload }) {
  const { session } = yield select();

  return yield call(clientApi(`/support/aisuggest?_client_token=${session.clientToken}`, 'POST', payload));
}

function* requestSuggestions(action) {
  const store = yield select();

  const response = yield call(handleNemoApiRequest(aisuggestSaga, action), { payload: action.payload });

  if (response) {
    yield put({ type: REQUEST_SUPPORT_SUGGESTIONS_SUCCESS, suggestions: response.data });
  }
}

function* aimatchesSaga({ payload }) {
  const { session } = yield select();

  return yield call(clientApi(`/support/aimatches?_client_token=${session.clientToken}`, 'POST', payload));
}

function* requestSearch(action) {
  const body = {
    ...action,
    payload: {
      ...action.payload,
      is_from_sitetools: 1
    }
  };

  const response = yield call(handleNemoApiRequest(aimatchesSaga, body), { payload: body.payload });

  if (response) {
    const directDisplayArticle = hasDirectDisplayArticle(response.data);

    if (directDisplayArticle) {
      return yield put({
        type: REQUEST_ARTICLE,
        requestTypeName: customRequestTypes.SUPPORT_LOADING,
        payload: { article: directDisplayArticle },
        aiMatches: response.data
      });
    }

    yield put({ type: REQUEST_SUPPORT_SEARCH_SUCCESS, results: response.data });
  }
}

export default function* popup() {
  yield takeLatest(REQUEST_ARTICLE, requestArticle);
  yield takeLatest(REQUEST_ARTICLES, requestArticles);
  yield takeLatest(REQUEST_SUPPORT_SUGGESTIONS, requestSuggestions);
  yield takeLatest(REQUEST_SUPPORT_SEARCH, requestSearch);
}
