import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button, List, ListItem, Title } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { PAGE_NOTIFICATION_SUBTITLE_PROPS, REDUX_FORM } from '../../../../../core/constants/common';
import MobileBreakElement from '../../../../components/break-element';
import CreateBox from '../../../../components/create-box';
import VCS from '../../../../components/visibility-control-service';

type Props = {
  children: any;
  locationUser: any[];
  invokeChangeUsersAccess: Function;
  onCreateUser: Function;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.LOCATION_PROTECT;
const formName = REDUX_FORM.CREATE_ITEM_LOCATION_PROTECT;

const CreateContainer = ({ children, locationUser = [], invokeChangeUsersAccess, onCreateUser }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.protected.url.create.title' })}
        resourceName={resourceName}
        formName={formName}
        renderTutorials={({ DefaultRender, listProps, listItemProps }) => (
          <DefaultRender
            title={intl.formatMessage({ id: 'translate.tips_and_tutorials.protected_urls.title' })}
            text={
              <List {...listProps}>
                <ListItem {...listItemProps}>
                  {intl.formatMessage({ id: 'translate.tips_and_tutorials.protected_urls.list.protect.info' })}
                </ListItem>
                <ListItem {...listItemProps}>
                  {intl.formatMessage({ id: 'translate.tips_and_tutorials.protected_urls.list.access.info' })}
                </ListItem>
              </List>
            }
          />
        )}
        renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
          switch (true) {
            case notification.state === 'error':
              return renderDefaultNotificationTemplate();
            case notification.state === 'success':
              return (
                <div style={{ textAlign: 'center' }}>
                  <Title {...PAGE_NOTIFICATION_SUBTITLE_PROPS}>
                    {intl.formatMessage({ id: 'translate.generic.whats.next' })}
                  </Title>

                  {locationUser.length === 0 && (
                    <Button type="outlined" color="secondary" data-e2e="create-new-user" onClick={onCreateUser}>
                      {intl.formatMessage({ id: 'translate.page.protected.urls.create.user' })}
                    </Button>
                  )}
                  {locationUser.length > 0 && (
                    <Button
                      type="outlined"
                      color="secondary"
                      data-e2e="manage-access"
                      onClick={() => {
                        invokeChangeUsersAccess(notification.responseData);
                        removeNotification();
                      }}
                    >
                      {intl.formatMessage({ id: 'translate.page.protected.user.manage.access' })}
                    </Button>
                  )}

                  <MobileBreakElement />

                  <Button type="outlined" color="secondary" data-e2e="protect-new" onClick={removeNotification}>
                    {intl.formatMessage({ id: 'translate.page.protected.user.protect.new' })}
                  </Button>
                </div>
              );
            default:
              return null;
          }
        }}
        resources={[
          {
            resourceName: API_RESOURCE.DOMAIN.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.LOCATION_PROTECT.resourceName,
            methods: ['POST']
          }
        ]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default CreateContainer;
