import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Column } from '@siteground/styleguide';
import { Field } from 'redux-form';
import FormDropdown from '../../components/form-dropdown';
import FormInput from '../../components/form-input';
import { generateTTLOptions } from './utils';

const DnsSRVField = ({ props }: any) => {
  const { intl, validationUtils, listedDomains } = props;
  const { required, validationWithMetaApi } = validationUtils;

  return (
    <React.Fragment>
      <Column smSpan="6">
        <Field
          name="service"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.service' })}
          validate={[required, validationWithMetaApi]}
          normalize={(value) => value.toLowerCase()}
          component={FormInput}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="proto"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.proto' })}
          options={[
            { label: 'TCP', value: 'tcp' },
            { label: 'UDP', value: 'udp' },
            { label: 'TLS', value: 'tls' }
          ]}
          optionValue="value"
          optionLabel="label"
          validate={[required, validationWithMetaApi]}
          component={FormDropdown}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="prio"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.prio' })}
          validate={[required, validationWithMetaApi]}
          normalize={(value) => value.toLowerCase()}
          component={FormInput}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="weight"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.weight' })}
          validate={[required, validationWithMetaApi]}
          normalize={(value) => value.toLowerCase()}
          component={FormInput}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="port"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.port' })}
          validate={[required, validationWithMetaApi]}
          normalize={(value) => value.toLowerCase()}
          component={FormInput}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="ttl"
          label={intl.formatMessage({ id: 'translate.generic.ttl' })}
          options={generateTTLOptions(props)}
          optionValue="seconds"
          optionLabel="label"
          validate={[required, validationWithMetaApi]}
          component={FormDropdown}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="value"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.dns.field.target.label' })}
          validate={[required, validationWithMetaApi]}
          normalize={(value) => value.toLowerCase()}
          component={FormInput}
        />
      </Column>
    </React.Fragment>
  );
};

export default injectIntl(DnsSRVField);
