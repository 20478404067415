import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Text } from '@siteground/styleguide/';
import { RootState } from '../../reducers';
import * as fileManagerActions from '../core/actions/file-manager';
import { getEntityPath, getEntityReadableName } from '../core/utils';
import { FileManagerProps, FMEntity } from '../types';
import './breadcrumbs.scss';

type Props = {
  selectedNavigationEntity: FMEntity;
  selectedContentEntities: FMEntity[];
  entities: FileManagerProps['entities'];
  actions: {
    onEntityClick: typeof fileManagerActions.onEntityClick;
  };
};

class Breadcrumb extends React.Component<Props> {
  getBreadcrumbPath() {
    const { selectedNavigationEntity, selectedContentEntities } = this.props;

    if (!selectedNavigationEntity) {
      return null;
    }

    const entityToUse = selectedContentEntities.length === 1 ? selectedContentEntities[0] : selectedNavigationEntity;
    return getEntityPath(entityToUse);
  }

  getBreadcrumbEntities() {
    const { entities } = this.props;
    const breadcrumbPath = this.getBreadcrumbPath();

    if (!breadcrumbPath) {
      return [];
    }

    return breadcrumbPath
      .split('/')
      .map((entityName, index, breadcrumbArray) => {
        const entityParentPath = breadcrumbArray.slice(0, index).join('/') || '/';
        const entityParentItems: any = entities[entityParentPath] || [];
        const breadcrumbEntityPath = `${entityParentPath}/${entityName}`.replace('//', '/');

        return entityParentItems.find((entity) => getEntityPath(entity) === breadcrumbEntityPath);
      })
      .filter(Boolean);
  }

  render() {
    const breadcrumbEntities = this.getBreadcrumbEntities();

    return (
      <ul className="breadcrumbs" aria-label="Breadcrumb">
        {breadcrumbEntities.map((entity, index, array) => (
          <li className="breadcrumb" key={index} data-e2e="breadcrumb-entity-item">
            <Text
              aria-role="button"
              color="light"
              size="small"
              weight="bold"
              truncate
              aria-current={index === array.length - 1 ? 'page' : null}
              onClick={() => this.props.actions.onEntityClick({ entity })}
            >
              {getEntityReadableName(entity)}
            </Text>

            {index !== array.length - 1 && (
              <Icon
                aria-hidden
                name="arrow-right"
                size="10"
                className="breadcrumb__icon"
                onClick={() => this.props.actions.onEntityClick({ entity })}
              />
            )}
          </li>
        ))}
      </ul>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  entities: state.fileManager.entities,
  selectedContentEntities: state.fileManager.selectedContentEntities,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Breadcrumb);
