import { call, select, takeLatest } from 'redux-saga/effects';
import { siteApi } from '../../api/site';
import handleAvalonApiRequest from '../handle-avalon-api-request';
import { API_RESOURCE } from '../../constants/api';
import { REQUEST_UNKNOWN_FILES } from '../../constants/actions';

// TODO remove this saga and use requestData instead

function* requestUnknownFiles({ payload }) {
  const { appId } = payload;
  const state = yield select();

  return yield call(
    siteApi({
      endpoint: `${API_RESOURCE.APP.endpoint}/${appId}`,
      method: 'GET',
      urlParams: {
        list_unknown_files: 1
      },
      state
    })
  );
}

function* wpStaging(): any {
  yield takeLatest(REQUEST_UNKNOWN_FILES, handleAvalonApiRequest(requestUnknownFiles));
}

export default wpStaging;
