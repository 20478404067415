import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Flex, Section, Text } from '@siteground/styleguide';
import { FirstLevelTitle } from '../../components/titles';
import FileManager from '../../file-manager';
import { shouldRenderMobile } from '../../file-manager/core/utils';
import { toggleCodeEditor } from '../../file-manager/core/actions/file-manager';
import './file-manager.scss';

type Props = {
  codeEditorIsVisible: boolean;
  environment: any;
  intl: Intl;
  toggleCodeEditor: Function;
};

type DispatchProps = {
  intl: Intl;
};

class FileManagerPage extends React.Component<Props & DispatchProps> {
  render() {
    const { codeEditorIsVisible, environment, intl, toggleCodeEditor } = this.props;

    return (
      <React.Fragment>
        <Section className="file-manager-section-expanded">
          <Flex justify="space-between" align="center">
            <FirstLevelTitle density="none">
              {intl.formatMessage({ id: 'translate.page.translate.file.manager.title' })}
            </FirstLevelTitle>

            {shouldRenderMobile(environment) && (
              <Text
                color="ocean"
                transform="uppercase"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleCodeEditor()}
              >
                {intl.formatMessage({
                  id: codeEditorIsVisible ? 'translate.generic.explorer' : 'translate.generic.editor'
                })}
              </Text>
            )}
          </Flex>
        </Section>

        <Section className="file-manager-section-expanded" style={{ paddingBottom: '0' }}>
          <FileManager />
        </Section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  environment: state.environment,
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible
});

export default connect<{}, any, any>(mapStateToProps, { toggleCodeEditor })(injectIntl(FileManagerPage));
