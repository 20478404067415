import * as React from 'react';
import { Grid, Link, Section, Tab, Tabs } from '@siteground/styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import { RootState } from '../../reducers';
import { getCurrentSite } from '../../../core/selectors';
import { DeleteDialog } from '../../components/dialogs';
import indexWithCRUD from '../../components/indexWithCRUD';
import ListBox from '../../components/list-box';
import PageHeader from '../../components/page-header';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../components/sg-dialog';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../components/visibility-control-service';
import CustomNotification from './create/custom-notification';
import { GenerateCreateBox, GenerateCreateForm } from './create/generate';
import { ImportCreateBox, ImportCreateForm } from './create/import/';
import ReadSSHKey from './read/key';
import { KeyNameForm } from './update';
import UpdateIPAddress from './update/ip-adress';

interface SSHProps {
  actions: CrudActions;
  items: any;
  location: any;
  siteMetaApiFeatures: any;
  selectedSite: { domain: string };
  intl: Intl;
  domainName: string;
  openSGDialog: Function;
  closeSGDialog: Function;
}

export enum SSHKey {
  KeyPrivate = 'key_priv',
  KeyPublic = 'key_pub'
}

class SSHPage extends React.Component<SSHProps, any> {
  readonly state = {
    currentUpdateIPs: null,
    currentUpdateKeyName: null,
    currentPrivateKeyValue: null,
    currentDeleteConformationDialogPayload: null,
    createFromGenerate: true
  };

  componentDidMount() {
    this.props.actions.fetchItem({
      itemId: this.props.domainName,
      ...API_RESOURCE.DOMAIN_ALL
    });
  }

  getSSHCredentialsInfo = () => {
    const { domainName, items, siteMetaApiFeatures, selectedSite } = this.props;
    const currentDomain = items.domainAll && items.domainAll.find((domain) => domain.name === domainName);
    const hostname = currentDomain && currentDomain.config.ssh_host;
    const username = siteMetaApiFeatures && siteMetaApiFeatures.username;

    return {
      username,
      hostname,
      port: '18765'
    };
  };

  renderCreateBoxTabs = () => {
    const { intl } = this.props;
    const { createFromGenerate } = this.state;

    return (
      <Tabs border="light">
        <Tab
          active={createFromGenerate}
          data-e2e="ssh-create-tab-import-generate"
          onClick={(index) => this.setState({ createFromGenerate: true })}
        >
          {intl.formatMessage({ id: 'translate.generic.generate' })}
        </Tab>
        <Tab
          active={!createFromGenerate}
          data-e2e="ssh-create-tab-import-import"
          onClick={(index) => this.setState({ createFromGenerate: false })}
        >
          {intl.formatMessage({ id: 'translate.generic.import' })}
        </Tab>
      </Tabs>
    );
  };

  render() {
    const { intl } = this.props;
    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.page.ssh.comment' }),
        accessor: 'comment'
      },
      {
        header: intl.formatMessage({ id: 'translate.page.ssh.key_type' }),
        accessor: 'key_type',
        mSize: '100px',
        style: {
          width: '200px'
        }
      },
      {
        header: intl.formatMessage({ id: 'translate.page.ssh.from' }),
        accessor: 'from',
        render: this.renderIPsArrayAsLinks
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <div>
        <PageHeader id={ToolId.ssh} instructions={intl.formatMessage({ id: 'translate.page.ssh.info' })} />

        <Section>
          <Grid>
            {this.state.createFromGenerate && (
              <GenerateCreateBox
                credentialsInfo={this.getSSHCredentialsInfo()}
                renderBeforeBoxChildren={this.renderCreateBoxTabs()}
                renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => (
                  <CustomNotification
                    type="generate"
                    defaultTemplate={renderDefaultNotificationTemplate}
                    notification={notification}
                    removeNotification={removeNotification}
                    invokeChangeIpList={this.renderUpdateIPsDialog}
                  />
                )}
              >
                <GenerateCreateForm onSubmit={this.submitCreateSSHFromGenerate} />
              </GenerateCreateBox>
            )}

            {!this.state.createFromGenerate && (
              <ImportCreateBox
                credentialsInfo={this.getSSHCredentialsInfo()}
                renderBeforeBoxChildren={this.renderCreateBoxTabs()}
                renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => (
                  <CustomNotification
                    type="import"
                    defaultTemplate={renderDefaultNotificationTemplate}
                    notification={notification}
                    removeNotification={removeNotification}
                    invokeChangeIpList={this.renderUpdateIPsDialog}
                  />
                )}
              >
                <ImportCreateForm onSubmit={this.submitCreateSSHFromImport} />
              </ImportCreateBox>
            )}

            <VCS resourceName={API_RESOURCE.SSH.resourceNameMetaApi} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.ssh.list.title' })}
                data={this.props.items.ssh}
                columns={columns}
                resources={[{ resourceName: API_RESOURCE.SSH.resourceName, methods: ['GET'] }]}
                noDataMessage="translate.page.ssh.sg-table.no-data.message"
                rowKey="key_id"
              />
            </VCS>
          </Grid>

          {this.renderUpdateIPDialog()}
          {this.renderReadPrivateSSHDialog()}
          {this.renderReadPublicSSHDialog()}
          {this.renderPrivateSSHKeyNotAvailableDialog()}
          {this.renderUpdateKeyNameDialog()}
          {this.renderSSHCredentialsDialog()}
          {this.renderDeleteConfirmationDialog()}
        </Section>
      </div>
    );
  }

  renderUpdateIPsDialog = (entity) => {
    const { intl, openSGDialog } = this.props;

    const updatePayload = {
      _metaFields: {
        ...API_RESOURCE.SSH
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.shh.ips_updated',
            intlValues: { name: entity.comment }
          },
          error: {
            intlKey: 'translate.page.ssh.failed_update_ip_msg',
            intlValues: { name: entity.comment }
          }
        }
      },
      ...entity
    };

    this.setState({ currentUpdateIPs: updatePayload }, () => openSGDialog(REDUX_FORM.SSH_CHANGE_IP));
  };

  openUpdateKeyNameDialog = (entity) => {
    const { intl, openSGDialog } = this.props;
    const updatePayload = {
      _metaFields: {
        ...API_RESOURCE.SSH
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.shh.key_name_updated',
            intlValues: { name: entity.comment }
          },
          error: {
            intlKey: 'translate.page.ssh.failed_update_key_name_msg',
            intlValues: { name: entity.comment }
          }
        }
      },
      ...entity
    };

    this.setState({ currentUpdateKeyName: updatePayload }, () => openSGDialog(REDUX_FORM.SSH_CHANGE_KEY_NAME));
  };

  renderDeleteConfirmationDialog = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.name;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.ssh.delete.dialog.title' }, { entityName })}
        onSubmit={() => {
          this.props.actions.deleteItem(deletePayload, () => {
            this.props.actions.fetchItems(API_RESOURCE.SSH);
          });
        }}
      />
    );
  };

  renderUpdateIPDialog = () => {
    const { intl } = this.props;
    const { currentUpdateIPs } = this.state;
    const initialValuesClone = currentUpdateIPs && {
      ...currentUpdateIPs,
      from: [...currentUpdateIPs.from, '']
    };
    const name = currentUpdateIPs && currentUpdateIPs.comment;

    return (
      <SGDialogForm
        name={REDUX_FORM.SSH_CHANGE_IP}
        icon="location-tree"
        title={intl.formatMessage({ id: 'translate.page.ssh.update.ip.title' }, { name })}
        resources={[{ resourceName: API_RESOURCE.SSH.resourceName, methods: ['PUT'] }]}
      >
        <UpdateIPAddress
          activeItemIndex={0}
          initialValues={initialValuesClone}
          onSubmit={this.modifyUpdateIPItemData}
        />
      </SGDialogForm>
    );
  };

  renderReadPrivateSSHDialog = () => {
    const { intl } = this.props;
    const { currentPrivateKeyValue } = this.state;
    const name = currentPrivateKeyValue && currentPrivateKeyValue.comment;

    return (
      <SGDialog
        id={DIALOGS.SSH_VIEW_PRIVATE_CERT}
        state="warning"
        icon="key"
        size="x-large"
        title={intl.formatMessage({ id: 'translate.page.ssh.view.private.key.title' }, { name })}
        footer={
          <SGDialogCancel
            id={DIALOGS.SSH_VIEW_PRIVATE_CERT}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        }
        resources={[{ resourceName: API_RESOURCE.SSH_KEY.resourceName, methods: ['GET'] }]}
      >
        <ReadSSHKey currentElement={currentPrivateKeyValue} sshKey={SSHKey.KeyPrivate} />
      </SGDialog>
    );
  };

  renderReadPublicSSHDialog = () => {
    const { intl } = this.props;
    const { currentPrivateKeyValue } = this.state;
    const name = currentPrivateKeyValue && currentPrivateKeyValue.comment;

    return (
      <SGDialog
        id={DIALOGS.SSH_VIEW_PUBLIC_CERT}
        state="warning"
        icon="public-key"
        size="x-large"
        title={intl.formatMessage({ id: 'translate.page.ssh.view.public.key.title' }, { name })}
        footer={
          <SGDialogCancel
            id={DIALOGS.SSH_VIEW_PUBLIC_CERT}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        }
        resources={[{ resourceName: API_RESOURCE.SSH_KEY.resourceName, methods: ['GET'] }]}
      >
        <ReadSSHKey currentElement={currentPrivateKeyValue} sshKey={SSHKey.KeyPublic} />
      </SGDialog>
    );
  };

  renderPrivateSSHKeyNotAvailableDialog = () => {
    const { intl } = this.props;

    return (
      <SGDialog
        id={DIALOGS.SSH_PRIVATE_KEY_NOT_AVAILABLE}
        state="warning"
        icon="warning"
        title={intl.formatMessage({ id: 'translate.page.ssh.private.key.not.available.dialog.title' })}
        footer={
          <SGDialogCancel
            id={DIALOGS.SSH_PRIVATE_KEY_NOT_AVAILABLE}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        }
      >
        {intl.formatMessage({ id: 'translate.page.ssh.private.key.not.available.dialog.message' })}
      </SGDialog>
    );
  };

  renderUpdateKeyNameDialog = () => {
    const { actions, intl, closeSGDialog } = this.props;
    const { currentUpdateKeyName } = this.state;
    const name = currentUpdateKeyName && currentUpdateKeyName.comment;

    return (
      <SGDialogForm
        name={REDUX_FORM.SSH_CHANGE_KEY_NAME}
        icon="rename"
        title={intl.formatMessage({ id: 'translate.page.ssh.update.key.name.title' }, { name })}
        resources={[{ resourceName: API_RESOURCE.SSH.resourceName, methods: ['PUT'] }]}
      >
        <KeyNameForm
          initialValues={currentUpdateKeyName}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.SSH_CHANGE_KEY_NAME))}
        />
      </SGDialogForm>
    );
  };

  renderSSHCredentialsDialog = () => {
    const { intl, selectedSite } = this.props;
    const siteName = selectedSite && selectedSite.domain;
    const { hostname, port, username } = this.getSSHCredentialsInfo();

    return (
      <SGDialog
        id={DIALOGS.SSH_SHOW_CREDENTIAL}
        icon="login"
        state="warning"
        title={intl.formatMessage({ id: 'translate.page.ssh.show.credentials.dialog.title' })}
        subTitle={intl.formatMessage({ id: 'translate.page.ssh.show.credentials.dialog.message' }, { site: siteName })}
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={DIALOGS.SSH_SHOW_CREDENTIAL}
              label={intl.formatMessage({ id: 'translate.generic.close' })}
            />
          </React.Fragment>
        }
        resources={[
          {
            resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
            methods: ['GET']
          }
        ]}
      >
        <ListBox
          data={[
            {
              label: intl.formatMessage({ id: 'translate.page.ssh.show.credentials.hostname' }),
              value: hostname
            },
            {
              label: intl.formatMessage({ id: 'translate.page.ssh.show.credentials.username' }),
              value: username
            },
            {
              label: intl.formatMessage({ id: 'translate.page.ssh.show.credentials.password' }),
              value: intl.formatMessage({ id: 'translate.page.ssh.show.credentials.password.value' })
            },
            {
              label: intl.formatMessage({ id: 'translate.page.ssh.show.credentials.port' }),
              value: port
            }
          ]}
        />
      </SGDialog>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name: entity.comment,
      _metaFields: { ...API_RESOURCE.SSH },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.ssh.deleted_msg',
            intlValues: { name: entity.comment }
          },
          error: {
            intlKey: 'translate.page.ssh.failed_delete_msg',
            intlValues: { name: entity.comment }
          }
        }
      }
    };

    const hasPrivateKey = Boolean(entity.key_priv_exist);

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.SSH.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'GET',
            icon: 'login',
            label: intl.formatMessage({ id: 'translate.page.ssh.show.credentials' }),
            onClick: () => openSGDialog(DIALOGS.SSH_SHOW_CREDENTIAL)
          },
          {
            vcsMethod: 'PUT',
            icon: 'location-tree',
            label: intl.formatMessage({ id: 'translate.page.ssh.update.ip' }),
            onClick: this.renderUpdateIPsDialog.bind(this, entity)
          },
          {
            vcsMethod: 'GET',
            icon: 'key',
            label: intl.formatMessage({ id: 'translate.page.ssh.view.private.key' }),
            onClick: () =>
              hasPrivateKey
                ? this.setState({ currentPrivateKeyValue: entity }, () => openSGDialog(DIALOGS.SSH_VIEW_PRIVATE_CERT))
                : openSGDialog(DIALOGS.SSH_PRIVATE_KEY_NOT_AVAILABLE)
          },
          {
            vcsMethod: 'GET',
            icon: 'public-key',
            label: intl.formatMessage({ id: 'translate.page.ssh.view.public.key' }),
            onClick: () =>
              this.setState({ currentPrivateKeyValue: entity }, () => openSGDialog(DIALOGS.SSH_VIEW_PUBLIC_CERT))
          },
          {
            vcsMethod: 'GET',
            icon: 'rename',
            label: intl.formatMessage({ id: 'translate.page.ssh.update.key.name' }),
            onClick: this.openUpdateKeyNameDialog.bind(this, entity)
          },
          {
            vcsMethod: 'DELETE',
            icon: 'trash',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            onClick: () =>
              this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
                openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };

  renderIPsArrayAsLinks = (id, entity) => {
    const { intl } = this.props;
    const count = id.length;
    const updatePayload = {
      _metaFields: {
        ...API_RESOURCE.SSH
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.shh.ips_updated',
            intlValues: { name: entity.comment }
          },
          error: {
            intlKey: 'translate.page.ssh.failed_update_ip_msg',
            intlValues: { name: entity.comment }
          }
        }
      },
      ...entity
    };

    if (count === 0) {
      return intl.formatMessage({ id: 'translate.generic.all' });
    }

    return (
      <Link
        onClick={() =>
          this.setState({ currentUpdateIPs: updatePayload }, () => this.props.openSGDialog(REDUX_FORM.SSH_CHANGE_IP))
        }
      >
        {id.length === 1 ? id[0] : `${count} ${intl.formatMessage({ id: 'translate.generic.ips' })}`}
      </Link>
    );
  };

  modifyUpdateIPItemData = (data) => {
    const modifiedData = {
      ...data,
      from: [...data.from]
    };
    modifiedData.from = modifiedData.from.filter((el) => el.length);

    this.props.actions.updateItem(modifiedData, () => this.props.closeSGDialog(REDUX_FORM.SSH_CHANGE_IP));
  };

  submitCreateSSHFromGenerate = (data) => {
    const modifiedData = {
      ...data,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_SSH_KEY,
          success: {
            intlKey: 'translate.page.ssh.generated_msg',
            intlValues: { name: data.comment }
          },
          error: {
            intlKey: 'translate.page.ssh.failed_generate_msg',
            intlValues: { name: data.comment }
          }
        }
      }
    };

    modifiedData.from = [];
    modifiedData._metaFields.dontChangeItemsState = true;
    this.props.actions.createItem(modifiedData);
  };

  submitCreateSSHFromImport = (data) => {
    const modifiedData = {
      ...data,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_SSH,
          success: {
            intlKey: 'translate.page.ssh.imported_msg',
            intlValues: { name: data.comment }
          },
          error: {
            intlKey: 'translate.page.ssh.failed_import_msg',
            intlValues: { name: data.comment }
          }
        }
      }
    };

    this.props.actions.createItem(modifiedData);
  };
}

const mapStateToProps = (state: RootState) => ({
  selectedSite: getCurrentSite(state),
  domainName: state.sites.currentDomainName,
  siteMetaApiFeatures: state.siteMetaApi.features
});

export default indexWithCRUD(mapStateToProps, { ...sgDialogActions })(SSHPage, API_RESOURCE.SSH);
