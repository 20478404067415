import * as Actions from '../constants/actions';
import { getEntityInfoNumber, getEntityPath, getParentPath } from '../utils';
import { convertPathToFolderArray } from '../utils/convert-path-to-folder-array';
import { FileManagerRootState } from './utils/internal-state-definition';

const SelectionsReducer = {
  [Actions.FILE_MANAGER_SELECT_CONTENT_ROWS]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      // TODO what will be the behavior of the right context menu and context selection ???
      contextContentEntities: action.payload,
      selectedContentEntities: action.payload,
      contextNavigationEntity: null
    };
  },
  [Actions.FILE_MANAGER_MARK_FOR_COPY]: (state, action: any) => {
    return {
      ...state,
      markedForCopy: action.payload
    };
  },
  [Actions.FILE_MANAGER_UPDATE_AFTER_RENAME]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      contextContentEntities: action.payload,
      contextNavigationEntity: null
    };
  },
  [Actions.FILE_MANAGER_UPDATE_AFTER_MOVE]: (state: FileManagerRootState, action: any) => {
    const { newInfoNumber, oldInfoNumber } = action.payload;
    const newState = { ...state };

    newState.codeEditor.files = newState.codeEditor.files.map((file) => {
      if (getEntityInfoNumber(file) === oldInfoNumber) {
        file.i = newInfoNumber;
      }

      return file;
    });

    if (getEntityInfoNumber(newState.selectedNavigationEntity) === oldInfoNumber) {
      newState.selectedNavigationEntity.i = newInfoNumber;
    }

    return newState;
  },
  [Actions.FILE_MANAGER_SELECT_ENTITY_IN_NAVIGATION]: (state: FileManagerRootState, action: any) => {
    const entity = action.payload;
    const newState = {
      ...state,
      selectedNavigationEntity: entity
    };

    const path = getEntityPath(entity);

    if (!path) {
      return state;
    }

    const pathsToBeExpanded = convertPathToFolderArray(path);

    pathsToBeExpanded.forEach((pathToBeExpanded) => {
      const parentPath = getParentPath(pathToBeExpanded);
      const entityToExpand = newState.entities[parentPath].find((ent) => ent._meta.path === pathToBeExpanded);

      if (entityToExpand) {
        entityToExpand._meta.isExpanded = true;
      }
    });

    return newState;
  }
};

export default SelectionsReducer;
