import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';
import * as Constants from '../../core/constants/actions';

type Props = {
  validationUtils: ValidationUtils;
};

const CreateDirForm = ({ validationUtils }: Props) => {
  const intl = useIntl();
  return (
    <Field
      name="id"
      label={intl.formatMessage({ id: 'translate.generic.name' })}
      autoFocus
      validate={[validationUtils.required]}
      component={FormInput}
    />
  );
};

export default withSpanelForm(
  reduxForm({
    form: REDUX_FORM.FILE_MANAGER_CREATE_DIR,
    initialValues: {
      _metaFields: {
        resourceNameMetaApi: 'dir',
        endpoint: Constants.FILE_MANGER_API_DIR
      }
    }
  })(CreateDirForm)
);
