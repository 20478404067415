import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Text } from '@siteground/styleguide';

const RenderSSHTutorials = (props) => {
  const { intl, DefaultRender, textProps, credentialsInfo } = props;
  return (
    <DefaultRender
      title={intl.formatMessage({ id: 'translate.tips_and_tutorials.ssh.title' })}
      text={intl.formatMessage({ id: 'translate.tips_and_tutorials.ssh.content' })}
    >
      <div>
        <Text {...textProps}>
          <Text {...textProps} tag="span" weight="extra-bold">
            {intl.formatMessage({ id: 'translate.page.ssh.show.credentials.hostname' })}:&nbsp;
          </Text>
          {credentialsInfo.hostname}
        </Text>

        <Text {...textProps}>
          <Text {...textProps} tag="span" weight="extra-bold">
            {intl.formatMessage({ id: 'translate.page.ssh.show.credentials.username' })}:&nbsp;
          </Text>
          {credentialsInfo.username}
        </Text>

        <Text {...textProps}>
          <Text {...textProps} tag="span" weight="extra-bold">
            {intl.formatMessage({ id: 'translate.page.ssh.show.credentials.port' })}:&nbsp;
          </Text>
          {credentialsInfo.port}
        </Text>
      </div>
    </DefaultRender>
  );
};

export default injectIntl(RenderSSHTutorials);
