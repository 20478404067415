import { takeEvery } from 'redux-saga/effects';
import * as Actions from '../../../core/constants/actions';
import handleAvalonApiRequest from '../../../core/sagas/handle-avalon-api-request';
import { updateItemSaga } from '../crud/update-item';
import { createItemSaga } from '../crud/create-item';

export default function* updateCDNItem(): any {
  yield takeEvery(Actions.UPDATE_CDN_ITEM_REQUESTED, handleAvalonApiRequest(updateItemSaga));
  yield takeEvery(Actions.CREATE_CDN_ITEM_REQUESTED, handleAvalonApiRequest(createItemSaga));
}
