import { handleActions } from 'redux-actions';
import { ADD_SG_TABLE_ROW_SELECTION, REMOVE_SG_TABLE_ROW_SELECTION } from '../constants/sg-table';

const initialState: any = {
  selectedRows: []
};

export default handleActions<any, any>(
  {
    [ADD_SG_TABLE_ROW_SELECTION]: (state, action: any) => ({
      ...state,
      selectedRows: [].concat(action.payload)
    }),
    [REMOVE_SG_TABLE_ROW_SELECTION]: (state, action: any) => ({
      ...state,
      selectedRows: []
    })
  },
  initialState
);
