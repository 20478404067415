import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ActionTable, cn, SortContext, SortProvider } from '@siteground/styleguide';
import { ReordableActionTable } from '@siteground/styleguide/lib/components/table/reordable';
import { uniqueId } from '@siteground/styleguide/lib/utils/unique-id';
import { removeSGTableRowSelection } from '../../../core/actions/sg-table';
import { getBlockingRequests } from '../../../core/selectors/partial-loader';
import { RootState } from '../../reducers';
import PartialLoader from '../partial-loader';
import { SecondLevelTitle } from '../titles';
import './sg-table.scss';
import { getTranslations } from './translation';

type Props = {
  resources?: LoaderResource[];
  rowResources?: LoaderResource[];
  removeSGTableRowSelection: Function;
  requestedIds: string;
  renderBeforeTableContent?: Function;
  disableMobileArrange?: boolean;
  dealtItemCount?: number;
  onLoadMore?: (nextItemsCount: number) => any;
  [other: string]: any;
};

const dealtItemCount = 20;

class SGTable extends React.Component<Props, any> {
  readonly dealItemCount = this.props.dealtItemCount || dealtItemCount;

  readonly state = {
    itemsCountToRender: this.dealItemCount
  };

  titleId = null;

  constructor(props) {
    super(props);

    this.titleId = uniqueId('sg-table-title-');
  }

  handleMouseOver = () => {
    const { selectedTableRows, removeSGTableRowSelection } = this.props;
    if (document.querySelector('[data-component="table"]:hover') && selectedTableRows.length === 1) {
      removeSGTableRowSelection();
    }
  };

  render() {
    const {
      title,
      data,
      columns,
      intl,
      noDataTitle = 'translate.sg-table.default.title',
      noDataMessage,
      reordable,
      resources = [],
      onLoadMore = () => null,
      getAllLoaded,
      requestedIds,
      backgroundTasks,
      selectedTableRows,
      showSkeleton,
      ...props
    } = this.props;

    const { rowResources, addOffsetOnMobile, ...propsToPropagate } = props;
    const requestedIdsSet = new Set(JSON.parse(requestedIds));

    const commonProps = {
      className: cn('sg-table', addOffsetOnMobile && 'sg-table--mobile-offset'),
      showSkeleton: data === undefined || showSkeleton,
      rowKey: 'id',
      shadow: true,
      showLoadMore: data && data.length > this.state.itemsCountToRender,
      onLoadMore: () => {
        const nextItemsCount = this.state.itemsCountToRender + this.dealItemCount;

        onLoadMore(nextItemsCount);

        this.setState({ itemsCountToRender: nextItemsCount });
      },
      reordable,
      shouldRowLoading: (row) => requestedIdsSet.has(row.id),
      noDataProps: {
        message: noDataMessage && intl.formatMessage({ id: noDataMessage }),
        title: intl.formatMessage({ id: noDataTitle })
      },
      translation: {
        ...getTranslations(intl),
        loadMoreText: intl.formatMessage({ id: 'translate.sg-table.load.more' })
      }
    };

    const TableTag = reordable ? ReordableActionTable : ActionTable;
    const childrenTable = (
      <SortProvider data={data}>
        <SortContext.Consumer>
          {({ sortedData = [] }) => (
            <TableTag
              data-e2e="table"
              columns={columns}
              data={sortedData.slice(0, this.state.itemsCountToRender)}
              {...commonProps}
              {...propsToPropagate}
              selectedRows={selectedTableRows}
              onMouseOver={this.handleMouseOver}
              aria-labelledby={this.titleId}
            />
          )}
        </SortContext.Consumer>
      </SortProvider>
    );

    return (
      <div style={{ width: '100%' }}>
        {title && <SecondLevelTitle id={this.titleId}>{title}</SecondLevelTitle>}
        {resources.length > 0 ? (
          <div style={{ position: 'relative' }}>
            <PartialLoader resources={resources} position="absolute">
              {childrenTable}
            </PartialLoader>
          </div>
        ) : (
          childrenTable
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: RootState, { resources = [], rowResources }: Partial<Props>) => {
  const resourcesToUse =
    rowResources ||
    resources.map((r) => ({
      ...r,
      methods: ['DELETE']
    }));

  const { requested } = getBlockingRequests(store, resourcesToUse);

  return {
    // stringifier in order to use the react props -> render optimization,
    // instead of JSON.stringify compare of all props
    requestedIds: JSON.stringify(Array.from(new Set(requested.map(({ id }) => id)))),
    selectedTableRows: store.table.selectedRows
  };
};

export default connect<any, any, any>(mapStateToProps, { removeSGTableRowSelection })(injectIntl(SGTable));
