import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@siteground/styleguide';

import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';
import { API_RESOURCE } from '../../../../core/constants/api';

import { ChangePhpIniSetting } from '../update';
import { apiToFormValueConversion } from '../utils';

export const RestorePhpVariableDialog = ({ variable, restoreDefaultPhpSetting, closeSGDialog }) => {
  const intl = useIntl();
  return (
    <SGDialog
      id={DIALOGS.PHP_RESTORE_VARIABLE}
      icon="restore"
      state="warning"
      title={intl.formatMessage(
        { id: 'translate.page.phpSettings.confirm_restore' },
        { variableName: variable && variable.id }
      )}
      footer={
        <React.Fragment>
          <SGDialogCancel id={DIALOGS.PHP_RESTORE_VARIABLE} />

          <Button
            data-e2e="dialog-submit"
            color="primary"
            onClick={() => {
              restoreDefaultPhpSetting(variable && variable.id);
              closeSGDialog(DIALOGS.PHP_RESTORE_VARIABLE);
            }}
          >
            {intl.formatMessage({ id: 'translate.generic.confirm' })}
          </Button>
        </React.Fragment>
      }
    >
      {intl.formatMessage({ id: 'translate.page.phpSettings.confirm_restore.description' })}
    </SGDialog>
  );
};

export default RestorePhpVariableDialog as typeof RestorePhpVariableDialog;
