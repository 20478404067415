import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Column, IconButton } from '@siteground/styleguide';
import KEYS from '@siteground/styleguide/lib/utils/keys';
import { openSGDialog } from '../../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import SelectPath from '../../components/dialogs/select-path';
import FormInput from '../../components/form-input';
import { SGForm, SGSubmitButton } from '../../components/sg-form';
import { withSpanelForm } from '../../components/sg-spanel-forms';
import { RootState } from '../../reducers';
import { closeSearchView } from '../core/actions/search-view';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../core/constants/common';
import { getEntityPath, getEntityType, getParentPath } from '../core/utils';

interface StateProps {
  selectedNavigationEntity: any;
}

type DispatchProps = {
  openSGDialog: typeof openSGDialog;
  closeSearchView: typeof closeSearchView;
  intl: Intl;
};

type OwnProps = {
  validationUtils?: ValidationUtils;
  sPanelFormSubmit?: (formName) => Function;
  onSubmit: Function;
  change?: Function;
};

interface Props extends StateProps, DispatchProps, OwnProps {}

const formName = REDUX_FORM.FILE_MANAGER_SEARCH;

const Form = (props: Props) => {
  const { openSGDialog, closeSearchView, change, intl, validationUtils, sPanelFormSubmit, selectedNavigationEntity } =
    props;

  React.useEffect(() => {
    if (!selectedNavigationEntity) {
      return;
    }

    const entityType = getEntityType(selectedNavigationEntity);
    const entityPath = getEntityPath(selectedNavigationEntity);

    if (entityType === FILE_MANAGER_API_RESPONSE_DIR.FILE) {
      change('root', getParentPath(entityPath));
      return;
    }

    change('root', getEntityPath(selectedNavigationEntity));
  }, []);

  const { required, minSearchLength } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)} gridProps={{ border: ['none', 'none', 'small', 'none'] }}>
      <Column smSpan="4" flex align="center">
        <Field
          name="root"
          suffix={
            <Button
              action="button"
              color="dark"
              size="small"
              onClick={() => openSGDialog(DIALOGS.FILE_MANAGER_SELECT_DIRECTORY_TO_SEARCH)}
            >
              {intl.formatMessage({ id: 'translate.generic.browse' })}
            </Button>
          }
          validate={[required]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="4" flex align="center">
        <Field
          autoFocus
          name="search"
          role="search-box"
          validate={[required, minSearchLength]}
          onKeyUp={(event) => {
            if (event.key === KEYS.ENTER) {
              sPanelFormSubmit(formName)();
            }
          }}
          component={FormInput}
        />
      </Column>

      <Column smSpan="2" flex align="center">
        <SGSubmitButton
          formName={formName}
          label={intl.formatMessage({ id: 'translate.file.manager.search.button' })}
        />
      </Column>

      <Column smSpan="2" flex justify="flex-end" align="center">
        <IconButton
          icon="close"
          shape="circle"
          aria-label={intl.formatMessage({ id: 'translate.file.manager.search.close.button.tooltip' })}
          tooltip={intl.formatMessage({ id: 'translate.file.manager.search.close.button.tooltip' })}
          data-e2e="search-view-close"
          onClick={() => closeSearchView()}
        />
      </Column>

      <SelectPath
        id={DIALOGS.FILE_MANAGER_SELECT_DIRECTORY_TO_SEARCH}
        initialPath="/"
        onChange={(path) => change('root', path)}
      />
    </SGForm>
  );
};

const SearchForm = reduxForm({
  form: formName,
  initialValues: {
    root: '/',
    _metaFields: {
      formName,
      disableAutoReset: true
    }
  }
})(Form);

const mapStateToProps = (state: RootState) => ({
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity
});

const mapDispatchToProps = {
  openSGDialog,
  closeSearchView
};

export default connect<StateProps, any, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withSpanelForm(SearchForm)));
