import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { HostingPlanCard } from '@siteground/styleguide/lib/composite/hosting-plan-card/hosting-plan-card';
import { HostingPlanCardProps } from '@siteground/styleguide/lib/composite/hosting-plan-card/types';
import { promoSystemTextParser } from '@siteground/styleguide/lib/utils/promo-system-text-parser/promo-system-text-parser';
import { extractPriceProps } from '../../../core/common/extract-prices';
import { getCurrencyData } from '../../../core/selectors/session';
import { composeFeatures, getVatText } from './utils';

interface PlanCardConnectedProps {
  currencyProps: ConnectedCurrencyProps;
}

interface PlanCardProps extends Pick<HostingPlanCardProps, 'labelProps' | 'iconProps' | 'buttonProps'> {
  plan: PlanDetails;
  intl: Intl;

  [key: string]: any;
}

type Props = PlanCardConnectedProps & PlanCardProps;

const CUSTOM_BILLING_CYCLES = [1, 3];

const PlanCardComponent: React.FunctionComponent<Props> = (props: Props) => {
  const { plan, intl, sharedPlanExpiresLessThan30Days } = props;

  const { price, regular_price, regular_upgrade_price, setup_price, regular_price_text, billing_cycle } = Object.values(
    plan.price
  ).shift();

  const priceBoxContent: PriceBoxContent = {
    promoText: intl.formatMessage({ id: 'translate.upgrade.price' }),
    currentPriceText: intl.formatMessage({ id: 'translate.current.plan' }),
    regularPriceText: promoSystemTextParser({
      currencyData: {
        ...props.currencyProps,
        price,
        regular_price
      },
      text: regular_price_text,
      translations: {
        excl: intl.formatMessage({ id: 'translate.excl' }),
        incl: intl.formatMessage({ id: 'translate.incl' }),
        month: intl.formatMessage({ id: 'translate.month' }),
        months: intl.formatMessage({ id: 'translate.months' }),
        daySuffix: intl.formatMessage({ id: 'translate.days.suffix' }),
        monthSuffix: intl.formatMessage({ id: 'translate.months.suffix' }),
        yearSuffix: intl.formatMessage({ id: 'translate.years.suffix' }),
        free: intl.formatMessage({ id: 'translate.free' })
      }
    }),
    vatText: getVatText({
      showPricesWithVat: false,
      taxName: props.currencyProps.taxName,
      showFullText: false,
      intlProvider: intl
    })
  };

  const priceProps: PriceBoxContent = extractPriceProps(
    {
      plan,
      currencyProps: {
        ...props.currencyProps,
        price,
        regular_price,
        regular_upgrade_price,
        setup_price,
        showPricesWithVat: true,
        ...priceBoxContent,
        periodSuffix: intl.formatMessage({ id: 'translate.generic.months.suffix' })
      },
      planExpiresInLessThan30Days: sharedPlanExpiresLessThan30Days,
      isCustomPeriod: CUSTOM_BILLING_CYCLES.includes(billing_cycle)
    },
    intl
  );

  const buttonProps = plan.status !== 'current' ? props.buttonProps : null;

  return (
    <HostingPlanCard
      title={plan.description}
      subtitle={plan.subtitle}
      priceProps={priceProps}
      iconProps={props.iconProps}
      featureProps={{
        features: composeFeatures(plan.features, intl),
        compareFeatures: plan.compare_features
      }}
      dialogProps={{
        closeButtonText: intl.formatMessage({ id: 'translate.generic.close' })
      }}
      buttonProps={buttonProps}
    />
  );
};

function mapStateToProps(store) {
  return {
    currencyProps: getCurrencyData(store)
  };
}

export const PlanCard: React.ComponentClass<PlanCardProps, any> = connect<PlanCardConnectedProps, Props, PlanCardProps>(
  mapStateToProps,
  null
)(injectIntl(PlanCardComponent));
