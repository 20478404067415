import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Grid, Link, Notice, Text } from '@siteground/styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import { ROUTES } from '../../../core/constants/routes';
import { RootState } from '../../reducers';

type Props = {
  locationSearch: string;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  router: {
    push: Function;
  };
};

const NoWPApplicationNotice = ({ locationSearch, openSGDialog, closeSGDialog, router }: Props) => {
  const intl = useIntl();
  return (
    <Grid padding={['medium', 'none', 'none', 'none']}>
      <Notice
        data-e2e="wordpress-no-app-notice"
        type="info"
        title={intl.formatMessage({ id: 'translate.notice.no.wp.application.title' })}
      >
        <Text>
          <FormattedMessage
            id="translate.notice.no.wp.application.text"
            values={{
              link: (
                <Link onClick={() => router.push(`${ROUTES[ToolId.wpManage]}${locationSearch}`)}>
                  {intl.formatMessage({ id: 'translate.page.wp.manage.title' })}
                </Link>
              )
            }}
          />
        </Text>

        <Button
          type="link"
          color="secondary"
          onClick={() => {
            closeSGDialog(REDUX_FORM.ADD_EXISTING_APP);
            router.push(`${ROUTES[ToolId.wpManage]}${locationSearch}`);
          }}
        >
          {intl.formatMessage({ id: 'translate.notice.no.wp.application.install.new' })}
        </Button>

        <Button
          type="link"
          color="secondary"
          onClick={() => {
            openSGDialog(REDUX_FORM.ADD_EXISTING_APP);
            router.push(`${ROUTES[ToolId.wpManage]}${locationSearch}`);
          }}
        >
          {intl.formatMessage({ id: 'translate.notice.no.wp.application.add.existing' })}
        </Button>
      </Notice>
    </Grid>
  );
};

const mapStateToProps = (store: RootState) => ({
  locationSearch: store.routing.locationBeforeTransitions.search
});

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(withRouter(NoWPApplicationNotice));
