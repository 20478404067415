import * as React from 'react';

import { API_RESOURCE } from '../../../../core/constants/api';
import SGTable from '../../../components/sg-table';
import VCS from '../../../components/visibility-control-service';

export const PhpVersionTable = ({ phpVersionData = [], columns, renderPhpVersionManageNotice }) => {
  return (
    <VCS resourceName={API_RESOURCE.PHP_LOCATION_VERSION.resourceNameMetaApi} hasMethod="GET">
      <SGTable
        shadow={false}
        data={phpVersionData}
        mobileLayout="card-flat"
        columns={columns}
        resources={[
          {
            resourceName: API_RESOURCE.PHP_LOCATION_VERSION.resourceName,
            methods: ['GET', 'PUT', 'POST', 'DELETE']
          },
          { resourceName: API_RESOURCE.PHP_VERSION.resourceName, methods: ['GET', 'DELETE'] },
          {
            resourceName: API_RESOURCE.PHP_LOCATION_INI.resourceName,
            methods: ['PUT', 'POST', 'DELETE']
          },
          {
            resourceName: API_RESOURCE.USERVICE.resourceName,
            methods: ['GET', 'POST', 'DELETE', 'PUT']
          }
        ]}
      />

      {renderPhpVersionManageNotice()}
    </VCS>
  );
};

export default PhpVersionTable;
