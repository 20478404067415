import * as Actions from '../../../core/constants/actions';
import { CreateCDNItem, UpdateCDNItem } from '../../../web/pages/cdn/types';

export const updateCDNItem: UpdateCDNItem = (payload, { onComplete, onFailure, requestTypeName }) => ({
  type: Actions.UPDATE_CDN_ITEM_REQUESTED,
  payload,
  onComplete,
  onFailure,
  requestTypeName
});

export const createCDNItem: CreateCDNItem = (payload, { onComplete, onFailure, requestTypeName }) => ({
  type: Actions.CREATE_CDN_ITEM_REQUESTED,
  payload,
  onComplete,
  onFailure,
  requestTypeName
});
