import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDate, getTime, Switch, Text } from '@siteground/styleguide';
import { updateItem } from '../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../core/constants/api';
import { ZONE_SETTING_ID } from '../constants';
import SettingListItem, { SettingListItemProps } from './setting-list-item';

const { DEVELOPMENT_MODE, WAF, ALWAYS_ONLINE, EMAIL_OBFUSCATION, MINIFY_JS, MINIFY_CSS, MINIFY_HTML, MIRAGE } =
  ZONE_SETTING_ID;

const notificationEnable = {
  [DEVELOPMENT_MODE]: {
    success: 'translate.page.cloudflare.development.mode.enable.success.message',
    error: 'translate.page.cloudflare.development.mode.enable.error.message'
  },
  [WAF]: {
    success: 'translate.page.cloudflare.waf.enable.success.message',
    error: 'translate.page.cloudflare.waf.enable.error.message'
  },
  [ALWAYS_ONLINE]: {
    success: 'translate.page.cloudflare.always.online.enable.success.message',
    error: 'translate.page.cloudflare.always.online.enable.error.message'
  },
  [EMAIL_OBFUSCATION]: {
    success: 'translate.page.cloudflare.email.obfuscation.enable.success.message',
    error: 'translate.page.cloudflare.email.obfuscation.enable.error.message'
  },
  [MINIFY_JS]: {
    success: 'translate.page.cloudflare.minify.js.enable.success.message',
    error: 'translate.page.cloudflare.minify.js.enable.error.message'
  },
  [MINIFY_CSS]: {
    success: 'translate.page.cloudflare.minify.css.enable.success.message',
    error: 'translate.page.cloudflare.minify.css.enable.error.message'
  },
  [MINIFY_HTML]: {
    success: 'translate.page.cloudflare.minify.html.enable.success.message',
    error: 'translate.page.cloudflare.minify.html.enable.error.message'
  },
  [MIRAGE]: {
    success: 'translate.page.cloudflare.mirage.enable.success.message',
    error: 'translate.page.cloudflare.mirage.enable.error.message'
  }
};

const notificationDeactivate = {
  [DEVELOPMENT_MODE]: {
    success: 'translate.page.cloudflare.development.mode.deactivate.success.message',
    error: 'translate.page.cloudflare.development.mode.deactivate.error.message'
  },
  [WAF]: {
    success: 'translate.page.cloudflare.waf.deactivate.success.message',
    error: 'translate.page.cloudflare.waf.deactivate.error.message'
  },
  [ALWAYS_ONLINE]: {
    success: 'translate.page.cloudflare.always.online.deactivate.success.message',
    error: 'translate.page.cloudflare.always.online.deactivate.error.message'
  },
  [EMAIL_OBFUSCATION]: {
    success: 'translate.page.cloudflare.email.obfuscation.deactivate.success.message',
    error: 'translate.page.cloudflare.email.obfuscation.deactivate.error.message'
  },
  [MINIFY_JS]: {
    success: 'translate.page.cloudflare.minify.js.deactivate.success.message',
    error: 'translate.page.cloudflare.minify.js.deactivate.error.message'
  },
  [MINIFY_CSS]: {
    success: 'translate.page.cloudflare.minify.css.deactivate.success.message',
    error: 'translate.page.cloudflare.minify.css.deactivate.error.message'
  },
  [MINIFY_HTML]: {
    success: 'translate.page.cloudflare.minify.html.deactivate.success.message',
    error: 'translate.page.cloudflare.minify.html.deactivate.error.message'
  },
  [MIRAGE]: {
    success: 'translate.page.cloudflare.mirage.deactivate.success.message',
    error: 'translate.page.cloudflare.mirage.deactivate.error.message'
  }
};

const getSwitchMetaFields = ({ id, isChecked }) => {
  const metaFields: any = {
    ...API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS
  };

  const meta = {
    notification: {
      type: 'generic',
      success: {
        intlKey: null,
        intlValues: null
      },
      error: {
        intlKey: null,
        intlValues: null
      }
    }
  };

  if (id) {
    meta.notification.success.intlKey = isChecked ? notificationEnable[id].success : notificationDeactivate[id].success;
    meta.notification.error.intlKey = isChecked ? notificationEnable[id].error : notificationDeactivate[id].error;
  }

  return {
    _meta: meta,
    _metaFields: metaFields
  };
};

type SettingListSwitchProps = SettingListItemProps & { updateItem: Function };

const SettingListSwitch = (props: SettingListSwitchProps) => {
  const { intl, updateItem, setting } = props;
  const isChecked = !!(setting && setting.value === 'on');
  const devModeUntil = setting.time_remaining > 0 && new Date().setSeconds(setting.time_remaining);
  const formattedDate = devModeUntil && `${getDate(devModeUntil)} ${getTime(devModeUntil)}`;

  return (
    <SettingListItem {...props}>
      {devModeUntil && (
        <Text color="light">
          {intl.formatMessage(
            { id: 'translate.page.cloudflare.development.mode.active.until' },
            { date: formattedDate }
          )}
          &nbsp;
        </Text>
      )}

      <Switch
        checked={isChecked}
        data-e2e={setting.id}
        onChange={(event) =>
          updateItem({
            ...setting,
            value: isChecked ? 'off' : 'on',
            ...getSwitchMetaFields({ id: setting.id, isChecked: !isChecked })
          })
        }
      />
    </SettingListItem>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateItem: bindActionCreators(updateItem, dispatch)
});

const mapStateToProps = (state) => ({});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl<any, any>(SettingListSwitch)) as any;
