import {
  REQUEST_ARTICLE,
  REQUEST_ARTICLES,
  REQUEST_SUPPORT_SEARCH,
  REQUEST_SUPPORT_SUGGESTIONS,
  SAVE_SUPPORT_STATE,
  SET_QUERY,
  SUPPORT_HISTORY_BACK,
  SUPPORT_HISTORY_CLEAR
} from '../constants/actions';

import customRequestTypes from '../constants/custom-request-types';

export const setQuery = (query) => ({
  type: SET_QUERY,
  payload: { query }
});

export const requestArticles = (context): NemoRequestAction => ({
  type: REQUEST_ARTICLES,
  requestTypeName: customRequestTypes.SUPPORT_LOADING,
  payload: { context }
});

export const requestArticle = (article) => ({
  type: REQUEST_ARTICLE,
  requestTypeName: customRequestTypes.SUPPORT_LOADING,
  payload: { article }
});

export const requestSuggestions = (queryString): NemoRequestAction => ({
  type: REQUEST_SUPPORT_SUGGESTIONS,
  payload: { queryString }
});

export const requestSearch = (inquiry): NemoRequestAction => ({
  type: REQUEST_SUPPORT_SEARCH,
  requestTypeName: customRequestTypes.SUPPORT_LOADING,
  payload: { inquiry }
});

export const saveSupportState = (popup) => ({
  type: SAVE_SUPPORT_STATE,
  payload: { popup }
});

export const supportHistoryBack = () => ({
  type: SUPPORT_HISTORY_BACK
});

export const supportHistoryClear = () => ({
  type: SUPPORT_HISTORY_CLEAR
});
