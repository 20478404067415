import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery } from 'redux-saga/effects';
import { ROUTES } from '../constants/routes';

import { groups } from '../../web/core/constants/menu-items';

// Works only for site tools, TODO fix to work with selectors from core/selectores/menu-items

const getSubRoutes = () => {
  return groups
    .map((group) => {
      if (!group.items || group.items.length === 0) {
        return null;
      }

      return group.items.map((gr) => gr.subStates);
    })
    .reduce((accumulator: any, currentValue) => {
      return accumulator
        .concat(currentValue)
        .filter(Boolean)
        .reduce((acc, cur) => acc.concat(cur), []);
    }, [])
    .map((route) => ROUTES[route]);
};

export const onRouteChangeSaga = ({ payload }) => {
  if (getSubRoutes().includes(payload.pathname)) {
    return;
  }

  const pageWrapper: HTMLElement = document.querySelector('[data-component="page"]');

  if (pageWrapper) {
    pageWrapper.focus();
  }
};

function* onRouteChange() {
  yield takeEvery(LOCATION_CHANGE, onRouteChangeSaga);
}

export default onRouteChange;
