import { delay } from 'redux-saga';
import { call, put, select, take, takeEvery, takeLatest, race } from 'redux-saga/effects';
import { triggerPageDataFetch } from '../actions/index-with-crud';
import { siteRequestAuthorizationFailed } from '../actions/session';
import { fetchPendingTasksRequested, initialTasksLoaded } from '../actions/tasks';
import { siteApi } from '../api/site';
import * as Actions from '../constants/actions';
import handleAvalonApiRequest from './handle-avalon-api-request';

import {
  isTaskCompleted,
  isTaskMatchingPathname,
  isTaskSuccessful,
  isTaskUnfinished
} from '../selectors/pending-tasks';

import { getCurrentPathname } from '../selectors/routing';

const DELAY_ON_HTTP200 = 5000;
const DELAY_ON_HTTP500 = 10000;
const seenTasks = {};

const TASKS_UPDATED_ACTIONS = [Actions.ADD_TASK, Actions.PENDING_TASK_COMPLETED, Actions.PENDING_TASK_MISSING];

function* fetchPendingTasksSaga(action: any) {
  for (const taskId of action.payload) {
    yield put({ type: Actions.CHECKING_TASK_REQUESTED, taskId });
  }
}

function* checkingTaskSaga(action: any) {
  if (seenTasks[action.taskId]) {
    return;
  }

  while (true) {
    try {
      const lastState = yield select();

      const { data } = yield call(
        siteApi({
          endpoint: `/task/${action.taskId}`,
          method: 'GET',
          state: lastState
        })
      );

      const existingTask = lastState.tasks.find((task) => task.id === action.taskId);

      // isNewTask
      if (!existingTask) {
        yield put({ type: Actions.ADD_TASK, payload: data });
        // task completed
      } else if (existingTask && isTaskCompleted(data)) {
        yield put({ type: Actions.PENDING_TASK_COMPLETED, task: data });
      }

      seenTasks[action.taskId] = 1;

      if (isTaskUnfinished(data)) {
        yield delay(DELAY_ON_HTTP200);
      } else {
        return;
      }
    } catch (e) {
      if (e.status === 401) {
        yield put(siteRequestAuthorizationFailed());

        const { tokenUpdated, logout } = yield race({
          tokenUpdated: take(Actions.SAVE_SITE_TOKEN),
          logout: take(Actions.LOGOUT)
        });

        if (logout) {
          return;
        }
      } else if (e.status >= 500 && e.status < 600) {
        yield delay(DELAY_ON_HTTP500);
      } else {
        yield put({ type: Actions.PENDING_TASK_MISSING, taskId: action.taskId });
        return;
      }
    }
  }
}

function* subscribeForTaskCompletion({ taskId, onComplete, onFailure }) {
  while (true) {
    yield take(TASKS_UPDATED_ACTIONS);

    const { tasks } = yield select();
    const taskData = tasks.find(({ id }) => id === taskId);

    if (taskData && isTaskCompleted(taskData)) {
      return isTaskSuccessful(taskData)
        ? onComplete(taskData.response, taskData)
        : onFailure(taskData.response.message, taskData);
    }

    if (!taskData) {
      console.error('Missing task data...', taskId);
    }
  }
}

function* handleNotifyTaskCompletion({ payload }) {
  const { task } = payload;
  const pathname = yield select(getCurrentPathname);

  if (task && isTaskMatchingPathname(task, pathname)) {
    // trigger fetch for page
    yield put(triggerPageDataFetch());
  }
}

function* handleInitialTasksLoaded() {
  yield delay(1000); // starts polling after a second
  const { tasks } = yield select();
  const tasksIds = tasks.map(({ id }: Task) => id);
  yield put(fetchPendingTasksRequested(tasksIds));
}

function* loadBackgroundTasks() {
  const state = yield select();

  const response = yield call(
    siteApi({
      endpoint: '/task',
      method: 'GET',
      state
    })
  );

  if (response) {
    yield put(initialTasksLoaded(response.data));
  }
}

function* tasks(): any {
  yield takeLatest(Actions.FETCH_PENDING_TASKS_REQUESTED, fetchPendingTasksSaga);
  yield takeEvery(Actions.CHECKING_TASK_REQUESTED, checkingTaskSaga);
  yield takeEvery(Actions.SUBSCRIBE_FOR_TASK_COMPLETION, subscribeForTaskCompletion);

  yield takeLatest(Actions.INITIAL_TASKS_LOADED, handleInitialTasksLoaded);

  yield takeEvery(Actions.HTTP_REQUEST_SUCCEEDED, handleNotifyTaskCompletion);

  yield takeEvery(Actions.LOAD_BACKGROUND_TASKS, handleAvalonApiRequest(loadBackgroundTasks));
}

export default tasks;
