import { RootState } from '../../web/reducers';

export const getStats =
  ({ webstats }: Partial<RootState>) =>
  (dateId: string, domainName: string, statsType: string): any[] => {
    if (!domainName) {
      return [];
    }

    const stats = webstats[domainName] && webstats[domainName][dateId] ? webstats[domainName][dateId] : {};

    return stats[statsType] || [];
  };

export const areStatsMissing =
  ({ webstats }: Partial<RootState>) =>
  (dateId: string, domainName: string) => {
    if (!domainName) {
      return true;
    }

    return Boolean(
      !webstats[domainName] ||
        !webstats[domainName][dateId] ||
        !webstats[domainName][dateId].general ||
        !parseInt(webstats[domainName][dateId].general.total_visits, 10) ||
        webstats[domainName][dateId].no_stats === 1
    );
  };
