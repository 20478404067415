import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Flex, Link, Title } from '@siteground/styleguide';
import { openNewTabAction, OpenNewTabPayload } from '../../../../core/actions/open-new-tab';
import { API_RESOURCE } from '../../../../core/constants/api';
import { PAGE_NOTIFICATION_SUBTITLE_PROPS, REDUX_FORM } from '../../../../core/constants/common';
import MobileBreakElement from '../../../components/break-element';
import CreateBox from '../../../components/create-box';
import { withFormMetadata } from '../../../components/sg-spanel-forms/index';
import VCS from '../../../components/visibility-control-service';

type Props = {
  children: any;
  renderMigrationToken: Function;
  openNewTab?: (payload: OpenNewTabPayload) => any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.WORDPRESS_TRANSFER;
const formName = REDUX_FORM.CREATE_WP_TRANSFER;

const CreateContainer = ({ children, renderMigrationToken, openNewTab }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.wp.transfer.create.title' })}
        resourceName={resourceName}
        formName={formName}
        renderTutorials={({ DefaultRender, textProps }) => (
          <DefaultRender
            title={intl.formatMessage({ id: 'translate.tips_and_tutorials.wordpress_transfer.title' })}
            text={
              <FormattedMessage
                id="translate.tips_and_tutorials.wordpress_transfer.content"
                values={{
                  link: (
                    <Link href="https://wordpress.org/plugins/siteground-migrator/" target="_blank">
                      {intl.formatMessage({ id: 'translate.generic.click.here' })}
                    </Link>
                  )
                }}
              />
            }
          />
        )}
        resources={[
          { resourceName: API_RESOURCE.WORDPRESS_TRANSFER.resourceName, methods: ['POST'] },
          { resourceName: API_RESOURCE.DOMAIN_ALL.resourceName, methods: ['GET'] }
        ]}
        renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
          if (notification.state === 'error') {
            return renderDefaultNotificationTemplate();
          }

          return (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Flex justify="center">{renderMigrationToken(notification.responseData.transfer_token)}</Flex>

              <Title {...PAGE_NOTIFICATION_SUBTITLE_PROPS}>
                {intl.formatMessage({ id: 'translate.generic.whats.next' })}
              </Title>

              <Button
                type="outlined"
                color="secondary"
                data-e2e="control-panel"
                onClick={() => openNewTab({ url: 'https://wordpress.org/plugins/siteground-migrator/' })}
              >
                {intl.formatMessage({ id: 'translate.page.wp.transfer.create.success.download.plugin' })}
              </Button>

              <MobileBreakElement />

              <Button type="outlined" color="secondary" data-e2e="view-site" onClick={() => removeNotification()}>
                {intl.formatMessage({ id: 'translate.page.wp.transfer.create.success.generate.new' })}
              </Button>
            </div>
          );
        }}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openNewTab: (payload) => dispatch(openNewTabAction(payload))
});

export default connect<{}, {}, Props>(undefined, mapDispatchToProps)(withFormMetadata(CreateContainer));
