import { takeLatest, select, put } from 'redux-saga/effects';

import * as Actions from '../constants/actions';
import { clearTokenTTL } from '../utils/set-localstorage-client-token-ttl';
import { redirectToLoginApp, redirectToResellerLogin } from '../utils/redirect-to-login-app';
import * as requestActions from '../actions/fetch';
import { getLanguagePrefix } from '../selectors/session';

function* logoutSaga(action) {
  const { payload } = action;
  const { sessionExpired } = payload;
  const lang = yield select(getLanguagePrefix);
  yield put(requestActions.httpRequestStarted(action));

  // not stopping the loader
  if (CONFIG.IS_RESELLER) {
    redirectToResellerLogin({ sessionExpired, lang });
  } else {
    redirectToLoginApp({ sessionExpired, lang });
  }
}

function* logout(): any {
  yield takeLatest(Actions.LOGOUT, logoutSaga);

  yield takeLatest([Actions.LOGOUT, Actions.CLEAR_SESSION_DATA], function* () {
    clearTokenTTL();
  });
}

export default logout;
