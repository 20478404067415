import * as React from 'react';

import { Flex, getDate, getTime, Text } from '@siteground/styleguide';

const DateWithTime = (props) => {
  const date = props.date * 1000;

  return (
    <Flex>
      <Text weight="bold">{getDate(date)}</Text>
      &nbsp;
      <Text color="light">{getTime(date)}</Text>
    </Flex>
  );
};

export default DateWithTime;
