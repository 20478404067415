import * as React from 'react';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Grid, Column, Text, IconButton, Notice, Link, Input } from '@siteground/styleguide';

import { REDUX_FORM } from '../../../../core/constants/common';
import { PhpModType } from '../../../../core/selectors/php-settings';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import InformationIcon from '../../../components/information-icon';
import FormDropdown from '../../../components/form-dropdown';
import FormFieldWrapper from '../../../components/form-field-wrapper/form-field-wrapper';
import { PhpManagerState, SiteTypes } from '../types';
import { ROUTES } from '../../../../core/constants/routes';
import { ToolId, getPageInfo } from '../../../../core/constants/route-info';

type Domain = {
  id: string;
  name: string;
  main?: number;
};

type Props = {
  phpMod: PhpModType;
  isUltrafastModDisabled: boolean;
  domains: Domain[];
  pageState: PhpManagerState;
  onDomainChange: Function;
  onEditPhpModClick: Function;
  selectedDomainBoxTab: SiteTypes;
  selectedDomainId: number;
  router?: {
    push: Function;
    location: {
      search: string;
    };
  };
};

export const DomainSelectForm = ({
  router,
  phpMod,
  isUltrafastModDisabled,
  domains,
  pageState,
  onDomainChange,
  onEditPhpModClick,
  selectedDomainBoxTab,
  selectedDomainId
}: Props) => {
  const intl = useIntl();

  let modLabel =
    phpMod === 'mod_php'
      ? intl.formatMessage({ id: 'translate.page.php-manager.ultrafast-php-mod.label' })
      : intl.formatMessage({ id: 'translate.page.php-manager.standard-php-mod.label' });

  if (pageState === PhpManagerState.StandardPhpStagingSelected) {
    modLabel = intl.formatMessage({ id: 'translate.page.php-manager.standard-php-mod.label' });
  }

  const shouldShowStagingDomainsNotice = Boolean(
    selectedDomainBoxTab === SiteTypes.StagingSites && domains.length === 0
  );

  if (shouldShowStagingDomainsNotice) {
    const { title } = getPageInfo(ToolId.wpStaging);

    return (
      <Grid sm="12" padding="responsive" gap="responsive">
        <Column smSpan="12">
          <Notice type="warning" background="light" shadow={false}>
            <Text>
              <FormattedMessage
                id="translate.page.php-manager.staging.no-staging-domains-notice.text"
                values={{
                  link: (
                    <Link onClick={() => router.push(`${ROUTES[ToolId.wpStaging]}${router.location.search}`)}>
                      {intl.formatMessage({ id: title })}
                    </Link>
                  )
                }}
              />
            </Text>
          </Notice>
        </Column>
      </Grid>
    );
  }

  const modPhpAndMainDomainSelected = Boolean(pageState === PhpManagerState.ModPhpMainSelected);

  const domainsListed = modPhpAndMainDomainSelected ? domains.filter(({ main }) => Boolean(main)) : domains;

  return (
    <Grid sm="12" padding="responsive" gap="responsive">
      <Column smSpan="6">
        <Input
          readOnly
          value={modLabel}
          label={intl.formatMessage({ id: 'translate.page.php-manager.php-mod-setup.label' })}
          suffix={
            <IconButton
              shape="circle"
              size="medium"
              icon="edit"
              disabled={Boolean(selectedDomainBoxTab === SiteTypes.StagingSites)}
              tooltip={intl.formatMessage({ id: 'translate.page.php-manager.edit-mod-button.label' })}
              data-e2e="php-edit-mod-button"
              onClick={onEditPhpModClick}
            />
          }
        />
      </Column>
      <Column smSpan="6">
        <Field
          name="domain"
          optionValue="id"
          optionLabel="name"
          options={domains}
          label={intl.formatMessage({ id: 'translate.generic.select.domain' })}
          disabled={modPhpAndMainDomainSelected}
          component={FormDropdown}
          onChange={(event, value) => onDomainChange(value)}
          selectedValue={selectedDomainId}
        />
      </Column>

      {modPhpAndMainDomainSelected && (
        <Column smSpan="12">
          <Notice type="instruction" background="light" shadow={false}>
            <Text>{intl.formatMessage({ id: 'translate.page.php-manager.subdomain_mod_php.warning.text' })}</Text>
          </Notice>
        </Column>
      )}

      {selectedDomainBoxTab === SiteTypes.StagingSites && (
        <Column smSpan="12">
          <Notice type="instruction" background="light" shadow={false}>
            <Text>{intl.formatMessage({ id: 'translate.page.php-manager.staging-sites.warning.text' })}</Text>
          </Notice>
        </Column>
      )}
    </Grid>
  );
};

const createFormToExport = (form: string) => {
  return withRouter(withSpanelForm(reduxForm({ form })(DomainSelectForm)));
};

export const DomainSelectLiveSites = createFormToExport(
  REDUX_FORM.PHP_SELECT_DOMAIN_LIVE_SITES
) as typeof DomainSelectForm;

export const DomainSelectStagingSites = createFormToExport(
  REDUX_FORM.PHP_SELECT_DOMAIN_STAGING_SITES
) as typeof DomainSelectForm;
