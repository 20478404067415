import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';

type Props = {
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CHANGE_LOCATION_IP_BLOCK_DIALOG;

const ChangeIPForm = ({ validationUtils }: Props) => {
  const intl = useIntl();
  return (
    <Grid>
      <Field
        name="addr"
        type="text"
        label={intl.formatMessage({ id: 'translate.generic.ip.ip.range' })}
        validate={[validationUtils.required, validationUtils.ipWithExtras]}
        component={FormInput}
      />
    </Grid>
  );
};

export default withSpanelForm(reduxForm({ form: formName })(ChangeIPForm));
