import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Column } from '@siteground/styleguide';
import { injectIntl } from 'react-intl';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import FormInput from '../../../components/form-input';

type Props = {
  validationUtils: ValidationUtils;
  intl: Intl;
};

const formName = REDUX_FORM.REPLICATE_WP_APP_STAGING;

export const Form = ({ validationUtils, intl }: Props) => (
  <Grid>
    <Column smSpan="12">
      <Field
        name="label"
        type="text"
        label={intl.formatMessage({ id: 'translate.generic.name' })}
        component={FormInput}
        validate={[validationUtils.required]}
      />
    </Column>
  </Grid>
);

const ReplicateWpStaging = reduxForm({ form: formName })(Form);

export default withSpanelForm(injectIntl(ReplicateWpStaging));
