export const API_RESOURCE = {
  AUTOINSTALL: {
    endpoint: '/autoinstall',
    resourceName: 'autoinstall',
    resourceNameMetaApi: 'autoinstall'
  },
  BACKUP: {
    endpoint: '/backup',
    resourceName: 'backup',
    resourceNameMetaApi: 'backup'
  },
  BACKUP_RESTORE: {
    endpoint: '/backup-restore',
    resourceName: 'backupRestore',
    resourceNameMetaApi: 'backup-restore'
  },
  BACKUP_RESTORE_DATABASE: {
    endpoint: '/backup-dbs',
    resourceName: 'backupDatabase',
    resourceNameMetaApi: 'backup-dbs'
  },
  BACKUP_RESTORE_EMAIL: {
    endpoint: '/backup-emails',
    resourceName: 'backupEmail',
    resourceNameMetaApi: 'backup-emails'
  },
  BACKUP_RESTORE_FILES: {
    endpoint: '/backup-files',
    resourceName: 'backupFiles',
    resourceNameMetaApi: 'backup-files'
  },
  APP: {
    endpoint: '/app',
    resourceName: 'app',
    resourceNameMetaApi: 'app'
  },
  APP_STAGING: {
    endpoint: '/app-staging',
    resourceName: 'appStaging',
    resourceNameMetaApi: 'app-staging'
  },
  APP_REPO: {
    endpoint: '/app-repo',
    resourceName: 'appRepo',
    resourceNameMetaApi: 'app-repo'
  },
  APP_LATEST: {
    endpoint: '/app-latest',
    resourceName: 'appLatest',
    resourceNameMetaApi: 'app-latest'
  },
  APP_RESTORE_BACKUP: {
    endpoint: '/app-restore_backup',
    resourceName: 'appRestoreBackup',
    resourceNameMetaApi: 'app-restore_backup'
  },
  APP_UPDATE_CORE: {
    endpoint: '/app-update_core',
    resourceName: 'appUpdateCore',
    resourceNameMetaApi: 'app-update_core'
  },
  APP_SETTINGS: {
    endpoint: '/app-settings',
    resourceName: 'appSettings',
    resourceNameMetaApi: 'app-settings'
  },
  CRON: {
    endpoint: '/cron',
    resourceName: 'cron',
    resourceNameMetaApi: 'cron'
  },
  CRON_NOTIFY: {
    endpoint: '/cron-notify',
    resourceName: 'cronNotify',
    resourceNameMetaApi: 'cron-notify'
  },
  CUSTOM_DEPLOY_STAGING: {
    resourceName: 'customDeployStaging'
  },
  DOMAIN_CDN: {
    endpoint: '/domain-cdn',
    resourceName: 'domainCDN',
    resourceNameMetaApi: 'domain-cdn'
  },
  CLOUDFLARE: {
    endpoint: '/cloudflare',
    resourceName: 'cloudflare',
    resourceNameMetaApi: 'cloudflare'
  },
  CLOUDFLARE_ZONE_PENDING: {
    endpoint: '/cloudflare-zone_pending',
    resourceName: 'cloudflareZonePending',
    resourceNameMetaApi: 'cloudflare-zone_pending'
  },
  CLOUDFLARE_ZONE: {
    endpoint: '/cloudflare-zone',
    resourceName: 'cloudflareZone',
    resourceNameMetaApi: 'cloudflare-zone'
  },
  CLOUDFLARE_ZONE_SETTINGS: {
    endpoint: '/cloudflare-zone_settings',
    resourceName: 'cloudflareZoneSettings', // TODO singular
    resourceNameMetaApi: 'cloudflare-zone_settings'
  },
  CLOUDFLARE_ZONE_STATISTICS: {
    endpoint: '/cloudflare-zone',
    resourceName: 'cloudflareZoneStatistic',
    resourceNameMetaApi: 'cloudflare-zone'
  },
  CLOUDFLARE_FIREWALL: {
    endpoint: '/cloudflare-firewall',
    resourceName: 'cloudflareFirewall',
    resourceNameMetaApi: 'cloudflare-firewall'
  },
  DBIMPORT: {
    endpoint: '/dbimport',
    resourceName: 'dbimport',
    resourceNameMetaApi: 'dbimport'
  },
  DOMAIN: {
    endpoint: '/domain',
    resourceName: 'domain',
    resourceNameMetaApi: 'domain'
  },
  DOMAIN_ALL: {
    endpoint: '/domain-all',
    resourceName: 'domainAll',
    resourceNameMetaApi: 'domain-all'
  },
  DOMAIN_CACHE: {
    endpoint: '/domain-cache',
    resourceName: 'domainCache',
    resourceNameMetaApi: 'domain-cache'
  },
  DOMAIN_ALIAS: {
    endpoint: '/domain-alias',
    resourceName: 'domainAlias',
    resourceNameMetaApi: 'domain-alias'
  },
  EMAIL: {
    endpoint: '/email',
    resourceName: 'email',
    resourceNameMetaApi: 'email'
  },
  EMAIL_FOLDER: {
    endpoint: '/email-folder',
    resourceName: 'emailFolder',
    resourceNameMetaApi: 'email-folder'
  },
  EMAIL_FORWARD: {
    endpoint: '/email-forward',
    resourceName: 'emailForward',
    resourceNameMetaApi: 'email-forward'
  },
  EMAIL_AUTORESPONDER: {
    endpoint: '/email-autoresponder',
    resourceName: 'emailAutoresponder',
    resourceNameMetaApi: 'email-autoresponder'
  },
  EMAIL_FILTER: {
    endpoint: '/email-filter',
    resourceName: 'emailFilter',
    resourceNameMetaApi: 'email-filter'
  },
  EMAIL_FILTER_ORDER: {
    endpoint: '/email-filter_order'
  },
  SPAM_EXPERTS_LOGIN: {
    resourceName: 'spamexpertsLogin',
    endpoint: '/spamexperts-login'
  },
  SSL: {
    endpoint: '/ssl',
    resourceName: 'ssl',
    resourceNameMetaApi: 'ssl'
  },
  SSL_LE: {
    endpoint: '/ssl-le',
    resourceName: 'sslLE',
    resourceNameMetaApi: 'ssl-le'
  },
  DKIM: {
    endpoint: '/dkim',
    resourceName: 'dkim',
    resourceNameMetaApi: 'dkim'
  },
  SPF: {
    endpoint: '/spf',
    resourceName: 'spf',
    resourceNameMetaApi: 'spf'
  },
  FTP: {
    endpoint: '/ftp',
    resourceName: 'ftp',
    resourceNameMetaApi: 'ftp'
  },
  LOCATION: {
    endpoint: '/location',
    resourceName: 'location',
    resourceNameMetaApi: 'location'
  },
  LOCATION_USER: {
    endpoint: '/location-user',
    resourceName: 'locationUser',
    resourceNameMetaApi: 'location-user'
  },
  LOCATION_PROTECT: {
    endpoint: '/location-protect',
    resourceName: 'locationProtect',
    resourceNameMetaApi: 'location-protect'
  },
  LOCATION_REDIRECT: {
    endpoint: '/location-redirect',
    resourceName: 'redirect',
    resourceNameMetaApi: 'location-redirect'
  },
  MYSQL_ACCESS: {
    endpoint: '/db-mysql_access',
    resourceName: 'dbMysqlAccess',
    resourceNameMetaApi: 'db-mysql_access'
  },
  MYSQL_DB: {
    endpoint: '/db-mysql_db',
    resourceName: 'dbMysqlDb',
    resourceNameMetaApi: 'db-mysql_db'
  },
  MYSQL_DB_GRANT: {
    endpoint: '/db-mysql_grant',
    resourceName: 'dbMysqlGrant',
    resourceNameMetaApi: 'db-mysql_grant'
  },
  MYSQL_DB_USER: {
    endpoint: '/db-mysql_user',
    resourceName: 'dbMysqlUser',
    resourceNameMetaApi: 'db-mysql_user'
  },
  PGSQL_ACCESS: {
    endpoint: '/db-pgsql_access',
    resourceName: 'dbPgsqlAccess',
    resourceNameMetaApi: 'db-pgsql_access'
  },
  PGSQL_DB: {
    endpoint: '/db-pgsql_db',
    resourceName: 'dbPgsqlDb',
    resourceNameMetaApi: 'db-pgsql_db'
  },
  PGSQL_DB_GRANT: {
    endpoint: '/db-pgsql_grant',
    resourceName: 'dbPgsqlGrant',
    resourceNameMetaApi: 'db-pgsql_grant'
  },
  PGSQL_DB_USER: {
    endpoint: '/db-pgsql_user',
    resourceName: 'dbPgsqlUser',
    resourceNameMetaApi: 'db-pgsql_user'
  },
  PHP_LOCATION_INI: {
    endpoint: '/location-phpini',
    resourceName: 'locationPhpini',
    resourceNameMetaApi: 'location-phpini'
  },
  PHP_LOCATION_INI_SETTINGS: {
    endpoint: '/location-phpini_settings',
    resourceName: 'locationPhpiniSettings',
    resourceNameMetaApi: 'location-phpini_settings'
  },
  PHP_LOCATION_VERSION: {
    endpoint: '/location-phpver',
    resourceName: 'locationPhpver',
    resourceNameMetaApi: 'location-phpver'
  },
  PHP_VARIABLE: {
    endpoint: '/php-variable',
    resourceName: 'phpVariable',
    resourceNameMetaApi: 'php-variable'
  },
  PHP_VERSION: {
    endpoint: '/php-version',
    resourceName: 'phpVersions', // TODO singulare
    resourceNameMetaApi: 'php-version'
  },
  PHP_EXTENSION: {
    endpoint: '/php-extension',
    resourceName: 'phpExtension',
    resourceNameMetaApi: 'php-extension'
  },
  LOCATION_IP_BLOCK: {
    endpoint: '/location-addr',
    resourceName: 'locationIpBlock',
    resourceNameMetaApi: 'location-user'
  },
  RESOURCE_STATS: {
    endpoint: '/webstats',
    resourceName: 'webstats'
  },
  DNS: {
    endpoint: '/dns',
    resourceName: 'dns',
    resourceNameMetaApi: 'dns-a'
  },
  DNS_A: {
    endpoint: '/dns-a',
    resourceName: 'dnsA',
    resourceNameMetaApi: 'dns-a'
  },
  DNS_AAAA: {
    endpoint: '/dns-aaaa',
    resourceName: 'dnsAaaa',
    resourceNameMetaApi: 'dns-aaaa'
  },
  DNS_CNAME: {
    endpoint: '/dns-cname',
    resourceName: 'dnsCname',
    resourceNameMetaApi: 'dns-cname'
  },
  DNS_MX: {
    endpoint: '/dns-mx',
    resourceName: 'dnsMx',
    resourceNameMetaApi: 'dns-mx'
  },
  DNS_SRV: {
    endpoint: '/dns-srv',
    resourceName: 'dnsSrv',
    resourceNameMetaApi: 'dns-srv'
  },
  DNS_TXT: {
    endpoint: '/dns-txt',
    resourceName: 'dnsTxt',
    resourceNameMetaApi: 'dns-txt'
  },
  SSH: {
    endpoint: '/ssh',
    resourceName: 'ssh',
    resourceNameMetaApi: 'ssh'
  },
  WORDPRESS: {
    endpoint: '/wordpress',
    resourceName: 'wordpress',
    resourceNameMetaApi: 'wordpress'
  },
  WORDPRESS_USER: {
    endpoint: '/wordpress-user',
    resourceName: 'wordpressUser',
    resourceNameMetaApi: 'wordpress-user'
  },
  WORDPRESS_TRANSFER: {
    endpoint: '/wordpress-transfer',
    resourceName: 'wordpressTransfer',
    resourceNameMetaApi: 'wordpress-transfer'
  },
  SSH_KEY: {
    endpoint: '/ssh-key',
    resourceName: 'ssh-key',
    resourceNameMetaApi: 'ssh-key'
  },
  ERRORLOGS: {
    endpoint: '/errorlogs',
    resourceName: 'errorlogs',
    resourceNameMetaApi: 'errorlogs'
  },
  ACCESSLOGS: {
    endpoint: '/accesslogs',
    resourceName: 'accesslogs',
    resourceNameMetaApi: 'accesslogs'
  },
  USERVICE: {
    endpoint: '/uservice',
    resourceName: 'uservice',
    resourceNameMetaApi: 'uservice'
  },
  EXTAPP: {
    endpoint: '/extapp',
    resourceName: 'extapp',
    resourceNameMetaApi: 'extapp'
  },
  GOOGLE_WORKSPACE: {
    endpoint: '/gworkspace',
    resourceName: 'gworkspace',
    resourceNameMetaApi: 'gworkspace'
  },
  GEOIP: {
    endpoint: '/location-geoip',
    resourceName: 'location-geoip',
    resourceNameMetaApi: 'location-geoip'
  }
};
