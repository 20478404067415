import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormEmailDropdown from '../../../components/form-email-dropdown';
import FormInput from '../../../components/form-input';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-spanel-forms';

type Props = {
  domainName: string;
  intl: Intl;
  sPanelFormSubmit: (formName) => null;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CREATE_EMAIL_FORWARD;

const ForwardForm = ({ domainName, intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required, validationWithMetaApi, emailsPunycode } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          data-e2e="forward-crate-name"
          name="name"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.emailForward.forward.name' })}
          placeholder={intl.formatMessage({ id: 'translate.page.emailForward.name.placeholder' })}
          suffix={`@${domainName}`}
          normalize={(value) => value.toLowerCase()}
          validate={[required, validationWithMetaApi]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="6">
        <Field
          data-e2e="forward-crate-email_select_visual"
          name="destination"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.emailForward.destination' })}
          placeholder={intl.formatMessage({ id: 'translate.page.emailForward.destination.placeholder' })}
          validate={[required, emailsPunycode]}
          component={FormEmailDropdown}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} />
      </Column>
    </SGForm>
  );
};

const EmailForwardForm = reduxForm({
  form: formName,
  initialValues: {
    is_discard: 0,
    _metaFields: {
      ...API_RESOURCE.EMAIL_FORWARD,
      formName
    }
  }
})(ForwardForm);

export default withSpanelForm(injectIntl(EmailForwardForm));
