import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Container, GetDeviceInformationType, Grid, Section, Tab, Tabs } from '@siteground/styleguide';
import { findMainDomain, getLanguagePrefix } from '../../../core/selectors';
import { isMemcacheEnabled } from '../../../core/selectors/site-meta-api';
import { API_RESOURCE } from '../../../core/constants/api';
import { ToolId } from '../../../core/constants/route-info';
import FeatureNotAvailable from '../../components/dialogs/feature-not-available';
import PageHeader from '../../components/page-header';
import { SecondLevelTitle } from '../../components/titles';
import DomainCachePage from './domain-cache';
import DynamicCachePage from './dynamic-cache';
import MemcachedPage from './memcached';
import { DomainCacheNotice, DynamicCacheNotice, MemCacheNotice } from './notices';

enum CACHE_TAB {
  STATIC_CACHE = 'cacher-static-cache',
  DYNAMIC_CACHE = 'cacher-dynamic-cache',
  MEMCACHED = 'cacher-memcached'
}

type Props = {
  mainDomain: any;
  environment: GetDeviceInformationType;
  memcacheEnabled?: boolean;
  navigateToUA: Function;
  intl: Intl;
  router: any;
  location: {
    search: string;
    pathname: string;
  };
  language: string;
  children: any;
};

type State = {
  activeTab: CACHE_TAB;
};

export class SuperCacher extends React.Component<Props, State> {
  readonly state = {
    activeTab: CACHE_TAB.STATIC_CACHE
  };

  handleTabSwitch = (tab) => {
    if (tab === CACHE_TAB.STATIC_CACHE) {
      return;
    }

    return this.setState({ activeTab: tab });
  };

  renderTabs = () => (
    <React.Fragment>
      <Tabs key="TABS" border="light">
        <Tab
          data-e2e="super-cacher-static"
          active={this.state.activeTab === CACHE_TAB.STATIC_CACHE}
          onClick={() => this.setState({ activeTab: CACHE_TAB.STATIC_CACHE })}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.superCacher.NGINX' })}
        </Tab>

        <Tab
          data-e2e="super-cacher-dynamic"
          active={this.state.activeTab === CACHE_TAB.DYNAMIC_CACHE}
          onClick={() => this.handleTabSwitch(CACHE_TAB.DYNAMIC_CACHE)}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.superCacher.dynamicCache' })}
        </Tab>

        <Tab
          data-e2e="super-cacher-memcached"
          active={this.state.activeTab === CACHE_TAB.MEMCACHED}
          onClick={() => this.handleTabSwitch(CACHE_TAB.MEMCACHED)}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.superCacher.memcached' })}
        </Tab>
      </Tabs>
    </React.Fragment>
  );

  renderTabNotice = () => {
    switch (this.state.activeTab) {
      case CACHE_TAB.STATIC_CACHE:
        return <DomainCacheNotice />;
      case CACHE_TAB.DYNAMIC_CACHE:
        if (!CONFIG.IS_RESELLER) {
          return <DynamicCacheNotice lang={this.props.language} />;
        }
        return null;
      case CACHE_TAB.MEMCACHED:
        return <MemCacheNotice />;
      default:
        return null;
    }
  };

  renderContent = () => {
    switch (this.state.activeTab) {
      case CACHE_TAB.STATIC_CACHE:
        return <DomainCachePage />;
      case CACHE_TAB.DYNAMIC_CACHE:
        return <DynamicCachePage />;
      case CACHE_TAB.MEMCACHED:
        return <MemcachedPage />;
      default:
        return null;
    }
  };

  render() {
    const { intl } = this.props;
    const notice = this.renderTabNotice();

    return (
      <React.Fragment>
        <PageHeader
          id={ToolId.cacher}
          instructions={intl.formatMessage({ id: 'translate.page.superCacher.instructions' })}
        />

        <Section>
          <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.superCacher.tab.title' })}</SecondLevelTitle>

          <Container padding="none">
            {this.renderTabs()}

            {notice && (
              <Grid
                gap="responsive"
                padding={this.props.environment.isPhone ? ['medium', 'medium', 'none', 'medium'] : 'responsive'}
              >
                {notice}
              </Grid>
            )}

            {this.renderContent()}
          </Container>
        </Section>

        <FeatureNotAvailable />
      </React.Fragment>
    );
  }
}

export default connect<any, any, Props>((store) => ({
  language: getLanguagePrefix(store),
  mainDomain: findMainDomain(store, API_RESOURCE.DOMAIN_ALL.resourceName),
  memcacheEnabled: isMemcacheEnabled({ siteMetaApi: store.siteMetaApi }),
  environment: store.environment
}))(injectIntl(SuperCacher));
