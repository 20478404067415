import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

export type Countries = {
  [countryCode: string]: {
    code: string;
    name: string;
    states: any[];
  };
};

export const initialState: Countries = {};

const reducerMap: any = {
  [Actions.GET_COUNTRIES_SUCCEEDED]: (state: Countries, { payload }) => ({ ...payload.countries }),
  [Actions.LOGOUT]: () => initialState
};

export default handleActions(reducerMap, initialState);
