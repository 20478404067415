import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues } from 'redux-form';
import { Button, Grid, Link, Notice } from '@siteground/styleguide';
import * as actions from '../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../core/constants/api';
import SettingList from '../../../components/setting-list';
import SGTable from '../../../components/sg-table';
import PartialLoader from '../../../components/partial-loader';
import { SettingDropdown, SettingListItem, SettingSwitch } from '../components';
import { ZONE_SETTING_ID } from '../constants';
import './settings.scss';

const {
  CACHE_LEVEL,
  DEVELOPMENT_MODE,
  WAF,
  SECURITY_LEVEL,
  ALWAYS_ONLINE,
  EMAIL_OBFUSCATION,
  MINIFY_JS,
  MINIFY_CSS,
  MINIFY_HTML,
  SSL,
  POLISH,
  MIRAGE
} = ZONE_SETTING_ID;

class CloudflareZoneSettings extends React.Component<any, any> {
  getSetting(settingId) {
    const { cloudflareZoneSettings } = this.props;
    return cloudflareZoneSettings.find((setting) => setting.id === settingId);
  }

  componentDidMount() {
    const { selectedZone } = this.props;

    if (!selectedZone) {
      return null;
    }

    this.props.actions.fetchItems({
      ...API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS,
      urlParams: {
        zone_id: selectedZone.id
      }
    });
  }

  render() {
    const { cloudflare, selectedZone, cloudflareZoneSettings, intl, goToUA } = this.props;

    if (!selectedZone || cloudflareZoneSettings.length === 0) {
      return (
        <SGTable
          data={[]}
          shadow={false}
          resources={[
            {
              resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
              methods: ['GET']
            }
          ]}
        />
      );
    }

    return (
      <div style={{ position: 'relative', minHeight: '250px' }}>
        <PartialLoader
          resources={[
            {
              resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
              methods: ['GET']
            }
          ]}
        >
          <SettingList title={intl.formatMessage({ id: 'translate.page.cloudflare.cache.management' })}>
            <SettingDropdown
              setting={this.getSetting(CACHE_LEVEL)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.caching.level' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.caching.level.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: CACHE_LEVEL
                }
              ]}
            />

            <SettingListItem
              setting={{}}
              selectedZone={selectedZone}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.cache.purge' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.cache.purge.info' })}
              resources={[{ resourceName: API_RESOURCE.CLOUDFLARE_ZONE.resourceName, methods: ['PUT'] }]}
            >
              <Button
                color="secondary"
                type="outlined"
                size="small"
                data-e2e="cache-purge"
                onClick={() =>
                  this.props.actions.updateItem({
                    id: cloudflareZoneSettings[0].zone_id,
                    urlParams: {
                      purge_cache: 1
                    },
                    _metaFields: {
                      ...API_RESOURCE.CLOUDFLARE_ZONE,
                      dontChangeItemsState: true
                    },
                    _meta: {
                      notification: {
                        type: 'generic',
                        success: {
                          intlKey: 'translate.page.cloudflare.purge.success.message'
                        },
                        error: {
                          intlKey: 'translate.page.cloudflare.purge.error.message'
                        }
                      }
                    }
                  })
                }
              >
                {intl.formatMessage({ id: 'translate.page.cloudflare.purge' })}
              </Button>
            </SettingListItem>

            <SettingSwitch
              setting={this.getSetting(DEVELOPMENT_MODE)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.development.mode' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.development.mode.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: DEVELOPMENT_MODE
                }
              ]}
            />
          </SettingList>

          <SettingList title={intl.formatMessage({ id: 'translate.page.cloudflare.security.availability' })}>
            <SettingSwitch
              setting={this.getSetting(WAF)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.waf' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.waf.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: WAF
                }
              ]}
            />

            <SettingDropdown
              setting={this.getSetting(SECURITY_LEVEL)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.cloudflare.security.settings' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.cloudflare.security.settings.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: SECURITY_LEVEL
                }
              ]}
            />
            <SettingSwitch
              setting={this.getSetting(ALWAYS_ONLINE)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.always.online' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.always.online.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: ALWAYS_ONLINE
                }
              ]}
            />
            <SettingSwitch
              setting={this.getSetting(EMAIL_OBFUSCATION)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.email.obfuscation.parameters' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.email.obfuscation.parameters.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: EMAIL_OBFUSCATION
                }
              ]}
            />
          </SettingList>

          <SettingList title={intl.formatMessage({ id: 'translate.generic.speed' })}>
            <SettingSwitch
              setting={this.getSetting(MINIFY_JS)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.javascript' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.javascript.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: MINIFY_JS
                }
              ]}
            />

            <SettingSwitch
              setting={this.getSetting(MINIFY_CSS)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.css' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.css.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: MINIFY_CSS
                }
              ]}
            />

            <SettingSwitch
              setting={this.getSetting(MINIFY_HTML)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.html' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.html.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: MINIFY_HTML
                }
              ]}
            />
          </SettingList>

          <SettingList title={intl.formatMessage({ id: 'translate.page.cloudflare.ssl' })}>
            <SettingDropdown
              setting={this.getSetting(SSL)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.ssl.support' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.ssl.support.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: SSL
                }
              ]}
            />
          </SettingList>

          <SettingList title={intl.formatMessage({ id: 'translate.page.cloudflare.polish.premium.features' })}>
            <SettingDropdown
              setting={this.getSetting(POLISH)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.polish.image.optimization' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.polish.image.optimization.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: POLISH
                }
              ]}
            />
            <SettingSwitch
              setting={this.getSetting(MIRAGE)}
              selectedZone={selectedZone}
              goToUA={goToUA}
              title={intl.formatMessage({ id: 'translate.page.cloudflare.mirage.image.optimization' })}
              info={intl.formatMessage({ id: 'translate.page.cloudflare.mirage.image.optimization.info' })}
              resources={[
                {
                  resourceName: API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS.resourceName,
                  methods: ['PUT'],
                  id: MIRAGE
                }
              ]}
            />
          </SettingList>

          <Grid className="more-settings-notice" padding="responsive">
            <Notice background="light" type="instruction" shadow={false} border={false}>
              <FormattedMessage
                id="translate.page.cloudflare.more.settings.info.box"
                values={{
                  email: cloudflare[0].email,
                  cloudflareLink: (
                    <Link href="https://www.cloudflare.com/login" target="_blank">
                      <FormattedMessage id="translate.page.cloudflare.info.box.cloudflare.account" />
                    </Link>
                  ),
                  hereLink: (
                    <Link href="https://dash.cloudflare.com/forgot-password" target="_blank">
                      <FormattedMessage id="translate.page.cloudflare.info.box.here" />
                    </Link>
                  )
                }}
              />
            </Notice>
          </Grid>
        </PartialLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cloudflare: state.pageItems.cloudflare,
  cloudflareZoneSettings: state.pageItems.cloudflareZoneSettings || []
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(CloudflareZoneSettings)) as any;
