import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from '@siteground/styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';
import { RootState } from '../../../reducers';
import { SGDialog } from '../../../components/sg-dialog';

const dialogId = DIALOGS.FILE_MANAGER_CONFIRM;

type Props = {
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  intl: Intl;
  dialog: {
    [otherProps: string]: {
      message: string;
      onConfirm: Function;
      title: string;
    };
  };
};

const ConfirmDialog = ({ closeSGDialog, intl, dialog }: Props) => {
  const title = dialog[dialogId] && intl.formatMessage({ id: dialog[dialogId].title });
  const message = dialog[dialogId] && dialog[dialogId].message;
  const onConfirm = dialog[dialogId] && dialog[dialogId].onConfirm;

  return (
    <SGDialog
      id={dialogId}
      icon="warning"
      state="warning"
      title={title}
      footer={
        <React.Fragment>
          <Button key={2} data-e2e="footer-button-cancel" onClick={() => closeSGDialog(dialogId)}>
            {intl.formatMessage({ id: 'translate.generic.cancel' })}
          </Button>
          <Button
            key={1}
            data-e2e="footer-button-confirm"
            onClick={() => {
              onConfirm();
              closeSGDialog(dialogId);
            }}
          >
            {intl.formatMessage({ id: 'translate.generic.confirm' })}
          </Button>
        </React.Fragment>
      }
    >
      {message && intl.formatMessage({ id: message })}
    </SGDialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

const mapStateToProps = (state: RootState) => ({
  dialog: state.dialog
});

export default connect<{}, any, Partial<Props>>(mapStateToProps, mapDispatchToProps)(injectIntl(ConfirmDialog));
