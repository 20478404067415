import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { change, getFormValues } from 'redux-form';
import { Button, Grid, Label, Link, Notice, Section, Tab, Table, Tabs } from '@siteground/styleguide';
import { addSiteManually } from '../../../core/actions/pages/wp-autoupdate';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { ToolId } from '../../../core/constants/route-info';
import { getAllWordpressApps, getLatestWordpressInfo } from '../../../core/selectors';
import indexWithCRUD from '../../components/indexWithCRUD';
import NoWPApplicationNotice from '../../components/no-wordpress-app-notice';
import PageHeader from '../../components/page-header';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../components/sg-dialog';
import { SecondLevelTitle } from '../../components/titles';
import VCS from '../../components/visibility-control-service';
import { CREATE_BOX_DROPDOWN_VALUE_KEY, WP_SITE_STATUS } from './constants';
import { CreateBox, CreateForm } from './create';
import WPAutoupdateLastBackup from './last-backup';
import WPAutoupdateSettings from './settings';
import { AddAppForm } from './update';
import './wp-autoupdate.scss';
import { getTranslations } from '../../components/sg-table/translation';
import { getDomains } from '../../../core/selectors/apps';

interface WPAutoupdatePageProps {
  actions: CrudActions;
  items: any;
  router: {
    push: Function;
  };
  location: {
    search: string;
  };
  intl: Intl;
  wordpressApps: any[];
  wordpressInfo: any;
  createFormValues: any;
  changeCreateFormFieldValue: any;
  addSiteManually: any;
  domain: any;
  hasAppsData: boolean;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
}

const TAB = {
  SETTINGS: 'SETTINGS',
  LAST_BACKUP: 'LAST_BACKUP'
};

class WPAutoupdatePage extends React.Component<WPAutoupdatePageProps, any> {
  readonly state = {
    activeTab: TAB.SETTINGS
  };

  getSelectedSite() {
    const { wordpressApps, createFormValues } = this.props;

    if (!createFormValues) {
      return null;
    }

    return wordpressApps.find(
      (app) => app[CREATE_BOX_DROPDOWN_VALUE_KEY] === createFormValues[CREATE_BOX_DROPDOWN_VALUE_KEY]
    );
  }

  getSiteStatus = (site) => {
    const { wordpressInfo } = this.props;

    if (!site || !wordpressInfo) {
      return null;
    }

    if (site.version === wordpressInfo.version.latest && site.scheduled_for_update === 0) {
      return WP_SITE_STATUS.UP_TO_DATE_AUTOUPDATE_DISABLED;
    }

    if (site.version === wordpressInfo.version.latest) {
      return WP_SITE_STATUS.UP_TO_DATE;
    }

    if (site.skip_version !== null && site.scheduled_for_update === 1) {
      return WP_SITE_STATUS.SKIP_UPDATE;
    }

    if (site.scheduled_for_update === 1) {
      return WP_SITE_STATUS.UPDATE_SCHEDULED;
    }

    if (site.scheduled_for_update === 0) {
      return WP_SITE_STATUS.AUTOUPDATE_DISABLED;
    }

    return null;
  };

  onWPAppChanged = (appId) => {
    const { actions } = this.props;

    actions.fetchItems({
      ...API_RESOURCE.APP_SETTINGS,
      urlParams: {
        app_id: appId
      }
    });
    actions.fetchItem({
      itemId: appId,
      ...API_RESOURCE.APP_LATEST
    });
  };

  onSkipVersion = () => {
    const { actions } = this.props;

    actions.fetchItems({
      ...API_RESOURCE.APP
    });
  };

  renderStatusLabel(site) {
    const { intl } = this.props;

    if (!site) {
      return null;
    }

    const siteStatus = this.getSiteStatus(site);
    let label;
    let labelColor;
    let labelType;

    switch (siteStatus) {
      case WP_SITE_STATUS.UP_TO_DATE_AUTOUPDATE_DISABLED:
      case WP_SITE_STATUS.AUTOUPDATE_DISABLED:
        label = 'translate.page.wp.autoupdate.status.auto.update.disabled';
        labelColor = 'error';
        labelType = 'inactive-outlined';
        break;
      case WP_SITE_STATUS.UP_TO_DATE:
        label = 'translate.page.wp.autoupdate.status.up.to.date';
        labelColor = 'success';
        labelType = 'active-outlined';
        break;
      case WP_SITE_STATUS.UPDATE_SCHEDULED:
        label = 'translate.page.wp.autoupdate.status.update.scheduled';
        labelColor = 'success';
        labelType = 'active-outlined';
        break;
      case WP_SITE_STATUS.SKIP_UPDATE:
        label = 'translate.page.wp.autoupdate.status.last.update.skipped';
        labelType = 'default-outlined';
        break;
      default:
        return null;
    }

    return (
      <Label type={labelType} color={labelColor}>
        {intl.formatMessage({ id: label })}
      </Label>
    );
  }

  renderSelectedSiteLabel() {
    return this.renderStatusLabel(this.getSelectedSite());
  }

  renderAddManualDialog() {
    const { addSiteManually, intl, domain, closeSGDialog } = this.props;

    return (
      <SGDialogForm
        name={REDUX_FORM.WP_AUTOUPDATE_ADD_APP_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.wp.autoupdate.add.manually.title' })}
        icon="new-staging"
        state="active"
        resources={[{ requestTypeName: customRequestTypes.ADD_APP_MANUALLY }]}
      >
        <AddAppForm
          domain={domain}
          initialValues={{
            _metaFields: {
              ...API_RESOURCE.APP
            }
          }}
          onSubmit={(data) => {
            const domainObj = domain.find((d) => d.id === data.domain_id);
            const domainName = domainObj && domainObj.name;
            const modifiedData = {
              ...data,
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.page.wp.autoupdate.add.manually.success.message',
                    intlValues: { domain: domainName }
                  },
                  error: {
                    intlKey: 'translate.page.wp.autoupdate.add.manually.error.message',
                    intlValues: { domain: domainName }
                  }
                }
              }
            };

            addSiteManually(modifiedData, () => closeSGDialog(REDUX_FORM.WP_AUTOUPDATE_ADD_APP_DIALOG));
          }}
        />
      </SGDialogForm>
    );
  }

  renderListDialog() {
    const { intl, changeCreateFormFieldValue, wordpressApps, closeSGDialog } = this.props;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.domain' }),
        accessor: 'app_url'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.status' }),
        accessor: 'id',
        render: (cell, entity) => this.renderStatusLabel(wordpressApps.find((app) => app.id === cell))
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.version' }),
        accessor: 'version'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: (cell, entity) => (
          <Button
            color="secondary"
            type="outlined"
            size="small"
            onClick={() => {
              changeCreateFormFieldValue(entity[CREATE_BOX_DROPDOWN_VALUE_KEY]);
              closeSGDialog(REDUX_FORM.WP_AUTOUPDATE_LIST_DIALOG);
            }}
          >
            {intl.formatMessage({ id: 'translate.generic.manage' })}
          </Button>
        )
      }
    ];

    return (
      <SGDialog
        id={REDUX_FORM.WP_AUTOUPDATE_LIST_DIALOG}
        state="warning"
        icon="new-staging"
        size="x-large"
        density="small"
        title={intl.formatMessage({ id: 'translate.page.wp.autoupdate.list.dialog.title' })}
        footer={<SGDialogCancel id={REDUX_FORM.WP_AUTOUPDATE_LIST_DIALOG} />}
      >
        <Table data={wordpressApps} columns={columns} headerBackground="white" translation={getTranslations(intl)} />
      </SGDialog>
    );
  }

  renderTabs() {
    const { intl } = this.props;
    const { activeTab } = this.state;

    return (
      <Tabs border="light">
        <Tab
          active={activeTab === TAB.SETTINGS}
          data-e2e="settings"
          onClick={() => this.setState({ activeTab: TAB.SETTINGS })}
        >
          {intl.formatMessage({ id: 'translate.page.wp.autoupdate.tab.setting' })}
        </Tab>
        <Tab
          active={activeTab === TAB.LAST_BACKUP}
          data-e2e="last-backup"
          onClick={() => this.setState({ activeTab: TAB.LAST_BACKUP })}
        >
          {intl.formatMessage({ id: 'translate.page.wp.autoupdate.last.backup' })}
        </Tab>
      </Tabs>
    );
  }

  renderTabContent() {
    const { activeTab } = this.state;
    const selectedSite = this.getSelectedSite();

    switch (activeTab) {
      case TAB.SETTINGS:
        return <WPAutoupdateSettings selectedSite={selectedSite} />;
      case TAB.LAST_BACKUP:
        return <WPAutoupdateLastBackup selectedSite={selectedSite} />;
      default:
        return null;
    }
  }

  renderPageContent() {
    const { intl, wordpressApps, wordpressInfo, actions } = this.props;

    return (
      <Grid>
        <CreateBox>
          <CreateForm
            updateItem={actions.updateItem}
            wordpressApps={wordpressApps}
            wordpressInfo={wordpressInfo}
            getSiteStatus={this.getSiteStatus}
            selectedSite={this.getSelectedSite()}
            selectedSiteLabel={this.renderSelectedSiteLabel()}
            onWPAppChanged={this.onWPAppChanged}
            onSkipVersion={this.onSkipVersion}
          />
        </CreateBox>

        <VCS resourceName={API_RESOURCE.APP.resourceNameMetaApi} hasMethod="GET">
          <div>
            <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.wp.autoupdate.list.title' })}</SecondLevelTitle>

            <div className="wp-autoupdate-settings">
              {this.renderTabs()}

              {this.renderTabContent()}

              <Grid padding="responsive">
                <Notice background="light" type="instruction" shadow={false} border={false}>
                  <FormattedMessage
                    id="translate.page.wp.autoupdate.more.settings.info.box"
                    values={{
                      allSiteLink: (
                        <Link onClick={() => this.props.openSGDialog(REDUX_FORM.WP_AUTOUPDATE_LIST_DIALOG)}>
                          <FormattedMessage id="translate.generic.here" />
                        </Link>
                      ),
                      addSiteManually: (
                        <Link onClick={() => this.props.openSGDialog(REDUX_FORM.WP_AUTOUPDATE_ADD_APP_DIALOG)}>
                          <FormattedMessage id="translate.generic.here" />
                        </Link>
                      )
                    }}
                  />
                </Notice>
              </Grid>

              {this.renderAddManualDialog()}
              {this.renderListDialog()}
            </div>
          </div>
        </VCS>
      </Grid>
    );
  }

  render() {
    const { intl, wordpressApps, hasAppsData } = this.props;
    const hasWordpressApps = wordpressApps.length > 0;
    const showPageContent = !hasAppsData || (hasAppsData && hasWordpressApps);

    return (
      <div>
        <PageHeader
          id={ToolId.wpAutoupdate}
          instructions={intl.formatMessage({ id: 'translate.page.wp.autoupdate.info' })}
        />

        <Section>{showPageContent ? this.renderPageContent() : <NoWPApplicationNotice />}</Section>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  createFormValues: getFormValues(REDUX_FORM.CREATE_WP_AUTOUPDATE)(store),
  domain: getDomains(store),
  wordpressApps: getAllWordpressApps(store),
  wordpressInfo: getLatestWordpressInfo(store),
  hasAppsData: Boolean(store.pageItems[API_RESOURCE.APP.resourceName])
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(sgDialogActions, dispatch),
  addSiteManually: bindActionCreators(addSiteManually, dispatch),
  changeCreateFormFieldValue: (value) =>
    dispatch(change(REDUX_FORM.CREATE_WP_AUTOUPDATE, CREATE_BOX_DROPDOWN_VALUE_KEY, value))
});

export default indexWithCRUD(mapStateToProps, mapDispatchToProps)(
  WPAutoupdatePage,
  API_RESOURCE.APP,
  API_RESOURCE.DOMAIN
);
