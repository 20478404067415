import * as React from 'react';
import { useIntl } from 'react-intl';
import { Flex, Spacer } from '@siteground/styleguide';

export const VariablesDesktopFilter = ({ renderFilterButton }) => {
  const intl = useIntl();
  return (
    <Flex margin="medium" padding="none" justify="flex-start" align="center">
      {intl.formatMessage({ id: 'translate.generic.name' })}
      <Spacer size="xx-small" />
      {renderFilterButton()}
    </Flex>
  );
};

export default VariablesDesktopFilter as typeof VariablesDesktopFilter;
