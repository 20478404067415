import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, CardProps, Container, Flex, Grid, Section, Switch, Text } from '@siteground/styleguide';
import { requestNemoData } from '../../../core/actions/request-data';
import { updateUserPreferences } from '../../../core/actions/session';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { getUserEasyReadFontEnabled, getUserTheme } from '../../../core/selectors/preferences';
import indexWithCRUD from '../../components/indexWithCRUD';
import PartialLoader from '../../components/partial-loader/partial-loader';
import FirstLevelTitle from '../../components/titles/first-level-title';
import SecondLevelTitle from '../../components/titles/second-level-title';
import { RootState } from '../../reducers';

type Props = {
  intl: Intl;
  theme: string;
  easyReadFont: number;
  requestNemoData: typeof requestNemoData;
  updateUserPreferences: typeof updateUserPreferences;
};

const THEMES = [
  {
    id: 'light',
    iconName: 'illustration-theme-light',
    title: 'translate.page.appearance.theme.light.title'
  },
  {
    id: 'dark',
    iconName: 'illustration-theme-dark',
    title: 'translate.page.appearance.theme.dark.title'
  },
  {
    id: 'hcl',
    iconName: 'illustration-theme-hcl',
    title: 'translate.page.appearance.theme.hcl.title',
    text: 'translate.page.appearance.theme.hcl.text'
  },
  {
    id: 'hcb',
    iconName: 'illustration-theme-hcb',
    title: 'translate.page.appearance.theme.hcb.title',
    text: 'translate.page.appearance.theme.hcb.text'
  }
];

const getCardOutline: (s) => CardProps['outline'] = (theme) => {
  switch (theme) {
    case 'hcb':
    case 'hcl':
      return 'border';
    default:
      return 'shadow';
  }
};

class Appearance extends React.Component<Props, any> {
  setFontPreferences = (easyReadFont) => {
    this.props.requestNemoData(
      {
        endpoint: '/user/set/preferences',
        method: 'POST',
        body: {
          easy_read_font: Number(easyReadFont)
        },
        requestTypeName: customRequestTypes.SET_USER_PREFERENCES,
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.appearance.update.easy.read.success.text'
          },
          error: {
            intlKey: 'translate.page.appearance.update.easy.read.error.text'
          }
        }
      },
      (data) => {
        this.props.updateUserPreferences(data);
      }
    );
  };

  setThemePreferences = (theme) => {
    const themeConfig = THEMES.find((thm) => thm.id === theme);
    const name = themeConfig && this.props.intl.formatMessage({ id: themeConfig.title });

    this.props.requestNemoData(
      {
        endpoint: '/user/set/preferences',
        method: 'POST',
        body: {
          theme
        },
        requestTypeName: customRequestTypes.SET_USER_PREFERENCES,
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.appearance.update.theme.success.text',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.appearance.update.theme.error.text',
            intlValues: { name }
          }
        }
      },
      (data) => {
        this.props.updateUserPreferences(data);
      }
    );
  };

  renderFontSettings = () => {
    const { intl, easyReadFont } = this.props;

    return (
      <PartialLoader resources={[{ requestTypeName: customRequestTypes.SET_USER_PREFERENCES }]}>
        <div>
          <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.appearance.font.title' })}</SecondLevelTitle>

          <Container padding="responsive">
            <Flex justify="space-between" gutter="medium" padding="none">
              <Text>{intl.formatMessage({ id: 'translate.page.appearance.font.easy.read.text' })}</Text>

              <Switch
                checked={Boolean(easyReadFont)}
                onChange={(event) => this.setFontPreferences(event.target.checked)}
              />
            </Flex>
          </Container>
        </div>
      </PartialLoader>
    );
  };

  renderThemeSettings = () => {
    const { intl, theme } = this.props;

    return (
      <PartialLoader resources={[{ requestTypeName: customRequestTypes.SET_USER_PREFERENCES }]}>
        <div>
          <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.appearance.theme.title' })}</SecondLevelTitle>

          <Grid m="1" lg="2" gap="responsive">
            {THEMES.map(({ id, iconName, title, text }) => {
              const isSelected = id === theme;

              return (
                <Card
                  key={id}
                  divided
                  size="large"
                  selected={isSelected}
                  iconName={iconName}
                  iconMulticolor
                  outline={getCardOutline(theme)}
                  title={title && intl.formatMessage({ id: title })}
                  text={text && intl.formatMessage({ id: text })}
                >
                  <Button
                    color="primary"
                    type={isSelected ? 'default' : 'outlined'}
                    onClick={() => this.setThemePreferences(id)}
                  >
                    {intl.formatMessage({
                      id: isSelected ? 'translate.generic.selected' : 'translate.generic.select'
                    })}
                  </Button>
                </Card>
              );
            })}
          </Grid>
        </div>
      </PartialLoader>
    );
  };

  render() {
    const { intl } = this.props;

    return (
      <Section style={{ position: 'relative' }}>
        <FirstLevelTitle>{intl.formatMessage({ id: 'translate.page.appearance.title' })}</FirstLevelTitle>

        <Grid gap="responsive">
          {this.renderFontSettings()}
          {this.renderThemeSettings()}
        </Grid>
      </Section>
    );
  }
}

export default indexWithCRUD(
  (store: RootState) => ({
    theme: getUserTheme(store),
    easyReadFont: getUserEasyReadFontEnabled(store)
  }),
  {
    requestNemoData,
    updateUserPreferences
  }
)(injectIntl(Appearance));
