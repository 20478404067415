import 'whatwg-fetch';
import { jsonResponse, formatRequestBodyNemo } from './utils';
import { CustomError } from './site';

type clientApi = () => Promise<Response>;

export function clientApi(url: string, method = 'GET', body = {}): clientApi {
  const apiHost = CONFIG.API.HOST;
  const newUrl = apiHost + url;

  // Nemo drops all api calls with extra params
  // if (newUrl.indexOf('?') > -1) {
  //   newUrl +=  '&av=' + process.env.VERSION;
  // } else {
  //   newUrl +=  '?av=' + process.env.VERSION;
  // }

  return () => {
    let args: any = {
      headers: {
        Accept: 'application/json'
      },
      method
    };

    if (method === 'POST' || method === 'PUT') {
      args = {
        ...args,
        body: formatRequestBodyNemo(body)
      };
    }

    return fetch(newUrl, args)
      .catch((error) => {
        throw Error('An error occurred. Please try again later');
      })
      .then((response) => {
        if (!response.ok && response.status !== 422) {
          return response.json().then((res) => {
            throw new CustomError(res);
          });
        }

        return jsonResponse(response);
      })
      .then((json) => json);
  };
}
