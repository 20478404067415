import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Table } from '@siteground/styleguide';

import Entity from '../../content/entity';
import { RootState } from '../../../reducers';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import { uploadFiles } from '../../core/actions/file-manager';
import { DIALOGS } from '../../../../core/constants/common';
import './failed-upload-files.scss';
import { getTranslations } from '../../../components/sg-table/translation';

const ROWS_TO_RENDER = 20;

type Props = {
  failedToUpload?: any[];
  intl: Intl;
  closeSGDialog?: typeof closeSGDialog;
  uploadFiles?: typeof uploadFiles;
};

type State = {
  maxRowsToRender: number;
};

class FailedUploadFilesDialog extends React.Component<Props, State> {
  readonly state = {
    maxRowsToRender: ROWS_TO_RENDER
  };

  render() {
    const { intl, failedToUpload, closeSGDialog, uploadFiles } = this.props;
    const { maxRowsToRender } = this.state;
    const filesCount = failedToUpload.length;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.file.name' }),
        accessor: 'file',
        render: (file, entity) => {
          return <Entity entity={entity} nameTextWeight="bold" showFullPath />;
        }
      }
    ];

    return (
      <SGDialog
        id={DIALOGS.FILE_MANAGER_FAILED_UPLOAD}
        state="inactive"
        density="small"
        size="large"
        title={`${intl.formatMessage({ id: 'translate.file.manager.failed.upload.files.dialog.title' })}`}
        footer={
          <React.Fragment>
            {filesCount > maxRowsToRender && (
              <Button
                data-e2e="footer-button-show-more"
                style={{ marginRight: 'auto' }}
                onClick={() => this.setState({ maxRowsToRender: filesCount })}
              >
                {intl.formatMessage(
                  { id: 'translate.file.manager.failed.upload.files.show.more' },
                  { fileCount: filesCount - ROWS_TO_RENDER }
                )}
              </Button>
            )}

            <SGDialogCancel id={DIALOGS.FILE_MANAGER_FAILED_UPLOAD} />
            <Button
              data-e2e="footer-button-retry-all"
              color="primary"
              onClick={() => {
                uploadFiles(failedToUpload);
                closeSGDialog(DIALOGS.FILE_MANAGER_FAILED_UPLOAD);
              }}
            >
              {intl.formatMessage({ id: 'translate.generic.retry.all' })}
            </Button>
          </React.Fragment>
        }
      >
        <Table
          className="table-flex"
          columns={columns}
          data={failedToUpload.slice(0, maxRowsToRender)}
          density="large"
          translation={getTranslations(intl)}
        />
      </SGDialog>
    );
  }
}

export default connect<any, any, any>(
  (state: RootState) => ({
    failedToUpload: state.fileManager.uploader.failed
  }),
  {
    closeSGDialog,
    uploadFiles
  }
)(injectIntl<any, any>(FailedUploadFilesDialog));
