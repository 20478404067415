import { put, takeEvery, select, fork } from 'redux-saga/effects';
import { chat, initWebsocketApi, EVENTS, onEvent, triggerEvent } from '@siteground/clients-frontend';

import * as Actions from '../constants/actions';
import { getClientToken, getUsername, getuserNames, getTranslationsFileName } from '../selectors';
import getStore from '../store';
import * as sessionActions from '../actions/session';

const watchClientInitialized = () => {
  onEvent(EVENTS.FAILED_TO_AUTHENTICATE_WITH_CLIENT_TOKEN, () => {
    const store = getStore();

    store.dispatch(sessionActions.refreshClientToken());
  });

  // Temporary fix until https://jira.siteground.com/browse/DES-6739
  onEvent(EVENTS.ON_AFTER_CHAT_RENDER, () => {
    const page: any = document.querySelector('[data-component="page"]');

    if (page) {
      page.style.paddingBottom = '90px';
    }
  });

  onEvent(EVENTS.ON_AFTER_CHAT_CLOSE, () => {
    const page: any = document.querySelector('[data-component="page"]');

    if (page) {
      page.style.paddingBottom = '';
    }
  });

  onEvent(EVENTS.CHAT_STARTED, () => {
    chat.create('chat');
  });
};

export function* watchClientTokenUpdate() {
  yield takeEvery([Actions.STORE_SESSION_DATA, Actions.REFRESH_CLIENT_TOKEN_SUCCEEDED], function* () {
    const clientToken = yield select(getClientToken);

    triggerEvent(EVENTS.CLIENT_TOKEN_UPDATED, { clientToken });
  });
}

export default function* initRealtimeCommunication() {
  const clientToken = yield select(getClientToken);
  const username = yield select(getUsername);
  const { firstName, lastName } = yield select(getuserNames);
  const language = yield select(getTranslationsFileName);

  if (!clientToken) {
    return;
  }

  if (CONFIG.IS_RESELLER || CONFIG.IMPULSE_URLS.length === 0) {
    return;
  }

  watchClientInitialized();
  yield fork(watchClientTokenUpdate);

  initWebsocketApi({
    CHAT_API: CONFIG.CHAT_API,
    IMPULSE_URLS: CONFIG.IMPULSE_URLS,
    clientToken,
    username,
    firstName,
    lastName,
    language
  });
}
