import * as React from 'react';

export const LoaderContext = React.createContext({
  parentLoaderVisible: false
});

export const IndexWithCrudPageContext = React.createContext({
  isIndexPage: true
});

export const ToolWrapper = React.createContext({
  toolId: null,
  pageId: null
});
