import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { connect } from 'react-redux';

import { Flex, Icon, Link, ListItem, Text } from '@siteground/styleguide/lib/components';
import { SupportPopup as Support } from '@siteground/styleguide/lib/composite';
import * as Actions from '../../../core/actions/support';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { RootState } from '../../reducers';
import { getBlockingRequests } from '../../../core/selectors/partial-loader';

interface StateProps extends SupportPopupState {
  requests: any;
}

type DispatchProps = {
  requestArticles: Function;
  requestArticle: Function;
  requestSuggestions: Function;
  requestSearch: Function;
  saveSupportState: Function;
  supportHistoryBack: Function;
  supportHistoryClear: Function;
  setQuery: Function;
  intl: Intl;
};

type OwnProps = {
  isReseller?: boolean;
};

type State = {
  isOpen: boolean;
  query: string;
};

class SupportPopup extends React.PureComponent<StateProps & DispatchProps & OwnProps, State> {
  iconRef = React.createRef();

  popupRef = React.createRef() as any;

  state = {
    isOpen: false,
    query: ''
  };

  openPopup = () => {
    this.setState({ isOpen: true });
    this.props.requestArticles(window.location.pathname);
  };

  searchForArticle = (article: string) => {
    this.setState({ query: article });
    this.props.requestSearch(article);
  };

  closePopup = () => {
    this.setState({ isOpen: false });
    this.props.supportHistoryClear();
  };

  getExternalLink = (article) => {
    const address = {
      sg_new_kb: 'support/kb',
      sg_new_tutorial: 'support/tutorials'
    };

    return `${CONFIG.UA_URL}/${address[article.type]}/${article.slug}`;
  };

  componentDidMount() {
    if (this.props.popup) {
      this.setState({ isOpen: true });
    }
  }

  render() {
    const { intl } = this.props;
    const { requested } = this.props.requests;
    const iconRect = this.iconRef.current ? (this.iconRef.current as any).getBoundingClientRect() : null;

    return (
      <React.Fragment>
        <Icon
          name="question-mark"
          size="40"
          onClick={this.openPopup}
          ref={this.iconRef}
          tabIndex={0}
          role="button"
          aria-label={intl.formatMessage({ id: 'translate.support.popup.button.a12y.label' })}
        />

        {this.state.isOpen && (
          <Support
            showDirectDisplayView={this.props.showDirectDisplayView}
            translate={{
              title: intl.formatMessage({ id: 'translate.support.popup.title' }),
              searchPlaceholder: intl.formatMessage({ id: 'translate.support.popup.search.placeholder' }),
              popularArticlesTitle: intl.formatMessage({
                id: this.props.isReseller
                  ? 'translate.support.popup.popular.articles.rseller.title'
                  : 'translate.support.popup.popular.articles.title'
              }),
              relatedArticles: intl.formatMessage({
                id: 'translate.support.popup.popular.related.articles.title'
              }),
              searchResultsRelevant: intl.formatMessage({
                id: 'translate.support.popup.search.results.relevant.title'
              }),
              cPanelLabel: intl.formatMessage({
                id: 'translate.search.cpanel.label'
              }),
              searchResultsMore: intl.formatMessage({ id: 'translate.support.popup.search.results.more.title' }),
              loadingTitle: intl.formatMessage({ id: 'translate.support.popup.loading.title' }),
              loadingSubTitle: intl.formatMessage({ id: 'translate.support.popup.loading.sub.title' }),
              noSearchResultFound: intl.formatMessage({ id: 'translate.support.popup.no.search.results.title' }),
              buttonImgDialog: intl.formatMessage({ id: 'translate.generic.close' }),
              seeMoreSearchResults: intl.formatMessage({ id: 'translate.support.popup.no.search.results.title' }),
              noSearchResultFoundMessage: (
                <FormattedMessage
                  id="translate.support.popup.no.search.results.message"
                  values={{
                    link: (
                      <Link
                        className="sg-link"
                        align="center"
                        href={CONFIG.UA_URL + '/support/tutorials'}
                        target="_blank"
                      >
                        {intl.formatMessage({ id: 'translate.support.popup.no.search.results.message.link' })}
                      </Link>
                    )
                  }}
                />
              )
            }}
            status={this.props.status}
            ref={this.popupRef}
            isReseller={this.props.isReseller}
            isLoading={requested.length > 0}
            article={this.props.article}
            pinned={this.props.pinned}
            popular={this.props.popular}
            searchResults={this.props.searchResults as any}
            suggestions={this.props.suggestions}
            onNavigate={this.props.requestArticle}
            onSearch={this.searchForArticle}
            onSuggest={this.props.requestSuggestions}
            setQuery={this.props.setQuery}
            onClose={this.closePopup}
            onBack={this.props.supportHistoryBack}
            initialState={this.props.popup}
            getExternalArticleLink={this.getExternalLink}
            onUpdate={this.props.saveSupportState}
            query={this.props.inquiry}
            style={{
              top: iconRect.bottom + 10,
              right: window.innerWidth - iconRect.right
            }}
            onMoreSearchResults={
              <ListItem icon="arrow-right-thin" iconSize="16" iconColor="lighter">
                <Link
                  className="sg-link"
                  target="_blank"
                  href={CONFIG.UA_URL + `/support/search?s=${this.state.query}`}
                >
                  {intl.formatMessage({ id: 'translate.support.popup.see-more-search-results' })}
                </Link>
              </ListItem>
            }
            helpLink={
              <Flex shrink="none" justify="center">
                <Link href={CONFIG.UA_URL + '/support/tutorials'}>
                  <Text tag="span" color="ocean" weight="bold">
                    {intl.formatMessage({ id: 'translate.support.popup.help.link' })}
                  </Text>
                </Link>
              </Flex>
            }
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStore = (store: RootState) => ({
  article: store.support.present.article,
  inquiry: store.support.present.inquiry,
  pinned: store.support.present.pinned,
  popular: store.support.present.popular,
  showDirectDisplayView: store.support.present.showDirectDisplayView,
  suggestions: store.support.present.suggestions,
  searchResults: store.support.present.searchResults,
  popup: store.support.present.popup,
  status: store.support.present.status,
  requests: getBlockingRequests(store, [{ requestTypeName: customRequestTypes.SUPPORT_LOADING }])
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStore, Actions)(injectIntl(SupportPopup));
