import * as React from 'react';
import { Dropdown, Filter, Flex, Grid, Notice, Section, Tab, Tabs, Text } from '@siteground/styleguide';
import { copyToClipboard, deepclone, selectNodeTextContent } from '@siteground/styleguide/lib/utils';
import { createNotification } from '../../../core/actions/notifications';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, MAIN_DOMAIN_ID, REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import { RootState } from '../../reducers';
import { reorderArray } from '../../../core/utils/reorder-array';
import { DeleteDialog } from '../../components/dialogs';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import PartialLoader from '../../components/partial-loader';
import { SGDialogForm } from '../../components/sg-dialog';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import SecondLevelTitle from '../../components/titles/second-level-title';
import VCS from '../../components/visibility-control-service';
import { API_RESOURCE_MAP, DNSTypes } from './constants';
import DomainNotConnectedNotice from '../../components/domain-not-connected-notice';
import { fetchItems } from '../../../core/actions/crud';

import {
  CreateBox,
  CreateFormA,
  CreateFormAAAA,
  CreateFormCNAME,
  CreateFormMX,
  CreateFormSRV,
  CreateFormTXT
} from './create';

import { DNS_FORM_BY_TYPE } from './create/box';
import ChangeDNS from './update/dns';
import { isTemporaryDomain } from './utils';
import areEqual from '@siteground/styleguide/lib/utils/are-equal';

type Props = {
  intl: Intl;
  environment: {
    isPhone: boolean;
  };
  router: any;
  location: any;
  actions: any;
  items: any;
  createNotification: typeof createNotification;
  domainName: string;
  dnsServers: string[];
  domainAll: any[];
  openSGDialog: Function;
  closeSGDialog: Function;
  fetchItems: typeof fetchItems;
};

type State = {
  currentUpdatePayload: {
    type: DNSTypes;
  };
  selectedDNSType: DNSTypes;
  selectedRecordTypes: string[];
  selectedDomain: {
    id: number;
    name: string;
  };
  [other: string]: any;
};

const resourceName = 'dns';
const CDN_RECORD = 'CDN Record';

const DNS_FORM_COMPONENT = {
  [DNSTypes.DNS_A]: CreateFormA,
  [DNSTypes.DNS_AAAA]: CreateFormAAAA,
  [DNSTypes.DNS_CNAME]: CreateFormCNAME,
  [DNSTypes.DNS_MX]: CreateFormMX,
  [DNSTypes.DNS_SRV]: CreateFormSRV,
  [DNSTypes.DNS_TXT]: CreateFormTXT
};

class DnsPage extends React.Component<Props, State> {
  readonly state = {
    currentUpdatePayload: null,
    currentDeleteConformationDialogPayload: null,
    selectedDomain: {
      name: null,
      id: 0
    },
    selectedDNSType: DNSTypes.DNS_A,
    selectedRecordTypes: []
  };

  filterParkedDomains(domains = this.props.domainAll) {
    return domains ? domains.filter((d) => d.domain_type !== 'sub_domain') : [];
  }

  filterByType = (filterConfig) => {
    const types = filterConfig.checkbox.children.filter((c) => c.checked).map((c) => c.id);
    this.setState({
      selectedRecordTypes: types
    });
  };

  pickerOptions() {
    const { domainAll } = this.props;
    const domains = this.filterParkedDomains(domainAll);

    return this.arrangeOptionsData(domains);
  }

  arrangeOptionsData(data = []) {
    return data.sort((a, b) => {
      return a.id - b.id;
    });
  }

  UCF = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  handleCopyToClipboard(value, node) {
    copyToClipboard(value, (isSuccessful) => {
      // isSuccessful in Safari with onCopy is always false ...
      selectNodeTextContent(node);
    });
  }

  getDNSName = ({ name, type, proto, service }) => {
    const domainName = this.state.selectedDomain.name;

    if (type === DNSTypes.DNS_SRV) {
      return `_${service}._${proto}.${name}.`;
    }

    if (!name) {
      return `${domainName}.`;
    }

    return name[name.length - 1] === '.' ? name : `${name}.${domainName}.`;
  };

  onDomainChanged = (id) => {
    const pickerOptions = this.pickerOptions();
    const domain = pickerOptions.find((item) => item.id === id);
    if (!domain) {
      return null;
    }

    this.selectDomain(id, domain);
  };

  normalizeCreateFormData = (formData) => {
    const { domainAll } = this.props;
    const { domain_choice, ...body } = formData;
    const selectedDomain = domainAll.find((domain) => domain.id === domain_choice);

    if (!domain_choice || domain_choice === 'other') {
      return body;
    }

    if (selectedDomain.domain_type === 'main_domain' || selectedDomain.domain_type === 'alias') {
      body.name = '';
    } else {
      body.name = selectedDomain.name.split('.')[0];
    }

    return body;
  };

  onCreateFormSubmit = (formData) => {
    const { selectedDNSType } = this.state;
    const { createNotification, domainAll } = this.props;
    const selectedDomain = domainAll.find((domain) => domain.id === formData.domain_id);

    this.props.actions.createItem(
      {
        ...this.normalizeCreateFormData(formData),
        _meta: {
          notification: {
            type: 'form',
            formName: DNS_FORM_BY_TYPE[selectedDNSType],
            success: {
              intlKey: 'translate.page.dns.create.success.message',
              intlValues: { type: selectedDNSType }
            }
          }
        }
      },
      null,
      (data) => {
        if (data?.err_fields?.err_meta?.upstream_err_code === 471) {
          createNotification({
            type: 'form',
            state: 'error',
            formName: DNS_FORM_BY_TYPE[selectedDNSType],
            error: {
              intlKey: 'translate.page.dns.create.error.message.471.cname',
              intlValues: { domain: `${formData.name}.${selectedDomain.name}` }
            }
          });
          return;
        }

        createNotification({
          type: 'form',
          state: 'error',
          formName: DNS_FORM_BY_TYPE[selectedDNSType],
          error: {
            intlKey: 'translate.page.dns.create.error.message',
            intlValues: { type: selectedDNSType }
          }
        });
      }
    );
  };

  selectDomain = (id, domain) => {
    if (id === this.state.selectedDomain.id) {
      return;
    }

    this.setState(
      {
        selectedDomain: {
          name: domain.name,
          id
        }
      },
      () => {
        this.props.fetchItems({
          urlParams: {
            domain_name: domain.name
          },
          ...API_RESOURCE.DNS
        });
      }
    );
  };

  renderUpdateComponent = () => {
    const { actions, closeSGDialog, intl, items } = this.props;
    const { currentUpdatePayload, selectedDomain } = this.state;

    const type = currentUpdatePayload && currentUpdatePayload.type;
    const recordName = currentUpdatePayload && this.getDNSName(currentUpdatePayload);

    if (currentUpdatePayload) {
      currentUpdatePayload.recordName = recordName;
    }

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_DNS_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.dns.update.title' }, { name: recordName })}
        resources={[
          { resourceName: API_RESOURCE.DNS_A.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_AAAA.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_CNAME.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_MX.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_SRV.resourceName, methods: ['PUT'] },
          { resourceName: API_RESOURCE.DNS_TXT.resourceName, methods: ['PUT'] }
        ]}
      >
        <ChangeDNS
          listedDomains={items.domain}
          domainName={selectedDomain.name}
          type={type}
          initialValues={currentUpdatePayload}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.CHANGE_DNS_DIALOG))}
        />
      </SGDialogForm>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const payload = deletePayload && deletePayload.payload;
    const name = deletePayload && this.getDNSName(deletePayload.entity);

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.dns.delete.dialog.title' }, { name })}
        onSubmit={() => this.props.actions.deleteItem(payload)}
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog, environment } = this.props;

    const dnsResourceMetaApi = 'dns-' + entity.type.toLowerCase();
    const dnsResource = 'dns' + this.UCF(entity.type.toLowerCase());
    const dnsEndpoint = '/dns-' + entity.type.toLowerCase();

    const metaFields = {
      resourceName: dnsResource,
      endpoint: dnsEndpoint,
      parentResourceName: 'dns',
      resourceNameMetaApi: dnsResourceMetaApi,
      entityName: entity.name || ''
    };

    const deletePayload = {
      entity: { ...entity },
      payload: {
        itemId: id,
        _metaFields: { ...metaFields },
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.page.dns.delete.success.message'
            },
            error: {
              intlKey: 'translate.page.dns.delete.error.message'
            }
          }
        }
      }
    };

    const updatePayload = {
      _metaFields: {
        ...metaFields
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.dns.update.success.message'
          },
          error: {
            intlKey: 'translate.page.dns.update.error.message'
          }
        }
      },
      ...entity
    };

    const hasCDNRecord = entity.value === CDN_RECORD;
    const showInactiveLabel = hasCDNRecord && environment.isPhone;

    return (
      <TableContextMenu
        entity={entity}
        resourceName={dnsResourceMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            label: !showInactiveLabel
              ? intl.formatMessage({ id: 'translate.generic.edit' })
              : `${intl.formatMessage({ id: 'translate.generic.edit' })} (${intl.formatMessage({
                  id: 'translate.generic.inactive'
                })})`,
            e2eAttr: 'table-action-edit',
            icon: 'edit',
            visibleOnDesktop: true,
            disabled: hasCDNRecord,
            // label: hasCDNRecord && intl.formatMessage({ id: 'translate.generic.inactive' }),
            onClick: () =>
              this.setState({ currentUpdatePayload: updatePayload }, () => openSGDialog(REDUX_FORM.CHANGE_DNS_DIALOG))
          },
          {
            vcsMethod: 'DELETE',
            label: !showInactiveLabel
              ? intl.formatMessage({ id: 'translate.generic.delete' })
              : `${intl.formatMessage({ id: 'translate.generic.delete' })} (${intl.formatMessage({
                  id: 'translate.generic.inactive'
                })})`,
            e2eAttr: 'table-action-delete',
            icon: 'trash',
            visibleOnDesktop: true,
            disabled: hasCDNRecord,
            onClick: () =>
              this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
                openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };

  getDataForDomain = () => {
    if (!this.props.items.dns) {
      return;
    }

    return this.props.items.dns.filter(
      (r) => r.domain_id === this.state.selectedDomain.id && !['SOA', 'NS'].includes(r.type)
    );
  };

  getTableData = () => {
    const data = this.getDataForDomain();

    if (this.state.selectedRecordTypes.length > 0) {
      return data.filter((record) => this.state.selectedRecordTypes.includes(record.type));
    }

    return data;
  };

  checkDomainCDNEnabled = (dns) => {
    return (dns.value = dns.sgcdn ? CDN_RECORD : dns.value);
  };

  normalizeTableData = (data, listedDomains) => {
    if (!data || !listedDomains) {
      return;
    }

    const dns = deepclone(data);

    return dns.filter((dns) => {
      return this.checkDomainCDNEnabled(dns);
    });
  };

  renderNotPointedNotice = () => {
    const { intl } = this.props;
    const { name, id } = this.state.selectedDomain;
    const pickerOptions = this.pickerOptions();
    const selectedDomainData = pickerOptions.find((domain) => domain.id === id);
    const shouldRenderNotPointedNotice = selectedDomainData && !selectedDomainData.sg_dns;

    if (!shouldRenderNotPointedNotice) {
      return null;
    }

    return (
      <div>
        <SecondLevelTitle />

        <DomainNotConnectedNotice
          apiResource={API_RESOURCE.DOMAIN}
          domainId={id}
          noticeTitle={intl.formatMessage({ id: 'translate.page.dns.dns-notice.title' }, { domain: name })}
        />
      </div>
    );
  };

  renderNotAvailableNotice = () => {
    const { intl } = this.props;

    return (
      <div>
        <SecondLevelTitle />

        <Notice type="warning" title={intl.formatMessage({ id: 'translate.page.dns.not-available-notice.title' })}>
          {intl.formatMessage({ id: 'translate.page.dns.not-available-notice.text' })}
        </Notice>
      </div>
    );
  };

  setCreateBoxTab = (dnsType: DNSTypes) => this.setState({ selectedDNSType: dnsType });

  renderCreateBoxTabs = () => {
    const { intl } = this.props;
    const { selectedDNSType } = this.state;

    return (
      <Tabs border="light">
        <Tab
          data-e2e="dns-tab-a"
          active={selectedDNSType === DNSTypes.DNS_A}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_A)}
        >
          {DNSTypes.DNS_A}
        </Tab>
        <Tab
          data-e2e="dns-tab-aaaa"
          active={selectedDNSType === DNSTypes.DNS_AAAA}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_AAAA)}
        >
          {DNSTypes.DNS_AAAA}
        </Tab>
        <Tab
          data-e2e="dns-tab-cname"
          active={selectedDNSType === DNSTypes.DNS_CNAME}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_CNAME)}
        >
          {DNSTypes.DNS_CNAME}
        </Tab>
        <Tab
          data-e2e="dns-tab-mx"
          active={selectedDNSType === DNSTypes.DNS_MX}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_MX)}
        >
          {DNSTypes.DNS_MX}
        </Tab>
        <Tab
          data-e2e="dns-tab-srv"
          active={selectedDNSType === DNSTypes.DNS_SRV}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_SRV)}
        >
          {DNSTypes.DNS_SRV}
        </Tab>
        <Tab
          data-e2e="dns-tab-txt"
          active={selectedDNSType === DNSTypes.DNS_TXT}
          onClick={() => this.setCreateBoxTab(DNSTypes.DNS_TXT)}
        >
          {DNSTypes.DNS_TXT}
        </Tab>
      </Tabs>
    );
  };

  renderValueColumn = (value, { type, prio = null, service = null, weight = null, port = null }) => {
    const { intl } = this.props;

    const textWrapperProps = {
      truncate: true,
      onCopy: (event) => {
        this.handleCopyToClipboard(value, event.target);
      },
      onCut: (event) => {
        event.preventDefault();

        this.handleCopyToClipboard(value, event.target);
      }
    };

    const prefixTextProps: any = {
      color: 'light',
      tag: 'span',
      style: { userSelect: 'none', WebkitUserSelect: 'none' }
    };

    const cdnRecordEnabled = value === CDN_RECORD;
    switch (type) {
      case DNSTypes.DNS_A:
      case DNSTypes.DNS_AAAA:
        return (
          <Text {...textWrapperProps}>
            {!cdnRecordEnabled ? (
              <React.Fragment>
                <Text {...prefixTextProps}>
                  {intl.formatMessage({ id: 'translate.page.dns.record.value.points-to.text' })}
                </Text>
                &nbsp;
                <Text tag="span">{value}</Text>
              </React.Fragment>
            ) : (
              <Text tag="span" weight="bold" color="ocean">
                {intl.formatMessage({ id: 'translate.page.dns.cdn.record' })}
              </Text>
            )}
          </Text>
        );
      case DNSTypes.DNS_CNAME:
        return (
          <Text {...textWrapperProps}>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.is-an-alias-of.text' })}
            </Text>
            &nbsp;<Text tag="span">{value}</Text>
          </Text>
        );
      case DNSTypes.DNS_MX:
        return (
          <Text {...textWrapperProps}>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.mail-is-hosted-at.text' })}
            </Text>
            &nbsp;<Text tag="span">{value}</Text>&nbsp;
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.with-priority.text' })}
            </Text>
            &nbsp;<Text tag="span">{prio}</Text>
          </Text>
        );
      case DNSTypes.DNS_SRV:
        return (
          <Text {...textWrapperProps}>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.with-priority.text' })}
            </Text>
            &nbsp;
            <Text tag="span">{prio}</Text>
            <Text {...prefixTextProps}>,&nbsp;</Text>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.with-weight.text' })}
            </Text>
            &nbsp;
            <Text tag="span">{weight}</Text>
            <Text {...prefixTextProps}>,&nbsp;</Text>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.with-port.text' })}
            </Text>
            &nbsp;
            <Text tag="span">{port}</Text>
            <Text {...prefixTextProps}>&nbsp;</Text>
            <Text {...prefixTextProps}>
              {intl.formatMessage({ id: 'translate.page.dns.record.value.with-value.text' })}
            </Text>
            &nbsp;
            <Text tag="span">{value}</Text>
          </Text>
        );
      default:
        return <Text {...textWrapperProps}>{value}</Text>;
    }
  };

  renderPageContent() {
    const { intl, environment, domainAll } = this.props;
    const { selectedDomain, selectedDNSType } = this.state;

    if (!selectedDomain.name) {
      return null;
    }

    const CreateForm = DNS_FORM_COMPONENT[selectedDNSType];

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.type' }),
        accessor: 'type',
        style: { width: environment.isPhone ? 'auto' : '100px' }
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.name' }),
        accessor: 'name',
        render: (name, entity) => this.getDNSName(entity)
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.value' }),
        accessor: 'value',
        render: this.renderValueColumn
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    const domainData = domainAll.find((d) => d.name === selectedDomain.name);
    const mainDomains = domainAll.filter((d) => d.domain_type !== 'alias');
    const listedDomains = domainData && domainData.domain_type !== 'main_domain' ? [domainData] : mainDomains;
    const tableData = this.normalizeTableData(this.getTableData(), listedDomains);

    return (
      <React.Fragment>
        {this.renderNotPointedNotice()}

        <CreateBox
          selectedDNSType={this.state.selectedDNSType}
          apiResource={API_RESOURCE_MAP[this.state.selectedDNSType]}
          renderBeforeBoxChildren={this.renderCreateBoxTabs()}
        >
          <CreateForm
            listedDomains={listedDomains}
            domainName={selectedDomain.name}
            selectedDNSType={this.state.selectedDNSType}
            apiResource={API_RESOURCE_MAP[this.state.selectedDNSType]}
            onSubmit={(data) => this.onCreateFormSubmit({ ...data, domain_id: selectedDomain.id })}
          />
        </CreateBox>

        <VCS resourceName={resourceName} hasMethod="GET">
          <SGTable
            title={
              <Flex align="center" justify="space-between">
                {intl.formatMessage({ id: 'translate.page.dns.list.title' })}
                {this.renderFilter()}
              </Flex>
            }
            data={tableData}
            columns={environment.isPhone ? reorderArray(columns, { from: 1, to: 0 }) : columns}
            resources={[{ resourceName: API_RESOURCE.DNS.resourceName, methods: ['GET'] }]}
            rowResources={[
              { resourceName: API_RESOURCE.DNS_A.resourceName, methods: ['DELETE'] },
              { resourceName: API_RESOURCE.DNS_AAAA.resourceName, methods: ['DELETE'] },
              { resourceName: API_RESOURCE.DNS_CNAME.resourceName, methods: ['DELETE'] },
              { resourceName: API_RESOURCE.DNS_MX.resourceName, methods: ['DELETE'] },
              { resourceName: API_RESOURCE.DNS_SRV.resourceName, methods: ['DELETE'] },
              { resourceName: API_RESOURCE.DNS_TXT.resourceName, methods: ['DELETE'] }
            ]}
            noDataMessage="translate.page.dns.sg-table.no-data.message"
          />
        </VCS>
      </React.Fragment>
    );
  }

  renderFilter = () => {
    const data = this.getDataForDomain();

    if (!data) {
      return null;
    }

    const recordType = Array.from(new Set(data.map((r) => r.type)));

    return (
      <Filter
        title={this.props.intl.formatMessage({ id: 'translate.generic.filter.dns.records' })}
        config={{
          checkbox: {
            type: 'checkbox',
            groupTitle: this.props.intl.formatMessage({ id: 'translate.generic.filter.by.type' }),
            children: recordType.map((type) => ({
              id: type,
              label: type,
              checked: this.state.selectedRecordTypes.includes(type)
            }))
          }
        }}
        onChange={this.filterByType}
        onResetFilter={this.filterByType}
        translations={{
          closeBtnLabel: this.props.intl.formatMessage({ id: 'translate.generic.close' }),
          resetBtnLabel: this.props.intl.formatMessage({ id: 'translate.reset' })
        }}
      />
    );
  };

  componentDidMount() {
    const pickerOptions = this.pickerOptions();
    if (pickerOptions.length === 1) {
      this.selectDomain(MAIN_DOMAIN_ID, pickerOptions[0]);
    }
  }

  componentDidUpdate(prevProps) {
    const pickerOptions = this.pickerOptions();
    if (!areEqual(prevProps, this.props) && pickerOptions.length === 1) {
      this.selectDomain(MAIN_DOMAIN_ID, pickerOptions[0]);
    }
  }

  componentWillUnmount() {
    this.props.actions.clearResource(API_RESOURCE.DOMAIN_ALL.resourceName);
  }

  render() {
    const { intl } = this.props;

    const pickerOptions = this.pickerOptions();
    const { name, id } = this.state.selectedDomain;
    const selectedDomainData = pickerOptions.find((domain) => domain.id === id);
    const isTempDomain = selectedDomainData && isTemporaryDomain(selectedDomainData.name);
    return (
      <div>
        <PageHeader id={ToolId.dns} instructions={intl.formatMessage({ id: 'translate.page.dns.instructions' })} />

        <Section>
          <Grid style={{ position: 'relative' }}>
            <PartialLoader
              resources={[
                { resourceName: API_RESOURCE.DOMAIN.resourceName, methods: ['GET'] },
                { resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName, methods: ['GET'] },
                { resourceName: API_RESOURCE.DOMAIN_ALL.resourceName, methods: ['GET'] }
              ]}
            >
              {pickerOptions.length > 1 && (
                <div className="domain-select">
                  <SecondLevelTitle>{intl.formatMessage({ id: 'translate.generic.select.domain' })}</SecondLevelTitle>

                  <Dropdown
                    data-e2e="domain-select"
                    options={pickerOptions}
                    size="x-large"
                    searchable={pickerOptions.length > 1}
                    enableShadow
                    onChange={this.onDomainChanged}
                    selectedValue={id}
                    placeholder={intl.formatMessage({ id: 'translate.generic.pick.domain' })}
                    searchByKey="name"
                    optionValue="id"
                    optionLabel="name"
                  />
                </div>
              )}

              {!isTempDomain ? this.renderPageContent() : this.renderNotAvailableNotice()}
            </PartialLoader>
          </Grid>
        </Section>

        {this.renderUpdateComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  environment: state.environment,
  domainName: state.sites.currentDomainName,
  domainAll: state.pageItems[API_RESOURCE.DOMAIN_ALL.resourceName] || [],
  dnsServers:
    (state.siteMetaApi && state.siteMetaApi.features && state.siteMetaApi.features.preferred_dns_servers) || []
});

export default indexWithCRUD(mapStateToProps, { ...sgDialogActions, fetchItems, createNotification })(
  DnsPage,
  API_RESOURCE.DOMAIN_ALL,
  API_RESOURCE.DOMAIN
);
