import * as React from 'react';
import { Grid } from '@siteground/styleguide';

type Props = {
  children: any;
  gridProps?: any;
  name?: string;
  onSubmit?: any;
};

const FormGrid = ({ children, onSubmit, gridProps }: Props) => (
  <form onSubmit={onSubmit}>
    <Grid sm="12" padding="responsive" gap="responsive" {...gridProps}>
      {children}
    </Grid>
  </form>
);

export default FormGrid;
