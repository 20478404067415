import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Column, Text, Label, Notice } from '@siteground/styleguide';
import { injectIntl } from 'react-intl';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import FormDropdown from '../../../components/form-dropdown';
import { PhpVersion, PhpVersionManageType } from '../types';

type Props = {
  change: Function;
  phpVersions: PhpVersion[];
  getFormValues: Function;
  intl: Intl;
  phpVersionOption: PhpVersionManageType;
};

type VersionTypeOption = {
  label: string;
  value: PhpVersionManageType;
};

const formName = REDUX_FORM.CHANGE_PHP_VERSION;

const VERSION_TYPE_OPTIONS: VersionTypeOption[] = [
  {
    label: 'translate.page.php-manager.php-managed.dropdown-option.text',
    value: PhpVersionManageType.Auto
  },
  {
    label: 'translate.page.php-manager.php-manual.dropdown-option.text',
    value: PhpVersionManageType.Manual
  }
];

export const deprecatedPHPVersion = [56, 70, 71, 72];

export const Form = ({ change, phpVersions = [], getFormValues, intl }: Props) => {
  const [phpVersion, setPHPVersion] = React.useState(0);
  const formValues = getFormValues(formName);
  const versionId = formValues && formValues.version_id;
  const versionType = formValues && formValues.version_type;

  const visiblePhpVersions = phpVersions.filter(({ disabled, id }) => Boolean(!Boolean(disabled) || id === versionId));

  return (
    <Grid>
      <Column smSpan="12">
        <Field
          name="version_type"
          label={intl.formatMessage({ id: 'translate.page.php-manager.set-php-version.label' })}
          options={VERSION_TYPE_OPTIONS}
          optionValue="value"
          optionLabel="label"
          template={(option: VersionTypeOption) => intl.formatMessage({ id: option.label })}
          component={FormDropdown}
        />
      </Column>
      {versionType === PhpVersionManageType.Manual && (
        <Column smSpan="12">
          <Field
            name="version_id"
            label={intl.formatMessage({ id: 'translate.page.phpSettings.php_version.label' })}
            options={visiblePhpVersions.filter(({ id }) => !deprecatedPHPVersion.includes(id))}
            optionValue="id"
            optionLabel="version"
            optionDisabled="disabled"
            component={FormDropdown}
            onChange={(e, value) => setPHPVersion(value)}
          />
        </Column>
      )}
      {deprecatedPHPVersion.includes(phpVersion) && (
        <Column smSpan="12">
          <Notice shadow={false} type="warning" background="light">
            <Text>{intl.formatMessage({ id: 'translate.page.phpSettings.not.recommend.version.notice' })}</Text>
          </Notice>
        </Column>
      )}
    </Grid>
  );
};

const UpdatePhpVersion = reduxForm({ form: formName })(Form);

export default withSpanelForm(injectIntl(UpdatePhpVersion));
