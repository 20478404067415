import { ToolId } from './route-info';

// TODO use API const
export const ENDPOINT = {
  [ToolId.dashboard]: null,
  [ToolId.backup]: 'backup',
  [ToolId.backupCreate]: null,
  [ToolId.backupRestore]: null,
  [ToolId.cloudflare]: 'cloudflare',
  [ToolId.email]: 'email',
  [ToolId.emailAuth]: 'spf',
  [ToolId.emailAutoresponder]: 'email-autoresponder',
  [ToolId.emailFilters]: 'email-filter',
  [ToolId.emailForward]: 'email-forward',
  [ToolId.spamExperts]: 'spamexperts-login',
  [ToolId.googleWorkspace]: 'gworkspace',
  [ToolId.ssh]: 'ssh',
  [ToolId.cron]: 'cron',
  [ToolId.cronJob]: null,
  [ToolId.cronNotification]: null,
  [ToolId.phpSettings]: 'php-version',
  [ToolId.blockIPs]: 'location-addr',
  [ToolId.redirect]: 'location-redirect',
  [ToolId.cacher]: 'domain-cache',
  [ToolId.cdn]: 'domain-cdn',
  [ToolId.cacherStatic]: null,
  [ToolId.cacherDynamic]: null,
  [ToolId.cacherMemcached]: null,
  [ToolId.dns]: 'dns',
  [ToolId.mysql]: 'db-mysql_db',
  [ToolId.mysqlDatabase]: null,
  [ToolId.mysqlUser]: null,
  [ToolId.mysqlAccess]: null,
  [ToolId.mysqlPhpMyAdmin]: null,
  [ToolId.pgsql]: 'db-pgsql_db',
  [ToolId.pgsqlDatabase]: null,
  [ToolId.pgsqlUser]: null,
  [ToolId.pgsqlAccess]: null,
  [ToolId.protected]: 'location',
  [ToolId.protectedURLs]: null,
  [ToolId.protectedUsers]: null,
  [ToolId.subdomain]: 'domain',
  [ToolId.parkedDomains]: 'domain-alias',
  [ToolId.ftp]: 'ftp',
  [ToolId.filemanager]: 'file',
  [ToolId.wpAutoupdate]: 'app',
  [ToolId.wpManage]: 'wordpress',
  [ToolId.wpStaging]: 'app-staging',
  [ToolId.wpTransfer]: 'wordpress-transfer',
  [ToolId.wpSearchAndReplace]: 'wordpress',
  [ToolId.ssl]: 'ssl',
  [ToolId.statistics]: 'webstats',
  [ToolId.httpsEnforce]: 'domain',
  [ToolId.errorLog]: 'errorlogs',
  [ToolId.accessLog]: 'accesslogs',
  [ToolId.appManager]: 'autoinstall',
  [ToolId.git]: 'app-repo',
  [ToolId.sitescanner]: 'domain'
};
