import * as React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import * as ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as crudActions from '../../core/actions/crud';
import * as sgDialogActions from '../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../core/constants/common';
import customRequestTypes from '../../core/constants/custom-request-types';
import { RootState } from '../reducers';
import PartialLoader from '../components/partial-loader';
import Breadcrumbs from './breadcrumbs';
import MonacoEditor from './code-editor';
import FileManagerContent from './content';
import FileManagerContextMenu from './context-menu';
import * as codeEditorActions from './core/actions/code-editor';
import * as fileManagerActions from './core/actions/file-manager';
import { openFileFromSearchView } from './core/actions/search-view';
import { FILE_MANAGER_CONTEXT_MENU_TYPE } from './core/constants/common';
import { initialFileManagerState } from './core/reducers/utils/initial-state';
import { getCreateFileDialogPayload } from './core/selectors/get-create-file-dialog-payload';

import { getEntityPath, shouldRenderMobile } from './core/utils';

import { FMDialogs } from './dialogs';

import './file-manager.scss';
import Footer from './footer';
import Header from './header';
import { FileManagerSearchView } from './search-view';
import SideNavigation from './side-navigation';
import { FileManagerProps } from './types';

const ReactDOMWithoutTypes: any = ReactDOM; // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20062

type State = {
  fileManagerHeight: string;
};

class FileManager extends React.Component<FileManagerProps, State> {
  filemanagerDOM = null;

  splitContainerRef = null;

  startPanelRef = null;

  endPanelRef = null;

  splitterRef = null;

  editor = null;

  state = {
    fileManagerHeight: 'auto'
  };

  fetchDir = (path) => {
    this.props.actions.fetchDir({
      urlParams: {
        id: path
      }
    });
  };

  componentDidMount() {
    const { entities } = this.props;

    if (entities['/'] === undefined) {
      this.fetchDir('/');
    }

    window.addEventListener('click', this.closeContextMenu, false);

    this.addPanelEventListeners();

    this.setFileManagerHeight();
  }

  componentWillUnmount() {
    this.removePanelEventListeners();

    window.removeEventListener('click', this.closeContextMenu, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.environment.height !== this.props.environment.height) {
      this.setFileManagerHeight(this.props);
    }

    if (this.props.selectedNavigationEntity && !prevProps.selectedNavigationEntity) {
      this.fetchDir(getEntityPath(this.props.selectedNavigationEntity));
    }

    if (prevProps.currentDomainName !== this.props.currentDomainName) {
      this.props.actions.clearFileManagerStore(initialFileManagerState);
      this.fetchDir('/');
    }

    if (!this.props.isSearchVisible && this.props.isSearchVisible !== prevProps.isSearchVisible) {
      this.addPanelEventListeners();
    }

    if (this.props.isSearchVisible && this.props.isSearchVisible !== prevProps.isSearchVisible) {
      this.removePanelEventListeners();
    }
  }

  addPanelEventListeners = () => {
    if (this.splitterRef) {
      this.splitterRef.addEventListener('mousedown', this.splitContainerMouseDownHandler, false);
    }
    if (this.splitContainerRef) {
      this.splitContainerRef.addEventListener('mouseup', this.splitContainerMouseUpHandler, false);
    }
  };

  removePanelEventListeners = () => {
    if (this.splitterRef) {
      this.splitterRef.removeEventListener('mousedown', this.splitContainerMouseDownHandler, false);
    }
    if (this.splitContainerRef) {
      this.splitContainerRef.removeEventListener('mouseup', this.splitContainerMouseUpHandler, false);
    }
  };

  setFileManagerHeight = (props = this.props) => {
    const spaceToBottom = shouldRenderMobile(props.environment) ? 0 : 30;
    const environmentHeight = props.environment.height;
    const FMBoundingClientRect = this.filemanagerDOM.getBoundingClientRect();
    this.setState(
      { fileManagerHeight: `${environmentHeight - FMBoundingClientRect.top - spaceToBottom}px` },
      () => this.editor && this.editor.resize()
    );
  };

  render() {
    const { codeEditor } = this.getAction();

    return (
      <div
        ref={(filemanager) => (this.filemanagerDOM = filemanager)}
        className="file-manager"
        style={{ height: this.state.fileManagerHeight }}
        data-e2e="file-manager"
      >
        <PartialLoader
          position="absolute"
          message={this.props.intl.formatMessage({
            id: 'translate.file.manager.fetch.folder.with.size.loader.message'
          })}
          resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZES }]}
        >
          {shouldRenderMobile(this.props.environment) ? this.renderMobile() : this.renderDesktop()}

          {this.props.contextMenu &&
            ReactDOMWithoutTypes.createPortal(
              <FileManagerContextMenu
                codeEditor={codeEditor}
                onClose={this.closeContextMenu}
                {...this.props.contextMenu}
              />,
              document.body
            )}

          <FMDialogs />
        </PartialLoader>
      </div>
    );
  }

  renderMobile() {
    const { codeEditorIsVisible } = this.props;

    return (
      <React.Fragment>
        {!codeEditorIsVisible && this.renderHeader()}
        {this.renderContent()}
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderDesktop() {
    if (this.props.isSearchVisible) {
      return (
        <PartialLoader
          position="absolute"
          resources={[
            { requestTypeName: customRequestTypes.FILE_MANAGER_REQUESTED_SEARCH },
            { requestTypeName: customRequestTypes.FILE_MANAGER_OPEN_ENTITY_IN_EXPLORER },
            { requestTypeName: customRequestTypes.FILE_MANAGER_SHOW_ENTITY_IN_EXPLORER }
          ]}
        >
          {this.renderHeader()}

          <FileManagerSearchView openContextMenu={this.openContextMenu} />
        </PartialLoader>
      );
    }

    return (
      <React.Fragment>
        {this.renderHeader()}
        {this.renderBreadcrumb()}
        {this.renderPanel()}
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderHeader() {
    const { codeEditor } = this.getAction();

    return <Header key="header" codeEditor={codeEditor} openContextMenu={this.openContextMenu} />;
  }

  renderBreadcrumb() {
    return <Breadcrumbs key="breadcrumbs" />;
  }

  renderPanel() {
    return (
      <article ref={(splitContainer) => (this.splitContainerRef = splitContainer)} key="panel" className="panel">
        {this.renderNavigation()}

        <div ref={(splitter) => (this.splitterRef = splitter)} className="panel__splitter" />

        {this.renderContent()}
      </article>
    );
  }

  renderNavigation() {
    const { codeEditor } = this.getAction();
    const { contextNavigationEntity } = this.props;

    const classes = ['panel__start', contextNavigationEntity && 'panel-disable-scroll'].filter(Boolean).join(' ');

    return (
      <aside
        ref={(panelStart) => (this.startPanelRef = panelStart)}
        className={classes}
        data-e2e="file-manager-navigation"
        onContextMenu={(event) => this.openContextMenu(event, FILE_MANAGER_CONTEXT_MENU_TYPE.BASE)}
      >
        <SideNavigation domains={this.props.domains} codeEditor={codeEditor} openContextMenu={this.openContextMenu} />
      </aside>
    );
  }

  renderContent() {
    const {
      createFileDialogPayload,
      contextMenu,
      codeEditorFileContent,
      codeEditorFileExtension,
      codeEditorIsVisible
    } = this.props;
    const { codeEditor } = this.getAction();

    const classes = ['panel__end', (codeEditorIsVisible || contextMenu) && 'panel-disable-scroll']
      .filter(Boolean)
      .join(' ');

    return (
      <section ref={(endPanel) => (this.endPanelRef = endPanel)} className={classes} data-e2e="file-manager-content">
        <PartialLoader
          position="absolute"
          resources={[
            { requestTypeName: customRequestTypes.FILE_MANAGER_SAVE },
            { requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_FILE },
            { requestTypeName: customRequestTypes.FILE_MANAGER_REQUESTED_NAVIGATE }
          ]}
        >
          {!codeEditorIsVisible && (
            <FileManagerContent codeEditor={codeEditor} openContextMenu={this.openContextMenu} />
          )}

          {codeEditorIsVisible && (
            <MonacoEditor
              onRefsReady={(editor) => {
                this.editor = editor;
              }}
              code={codeEditorFileContent}
              extension={codeEditorFileExtension}
              createFile={() => this.props.openSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_FILE, createFileDialogPayload)}
              onTabClose={(entity) => this.props.actions.closeTabWithConfirmation(entity)}
            />
          )}
        </PartialLoader>
      </section>
    );
  }

  renderFooter() {
    return (
      <Footer
        key="footer"
        onFailedClick={() => this.props.openSGDialog(DIALOGS.FILE_MANAGER_FAILED_UPLOAD)}
        openContextMenu={this.openContextMenu}
      />
    );
  }

  openContextMenu = (event, type) => {
    event.preventDefault();
    const clientX = event.clientX;
    const clientY = event.clientY;

    if (this.props.contextMenu && this.props.contextMenu.clientX) {
      // wait for context to close and then reopen it.
      setTimeout(() => {
        this.props.actions.setContextMenuPosition({ clientX, clientY, type });
      }, 400);
    } else {
      this.props.actions.setContextMenuPosition({ clientX, clientY, type });
    }
  };

  closeContextMenu = (event) => {
    if (!this.props.contextMenu) {
      return;
    }

    this.props.actions.setContextMenuPosition(null);

    if (event && event.nativeEvent) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  getAction: any = () => ({
    codeEditor: {
      openFile: (file) => {
        if (this.props.isSearchVisible) {
          this.props.actions.openFileFromSearchView(file);

          return;
        }

        this.props.actions.openFile(file);
      },

      downloadActiveTabContent: () => this.editor.downloadActiveTabContent(),

      openFindPanel: () => this.editor.openFindPanel(),

      openFindReplacePanel: () => this.editor.openFindReplacePanel(),

      closeTabsWithConfirmation: () => {
        const { codeEditorFiles } = this.props;
        this.props.actions.closeTabsWithConfirmation(codeEditorFiles);
      }
    }
  });

  splitContainerMouseDownHandler = (event) => {
    this.splitContainerRef.addEventListener('mousemove', this.splitContainerMouseMoveHandler, false);
  };

  splitContainerMouseMoveHandler: any = (event) => {
    if (this.editor && this.editor.resize) {
      this.editor.resize();
    }
    this.startPanelRef.style.width = `${event.clientX - this.startPanelRef.getBoundingClientRect().left}px`;
  };

  splitContainerMouseUpHandler = (event) => {
    this.splitContainerRef.removeEventListener('mousemove', this.splitContainerMouseMoveHandler, false);
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...crudActions,
      ...fileManagerActions,
      ...codeEditorActions,
      openFileFromSearchView
    },
    dispatch
  ),

  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

const mapStateToProps = (state: RootState) => ({
  createFileDialogPayload: getCreateFileDialogPayload(state),
  currentDomainName: state.sites.currentDomainName,

  fileManagerState: state.fileManager,
  entities: state.fileManager.entities,
  environment: state.environment,

  codeEditorFiles: state.fileManager.codeEditor.files,
  codeEditorFileContent: state.fileManager.codeEditor.fileContent,
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible,
  codeEditorUserSelection: state.fileManager.codeEditor.userSelection,
  updatedFileContent: state.fileManager.codeEditor.updatedFileContent,

  contextMenu: state.fileManager.contextMenu,
  markedForCopy: state.fileManager.markedForCopy,

  contextContentEntities: state.fileManager.contextContentEntities,
  contextNavigationEntity: state.fileManager.contextNavigationEntity,
  selectedContentEntities: state.fileManager.selectedContentEntities,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,

  siteMetaApiLabels: state.siteMetaApi.labels,

  isSearchVisible: state.fileManager.search.isSearchVisible
});

/**
 * Monkey pacth of React DND HTML5Backend for handling dnd of folders
 * @param manager
 * @returns {any}
 */
function makeFolderAwareHTML5Backend(manager) {
  const backend = HTML5Backend(manager);
  const orig = backend.handleTopDropCapture;
  backend.handleTopDropCapture = function (event) {
    /**
     * The try - catch block is needed for IE,
     * because reading event.dataTransfer.items leeds to error <Permission Denied>
     */
    try {
      if (backend.currentNativeSource) {
        backend.currentNativeSource.item.items = event.dataTransfer.items;
      }
    } catch (e) {
      console.error(e);
    }
    return orig(event);
  };
  return backend;
}

export default connect<{}, {}, Partial<FileManagerProps>>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DragDropContext(makeFolderAwareHTML5Backend)(FileManager)));
