import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchItems } from '../../actions/crud';
import { siteApi } from '../../api/site';
import { UPDATE_EMAIL_AUTHENTICATION_SPF } from '../../constants/actions';
import { API_RESOURCE } from '../../constants/api';
import handleAvalonApiRequest from '../handle-avalon-api-request';

function* updateEmailFiltersOrder(action) {
  const state = yield select();

  const response = yield call(
    siteApi({
      endpoint: API_RESOURCE.SPF.endpoint,
      method: 'POST',
      body: action.payload,
      state
    })
  );

  yield put(
    fetchItems({
      ...API_RESOURCE.SPF,
      urlParams: {
        id: action.payload.domain_name
      }
    })
  );

  return response;
}

function* EmailFilters(): any {
  yield takeLatest(UPDATE_EMAIL_AUTHENTICATION_SPF, handleAvalonApiRequest(updateEmailFiltersOrder));
}

export default EmailFilters;
