import {
  FILE_MANAGER_CLOSE_SEARCH_VIEW,
  FILE_MANAGER_OPEN_SEARCH_VIEW,
  FILE_MANAGER_SEARCH_FOR_INPUT_SUCCEEDED
} from '../constants/actions';
import { initialFileManagerState } from './utils/initial-state';
import { FileManagerRootState } from './utils/internal-state-definition';

const SearchViewReducer = {
  [FILE_MANAGER_OPEN_SEARCH_VIEW]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      search: {
        ...state.search,
        isSearchVisible: true
      }
    };
  },
  [FILE_MANAGER_CLOSE_SEARCH_VIEW]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      search: initialFileManagerState.search
    };
  },
  [FILE_MANAGER_SEARCH_FOR_INPUT_SUCCEEDED]: (state: FileManagerRootState, action) => {
    return {
      ...state,
      search: {
        ...state.search,
        results: action.data.map((entity) => ({
          ...entity,
          n: entity.n.split('/').pop(),
          _meta: {
            path: `${action.root}${entity.n}`.replace('//', '/')
          }
        }))
      }
    };
  }
};
export default SearchViewReducer;
