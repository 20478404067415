import { take, call, fork, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import punycode from 'punycode';

import { siteApi } from '../../api/site';
import handleAvalonApiRequest from '../handle-avalon-api-request';
import { API_RESOURCE } from '../../constants/api';
import { formatStatsData, sortDatesIds } from '../../utils/stats-format';
import { DOMAIN_STATS_REQUESTED } from '../../constants/actions';

import { requestDomainStats, successDomainStats } from '../../actions/pages/resource-stats';

function* requestDomainStatsData({ payload }) {
  const { domainId, dateId } = payload;
  const state = yield select();

  const response = yield call(
    siteApi({
      endpoint: `${API_RESOURCE.RESOURCE_STATS.endpoint}`,
      method: 'GET',
      urlParams: {
        domain_name: punycode.toASCII(domainId),
        id: dateId
      },
      state
    })
  );

  if (response && response.data) {
    yield put(successDomainStats(domainId, dateId, formatStatsData(response.data, dateId)));
  }

  return response;
}

function* resourceStats(): any {
  yield takeLatest(DOMAIN_STATS_REQUESTED, handleAvalonApiRequest(requestDomainStatsData));
}

export default resourceStats;
