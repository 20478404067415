import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@siteground/styleguide';

const FormattedTranslation = ({ id }) => {
  return (
    <FormattedMessage
      id={id}
      values={{
        href: (url) => url,
        a: (href, text) => <Link href={href}>{text}</Link>
      }}
    />
  );
};

export default FormattedTranslation;
