import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Button, Grid, Text } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import { getLanguagePrefix } from '../../../../core/selectors/session';
import { redirectToURL } from '../../../../core/utils/redirect-to-url';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';
import { CREATE_BOX_STATES } from '../constants';

type Props = {
  children: any;
  selectedTab: string;
  renderBeforeBoxChildren: any;
  language?: string;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.SSL;

export const FORM_NAME_BY_TAB_ID = {
  [CREATE_BOX_STATES.INSTALL]: REDUX_FORM.CREATE_INSTALL_SSL,
  [CREATE_BOX_STATES.IMPORT]: REDUX_FORM.CREATE_SSL_IMPORT
};

const CONFIGURE_SSL_URL = {
  en: 'https://my.siteground.com/support/kb/how-do-i-enforce-https/',
  it: 'https://my.siteground.com/support/kb/come-forzo-il-https/',
  es: 'https://my.siteground.com/support/kb/forzar-https/',
  de: 'https://my.siteground.com/support/kb/wie-erzwinge-ich-https/'
};

const CreateContainer = ({ children, selectedTab, renderBeforeBoxChildren, language }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.ssl.create.title' })}
        resourceName={resourceName}
        formName={FORM_NAME_BY_TAB_ID[selectedTab]}
        renderBeforeBoxChildren={renderBeforeBoxChildren}
        renderCustomNotification={({ notification, renderDefaultNotificationTemplate, removeNotification }) => {
          if (notification.state === 'error') {
            return (
              <Grid>
                <Text size="large" align="center">
                  {notification.responseError.message}
                </Text>

                <div style={{ textAlign: 'center' }}>{renderDefaultNotificationTemplate()}</div>
              </Grid>
            );
          }

          return (
            <div>
              {!CONFIG.IS_RESELLER && (
                <Button
                  type="outlined"
                  color="secondary"
                  data-e2e="configure-ssl-button"
                  onClick={() => window.open(CONFIGURE_SSL_URL[language], '_blank')}
                >
                  {intl.formatMessage({ id: 'translate.page.ssl.create.success.configure-https.button' })}
                </Button>
              )}

              {renderDefaultNotificationTemplate()}
            </div>
          );
        }}
        resources={[
          {
            resourceName: API_RESOURCE.SSL.resourceName,
            methods: ['POST']
          },
          {
            resourceName: API_RESOURCE.SSL_LE.resourceName,
            methods: ['POST']
          },
          {
            resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
            methods: ['GET']
          }
        ]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default connect<{}, {}, Props>((store) => ({
  language: getLanguagePrefix(store)
}))(CreateContainer);
