import { RESSELLER_LOGIN_ROUTE } from '../constants/route-info';
import { SESSION_EXPIRED_REASON } from '../constants/common';
import { getAppHost } from '../common/route-utils';

const generateQuery = ({ redirect, sessionExpired, lang }) => {
  return [
    `?redirect=${encodeURIComponent(redirect)}`,
    sessionExpired ? `&reason=${SESSION_EXPIRED_REASON}` : '',
    lang ? `&lang=${lang}` : ''
  ].join('');
};
export const redirectToLoginApp = ({ sessionExpired = false, lang }) =>
  (window.location.href = `${CONFIG.LOGIN_URL}${generateQuery({
    sessionExpired,
    redirect: window.location.href,
    lang
  })}`);
export const redirectToResellerLogin = ({ sessionExpired = false, lang }) =>
  (window.location.href = `${getAppHost()}/${RESSELLER_LOGIN_ROUTE}${generateQuery({
    sessionExpired,
    redirect: window.location.href,
    lang
  })}`);
