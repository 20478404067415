import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DIALOGS } from '../../../../core/constants/common';
import { RootState } from '../../../reducers';
import Entity from '../../content/entity/entity';
import { moveFiles, uploadFiles } from '../../core/actions/file-manager';
import { getEntityPath } from '../../core/utils';
import { OverwriteFilesConfirmationDialog } from '../overwrite-files-confirmation';

type StateProps = {
  dialogPayload: {
    requestPayload: any;
    existingFiles: string[];
  };
};

type DispatchProps = {
  moveFiles: typeof moveFiles;
};

interface Props extends StateProps, DispatchProps {}

const MoveFilesConfirmationDialog = (props: Props) => {
  const { dialogPayload, moveFiles } = props;
  const requestPayload = dialogPayload && dialogPayload.requestPayload;
  const existingFiles = dialogPayload ? dialogPayload.existingFiles : [];
  const intl = useIntl();
  const columns = [
    {
      header: intl.formatMessage({ id: 'generic.file-name.label' }),
      accessor: 'n',
      render: (n, entity) => <Entity entity={entity} />
    }
  ];

  return (
    <OverwriteFilesConfirmationDialog
      id={DIALOGS.FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_MOVE}
      title={intl.formatMessage(
        {
          id: 'translate.file.manager.overwrite.confirmation.files.dialog.title'
        },
        { total: existingFiles.length }
      )}
      subTitle={intl.formatMessage({ id: 'translate.file.manager.overwrite.confirmation.files.dialog.subtitle' })}
      tableProps={{ data: existingFiles, columns }}
      handleOverwrite={() => {
        moveFiles({
          ...requestPayload,
          urlParams: {
            ...requestPayload.urlParams,
            safe: 0
          }
        });
      }}
      handleKeepExistingOverwrite={() => {
        if (!requestPayload.urlParams && !requestPayload.urlParams.entries) {
          return;
        }

        const existingFilePaths = existingFiles.map((e) => getEntityPath(e));
        const filesToMove = requestPayload.urlParams.entries.filter((path) => !existingFilePaths.includes(path));

        if (filesToMove.length === 0) {
          return;
        }

        moveFiles({
          ...requestPayload,
          urlParams: {
            ...requestPayload.urlParams,
            entries: filesToMove
          }
        });
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  dialogPayload: state.dialog[DIALOGS.FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_MOVE]
});

const mapDispatchToProps = {
  uploadFiles,
  moveFiles
};

export default connect<StateProps, DispatchProps, any>(
  mapStateToProps,
  mapDispatchToProps
)(MoveFilesConfirmationDialog);
