import { formatMessage } from '../../../core/translate';

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 3600;

export const getTTLOptions = () => [
  {
    seconds: 5 * SECONDS_IN_MINUTE,
    label: `5 ${formatMessage({ id: 'translate.generic.time.min' })}`
  },
  {
    seconds: 15 * SECONDS_IN_MINUTE,
    label: `15 ${formatMessage({ id: 'translate.generic.time.min' })}`
  },
  {
    seconds: 30 * SECONDS_IN_MINUTE,
    label: `30 ${formatMessage({ id: 'translate.generic.time.min' })}`
  },
  {
    seconds: 1 * SECONDS_IN_HOUR,
    label: `1 ${formatMessage({ id: 'translate.generic.time.hour' })}`
  },
  {
    seconds: 2 * SECONDS_IN_HOUR,
    label: `2 ${formatMessage({ id: 'translate.generic.time.hours' })}`
  },
  {
    seconds: 5 * SECONDS_IN_HOUR,
    label: `5 ${formatMessage({ id: 'translate.generic.time.hours' })}`
  },
  {
    seconds: 12 * SECONDS_IN_HOUR,
    label: `12 ${formatMessage({ id: 'translate.generic.time.hours' })}`
  },
  {
    seconds: 24 * SECONDS_IN_HOUR,
    label: `24 ${formatMessage({ id: 'translate.generic.time.hours' })}`
  }
];

export const findPredefinedTTLOption = (ttl: number) =>
  getTTLOptions().find(({ seconds }) => Number(seconds) === Number(ttl));

export const isTemporaryDomain = (domain) => domain.match(/\.sg-host\.com$/);

export const generateTTLOptions = (props) => {
  const { formName, getFormValues, intl } = props;
  const formValues = getFormValues(formName);

  const ttlValue = formValues && formValues.ttl;
  const ttlPredefinedOption = findPredefinedTTLOption(ttlValue);

  if (!ttlValue || ttlPredefinedOption) {
    return getTTLOptions();
  }

  return [
    {
      seconds: ttlValue,
      label: `${ttlValue} ${intl.formatMessage({ id: 'translate.generic.time.seconds' })}`
    }
  ].concat(getTTLOptions());
};
