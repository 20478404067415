import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';
import { injectIntl } from 'react-intl';

import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../components/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../../components/sg-form';

import FormInput from '../../../../components/form-input';

type Props = {
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => any;
  intl: Intl;
};

const formName = REDUX_FORM.CREATE_ITEM_CRON_NOTIFY;

const Form = ({ validationUtils, sPanelFormSubmit, intl }: Props) => {
  const { required, validationWithMetaApi } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          name="email"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.cron.notification.email' })}
          placeholder={intl.formatMessage({ id: 'translate.page.cron.notification.email' })}
          component={FormInput}
          validate={[required, validationWithMetaApi]}
        />
      </Column>
      <Column smSpan="12">
        <SGSubmitButton formName={formName} />
      </Column>
    </SGForm>
  );
};

const CreateCronNotification = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.CRON_NOTIFY,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(CreateCronNotification));
