import * as React from 'react';
import { injectIntl } from 'react-intl';
import SettingListItem from '../../../components/setting-list-item';

export type SettingListItemProps = {
  children: any;
  intl: Intl;
  title: string;
  info: string;
  resources: LoaderResource[];
};

const SettingListItemWrapper = (props: SettingListItemProps) => {
  const { children, intl, title, info, resources } = props;
  return (
    <SettingListItem title={title} info={info} resources={resources}>
      {children}
    </SettingListItem>
  );
};

export default injectIntl(SettingListItemWrapper);
