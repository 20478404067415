import { put, select, takeEvery } from 'redux-saga/effects';
import { openSGDialog } from '../../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';
import getStore from '../../../../core/store';
import { codeEditorOnTabClose, saveFile } from '../../core/actions/file-manager';
import { fetchFile, focusCodeEditorEntity } from '../actions/file-manager';
import * as FileManagerConstants from '../constants/actions';
import { getPrioritizedEntities } from '../selectors/get-prioritized-entities';

import {
  getCodeEditorEntityContent,
  getCodeEditorEntityUpdatedContent,
  getEntityInfoNumber,
  getEntityPath,
  getEntityReadableName,
  getEntitySize,
  isEntityActiveInCodeEditor,
  isEntityForbiddenInCodeEditor,
  isEntityKnownInCodeEditor,
  isEntityOpenedInCodeEditor,
  isEntityUpdatedInCodeEditor
} from '../utils';

function* handleCloseEditorTab({ payload }) {
  const { entities } = payload;

  for (const entity of entities) {
    yield put(codeEditorOnTabClose(entity));
  }
}

function* handleCloseTabWithConfirmation({ payload }) {
  const { entity } = payload;

  if (getCodeEditorEntityContent(entity) !== getCodeEditorEntityUpdatedContent(entity)) {
    yield put(openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_CLOSE, [entity]));

    return;
  }

  yield put(codeEditorOnTabClose(entity));
}

function* handleCloseTabsWithConfirmation({ payload }) {
  const { entities } = payload;
  const dirtyEntities = [];
  const pureEntities = [];

  entities.forEach((entity) => {
    if (getCodeEditorEntityContent(entity) !== getCodeEditorEntityUpdatedContent(entity)) {
      dirtyEntities.push(entity);
    } else {
      pureEntities.push(entity);
    }
  });

  if (dirtyEntities.length > 0) {
    yield put(openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_CLOSE, dirtyEntities));
  }

  for (const pureEntity of pureEntities) {
    yield put(codeEditorOnTabClose(pureEntity));
  }
}

function* handleOpenFile(action) {
  const { entity } = action.payload;

  const { fileManager } = yield select();
  const prioritiezedEntities = getPrioritizedEntities(fileManager);
  const file = entity ? entity : prioritiezedEntities[0];

  const isEntityOpenedInEditor = isEntityOpenedInCodeEditor(fileManager, getEntityInfoNumber(file));
  const isEntityUpdatedInEditor = isEntityUpdatedInCodeEditor(fileManager, getEntityInfoNumber(file));

  const fetchFileParams = {
    urlParams: {
      filename: getEntityPath(file)
    },
    entity: file
  };

  switch (true) {
    case isEntityForbiddenInCodeEditor(file):
      return yield put(
        openSGDialog(DIALOGS.FILE_MANAGER_MESSAGE, {
          title: 'translate.file.manager.file.cant.be.edited.not_allowed'
        })
      );
    case !isEntityKnownInCodeEditor(file):
      return yield put(
        openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM, {
          title: 'translate.file.manager.file.cant.be.edited.unknown',
          message: 'translate.file.manager.file.cant.be.edited.unknown.message',
          onConfirm: () => getStore().dispatch(fetchFile(fetchFileParams))
        })
      );
    case Boolean(getEntitySize(file) > 10 * 1024 * 1024):
      return yield put(
        openSGDialog(DIALOGS.FILE_MANAGER_MESSAGE, {
          title: 'translate.file.manager.file.cant.be.edited.size_limit'
        })
      );
    case isEntityOpenedInEditor:
      yield put(focusCodeEditorEntity(getEntityInfoNumber(file)));

      if (!isEntityUpdatedInEditor) {
        yield put(fetchFile(fetchFileParams));
      }

      return;
    default:
      yield put(fetchFile(fetchFileParams));
  }
}

function* handleFileSave() {
  const { fileManager } = yield select();
  const codeEditorFiles = fileManager.codeEditor.files;

  const modifiedFile = codeEditorFiles.find((file) => isEntityActiveInCodeEditor(file));
  const fileName = getEntityReadableName(modifiedFile);

  yield put(
    saveFile({
      urlParams: {
        filename: getEntityPath(modifiedFile)
      },
      file: getCodeEditorEntityUpdatedContent(modifiedFile),
      entity: modifiedFile,
      _meta: {
        name: fileName,
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.save.file.success.message',
            intlValues: { name: getEntityReadableName(modifiedFile) }
          },
          error: {
            intlKey: 'translate.file.manager.save.file.error.message',
            intlValues: { name: getEntityReadableName(modifiedFile) }
          }
        }
      }
    })
  );
}

export default function* codeEditor() {
  yield takeEvery(FileManagerConstants.CLOSE_CODE_EDITOR_TAB, handleCloseEditorTab);
  yield takeEvery(FileManagerConstants.CLOSE_TAB_WITH_CONFIRMATION, handleCloseTabWithConfirmation);
  yield takeEvery(FileManagerConstants.CLOSE_TABS_WITH_CONFIRMATION, handleCloseTabsWithConfirmation);
  yield takeEvery(FileManagerConstants.OPEN_FILE, handleOpenFile);
  yield takeEvery(FileManagerConstants.FILE_MANAGER_TRIGGER_FILE_SAVE, handleFileSave);
}
