import * as React from 'react';
import { useIntl } from 'react-intl';
import { Tabs, Tab } from '@siteground/styleguide';

import { SiteTypes } from '../types';

export const SitesTabs = ({ selectedType, onChangeType }) => {
  const intl = useIntl();
  return (
    <Tabs size="medium" border="light">
      <Tab
        data-e2e="domain-box-tab-live-sites"
        active={selectedType === SiteTypes.LiveSites}
        onClick={() => onChangeType(SiteTypes.LiveSites)}
      >
        {intl.formatMessage({ id: 'translate.page.php-settings.domain-box-tab-live-sites.label' })}
      </Tab>
      <Tab
        data-e2e="domain-box-tab-staging-sites"
        active={selectedType === SiteTypes.StagingSites}
        onClick={() => onChangeType(SiteTypes.StagingSites)}
      >
        {intl.formatMessage({ id: 'translate.page.php-settings.domain-box-tab-staging-sites.label' })}
      </Tab>
    </Tabs>
  );
};

export default SitesTabs as typeof SitesTabs;
