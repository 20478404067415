import * as React from 'react';
import { injectIntl } from 'react-intl';
import Section from '@siteground/styleguide/lib/components/layout/section';
import { ToolId } from '../../../core/constants/route-info';
import { ROUTES } from '../../../core/constants/routes';
import PageHeader from '../../components/page-header';
import PageTabs from '../../components/page-tabs';

type MysqlPageProps = {
  location: any;
  intl: any;
  router: any;
  children: any;
};

export const MysqlPage = ({ intl, location, router, children }: MysqlPageProps) => (
  <React.Fragment>
    <PageHeader id={ToolId.mysql} instructions={intl.formatMessage({ id: 'translate.page.mysql.instructions' })} />
    <Section>
      <PageTabs
        location={location}
        tabLabelsByTabId={{
          database: intl.formatMessage({ id: 'translate.generic.databases' }),
          user: intl.formatMessage({ id: 'translate.generic.users' }),
          access: intl.formatMessage({ id: 'translate.generic.remote' }),
          myAdmin: intl.formatMessage({ id: 'translate.generic.phpmyadmin' })
        }}
        routesByTabId={{
          database: ROUTES[ToolId.mysqlDatabase],
          user: ROUTES[ToolId.mysqlUser],
          access: ROUTES[ToolId.mysqlAccess],
          myAdmin: ROUTES[ToolId.mysqlPhpMyAdmin]
        }}
        onNavigate={router.push}
      />
    </Section>
    <Section>{children}</Section>
  </React.Fragment>
);

export default injectIntl(MysqlPage);
