import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../../core/constants/api';
import FormDropdown from '../../../../components/form-dropdown';

import FormInput from '../../../../components/form-input';
import { SGForm, SGSubmitButton } from '../../../../components/sg-form';
import { withSpanelForm } from '../../../../components/sg-spanel-forms';
import FormPassword from '../../../../components/form-password';
import { Domains } from '../../types';
import { CREATE_USER_FORM_NAME, DOMAIN_ID_FORM_NAME } from '../../constants';
import { normalizePathInput } from '../../utils';

type Props = {
  change: Function;
  getFormValues: Function;
  locationState: { domainId?: number };
  domainOptions: Domains[];
  users: any;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => any;
  intl: Intl;
};

const Form = (props: Props) => {
  const { change, getFormValues, validationUtils, sPanelFormSubmit, domainOptions, locationState, intl, users } = props;

  React.useEffect(() => {
    const formValues = getFormValues(CREATE_USER_FORM_NAME);
    const domainIdToPreselect = locationState && locationState.domainId;

    if (!domainIdToPreselect) {
      return;
    }

    if (formValues[DOMAIN_ID_FORM_NAME] === domainIdToPreselect) {
      return;
    }

    change(DOMAIN_ID_FORM_NAME, domainIdToPreselect);
  }, [domainOptions.length, users]);

  const { required, validationWithMetaApi, password } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(CREATE_USER_FORM_NAME)}>
      <Column smSpan="6">
        <Field
          name={DOMAIN_ID_FORM_NAME}
          type="text"
          options={domainOptions}
          optionValue="id"
          optionLabel="name"
          label={intl.formatMessage({ id: 'translate.page.protected.domain' })}
          placeholder={intl.formatMessage({ id: 'translate.page.protected.domain' })}
          searchable
          component={FormDropdown}
          validate={[required]}
        />
      </Column>
      <Column smSpan="6">
        <Field
          name="path"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.protected.path' })}
          placeholder={intl.formatMessage({ id: 'translate.page.protected.path' })}
          normalize={(path) => normalizePathInput(path, domainOptions)}
          validate={[required, validationWithMetaApi]}
          component={FormInput}
        />
      </Column>
      <Column smSpan="6">
        <Field
          name="username"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.user' })}
          placeholder={intl.formatMessage({ id: 'translate.generic.user' })}
          component={FormInput}
          validate={[required, validationWithMetaApi]}
          normalize={(value) => value.toLowerCase()}
        />
      </Column>
      <Column smSpan="6">
        <Field
          name="password"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.password' })}
          placeholder={intl.formatMessage({ id: 'translate.generic.password.label' })}
          component={FormPassword}
          validate={[required, password]}
        />
      </Column>
      <Column smSpan="12">
        <SGSubmitButton
          label={intl.formatMessage({ id: 'translate.page.protected.protect.button' })}
          formName={CREATE_USER_FORM_NAME}
        />
      </Column>
    </SGForm>
  );
};

const ProtectedUrlsCreateForm = reduxForm({
  form: CREATE_USER_FORM_NAME,
  initialValues: {
    path: '/',
    _metaFields: {
      ...API_RESOURCE.LOCATION_USER,
      fetchItemsOnSuccess: [API_RESOURCE.LOCATION_PROTECT],
      formName: CREATE_USER_FORM_NAME
    }
  }
})(Form);

export default withSpanelForm(injectIntl(ProtectedUrlsCreateForm));
