import * as React from 'react';
import { connect } from 'react-redux';
import { AlignType, Button, Flex, FlexProps, Grid, Icon, Switch, Text, WeightType } from '@siteground/styleguide';
import { deepclone } from '@siteground/styleguide/lib/utils';
import { toggleTranslationKeys } from '../../../../core/actions/i18n';
import { pingAvalon, pingNemo, stopPingAvalon, stopPingNemo } from '../../../../core/actions/ping-backend';
import { RootState } from '../../../reducers';

type TextProps = {
  tag: string;
  align: AlignType;
  weight: WeightType;
};

type StateProps = {
  store: RootState;
  isAvalonWorking: boolean;
  isNemoWorking: boolean;
  showI18N: boolean;
};

type DispatchProps = {
  pingAvalon: typeof pingAvalon;
  stopPingAvalon: typeof stopPingAvalon;
  pingNemo: typeof pingNemo;
  stopPingNemo: typeof stopPingNemo;
  toggleTranslationKeys: typeof toggleTranslationKeys;
};

type OwnProps = {};

interface Props extends StateProps, DispatchProps, OwnProps {}

const isNotProduction = process.env.APP_ENV !== 'production' && process.env.APP_ENV !== 'resellerproduction';

class DebugDialogContent extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.pingAvalon();
    this.props.pingNemo();
  }

  componentWillUnmount() {
    this.props.stopPingAvalon();
    this.props.stopPingNemo();
  }

  getStoreToDownload = () => {
    const { store } = this.props;
    const modifiedStore = deepclone(store);

    return {
      ...modifiedStore,
      session: null,
      i18n: null
    };
  };

  downloadStore = () => {
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.getStoreToDownload()))
    );
    link.setAttribute('download', 'store.txt');

    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  render() {
    const { isAvalonWorking, isNemoWorking, showI18N, toggleTranslationKeys } = this.props;
    const flexProps: Partial<FlexProps> = {
      align: 'center'
    };
    const textProps: TextProps = {
      tag: 'span',
      align: 'left',
      weight: 'bold'
    };

    return (
      <Grid align="flex-start">
        <Grid gap="xx-small">
          <Flex {...flexProps}>
            <Text {...textProps}>
              Build:&nbsp;
              <Text tag="span">v{process.env.VERSION}</Text>
              {isNotProduction && process.env.BRANCH_NAME && (
                <Text tag="span">&nbsp;from {process.env.BRANCH_NAME}</Text>
              )}
              <Text tag="span">&nbsp;at {new Date(process.env.BUILD_DATE).toLocaleString()}</Text>
            </Text>
          </Flex>

          <Flex {...flexProps}>
            <Text {...textProps}>
              StyleGuide:&nbsp;
              <Text tag="span">v{process.env.STYLE_GUIDE_VERSION}</Text>
            </Text>
          </Flex>

          <Flex {...flexProps}>
            <Text {...textProps}>
              Environment:&nbsp;
              <Text tag="span">{process.env.NODE_ENV}</Text>
            </Text>
          </Flex>

          <Flex {...flexProps}>
            <Text {...textProps}>
              User Agent:&nbsp;
              <Text tag="span">{navigator.userAgent}</Text>
            </Text>
          </Flex>
        </Grid>

        <div>
          <Flex align="center">
            <div style={{ textAlign: 'left', minWidth: '50px' }}>
              <Text tag="span" weight="bold">
                Avalon:{' '}
              </Text>
            </div>
            <Icon name="dot" size="26" color={isAvalonWorking ? 'success' : 'error'} />
            <Text tag="span">{isAvalonWorking ? 'Available' : 'Not Available'}</Text>
          </Flex>

          <Flex align="center">
            <div style={{ textAlign: 'left', minWidth: '50px' }}>
              <Text tag="span" weight="bold">
                Nemo:{' '}
              </Text>
            </div>
            <Icon name="dot" size="26" color={isNemoWorking ? 'success' : 'error'} />
            <Text tag="span">{isNemoWorking ? 'Available' : 'Not Available'}</Text>
          </Flex>
        </div>

        <Flex justify="flex-start">
          <Button color="primary" size="small" onClick={() => this.downloadStore()}>
            Download store
          </Button>
        </Flex>

        {isNotProduction && (
          <Switch label="Show Translation Keys" checked={!showI18N} onChange={toggleTranslationKeys} />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
  isAvalonWorking: state.ping.isAvalonWorking,
  isNemoWorking: state.ping.isNemoWorking,
  showI18N: state.i18n.showMessages
});

const mapDispatchToProps = (dispatch) => ({
  pingAvalon: () => dispatch(pingAvalon()),
  stopPingAvalon: () => dispatch(stopPingAvalon()),
  pingNemo: () => dispatch(pingNemo()),
  stopPingNemo: () => dispatch(stopPingNemo()),
  toggleTranslationKeys: () => dispatch(toggleTranslationKeys())
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(DebugDialogContent);
