import * as React from 'react';
import { injectIntl } from 'react-intl';
import { DialogSearch, Flex, Icon, Text, Title } from '@siteground/styleguide';
import { getNavigationInfo, getPageInfo, ToolId } from '../../../core/constants/route-info';
import { ROUTES } from '../../../core/constants/routes';
import { groups } from '../../core/constants/menu-items';
import { MenuItemST } from '../../../core/definitions/menu-items';

import './tool-finder.scss';

interface ToolFinderProps {
  handleClose: any;
  router: any;
  state?: any;
  intl: Intl;
}

interface ToolFinderState {
  searchValue: string;
}

class ToolFinder extends React.Component<ToolFinderProps, ToolFinderState> {
  readonly state = {
    searchValue: ''
  };

  getParentGroup = (toolId: MenuItemST['toolId']) => {
    return (groups as any).find((group) => group.items.find((item: MenuItemST) => item.toolId === toolId));
  };

  filterList(list, searchValue) {
    // dont want to calculate factorial on runtime - performance, performance, performance
    return list
      .filter((item) => {
        const stripRegEx = /[^A-Z\s0-9]/gi;
        const stripBackslashRegEx = /\\/g;
        const { title } = getPageInfo(item.toolId);

        if (item.pageEndpoint === undefined) {
          console.warn('Please define pageEndpoint', item);
          return false;
        }

        if (title === undefined) {
          console.error('Missing title', item, title);
          return;
        }

        const strippedItem = title.replace(stripRegEx, '').toLowerCase();
        const searchValueStripped = searchValue.replace(stripBackslashRegEx, '/').toLowerCase();
        item.ranking = 0;
        item.tagsFound = {};
        // Exact title match
        if (strippedItem === searchValueStripped) {
          item.ranking = item.ranking < 3 ? 3 : item.ranking;
          // Partial title match
        } else if (strippedItem.search(searchValueStripped) !== -1) {
          item.ranking = item.ranking < 2 ? 2 : item.ranking;
        } else if (item.keywords) {
          let i = item.keywords.length;
          for (const word of item.keywords) {
            /**
             * Keywords Scores Formula
             * 1. encrease with keywordRanking (position in the array)
             * 2. decrease with keywordsArrayLength
             */
            const keyword = word.toLocaleLowerCase();
            const keywordRanking = i / item.keywords.length;
            const keywordScoresToAdd = keywordRanking / item.keywords.length;

            // keyword match both "search value in keyword" and "keyword in search value"
            if (searchValueStripped.search(keyword) !== -1 || keyword.search(searchValueStripped) !== -1) {
              item.tagsFound[keyword] = '(' + i + '/' + item.keywords.length + ')' + '/' + item.keywords.length;
              item.ranking = item.ranking < 1 ? item.ranking + keywordScoresToAdd : item.ranking;
            }
            i = i - 1;
          }
        }
        return item.ranking;
      })
      .sort((toolA, toolB) => toolB.ranking - toolA.ranking);
  }

  handleNavigateAction(toolId) {
    this.props.router.push(`${ROUTES[toolId]}${location.search}`);
    this.closeHandler();
  }

  closeHandler = () => {
    this.props.handleClose();
  };

  template = (row: MenuItemST) => {
    const { intl } = this.props;
    const { title } = getPageInfo(row.toolId);
    const parentGroup = this.getParentGroup(row.toolId);
    const navigationInfo = getNavigationInfo(parentGroup.toolId);

    return (
      <div
        className="tool-finder__searched-item"
        onClick={() => {
          this.handleNavigateAction(row.toolId);
        }}
      >
        <Flex gutter="medium" direction="row" align="center">
          <Title level="4">{intl.formatMessage({ id: title })}</Title>
          <Icon name="dot" size="10" />
          <Text size="small" color="dark">
            {intl.formatMessage({ id: navigationInfo.title })}
          </Text>
        </Flex>
      </div>
    );
  };

  render() {
    const { intl } = this.props;
    const data = groups.reduce((a, b) => a.concat(b.items), []);

    return (
      <DialogSearch
        placeholder={intl.formatMessage({ id: 'translate.tool.finder.input.placeholder' })}
        data={data}
        onCloseHandler={this.closeHandler}
        handleEnter={(row) => this.handleNavigateAction(row.toolId)}
        customFilter={this.filterList}
        indexKey="title"
        template={this.template}
        noResultsText={intl.formatMessage({ id: 'translate.tool.finder.no-results.text' })}
      />
    );
  }
}

export default injectIntl(ToolFinder);
