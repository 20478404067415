import * as React from 'react';
import { useIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import CreateBox from '../../../../components/create-box';
import VCS from '../../../../components/visibility-control-service';

type Props = {
  children: any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.PGSQL_ACCESS;
const formName = REDUX_FORM.CREATE_PGSQL_ACCESS;

const CreateContainer = ({ children }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.database_access.create.title' })}
        resourceName={resourceName}
        formName={formName}
        resources={[
          {
            resourceName: API_RESOURCE.PGSQL_ACCESS.resourceName,
            methods: ['POST']
          }
        ]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default CreateContainer;
