import * as React from 'react';
import { useIntl } from 'react-intl';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';
import { API_RESOURCE } from '../../../../core/constants/api';

type Props = {
  children: any;
  renderBeforeBoxChildren: any;
};

const BoxContainer = ({ children, renderBeforeBoxChildren }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={API_RESOURCE.DOMAIN.resourceName} hasMethod="GET">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.php-manager.domain-select-box.title' })}
        formName={REDUX_FORM.PHP_SELECT_DOMAIN_LIVE_SITES}
        renderBeforeBoxChildren={renderBeforeBoxChildren}
        resources={[
          {
            resourceName: API_RESOURCE.DOMAIN.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.PHP_LOCATION_INI_SETTINGS.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.PHP_LOCATION_INI.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.USERVICE.resourceName,
            methods: ['GET']
          }
        ]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default BoxContainer;
