import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from '@siteground/styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

type Props = {
  children: any;
  openSGDialog?: Function;
};

const formName = REDUX_FORM.CREATE_GIT;
const { resourceName, resourceNameMetaApi } = API_RESOURCE.APP_REPO;

const CreateContainer = ({ children, openSGDialog }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.git.create.title' })}
        resourceName={resourceName}
        formName={formName}
        resources={[
          { resourceName: API_RESOURCE.APP.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.APP_REPO.resourceName, methods: ['POST'] }
        ]}
        renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
          if (notification.state === 'error') {
            return renderDefaultNotificationTemplate();
          }

          return (
            <div style={{ textAlign: 'center' }}>
              <Button
                data-e2e="notification-git-info-button"
                color="secondary"
                type="outlined"
                onClick={() => openSGDialog(DIALOGS.GIT_VIEW_REPO, notification.responseData)}
              >
                {intl.formatMessage({ id: 'translate.page.git.create.button.info' })}
              </Button>

              {renderDefaultNotificationTemplate()}
            </div>
          );
        }}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

export default connect<{}, {}, Props>(undefined, mapDispatchToProps)(CreateContainer);
