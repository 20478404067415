import { put, select, takeEvery } from 'redux-saga/effects';
import { fileManagerPostRequest } from '../actions/file-manager';
import { FILE_MANAGER_EXTRACT_DIR, FILE_MANGER_API_DIR_EXTRACT } from '../constants/actions';
import { getPrioritizedEntities } from '../selectors/get-prioritized-entities';
import { getEntityPath, getEntityReadableName } from '../utils';

function* extractDir() {
  const { fileManager } = yield select();
  const prioritizedEntity = getPrioritizedEntities(fileManager);

  for (const entity of prioritizedEntity) {
    yield put(
      fileManagerPostRequest({
        endpoint: FILE_MANGER_API_DIR_EXTRACT,
        urlParams: {
          id: getEntityPath(entity),
          dest: getEntityPath(entity).replace(/\.[^\.]+$/, '')
        },
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.file.manager.extract.dir.success.message',
              intlValues: { name: getEntityReadableName(entity) }
            },
            error: {
              intlKey: 'translate.file.manager.extract.dir.error.message',
              intlValues: { name: getEntityReadableName(entity) }
            }
          }
        }
      })
    );
  }
}

function* extract(): any {
  yield takeEvery(FILE_MANAGER_EXTRACT_DIR, extractDir);
}

export default extract;
