type Params = {
  siteMetaApi: {
    endpoints: any[];
  };
  resourceNameMetaApi: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
};

export const canDoCRUD = ({ siteMetaApi, resourceNameMetaApi, method }: Params) => {
  if (!siteMetaApi) {
    return false;
  }

  const endpointInfo = siteMetaApi.endpoints && siteMetaApi.endpoints[resourceNameMetaApi];

  if (!endpointInfo) {
    return false;
  }

  const result = endpointInfo && endpointInfo.methods && endpointInfo.methods[method];

  if (!result) {
    console.warn(`Request for ${resourceNameMetaApi} was stopped`);
  }

  return result;
};
