import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Box, ButtonsGroup, Flex, Grid, Link, Text } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../../core/constants/common';
import FormCheckbox from '../../../../components/form-checkbox';
import ErrorNotice from '../../../../components/form-error-notice';
import FormInput from '../../../../components/form-input';
import FormPassword from '../../../../components/form-password';
import { withSpanelForm } from '../../../../components/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  initialValues: {
    terms: boolean;
    actionType: 'CREATE' | 'CONNECT';
    _metaFields: any;
  };
  validationUtils: ValidationUtils;
  change: Function;
  submitFailed: boolean;
  getFormErrors: Function;
  getFormValues: Function;
};

type State = {
  activeButtonGroupIndex: number;
};

const formName = REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT;
export const FORM_ACTIONS = ['CREATE', 'CONNECT'];

export class Account extends React.Component<Props, State> {
  render() {
    const { intl, validationUtils, getFormValues, getFormErrors, submitFailed, change } = this.props;
    const { actionType } = getFormValues(REDUX_FORM.CLOUDFLARE_CONNECT_ACCOUNT);
    const isCreateAccountPressed = actionType === FORM_ACTIONS[0];
    const formErrors = getFormErrors(formName);
    const errors = formErrors.terms
      ? [
          {
            field: 'terms',
            label: intl.formatMessage({ id: 'translate.page.cloudflare.landing.connect.account.error.notice.terms' })
          }
        ]
      : [];

    return (
      <Grid>
        {submitFailed && (
          <ErrorNotice
            title={intl.formatMessage({ id: 'translate.page.backup.restore.error.notice.title' })}
            errors={errors}
          />
        )}

        <Flex justify="center">
          <ButtonsGroup
            activeIndex={FORM_ACTIONS.indexOf(actionType)}
            buttons={[
              intl.formatMessage({ id: 'translate.cloudflare.create.new' }),
              intl.formatMessage({ id: 'translate.cloudflare.connect.existing' })
            ]}
            color="secondary"
            onChange={(index) => change('actionType', FORM_ACTIONS[index])}
          />
        </Flex>

        <Text align="left">
          {intl.formatMessage({
            id: isCreateAccountPressed
              ? 'translate.page.cloudflare.landing.create.account.dialog.text'
              : 'translate.page.cloudflare.landing.connect.account.dialog.text'
          })}
        </Text>

        <Field
          name="email"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.email' })}
          component={FormInput}
          validate={[validationUtils.required, validationUtils.email]}
        />

        {isCreateAccountPressed ? (
          <Field
            name="password"
            label={intl.formatMessage({ id: 'translate.generic.password' })}
            generate={isCreateAccountPressed}
            component={FormPassword}
            validate={[validationUtils.required, validationUtils.password]}
          />
        ) : (
          <Field
            name="password"
            type="password"
            label={intl.formatMessage({ id: 'translate.generic.password' })}
            component={FormInput}
            validate={[validationUtils.required]}
          />
        )}

        {isCreateAccountPressed && (
          <Field name="terms" component={FormCheckbox} validate={[validationUtils.required]}>
            <FormattedMessage
              id="translate.page.cloudflare.landing.connect.account.terms.of.service"
              values={{
                link: (
                  <Link href="https://www.cloudflare.com/terms" target="_blank">
                    <FormattedMessage id="translate.page.cloudflare.terms.of.service" />
                  </Link>
                )
              }}
            />
          </Field>
        )}
      </Grid>
    );
  }
}

export default withSpanelForm(reduxForm({ form: formName })(injectIntl(Account)));
