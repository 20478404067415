import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { REDUX_FORM } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';

import { SGDialogForm } from '../../../components/sg-dialog';
import { fileManagerPostRequest } from '../../core/actions/file-manager';
import * as Constants from '../../core/constants/actions';
import { RenameDialogPayload } from '../../core/selectors/get-rename-dialog-payload';
import { getEntityParentPath, getEntityPath, getEntityReadableName } from '../../core/utils';
import RenameEntityForm from './rename-form';

type Props = {
  closeSGDialog: typeof closeSGDialog;
  intl: Intl;
  fileManagerPostRequest: typeof fileManagerPostRequest;
  renameDialogPayload: RenameDialogPayload;
};

type DispatchProps = {
  intl: Intl;
};

const RenameDialog = ({ closeSGDialog, intl, fileManagerPostRequest, renameDialogPayload }: Props & DispatchProps) => {
  const { prioritizedEntities } = renameDialogPayload;

  const entity = prioritizedEntities && prioritizedEntities[0];
  const entityName = getEntityReadableName(entity);
  const entityPath = getEntityPath(entity);
  const entityParentPath = getEntityParentPath(entity);

  return (
    <SGDialogForm
      name={REDUX_FORM.FILE_MANAGER_RENAME_ENTITY}
      icon="rename"
      title={intl.formatMessage({ id: 'translate.file.manager.rename.dialog.title' }, { entityName })}
      resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_POST }]}
    >
      {entity && (
        <RenameEntityForm
          initialValues={{
            id: entityName,
            _metaFields: {
              resourceNameMetaApi: 'dir-rename',
              endpoint: Constants.FILE_MANGER_API_DIR_RENAME
            }
          }}
          onSubmit={({ id }) =>
            fileManagerPostRequest(
              {
                endpoint: Constants.FILE_MANGER_API_DIR_RENAME,
                urlParams: {
                  id: entityPath,
                  safe: 1,
                  dest: `${entityParentPath}/${id}`
                },
                entity,
                clearFileManagerStore: {
                  // should be clear, because selectedContentEntities is not updated with dir-fetch
                  selectedContentEntities: []
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.file.manager.rename.dir.success.message'
                    },
                    error: {
                      intlKey: 'translate.file.manager.rename.dir.error.message'
                    }
                  }
                }
              },
              () => closeSGDialog(REDUX_FORM.FILE_MANAGER_RENAME_ENTITY)
            )
          }
        />
      )}
    </SGDialogForm>
  );
};

const mapDispatchToProps = {
  closeSGDialog,
  fileManagerPostRequest
};

export default connect<{}, any, Partial<Props>>(
  (state) => ({
    renameDialogPayload: state.dialog[REDUX_FORM.FILE_MANAGER_RENAME_ENTITY] || {}
  }),
  mapDispatchToProps
)(injectIntl(RenameDialog));
