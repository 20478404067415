import { Domains } from './types';

export const matchExistingDomain = (path: string, domains: Domains[]) => {
  const domain = domains.find(({ name }) => new RegExp(name, 'i').test(path));

  if (domain) {
    const [matchedString, protocol, www, domainFound, relativePath] = new RegExp(
      `(https?:\/\/)?(www.)?(${domain.name}\/)(.+)?`,
      'i'
    ).exec(path);

    return `/${relativePath}`;
  }

  return path;
};

export const normalizePathInput = (path: string, domains: Domains[] = []) => {
  const modifiedPath = matchExistingDomain(path, domains);

  if (modifiedPath.charAt(0) === '/') {
    return modifiedPath;
  }

  return `/${modifiedPath}`;
};
