import * as React from 'react';
import { Title, TitleProps } from '@siteground/styleguide';

const SecondLevelTitle = ({ children, className, density = 'cozy', style, id }: Partial<TitleProps>) => {
  return (
    <Title id={id} className={className} level="4" tag="h2" density={density} weight="medium" style={style}>
      {children}
    </Title>
  );
};

export default SecondLevelTitle;
