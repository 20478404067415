import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ButtonsGroup, Flex, IconButton, Spacer, Text, Toolbar } from '@siteground/styleguide';
import { RootState } from '../../reducers';
import ActionToolbar from '../action-toolbar';
import { clearContextEntities, onEntityClick, selectContentRows, toggleCodeEditor } from '../core/actions/file-manager';
import { getEntityByPath, getEntityParentPath, getEntityReadableName, shouldRenderMobile } from '../core/utils';
import { FileManagerState, CodeEditor, CommonFMProps, FMEntity } from '../types';
import './header.scss';

interface Props extends CommonFMProps {
  clearContextEntities: typeof clearContextEntities;
  onEntityClick: typeof onEntityClick;
  selectContentRows: typeof selectContentRows;
  toggleCodeEditor: typeof toggleCodeEditor;
  selectedContentEntities: FMEntity[];
  fileManagerState: FileManagerState;
  codeEditor: CodeEditor;
  isSearchVisible: boolean;
}

type DispatchProps = {
  intl: Intl;
};

class FileManagerHeader extends React.Component<Props & DispatchProps> {
  render() {
    const { environment } = this.props;

    return (
      <Toolbar density="compact" align="center" className="file-manager-header">
        {shouldRenderMobile(environment) ? this.renderMobile() : this.renderDesktop()}
      </Toolbar>
    );
  }

  renderMobile() {
    const {
      clearContextEntities,
      onEntityClick,
      fileManagerState,
      selectedNavigationEntity,
      selectedContentEntities,
      selectContentRows
    } = this.props;

    const selectedNavigationEntityPath = selectedNavigationEntity && getEntityReadableName(selectedNavigationEntity);
    const entity = selectedNavigationEntity
      ? getEntityByPath(getEntityParentPath(selectedNavigationEntity), fileManagerState)
      : null;
    const count = selectedContentEntities.length;

    if (count > 1) {
      return (
        <Flex gutter="medium" justify="space-between" style={{ width: '100%' }} data-e2e="mobile-header">
          <Flex align="center">
            <IconButton
              aria-label={this.props.intl.formatMessage({ id: 'translate.aria-label.deselect' })}
              icon="cross"
              size="small"
              shape="circle"
              onClick={() => {
                selectContentRows([]);
                clearContextEntities();
              }}
            />

            <Text color="light" style={{ marginLeft: '10px' }}>
              {this.props.intl.formatMessage({ id: 'translate.file.manager.toolbar.selected.entities' }, { count })}
            </Text>
          </Flex>

          <IconButton
            aria-label={this.props.intl.formatMessage({ id: 'translate.aria-label.actions' })}
            icon="dots"
            onClick={(event) => {
              event.stopPropagation();
              event.nativeEvent.stopImmediatePropagation();
              this.props.openContextMenu(event);
            }}
          />
        </Flex>
      );
    }

    return (
      <React.Fragment>
        {selectedNavigationEntityPath ? (
          <IconButton
            aria-label={this.props.intl.formatMessage({ id: 'translate.aria-label.arrow.back' })}
            key="back-button"
            icon="arrow-back"
            shape="circle"
            onClick={() => onEntityClick({ entity })}
            data-e2e="back-button"
          />
        ) : (
          <Spacer size="x-small" />
        )}
        <Spacer size={selectedNavigationEntityPath ? 'x-small' : 'x-large'} />
        {selectedNavigationEntityPath ? selectedNavigationEntityPath : '/'}
      </React.Fragment>
    );
  }

  renderDesktop() {
    const { codeEditor, codeEditorIsVisible, intl, toggleCodeEditor, isSearchVisible } = this.props;
    const activeIndex = codeEditorIsVisible ? 1 : 0;
    const buttons = [
      intl.formatMessage({ id: 'translate.generic.explorer' }),
      intl.formatMessage({ id: 'translate.generic.editor' })
    ];

    return (
      <React.Fragment>
        <ButtonsGroup
          activeIndex={activeIndex}
          buttons={buttons}
          color="secondary"
          size="small"
          onChange={toggleCodeEditor}
          disabled={isSearchVisible}
          data-e2e="explorer-editor-switch"
        />

        <ActionToolbar codeEditor={codeEditor} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  clearContextEntities,
  onEntityClick,
  selectContentRows,
  toggleCodeEditor
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({ ...fileManagerActions }, dispatch)
// });

const mapStateToProps = (state: RootState) => ({
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible,
  environment: state.environment,
  fileManagerState: state.fileManager,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  selectedContentEntities: state.fileManager.selectedContentEntities,
  isSearchVisible: state.fileManager.search.isSearchVisible
});

export default connect<{}, any, Partial<Props>>(mapStateToProps, mapDispatchToProps)(injectIntl(FileManagerHeader));
