import * as React from 'react';

type LoadMoreSettings = {
  id: string;
  loadStep: number;
  initialLength: number;
  loaded?: number;
};

type State = {
  loadMoreState: {
    [id: string]: LoadMoreSettings;
  };
};

export const withLoadMore = function (Wrapped, ...loadMoreSettings: LoadMoreSettings[]) {
  return class WithLoadMoreWrapper extends React.Component<any, State> {
    constructor(props) {
      super(props);
      this.state = {
        loadMoreState: loadMoreSettings.reduce((output, item: LoadMoreSettings) => {
          return {
            ...output,
            [item.id]: {
              ...item,
              loaded: item.initialLength
            }
          };
        }, {})
      };
    }

    render() {
      const { loadMoreState } = this.state;

      return (
        <Wrapped
          {...this.props}
          onLoadMore={(id: string) => {
            if (loadMoreState[id]) {
              return this.setState({
                loadMoreState: {
                  ...loadMoreState,
                  [id]: {
                    ...loadMoreState[id],
                    loaded: loadMoreState[id].loaded + loadMoreState[id].loadStep
                  }
                }
              });
            }
          }}
          getAllLoaded={(id: string, items: any[] = []) => {
            if (loadMoreState[id]) {
              return [...items].splice(0, loadMoreState[id].loaded);
            }

            return items;
          }}
          shouldShowLoadMore={(id: string, items: any[] = []) => {
            return Boolean(loadMoreState[id] && loadMoreState[id].loaded < items.length);
          }}
        />
      );
    }
  };
};

export default withLoadMore;
