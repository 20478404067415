import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { ToolId } from '../constants/route-info';

export type UserPreferences = {
  theme: string;
  easy_read_font: 0 | 1;
  sitetools: {
    dashboard: {
      tiles: ToolId[];
    };
  };
};

export interface Session {
  clientRefreshToken: string;
  clientToken: string;
  currentSiteId: string;
  siteTokens: any;
  user: {
    language: string;
    preferences: UserPreferences;
  };
  siteUnavailable: boolean;
}

export const sessionInitialState = {
  clientRefreshToken: null,
  clientToken: null,
  siteTokens: {},
  user: {
    preferences: {
      theme: 'light',
      easy_read_font: 0,
      sitetools: {
        dashboard: {
          tiles: [ToolId.appManager, ToolId.email, ToolId.filemanager, ToolId.cloudflare]
        }
      }
    }
  },
  siteUnavailable: false
};

const reducerMap: any = {
  [Actions.STORE_SESSION_DATA]: (state: Session, { payload }) => {
    const { session } = payload;

    return {
      ...state,
      clientRefreshToken: session.data.refresh_token,
      clientToken: session.data.client_token,
      user: session.data.profile || {}
    };
  },

  [Actions.STORE_SESSION_TOKEN]: (state: Session, { token }) => ({
    ...state,
    clientToken: token.clientToken,
    clientRefreshToken: token.refreshToken
  }),

  [Actions.STORE_SESSION_PROFILE]: (state: Session, { user }) => ({
    ...state,
    user
  }),

  [Actions.REFRESH_CLIENT_TOKEN_SUCCEEDED]: (state: Session, action: { session: APIResponseClientToken }) => ({
    ...state,
    clientToken: action.session.data.client_token
  }),

  [Actions.UPDATE_USER_LANGUAGE]: (state: Session, { payload }) => ({
    ...state,
    user: {
      ...state.user,
      language: payload.language
    }
  }),

  [Actions.UPDATE_USER_PREFERENCES]: (state: Session, { payload }) => ({
    ...state,
    user: {
      ...state.user,
      preferences: {
        ...payload
      }
    }
  }),

  [Actions.CLEAR_SESSION_DATA]: (state) => ({ ...sessionInitialState }),

  [Actions.LOGOUT]: (state: Session) => {
    // keep user's language in order to pass the lang parameter by URL on user logout
    return {
      ...sessionInitialState,
      user: {
        language: state.user.language
      }
    };
  },

  [Actions.SAVE_SITE_TOKEN]: (state, { payload }) => {
    const { siteId } = payload;
    const token: APIResponseSiteToken = payload.siteToken;

    return {
      ...state,
      siteTokens: {
        [siteId]: {
          localSiteId: token.data.local_site_id,
          siteToken: token.data.site_token
        }
      },
      siteUnavailable: false
    };
  },

  [Actions.REFRESH_SITE_TOKEN_FAILED]: (state, { payload }) => {
    const { siteId } = payload;

    return {
      ...state,
      siteTokens: {
        [siteId]: {
          localSiteId: null,
          siteToken: null
        }
      },
      siteUnavailable: true
    };
  },
  [Actions.PAGE_LOAD_FAILED]: (state, { payload }) => {
    return {
      ...state,
      siteUnavailable: true
    };
  },
  [Actions.LOGOUT_FROM_WEBAPP]: (state) => ({ ...sessionInitialState })
};

export default handleActions<any, any>(reducerMap, sessionInitialState);
