import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Icon, Link, Section } from '@siteground/styleguide';
import { openNewTabAction } from '../../../core/actions/open-new-tab';
import { requestData } from '../../../core/actions/request-data';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { createTaskLoaderMetadata } from '../../../core/common/create-metadata';
import { API_RESOURCE } from '../../../core/constants/api';
import { APP_TYPES } from '../../../core/constants/application';
import { LocalTaskLoaderType, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { ToolId } from '../../../core/constants/route-info';
import { filterStagingDomains, getWordpressApps } from '../../../core/selectors';
import {
  ascendingSortByURLWithoutProtocol,
  ascendingSortByVersion,
  descendingSortByURLWithoutProtocol,
  descendingSortByVersion
} from '../../../core/utils/sort';
import DeleteInstalledWPApp from '../../components/common-forms/delete-installed-wp-app';
import AddExistingApp from '../../components/dialogs/add-existing-app';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import { SGDialogForm } from '../../components/sg-dialog';
import SGTable from '../../components/sg-table';
import { TableHeaderSort } from '../../components/sg-table-sort';
import TableContextMenu from '../../components/table-context-menu';
import VCS from '../../components/visibility-control-service';
import { RootState } from '../../reducers';

import { FIELDS } from '../app-manager/constants';
import { CONTEXT_MENU_ACTION, WORDPRESS_WOOCOMMERCE } from './constants';
import { CreateBox, CreateForm } from './create';
import { ChangePasswordForm, FixPermissionsForm, MoveApplicationForm } from './dialogs';

interface Props {
  actions: CrudActions;
  appInstallDomains: any[];
  location: any;
  items: any[];
  wordpressApps: WordpressApp[];
  session: {
    user: {
      language;
    };
  };
  intl: Intl;
  requestData: typeof requestData;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  openNewTabAction: typeof openNewTabAction;
}

interface State {
  contextMenuAction: string;
  selectedApplication: WordpressApp;
  selectedInstallation: string;
}

class WPManagerPage extends React.Component<Props, State> {
  readonly state = {
    contextMenuAction: null,
    selectedApplication: null,
    selectedInstallation: ''
  };

  getAppName = (app: WordpressApp) => {
    const { intl } = this.props;

    return app.plugins && app.plugins.includes('woocommerce')
      ? intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.wordpress.and.woo' })
      : intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.wordpress' });
  };

  onContextMenuClick = (action: string, app: WordpressApp) => {
    const { openSGDialog, requestData } = this.props;

    const state = {
      contextMenuAction: action,
      selectedApplication: app
    };

    switch (action) {
      case CONTEXT_MENU_ACTION.LOG_IN_TO_ADMIN_PANEL:
        return requestData(
          {
            payload: {
              _metaFields: {
                endpoint: `${API_RESOURCE.WORDPRESS.endpoint}/${app.id}`,
                method: 'PUT',
                itemId: app.id
              },
              urlParams: {
                auto_login: 1
              }
            },
            requestTypeName: customRequestTypes.GET_WP_LOGIN_URL
          },
          ({ autologin_url }) => this.props.openNewTabAction({ url: autologin_url })
        );
      case CONTEXT_MENU_ACTION.CHANGE_ADMIN_PASSWORD:
        return this.setState(state, () => openSGDialog(REDUX_FORM.CHANGE_WP_MANAGE_PASSWORD_DIALOG));
      case CONTEXT_MENU_ACTION.FIX_PERMISSIONS:
        return this.setState(state, () => openSGDialog(REDUX_FORM.CHANGE_WP_MANAGE_FIX_PERMISSIONS_DIALOG));
      case CONTEXT_MENU_ACTION.MOVE:
        return this.setState(state, () => openSGDialog(REDUX_FORM.CHANGE_WP_MANAGE_MOVE_DIALOG));
      case CONTEXT_MENU_ACTION.DELETE:
        return this.setState(state, () => openSGDialog(REDUX_FORM.DELETE_INSTALLED_WP_APP));
      default:
        return null;
    }
  };

  onCreateFormSubmit = (formData) => {
    const domain = this.props.appInstallDomains.find((d) => d.id === formData.domain_id);
    const url = domain && domain.name ? `${domain.name}/${formData.path || ''}` : formData.path || '';

    formData.path_option = undefined;
    if (!formData.path) {
      formData.path = '/';
    }

    this.props.actions.createItem({
      ...formData,
      _meta: {
        ...createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX),
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_WP_MANAGE,
          success: {
            intlKey: 'translate.page.wp.manage.create.success.title',
            intlValues: { url }
          },
          error: {
            intlKey: 'translate.page.wp.manage.create.error.title',
            intlValues: { url }
          }
        }
      }
    });
  };

  renderContextMenuDialog = () => {
    const { actions, appInstallDomains, intl, closeSGDialog } = this.props;
    const { contextMenuAction, selectedApplication } = this.state;

    switch (contextMenuAction) {
      case CONTEXT_MENU_ACTION.CHANGE_ADMIN_PASSWORD:
        return (
          <SGDialogForm
            name={REDUX_FORM.CHANGE_WP_MANAGE_PASSWORD_DIALOG}
            icon="lock"
            title={intl.formatMessage(
              { id: 'translate.page.wp.manage.change.password.title' },
              { url: selectedApplication.app_url }
            )}
            resources={[
              { resourceName: API_RESOURCE.WORDPRESS_USER.resourceName, methods: ['GET'] },
              { resourceName: API_RESOURCE.WORDPRESS_USER.resourceName, methods: ['PUT'] }
            ]}
            onCloseHandler={() => this.setState({ contextMenuAction: null })}
          >
            <ChangePasswordForm
              initialValues={{
                id: selectedApplication.id,
                _metaFields: {
                  ...API_RESOURCE.WORDPRESS_USER,
                  dontChangeItemsState: true
                }
              }}
              selectedApplication={selectedApplication}
              onSubmit={(data) =>
                actions.updateItem(
                  {
                    ...data,
                    _meta: {
                      notification: {
                        type: 'generic',
                        success: {
                          intlKey: 'translate.page.wp.manage.change.password.success.message'
                        },
                        error: {
                          intlKey: 'translate.page.wp.manage.change.password.error.message'
                        }
                      }
                    }
                  },
                  () => closeSGDialog(REDUX_FORM.CHANGE_WP_MANAGE_PASSWORD_DIALOG)
                )
              }
            />
          </SGDialogForm>
        );
      case CONTEXT_MENU_ACTION.FIX_PERMISSIONS:
        return (
          <SGDialogForm
            name={REDUX_FORM.CHANGE_WP_MANAGE_FIX_PERMISSIONS_DIALOG}
            icon="key"
            title={intl.formatMessage(
              { id: 'translate.page.wp.manage.fix.permissions.title' },
              { url: selectedApplication.app_url }
            )}
            resources={[{ resourceName: API_RESOURCE.WORDPRESS.resourceName, methods: ['PUT'] }]}
            onCloseHandler={() => this.setState({ contextMenuAction: null })}
          >
            <FixPermissionsForm
              initialValues={{
                fix_perms: 1,
                id: selectedApplication.id,
                _metaFields: {
                  ...API_RESOURCE.WORDPRESS
                }
              }}
              selectedApplication={selectedApplication}
              onSubmit={(data) =>
                actions.updateItem(
                  {
                    ...data,
                    _meta: {
                      notification: {
                        type: 'generic',
                        success: {
                          intlKey: 'translate.page.wp.manage.fix.permissions.success.message'
                        },
                        error: {
                          intlKey: 'translate.page.wp.manage.fix.permissions.error.message'
                        }
                      }
                    }
                  },
                  () => closeSGDialog(REDUX_FORM.CHANGE_WP_MANAGE_FIX_PERMISSIONS_DIALOG)
                )
              }
            />
          </SGDialogForm>
        );
      case CONTEXT_MENU_ACTION.MOVE:
        return (
          <SGDialogForm
            name={REDUX_FORM.CHANGE_WP_MANAGE_MOVE_DIALOG}
            icon="move-to"
            size="large"
            title={intl.formatMessage(
              { id: 'translate.page.wp.manage.move.installation.title' },
              { url: selectedApplication.app_url }
            )}
            resources={[{ resourceName: API_RESOURCE.WORDPRESS.resourceName, methods: ['PUT'] }]}
            onCloseHandler={() => this.setState({ contextMenuAction: null })}
          >
            <MoveApplicationForm
              initialValues={{
                id: selectedApplication.id,
                move_to: selectedApplication.path,
                _metaFields: {
                  fetchItemsOnSuccess: [API_RESOURCE.APP],
                  ...API_RESOURCE.WORDPRESS
                }
              }}
              domain={appInstallDomains}
              selectedApplication={selectedApplication}
              onSubmit={(data) =>
                actions.updateItem(
                  {
                    ...data,
                    _meta: {
                      notification: {
                        type: 'generic',
                        success: {
                          intlKey: 'translate.page.wp.manage.move.installation.success.message'
                        },
                        error: {
                          intlKey: 'translate.page.wp.manage.move.installation.error.message'
                        }
                      }
                    }
                  },
                  () => closeSGDialog(REDUX_FORM.CHANGE_WP_MANAGE_MOVE_DIALOG)
                )
              }
            />
          </SGDialogForm>
        );
      case CONTEXT_MENU_ACTION.DELETE:
        return (
          <SGDialogForm
            name={REDUX_FORM.DELETE_INSTALLED_WP_APP}
            icon="trash"
            state="inactive"
            title={intl.formatMessage(
              { id: 'translate.page.wp.manage.delete.title' },
              { url: selectedApplication.app_url }
            )}
            onCloseHandler={() => this.setState({ contextMenuAction: null })}
          >
            <DeleteInstalledWPApp
              selectedApplication={selectedApplication}
              onSubmit={({ keep_db, keep_stagings, ...formData }: DeleteItemPayload) => {
                actions.deleteItem({
                  ...formData,
                  itemId: selectedApplication.id,
                  bodyParams: {
                    delete_db: Number(!Boolean(keep_db)),
                    full_delete: Number(!Boolean(keep_stagings))
                  },
                  _meta: {
                    notification: {
                      type: 'generic',
                      success: {
                        intlKey: 'translate.page.wp.manage.delete.success.message'
                      },
                      error: {
                        intlKey: 'translate.page.wp.manage.delete.error.message'
                      }
                    }
                  }
                });

                closeSGDialog(REDUX_FORM.DELETE_INSTALLED_WP_APP);
              }}
            />
          </SGDialogForm>
        );
      default:
        return null;
    }
  };

  renderContextMenu = (id, entity) => {
    const { intl } = this.props;

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.WORDPRESS.resourceName}
        items={[
          {
            label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.login.admin.panel' }),
            e2eAttr: 'wp-manage-context-menu-item-log-in-admin',
            visibleOnDesktop: true,
            icon: 'login',
            shortcut: <Icon color="dark" name="external-link" size="14" />,
            onClick: () => this.onContextMenuClick(CONTEXT_MENU_ACTION.LOG_IN_TO_ADMIN_PANEL, entity)
          },
          {
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.update.admin.password' }),
            e2eAttr: 'wp-manage-context-menu-item-update-admin-password',
            icon: 'lock',
            onClick: () => this.onContextMenuClick(CONTEXT_MENU_ACTION.CHANGE_ADMIN_PASSWORD, entity)
          },
          {
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.permissions' }),
            e2eAttr: 'wp-manage-context-menu-item-permissions',
            icon: 'key',
            onClick: () => this.onContextMenuClick(CONTEXT_MENU_ACTION.FIX_PERMISSIONS, entity)
          },
          {
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.move' }),
            e2eAttr: 'wp-manage-context-menu-item-move',
            icon: 'move-to',
            onClick: () => this.onContextMenuClick(CONTEXT_MENU_ACTION.MOVE, entity)
          },
          {
            vcsMethod: 'DELETE',
            label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.delete' }),
            e2eAttr: 'wp-manage-context-menu-item-delete',
            icon: 'trash',
            onClick: () => this.onContextMenuClick(CONTEXT_MENU_ACTION.DELETE, entity)
          }
        ]}
      />
    );
  };

  render() {
    const { intl, wordpressApps, appInstallDomains, session } = this.props;
    const { selectedInstallation } = this.state;
    const userLang = session.user && session.user.language;

    const columns = [
      {
        header: () => (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.generic.domain' })}
            config={{
              ascending: (a, b) => ascendingSortByURLWithoutProtocol(a.app_url, b.app_url),
              descending: (a, b) => descendingSortByURLWithoutProtocol(a.app_url, b.app_url)
            }}
          />
        ),
        accessor: 'app_url',
        render: (cell) => cell.split('://').pop()
      },
      {
        header: () => (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.app' })}
            config={{
              ascending: (a, b) => (this.getAppName(a) >= this.getAppName(b) ? 1 : -1),
              descending: (a, b) => (this.getAppName(a) <= this.getAppName(b) ? 1 : -1)
            }}
          />
        ),
        accessor: 'app',
        mSize: '200px',
        render: (id, app: WordpressApp) => this.getAppName(app)
      },
      {
        header: () => (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.generic.version' })}
            config={{
              ascending: (a, b) => ascendingSortByVersion(a.version, b.version),
              descending: (a, b) => descendingSortByVersion(a.version, b.version)
            }}
          />
        ),
        accessor: 'version',
        mSize: '200px'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <div>
        <PageHeader
          id={ToolId.wpManage}
          instructions={
            <FormattedMessage
              id="translate.page.wp.manage.info"
              values={{
                addWordpress: (
                  <Link onClick={() => this.props.openSGDialog(REDUX_FORM.ADD_EXISTING_APP)}>
                    <FormattedMessage id="translate.generic.click.here" />
                  </Link>
                )
              }}
            />
          }
        />

        <Section>
          <Grid>
            <CreateBox selectedInstallation={selectedInstallation} setParentState={(state) => this.setState(state)}>
              <CreateForm
                domain={appInstallDomains}
                userLang={userLang}
                selectedInstallation={selectedInstallation}
                setParentState={(state) => this.setState(state)}
                onSubmit={({ wp_starter, _metaFields, is_wpmu, ...data }) => {
                  const selectedWooCommerce = selectedInstallation === WORDPRESS_WOOCOMMERCE;

                  const plugins = [
                    selectedWooCommerce && APP_TYPES.WOO_COMMERCE,
                    wp_starter && FIELDS.INSTALL_WP_STARTER
                  ].filter(Boolean);

                  this.onCreateFormSubmit({
                    ...data,
                    is_wpmu: Number(Boolean(is_wpmu)),
                    theme: selectedWooCommerce ? 'storefront' : undefined,
                    _metaFields,
                    plugins
                  });
                }}
              />
            </CreateBox>

            <VCS resourceName={API_RESOURCE.APP.resourceName} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.title' })}
                data={wordpressApps}
                columns={columns}
                resources={[
                  { resourceName: API_RESOURCE.APP.resourceName, methods: ['GET'] },
                  { resourceName: API_RESOURCE.WORDPRESS.resourceName, methods: ['GET'] }
                ]}
                rowResources={[
                  {
                    resourceName: API_RESOURCE.WORDPRESS.resourceName,
                    methods: ['DELETE']
                  },
                  {
                    requestTypeName: customRequestTypes.GET_WP_LOGIN_URL
                  }
                ]}
                showSkeleton={this.props.items[API_RESOURCE.APP.resourceName] === undefined}
                noDataMessage="translate.page.wp.manage.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>

        <AddExistingApp domain={appInstallDomains} />
        {this.renderContextMenuDialog()}
      </div>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  session: store.session || {},
  wordpressApps: getWordpressApps(store),
  appInstallDomains: filterStagingDomains(store)
});

export default indexWithCRUD(mapStateToProps, {
  requestData,
  openNewTabAction,
  ...sgDialogActions
})(WPManagerPage, API_RESOURCE.APP, API_RESOURCE.DOMAIN);
