import { browserHistory } from 'react-router';
import { call, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import * as fetchActions from '../actions/fetch';
import * as i18nActions from '../actions/i18n';
import * as nemoStoreActions from '../actions/nemo-store';
import * as sessionActions from '../actions/session';
import { fetchSiteMetaApi } from '../actions/site-meta-api';
import { storeSites } from '../actions/sites';
import { loadBackgroundTasks } from '../actions/tasks';
import { clientApi } from '../api/client';
import { getUrlSiteId } from '../common/site-id';
import * as Actions from '../constants/actions';
import { getCurrentSiteId, getSiteToken, getTranslationsFileName } from '../selectors';
import { redirectToURL } from '../utils/redirect-to-url';
import { parseJwt, siteTokenWillExpireSoon } from '../utils/session';
import { setRefreshTokenTTLIntoLocalStorage } from '../utils/set-localstorage-client-token-ttl';
import { handleNemoApiRequest } from './handle-nemo-api-request';
import initRealtimeCommunication from './realtime-communication';

function* loadNemoStoredData({ hash }) {
  try {
    const response = yield call(clientApi('/data/retrieve', 'POST', { hash }));

    yield put(nemoStoreActions.retrieveDataFromNemoSucceeded(response));

    const { exp } = parseJwt(response.session.refreshToken);

    setRefreshTokenTTLIntoLocalStorage(exp * 1000);

    return response;
  } catch (e) {
    console.error(e);
    return null;
  }
}

function* getProfile({ token }) {
  return yield call(clientApi(`/profile/getauthenticatedresponse?_client_token=${encodeURIComponent(token)}`, 'GET'));
}

function* setUserPreferencesFont() {
  const { session } = yield select();
  const userPreferences = session && session.user && session.user.preferences;
  const isEasyReadActive = userPreferences && userPreferences.easy_read_font;

  if (!isEasyReadActive) {
    document.documentElement.style.removeProperty('--font-family-default');
    document.documentElement.style.removeProperty('--font-family-component');

    return;
  }

  const font = new (window as any).FontFace(
    'OpenDyslexia Regular',
    `url(${process.env.FONTS_PATH}/OpenDyslexic-Regular.otf)`
  );

  font
    .load()
    .then((loadedFace) => {
      (document as any).fonts.add(loadedFace);
      document.documentElement.style.setProperty('--font-family-default', 'OpenDyslexia Regular');
      document.documentElement.style.setProperty('--font-family-component', 'OpenDyslexia Regular');
    })
    .catch((error) => {
      console.error(error);
    });
}

function* setUserPreferencesTheme() {
  const { session } = yield select();
  const userPreferences = session && session.user && session.user.preferences;
  const theme = userPreferences && userPreferences.theme;

  const currentTheme = Array.from(document.documentElement.classList).find((cls) => cls.indexOf('sg-theme') > -1);

  const themeClass = `sg-theme-${theme}`;
  if (currentTheme) {
    return document.documentElement.classList.replace(currentTheme, themeClass);
  }

  return document.documentElement.classList.add(themeClass);
}

function* applyUserPreferencesSaga() {
  yield call(setUserPreferencesTheme);
  yield call(setUserPreferencesFont);
}

function* getSites() {
  const { session } = yield select();

  return yield call(clientApi(`/sites?_client_token=${session.clientToken}`, 'GET'));
}

function* loadUserProfile({ clientToken }) {
  try {
    const response = yield call(getProfile, { token: clientToken });

    yield put(sessionActions.storeSessionProfile(response.data.profile));

    return response;
  } catch (e) {
    return null;
  }
}

export function* handleSitesInitialization(sites = []) {
  if (sites.length === 0) {
    return yield put(storeSites([], null));
  }

  let siteId = getUrlSiteId();
  const urlSelectedSite = Boolean(sites.find(({ id }) => id === siteId));

  const [defaultSite, ...otherSites] = sites;

  if (!urlSelectedSite && defaultSite) {
    siteId = defaultSite.id;

    redirectToURL({ urlParam: { siteId: defaultSite.id } });
  }

  yield put(storeSites(sites, siteId));

  const siteToken = yield select(getSiteToken, siteId);

  if (siteId) {
    if (!siteToken || siteTokenWillExpireSoon(siteToken)) {
      yield put(sessionActions.refreshSiteToken());

      const { siteTokenRefreshed, siteTokenRefreshFailed } = yield race({
        siteTokenRefreshed: take(Actions.SAVE_SITE_TOKEN),
        siteTokenRefreshFailed: take(Actions.REFRESH_SITE_TOKEN_FAILED)
      });
    }

    yield put(fetchSiteMetaApi());
  }
}

function* handlePageRedirect({ siteId, nemoRetrievedResponse }) {
  const redirectSiteId = nemoRetrievedResponse.siteId ? encodeURI(nemoRetrievedResponse.siteId) : siteId;
  const currentSearchParams = browserHistory.getCurrentLocation().query;
  const currentPathname = browserHistory.getCurrentLocation().pathname;
  const nemoPathname = `/${nemoRetrievedResponse.page}`;

  yield put(
    sessionActions.saveRedirectData({
      redirectSiteId,
      currentSearchParams,
      currentPathname,
      page: nemoRetrievedResponse.page ? nemoPathname : 'LOGIN_REDIRECT'
    })
  );

  redirectToURL({
    pathname: nemoRetrievedResponse.page ? nemoPathname : currentPathname,
    urlParam: {
      ...currentSearchParams,
      siteId: redirectSiteId
    },
    urlParamsToRemove: ['hash']
  });
}

export function* pageLoad(action) {
  yield put(fetchActions.httpRequestStarted(action));

  try {
    const { payload } = action;
    const { query } = browserHistory.getCurrentLocation();
    const redirectHash = query && query.hash;

    const { session } = yield select();

    let nemoRetrievedResponse;
    // use tokens from LS, synced to store
    let clientToken = session.clientToken;
    let refreshToken = session.refreshToken;

    if (redirectHash) {
      nemoRetrievedResponse = yield call(loadNemoStoredData, { hash: redirectHash });

      if (!nemoRetrievedResponse) {
        redirectToURL({
          pathname: browserHistory.getCurrentLocation().pathname,
          urlParamsToRemove: ['hash']
        });

        throw new Error('Cannot retrieve nemo stored data.');
      }

      // overwrite tokens with retrieved response from nemo
      clientToken = nemoRetrievedResponse.session.token;
      refreshToken = nemoRetrievedResponse.session.refreshToken;
    }

    if (!clientToken) {
      if (CONFIG.IS_RESELLER) {
        yield put(i18nActions.loadTranslations({ fileName: 'en' }));
      }

      yield put(sessionActions.logoutUser());
      yield put(fetchActions.httpRequestFailed(action, 'MISSING_CLIENT_TOKEN'));
      return;
    }

    if (redirectHash) {
      yield put(sessionActions.storeSessionToken({ clientToken, refreshToken }));
      const updatedProfile = yield call(loadUserProfile, { clientToken, refreshToken });

      if (!updatedProfile) {
        throw new Error('Failed to load profile data from nemo.');
      }
    }

    // non-blocking load of translations
    const fileName = yield select(getTranslationsFileName);
    yield put(i18nActions.loadTranslations({ fileName }));

    // Handles client token expiration, every token request should be made after this call()
    const sites = yield call(handleNemoApiRequest(getSites));

    if (!sites) {
      throw new Error('Failed to load sites from nemo.');
    }

    // Handles site token expiration, every token request should be made after this call()
    yield call(handleSitesInitialization, sites.data);

    if (nemoRetrievedResponse) {
      // handle specified from nemo store redirect
      const siteId = yield select(getCurrentSiteId);
      yield call(handlePageRedirect, { siteId, nemoRetrievedResponse });
    }

    yield put(fetchActions.httpRequestSucceeded(action));
  } catch (e) {
    console.error(e);
    yield put(sessionActions.pageLoadFailed());
    yield put(fetchActions.httpRequestFailed(action));
  }
}

function* afterPageLoad() {
  yield fork(initRealtimeCommunication);

  yield call(applyUserPreferencesSaga);

  // if something is not ok with the pageLoad (e.g /retrieve failed)
  // the following requests will trigger re-auth

  // load avalon tasks
  yield put(loadBackgroundTasks());
}

function* retryPageLoad(action) {
  yield call(pageLoad, action);
}

function* pageLoadSaga(): any {
  yield takeEvery(Actions.UPDATE_USER_PREFERENCES, applyUserPreferencesSaga);
  yield takeLatest(Actions.RETRY_PAGE_LOAD, retryPageLoad);

  yield fork(function* () {
    // Wait for pageLoad only once
    const action = yield take(Actions.PAGE_LOAD);

    yield call(pageLoad, action);
    yield call(afterPageLoad);
  });
}

export default pageLoadSaga;
