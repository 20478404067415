import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';

import CreateBox from '../../../../components/create-box';
import VCS from '../../../../components/visibility-control-service';

type Props = {
  children: any;
  intl: Intl;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.CRON_NOTIFY;
const formName = REDUX_FORM.CREATE_ITEM_CRON_NOTIFY;

const CreateContainer = ({ children, intl }: Props) => (
  <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.cron.notification.create.title' })}
      resourceName={resourceName}
      formName={formName}
      resources={[
        {
          resourceName: API_RESOURCE.CRON_NOTIFY.resourceName,
          methods: ['POST']
        }
      ]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
