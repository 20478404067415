import * as React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Button } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';

import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

type Props = {
  children: any;
  router: any;
  location: {
    search: string;
  };
  intl: Intl;
};

const { resourceName } = API_RESOURCE.DOMAIN;
const formName = REDUX_FORM.CREATE_SUB_DOMAIN;

class CreateContainer extends React.Component<Props> {
  render() {
    const { children, intl, router, location } = this.props;

    return (
      <VCS resourceName={resourceName} hasMethod="POST">
        <CreateBox
          title={intl.formatMessage({ id: 'translate.page.domain.create.title' })}
          resourceName={resourceName}
          formName={formName}
          resources={[
            {
              resourceName: API_RESOURCE.DOMAIN.resourceName,
              methods: ['POST']
            }
          ]}
          renderCustomNotification={({ notification, renderDefaultNotificationTemplate }) => {
            if (notification.state !== 'success') {
              return renderDefaultNotificationTemplate();
            }
            return (
              <div style={{ textAlign: 'center' }}>
                {renderDefaultNotificationTemplate()}
                <Button
                  data-e2e="notification-git-info-button"
                  color="secondary"
                  type="outlined"
                  onClick={() => router.push(`${ROUTES[ToolId.appManager]}${location.search}`)}
                >
                  {intl.formatMessage({ id: 'translate.page.subdomain.create.button.app.install' })}
                </Button>
              </div>
            );
          }}
        >
          {children}
        </CreateBox>
      </VCS>
    );
  }
}

export default withRouter(injectIntl<any, any>(CreateContainer));
