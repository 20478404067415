import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { CLOSE_SG_DIALOG, OPEN_SG_DIALOG, UNMOUNT_DIALOG } from '../constants/sg-dialog';

const initialState: any = {};

const clearDialog = (state, { id }: any) => ({
  ...state,
  [id]: undefined
});

export default handleActions<any, any>(
  {
    [OPEN_SG_DIALOG]: (state, { id, payload }: any) => ({
      ...state,
      [id]: Array.isArray(payload) ? [...payload] : { ...payload }
    }),
    [UNMOUNT_DIALOG]: clearDialog,
    [CLOSE_SG_DIALOG]: clearDialog,
    [Actions.LOGOUT]: () => initialState
  },
  initialState
);
