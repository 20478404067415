import * as React from 'react';
import { EmailDropdown } from '@siteground/styleguide';
import { emailRegExpPunyCode } from '../../core/common/form-validations';

class FormEmailDropdown extends React.Component<any, any> {
  render() {
    const { input, meta, optionValue = 'value', optionLabel = 'label', ...props } = this.props;
    let status;
    let error;

    const touchedOrActive = meta.touched || meta.active;
    const touchedOrHasValue = meta.touched || Boolean(input.value);

    if (!meta.valid && touchedOrActive && touchedOrHasValue) {
      status = 'error';
      error = meta.error;
    }

    return (
      <EmailDropdown
        data={props.options}
        state={status}
        selectedValue={input.value}
        validationMessage={error}
        chipRegExp={emailRegExpPunyCode}
        data-e2e={`form-email-dropdown-${input.name}`}
        {...input}
        {...props}
        optionValue={optionValue}
        optionLabel={optionLabel}
        onChange={(value, selectedOption, selectedOptions) => {
          input.onChange(selectedOptions.map((option) => option.value));
        }}
        onBlur={() => {
          // https://github.com/erikras/redux-form/issues/2768
          input.onBlur();
        }}
      />
    );
  }
}

export default FormEmailDropdown;
