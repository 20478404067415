import * as React from 'react';
import './form-field-wrapper.scss';

import { Text, FormLabel } from '@siteground/styleguide';

const FormFieldWrapper = ({ children, label, ...props }: any) => {
  return (
    <div className="form-field-wrapper" data-e2e="form-field-wrapper">
      <FormLabel>{label}</FormLabel>
      <div className="form-field-wrapper__content">{children}</div>
    </div>
  );
};

export default FormFieldWrapper;
