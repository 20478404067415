import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@siteground/styleguide';
import * as actions from '../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { RootState } from '../../../reducers';
import FormDropdown from '../../../components/form-dropdown';
import FormPassword from '../../../components/form-password';
import { withSpanelForm } from '../../../components/sg-spanel-forms';

type Props = {
  actions: {
    fetchItem: FetchItem;
  };
  validationUtils: ValidationUtils;
  wordpressUser: any[];
  intl: Intl;
  selectedApplication: {
    id: number;
  };
};

const formName = REDUX_FORM.CHANGE_WP_MANAGE_PASSWORD_DIALOG;

class ChangePasswordForm extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.actions.fetchItem({
      ...API_RESOURCE.WORDPRESS_USER,
      itemId: this.props.selectedApplication.id
    });
  }

  render() {
    const { wordpressUser, intl, validationUtils } = this.props;
    const { required, password } = validationUtils;
    const users = wordpressUser ? wordpressUser[0].users.map((user) => ({ id: user })) : [];

    return (
      <Grid>
        <Field
          name="username"
          label={intl.formatMessage({ id: 'translate.generic.select.user' })}
          options={users}
          optionValue="id"
          optionLabel="id"
          component={FormDropdown}
        />

        <Field
          name="password"
          label={intl.formatMessage({ id: 'translate.page.wp.manage.change.password.new.password' })}
          validate={[required, password]}
          component={FormPassword}
        />
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  wordpressUser: state.pageItems.wordpressUser
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withSpanelForm(reduxForm({ form: formName })(ChangePasswordForm))));
