import { call, select } from 'redux-saga/effects';
import { clientApi } from '../../api/client';

export function* getNemoData({ endpoint, method, body }) {
  const { session } = yield select();

  const url = [endpoint, session.clientToken && `?_client_token=${session.clientToken}`].join('');

  return yield call(clientApi(url, method, body));
}
