import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Button, Link, Text, Grid, textToHTML } from '@siteground/styleguide';
import { RootState } from '../../../reducers';
import InlineErrorPage from '../../inline-error-page';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { retryPageLoad } from '../../../../core/actions/session';
import { isApiDisabled, getQuotaUsed, getCurrentDomainName } from '../../../../core/selectors';
import { ROUTES } from '../../../../core/constants/routes';
import { ToolId } from '../../../../core/constants/route-info';

type Props = {
  children: any;
  // clears 403 failed flag from store, so page will mount again and try to load
  retryPageLoad?: typeof retryPageLoad;
  isAvalonForbidden?: boolean;
  quotaUsed?: SiteMetaApi['resource_usage'];
  domain?: string;
  location?: Location;
  router?: {
    push: Function;
  };
};

const ALOWED_ROUTES = [ROUTES[ToolId.filemanager], ROUTES[ToolId.email], ROUTES[ToolId.ftp], ROUTES[ToolId.ssh]];

const HandleDiskQuotaExceeded = ({
  children,
  retryPageLoad,
  isAvalonForbidden,
  quotaUsed = {
    used_percent_disk_soft: '',
    used_percent_inodes_soft: ''
  },
  domain,
  location,
  router
}: Props) => {
  const intl = useIntl();
  if (!isAvalonForbidden || ALOWED_ROUTES.includes(location.pathname)) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const { used_percent_inodes_soft, used_percent_disk_soft } = quotaUsed;

  return (
    <InlineErrorPage
      type="no-access"
      zIndex={2}
      title={intl.formatMessage({ id: 'translate.avalon-forbidden.title' })}
      message={textToHTML(
        intl.formatMessage(
          {
            id: 'translate.avalon-forbidden.description'
          },
          {
            site: domain,
            percentOfDiskSpace: used_percent_disk_soft,
            percentOfInodes: used_percent_inodes_soft
          }
        )
      )}
    >
      <Text padding="small">
        <Button color="secondary" onClick={() => router.push(`${ROUTES[ToolId.filemanager]}${location.search}`)}>
          {intl.formatMessage({ id: 'translate.avalon-forbidden.go-to-fm.link' })}
        </Button>
      </Text>
      <Text padding="small">
        <FormattedMessage
          id="translate.avalon-forbidden.refresh.text"
          values={{
            link: <Link onClick={() => retryPageLoad()}>{intl.formatMessage({ id: 'translate.generic.refresh' })}</Link>
          }}
        />
      </Text>
    </InlineErrorPage>
  );
};

export default connect<{}, {}, Props>(
  (state: RootState) => {
    return {
      isAvalonForbidden: isApiDisabled(state),
      quotaUsed: getQuotaUsed(state),
      domain: getCurrentDomainName(state)
    };
  },
  { retryPageLoad }
)(withRouter(HandleDiskQuotaExceeded));
