import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Button, Checkbox, Grid, RadioButton, Text } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../core/constants/common';
import { RadioButtonValues, initialFormState } from '../../../core/constants/db-forms';
import { withSpanelForm } from '../sg-form-util/with-spanel-form';

type DBUser = {
  id: string;
  name: string;
};

type DBGrant = {
  dbuser_id: string;
  db_id: string;
  grants: {
    perms?: string[];
    all?: number;
  };
};

type Props = {
  userData: {
    grant: {
      dbuser_name: string;
    };
    db_id: string;
  };
  dbUsers: DBUser[];
  dbGrant: DBGrant[];
  onSubmit: Function;
  change: Function;
  getFormValues: Function;
  intl: Intl;
};

const formName = REDUX_FORM.EDIT_DB_USER;

class EditDatabaseUser extends React.Component<Props, any> {
  static defaultProps: Partial<Props> = {
    dbUsers: [],
    dbGrant: []
  };

  handleCheckboxChange(index, event) {
    const { change, getFormValues } = this.props;
    const { checkboxes = [] } = getFormValues(formName);

    // eslint-disable-next-line sonarjs/no-unused-collection
    const checkboxesUpdated = [...checkboxes];
    checkboxesUpdated[index].checked = event.target.checked;

    change('checkboxes', checkboxes);
  }

  renderCheckboxes = () => {
    const { intl, getFormValues } = this.props;
    const { checkboxes = [] } = getFormValues(formName);

    return (
      <div>
        <Grid sm="3" gap="none">
          {checkboxes.map((checkbox, index) => (
            <Checkbox
              {...checkbox}
              name={checkbox.label}
              onChange={this.handleCheckboxChange.bind(this, index)}
              key={checkbox.label}
              data-e2e={`privileges-option-${checkbox.label}`}
            >
              {checkbox.label}
            </Checkbox>
          ))}
        </Grid>
      </div>
    );
  };

  renderRadioButtons = () => {
    const { intl, getFormValues } = this.props;
    const { radiobuttons = [] } = getFormValues(formName);

    return (
      <div>
        <Text align="left" color="light" size="small">
          {intl.formatMessage({ id: 'translate.page.database.privileges' })}
        </Text>

        <Grid sm="3">
          {radiobuttons.map((radiobutton, i) => (
            <RadioButton
              key={radiobutton.label}
              name="privileges"
              onChange={(e) => this.handleRadioButtonsChange(e.target.value)}
              value={radiobutton.value}
              data-e2e={`privileges-${radiobutton.value}`}
              {...radiobutton}
            >
              {intl.formatMessage({ id: radiobutton.label })}
            </RadioButton>
          ))}
        </Grid>
      </div>
    );
  };

  getRadioButtonsValueUpdated = (radiobuttons = [], value: string) => {
    return radiobuttons.map((radiobutton) => ({
      ...radiobutton,
      checked: radiobutton.value === value
    }));
  };

  handleRadioButtonsChange = (value) => {
    const { change, getFormValues } = this.props;

    const {
      radiobuttons = initialFormState.radiobuttons,
      checkboxes = initialFormState.checkboxes,
      grantForEdit = null
    } = getFormValues(formName);

    const radiobuttonsUpdated = this.getRadioButtonsValueUpdated(radiobuttons, value);

    const checkboxesUpdated = checkboxes.map((checkbox) => {
      if (radiobuttonsUpdated[0].checked) {
        return {
          ...checkbox,
          disabled: true,
          checked: true
        };
      } else if (radiobuttonsUpdated[1].checked) {
        return {
          ...checkbox,
          disabled: true,
          checked: !!['SELECT'].includes(checkbox.label)
        };
      } else {
        const hasGrants = Boolean(grantForEdit && grantForEdit.grants);

        if (hasGrants && grantForEdit.grants.all === 1) {
          return {
            ...checkbox,
            disabled: false,
            checked: true
          };
        }

        if (hasGrants && grantForEdit.grants.perms) {
          return {
            ...checkbox,
            disabled: false,
            checked: !!grantForEdit.grants.perms.includes(checkbox.label)
          };
        }

        return checkbox;
      }
    });

    change('radiobuttons', radiobuttonsUpdated);
    change('checkboxes', checkboxesUpdated);
  };

  revoke = (e) => {
    e.preventDefault();

    const { change } = this.props;
    this.handleRadioButtonsChange(RadioButtonValues.custom);

    const checkboxes = initialFormState.checkboxes.map((checkbox) => ({
      ...checkbox,
      disabled: false,
      checked: false
    }));

    change('checkboxes', checkboxes);
  };

  render() {
    const { intl, getFormValues } = this.props;
    const { grantForEdit } = getFormValues(REDUX_FORM.EDIT_DB_USER);

    return (
      <Grid>
        {this.renderRadioButtons()}
        {this.renderCheckboxes()}

        <div style={{ textAlign: 'left' }}>
          <Button type="outlined" color="secondary" size="small" onClick={this.revoke}>
            {intl.formatMessage({ id: 'translate.generic.revoke' })}
          </Button>
        </div>
      </Grid>
    );
  }

  componentDidMount() {
    const { dbUsers, dbGrant, userData, change } = this.props;

    if (userData) {
      const userForEdit = dbUsers.find((user) => user.name === userData.grant.dbuser_name);

      const grantForEdit = dbGrant.find(
        (grant) => grant.dbuser_id === userForEdit.id && grant.db_id === userData.db_id
      );

      change('userForEdit', userForEdit);
      change('grantForEdit', grantForEdit);

      const hasGrants = Boolean(grantForEdit && grantForEdit.grants);

      if (hasGrants && grantForEdit.grants.all === 1) {
        // all granted
        change('checkboxes', initialFormState.checkboxes);
        change('radiobuttons', initialFormState.radiobuttons);
        return;
      }

      if (hasGrants && grantForEdit.grants.perms) {
        if (JSON.stringify(grantForEdit.grants.perms) === JSON.stringify(['SELECT'])) {
          this.handleRadioButtonsChange(RadioButtonValues.readonly);
        } else if (grantForEdit.grants.perms.length === initialFormState.checkboxes.length) {
          this.handleRadioButtonsChange(RadioButtonValues.all);
        } else {
          this.handleRadioButtonsChange(RadioButtonValues.custom);

          const checkboxes = initialFormState.checkboxes.map((checkbox) => {
            const includesPerm = Boolean(
              grantForEdit && grantForEdit.grants.perms && grantForEdit.grants.perms.includes(checkbox.label)
            );

            return {
              ...checkbox,
              disabled: false,
              checked: includesPerm
            };
          });

          change('checkboxes', checkboxes);
        }
      }
    }
  }
}

export default withSpanelForm(
  reduxForm({
    form: formName
  })(injectIntl(EditDatabaseUser))
);
