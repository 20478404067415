import {
  FILE_MANAGER_API_RESPONSE_DIR,
  FILE_MANAGER_CODE_EDITOR_FORBIDDEN_FORMATS,
  FILE_MANAGER_CODE_EDITOR_SUPPORTED_FORMATS
} from '../constants/common';

import { FileManagerRootState } from '../reducers/utils/internal-state-definition';

const ABSOLUTE_PATH_INDEX = '/www';

export const getEntity = (entity, fileManagerState) => {
  if (!entity) {
    return null;
  }

  const stateToUse = fileManagerState;

  const entityPath = getEntityPath(entity);
  const entityParentPath = entityPath.substr(0, entityPath.lastIndexOf('/')) || '/';

  return (
    stateToUse.entities[entityParentPath] &&
    stateToUse.entities[entityParentPath].find((ent) => getEntityPath(ent) === getEntityPath(entity))
  );
};

export const getEntityByPath = (entityPath, fileManagerState) => {
  // TODO refactor fileManagerState to be just entities
  const stateToUse = fileManagerState;
  const entityParentPath = entityPath.substr(0, entityPath.lastIndexOf('/')) || '/';

  return (
    stateToUse.entities[entityParentPath] &&
    stateToUse.entities[entityParentPath].find((entity) => getEntityPath(entity) === entityPath)
  );
};

export const getEntityInfoNumber = (entity) => {
  return entity && entity.i;
};

export const getEntityName = (entity) => {
  return entity && entity.n;
};

export const getEntityNameExtension = (entity) => {
  return entity && getEntityReadableName(entity).split('.').pop();
};

export const getEntityReadableName = (entity) => {
  return entity && entity.n && entity.n.split('/').pop();
};

export const getEntityReadableNameByPath = (path) => {
  return path && path.split('/').pop();
};

export const getEntityPath = (entity) => {
  return entity && entity._meta.path;
};

export const getParentPath = (entityPath) => {
  return (entityPath && entityPath.substr(0, entityPath.lastIndexOf('/'))) || '/';
};

export const getEntityParentPath = (entity) => {
  if (!entity) {
    return;
  }

  const entityPath = getEntityPath(entity);

  return getParentPath(entityPath);
};

export const getEntityType = (entity) => {
  return entity && entity.t;
};

export const getEntitySize = (entity) => {
  return entity && entity.s;
};

export const getEntityIsExpanded = (entity) => {
  return entity && entity._meta && entity._meta.isExpanded;
};

export const getEntityPermissions = (entity) => {
  return entity && entity.p;
};

export const getOctalPermission = (permissionsCheckboxes) => {
  let octalValue = '';
  Object.keys(permissionsCheckboxes).forEach((key) => {
    const booleanCheckboxesValues = permissionsCheckboxes[key].map((checkbox) => Number(checkbox.checked)).join('');
    octalValue += parseInt(booleanCheckboxesValues, 2).toString(8);
  });

  return octalValue;
};

export const getBinaryPermissions = (octalValue, permissionsCheckboxes) => {
  const modifiedPermissionsCheckboxes = { ...permissionsCheckboxes };

  Object.keys(modifiedPermissionsCheckboxes).forEach((key, i) => {
    modifiedPermissionsCheckboxes[key].map((checkbox, j) => {
      let octalValueNumeric: any = Number(octalValue[i]).toString(2);
      octalValueNumeric = octalValueNumeric.length < 3 ? octalValueNumeric.padStart(3, '0') : octalValueNumeric;

      if (!checkbox.disabled) {
        checkbox.checked = Boolean(parseInt(octalValueNumeric[j], 10));
      }

      return checkbox;
    });
  });

  return modifiedPermissionsCheckboxes;
};

export const getCodeEditorEntityContent = (entity) => {
  return entity && entity._meta && entity._meta.content;
};

export const getCodeEditorEntityUpdatedContent = (entity) => {
  return entity && entity._meta && entity._meta.updatedContent;
};

export const hasEntitiesProtected = (entities = []) => {
  return entities.find((entity) => entity && entity.sys > 0);
};

export const areAllFolderSizesAvailable = (folderPath, fileManagerState) => {
  const stateToUse = fileManagerState;

  return (
    stateToUse.entities[folderPath] && stateToUse.entities[folderPath].filter((entity) => entity.s === 0).length === 0
  );
};

export const isEntityProtected = (entity) => {
  if (entity && entity.sys) {
    return entity.sys > 0;
  }

  return false;
};

export const isEntityActiveInCodeEditor = (entity) => {
  return entity && entity._meta && entity._meta.isActiveInCodeEditor;
};

export const isEntityForbiddenInCodeEditor = (entity) => {
  const name = typeof entity === 'string' ? entity : getEntityName(entity);
  const extension = name.substr(name.lastIndexOf('.') + 1, name.length);
  return Object.keys(FILE_MANAGER_CODE_EDITOR_FORBIDDEN_FORMATS).includes(extension.toLowerCase());
};

export const isEntityKnownInCodeEditor = (entity) => {
  const name = typeof entity === 'string' ? entity : getEntityName(entity);
  const extension = name.substr(name.lastIndexOf('.') + 1, name.length);

  return Object.keys(FILE_MANAGER_CODE_EDITOR_SUPPORTED_FORMATS).includes(extension.toLowerCase());
};

export const isEntityOpenedInCodeEditor = ({ codeEditor }: FileManagerRootState, entityId: string | number): boolean =>
  Boolean(codeEditor.files.find(({ i }) => i === entityId));

export const isEntityUpdatedInCodeEditor = ({ codeEditor }: FileManagerRootState, entityId): boolean => {
  const storedEntity = codeEditor.files.find(({ i }) => i === entityId);

  return Boolean(
    storedEntity && getCodeEditorEntityContent(storedEntity) !== getCodeEditorEntityUpdatedContent(storedEntity)
  );
};

export const shouldRenderMobile = (environment) => {
  return environment.isDevicePhone || environment.isDeviceTablet || environment.width < 1200;
};

export const getSymlinkEntityDestination = (entity) => {
  if (getEntityType(entity) !== FILE_MANAGER_API_RESPONSE_DIR.SYMLINK) {
    return null;
  }

  const fullPath = getEntityPath(entity);
  const { n } = entity;

  if (n.startsWith(ABSOLUTE_PATH_INDEX)) {
    return n.replace(ABSOLUTE_PATH_INDEX, '');
  }

  const parentPath = getEntityParentPath(entity);

  switch (true) {
    case n.startsWith('./'):
      const [relative, ...dstPath] = n;
      return `${parentPath}${dstPath}`;
    case n.startsWith('/'):
      return `${parentPath}${n}`;
    case parentPath === '/':
      return `/${n}`;
    default:
      return `${parentPath}/${n}`;
  }
};

export const getMonacoTheme = (theme) => {
  switch (theme) {
    case 'dark':
      return 'vs-dark';
    case 'hcb':
      return 'hc-black';
    // case 'hcl':
    //   return null;
    default:
      return 'vs';
  }
};
