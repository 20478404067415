import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm, WrappedFieldProps } from 'redux-form';
import { Box, Button, Column, Container, Flex, Icon, Label, Link, Notice, Text, Title } from '@siteground/styleguide';

import { navigateToUA } from '../../../../core/actions/nemo-store';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';

import { APP_TYPES, APP_TYPES_INFO_TEXT_BY_ID, APPS_LIST } from '../../../../core/constants/application';

import { REDUX_FORM } from '../../../../core/constants/common';
import { JOOMLA_LANGUAGES, WP_LANGUAGES } from '../../../../core/constants/languages';
import { getCurrentSiteId } from '../../../../core/selectors';
import { isACLActionEnabled } from '../../../../core/selectors/site-meta-api';
import { findAppIconByType, findAppNameByType } from '../../../../core/utils/app-names';
import { generateRandomPath } from '../../../../core/utils/path-generation';

import FormCheckbox from '../../../components/form-checkbox';
import FormDropdown from '../../../components/form-dropdown';
import FormInput from '../../../components/form-input';
import FormPassword from '../../../components/form-password';
import InformationIcon from '../../../components/information-icon';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import { FIELDS, VISUAL_VERIFICATIONS, WEEBLY_PLANS } from '../constants';
import { WeeblyExtra } from '../types';
import { generateWeeblyPlanOptions, getCmsIconSize, NotSupportedYet } from './utils';
import { normalizePathInput } from '../../../../core/utils/paths';

type Props = {
  // connected props
  currentSiteId?: string;
  domains?: any[];
  openSGDialog?: Function;
  userLang?: string;
  isDesktop?: boolean;
  isPhone?: boolean;
  canOrderWeebly?: boolean;
  appInRoot?: any;
  navigateToUA?: typeof navigateToUA;
  form?: string;
  validationUtils?: ValidationUtils;
  change?: Function;
  reset?: Function;
  getFormValues?: (formName: string) => any;
  sPanelFormSubmit?: (fieldName: string) => any;
  // props from parent
  activeWeebly?: WeeblyExtra;
  selectedAppType: string;
  onSelectAppType: Function;
  onSelectDomain: Function;
  activateFreeWeebly: Function;
  onSubmit: Function;
};

type DispatchProps = {
  intl: Intl;
};

const isWeeblyInstallationDisabled = (activeWeebly, appInRoot): boolean => {
  // Trying to install weebly will break an existing app in the root.
  return appInRoot && !activeWeebly;
};

export const getCustomPathOptions = (intl) => [
  {
    id: '0',
    name: intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.option.main.folder' }) as string
  },
  {
    id: '1',
    name: intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.option.sub.folder' }) as string
  }
];

export const getInstallationPathMessage = (intl: Intl, values: {}, domains: any[]): string => {
  const selectedDomain = domains.find((d) => d.id === values[FIELDS.DOMAIN_ID]);
  const path = selectedDomain.name + values[FIELDS.URL_PATH];

  return intl.formatMessage(
    { id: 'translate.page.wp.manage.create.installation.option.sub.folder.hint' },
    { path: path }
  );
};

export const getIsCustomPathSelected = (
  componentValue: string,
  customPathOptions: { id: string; name: string }[]
): boolean => componentValue === customPathOptions[1].id;

export class Form extends React.Component<Props & DispatchProps, {}> {
  validateFolder = (name, fields, formProps, fieldName) => {
    const { validationUtils } = this.props;
    const validation = validationUtils.folder(name, fields, formProps, fieldName);

    return validation && this.props.intl.formatMessage({ id: 'translate.validations.invalid_subfolder' });
  };

  onSelectedWeebly = (): void => {
    const { change, activeWeebly } = this.props;

    if (activeWeebly) {
      const planOptions = generateWeeblyPlanOptions(activeWeebly && activeWeebly.plan);
      const nextAvailablePlan = planOptions.find(({ disabled }) => !disabled);

      change(FIELDS.WEEBLY_PLAN, nextAvailablePlan ? nextAvailablePlan.id : activeWeebly.plan);
    }
  };

  onAppTypeSelected = (ev: React.SyntheticEvent, appType: string): void => {
    const { selectedAppType, onSelectAppType } = this.props;
    const appTypeHasChanged = Boolean(selectedAppType !== appType);

    if (appTypeHasChanged) {
      onSelectAppType(appType);
      this.resetFormState(appType);

      if (appType === APP_TYPES.WEEBLY) {
        this.onSelectedWeebly();
      }
    }
  };

  getHintMessage = (): string => {
    const { intl, form, getFormValues, domains } = this.props;
    const values = getFormValues(form);

    return getInstallationPathMessage(intl, values, domains);
  };

  resetFormState = (appType: string): JSX.Element => {
    const { reset, change, userLang } = this.props;
    const lang = userLang === 'en' ? 'en_US' : userLang;

    reset();

    change(FIELDS.LANGUAGE, lang);
    change(FIELDS.APP_TYPE, appType);

    // initialize default custom fields

    switch (appType) {
      case APP_TYPES.WORDPRESS:
      case APP_TYPES.WOO_COMMERCE:
        change(FIELDS.INSTALL_WP_STARTER, !CONFIG.IS_RESELLER);
        break;
      case APP_TYPES.PRESTASHOP:
        change(FIELDS.ADMIN_FOLDER, generateRandomPath(8));
        break;
      case APP_TYPES.OPEN_CART:
        change(FIELDS.DATA_DIRECTORY, generateRandomPath(8));
        break;
      case APP_TYPES.MOODLE:
        change(FIELDS.DATA_DIRECTORY, generateRandomPath(8));
        break;
      default:
        return;
    }
  };

  renderSelectApp = (): JSX.Element => {
    const { validationUtils, intl } = this.props;
    const { required } = validationUtils;
    const appList = APPS_LIST.map(({ group, ...data }) => ({
      ...data,
      group: intl.formatMessage({ id: group })
    }));

    return (
      <Column smSpan="12">
        <Field
          label={intl.formatMessage({ id: 'translate.page.app-manager.create.select-app.label' })}
          name={FIELDS.APP_TYPE}
          options={appList}
          optionValue="id"
          optionLabel="label"
          groupBy="group"
          disableAutoSelect
          validate={[required]}
          onChange={this.onAppTypeSelected}
          disableGroupReorder
          template={({ id, label }) => (
            <Flex align="center">
              <Icon size="20" name={findAppIconByType(id)} multicolor />
              <span style={{ marginLeft: '10px' }}>{intl.formatMessage({ id: label })}</span>
            </Flex>
          )}
          component={FormDropdown}
        />
      </Column>
    );
  };

  d: WrappedFieldProps;

  renderInfoBox = (): JSX.Element => {
    const { intl, selectedAppType, isDesktop, isPhone, canOrderWeebly, currentSiteId, navigateToUA } = this.props;

    return (
      <Column smSpan="12">
        <Container elevation="none" padding="none">
          <Flex gutter="medium" background="light" padding="medium" direction="row">
            <Box>
              <Icon
                style={{ marginTop: '10px' }}
                size={getCmsIconSize({ isPhone, isDesktop })}
                name={findAppIconByType(selectedAppType)}
                multicolor
              />
            </Box>
            <Box style={{ width: isPhone ? '70%' : '80%' }}>
              <Title level="6" density="compact" color="dark">
                {findAppNameByType(selectedAppType)}
              </Title>
              <Text>
                <FormattedMessage
                  id={APP_TYPES_INFO_TEXT_BY_ID[selectedAppType]}
                  values={{
                    link:
                      selectedAppType === APP_TYPES.WEEBLY && canOrderWeebly ? (
                        <Link
                          onClick={() =>
                            navigateToUA({
                              page: 'sitebuilder',
                              siteId: currentSiteId
                            })
                          }
                        >
                          {intl.formatMessage({ id: 'translate.page.app-manager.compare-weebly-plans' })}
                        </Link>
                      ) : null
                  }}
                />
              </Text>
            </Box>
          </Flex>
        </Container>
      </Column>
    );
  };

  renderApplicationSetup = (): JSX.Element => {
    const {
      validationUtils,
      onSelectDomain,
      intl,
      change,
      domains = [],
      form,
      getFormValues,
      selectedAppType,
      activeWeebly,
      appInRoot
    } = this.props;

    const { required } = validationUtils;
    const values = getFormValues(form);

    const customPathOptions = getCustomPathOptions(intl);
    const selectedDomainUrl = domains.find((d) => values.domain_id === d.id)?.name + '/';
    const isCustomPathSelected = getIsCustomPathSelected(values[FIELDS.URL_PATH_OPTION], customPathOptions);

    return (
      <React.Fragment>
        {!(selectedAppType === APP_TYPES.WEEBLY && isWeeblyInstallationDisabled(activeWeebly, appInRoot)) && (
          <Column smSpan="12">
            <Title level="6" density="none" color="dark">
              {intl.formatMessage({ id: 'translate.page.wp.manage.create.application.setup' })}
            </Title>
          </Column>
        )}

        {selectedAppType !== APP_TYPES.WEEBLY && (
          <Column smSpan="6">
            <Field
              label={intl.formatMessage({ id: 'translate.page.wp.manage.create.select.domain' })}
              name={FIELDS.DOMAIN_ID}
              options={domains}
              optionValue="id"
              optionLabel="name"
              validate={[required]}
              onChange={(event, value) => {
                const domain = domains.find((d) => d.id === value);
                onSelectDomain(domain);
              }}
              component={FormDropdown}
            />
          </Column>
        )}

        {selectedAppType === APP_TYPES.WEEBLY && !isWeeblyInstallationDisabled(activeWeebly, appInRoot) && (
          <Column smSpan="6">
            <Field
              label={intl.formatMessage({ id: 'translate.page.app-manager.weebly-plan.select' })}
              name={FIELDS.WEEBLY_PLAN}
              options={generateWeeblyPlanOptions(activeWeebly && activeWeebly.plan)}
              optionValue="id"
              optionLabel="label"
              optionDisabled="disabled"
              validate={[required]}
              component={FormDropdown}
              template={({ id, label, current }) => (
                <Flex align="center" justify="space-between">
                  <span>{intl.formatMessage({ id: label })}</span>
                  {current && (
                    <Label color="royal">
                      {intl.formatMessage({ id: 'translate.page.app-manager.weebly-label.current' })}
                    </Label>
                  )}
                </Flex>
              )}
            />
          </Column>
        )}

        <Column smSpan="6">{this.renderCustomField()}</Column>
        {selectedAppType !== APP_TYPES.WEEBLY && (
          <React.Fragment>
            <Column smSpan="6">
              <Field
                label={
                  <Flex align="center">
                    {/* TODO: refactor through the project. There are multiple code blocks like this through the project.  */}
                    {intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.path' })}
                    &nbsp;
                    <InformationIcon
                      tooltip={intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.path.tooltip' })}
                    />
                  </Flex>
                }
                name={FIELDS.URL_PATH_OPTION}
                options={customPathOptions}
                optionValue="id"
                optionLabel="name"
                component={FormDropdown}
              />
            </Column>
            <Column smSpan="6">
              {isCustomPathSelected && (
                <Field
                  label={intl.formatMessage({ id: 'translate.page.wp.manage.create.installation.folder' })}
                  name={FIELDS.URL_PATH}
                  placeholder={intl.formatMessage({ id: 'translate.page.wp.manage.path.placeholder' })}
                  validate={[required, this.validateFolder]}
                  normalize={normalizePathInput}
                  prefix={selectedDomainUrl}
                  component={FormInput}
                />
              )}
            </Column>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  renderCustomField = (): JSX.Element => {
    const { validationUtils, intl, selectedAppType } = this.props;
    const { email } = validationUtils;

    switch (selectedAppType) {
      case APP_TYPES.WORDPRESS:
      case APP_TYPES.WOO_COMMERCE:
        return this.renderSelectLanguage();
      case APP_TYPES.JOOMLA:
        return this.renderSelectLanguage(JOOMLA_LANGUAGES);
      case APP_TYPES.PRESTASHOP:
        return (
          <Field
            label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-admin-folter.label' })}
            name={FIELDS.ADMIN_FOLDER}
            component={FormInput}
          />
        );
      case APP_TYPES.OPEN_CART:
        return (
          <Field
            label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-data-dir.label' })}
            name={FIELDS.DATA_DIRECTORY}
            component={FormInput}
          />
        );
      case APP_TYPES.PHP_BB:
        return this.renderBoardEmail();
      case APP_TYPES.MEDIAWIKI:
        return (
          <Field
            label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-wiki-email.label' })}
            name={FIELDS.WIKI_EMAIL}
            component={FormInput}
            validate={[email]}
          />
        );
      // eslint-disable-next-line sonarjs/no-duplicated-branches
      case APP_TYPES.MOODLE:
        return (
          <Field
            label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-data-dir.label' })}
            name={FIELDS.DATA_DIRECTORY}
            component={FormInput}
          />
        );
      default:
        return null;
    }
  };

  renderAddOns = (): JSX.Element => {
    const { intl, selectedAppType } = this.props;
    const AddonsHeader = () => {
      return (
        <Column smSpan="12">
          <Title level="6" color="dark" density="none">
            {intl.formatMessage({ id: 'translate.page.wp.manage.create.add.ons' })}
          </Title>
        </Column>
      );
    };

    switch (selectedAppType) {
      case APP_TYPES.WORDPRESS:
      case APP_TYPES.WOO_COMMERCE:
        return (
          <React.Fragment>
            <AddonsHeader />
            <Column smSpan="12">
              <Flex align="flex-end">{this.renderEnableWPStarter()}</Flex>
              <Flex align="flex-end">
                <Field name={FIELDS.IS_WPMU} decoration="dotted" component={FormCheckbox}>
                  {intl.formatMessage({ id: 'translate.page.wp.manage.create.install.with.wpmu' })}
                  &nbsp;
                  <InformationIcon
                    tooltip={intl.formatMessage({ id: 'translate.page.wp.manage.create.install.with.wpmu.tooltip' })}
                  />
                </Field>
              </Flex>
            </Column>
          </React.Fragment>
        );
      case APP_TYPES.JOOMLA:
        return (
          <React.Fragment>
            <AddonsHeader />
            <Column smSpan="6">{this.renderSampleData()}</Column>
          </React.Fragment>
        );
      case APP_TYPES.MEDIAWIKI:
        return (
          <NotSupportedYet>
            <React.Fragment>
              <AddonsHeader />
              <Column smSpan="6">
                <Flex align="flex-end">
                  <Field name={FIELDS.ENABLE_UPLOADS} component={FormCheckbox}>
                    {intl.formatMessage({ id: 'translate.page.app-manager.create.input-enable-uploads.label' })}
                  </Field>
                </Flex>
              </Column>
            </React.Fragment>
          </NotSupportedYet>
        );
      default:
        return null;
    }
  };

  renderSelectLanguage = (languages: any[] = WP_LANGUAGES): JSX.Element => {
    const { intl } = this.props;

    return (
      <Field
        label={intl.formatMessage({ id: 'translate.page.wp.manage.create.select.language' })}
        name={FIELDS.LANGUAGE}
        options={languages}
        optionValue="id"
        optionLabel="label"
        component={FormDropdown}
      />
    );
  };

  renderEnableWPStarter = (): JSX.Element => {
    const { intl } = this.props;

    if (CONFIG.IS_RESELLER) {
      return null;
    }

    return (
      <Field name={FIELDS.INSTALL_WP_STARTER} decoration="dotted" component={FormCheckbox}>
        {intl.formatMessage({ id: 'translate.page.wp.manage.create.enable.wp-starter' })}
        &nbsp;
        <InformationIcon
          tooltip={intl.formatMessage({ id: 'translate.page.wp.manage.create.enable.wp-starter.text.tooltip' })}
        />
      </Field>
    );
  };

  renderBoardEmail = (): JSX.Element => {
    const { validationUtils, intl } = this.props;
    const { email } = validationUtils;

    return (
      <Field
        label={intl.formatMessage({ id: 'translate.page.app-manager.create.input-board-contact-email.label' })}
        name={FIELDS.BOARD_EMAIL}
        component={FormInput}
        validate={[email]}
        normalize={(value) => value.toLowerCase()}
      />
    );
  };

  renderSampleData = (): JSX.Element => {
    const { intl } = this.props;

    return (
      <Flex align="flex-end" style={{ height: '100%' }}>
        <Field expand name={FIELDS.SAMPLE_DATA} component={FormCheckbox}>
          {intl.formatMessage({
            id: 'translate.page.app-manager.create.input-install-dummy-content.label'
          })}
        </Field>
      </Flex>
    );
  };

  renderDefaultAdminFields = (): JSX.Element => {
    const { validationUtils, intl, selectedAppType } = this.props;
    const { required, email, password, validationWithMetaApi, adminName } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            label={intl.formatMessage({ id: 'translate.generic.username' })}
            name={FIELDS.ADMIN_USERNAME}
            component={FormInput}
            validate={[required, adminName, validationWithMetaApi]}
          />
        </Column>
        <Column smSpan="6">
          <Field
            style={{ display: 'none' }}
            name={FIELDS.ADMIN_PASSWORD}
            label="fake_password_to_prevent_autofill"
            data-e2e="fake-password"
            component={FormPassword}
            aria-hidden
          />
          <Field
            label={intl.formatMessage({ id: 'translate.generic.password' })}
            name={FIELDS.ADMIN_PASSWORD}
            validate={[required, password]}
            component={FormPassword}
          />
        </Column>
        <Column smSpan="6">
          <Field
            label={intl.formatMessage({ id: 'translate.generic.email' })}
            name={FIELDS.ADMIN_EMAIL}
            component={FormInput}
            validate={[required, email, validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
          />
        </Column>
      </React.Fragment>
    );
  };

  renderPrestaAdminFields = (): JSX.Element => {
    const { validationUtils, intl } = this.props;
    const { required, email, password, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            label={intl.formatMessage({ id: 'translate.generic.email' })}
            name={FIELDS.ADMIN_EMAIL}
            component={FormInput}
            validate={[required, email, validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
          />
        </Column>

        <Column smSpan="6">
          <Field
            style={{ display: 'none' }}
            name={FIELDS.ADMIN_PASSWORD}
            label="fake_password_to_prevent_autofill"
            data-e2e="fake-password"
            component={FormPassword}
            aria-hidden
          />
          <Field
            label={intl.formatMessage({ id: 'translate.generic.password' })}
            name={FIELDS.ADMIN_PASSWORD}
            validate={[required, password]}
            component={FormPassword}
          />
        </Column>
      </React.Fragment>
    );
  };

  renderAdminInfo = (): JSX.Element => {
    const { intl, selectedAppType } = this.props;

    if (selectedAppType === APP_TYPES.WEEBLY) {
      return null;
    }

    return (
      <React.Fragment>
        <Column smSpan="12">
          <Title level="6" density="none" color="dark">
            {intl.formatMessage({ id: 'translate.page.wp.manage.create.admin.info' })}
          </Title>
        </Column>
        {selectedAppType === APP_TYPES.PRESTASHOP ? this.renderPrestaAdminFields() : this.renderDefaultAdminFields()}
      </React.Fragment>
    );
  };

  renderSubmitSection = (): JSX.Element => {
    const {
      selectedAppType,
      form,
      intl,
      getFormValues,
      canOrderWeebly,
      appInRoot,
      currentSiteId,
      navigateToUA,
      activeWeebly
    } = this.props;

    const values = getFormValues(form);

    if (selectedAppType === APP_TYPES.WEEBLY) {
      const weeblyPlan = values[FIELDS.WEEBLY_PLAN];

      if (isWeeblyInstallationDisabled(activeWeebly, appInRoot)) {
        const mainDomain = this.props.domains?.find((domain) => domain.domain_type === 'main_domain');

        return (
          <Column smSpan="12">
            <Notice type="warning" background="light" shadow={false}>
              {intl.formatMessage(
                { id: 'translate.page.app-manager.weebly-warning-existing-app-in-root.warning' },
                { domain: mainDomain?.name }
              )}
            </Notice>
          </Column>
        );
      }

      if (weeblyPlan !== WEEBLY_PLANS.FREE && !canOrderWeebly) {
        return (
          <Column smSpan="12">
            <Notice
              type="warning"
              background="light"
              shadow={false}
              title={intl.formatMessage({ id: 'translate.page.superCacher.feature-not-available-dialog.title' })}
            >
              {intl.formatMessage({ id: 'translate.page.superCacher.feature-not-available-dialog.text' })}
            </Notice>
          </Column>
        );
      }

      // eslint-disable-next-line sonarjs/no-small-switch
      switch (values[FIELDS.WEEBLY_PLAN]) {
        case WEEBLY_PLANS.FREE:
          return (
            <Column smSpan="12">
              <Button
                color="primary"
                action="button"
                onClick={() => {
                  this.props.activateFreeWeebly();
                }}
              >
                {intl.formatMessage({ id: 'translate.generic.install' })}
              </Button>
            </Column>
          );
        default:
          if (activeWeebly && activeWeebly.plan === WEEBLY_PLANS.BUSSINESS) {
            return (
              <Column smSpan="12">
                <Notice type="warning" background="light" shadow={false}>
                  <Text>
                    {intl.formatMessage({ id: 'translate.page.app-manager.weebly-warning-max-plan.warning' })}
                  </Text>
                </Notice>
              </Column>
            );
          }

          return (
            <React.Fragment>
              <Column smSpan="12">
                <Notice type="warning" background="light" shadow={false}>
                  <Text>{intl.formatMessage({ id: 'translate.page.app-manager.weebly-navigate-to-ua.warning' })}</Text>
                </Notice>
              </Column>
              <Column smSpan="12">
                <Button
                  color="primary"
                  data-e2e="buy-weebly-ua"
                  disabled={!canOrderWeebly}
                  action="button"
                  onClick={() => {
                    navigateToUA({
                      page: 'sitebuilder',
                      siteId: currentSiteId,
                      planType: weeblyPlan
                    });
                  }}
                >
                  {intl.formatMessage({ id: 'translate.page.app-installer.go-to-ua.button' })}
                </Button>
              </Column>
            </React.Fragment>
          );
      }
    }

    return (
      <Column smSpan="12">
        <SGSubmitButton formName={form} label={intl.formatMessage({ id: 'translate.generic.install' })} />
      </Column>
    );
  };

  render() {
    const { isPhone, sPanelFormSubmit, form, getFormValues } = this.props;

    const values = getFormValues(form);

    return (
      <SGForm onSubmit={sPanelFormSubmit(form)} name={form}>
        {this.renderSelectApp()}
        {values[FIELDS.APP_TYPE] && (
          <React.Fragment>
            {!isPhone && this.renderInfoBox()}
            {this.renderApplicationSetup()}
            {this.renderAdminInfo()}
            {this.renderAddOns()}
          </React.Fragment>
        )}
        {this.renderSubmitSection()}
      </SGForm>
    );
  }
}

const CreateApp = reduxForm({ form: REDUX_FORM.CREATE_APP_INSTALL })(Form);

export default connect<any, any, Props>(
  ({ environment, sites, pageItems, siteMetaApi }) => {
    return {
      appInRoot: pageItems.app?.find((app) => app.path === '/' && app.domain_type === 'main_domain'),
      canOrderWeebly: isACLActionEnabled({ siteMetaApi }, 'app', 'can_order'),
      currentSiteId: getCurrentSiteId({ sites }),
      isDesktop: environment.isDesktop,
      isPhone: environment.isPhone
    };
  },
  (dispatch) => ({
    openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload)),
    navigateToUA: (redirectData) => dispatch(navigateToUA(redirectData))
  })
)(withSpanelForm(injectIntl(CreateApp)));
