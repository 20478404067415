import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import SGTable from '../../../components/sg-table';
import VCS from '../../../components/visibility-control-service';
import TableContextMenu from '../../../components/table-context-menu/table-context-menu';

export const SettingsContextMenu = ({
  value,
  entity,
  getSettingDataForCurrentDomain,
  onEditClicked,
  onRestoreClicked
}) => {
  const intl = useIntl();
  const { id, can_set, ...otherSettingData } = entity;
  const { user_value } = getSettingDataForCurrentDomain({ id, ...otherSettingData });
  const hasUserValue = user_value !== undefined;

  const contextMenuItems = [
    {
      vcsMethod: 'PUT',
      icon: 'edit',
      label: Boolean(can_set)
        ? intl.formatMessage({ id: 'translate.page.phpSettings.edit.tooltip' })
        : intl.formatMessage({ id: 'translate.page.phpSettings.cannot_edit.tooltip' }),
      e2eAttr: 'table-action-edit',
      visibleOnDesktop: true,
      disabled: !can_set,
      onClick: () => {
        const changeSettingPayload = {
          id,
          value: hasUserValue ? user_value : value
        };

        onEditClicked({ changeSettingPayload });
      }
    }
  ];

  const userValueRestoreButton = {
    vcsMethod: 'PUT',
    icon: 'restore',
    e2eAttr: 'restore-default-value',
    label: intl.formatMessage({ id: 'translate.page.phpSettings.restore_default.tooltip' }),
    visibleOnDesktop: true,
    disabled: !can_set,
    onClick: () => onRestoreClicked({ id })
  };

  return (
    <TableContextMenu
      entity={entity}
      resourceName={API_RESOURCE.PHP_LOCATION_INI.resourceName}
      items={hasUserValue ? [userValueRestoreButton, ...contextMenuItems] : contextMenuItems}
    />
  );
};

export default SettingsContextMenu as typeof SettingsContextMenu;
