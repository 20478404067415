import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { REDUX_FORM } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { RootState } from '../../../reducers';
import { SGDialogForm } from '../../../components/sg-dialog';
import { saveFile } from '../../core/actions/file-manager';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../../core/constants/common';
import { CreateFileDialogPayload } from '../../core/selectors/get-create-file-dialog-payload';
import { getEntityParentPath, getEntityPath, getEntityType } from '../../core/utils';
import CreateFileForm from './create-file-form';

type CreateFileDialogProps = {
  createFileDialogPayload: CreateFileDialogPayload;
  closeSGDialog: typeof closeSGDialog;
  intl: Intl;
  saveFile: typeof saveFile;
};

const CreateFile = ({ closeSGDialog, createFileDialogPayload, intl, saveFile }: CreateFileDialogProps) => {
  const { selectedNavigationEntity, codeEditorIsVisible } = createFileDialogPayload;

  return (
    <SGDialogForm
      name={REDUX_FORM.FILE_MANAGER_CREATE_FILE}
      icon="file-new"
      state="active"
      title={intl.formatMessage({ id: 'translate.file.manager.create.file.dialog.title' })}
      submitLabel={intl.formatMessage({ id: 'translate.generic.confirm' })}
      resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_SAVE }]}
    >
      <CreateFileForm
        onSubmit={(formData) => {
          const fileName = formData.filename;
          const isNavigationEntityFile = getEntityType(selectedNavigationEntity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
          const newFileParentPath = isNavigationEntityFile
            ? getEntityParentPath(selectedNavigationEntity)
            : getEntityPath(selectedNavigationEntity);

          saveFile(
            {
              ...formData,
              urlParams: {
                safe: 1,
                filename: `${newFileParentPath}/${fileName}`
              },
              file: '',
              _meta: {
                name: fileName,
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.file.manager.create.file.success.message',
                    intlValues: { name: fileName }
                  },
                  error: {
                    intlKey: 'translate.file.manager.create.file.error.message',
                    intlValues: { name: fileName }
                  }
                }
              },
              autoOpen: codeEditorIsVisible
            },
            () => closeSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_FILE)
          );
        }}
      />
    </SGDialogForm>
  );
};

const mapStateToProps = (state: RootState) => ({
  createFileDialogPayload: state.dialog[REDUX_FORM.FILE_MANAGER_CREATE_FILE] || {}
});

const mapDispatchToProps = {
  closeSGDialog,
  saveFile
};

export default connect<{}, {}, Partial<CreateFileDialogProps>>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl<any, any>(CreateFile));
