import { call, put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_DIR_BY_PATH_REQUESTED, FETCH_DIR_BY_PATH_SUCCEEDED } from '../constants/fetch-dir-by-path';
import { siteApi } from '../api/site';
import handleAvalonApiRequest from './handle-avalon-api-request';

function* fetchDirSaga(action: FetchItemsAction) {
  const state = yield select();
  const path = action.payload;

  const response = yield call(
    siteApi({
      endpoint: '/dir-list',
      method: 'POST',
      state,
      body: {
        entries: [path]
      }
    })
  );

  yield put({ type: FETCH_DIR_BY_PATH_SUCCEEDED, items: response.data });

  return response;
}

function* fetchDir(): any {
  yield takeEvery(FETCH_DIR_BY_PATH_REQUESTED, handleAvalonApiRequest(fetchDirSaga));
}

export default fetchDir;
