import { RootState } from '../../web/reducers';
import { sessionInitialState } from '../reducers/session';

export const getUserTheme = ({ session }: Partial<RootState>) => {
  if (!session || !session.user || !session.user.preferences) {
    return sessionInitialState.user.preferences.theme;
  }

  return session.user.preferences.theme;
};

export const getUserEasyReadFontEnabled = ({ session }: Partial<RootState>) => {
  if (!session || !session.user || !session.user.preferences) {
    return sessionInitialState.user.preferences.easy_read_font;
  }

  return Boolean(session.user.preferences.easy_read_font);
};

export const getUserDashboardTiles = ({ session }: Partial<RootState>) => {
  if (
    !session ||
    !session.user ||
    !session.user.preferences ||
    !session.user.preferences.sitetools ||
    !session.user.preferences.sitetools.dashboard ||
    !session.user.preferences.sitetools.dashboard.tiles
  ) {
    return sessionInitialState.user.preferences.sitetools.dashboard.tiles;
  }

  return session.user.preferences.sitetools.dashboard.tiles;
};
