import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../core/constants/common';
import FormCheckbox from '../../components/form-checkbox';
import FormInput from '../../components/form-input';
import FormSlider from '../../components/form-slider';
import { withSpanelForm } from '../sg-form-util/with-spanel-form';
import InformationIcon from '../../components/information-icon';

type StateProps = {
  siteMetaApi: {
    features: any;
  };
};

type DispatchProps = {
  intl: Intl;
};

type OwnProps = {
  type: 'email' | 'ftp';
  initialValues: { _metaFields: { entityName: string; maxQuotaSize: number } };
  change?: Function;
  validationUtils?: ValidationUtils;
  siteMetaApiKey: string;
  onSubmit: Function;
};

interface Props extends StateProps, DispatchProps, OwnProps {}

type State = {
  isQuotaUnlimited: boolean;
};

const formName = REDUX_FORM.GENERIC_QUOTA;
const QUOTA_SIZE_FIELD_NAME = 'quota_size';
const MINIMUM_QUOTA_SIZE = 1024 * 1024 * 1; // 1mb

export const getIsQuotaUnlimited = ({ initialValues }: Props) =>
  Boolean(initialValues && initialValues[QUOTA_SIZE_FIELD_NAME] === 0);

class ChangeQuota extends React.Component<Props, State> {
  private quotaFieldRef;

  readonly state = {
    isQuotaUnlimited: getIsQuotaUnlimited(this.props)
  };

  handleUnlimitedCheckboxClick = () => {
    this.setState({ isQuotaUnlimited: !this.state.isQuotaUnlimited }, () => {
      this.props.change(QUOTA_SIZE_FIELD_NAME, MINIMUM_QUOTA_SIZE);
      if (this.quotaFieldRef && !this.state.isQuotaUnlimited) {
        this.quotaFieldRef.focus();
      }
    });
  };

  convertToMB = (K) => {
    if (Number.isNaN(parseInt(K, 10))) {
      return K;
    }

    return K / 1024 / 1024;
  };

  convertFromMB = (MB) => {
    if (Number.isNaN(parseInt(MB, 10))) {
      return MB;
    }

    return MB * 1024 * 1024;
  };

  renderSlider() {
    const { initialValues } = this.props;
    const maxQuotaSize = initialValues && initialValues._metaFields && initialValues._metaFields.maxQuotaSize;

    return (
      <Field
        name={QUOTA_SIZE_FIELD_NAME}
        min={MINIMUM_QUOTA_SIZE}
        max={maxQuotaSize}
        step={1024 * 1024}
        label="MB"
        isUnlimited={this.state.isQuotaUnlimited}
        valueTemplate={(value) => (value / 1024 / 1024).toFixed(0)}
        component={FormSlider}
      />
    );
  }

  isBiggerThan: ValidationUtil = (value) => {
    const { initialValues, intl } = this.props;
    const maxQuotaSize = initialValues && initialValues._metaFields && initialValues._metaFields.maxQuotaSize;

    return value <= maxQuotaSize
      ? undefined
      : intl.formatMessage(
          { id: 'translate.generic.quota.error.bigger' },
          { maxQuotaSize: this.convertToMB(maxQuotaSize) }
        );
  };

  isSmallerThan: ValidationUtil = (value) => {
    const { intl } = this.props;

    return value >= MINIMUM_QUOTA_SIZE
      ? undefined
      : intl.formatMessage(
          { id: 'translate.generic.quota.error.smaller' },
          { minQuotaSize: this.convertToMB(MINIMUM_QUOTA_SIZE) }
        );
  };

  renderInput() {
    const { intl, validationUtils, type } = this.props;
    const validations = this.state.isQuotaUnlimited
      ? [validationUtils.number, this.isBiggerThan, this.isSmallerThan]
      : [validationUtils.number, this.isSmallerThan];

    return (
      <React.Fragment>
        {this.state.isQuotaUnlimited ? (
          <Field name="for-visualization-only" suffix="MB" disabled component={FormInput} />
        ) : (
          <Field
            type="number"
            onRefsReady={(ref) => {
              this.quotaFieldRef = ref;
            }}
            valueTemplate={this.convertToMB}
            onChangeTemplate={(event) => this.convertFromMB(event.target.value)}
            name={QUOTA_SIZE_FIELD_NAME}
            suffix="MB"
            readOnly={this.state.isQuotaUnlimited}
            validate={validations}
            component={FormInput}
          />
        )}

        <div style={{ textAlign: 'left' }}>
          <Field
            name="quota_unlimited"
            checked={this.state.isQuotaUnlimited}
            onChange={this.handleUnlimitedCheckboxClick}
            component={FormCheckbox}
            expand
          >
            {intl.formatMessage({ id: 'translate.generic.quota.unlimited' })}
            {type === 'email' && (
              <React.Fragment>
                &nbsp;
                <InformationIcon tooltip={intl.formatMessage({ id: 'translate.quota.unlimited.tooltip' })} />
              </React.Fragment>
            )}
          </Field>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { siteMetaApi, siteMetaApiKey } = this.props;

    return siteMetaApi.features[siteMetaApiKey] === 0 ? this.renderInput() : this.renderSlider();
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(({ siteMetaApi }) => ({ siteMetaApi }))(
  withSpanelForm(reduxForm({ form: formName })(injectIntl(ChangeQuota)))
);
