import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { REDUX_FORM } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { RootState } from '../../../reducers';
import { SGDialogForm } from '../../../components/sg-dialog';
import { fileManagerPostRequest } from '../../core/actions/file-manager';
import * as Constants from '../../core/constants/actions';
import { CreateDirDialogPayload } from '../../core/selectors/get-create-dir-dialog-payload';
import { getEntityPath } from '../../core/utils';
import CreateDirForm from './create-dir-form';

type CreateFileDialogProps = {
  createDirDialogPayload: CreateDirDialogPayload;
  closeSGDialog: typeof closeSGDialog;
  fileManagerPostRequest: typeof fileManagerPostRequest;
  children?: any;
};

const CreateDir = ({ closeSGDialog, createDirDialogPayload, fileManagerPostRequest }: CreateFileDialogProps) => {
  const { selectedNavigationEntity } = createDirDialogPayload;
  const intl = useIntl();
  return (
    <SGDialogForm
      name={REDUX_FORM.FILE_MANAGER_CREATE_DIR}
      icon="folder-new"
      state="active"
      title={intl.formatMessage({ id: 'translate.file.manager.create.directory.dialog.title' })}
      submitLabel={intl.formatMessage({ id: 'translate.generic.confirm' })}
      resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_POST }]}
    >
      <CreateDirForm
        onSubmit={(formData) =>
          fileManagerPostRequest(
            {
              ...formData,
              endpoint: Constants.FILE_MANGER_API_DIR,
              urlParams: {
                safe: 1,
                entries: [`${getEntityPath(selectedNavigationEntity)}/${formData.id}`]
              },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.file.manager.create.dir.success.message',
                    intlValues: { name: formData.id }
                  },
                  error: {
                    intlKey: 'translate.file.manager.create.dir.error.message',
                    intlValues: { name: formData.id }
                  }
                }
              }
            },
            () => closeSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_DIR)
          )
        }
      />
    </SGDialogForm>
  );
};

const mapStateToProps = (state: RootState) => ({
  createDirDialogPayload: state.dialog[REDUX_FORM.FILE_MANAGER_CREATE_DIR] || {}
});

const mapDispatchToProps = {
  closeSGDialog,
  fileManagerPostRequest
};

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(CreateDir);
