import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Grid, IconButton, Section, Text, textToHTML } from '@siteground/styleguide';
import { HostingTypes } from '@siteground/styleguide/lib/utils';

import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { navigateToUA } from '../../../core/actions/nemo-store';
import { requestNemoData } from '../../../core/actions/request-data';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { ToolId } from '../../../core/constants/route-info';
import { RootState } from '../../reducers';

import {
  getAvailableLocationPhpSettings,
  getDefaultPhpVersion,
  getLocationPhpIniById,
  getLocationPhpVersionByDomainId,
  getPhpVariablesById,
  getPhpMod,
  getApacheUservice,
  PhpModType,
  findMainDomain,
  isUltrafastModDisabled,
  isACLActionEnabled,
  getToolMainEndpoint,
  getCurrentSiteAccountId,
  getCurrentSiteId
} from '../../../core/selectors';

import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import { SecondLevelTitle } from '../../components/titles';
import VCS from '../../components/visibility-control-service';
import PhpIniValue from './php-variables/php-ini-value';
import { DomainBox, DomainSelectLiveSites, DomainSelectStagingSites } from './domain-select';
import * as PageComponents from './components';
import FeatureNotAvailable from '../../components/dialogs/feature-not-available';

import {
  PhpManagerState,
  PHPVariable,
  PhpVersion,
  PhpLocationVersion,
  PhpVersionManageType,
  SettingApiType,
  SiteTypes
} from './types';

import {
  getStagingDomains,
  getLiveSiteDomains,
  getPhpManagerState,
  filterPHPVariables,
  formToApiValueConversion,
  isPhpVersionManageTypeAuto,
  getDomainType,
  findDomainById
} from './utils';

const DOMAIN_SELECT_FORM_BY_TAB_ID = {
  [SiteTypes.LiveSites]: DomainSelectLiveSites,
  [SiteTypes.StagingSites]: DomainSelectStagingSites
};

const tabs = ['version', 'variables'];

const MOD_PHP_NEED_UPGRADE_PLANS = [HostingTypes.START_UP, HostingTypes.GROW_BIG];
const PHP_ULTRAFAST = 'mod_php';
const PHP_STANDARD = 'standard';

type Props = {
  actions: CrudActions;
  environment: {
    isPhone: boolean;
  };
  items: {
    locationPhpiniSettings: PHPVariable[];
    domain: any[];
    phpVariableDataById: {
      [variableId: string]: {
        name: string;
        id: string;
        type: SettingApiType;
      };
    };
    locationPhpiniByDomainId: object;
    phpVersions: PhpVersion[];
    phpExtension: any;
    locationPhpVersionByDomainId: object;
  };
  mainDomain: {
    id: number;
    name: string;
  };
  isUltrafastModDisabled: boolean;
  phpMod: PhpModType;
  apacheUservice: {
    id: number;
    type: string;
    settings: {
      php_version;
    };
  };
  defaultPhpVersion: PhpVersion;
  intl: Intl;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  accountId: string;
  siteId: string;
  isUAEnabled: boolean;
  requestNemoData: typeof requestNemoData;
  navigateToUA: typeof navigateToUA;
};

type State = {
  selectedDomainId: number;
  selectedDomainBoxTab: SiteTypes;
  tabActive: string;
  selectedPhpExtension: any;
  selectedEditableVariable: {
    id: string;
    value: string;
  };
  selectedVariableToRestore: {
    id: string;
  };
  phpVariableSearch: string;
  upgradeResponse: OrderUpgradeData;
};

class PhpSettings extends React.Component<Props, State> {
  readonly state: State = {
    selectedDomainBoxTab: SiteTypes.LiveSites,
    selectedDomainId: null,
    tabActive: tabs[0],
    selectedPhpExtension: null,
    selectedEditableVariable: null,
    selectedVariableToRestore: null,
    phpVariableSearch: '',
    upgradeResponse: null
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
      JSON.stringify(nextState) !== JSON.stringify(this.state)
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { phpMod, items } = this.props;

    if (phpMod === PHP_ULTRAFAST) {
      const mainDomain = items.domain.find((domain) => domain.domain_type === 'main_domain');
      const id = mainDomain ? mainDomain.id : null;
      this.setState({ selectedDomainId: id });
    }

    const { selectedDomainId } = this.state;

    // php manager state in prev lifecycle
    const prevPhpManagerState = getPhpManagerState({
      phpMod: prevProps.phpMod,
      domainType: getDomainType(
        findDomainById({
          domainId: prevState.selectedDomainId,
          domain: prevProps.items.domain
        })
      )
    });

    // php manager state in current lifecycle
    const selectedDomain = this.getSelectedDomain();
    const currentPhpManagerState = getPhpManagerState({
      phpMod,
      domainType: getDomainType(selectedDomain)
    });

    const shouldReFetchDomainData = Boolean(
      (currentPhpManagerState && currentPhpManagerState !== prevPhpManagerState) ||
        prevState.selectedDomainId !== selectedDomainId
    );

    if (shouldReFetchDomainData) {
      // re-fetch page data for domain
      this.handlePageReFetch(currentPhpManagerState, selectedDomain.id);
    }
  }

  handlePageReFetch = (pageState: PhpManagerState, domainId: number) => {
    const { mainDomain } = this.props;
    const { selectedDomainId } = this.state;
    this.fetchPhpIniSettings(selectedDomainId);
  };

  fetchPhpIniSettings = (domainId: number) => {
    const { actions } = this.props;

    actions.fetchItems({
      ...API_RESOURCE.PHP_LOCATION_INI_SETTINGS,
      urlParams: {
        domain_id: domainId
      }
    });

    actions.fetchItems({
      ...API_RESOURCE.PHP_LOCATION_INI,
      id: domainId
    });
  };

  resetPhpVariableSearch = () => this.setState({ phpVariableSearch: '' });

  // Data selectors
  getSettingDataForCurrentDomain = ({ user_value, ...otherSettingData }: any) => {
    const { items } = this.props;
    const { locationPhpiniByDomainId } = items;
    const { selectedDomainId } = this.state;

    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (locationPhpiniByDomainId[selectedDomainId]) {
      /* if the variable is set for the currently selected domain, return the object with user_value,
        it will be merged with the data returned for the variable from /location-phpini_settings  GET,
        because the /location-phpini_settings  GET, returns user_value if it is set for any of the domains
        (really strange API behaviour) */
      if (locationPhpiniByDomainId[selectedDomainId].settings[otherSettingData.id]) {
        return {
          ...otherSettingData,
          user_value: locationPhpiniByDomainId[selectedDomainId].settings[otherSettingData.id].value
        };
      }
    }

    return {
      // ommiting the user_value, if it is not for the current domainId
      ...otherSettingData
    };
  };

  getVersionDataForCurrentDomain = (): PhpVersion => {
    const { items, mainDomain, defaultPhpVersion } = this.props;
    const { selectedDomainId } = this.state;
    const domainSelectedVersion: PhpLocationVersion = items.locationPhpVersionByDomainId[selectedDomainId];

    if (domainSelectedVersion) {
      return items.phpVersions.find(({ id }) => String(id) === String(domainSelectedVersion.version_id));
    }

    return null;
  };

  getSelectedDomain = () =>
    this.props.items.domain && this.props.items.domain.find(({ id }) => id === this.state.selectedDomainId);

  getUservicePhpVersion = () => {
    const { items, apacheUservice } = this.props;

    const selectedVersion = apacheUservice && apacheUservice.settings && apacheUservice.settings.php_version;

    if (selectedVersion) {
      return items.phpVersions.find(({ id }) => String(id) === String(selectedVersion));
    }

    return null;
  };

  getApacheUservicePhpVersion = () => {
    const { defaultPhpVersion } = this.props;

    // default is always mod_php compatible
    return this.getUservicePhpVersion() || defaultPhpVersion;
  };

  getUsedPhpVersion = (pageState: PhpManagerState): PhpVersion => {
    const { items, mainDomain, defaultPhpVersion, apacheUservice } = this.props;
    const { selectedDomainId } = this.state;

    if (apacheUservice && pageState === PhpManagerState.ModPhpMainSelected) {
      return this.getApacheUservicePhpVersion();
    }

    const domainSelectedVersion: PhpLocationVersion = items.locationPhpVersionByDomainId[selectedDomainId];

    if (!domainSelectedVersion || isPhpVersionManageTypeAuto(domainSelectedVersion)) {
      // returns the recommended version (default === 1)
      return defaultPhpVersion;
    }

    const selectedPhpVersion: PhpVersion = this.getVersionDataForCurrentDomain();

    if (selectedPhpVersion) {
      return selectedPhpVersion;
    }

    // returns the default php version for all
    return defaultPhpVersion;
  };

  getPhpVersionManageType = (pageState: PhpManagerState): PhpVersionManageType => {
    const { items, apacheUservice } = this.props;

    if (apacheUservice && pageState === PhpManagerState.ModPhpMainSelected) {
      const phpVersionData = this.getUservicePhpVersion();

      return isPhpVersionManageTypeAuto(phpVersionData) ? PhpVersionManageType.Auto : PhpVersionManageType.Manual;
    }

    const { selectedDomainId } = this.state;
    const domainSelectedVersion = items.locationPhpVersionByDomainId[selectedDomainId];

    return isPhpVersionManageTypeAuto(domainSelectedVersion) ? PhpVersionManageType.Auto : PhpVersionManageType.Manual;
  };

  getDomainBoxDomains = () => {
    const { items } = this.props;
    const { selectedDomainBoxTab } = this.state;

    if (selectedDomainBoxTab === 'live-sites') {
      return getLiveSiteDomains({ domains: items.domain });
    }

    return getStagingDomains({ domains: items.domain });
  };

  updateModPhpVersion = ({ version_id }, notification?: SpanelNotification, onComplete?: OnCompleteCallback) => {
    const { actions, phpMod, apacheUservice } = this.props;

    const settings = version_id === PhpVersionManageType.Auto ? {} : { php_version: version_id };

    return actions.updateItem(
      {
        id: apacheUservice.id,
        settings,
        _metaFields: { ...API_RESOURCE.USERVICE },
        _meta: { notification }
      },
      onComplete
    );
  };

  // Update functions
  updatePhpVersion = ({ version_id }, notification?: SpanelNotification, onComplete?: OnCompleteCallback) => {
    const { actions, items, phpMod, apacheUservice } = this.props;
    const selectedDomain = this.getSelectedDomain();

    const pageState = getPhpManagerState({ phpMod, domainType: getDomainType(selectedDomain) });

    if (apacheUservice && pageState === PhpManagerState.ModPhpMainSelected) {
      return this.updateModPhpVersion({ version_id }, notification, onComplete);
    }

    const hasSetPhpVersion = Boolean(items.locationPhpVersionByDomainId[this.state.selectedDomainId]);

    const meta = {
      _metaFields: { ...API_RESOURCE.PHP_LOCATION_VERSION },
      _meta: { notification }
    };

    if (hasSetPhpVersion) {
      const id = items.locationPhpVersionByDomainId[this.state.selectedDomainId].id;

      if (version_id === PhpVersionManageType.Auto) {
        // Joro said: when setting version_id to automanaged, make delete HTTP request !!!
        return actions.deleteItem(
          {
            ...(meta as DeleteItemPayload),
            itemId: id
          },
          onComplete
        );
      }

      actions.updateItem(
        {
          ...(meta as UpdateItemPayload),
          id,
          version_id
        },
        onComplete
      );
    } else {
      actions.createItem(
        {
          ...(meta as CreateItemPayload),
          domain_id: this.state.selectedDomainId,
          path: '/', // path is always '/' according to current backend implementation
          version_id
        },
        onComplete
      );
    }
  };

  updatePhpSettings = ({ settingName, settingType, settingFormValue }) => {
    const { items, actions, intl, closeSGDialog } = this.props;

    const settingData = items.locationPhpiniSettings.find((variable) => variable.id === settingName);

    const updatedSettingData = {
      id: settingName,
      value: formToApiValueConversion(settingFormValue, settingType)
    };

    if (settingData.value === updatedSettingData.value) {
      // value is changed to its default value, should be called restore default
      return this.restoreDefaultPhpSetting(settingName);
    }

    const metaFields = {};

    const domainHasSetPhpIni = Boolean(items.locationPhpiniByDomainId[this.state.selectedDomainId]);

    const notification: SpanelNotification = {
      type: 'generic',
      success: {
        intlKey: 'translate.page.phpSettings.php_variable_updated',
        intlValues: { variableName: settingName }
      },
      error: {
        intlKey: 'translate.page.phpSettings.failed_update_php_variable',
        intlValues: { variableName: settingName }
      }
    };

    if (domainHasSetPhpIni) {
      actions.updateItem(
        {
          id: items.locationPhpiniByDomainId[this.state.selectedDomainId].id,
          settings: this.prepareVariablesArrayToUpdate(updatedSettingData),
          recursive: '1', // legacy api param
          _metaFields: {
            ...API_RESOURCE.PHP_LOCATION_INI
          },
          _meta: {
            notification
          }
        },
        () => closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING)
      );
    } else {
      actions.createItem(
        {
          _metaFields: {
            ...API_RESOURCE.PHP_LOCATION_INI
          },
          _meta: {
            notification
          },
          domain_id: this.state.selectedDomainId,
          recursive: '1', // legacy api param
          path: '/', // path is always '/' according to current backend implementation
          settings: [updatedSettingData]
        },
        () => closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING)
      );
    }
  };

  fetchPHPExtensions = () => {
    const { actions, phpMod } = this.props;
    const { tabActive } = this.state;
    const selectedDomain = this.getSelectedDomain();

    const pageState: PhpManagerState = getPhpManagerState({
      phpMod,
      domainType: getDomainType(selectedDomain)
    });

    const phpVersion = this.getUsedPhpVersion(pageState);

    if (tabActive === 'extensions') {
      actions.fetchItems({
        ...API_RESOURCE.PHP_EXTENSION,
        urlParams: {
          domain_id: this.state.selectedDomainId,
          version: phpVersion.id
        }
      });
    }
  };

  updatePhpExtension = () => {
    const { actions, mainDomain, closeSGDialog } = this.props;
    const { selectedDomainId } = this.state;

    const onUpdateCompleted = () => {
      this.fetchPHPExtensions();
      closeSGDialog(DIALOGS.CHANGE_PHP_EXTENSION_SETTING);
    };

    const extensionName = this.state.selectedPhpExtension.name;

    if (this.state.selectedPhpExtension.enabled) {
      return actions.deleteItem(
        {
          itemId: this.state.selectedPhpExtension.id,
          bodyParams: {
            domain_id: selectedDomainId,
            version: this.state.selectedPhpExtension.version
          },
          _metaFields: {
            ...API_RESOURCE.PHP_EXTENSION
          },
          _meta: {
            notification: {
              type: 'generic',
              success: {
                intlKey: 'translate.change-php-extension.off.notification.success',
                intlValues: {
                  extension: extensionName
                }
              },
              error: {
                intlKey: 'translate.change-php-extension.off.notification.fail',
                intlValues: {
                  extension: extensionName
                }
              }
            }
          }
        },
        onUpdateCompleted
      );
    }

    return actions.createItem(
      {
        id: this.state.selectedPhpExtension.id,
        domain_id: selectedDomainId,
        version: this.state.selectedPhpExtension.version,
        _metaFields: {
          ...API_RESOURCE.PHP_EXTENSION
        },
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.change-php-extension.on.notification.success',
              intlValues: {
                extension: extensionName
              }
            },
            error: {
              intlKey: 'translate.change-php-extension.on.notification.fail',
              intlValues: {
                extension: extensionName
              }
            }
          }
        }
      },
      onUpdateCompleted
    );
  };

  updatePhpMod = (updatedPhpMod: { phpMod: PhpModType }) => {
    const { intl, phpMod, actions, mainDomain, closeSGDialog } = this.props;

    const onUpdateCompleted = () => {
      actions.fetchItems({ ...API_RESOURCE.USERVICE });
      this.fetchPHPExtensions();
      closeSGDialog(DIALOGS.PHP_CHANGE_MOD);
    };

    const TRANSLATIONS_BY_PHP_MOD = {
      mod_php: intl.formatMessage({ id: 'translate.page.php-manager.ultrafast-php-mod.label' }),
      standard: intl.formatMessage({ id: 'translate.page.php-manager.standard-php-mod.label' })
    };

    const notification: SpanelNotification = {
      type: 'generic',
      success: {
        intlKey: 'translate.change-php-mod.notification.success',
        intlValues: {
          domain: mainDomain.name,
          phpMod: TRANSLATIONS_BY_PHP_MOD[updatedPhpMod.phpMod]
        }
      },
      error: {
        intlKey: 'translate.change-php-mod.notification.error',
        intlValues: {
          domain: mainDomain.name
        }
      }
    };

    if (phpMod === PHP_ULTRAFAST && updatedPhpMod.phpMod === PHP_STANDARD) {
      // updated to standard and current is mod_php
      return actions.deleteItem(
        {
          itemId: 'apache',
          _metaFields: {
            ...API_RESOURCE.USERVICE
          },
          _meta: { notification }
        },
        onUpdateCompleted
      );
    }

    if (phpMod === PHP_STANDARD && updatedPhpMod.phpMod === PHP_ULTRAFAST) {
      const selectedDomain = this.getSelectedDomain();
      const pageState = getPhpManagerState({ phpMod, domainType: getDomainType(selectedDomain) });

      const currentPhpVersion = this.getUsedPhpVersion(pageState);

      const isCurrentVersionNotSupportingModPhp = !currentPhpVersion.mod_php;
      const isCurrentVersionManaged = Boolean(currentPhpVersion.recommended);

      const settings =
        isCurrentVersionNotSupportingModPhp || isCurrentVersionManaged ? {} : { php_version: currentPhpVersion.id };

      // updated to mod_php and current is standard
      return actions.createItem(
        {
          type: 'apache',
          settings,
          _metaFields: {
            ...API_RESOURCE.USERVICE
          },
          _meta: { notification }
        },
        onUpdateCompleted
      );
    }

    closeSGDialog(DIALOGS.PHP_CHANGE_MOD);
  };

  onEditPhpModClicked = () => {
    const { phpMod, isUAEnabled, isUltrafastModDisabled, requestNemoData, accountId, openSGDialog } = this.props;

    if (phpMod === PHP_ULTRAFAST) {
      return openSGDialog(DIALOGS.PHP_CHANGE_MOD);
    }

    switch (true) {
      case !isUltrafastModDisabled:
        return openSGDialog(DIALOGS.PHP_CHANGE_MOD);
      case isUltrafastModDisabled && isUAEnabled:
        openSGDialog(DIALOGS.PHP_MOD_UPGRADE_DIALOG);

        return requestNemoData(
          {
            endpoint: `/order_upgrade/${accountId}`,
            method: 'GET',
            requestTypeName: customRequestTypes.REQUEST_ACCOUNT_UPGRADE_PLANS
          },
          (upgradeResponse: OrderUpgradeData) => this.setState({ upgradeResponse })
        );
      case isUltrafastModDisabled && !isUAEnabled:
        return openSGDialog(DIALOGS.FEATURE_NOT_AVAILABLE);
      default:
        return;
    }
  };

  restoreDefaultPhpSetting = (id: string) => {
    const { actions, items, closeSGDialog } = this.props;
    const settings = this.prepareVariablesArrayToRestoreDefault({ id });

    if (settings.length === 0) {
      const hasValuesToRestore = items.locationPhpiniByDomainId[this.state.selectedDomainId];

      if (!hasValuesToRestore) {
        return closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING);
      }

      return actions.deleteItem(
        {
          itemId: items.locationPhpiniByDomainId[this.state.selectedDomainId].id,
          _metaFields: { ...API_RESOURCE.PHP_LOCATION_INI },
          _meta: {
            notification: {
              type: 'generic',
              success: {
                intlKey: 'translate.page.phpSettings.php_variable_updated',
                intlValues: { variableName: id }
              },
              error: {
                intlKey: 'translate.page.phpSettings.failed_update_php_variable',
                intlValues: { variableName: id }
              }
            }
          }
        },
        () => closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING)
      );
    }

    // if already has settings
    actions.updateItem(
      {
        id: items.locationPhpiniByDomainId[this.state.selectedDomainId].id,
        settings: this.prepareVariablesArrayToRestoreDefault({ id }),
        recursive: '1', // legacy api param
        _metaFields: { ...API_RESOURCE.PHP_LOCATION_INI },
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.page.phpSettings.php_variable_updated',
              intlValues: { variableName: id }
            },
            error: {
              intlKey: 'translate.page.phpSettings.failed_update_php_variable',
              intlValues: { variableName: id }
            }
          }
        }
      },
      () => closeSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING)
    );
  };

  prepareVariablesArrayToUpdate = ({ id, value }) => {
    const otherSetSettings: any[] = this.prepareVariablesArrayToRestoreDefault({ id });
    const { type } = this.props.items.phpVariableDataById[id];
    return [...otherSetSettings, { id, value }];
  };

  prepareVariablesArrayToRestoreDefault = ({ id }) => {
    /* When restoring to default value,
      the api needs all variables which have
      user values except the reset one */
    const { items } = this.props;
    const { selectedDomainId } = this.state;

    if (items.locationPhpiniByDomainId[selectedDomainId]) {
      const { settings } = items.locationPhpiniByDomainId[selectedDomainId];
      return Object.keys(settings).reduce((output, settingId: string) => {
        if (settingId === id) {
          return output;
        }

        return [...output, { ...settings[settingId] }];
      }, []);
    }

    return [];
  };

  getExtensionsColumns = () => [
    {
      header: () => this.props.intl.formatMessage({ id: 'translate.generic.name' }),
      accessor: 'id'
    },
    {
      header: this.props.intl.formatMessage({ id: 'translate.generic.status' }),
      accessor: 'enabled',
      style: {
        wordBreak: 'break-all'
      },
      render: (value, settingData) => {
        return Boolean(settingData.enabled)
          ? this.props.intl.formatMessage({ id: 'translate.generic.on' })
          : this.props.intl.formatMessage({ id: 'translate.generic.off' });
      }
    },
    {
      header: this.props.intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'enabled',
      render: (value, extension) => (
        <PageComponents.PhpExtensionContextMenu
          extension={extension}
          onEditClicked={(extension) => {
            this.setState({ selectedPhpExtension: extension }, () => {
              this.props.openSGDialog(DIALOGS.CHANGE_PHP_EXTENSION_SETTING);
            });
          }}
        />
      )
    }
  ];

  // Renders and table columns
  getVariablesColumns = () => [
    {
      header: () => <PageComponents.VariablesDesktopFilter renderFilterButton={this.renderFilterButton} />,
      accessor: 'id'
    },
    {
      header: this.props.intl.formatMessage({ id: 'translate.generic.value' }),
      accessor: 'value',
      style: {
        wordBreak: 'break-all'
      },
      render: (value, settingData) => {
        return (
          <PhpIniValue
            value={value}
            data={this.getSettingDataForCurrentDomain(settingData)}
            onAddValue={() => {
              const changeSettingPayload = {
                id: settingData.id,
                value
              };

              this.setState(
                {
                  selectedEditableVariable: changeSettingPayload
                },
                () => this.props.openSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING, changeSettingPayload)
              );
            }}
            type={
              this.props.items.phpVariableDataById[settingData.id] &&
              this.props.items.phpVariableDataById[settingData.id].type
            }
            customText={this.props.intl.formatMessage({ id: 'translate.page.phpSettings.custom.label' })}
            addValueText={this.props.intl.formatMessage({ id: 'translate.page.phpSettings.add_value.link' })}
          />
        );
      }
    },
    {
      header: this.props.intl.formatMessage({ id: 'translate.generic.actions' }),
      accessor: 'value',
      render: (value, entity) => (
        <PageComponents.SettingsContextMenu
          value={value}
          entity={entity}
          getSettingDataForCurrentDomain={this.getSettingDataForCurrentDomain}
          onEditClicked={({ changeSettingPayload }) =>
            this.setState(
              {
                selectedEditableVariable: changeSettingPayload
              },
              () => this.props.openSGDialog(REDUX_FORM.CHANGE_PHP_INI_SETTING, changeSettingPayload)
            )
          }
          onRestoreClicked={({ id }) =>
            this.setState(
              {
                selectedVariableToRestore: { id }
              },
              () => this.props.openSGDialog(DIALOGS.PHP_RESTORE_VARIABLE)
            )
          }
        />
      )
    }
  ];

  getVersionColumns = () => {
    const { openSGDialog } = this.props;
    const translateTooltip = this.props.intl.formatMessage({ id: 'translate.page.phpSettings.change.version.tooltip' });
    return [
      {
        header: this.props.intl.formatMessage({ id: 'translate.page.phpSettings.version.column.title' }),
        accessor: 'version'
      },
      {
        header: this.props.intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'actions',
        render: (value, data) => (
          <VCS resourceName={API_RESOURCE.PHP_LOCATION_VERSION.resourceNameMetaApi} hasMethod="PUT">
            <IconButton
              aria-label={translateTooltip}
              shape="circle"
              size="medium"
              icon="edit"
              data-e2e="table-action-edit"
              tooltip={this.props.intl.formatMessage({ id: 'translate.page.phpSettings.change.version.tooltip' })}
              onClick={() => openSGDialog(REDUX_FORM.CHANGE_PHP_VERSION)}
            />
          </VCS>
        )
      }
    ];
  };

  renderFilterButton = () => (
    <PageComponents.FilterButton
      value={this.state.phpVariableSearch}
      onChange={({ phpVariableSearch }) => this.setState({ phpVariableSearch })}
      onResetFilter={this.resetPhpVariableSearch}
    />
  );

  render() {
    const {
      phpMod,
      isUltrafastModDisabled,
      items,
      mainDomain,
      intl,
      environment,
      closeSGDialog,
      accountId,
      siteId,
      navigateToUA
    } = this.props;

    const { isPhone } = environment;

    const {
      tabActive,
      selectedDomainId,
      selectedEditableVariable,
      selectedDomainBoxTab,
      selectedVariableToRestore,
      upgradeResponse
    } = this.state;

    const selectedDomain = this.getSelectedDomain();

    const pageState: PhpManagerState = getPhpManagerState({
      phpMod,
      domainType: getDomainType(selectedDomain)
    });

    const phpVersionData = this.getUsedPhpVersion(pageState);
    const listedDomains = this.getDomainBoxDomains();
    const phpSettingsDisabled = Boolean(selectedDomainBoxTab === SiteTypes.StagingSites && listedDomains.length === 0);
    const DomainSelectForm = DOMAIN_SELECT_FORM_BY_TAB_ID[selectedDomainBoxTab];

    return (
      <React.Fragment>
        <PageHeader
          id={ToolId.phpSettings}
          instructions={intl.formatMessage({ id: 'translate.page.phpSettings.page.info.text' })}
        />
        <Section>
          <Grid>
            <DomainBox
              renderBeforeBoxChildren={
                <PageComponents.SitesTabs
                  selectedType={selectedDomainBoxTab}
                  onChangeType={(type: SiteTypes) => this.setState({ selectedDomainBoxTab: type })}
                />
              }
            >
              <DomainSelectForm
                domains={listedDomains}
                phpMod={phpMod}
                pageState={pageState}
                isUltrafastModDisabled={isUltrafastModDisabled}
                selectedDomainBoxTab={selectedDomainBoxTab}
                onDomainChange={(domainId) => {
                  this.setState({ selectedDomainId: domainId }, () => {
                    this.fetchPHPExtensions();
                  });
                }}
                onEditPhpModClick={this.onEditPhpModClicked}
                selectedDomainId={this.state.selectedDomainId}
              />
            </DomainBox>
            {!phpSettingsDisabled && (
              <div>
                <SecondLevelTitle>
                  {textToHTML(
                    intl.formatMessage(
                      {
                        id: 'translate.page.phpSettings.settings.subtitle.for'
                      },
                      { domainName: selectedDomain && selectedDomain.name }
                    )
                  )}
                </SecondLevelTitle>

                <Container padding="none">
                  <PageComponents.TableTabs
                    isPhone={isPhone}
                    tabActive={tabActive}
                    onTabChange={(nextTab) => this.setState({ tabActive: nextTab, phpVariableSearch: '' })}
                  />
                  <Grid padding={isPhone ? 'responsive' : 'none'} gap="none">
                    {tabActive === 'version' && (
                      <PageComponents.VersionTable
                        phpVersionData={phpVersionData ? [phpVersionData] : []}
                        columns={this.getVersionColumns()}
                        renderPhpVersionManageNotice={() => (
                          <PageComponents.PhpManageVersionNotice
                            environment={environment}
                            phpVersionManageType={this.getPhpVersionManageType(pageState)}
                            domainData={selectedDomain}
                            updatePhpVersion={this.updatePhpVersion}
                          />
                        )}
                      />
                    )}
                    {tabActive === 'variables' && (
                      <PageComponents.VariablesTable
                        phpVersionData={phpVersionData}
                        filteredData={filterPHPVariables(
                          this.props.items.locationPhpiniSettings,
                          this.state.phpVariableSearch
                        )}
                        columns={this.getVariablesColumns()}
                        resetPhpVariableSearch={this.resetPhpVariableSearch}
                        renderPhpVariablesMobileFilter={() => (
                          <PageComponents.VariablesMobileFilter renderFilterButton={this.renderFilterButton} />
                        )}
                      />
                    )}
                    {tabActive === 'extensions' && (
                      <PageComponents.ExtensionsTable
                        clearResource={this.props.actions.clearResource}
                        fetchItems={this.props.actions.fetchItems}
                        selectedDomainId={selectedDomainId}
                        phpExtension={this.props.items.phpExtension}
                        columns={this.getExtensionsColumns()}
                        phpVersion={this.getUsedPhpVersion(pageState)}
                      />
                    )}
                  </Grid>
                </Container>
              </div>
            )}
          </Grid>

          <PageComponents.UpdatePhpVersionDialog
            isPhone={isPhone}
            domainData={this.getSelectedDomain()}
            domainId={selectedDomainId}
            versionType={this.getPhpVersionManageType(pageState)}
            pageState={pageState}
            phpVersions={items.phpVersions}
            phpVersionData={phpVersionData}
            updatePhpVersion={this.updatePhpVersion}
            closeSGDialog={closeSGDialog}
          />

          <PageComponents.ChangePhpSettingDialog
            selectedVariable={selectedEditableVariable}
            settingData={selectedEditableVariable && items.phpVariableDataById[selectedEditableVariable.id]}
            updatePhpSettings={this.updatePhpSettings}
          />

          <PageComponents.RestorePhpVariableDialog
            variable={selectedVariableToRestore}
            restoreDefaultPhpSetting={this.restoreDefaultPhpSetting}
            closeSGDialog={closeSGDialog}
          />

          <PageComponents.ChangePhpModDialog
            environment={environment}
            phpMod={phpMod}
            domainName={mainDomain && mainDomain.name /* Php mod could be changed only on main domain */}
            onSubmit={this.updatePhpMod}
          />

          <PageComponents.UpgradePlanDialog
            upgradeResponse={upgradeResponse}
            onPlanConfirmed={({ planType }) =>
              navigateToUA({
                page: 'upgrade-order',
                siteId,
                planType,
                accountId
              })
            }
          />

          <PageComponents.UpdatePhpExtensionDialog
            environment={environment}
            extension={this.state.selectedPhpExtension}
            onSubmit={this.updatePhpExtension}
            closeSGDialog={closeSGDialog}
          />

          <FeatureNotAvailable />
        </Section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  environment: state.environment,
  items: {
    domain: state.pageItems.domain || [],
    // filtered /location-phpini_settings data (some variables not listed)
    locationPhpiniSettings: getAvailableLocationPhpSettings(state),
    // mapped /php-variable by id, fetched to access the type of the variables
    phpVariableDataById: getPhpVariablesById(state),
    locationPhpiniByDomainId: getLocationPhpIniById(state),
    phpVersions: state.pageItems.phpVersions || [],
    phpExtension: state.pageItems.phpExtension,
    locationPhpVersionByDomainId: getLocationPhpVersionByDomainId(state)
  },
  apacheUservice: getApacheUservice(state),
  phpMod: getPhpMod(state),
  isUltrafastModDisabled: isUltrafastModDisabled(state),
  defaultPhpVersion: getDefaultPhpVersion(state),
  mainDomain: findMainDomain(state, API_RESOURCE.DOMAIN.resourceName),
  accountId: getCurrentSiteAccountId(state),
  siteId: getCurrentSiteId(state),
  isUAEnabled: isACLActionEnabled(state, getToolMainEndpoint(ToolId.phpSettings), 'can_order')
});

export default indexWithCRUD(mapStateToProps, { ...sgDialogActions, requestNemoData, navigateToUA })(
  PhpSettings,
  API_RESOURCE.PHP_VARIABLE,
  API_RESOURCE.PHP_VERSION,
  API_RESOURCE.PHP_LOCATION_VERSION,
  API_RESOURCE.DOMAIN,
  API_RESOURCE.USERVICE
);
