import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Field, FieldArray, reduxForm } from 'redux-form';
import {
  Context,
  ContextMenu,
  ContextMenuItem,
  Flex,
  Grid,
  IconButton,
  Spacer,
  Text
} from '@siteground/styleguide/lib/components/';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';

interface Props extends WrappedComponentProps {
  activeItemIndex?: number;
  validationUtils?: ValidationUtils;
  onSubmit: Function;
}

class UpdateIPAdress extends React.Component<Props, any> {
  allRenderedFields = [];

  componentDidMount() {
    const { activeItemIndex } = this.props;
    if (activeItemIndex !== undefined) {
      this.allRenderedFields[activeItemIndex].focus();
    }
  }

  renderMembers = ({ fields }) => {
    const { intl, validationUtils } = this.props;
    const { required, ipWithExtras }: ValidationUtils = validationUtils;

    return (
      <React.Fragment>
        <Text size="small" color="light" weight="light" align="left">
          {intl.formatMessage({ id: 'translate.generic.ip.ip.range' })}
        </Text>
        <Grid>
          {fields.map((ip, index, array) => (
            <Flex wrap="nowrap" align="center" key={ip}>
              <Field
                name={ip}
                type="text"
                validate={[required, ipWithExtras]}
                onRefsReady={(ipRef) => {
                  this.allRenderedFields.push(ipRef);
                }}
                component={FormInput}
              />

              <Spacer size="x-small" />

              <Context.Consumer>
                {({ device }) =>
                  device.isPhone ? (
                    <React.Fragment>
                      <ContextMenu
                        opener={
                          <IconButton
                            shape="circle"
                            icon="dots"
                            aria-label={intl.formatMessage({ id: 'translate.aria-label.actions' })}
                          />
                        }
                      >
                        <ContextMenuItem icon="circle-plus" onClick={() => fields.insert(index + 1, '')}>
                          {intl.formatMessage({ id: 'translate.ssh.manage.ip.add.new' })}
                        </ContextMenuItem>

                        <ContextMenuItem
                          icon="circle-minus"
                          onClick={() => {
                            if (index === 0 && array.length === 1) {
                              fields.insert(index + 1, '');
                            }

                            fields.remove(index);
                          }}
                        >
                          {intl.formatMessage({ id: 'translate.ssh.manage.ip.remove' })}
                        </ContextMenuItem>
                      </ContextMenu>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <IconButton
                        aria-label={intl.formatMessage({ id: 'translate.aria-label.minus' })}
                        icon="circle-minus"
                        shape="circle"
                        onClick={() => {
                          if (index === 0 && array.length === 1) {
                            fields.insert(index + 1, '');
                          }

                          fields.remove(index);
                        }}
                      />

                      <IconButton
                        aria-label={intl.formatMessage({ id: 'translate.aria-label.plus' })}
                        icon="circle-plus"
                        shape="circle"
                        onClick={() => fields.insert(index + 1, '')}
                      />
                    </React.Fragment>
                  )
                }
              </Context.Consumer>
            </Flex>
          ))}
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return <FieldArray name="from" component={this.renderMembers} />;
  }
}

export default withSpanelForm(reduxForm({ form: REDUX_FORM.SSH_CHANGE_IP })(injectIntl(UpdateIPAdress)));
