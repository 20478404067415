import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';

type Props = {
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.SSH_CHANGE_KEY_NAME;

const ChangeKeyName = ({ validationUtils }: Props) => {
  const intl = useIntl();
  return (
    <Grid>
      <Field
        name="comment"
        type="text"
        label={intl.formatMessage({ id: 'translate.page.ssh.comment' })}
        placeholder={intl.formatMessage({ id: 'translate.page.ssh.comment' })}
        validate={[validationUtils.required]}
        component={FormInput}
      />
    </Grid>
  );
};

export default withSpanelForm(reduxForm({ form: formName })(ChangeKeyName));
