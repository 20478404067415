import * as Actions from '../constants/actions';

export const closeEditorTab = (entities = []) => ({
  type: Actions.CLOSE_CODE_EDITOR_TAB,
  payload: { entities }
});

export const closeTabWithConfirmation = (entity) => ({
  type: Actions.CLOSE_TAB_WITH_CONFIRMATION,
  payload: { entity }
});

export const closeTabsWithConfirmation = (entities = []) => ({
  type: Actions.CLOSE_TABS_WITH_CONFIRMATION,
  payload: { entities }
});

export const openFile = (entity) => ({
  type: Actions.OPEN_FILE,
  payload: {
    entity
  }
});

export const triggerFileSave = () => ({
  type: Actions.FILE_MANAGER_TRIGGER_FILE_SAVE
});
