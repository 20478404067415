import * as React from 'react';
import { connect } from 'react-redux';
import { getMetaDataByFormName } from '../../../core/selectors/form-selectors';
import { formValueSelector } from 'redux-form';

type Props = {
  formName: string;
  [otherProps: string]: any;
};

const generateFormMeta = (pageFormsIds: string[], formName: string, store) => {
  if (pageFormsIds && pageFormsIds.length) {
    return pageFormsIds.reduce(
      (output, formId: string) => ({
        ...output,
        [formId]: getMetaDataByFormName(store, formId)
      }),
      {}
    );
  }

  if (formName) {
    return getMetaDataByFormName(store, formName);
  }

  return null;
};

export const withReduxFormMetaData = (Wrapped, pageFormsIds: string[] = []) =>
  connect((store, { formName }: Props) => ({
    formMeta: generateFormMeta(pageFormsIds, formName, store),
    getFormValue: (sgFormName, ...fields: string[]) => formValueSelector(sgFormName)(store, ...fields)
  }))(Wrapped);

export default withReduxFormMetaData;
