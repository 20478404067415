import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';
import customRequestTypes from '../../../../core/constants/custom-request-types';

type Props = {
  children: any;
  intl: any;
};

const { resourceName } = API_RESOURCE.DOMAIN_ALIAS;
const formName = REDUX_FORM.CREATE_DOMAIN_ALIAS;

const CreateContainer = ({ children, intl }: Props) => (
  <VCS resourceName={resourceName} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.domainAlias.create.title' })}
      resourceName={resourceName}
      formName={formName}
      resources={[{ requestTypeName: customRequestTypes.DOMAIN_ALIAS }]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
