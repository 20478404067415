import { put, takeEvery } from 'redux-saga/effects';
import { openNewTab } from '@siteground/styleguide/lib/utils';
import * as sgDialogActions from '../actions/sg-dialog';
import { DIALOGS } from '../constants/common';
import { OPEN_NEW_TAB } from '../constants/open-new-tab';

function* openNewTabSaga({ payload }) {
  const { url } = payload;
  let isNewTabBlocked;
  if (!url) {
    return;
  }

  openNewTab({
    url,
    callback: (isPopupBlocked) => (isNewTabBlocked = isPopupBlocked)
  });

  if (isNewTabBlocked) {
    yield put(sgDialogActions.openSGDialog(DIALOGS.NEW_TAB_DIALOG));
  }
}

export default function* () {
  yield takeEvery(OPEN_NEW_TAB, openNewTabSaga);
}
