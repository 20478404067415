import * as React from 'react';
import { useIntl } from 'react-intl';
import { Text, Container } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import SGTable from '../../../components/sg-table';
import VCS from '../../../components/visibility-control-service';

export const ExtensionsTable = ({
  phpExtension = undefined,
  columns,
  fetchItems,
  clearResource,
  selectedDomainId,
  phpVersion
}) => {
  const intl = useIntl();

  React.useEffect(() => {
    fetchItems({
      ...API_RESOURCE.PHP_EXTENSION,
      urlParams: {
        domain_id: selectedDomainId,
        version: phpVersion.id
      }
    });
  }, []);

  return (
    <React.Fragment>
      <Container elevation="none">
        <Text>{intl.formatMessage({ id: 'translate.page.phpSettings.extensions.notice' })}</Text>
      </Container>
      <VCS resourceName={API_RESOURCE.PHP_EXTENSION.resourceNameMetaApi} hasMethod="GET">
        <SGTable
          shadow={false}
          data={phpExtension}
          columns={columns}
          mobileLayout="card-flat"
          resources={[
            {
              resourceName: API_RESOURCE.PHP_EXTENSION.resourceName,
              methods: ['GET', 'POST', 'DELETE']
            }
          ]}
        />
      </VCS>
    </React.Fragment>
  );
};

export default ExtensionsTable;
