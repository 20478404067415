import { LANGUAGES } from '../resseller-login/utils';
import formattedCurrency from '@siteground/styleguide/lib/utils/formatted-currency';

export const DNS_CENTRAL_RECORDS = 2;

export const CDN_SETTING_ID = {
  TTL: 'TTL',
  CDN: 'CDN',
  CACHE_PURGE: 'CACHE_PURGE',
  DEVELOPMENT_MODE: 'DEVELOPMENT_MODE',
  DYNAMIC_CACHE: 'DYNAMIC_CACHE',
  ALWAYS_ONLINE: 'ALWAYS_ONLINE'
};

const CDN_SCO_ID = {
  UK: 2,
  US: 4,
  SPAIN: 3
};

const CDN_RENEW_CURRENCY = {
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP',
  AUD: 'AUD'
};

export const DEFAULT_TTL_VALUE = 43200;

export const getScoUKLink = (lang) => {
  switch (lang) {
    case LANGUAGES.ENGLISH:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=2&lang=en_EN';
    case LANGUAGES.GERMAN:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=2&lang=de_DE';
    case LANGUAGES.ITALIAN:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=2&lang=it_IT';
    case LANGUAGES.SPANISH:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=2&lang=es_ES';
    default:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=2&lang=en_EN';
  }
};

export const getScoUSLink = (lang) => {
  switch (lang) {
    case LANGUAGES.ENGLISH:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=4&lang=en_EN';
    case LANGUAGES.GERMAN:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=4&lang=de_DE';
    case LANGUAGES.ITALIAN:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=4&lang=it_IT';
    case LANGUAGES.SPANISH:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=4&lang=es_ES';
    default:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=4&lang=en_EN';
  }
};

export const getScoSpainLink = (lang) => {
  switch (lang) {
    case LANGUAGES.ENGLISH:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=3&lang=en_EN';
    case LANGUAGES.GERMAN:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=3&lang=de_DE';
    case LANGUAGES.ITALIAN:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=3&lang=it_IT';
    case LANGUAGES.SPANISH:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=3&lang=es_ES';
    default:
      return 'https://www.siteground.com/viewtos/cdn_beta_terms?scid=3&lang=en_EN';
  }
};

export const getSGOptimizerLink = (lang) => {
  switch (lang) {
    case LANGUAGES.ENGLISH:
      return 'https://wordpress.org/plugins/sg-cachepress';
    case LANGUAGES.GERMAN:
      return 'https://de.wordpress.org/plugins/sg-cachepress';
    case LANGUAGES.ITALIAN:
      return 'https://it.wordpress.org/plugins/sg-cachepress/';
    case LANGUAGES.SPANISH:
      return 'https://es.wordpress.org/plugins/sg-cachepress';
    default:
      return 'https://wordpress.org/plugins/sg-cachepress';
  }
};

export const getCDNRenewPrice = (currency) => {
  switch (currency.currency) {
    case CDN_RENEW_CURRENCY.USD:
      return formattedCurrency(12.49, currency.currencySymbol, currency.currencySymbolBefore);
    case CDN_RENEW_CURRENCY.EUR:
      return formattedCurrency(9.99, currency.currencySymbol, currency.currencySymbolBefore);
    case CDN_RENEW_CURRENCY.AUD:
      return formattedCurrency(15.99, currency.currencySymbol, currency.currencySymbolBefore);
    case CDN_RENEW_CURRENCY.GBP:
      return formattedCurrency(7.99, currency.currencySymbol, currency.currencySymbolBefore);
    default:
      return formattedCurrency(12.49, '&#36;', true);
  }
};

export const getCDNTermsLink = (lang, scoId) => {
  switch (scoId) {
    case CDN_SCO_ID.UK:
      return getScoUKLink(lang);
    case CDN_SCO_ID.US:
      return getScoUSLink(lang);
    case CDN_SCO_ID.SPAIN:
      return getScoSpainLink(lang);
    default:
      return getScoUSLink(lang);
  }
};
