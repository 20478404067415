import * as React from 'react';
import { Field } from 'redux-form';
import { Grid, Section } from '@siteground/styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../components/dialogs';
import { SGDialogForm } from '../../components/sg-dialog';
import VCS from '../../components/visibility-control-service';
import { CreateBox, CreateForm } from './create';
import ChangeIPForm from './update/ip-form';

interface LocationIpBlockProps {
  actions: CrudActions;
  items: any;
  location: any;
  intl: any;
  create: () => void;
  openSGDialog: Function;
  closeSGDialog: Function;
}

type State = {
  currentUpdatePayload: any;
  currentDeleteConformationDialogPayload: any;
};

class LocationIpBlockPage extends React.Component<LocationIpBlockProps, State> {
  readonly state = {
    currentUpdatePayload: null,
    currentDeleteConformationDialogPayload: null
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_LOCATION_IP_BLOCK,
          success: {
            intlKey: 'translate.page.locationIpBlock.create.success.message',
            intlValues: { ip: formData.addr }
          },
          error: {
            intlKey: 'translate.page.locationIpBlock.create.error.message',
            intlValues: { ip: formData.addr }
          }
        }
      }
    });
  };

  render() {
    const { intl } = this.props;
    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.page.locationIpBlock.domain_name' }),
        accessor: 'domain_name'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.ip.ip.range' }),
        accessor: 'addr'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <div>
        <PageHeader
          id={ToolId.blockIPs}
          instructions={intl.formatMessage({ id: 'translate.page.locationIpBlock.instructions' })}
        />
        <Section>
          <Grid>
            <CreateBox>
              <CreateForm domain={this.props.items.domain} onSubmit={this.onCreateFormSubmit} />
            </CreateBox>

            <VCS resourceName={API_RESOURCE.LOCATION_IP_BLOCK.resourceNameMetaApi} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.locationIpBlock.list.title' })}
                data={this.props.items.locationIpBlock}
                resources={[
                  {
                    resourceName: API_RESOURCE.LOCATION_IP_BLOCK.resourceName,
                    methods: ['GET']
                  }
                ]}
                columns={columns}
                noDataMessage="translate.page.locationIpBlock.urls.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>

        {this.renderUpdateComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  }

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const ip = deletePayload && deletePayload.addr;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.locationIpBlock.delete.title' }, { ip })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderUpdateComponent = () => {
    const { intl, closeSGDialog } = this.props;

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_LOCATION_IP_BLOCK_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.locationIpBlock.update.title' })}
        resources={[
          {
            resourceName: API_RESOURCE.LOCATION_IP_BLOCK.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <ChangeIPForm
          initialValues={this.state.currentUpdatePayload}
          onSubmit={(data) =>
            this.props.actions.updateItem(data, () => closeSGDialog(REDUX_FORM.CHANGE_LOCATION_IP_BLOCK_DIALOG))
          }
        />
      </SGDialogForm>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const deletePayload = {
      itemId: id,
      name: entity.label,
      addr: entity.addr,
      _metaFields: { ...API_RESOURCE.LOCATION_IP_BLOCK },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.locationIpBlock.delete.success.message'
          },
          error: {
            intlKey: 'translate.page.locationIpBlock.delete.error.message'
          }
        }
      }
    };
    const updatePayload = {
      _metaFields: { ...API_RESOURCE.LOCATION_IP_BLOCK },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.locationIpBlock.update.success.message'
          },
          error: {
            intlKey: 'translate.page.locationIpBlock.update.error.message'
          }
        }
      },
      ...entity
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.LOCATION_IP_BLOCK.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            icon: 'edit',
            label: intl.formatMessage({ id: 'translate.generic.edit' }),
            e2eAttr: 'table-action-edit',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentUpdatePayload: updatePayload }, () =>
                openSGDialog(REDUX_FORM.CHANGE_LOCATION_IP_BLOCK_DIALOG)
              )
          },
          {
            vcsMethod: 'DELETE',
            icon: 'trash',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-delete',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
                openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };
}

export default indexWithCRUD(undefined, { ...sgDialogActions })(
  LocationIpBlockPage,
  API_RESOURCE.LOCATION_IP_BLOCK,
  API_RESOURCE.DOMAIN
);
