import * as React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Context, Spacer, Table } from '@siteground/styleguide';
import { DIALOGS } from '../../../../core/constants/common';
import { SGDialog } from '../../../components/sg-dialog';
import Entity from '../../content/entity';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { saveFile } from '../../core/actions/file-manager';
import { closeEditorTab } from '../../core/actions/code-editor';
import { RootState } from '../../../reducers';
import { getTranslations } from '../../../components/sg-table/translation';

import { getEntityPath, getEntityReadableName, getCodeEditorEntityUpdatedContent } from '../../core/utils';

const saveTabContent = ({ files, saveFile }) =>
  files.forEach((entity) => {
    const fileName = getEntityReadableName(entity);

    saveFile({
      urlParams: {
        filename: getEntityPath(entity)
      },
      file: getCodeEditorEntityUpdatedContent(entity),
      entity,
      // TODO: handle with action
      autoClose: true,
      _meta: {
        name: fileName,
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.file.manager.save.file.success.message',
            intlValues: { name: fileName }
          },
          error: {
            intlKey: 'translate.file.manager.save.file.error.message',
            intlValues: { name: fileName }
          }
        }
      }
    });
  });

type Props = {
  files?: any[];
  saveFile: typeof saveFile;
  closeEditorTab: typeof closeEditorTab;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
};

const CloseEditorTabsDialog = ({ files = [], saveFile, closeEditorTab, closeSGDialog }: Props) => {
  const intl = useIntl();
  const columns = [
    {
      header: intl.formatMessage({ id: 'generic.file-name.label' }),
      accessor: 'file',
      render: (file, entity) => {
        return <Entity entity={entity} />;
      }
    }
  ];

  return (
    <SGDialog
      id={DIALOGS.FILE_MANAGER_CONFIRM_CLOSE}
      density="small"
      title={intl.formatMessage({ id: 'translate.file.manager.code.editor.close.tab.title' })}
      icon="cross"
      state="inactive"
      footer={
        <Context.Consumer>
          {({ device }) => (
            <div style={{ display: 'inherit', width: '100%' }}>
              <Button
                type={device.isPhone ? 'link' : 'default'}
                data-e2e="footer-button-no-save"
                onClick={() => {
                  closeSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_CLOSE);
                  closeEditorTab(files);
                }}
              >
                {intl.formatMessage({ id: 'translate.generic.dont.save' })}
              </Button>
              <Spacer />

              <Button
                type={device.isPhone ? 'link' : 'default'}
                data-e2e="footer-button-close-dialog"
                onClick={() => closeSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_CLOSE)}
              >
                {intl.formatMessage({ id: 'translate.generic.close' })}
              </Button>

              <Button
                data-e2e="footer-button-save"
                color="primary"
                onClick={(e) => {
                  saveTabContent({ files, saveFile });
                  closeSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_CLOSE);
                }}
              >
                {intl.formatMessage({ id: 'translate.generic.save' })}
              </Button>
            </div>
          )}
        </Context.Consumer>
      }
    >
      <Table columns={columns} data={files} density="large" mobileLayout="row" translation={getTranslations(intl)} />
    </SGDialog>
  );
};

export default connect<any, any, any>(
  (state: RootState) => ({
    files: state.dialog[DIALOGS.FILE_MANAGER_CONFIRM_CLOSE] || []
  }),
  {
    ...sgDialogActions,
    saveFile,
    closeEditorTab
  }
)(CloseEditorTabsDialog);
