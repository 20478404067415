import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import BoxNotificationSG from '@siteground/styleguide/lib/components/box-notification';
import Button from '@siteground/styleguide/lib/components/button';
import { removeNotification } from '../../core/actions/notifications';
import { scrollToElementWrapper } from '../../core/common/dom-utils';
import { FORM_NOTIFICATION_DELAY } from '../../core/constants/common';
import * as NotificationSelectors from '../../core/selectors/notifications';

export type RenderCustomNotification = {
  notification: SpanelNotification;
  removeNotification: Function;
  renderDefaultNotificationTemplate: Function;
};

interface FormNotificationProps {
  renderCustomNotification?: (RenderCustomNotification) => any;
  removeNotification?: typeof removeNotification;
  notification: SpanelNotification;
  resourceName: string;
}

class FormNotification extends React.Component<FormNotificationProps & WrappedComponentProps, any> {
  routeChangeListener = null;

  componentDidMount() {
    const { notification, removeNotification } = this.props;
    const { id, formName } = notification;

    // scroll to notification
    const boxElement = document.querySelector('[data-component=spanel-form-notification]');
    const page = document.querySelector('.sg-page');
    scrollToElementWrapper(boxElement, page);

    // clear notification on route change
    this.routeChangeListener = browserHistory.listenBefore(() => removeNotification({ id, formName }));
  }

  componentWillUnmount() {
    // clear route listener
    if (this.routeChangeListener) {
      this.routeChangeListener();
    }
  }

  translateBackendError = (backendError: string) => {
    const { intl, notification } = this.props;
    const { error } = notification;

    if (error && error.intlKey) {
      return intl.formatMessage(
        {
          id: error.intlKey
        },
        {
          ...error.intlValues,
          backendError
        }
      );
    }

    const translationId = 'backendErrors.' + backendError;
    const translatedString = intl.formatMessage({ id: translationId });

    if (translatedString === translationId) {
      return backendError;
    }

    return translatedString;
  };

  createNotificationMessage = (resourceName: string) => {
    const { intl, notification } = this.props;
    const { success } = notification;
    const item = notification.responseData;

    if (success && success.intlKey) {
      return intl.formatMessage(
        {
          id: success.intlKey
        },
        {
          ...notification.responseData,
          ...success.intlValues
        }
      );
    }

    if (item && item.password !== undefined) {
      return intl.formatMessage(
        {
          id: 'translate.generic.created-password.message'
        },
        {
          resourceName,
          name: item.name,
          password: item.password
        }
      );
    }

    return intl.formatMessage(
      {
        id: 'translate.generic.created-item.message'
      },
      {
        resourceName,
        name: item.name || ''
      }
    );
  };

  removeFormNotification = () => {
    const { notification } = this.props;
    const { id, formName } = notification;

    this.props.removeNotification({ id, formName });
  };

  renderDefaultNotificationTemplate = () => {
    const { intl } = this.props;

    // TODO: add buttonText prop in the notification

    return (
      <Button
        color="secondary"
        type="outlined"
        data-e2e="box-notification-back-button"
        onClick={this.removeFormNotification}
      >
        {intl.formatMessage({ id: 'translate.create.box.default.button.label' })}
      </Button>
    );
  };

  render() {
    const { notification, renderCustomNotification, intl, resourceName } = this.props;
    const status = NotificationSelectors.isNotificationSuccess(notification) ? 'success' : 'error';

    return notification ? (
      <BoxNotificationSG
        autoFocus
        type={status}
        data-component="spanel-form-notification"
        data-e2e-box-notification-status={status}
        title={
          NotificationSelectors.isNotificationSuccess(notification)
            ? this.createNotificationMessage(resourceName)
            : this.translateBackendError(notification?.responseError?.message)
        }
      >
        {renderCustomNotification
          ? renderCustomNotification({
              notification,
              removeNotification: this.removeFormNotification,
              renderDefaultNotificationTemplate: this.renderDefaultNotificationTemplate
            })
          : this.renderDefaultNotificationTemplate()}
      </BoxNotificationSG>
    ) : null;
  }
}

export default connect<any, WrappedComponentProps, FormNotificationProps>(undefined, { removeNotification })(
  injectIntl(FormNotification)
);
