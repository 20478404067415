import { browserHistory } from 'react-router';
import { put, race, select, take, takeLatest } from 'redux-saga/effects';
import { openSGDialog } from '../../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import { getCurrentSiteId } from '../../../../core/selectors/index';
import * as FileManagerActions from '../actions/file-manager';
import * as FileManagerConstants from '../constants/actions';
import { getEntityByPath } from '../utils';
import * as requestActions from '../../../../core/actions/fetch';

const generatePreloadedFolders = (path: string) => {
  const pathParts = path.split('/');

  const entries = [];

  pathParts.forEach((part, index) => {
    const allPrev = pathParts.slice(0, index);
    entries.push(`${allPrev.join('/')}/${part}`);
  });

  return entries;
};

function* navigateToFMPath(action) {
  const { payload } = action;
  yield put(requestActions.httpRequestStarted(action));

  const { path } = payload;

  const siteId = yield select(getCurrentSiteId);

  browserHistory.replace(`${ROUTES[ToolId.filemanager]}?siteId=${encodeURI(siteId)}`);

  const entries = ['/', ...generatePreloadedFolders(path)];

  yield put(FileManagerActions.fetchDirs({ entries }));

  const { succeeded, failed } = yield race({
    succeeded: take(FileManagerConstants.FILE_MANAGER_FETCH_DIRS_SUCCEEDED),
    failed: take(FileManagerConstants.FILE_MANAGER_FETCH_DIRS_FAILED)
  });

  if (succeeded) {
    const { fileManager } = yield select();
    const selectedEntity = getEntityByPath(path, fileManager);

    yield put(requestActions.httpRequestSucceeded(action));

    if (selectedEntity) {
      yield put(FileManagerActions.onEntityClick({ entity: selectedEntity }));
    } else {
      yield put(openSGDialog(DIALOGS.FILE_MANAGER_NO_PATH_TO_SELECT));
    }

    return;
  }

  yield put(requestActions.httpRequestFailed(action, 'FAILED_TO_FETCH'));
}

function* navigate(): any {
  yield takeLatest(FileManagerConstants.FILE_MANAGER_NAVIGATE_TO_PATH, navigateToFMPath);
}

export default navigate;
