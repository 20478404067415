import { NemoRequestData } from '../../actions/nemo/types';

export const getInstalledExtrasRequest = ({ siteId, extraName }): NemoRequestData => ({
  endpoint: `/sites/${siteId}/extras/${extraName}`,
  method: 'GET'
});

export const postOrderExtraRequest = ({ body, requestTypeName }): NemoRequestData | any => ({
  endpoint: '/order_extra',
  method: 'POST',
  body,
  requestTypeName
});

export const getOrderStatusRequest = ({ orderId }): NemoRequestData => ({
  endpoint: `/order/${orderId}/status`,
  method: 'GET'
});

export const getWeeblyLoginLink = ({ siteId, itemId, requestTypeName }): NemoRequestData => ({
  endpoint: `/sites/${siteId}/weebly_login_link/${itemId}`,
  method: 'GET',
  itemId,
  requestTypeName
});
