import { put, takeEvery } from 'redux-saga/effects';
import * as sgDialogActions from '../actions/sg-dialog';
import * as ACTIONS from '../constants/actions';
import { DIALOGS } from '../constants/common';

export const handleRequestActionCallback = (action, response) => {
  const actionSuccessCallback = action && action.onComplete && typeof action.onComplete === 'function';

  if (actionSuccessCallback && response) {
    const data = response.data || response.result;
    action.onComplete(data);
  }
};

export const handleRequestFailureCallback = (action, responseErr) => {
  const actionFailureCallback = action && action.onFailure && typeof action.onFailure === 'function';

  if (actionFailureCallback) {
    action.onFailure(responseErr);
  }
};

function* handleRequestFailed({ payload }) {
  const { requestedAction, exception } = payload;

  handleRequestFailureCallback(requestedAction, exception);

  if (exception && exception.status === 429) {
    yield put(sgDialogActions.openSGDialog(DIALOGS.TOO_MANY_REQUEST_DIALOG));
  }
}

export default function* () {
  yield takeEvery(ACTIONS.HTTP_REQUEST_SUCCEEDED, function* ({ payload }) {
    const { requestedAction, response } = payload;

    handleRequestActionCallback(requestedAction, response);
  });

  yield takeEvery(ACTIONS.HTTP_REQUEST_FAILED, handleRequestFailed);
}
