import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, Link, Notice, Text } from '@siteground/styleguide';
import { openSGDialog } from '../../../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import { getCurrentSite } from '../../../../core/selectors';
import { normalizeDirectoryData } from '../../../../core/utils/paths';
import SelectPath, { SelectPathType } from '../../../components/dialogs/select-path';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';
import { RootState } from '../../../reducers';

type StateProps = {};

type DispatchProps = {
  openSGDialog: Function;
  intl: Intl;
};

type OwnProps = {
  initialValues: {
    db_name: string;
    filename: string;
    log_file?: string;
    _metaFields: any;
  };
  onSubmit: Function;
  change?: Function;
  validationUtils?: ValidationUtils;
};

interface Props extends StateProps, DispatchProps, OwnProps {}

const formName = REDUX_FORM.SELECT_DB_DUMP;

const SelectDatabaseDump = ({ change, intl, validationUtils, openSGDialog }: Props) => {
  const defaultPath = '/';

  return (
    <React.Fragment>
      <Grid gap="responsive">
        <Notice type="warning" background="light" shadow={false}>
          <Text color="dark">
            {CONFIG.IS_RESELLER ? (
              <FormattedMessage
                id="translate.page.database.select.db.dump.notice.message.reseller"
                values={{
                  link: (
                    <Link href={`${document.location.origin}${ROUTES[ToolId.backup]}`} target="_blank">
                      <FormattedMessage id="translate.navigation.page.backup_restore.label" />
                    </Link>
                  )
                }}
              />
            ) : (
              <FormattedMessage
                id="translate.page.database.select.db.dump.notice.message"
                values={{
                  link: (
                    <Link href="https://my.siteground.com/support/tutorials/php-mysql/mysql-export" target="_blank">
                      <FormattedMessage id="translate.page.database.select.db.dump.notice.message.link" />
                    </Link>
                  ),
                  verticalSpace: <br />
                }}
              />
            )}
          </Text>
        </Notice>

        <Field
          name="filename"
          type="text"
          readOnly
          showValidation
          label={intl.formatMessage({ id: 'translate.page.database.select.path.label' })}
          onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY)}
          suffix={
            <Button
              data-e2e="button-browse-select-directory"
              action="button"
              color="dark"
              size="small"
              onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY)}
            >
              {intl.formatMessage({ id: 'translate.generic.browse' })}
            </Button>
          }
          validate={[validationUtils.mysqlDump]}
          component={FormInput}
        />
      </Grid>

      <SelectPath
        title={intl.formatMessage({ id: 'translate.page.database.select.file.title' })}
        label={intl.formatMessage({ id: 'translate.page.database.select.file.label' })}
        initialPath={defaultPath}
        pathType={SelectPathType.file}
        onChange={(path) => change('filename', path)}
        expandedNodes={[defaultPath]}
        normalizeDirectoryData={normalizeDirectoryData}
      />
    </React.Fragment>
  );
};

export default connect<StateProps, any, OwnProps>(
  (store: RootState) => ({
    currentSite: getCurrentSite(store)
  }),
  {
    openSGDialog
  }
)(injectIntl(withSpanelForm(reduxForm({ form: formName })(SelectDatabaseDump))));
