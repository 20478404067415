import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Flex, Grid, InputState, PasswordGenerator, Text } from '@siteground/styleguide';
import { LoginIcon } from '@siteground/styleguide/lib/composite';

type Props = {
  intl: Intl;
  text: string;
  password: string;
  passwordInputMessage: string;
  passwordInputState: InputState;
  onSubmit: () => any;
  onChange: ({ password }) => any;
};

export class ChangePasswordForm extends React.Component<Props> {
  render() {
    const { onChange, onSubmit, passwordInputMessage, passwordInputState, password, text, intl } = this.props;

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Grid gap="x-large">
          <Grid gap="small">
            <LoginIcon icon="key" size="40" />
            <Text align="center">{text}</Text>
          </Grid>

          <Grid gap="large">
            <PasswordGenerator
              label={intl.formatMessage({ id: 'translate.reseller-login.site-panel.change-password.filed.label' })}
              onChange={(pass) => onChange({ password: pass })}
              state={passwordInputState}
              validationMessage={passwordInputMessage}
              value={password}
            />

            <Flex align="flex-end" direction="column">
              <Button id="create-pass-submit" color="secondary" size="x-large" style={{ alignSelf: 'stretch' }}>
                Submit
              </Button>
            </Flex>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default injectIntl(ChangePasswordForm);
