import * as React from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors, getFormValues } from 'redux-form';
import { sPanelFocusField } from '../../../core/actions/form-actions';

import {
  doesDomainExistInDNS,
  doesDomainStartsWithWWWInDNS,
  doesSubDomainStartsWithWWWInDNS,
  isEqualToPassword,
  isMysqlDumpValid,
  isNumber,
  isRequired,
  isValidAdminName,
  isValidDomain,
  isValidEmail,
  isValidEmailPunyCode,
  isValidFolder,
  isValidIpOrDomain,
  isValidMultipleEmails,
  isValidMultipleEmailsPunycode,
  isValidPassword,
  isValidURL,
  minSearchLength,
  validationWithMetaApi,
  validBase64Encode,
  validIp,
  validIpWithExtras,
  validPhpSettingValue
} from '../../../core/common/form-validations';

import { dispatchRemoteFormSubmit } from './remote-submit-util';

type Props = {
  siteMetaApi: SiteMetaApi;
  validationUtils: ValidationUtils;
  formValues: any;
  getFormValues: (forName) => any;
  sPanelFormSubmit: () => any;
  [otherProps: string]: any;
};

export const withSpanelForm = (FormComponent) => {
  return connect(
    ({ siteMetaApi, dir, ...store }): Partial<Props> => ({
      dir,
      siteMetaApi,
      formValues: (formName) => getFormValues(formName)(store) || {}, // TODO remove in favor of getFormValues
      formErrors: (formName) => getFormSyncErrors(formName)(store) || {}, // TODO remove in favor of getFormErrors
      getFormErrors: (formName) => getFormSyncErrors(formName)(store) || {},
      getFormValues: (formName) => getFormValues(formName)(store) || {},
      validationUtils: {
        /*
          TODO:
          ____________________________________

          Add new validation pure functions here,
          all MUST be of type `ValidationUtil`
          and don't forget to write unit tests for them ;)
        */
        required: isRequired,
        email: isValidEmail,
        emailPunycode: isValidEmailPunyCode,
        emailsPunycode: isValidMultipleEmailsPunycode,
        emails: isValidMultipleEmails,
        adminName: isValidAdminName,
        password: isValidPassword,
        ip: validIp,
        ipWithExtras: validIpWithExtras,
        url: isValidURL,
        domain: isValidDomain,
        folder: isValidFolder,
        number: isNumber,
        equalToPassword: isEqualToPassword,
        validationWithMetaApi,
        minSearchLength,
        validBase64Encode,
        validPhpSettingValue,
        isValidIpOrDomain,
        mysqlDump: isMysqlDumpValid,
        doesDomainExistInDNS: doesDomainExistInDNS,
        doesDomainStartsWithWWWInDNS: doesDomainStartsWithWWWInDNS,
        doesSubDomainStartsWithWWWInDNS: doesSubDomainStartsWithWWWInDNS
      }
    }),
    (dispatch) => ({
      sPanelFormSubmit: dispatchRemoteFormSubmit(dispatch),
      sPanelFocusField: (fieldName, formName) => dispatch(sPanelFocusField(fieldName, formName))
    })
  )(FormComponent) as any;
};

export default withSpanelForm;
