import * as React from 'react';
import { useIntl } from 'react-intl';
import { Filter } from '@siteground/styleguide';

export const FilterButton = ({ onChange, value, onResetFilter }) => {
  const intl = useIntl();
  return (
    <Filter
      config={{
        input: {
          type: 'input',
          onChange: ({ input }) => onChange({ phpVariableSearch: input.children[0].value }),
          children: [
            {
              placeholder: intl.formatMessage({ id: 'translate.generic.search' }),
              value
            }
          ]
        }
      }}
      translations={{
        closeBtnLabel: intl.formatMessage({ id: 'translate.generic.close' }),
        resetBtnLabel: intl.formatMessage({ id: 'translate.reset' })
      }}
      onResetFilter={onResetFilter}
    />
  );
};

export default FilterButton as typeof FilterButton;
