import 'whatwg-fetch';
import { openNewTab } from '@siteground/styleguide/lib/utils';
import { getCurrentSiteId, getSiteToken, getCurrentSite } from '../selectors';

export function downloadFile(endpoint: string, state: any, urlParams = {}) {
  const siteId: string = getCurrentSiteId(state);
  const siteToken: string = getSiteToken(state, siteId);
  const currentSite: SiteData = getCurrentSite(state);

  const apiHost = 'https://' + currentSite.api_url + '/api-sgcp/v00';

  let urlParamsStr = '';

  Object.keys(urlParams).forEach((key) => {
    urlParamsStr += key + '=' + encodeURIComponent(urlParams[key]) + '&';
  });

  let isNewTabOpened;
  const fileUrl = apiHost + endpoint + '?' + urlParamsStr + '_site_token=' + siteToken;
  openNewTab({ url: fileUrl, callback: (isPopupBlocked) => (isNewTabOpened = !isPopupBlocked) });

  return isNewTabOpened;
}
