import { createIntl, createIntlCache } from 'react-intl';
import getStore from './store';

// intl reference used only internally in this module
let intl;
const cache = createIntlCache();

// called when translations are updated
export const updateIntlInstance = () => {
  const store = getStore();
  const state = store.getState();
  intl = createIntl(
    {
      locale: 'en',
      messages: state.i18n.messages
    },
    cache
  );

  return intl;
};

type FormatMessageObject = {
  id: any;
  defaultMessage?: string;
  description?: string;
};

export const formatMessage = (message: FormatMessageObject, values?: any) => {
  const intlInstance = intl || updateIntlInstance();
  return intlInstance.formatMessage(message, values);
};
