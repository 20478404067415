import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Link, Text } from '@siteground/styleguide';
import { openNewTabAction, OpenNewTabPayload } from '../../../../core/actions/open-new-tab';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import MobileBreakElement from '../../../components/break-element';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

type Props = {
  children: any;
  selectedInstallation: string;
  setParentState?: (object) => any;
  openNewTab?: (payload: OpenNewTabPayload) => any;
  openSGDialog?: Function;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.WORDPRESS;
const formName = REDUX_FORM.CREATE_WP_MANAGE;

const CreateContainer = ({ children, selectedInstallation, setParentState, openNewTab, openSGDialog }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.wp.manage.create.title' })}
        resourceName={resourceName}
        formName={formName}
        renderTutorials={({ DefaultRender }) => (
          <DefaultRender
            title={intl.formatMessage({ id: 'translate.tips_and_tutorials.wordpress.title' })}
            text={intl.formatMessage({ id: 'translate.tips_and_tutorials.wordpress.content' })}
          >
            <Link transform="uppercase" weight="bold" onClick={() => openSGDialog(REDUX_FORM.ADD_EXISTING_APP)}>
              {intl.formatMessage({ id: 'translate.tips_and_tutorials.wordpress.link' })}
            </Link>
          </DefaultRender>
        )}
        resources={[
          {
            resourceName: API_RESOURCE.WORDPRESS.resourceName,
            methods: ['POST', 'GET']
          },
          {
            resourceName: API_RESOURCE.DOMAIN.resourceName,
            methods: ['GET']
          }
        ]}
        renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
          if (notification.state === 'error') {
            return renderDefaultNotificationTemplate();
          }

          if (notification.state === 'success' && selectedInstallation !== '') {
            setParentState({ selectedInstallation: '' });
          }

          const { responseData } = notification;
          const appUrl = responseData.app_url;
          const adminUrl = responseData.admin_url;

          return (
            <div style={{ textAlign: 'center' }}>
              <Text size="x-large" weight="extra-bold" color="dark" align="center" style={{ marginBottom: '10px' }}>
                {intl.formatMessage({ id: 'translate.generic.whats.next' })}
              </Text>

              <Button
                type="outlined"
                color="secondary"
                data-e2e="control-panel"
                onClick={() => openNewTab({ url: adminUrl })}
              >
                {intl.formatMessage({ id: 'translate.page.wp.manage.create.success.control.panel.label' })}
              </Button>

              <MobileBreakElement />

              <Button
                type="outlined"
                color="secondary"
                data-e2e="view-site"
                onClick={() => openNewTab({ url: appUrl })}
              >
                {intl.formatMessage({ id: 'translate.page.wp.manage.create.success.view.site.label' })}
              </Button>
            </div>
          );
        }}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openNewTab: (payload) => dispatch(openNewTabAction(payload)),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

export default connect<{}, {}, Props>(undefined, mapDispatchToProps)(CreateContainer);
