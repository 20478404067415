import * as React from 'react';
import {
  Button,
  Container,
  copyToClipboard,
  Flex,
  Grid,
  Link,
  Placeholder,
  Section,
  Spacer,
  Text,
  Textarea
} from '@siteground/styleguide';
import { createNotification } from '../../../core/actions/notifications';
import { setCaretToPosition } from '../../../core/common/dom-utils';
import { API_RESOURCE } from '../../../core/constants/api';
import { ToolId } from '../../../core/constants/route-info';
import { RootState } from '../../reducers';
import DomainSelect from '../../components/domain-select';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import PartialLoader from '../../components/partial-loader';
import SecondLevelTitle from '../../components/titles/second-level-title';
import VCS from '../../components/visibility-control-service';

type Props = {
  actions: {
    fetchItems: FetchItems;
  };
  createNotification: typeof createNotification;
  environment: any;
  errorlogs: any[];
  domain: any[];
  intl: Intl;
};

type State = {
  selectedDomainName: string;
};

const TEXTAREA_NEW_LINE = '\n\u202E\n';

class ErrorLogs extends React.Component<Props, State> {
  readonly state = {
    selectedDomainName: null
  };

  textareaRef = null;

  componentDidUpdate(prevProps) {
    if (!this.textareaRef) {
      return;
    }

    if (JSON.stringify(prevProps.errorlogs) !== JSON.stringify(this.props.errorlogs)) {
      this.textareaRef.scrollTop = this.textareaRef.scrollHeight;
      setCaretToPosition(this.textareaRef, 0, 0);
    }
  }

  fetchLogs = (name) => {
    this.props.actions.fetchItems({
      ...API_RESOURCE.ERRORLOGS,
      urlParams: {
        domain_name: name,
        lines_limit: 300
      }
    });
  };

  handleCopyToClipboard(value) {
    const { createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.generic.copied.to.clipboard'
        },
        error: {
          intlKey: 'translate.generic.failed.copied.to.clipboard'
        }
      });
    });
  }

  render() {
    const { environment, errorlogs, domain, intl } = this.props;
    const errors =
      errorlogs && errorlogs.map((error) => `${error.time} [${error.source}]${error.line}`).join(TEXTAREA_NEW_LINE);
    const shouldRenderErrors = errorlogs && errorlogs.length;

    return (
      <React.Fragment>
        <PageHeader
          id={ToolId.errorLog}
          instructions={intl.formatMessage({ id: 'translate.page.error-logs.instructions' })}
        />

        <VCS resourceName={API_RESOURCE.ERRORLOGS.resourceNameMetaApi} hasMethods={['GET']}>
          <Section>
            <Grid>
              <DomainSelect
                selectedValue={this.state.selectedDomainName}
                options={domain}
                optionValue="name"
                optionLabel="name"
                onChange={(name) => {
                  this.setState({ selectedDomainName: name });
                  this.fetchLogs(name);
                }}
              />

              <VCS resourceName={API_RESOURCE.ERRORLOGS.resourceName} hasMethod="GET">
                <div>
                  <SecondLevelTitle>
                    {intl.formatMessage({ id: 'translate.page.error-logs.list.title' })}
                  </SecondLevelTitle>

                  <Container padding={shouldRenderErrors ? 'responsive' : 'none'} style={{ position: 'relative' }}>
                    <PartialLoader
                      resources={[{ resourceName: API_RESOURCE.ERRORLOGS.resourceName, methods: ['GET'] }]}
                    >
                      {shouldRenderErrors ? (
                        <Textarea
                          readOnly
                          rows="20"
                          value={errors}
                          onRefsReady={(textarea) => (this.textareaRef = textarea)}
                          label={
                            <Flex justify="flex-end">
                              <Text>
                                <Link
                                  transform="capitalize"
                                  data-e2e="error-log-refresh"
                                  onClick={() => this.fetchLogs(this.state.selectedDomainName)}
                                >
                                  {intl.formatMessage({ id: 'translate.generic.refresh' })}
                                </Link>

                                <Spacer size="medium" />

                                <Link
                                  role="button"
                                  data-e2e="error-log-copy"
                                  onClick={() => this.handleCopyToClipboard(errors)}
                                >
                                  {intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                                </Link>
                              </Text>
                            </Flex>
                          }
                        />
                      ) : (
                        <Placeholder
                          icon="presentational-good-job-placeholder"
                          title={intl.formatMessage({ id: 'translate.page.error-logs.no.data.title' })}
                          message={intl.formatMessage({ id: 'translate.page.error-logs.no.data.message' })}
                        >
                          <Button color="primary" onClick={() => this.fetchLogs(this.state.selectedDomainName)}>
                            {intl.formatMessage({ id: 'translate.generic.refresh' })}
                          </Button>
                        </Placeholder>
                      )}
                    </PartialLoader>
                  </Container>
                </div>
              </VCS>
            </Grid>
          </Section>
        </VCS>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  environment: store.environment,
  errorlogs: store.pageItems.errorlogs || [],
  domain: store.pageItems.domain || []
});

export default indexWithCRUD(mapStateToProps, { createNotification })(ErrorLogs, API_RESOURCE.DOMAIN);
