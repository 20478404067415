import * as React from 'react';
import { Flex, Text } from '@siteground/styleguide';
import InformationIcon from '../../components/information-icon';
import { toSentence, translations } from './cron-parser';

type Props = {
  intl: Intl;
  entity: CronEntityValues;
};

export const IntervalInfo = ({ intl, entity }: Props) => {
  const predefinedInterval = translations(intl).intervals.find(
    (interval) =>
      interval.value[0] === entity.min &&
      interval.value[1] === entity.hour &&
      interval.value[2] === entity.day &&
      interval.value[3] === entity.mon &&
      interval.value[4] === entity.dow
  );

  const sentence = toSentence([entity.min, entity.hour, entity.day, entity.mon, entity.dow], intl);

  return predefinedInterval ? (
    <Flex>
      {predefinedInterval.name.split('(')[0]}
      <Text color="light">
        &nbsp;
        {predefinedInterval.value.length > 0 ? '(' + predefinedInterval.value.join(' ') + ')' : ''}
      </Text>
    </Flex>
  ) : (
    <Flex align="center" wrap="nowrap" style={{ wordBreak: 'break-all' }}>
      <Text truncate>
        {entity.min}&nbsp;{entity.hour}&nbsp;{entity.day}&nbsp;{entity.mon}&nbsp;{entity.dow}
      </Text>
      &nbsp;
      <InformationIcon tooltip={sentence} />
    </Flex>
  );
};

export default IntervalInfo;
