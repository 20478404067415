export enum RadioButtonValues {
  all = 'all',
  readonly = 'readonly',
  custom = 'custom'
}

export const initialFormState = {
  radiobuttons: [
    {
      label: 'translate.page.database.all.privileges',
      value: RadioButtonValues.all,
      size: 'medium',
      checked: true
    },
    {
      label: 'translate.page.database.read.only',
      value: RadioButtonValues.readonly,
      size: 'medium',
      checked: false
    },
    {
      label: 'translate.page.database.custom',
      value: RadioButtonValues.custom,
      size: 'medium',
      checked: false
    }
  ],
  checkboxes: [
    {
      label: 'ALTER',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'CREATE',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'DELETE',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'EXECUTE',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'DROP',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'EVENT',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'INDEX',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'INSERT',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'SELECT',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'TRIGGER',
      size: 'medium',
      checked: true,
      disabled: true
    },
    {
      label: 'UPDATE',
      size: 'medium',
      checked: true,
      disabled: true
    }
  ],
  selectedUserFromDropdown: ''
};
