import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Container, Grid, Link, Spacer, Text, Toolbar } from '@siteground/styleguide';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import PartialLoader from '../../../components/partial-loader';
import SecondLevelTitle from '../../../components/titles/second-level-title';
import StatisticsLineChart from '../../resource-stats/traffic/line-charts';
import './statistic-section.scss';

type Props = {
  domain: string;
  locationSearch: string;
  router: { push: Function };
  stats: any[];
  intl: Intl;
};

const StatisticSection = ({ domain, intl, stats, router, locationSearch }: Props) => (
  <div>
    <SecondLevelTitle className="title--break-strong">
      {intl.formatMessage({ id: 'translate.page.dashboard.statistic.title' }, { domain })}
    </SecondLevelTitle>

    <Container padding="none" tabIndex={0} style={{ position: 'relative' }}>
      <PartialLoader resources={[{ requestTypeName: customRequestTypes.DOMAIN_STATS }]}>
        <Grid sm="2" padding="responsive" gap="responsive">
          <StatisticsLineChart stats={stats} type="unique-visitors" />
          <StatisticsLineChart stats={stats} type="pageviews" />
        </Grid>
      </PartialLoader>

      <Toolbar style={{ marginTop: '0' }}>
        <Spacer />

        <Text weight="bold" transform="uppercase">
          <Link onClick={() => router.push(`${ROUTES[ToolId.statistics]}${locationSearch}`)}>
            {intl.formatMessage({ id: 'translate.page.dashboard.statistic.view.more' }, { domain })}
          </Link>
        </Text>
      </Toolbar>
    </Container>
  </div>
);

export default injectIntl(StatisticSection);
