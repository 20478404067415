import * as React from 'react';
import { injectIntl } from 'react-intl';
import { DIALOGS } from '../../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';

const UnknownPathDialog = ({ intl }) => (
  <SGDialog
    id={DIALOGS.FILE_MANAGER_NO_PATH_TO_SELECT}
    icon="warning"
    state="warning"
    title={intl.formatMessage({ id: 'translate.file.manager.path.no.longer.exist.dialog.title' })}
    footer={<SGDialogCancel id={DIALOGS.FILE_MANAGER_NO_PATH_TO_SELECT} />}
  />
);

export default injectIntl(UnknownPathDialog);
