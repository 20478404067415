import { API_RESOURCE } from '../../../core/constants/api';

export const CREATE_NEW_DOMAIN_OPTIONS_NAME = 'other';

export enum DNSTypes {
  DNS_A = 'A',
  DNS_AAAA = 'AAAA',
  DNS_CNAME = 'CNAME',
  DNS_MX = 'MX',
  DNS_SRV = 'SRV',
  DNS_TXT = 'TXT'
}

export const API_RESOURCE_MAP = {
  [DNSTypes.DNS_A]: API_RESOURCE.DNS_A,
  [DNSTypes.DNS_AAAA]: API_RESOURCE.DNS_AAAA,
  [DNSTypes.DNS_CNAME]: API_RESOURCE.DNS_CNAME,
  [DNSTypes.DNS_MX]: API_RESOURCE.DNS_MX,
  [DNSTypes.DNS_SRV]: API_RESOURCE.DNS_SRV,
  [DNSTypes.DNS_TXT]: API_RESOURCE.DNS_TXT
};
