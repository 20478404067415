import { Grid, Link, Notice, Text } from '@siteground/styleguide';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SG_OPTIMIZER_LINKS } from '../../../../core/constants/common';

type Props = {
  intl: Intl;
  lang: string;
};

export const DynamicCacheNotice = ({ intl, lang }: Props) => (
  <Notice
    shadow={false}
    type="instruction"
    background="light"
    title={intl.formatMessage({ id: 'translate.page.super-cacher.dynamic-cache-notice.title' })}
  >
    <Grid>
      <Text>
        <FormattedMessage
          id="translate.page.super-cacher.dynamic-cache-notice.info"
          values={{
            link: (
              <Link href={SG_OPTIMIZER_LINKS[lang]} target="_blank">
                {intl.formatMessage({ id: 'translate.page.super-cacher.dynamic-cache-notice.sg.optimizer.plugin' })}
              </Link>
            )
          }}
        />
      </Text>

      <Text>
        <FormattedMessage
          id="translate.page.super-cacher.dynamic-cache-notice.info.tutorial"
          values={{
            link: (
              <Link href="https://my.siteground.com/support/tutorials/supercacher/" target="_blank">
                {intl.formatMessage({ id: 'translate.page.superCacher.dynamic-cache.notice.info.tutorial.link' })}
              </Link>
            )
          }}
        />
        &nbsp;
        <FormattedMessage
          id="translate.page.super-cacher.dynamic-cache-notice.info.tutorial.details"
          values={{
            a: (text) => (
              <Link href={`${CONFIG.UA_URL}/support/kb/siteground-dynamic-caching-configuration/`} target="_blank">
                {text}
              </Link>
            )
          }}
        />
      </Text>
    </Grid>
  </Notice>
);

export default injectIntl(DynamicCacheNotice);
