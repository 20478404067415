export const parseJwt = (siteToken) => {
  const base64Url = siteToken.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const siteTokenWillExpireSoon = (siteToken: string): boolean => {
  const jwt = parseJwt(siteToken);
  const soon = Math.round(jwt.vsec / 4);
  return jwt.cts + jwt.vsec - Math.round(+new Date() / 1000) <= soon;
};
