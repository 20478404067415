import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../components/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../../components/sg-form';
import FormInput from '../../../../components/form-input';
import FormPassword from '../../../../components/form-password';

type Props = {
  intl: any;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => null;
};

const formName = REDUX_FORM.CREATE_SSH_KEY;

const Form = ({ intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required, password, validationWithMetaApi } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          name="comment"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.ssh.comment' })}
          placeholder={intl.formatMessage({ id: 'translate.page.ssh.comment' })}
          validate={[required, validationWithMetaApi]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="6">
        <Field
          style={{ display: 'none' }}
          name="password"
          label="fake_password_to_prevent_autofill"
          data-e2e="fake-password"
          component={FormPassword}
          aria-hidden
        />

        <Field
          name="password"
          type="password"
          label={intl.formatMessage({ id: 'translate.generic.password' })}
          validate={[required, password]}
          component={FormPassword}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} />
      </Column>
    </SGForm>
  );
};

const SSHForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.SSH_KEY,
      fetchItemsOnSuccess: [API_RESOURCE.SSH],
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(SSHForm));
