import { delay } from 'redux-saga';
import { call, cancelled, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { pingAvalonSucceed, pingNemoSucceed } from '../actions/ping-backend';
import { clientApi } from '../api/client';
import { siteApi } from '../api/site';
import { PING_AVALON, PING_NEMO, STOP_PING_AVALON, STOP_PING_NEMO } from '../constants/ping-backend';

function* pingAvalonRequest() {
  const state = yield select();

  try {
    while (true) {
      const response = yield call(
        siteApi({
          endpoint: '/ping',
          method: 'GET',
          state
        })
      );

      yield put(pingAvalonSucceed(response.status === 200));

      yield delay(5000);
    }
  } finally {
    yield cancelled();
  }
}

function* pingAvalon() {
  yield race({
    task: call(pingAvalonRequest),
    cancel: take(STOP_PING_AVALON)
  });
}

function* pingNemoRequest() {
  const state = yield select();

  try {
    while (true) {
      const response = yield call(
        clientApi(`/system/keep-alive?_client_token=${encodeURIComponent(state.session.clientToken)}`, 'GET')
      );

      yield put(pingNemoSucceed(response.status === 200));

      yield delay(5000);
    }
  } finally {
    yield cancelled();
  }
}

function* pingNemo() {
  yield race({
    task: call(pingNemoRequest),
    cancel: take(STOP_PING_NEMO)
  });
}

function* pingBackend(): any {
  yield takeLatest(PING_AVALON, pingAvalon);
  yield takeLatest(PING_NEMO, pingNemo);
}

export default pingBackend;
