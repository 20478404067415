import * as React from 'react';
import { injectIntl } from 'react-intl';
import Section from '@siteground/styleguide/lib/components/layout/section';
import { ToolId } from '../../../core/constants/route-info';
import { ROUTES } from '../../../core/constants/routes';
import PageHeader from '../../components/page-header';
import PageTabs from '../../components/page-tabs';

interface PgsqlPageProps {
  intl: any;
  location: any;
  router: any;
  children: any;
}

export const PgsqlPage = ({ intl, location, router, children }: PgsqlPageProps) => (
  <React.Fragment>
    <PageHeader id={ToolId.pgsql} instructions={intl.formatMessage({ id: 'translate.page.pgsql.instructions' })} />
    <Section>
      <PageTabs
        location={location}
        tabLabelsByTabId={{
          database: intl.formatMessage({ id: 'translate.generic.databases' }),
          user: intl.formatMessage({ id: 'translate.generic.users' }),
          access: intl.formatMessage({ id: 'translate.generic.remote' })
        }}
        routesByTabId={{
          database: ROUTES[ToolId.pgsqlDatabase],
          user: ROUTES[ToolId.pgsqlUser],
          access: ROUTES[ToolId.pgsqlAccess]
        }}
        onNavigate={router.push}
      />
    </Section>
    <Section>{children}</Section>
  </React.Fragment>
);

export default injectIntl(PgsqlPage);
