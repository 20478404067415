import { put, race, select, take, takeLatest, takeEvery } from 'redux-saga/effects';
import { actionTypes, change, submit, touch, reset, destroy } from 'redux-form';
import * as SpanelFormActionTypes from '../constants/form-actions';
import * as SpanelFormActions from '../actions/form-actions';
import { HTTP_REQUEST_SUCCEEDED } from '../constants/actions';
import { selectReduxFormData, selectFirstFormErrorField } from '../selectors/form-selectors';
import { focusFormInput, removeFocusFromCurrentElement } from '../common/dom-utils';

const REDUX_FORM_ACTION_TYPES = actionTypes;

const handleMissingFormData = (formName: string) =>
  console.error(`${formName} not found in store. Form was most probably destroyed.`);

export function* handleFormInitialization({ meta }) {
  const { form } = meta;
  const { values = {} } = yield select(selectReduxFormData, form);
  const { _metaFields = {} } = values;

  if (!_metaFields || !_metaFields.formName) {
    yield put(
      change(form, '_metaFields', {
        ..._metaFields,
        formName: form
      })
    );
  }
}

export function* handleSpanelFormSubmit({ formName }) {
  const submittedForm = yield select(selectReduxFormData, formName);

  if (!submittedForm) {
    return handleMissingFormData(formName);
  }

  const fieldsIds = Object.keys(submittedForm.registeredFields || []);

  // trigger validation for all fields, if not touched
  yield put(touch(formName, ...fieldsIds));

  // attempt to submit form
  yield put(submit(formName));

  // handle succeed/fail of the form submission
  const { succeeded, failed } = yield race({
    succeeded: take(REDUX_FORM_ACTION_TYPES.SET_SUBMIT_SUCCEEDED),
    failed: take(REDUX_FORM_ACTION_TYPES.SET_SUBMIT_FAILED)
  });

  if (failed) {
    const failedToSubmitFormData = yield select(selectReduxFormData, formName);

    if (!failedToSubmitFormData) {
      return handleMissingFormData(formName);
    }

    const errorField = yield select(selectFirstFormErrorField, formName);
    yield put(SpanelFormActions.sPanelFocusField(errorField, formName));
  }

  if (succeeded) {
    // workaround for forms submitted with pressing enter and validated on click after submission -> SPANEL-3447
    removeFocusFromCurrentElement();
  }
}

export function* handleInputFocus({ fieldName, formName }) {
  focusFormInput(fieldName, formName);
}

export default function* spanelForms() {
  yield takeLatest(SpanelFormActionTypes.SPANEL_FORM_SUBMIT, handleSpanelFormSubmit);
  yield takeLatest(SpanelFormActionTypes.SPANEL_INPUT_FOCUS, handleInputFocus);

  const { INITIALIZE, RESET, REGISTER_FIELD } = REDUX_FORM_ACTION_TYPES;
  // handleFormInitialization will place the formName into _metaFields, when it is not explicitly set
  // formName in _metaFields is needed for generic form requests handling and notifications, validation etc.
  yield takeEvery([INITIALIZE, RESET, REGISTER_FIELD], handleFormInitialization);
}
