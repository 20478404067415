import * as React from 'react';
import { injectIntl } from 'react-intl';
import MonacoEditor from 'react-monaco-editor';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Icon, Tab, Tabs } from '@siteground/styleguide/';
import { getUserTheme } from '../../../core/selectors/preferences';
import { RootState } from '../../reducers';
import * as codeEditorActions from '../core/actions/code-editor';
import * as fileManagerActions from '../core/actions/file-manager';
import { FILE_MANAGER_CODE_EDITOR_SUPPORTED_FORMATS } from '../core/constants/common';

import {
  getCodeEditorEntityContent,
  getCodeEditorEntityUpdatedContent,
  getEntityNameExtension,
  getEntityReadableName,
  isEntityActiveInCodeEditor,
  getMonacoTheme
} from '../core/utils';

import DefaultView from './default-view';
import './monaco-editor.scss';

const MONACO_EDITOR_ACTION = {
  FIND: 'actions.find',
  FIND_REPLACE: 'editor.action.startFindReplaceAction'
};

class EditorM extends React.Component<any, any> {
  editor;

  timeout;

  scrollTimeout;

  constructor(props) {
    super(props);
    this.state = {
      tabIndexToBeDeleted: null
    };
  }

  editorDidMount = (editor, monaco) => {
    this.editor = editor;

    this.editor.addCommand(monaco.KeyMod.CtrlCmd + monaco.KeyCode.KEY_S, this.props.actions.triggerFileSave);

    if (this.props.onRefsReady) {
      this.props.onRefsReady({
        openFindPanel: this.openFindPanel,
        openFindReplacePanel: this.openFindReplacePanel,
        downloadActiveTabContent: this.downloadActiveTabContent,
        resize: () => editor.layout()
      });
    }

    this.editor.onDidScrollChange((event) => {
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
      }

      this.scrollTimeout = setTimeout(() => this.saveCurrentTabViewState(), 300);
    });

    this.editor.focus();
  };

  downloadActiveTabContent = () => {
    const { files } = this.props;
    const activeEntity = files.find((entity) => isEntityActiveInCodeEditor(entity));
    const activeEntityUpdatedContent = getCodeEditorEntityUpdatedContent(activeEntity);
    const link = document.createElement('a');

    link.href = URL.createObjectURL(new Blob([activeEntityUpdatedContent], { type: 'text/plain' }));
    link.download = getEntityReadableName(activeEntity);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  openFindPanel = () => {
    this.editor.getAction(MONACO_EDITOR_ACTION.FIND).run();
  };

  openFindReplacePanel = () => {
    this.editor.getAction(MONACO_EDITOR_ACTION.FIND_REPLACE).run();
  };

  updateDimensions = () => {
    if (this.editor) {
      this.editor.layout();
    }
  };

  onChange = (newValue, event) => {
    const modifiedFiles = [...this.props.files];
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const activeFileIndex = this.props.files.findIndex((file) => file._meta.isActiveInCodeEditor);

    modifiedFiles[activeFileIndex]._meta.updatedContent = newValue;

    this.timeout = setTimeout(() => this.props.actions.codeEditorOnFileChange(modifiedFiles), 300);
  };

  saveCurrentTabViewState = () => {
    const { files } = this.props;
    const activeTab = files.find((file) => file._meta.isActiveInCodeEditor);
    const currentTabPath = activeTab && activeTab._meta.path;

    if (!currentTabPath) {
      return;
    }

    const currentViewState = this.editor.saveViewState();

    if (!currentViewState) {
      return;
    }

    this.props.actions.saveCurrentTabViewState({
      currentTabPath,
      currentViewState: JSON.stringify(currentViewState)
    });
  };

  applyNextTabViewState = (nextTabIndex) => {
    const { files, tabsViewState } = this.props;
    const nextTabFilePath = files[nextTabIndex] && files[nextTabIndex]._meta.path;

    if (nextTabFilePath && tabsViewState[nextTabFilePath]) {
      this.editor.restoreViewState(JSON.parse(tabsViewState[nextTabFilePath]));
    }
  };

  onTabClick = (tabIndex) => {
    this.applyNextTabViewState(tabIndex);

    this.props.actions.codeEditorChangeActiveTab(tabIndex);
  };

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.environment.width !== prevProps.environment.width) {
      this.updateDimensions();
    }
  }

  componentDidMount() {
    window.addEventListener('monaco-editor-resize-handler', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('monaco-editor-resize-handler', this.updateDimensions);
  }

  render() {
    const { actions, files, createFile } = this.props;

    if (files.length) {
      return this.renderCodeEditor();
    }

    return <DefaultView createFile={createFile} toggleCodeEditor={actions.toggleCodeEditor} />;
  }

  renderCodeEditor() {
    const { files, extension } = this.props;
    const activeTab = files.find((file) => file._meta.isActiveInCodeEditor);
    const activeTabContent = activeTab ? activeTab._meta.updatedContent : '';
    const activeTabExtension = getEntityNameExtension(activeTab);
    const editorLanguage = FILE_MANAGER_CODE_EDITOR_SUPPORTED_FORMATS[activeTabExtension] || 'plaintext';
    const theme = getMonacoTheme(this.props.theme);
    const options = {
      selectOnLineNumbers: true
    };

    return (
      <div className="code-editor" data-e2e="code-editor">
        <Tabs className="code-editor-tabs">{this.renderCodeEditorTab()}</Tabs>

        <div className="code-editor-container">
          <MonacoEditor
            theme={theme}
            language={editorLanguage}
            value={activeTabContent}
            options={options}
            onChange={this.onChange}
            editorDidMount={this.editorDidMount}
          />
        </div>
      </div>
    );
  }

  renderCodeEditorTab = () => {
    const { files, onTabClose } = this.props;

    return files.map((file, index) => {
      const isTabContentModified = getCodeEditorEntityContent(file) !== getCodeEditorEntityUpdatedContent(file);
      const tabClasses = ['code-editor-tab', isTabContentModified && 'code-editor-tab--modified']
        .filter(Boolean)
        .join(' ');

      return (
        <Tab
          key={index}
          className={tabClasses}
          data-e2e={`code-editor-tab-${index}`}
          active={file._meta.isActiveInCodeEditor}
          onClick={this.onTabClick.bind(this, index)}
        >
          <div>
            {getEntityReadableName(file)}

            {isTabContentModified && <strong>&nbsp;*</strong>}

            <Icon
              name="cross"
              size="8"
              className="code-editor-tab-icon"
              data-e2e="code-editor-tab-close"
              onClick={(event) => {
                event.stopPropagation();
                onTabClose(file);
              }}
            />
          </div>
        </Tab>
      );
    });
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions, ...codeEditorActions }, dispatch)
});

const mapStateToProps = (store: RootState) => ({
  theme: getUserTheme(store),
  files: store.fileManager.codeEditor.files,
  tabsViewState: store.fileManager.codeEditor.tabsViewState,
  environment: store.environment
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(EditorM));
