import { createAction } from 'redux-actions';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import * as Actions from '../constants/actions';
import { BrowserFileUploadPayload, UploadFilesPayload } from '../sagas/browser-file-upload';

export const clearFileManagerStore = createAction(Actions.FILE_MANAGER_CLEAR_STORE_PROPERTIES);
export const closeCodeEditor = createAction(Actions.FILE_MANAGER_CODE_EDITOR_CLOSE);
export const toggleCodeEditor = createAction(Actions.FILE_MANAGER_CODE_EDITOR_TOGGLE);
export const codeEditorChangeActiveTab = createAction(Actions.FILE_MANAGER_CODE_EDITOR_CHANGE_ACTIVE_TAB);
export const codeEditorOnFileChange = createAction(Actions.FILE_MANAGER_CODE_EDITOR_ON_FILE_CHANGE);
export const codeEditorOnTabClose = createAction(Actions.FILE_MANAGER_CODE_EDITOR_ON_TAB_CLOSE);

export const focusCodeEditorEntity = (entityId) => ({
  type: Actions.FILE_MANAGER_CODE_EDITOR_FOCUS_ENTITY,
  payload: {
    entityId
  }
});

export const deleteDir = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_DELETE_DIRS_REQUESTED,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_DELETE,
  onComplete
});

export const downloadSelectedFiles = () => ({ type: Actions.FILE_MANAGER_DOWNLOAD_FILE });

export const fetchDir = (payload, requestTypeName = customRequestTypes.FILE_MANAGER_FETCH_DIR) => ({
  type: Actions.FILE_MANAGER_FETCH_DIR_REQUESTED,
  payload,
  requestTypeName
});

export const fetchDirWithFolderSizes = () => ({ type: Actions.FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZE });

export const fetchDirs = createAction(Actions.FILE_MANAGER_FETCH_DIRS_REQUESTED);

export const fetchFile = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_FETCH_FILE_REQUESTED,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_FILE
});

export const fileManagerPostRequest = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_POST_REQUEST,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_POST,
  onComplete
});

export const extractDir = () => ({ type: Actions.FILE_MANAGER_EXTRACT_DIR });

export const copy = () => ({ type: Actions.FILE_MANAGER_COPY });
export const markForCopy = (payload) => ({ type: Actions.FILE_MANAGER_MARK_FOR_COPY, payload });
export const requestPaste = () => ({ type: Actions.FILE_MANAGER_REQUEST_PASTE });
export const paste = (payload) => ({ type: Actions.FILE_MANAGER_PASTE, payload });
export const onEntityClick = createAction(Actions.FILE_MANAGER_ON_SIDE_NAVIGATION_ENTITY_CLICK);
export const selectContentRows = createAction(Actions.FILE_MANAGER_SELECT_CONTENT_ROWS);
export const onContextNavigationEntityClick = createAction(Actions.FILE_MANAGER_ON_CONTEXT_NAVIGATION_ENTITY_CLICK);
export const clearContextEntities = createAction(Actions.FILE_MANAGER_CLEAR_CONTEXT_ENTITIES);
export const setContextMenuPosition = createAction(Actions.FILE_MANAGER_SET_CONTEXT_MENU_POSITION);
export const toggleNavigationList = createAction(Actions.FILE_MANAGER_TOGGLE_NAVIGATION_LIST);
export const uploadFiles = createAction(Actions.FILE_MANAGER_UPLOAD_FILES);

export const saveFile = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_SAVE_FILE,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_SAVE,
  onComplete
});

export const browserFileUpload = (payload: BrowserFileUploadPayload) => ({
  type: Actions.FILE_MANAGER_BROWSER_FILE_UPLOAD,
  payload
});

export const validationBeforeUploadFiles = (payload: UploadFilesPayload) => ({
  type: Actions.FILE_MANAGER_VALIDATION_BEFORE_UPLOAD_FILES,
  payload
});

export const dragAndDropFileUpload = (payload) => ({
  type: Actions.FILE_MANAGER_DRAG_AND_DROP_FILE_UPLOAD,
  payload
});

export const requestFilesMove = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_REQUEST_FILES_MOVE,
  payload,
  requestTypeName: customRequestTypes.FILE_MANAGER_MOVE_REQUEST,
  onComplete
});

export const moveFiles = (payload, onComplete = null) => ({
  type: Actions.FILE_MANAGER_FILES_MOVE,
  payload,
  onComplete
});

export const moveByDragAndDrop = (payload) => ({
  type: Actions.FILE_MANAGER_MOVE_BY_DRAG_AND_DROP,
  payload
});

export const uploadFileChunk = ({ urlParams = {}, chunk, append = false }, onComplete = null, onFailure = null) => ({
  type: Actions.FILE_MANAGER_UPLOAD_FILE_CHUNK,
  payload: {
    urlParams,
    chunk,
    append
  },
  onComplete,
  onFailure
});

export const changePermission = createAction(Actions.FILE_MANAGER_CHANGE_ENTITY_PERMISSIONS);
export const transferSizeChange = createAction(Actions.FILE_MANAGER_TRANSFER_SIZE_CHANGE);
export const clearUploadProgress = createAction(Actions.FILE_MANAGER_CLEAR_UPLOAD_PROGRESS);

export const navigateToFMPath = (path) => ({
  type: Actions.FILE_MANAGER_NAVIGATE_TO_PATH,
  payload: {
    path
  },
  requestTypeName: customRequestTypes.FILE_MANAGER_REQUESTED_NAVIGATE
});

export const getExistingFilesResponse = ({ entries }) => ({
  type: Actions.FILE_MANAGER_GET_EXISTING_FILES_RESPONSE,
  payload: {
    entries
  }
});

export const getExistingFilesResponseSucceeded = (response) => ({
  type: Actions.FILE_MANAGER_GET_EXISTING_FILES_RESPONSE_SUCCEEDED,
  payload: {
    response
  }
});

export const saveCurrentTabViewState = ({ currentTabPath, currentViewState }) => ({
  type: Actions.FILE_MANAGER_SAVE_CURRENT_TAB_VIEW_STATE,
  payload: {
    currentTabPath,
    currentViewState
  }
});
