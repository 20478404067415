import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as i18nActions from '../../../core/actions/i18n';
import * as sessionActions from '../../../core/actions/session';
import LanguageSwitcher from './language-context';

type Props = {
  sessionActions: typeof sessionActions;
  i18nActions: typeof i18nActions;
  [other: string]: any;
};

function withResellerLoginPage(Component) {
  class WithResellerLoginPage extends React.Component<Props> {
    componentDidMount() {
      const { sessionActions } = this.props;
      sessionActions.clearSessionData();
    }

    render() {
      const { sessionActions, ...props } = this.props;
      return (
        <LanguageSwitcher>
          <Component {...props} />
        </LanguageSwitcher>
      );
    }
  }

  return connect(undefined, (dispatch) => ({
    sessionActions: bindActionCreators(sessionActions as any, dispatch),
    i18nActions: bindActionCreators(i18nActions as any, dispatch)
  }))(WithResellerLoginPage);
}

export default withResellerLoginPage;
