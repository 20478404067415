import { RootState } from '../../web/reducers';
import { HostingTypes } from '@siteground/styleguide/lib/utils';

export const getCurrentDomainName = ({ sites }: Partial<RootState>) => sites.currentDomainName || null;
export const getCurrentDomainSiteID = ({ sites }: Partial<RootState>) => sites.currentSiteID || null;

export const getCurrentSite = ({ sites }: Partial<RootState>) => {
  const domainID = getCurrentDomainSiteID({ sites });
  const { items = [] } = sites;
  return items.find(({ id }) => id === domainID);
};

export const getCurrentSiteId = ({ sites }: Partial<RootState>): string => {
  const currentSite = getCurrentSite({ sites });
  return currentSite && currentSite.id;
};

export const getCurrentSiteAccountId = ({ sites }: Partial<RootState>): string => {
  const currentSite = getCurrentSite({ sites });
  return currentSite && currentSite.account_id;
};

export const isSitesListEmpty = ({ sites }: Partial<RootState>): boolean =>
  Boolean(sites && sites.items && sites.items.length === 0 && !sites.loading);

export const getAccountType = ({ sites }: Partial<RootState>): HostingTypes => {
  const site = getCurrentSite({ sites });
  return site && (site.account_type as HostingTypes);
};
