import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';
import { injectIntl } from 'react-intl';

import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../components/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../../components/sg-form';

import FormInput from '../../../../components/form-input';
import FormPassword from '../../../../components/form-password';

type Props = {
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => any;
  intl: Intl;
};

const formName = REDUX_FORM.CREATE_ITEM_LOCATION_USER;

const Form = ({ validationUtils, sPanelFormSubmit, intl }: Props) => {
  const { required, password, validationWithMetaApi } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          name="username"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.name' })}
          placeholder={intl.formatMessage({ id: 'translate.generic.name' })}
          component={FormInput}
          validate={[required, validationWithMetaApi]}
          normalize={(value) => value.toLowerCase()}
        />
      </Column>
      <Column smSpan="6">
        <Field
          name="password"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.password' })}
          placeholder={intl.formatMessage({ id: 'translate.generic.password.label' })}
          component={FormPassword}
          validate={[required, password]}
        />
      </Column>
      <Column smSpan="12">
        <SGSubmitButton formName={formName} />
      </Column>
    </SGForm>
  );
};

const ProtectedUsersCreateForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.LOCATION_USER,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(ProtectedUsersCreateForm));
