type Location = {
  pathname: string;
};

export const getAppHost = () => `${window.location.protocol}//${window.location.host}`;

export const getActiveTab = (
  location: Location,
  routesMappingByTabId: {
    [tabId: string]: string;
  }
): string => {
  const tabs = Object.keys(routesMappingByTabId);

  for (const tabId in routesMappingByTabId) {
    if (location.pathname.includes(routesMappingByTabId[tabId])) {
      return tabId;
    }
  }

  return tabs[0];
};
