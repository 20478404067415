import { formatCurrency } from '@siteground/styleguide/lib/utils';

export const extractPriceProps = (
  props: {
    currencyProps: CurrencyProps;
    planExpiresInLessThan30Days: boolean;
    plan: PlanDetails;
    isCustomPeriod: boolean;
  },
  intl
): PriceBoxContent => {
  const { planExpiresInLessThan30Days, plan, isCustomPeriod } = props;

  const currencyProps = {
    ...props.currencyProps,
    price: planExpiresInLessThan30Days ? props.currencyProps.regular_price : props.currencyProps.price
  };

  const renewalPriceTranslation = intl.formatMessage({ id: 'translate.renewal-price' });
  const renewalPrice = formatCurrency({ ...currencyProps, price: currencyProps.regular_price });

  const isCustomRenewal = isCustomPeriod || planExpiresInLessThan30Days;

  if (plan.status !== 'current') {
    const currentPriceText = formatCurrency({ ...currencyProps, price: currencyProps.price });

    return {
      // promoText: It shows "RENEWAL PRICE" text above the price if plan expires in less than 30 days
      promoText: isCustomRenewal ? plan.price[Object.keys(plan.price)[0]].discount_price_text : null,

      currentPriceText,

      // remove renewal price text below the price if plan expires in less than 30 days
      renewalPriceText: isCustomRenewal ? null : `${renewalPriceTranslation} ${renewalPrice}`,

      vatText: currencyProps.vatText
    };
  }

  return {
    current: true,
    currentPriceText: currencyProps.currentPriceText,
    renewalPriceText: `${renewalPriceTranslation} ${renewalPrice}`,
    vatText: currencyProps.vatText
  };
};
