import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  BorderType,
  Button,
  copyToClipboard,
  Flex,
  Grid,
  Link,
  Section,
  Text,
  Textarea,
  Container,
  Tile,
  Card,
  Toolbar,
  Spacer
} from '@siteground/styleguide';

import { navigateToUA } from '../../../core/actions/nemo-store';
import { createNotification } from '../../../core/actions/notifications';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import { ROUTES } from '../../../core/constants/routes';
import { RootState } from '../../reducers';
import { getCurrentSite } from '../../../core/selectors';
import { findAppNameByType } from '../../../core/utils/app-names';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog, FeatureNotAvailable } from '../../components/dialogs';
import { SGDialog, SGDialogCancel } from '../../components/sg-dialog';
import VCS from '../../components/visibility-control-service';
import { CreateBox, CreateForm } from './create';
import { SecondLevelTitle } from '../../components/titles';

type Props = {
  intl: Intl;
  items: {
    app: any[];
    appRepo: any[];
  };
  actions: {
    createItem: CreateItem;
    deleteItem: DeleteItem;
  };
  createNotification: Function;
  openSGDialog: Function;
  locationSearch: string;
  router: {
    push: Function;
  };
  dialogGitViewRepo: any;
  selectedSite: SiteItem;
  navigateToUA: typeof navigateToUA;
  shouldRenderSalesPage: boolean;
  environment: {
    isPhone: boolean;
  };
};

type State = {
  deleteDialogPayload: any;
};

class Git extends React.Component<Props, State> {
  readonly state = {
    deleteDialogPayload: null
  };

  handleCopyToClipboard(value) {
    const { createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.generic.copied.to.clipboard'
        },
        error: {
          intlKey: 'translate.generic.failed.copied.to.clipboard'
        }
      });
    });
  }

  onCreateFormSubmit = (formData) => {
    const { items, openSGDialog, selectedSite, actions } = this.props;
    const { app = [] } = items;
    const selectedApp = app.find((a) => a.id === formData.id);
    const domain = selectedApp && selectedApp.app_url;

    actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_GIT,
          success: {
            intlKey: 'translate.page.git.create.success-create.notification',
            intlValues: { domain }
          },
          error: {
            intlKey: 'translate.page.git.create.fail-create.notification',
            intlValues: { domain }
          }
        }
      }
    });
  };

  goToSSH = () => this.props.router.push(`${ROUTES[ToolId.ssh]}${this.props.locationSearch}`);

  renderDeleteDialog() {
    const { intl } = this.props;
    const { deleteDialogPayload } = this.state;
    const domain = deleteDialogPayload && deleteDialogPayload.app_url;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.git.delete.dialog.title' }, { domain })}
        icon="destroy"
        onSubmit={() =>
          this.props.actions.deleteItem({
            itemId: deleteDialogPayload.id,
            _metaFields: {
              ...API_RESOURCE.APP_REPO
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.git.delete.success.message',
                  intlValues: { domain }
                },
                error: {
                  intlKey: 'translate.page.git.delete.error.message',
                  intlValues: { domain }
                }
              }
            }
          })
        }
      />
    );
  }

  renderViewGitDialog() {
    const { intl, dialogGitViewRepo } = this.props;
    const domain = dialogGitViewRepo.app_url;
    const gitURL = dialogGitViewRepo.git_url;
    const ignoredFiles = dialogGitViewRepo.ignored_repo_files ? dialogGitViewRepo.ignored_repo_files : [];

    return (
      <SGDialog
        id={DIALOGS.GIT_VIEW_REPO}
        state="warning"
        icon="file-outlined"
        size="large"
        title={intl.formatMessage({ id: 'translate.page.git.view.repo.dialog.title' }, { domain })}
        footer={
          <SGDialogCancel id={DIALOGS.GIT_VIEW_REPO} label={intl.formatMessage({ id: 'translate.generic.close' })} />
        }
        resources={[
          { resourceName: API_RESOURCE.APP.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.APP_REPO.resourceName, methods: ['PUT'] }
        ]}
      >
        <Grid>
          <Textarea
            readOnly
            label={
              <Flex align="center" justify="space-between">
                <span style={{ marginRight: '15px' }}>
                  {intl.formatMessage({ id: 'translate.page.git.view.repo.clone.label' })}
                </span>
                <Link role="button" onClick={() => this.handleCopyToClipboard(gitURL)}>
                  {intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                </Link>
              </Flex>
            }
            value={gitURL}
          />

          {ignoredFiles.length > 0 && (
            <Textarea readOnly label={intl.formatMessage({ id: 'translate.page.git.view.repo.excluded.label' })}>
              {ignoredFiles.join('\n')}
            </Textarea>
          )}

          <Text align="left">
            <FormattedMessage
              id="translate.page.git.view.repo.ssh.key.message"
              values={{
                link: (
                  <Link onClick={this.goToSSH}>
                    <FormattedMessage id="translate.page.git.view.repo.ssh.key.link.text" />
                  </Link>
                )
              }}
            />
          </Text>
        </Grid>
      </SGDialog>
    );
  }

  renderContextMenu = (cell, entity) => {
    const { openSGDialog, intl } = this.props;

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.APP_REPO.resourceName}
        items={[
          {
            vcsMethod: 'GET',
            label: intl.formatMessage({ id: 'translate.page.git.sg-table.action.view' }),
            e2eAttr: 'git-context-menu-item-view',
            icon: 'file-outlined',
            onClick: () => this.setState({}, () => openSGDialog(DIALOGS.GIT_VIEW_REPO, entity))
          },
          {
            vcsMethod: 'DELETE',
            label: intl.formatMessage({ id: 'translate.page.git.sg-table.action.delete' }),
            e2eAttr: 'git-context-menu-item-delete',
            icon: 'destroy',
            onClick: () =>
              this.setState(
                {
                  deleteDialogPayload: { ...entity }
                },
                () => openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };

  renderOrderPage = () => {
    const { intl, environment, selectedSite, navigateToUA } = this.props;

    const DESKTOP_TILE_BORDER: BorderType = ['none', 'small', 'none', 'none'];
    const MOBILE_TILE_BORDER: BorderType = ['none', 'none', 'small', 'none'];

    return (
      <div>
        <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.git.order-page.title' })}</SecondLevelTitle>
        <Container padding="none">
          <Grid padding="responsive">
            <Text>{intl.formatMessage({ id: 'translate.page.git.order-page.desc' })}</Text>

            <Grid gap="none" sm="2">
              <Tile border={environment.isPhone ? MOBILE_TILE_BORDER : DESKTOP_TILE_BORDER} padding="none">
                <Card
                  iconName="product-website"
                  iconColor="bronze"
                  title={intl.formatMessage({ id: 'translate.page.git.order-page.column-one.title' })}
                  text={intl.formatMessage({ id: 'translate.page.git.order-page.column-one.text' })}
                  size="medium"
                  padding="small"
                  layout="vertical"
                  outline="none"
                  divided
                />
              </Tile>
              <Tile border="none" padding="none">
                <Card
                  iconName="product-pointer"
                  iconColor="bronze"
                  title={intl.formatMessage({ id: 'translate.page.git.order-page.column-two.title' })}
                  text={intl.formatMessage({ id: 'translate.page.git.order-page.column-two.text' })}
                  size="medium"
                  padding="small"
                  layout="vertical"
                  outline="none"
                  divided
                />
              </Tile>
            </Grid>
          </Grid>

          <Toolbar>
            <Spacer />
            <Button
              color="primary"
              onClick={() =>
                navigateToUA({
                  command: 'redirect',
                  page: 'upgrade-order',
                  accountId: selectedSite.account_id,
                  planType: 'shared_geek'
                })
              }
            >
              {intl.formatMessage({ id: 'translate.page.git.order-page.button.label' })}
            </Button>
          </Toolbar>
        </Container>
      </div>
    );
  };

  render() {
    const { intl, items, shouldRenderSalesPage } = this.props;
    const { appRepo = [], app = [] } = items;
    const data = appRepo && appRepo.filter((a) => a.have_git_repo);

    return (
      <React.Fragment>
        <PageHeader id={ToolId.git} instructions={intl.formatMessage({ id: 'translate.page.git.info' })} />

        <Section>
          {shouldRenderSalesPage ? (
            this.renderOrderPage()
          ) : (
            <Grid>
              <VCS resourceName={API_RESOURCE.APP_REPO.resourceNameMetaApi} hasMethods={['POST']}>
                <CreateBox>
                  <CreateForm app={app} onSubmit={this.onCreateFormSubmit} />
                </CreateBox>
              </VCS>

              <SGTable
                title={intl.formatMessage({ id: 'translate.page.git.table.title' })}
                resources={[
                  {
                    resourceName: API_RESOURCE.APP_REPO.resourceName,
                    methods: ['GET']
                  }
                ]}
                data={data}
                columns={[
                  {
                    header: intl.formatMessage({ id: 'translate.page.git.sg-table-head-site.title' }),
                    accessor: 'app_url'
                  },
                  {
                    header: intl.formatMessage({ id: 'translate.page.git.sg-table-head-application.title' }),
                    accessor: 'app',
                    render: (appType: string) => findAppNameByType(appType)
                  },
                  {
                    header: intl.formatMessage({ id: 'translate.generic.actions' }),
                    accessor: 'app',
                    render: this.renderContextMenu
                  }
                ]}
                noDataMessage="translate.page.git.no-table-data.text"
              />
            </Grid>
          )}
        </Section>

        {this.renderDeleteDialog()}
        {this.renderViewGitDialog()}

        <FeatureNotAvailable />
      </React.Fragment>
    );
  }
}

export default indexWithCRUD(
  (store: RootState) => ({
    environment: store.environment,
    locationSearch: store.routing.locationBeforeTransitions.search,
    dialogGitViewRepo: store.dialog[DIALOGS.GIT_VIEW_REPO] || {},
    selectedSite: getCurrentSite(store)
  }),
  { createNotification, navigateToUA, ...sgDialogActions }
)(Git, API_RESOURCE.APP, API_RESOURCE.APP_REPO);
