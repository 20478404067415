import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as Actions from '../../constants/actions';
import { siteApi } from '../../api/site';
import handleAvalonApiRequest from '../handle-avalon-api-request';
import { fetchItems } from '../../actions/crud';

export function* fetchArray(data: Partial<APIResourceData>[]) {
  for (const item of data) {
    yield put(fetchItems(item));
  }
}

export function* updateItemSaga(action: UpdateItemAction) {
  const state = yield select();

  const {
    resourceName,
    endpoint,
    dontChangeItemsState,
    fetchItemsOnSuccess = [],
    parentResourceName
  } = action.payload._metaFields;

  if (state.siteMetaApi && state.siteMetaApi.endpoints && state.siteMetaApi.endpoints[resourceName]) {
    const inputFields = state.siteMetaApi.endpoints[resourceName].input_fields;
    const outputFields = state.siteMetaApi.endpoints[resourceName].output_fields;

    if (inputFields && outputFields) {
      Object.keys(action.payload).forEach((fieldKey) => {
        if (!fieldKey.startsWith('_') && !inputFields[fieldKey] && !outputFields[fieldKey]) {
          console.error('Illegal PUT parameter:', fieldKey);
        }
      });
    }
  }

  const response = yield call(
    siteApi({
      endpoint: endpoint + '/' + encodeURIComponent(action.payload.id),
      method: 'PUT',
      body: {
        ...action.payload // includes _meta
      },
      state,
      urlParams: action.payload.urlParams
    })
  );

  const updatedItem = Object.assign({}, response.data);

  yield put({
    type: Actions.UPDATE_ITEM_SUCCEEDED,
    item: updatedItem,
    resourceName,
    dontChangeItemsState,
    parentResourceName
  });

  yield fetchArray(fetchItemsOnSuccess);

  return response;
}

function* updateItem(): any {
  yield takeEvery(Actions.UPDATE_ITEM_REQUESTED, handleAvalonApiRequest(updateItemSaga));
}

export default updateItem;
