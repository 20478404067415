import * as React from 'react';

type Props = {
  condition: boolean;
};

const RenderIf: React.FC<Props> = ({ condition, children }) => {
  return condition ? <React.Fragment>{children}</React.Fragment> : null;
};

export default RenderIf;
