import { isPasswordValid } from '@siteground/styleguide/lib/utils';
import { formatMessage } from '../translate';
import { findSubDomainByName, findSubDomainByNameWithWWW } from '../../web/pages/php-settings/utils';
import { REDUX_FORM } from '../constants/common';
import punycode from 'punycode';

export const NO_ERROR = undefined;
export const translateInvalidFieldValueError = () => formatMessage({ id: 'translate.validations.invalid_field' });

export const INVALID_PASSWORD_ERROR = 'validation error';

export const translatePasswordTooShort = () => formatMessage({ id: 'translate.validations.short_password' });
export const translatePasswordHasExtendedASCII = () =>
  formatMessage({ id: 'translate.validations.no.special.characters' });

export const translateRequiredField = () => formatMessage({ id: 'translate.form-validation.required-field.error' });

// eslint-disable-next-line max-len
export const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line max-len
export const emailRegExpPunyCode = new RegExp(
  /^(([^<>()\[\]\.,;|:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^~`!@#$%^&*()_+={}[\]|\/:;"'<>,? \s@\"]+\.)+[^~`!@#$%^&*()_+={}[\]|\/:;"'<>,?\s@\"]{2,})$/
);

export const adminNameRegExp = new RegExp(/^administrator$|^Administrator$|^user1$|^admin$|^Admin$|^user$|^User$/);

// eslint-disable-next-line no-control-regex
export const hasOnlyASCIICharacterRegExp = /^[\x00-\x7F]*$/;
/*
  UTILS declarations:
  *
  *
  All utils must be type `ValidationUtil` and return error string or NO_ERROR
*/
export const isRequired: ValidationUtil = (value): string => {
  if (Array.isArray(value) && value.length === 0) {
    return translateRequiredField();
  }

  if (value === undefined || value === null || value === '' || value === false) {
    return translateRequiredField();
  }

  return NO_ERROR;
};

export const isNumber: ValidationUtil = (value) => {
  if (isNaN(value)) {
    return formatMessage({ id: 'translate.validations.invalid_input' });
  }

  return NO_ERROR;
};

export const isValidURL: ValidationUtil = (url) => {
  /* tslint:disable */
  const re = /^(http:\/\/|https:\/\/)[a-z0-9À-ÿ\-]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,15}(:[0-9]{1,5})?(\/.*)?$/gm;
  /* tslint:enable */

  if (!url.match(re)) {
    return formatMessage({ id: 'translate.validations.invalid_url' });
  }

  return NO_ERROR;
};

export const isValidDomain: ValidationUtil = (domain, fields) => {
  const re = new RegExp(
    /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s/
  );
  // const re = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{2,})+$/);

  if (!domain.match(re)) {
    return formatMessage({ id: 'translate.validations.invalid_input' });
  }

  return NO_ERROR;
};

export const isValidFolder: ValidationUtil = (folder, fields) => {
  const ignoredFirstDash = folder[0] === '/' ? folder.substr(1).trim() : folder.trim();
  // eslint-disable-next-line no-control-regex
  const re = new RegExp(/^[a-zA-Z0-9\-\/]*$/);

  if (!ignoredFirstDash.match(re)) {
    return formatMessage({ id: 'translate.validations.invalid_folder' });
  }

  return NO_ERROR;
};

export const doesDomainExistInDNS: ValidationUtil = (domain, fields, formProps: any) => {
  if (
    findSubDomainByName({
      domainName: fields.name,
      domains: formProps.listedDomains,
      mainDomain: formProps.domainName
    })
  ) {
    return formatMessage({ id: 'translate.page.dns.field.this.subdomain.already.exists' });
  }

  return NO_ERROR;
};

export const doesDomainStartsWithWWWInDNS: ValidationUtil = (domain = '', fields, formProps: any) => {
  if (domain.toLowerCase() === 'www' && fields._metaFields.formName === REDUX_FORM.CREATE_DNS_A) {
    return formatMessage({ id: 'translate.page.dns.field.a.record.www.error' });
  }

  if (domain.toLowerCase() === 'www' && fields._metaFields.formName === REDUX_FORM.CREATE_DNS_AAAA) {
    return formatMessage({ id: 'translate.page.dns.field.aaa.record.www.error' });
  }

  return NO_ERROR;
};

export const doesSubDomainStartsWithWWWInDNS: ValidationUtil = (domain = '', fields, formProps: any) => {
  const subDomain = findSubDomainByNameWithWWW({
    domainName: fields.name,
    domains: formProps.listedDomains,
    mainDomain: formProps.domainName
  });

  if (subDomain && fields._metaFields.formName === REDUX_FORM.CREATE_DNS_A) {
    return formatMessage({ id: 'translate.page.dns.field.a.record.www.error' });
  }

  if (subDomain && fields._metaFields.formName === REDUX_FORM.CREATE_DNS_AAAA) {
    return formatMessage({ id: 'translate.page.dns.field.aaa.record.www.error' });
  }

  return NO_ERROR;
};

export const isValidEmail: ValidationUtil = (email) => {
  if (emailRegExp.test(email)) {
    return NO_ERROR;
  }

  return formatMessage({ id: 'translate.validations.invalid_email' });
};

export const isValidMultipleEmails: ValidationUtil = (value, fields) => {
  let error = NO_ERROR;

  if (value && Array.isArray(value)) {
    value.forEach((email) => {
      if (!emailRegExp.test(email.trim())) {
        error = formatMessage({ id: 'translate.validations.invalid_email_in_list' });
      }
    });
  }

  return error;
};

export const isValidEmailPunyCode: ValidationUtil = (email) => {
  if (emailRegExpPunyCode.test(email)) {
    return NO_ERROR;
  }

  return formatMessage({ id: 'translate.validations.invalid_email' });
};

export const isValidAdminName: ValidationUtil = (name) => {
  if (!adminNameRegExp.test(name)) {
    return NO_ERROR;
  }

  return formatMessage({ id: 'translate.validations.username.is.not.allowed' });
};

export const isValidMultipleEmailsPunycode: ValidationUtil = (value, fields) => {
  let error = NO_ERROR;

  if (value && Array.isArray(value)) {
    value.forEach((email) => {
      if (!emailRegExpPunyCode.test(email.trim())) {
        error = formatMessage({ id: 'translate.validations.invalid_email_in_list' });
      }
    });
  }

  return error;
};

export const isValidPassword: ValidationUtil = (password) => {
  const validation = isPasswordValid(password);

  if (!hasOnlyASCIICharacterRegExp.test(password)) {
    return translatePasswordHasExtendedASCII();
  }

  if (validation.isPasswordToShort) {
    return translatePasswordTooShort();
  }

  if (validation.isPasswordValid === false) {
    return INVALID_PASSWORD_ERROR;
  }

  return NO_ERROR;
};

export const isEqualToPassword: ValidationUtil = (value, fields) => {
  if (value !== fields.password) {
    return formatMessage({ id: 'translate.validations.password.not.equal' });
  }

  return NO_ERROR;
};

export const validateIntegerField = (value: any, { min, max }) => {
  const intValue = parseInt(value, 10);
  const isValidNumber = String(intValue) === String(value);

  if (isValidNumber) {
    const notInRange = Boolean(intValue < min || intValue > max);

    return notInRange ? translateInvalidFieldValueError() : NO_ERROR;
  }

  return value ? translateInvalidFieldValueError() : NO_ERROR;
};

/* tslint:disable:cyclomatic-complexity */
export const validationWithMetaApi: ValidationUtil = (
  value: any,
  { _metaFields },
  { siteMetaApi },
  fieldName: string
): string => {
  const punycodeFields = siteMetaApi?.types?.punnycode_fields?.fields;
  let valueToUse = value;
  const requestedResource = _metaFields && _metaFields.resourceNameMetaApi;
  const metaField = requestedResource && siteMetaApi.endpoints[requestedResource].input_fields[fieldName];
  const dontConvertToAscii = metaField && metaField.no_punny === 1;

  if (siteMetaApi.loading) {
    return NO_ERROR;
  }

  if (value && punycodeFields && punycodeFields.includes(fieldName) && !dontConvertToAscii) {
    valueToUse = punycode.toASCII(value);
  }

  if (!requestedResource || typeof siteMetaApi.endpoints[requestedResource].input_fields[fieldName] !== 'object') {
    return NO_ERROR;
  }

  // Validate Integers
  const validateAsInt = Boolean(
    requestedResource &&
      siteMetaApi.endpoints[requestedResource].input_fields &&
      siteMetaApi.endpoints[requestedResource].input_fields[fieldName] &&
      siteMetaApi.endpoints[requestedResource].input_fields[fieldName].type === 'integer'
  );

  if (validateAsInt) {
    const { min, max } = siteMetaApi.endpoints[requestedResource].input_fields[fieldName];
    return validateIntegerField(valueToUse, { min, max });
  }

  // Regexp
  if (siteMetaApi.endpoints[requestedResource].input_fields[fieldName].regex !== undefined) {
    const rx = new RegExp(siteMetaApi.endpoints[requestedResource].input_fields[fieldName].regex);
    return rx.test(valueToUse) ? undefined : translateInvalidFieldValueError();
  } else if (siteMetaApi.endpoints[requestedResource].input_fields[fieldName].label !== undefined) {
    const label = siteMetaApi.endpoints[requestedResource].input_fields[fieldName].label;
    if (siteMetaApi.labels[label] !== undefined && siteMetaApi.labels[label].regex !== undefined) {
      const rx = new RegExp(siteMetaApi.labels[label].regex);
      return rx.test(valueToUse) ? NO_ERROR : formatMessage({ id: siteMetaApi.labels[label].message });
    }
  }
};
/* tslint:disable:cyclomatic-complexity */
export const isMysqlDumpValid: ValidationUtil = (value: string, { _metaFields }, { siteMetaApi, dir }): string => {
  if (!value || !siteMetaApi || !siteMetaApi.features) {
    return translateRequiredField();
  }

  const mysqlSize = siteMetaApi.features.mysql_size * 1000;
  const dirName = value.substr(0, value.lastIndexOf('/')) || '/';

  if (!dir[dirName]) {
    return translateRequiredField();
  }

  const entity = dir[dirName].find((e) => e._meta.path === value);

  if (!entity) {
    return translateRequiredField();
  }

  if (entity.s > mysqlSize) {
    return formatMessage({ id: 'translate.validations.mysql.dump.too.big' });
  }

  return NO_ERROR;
};
/* tslint:enable:cyclomatic-complexity */

export const rxIP = new RegExp(/^[A-Fa-f0-9.\-:*]+$/);

export const validIp = (value) => (!rxIP.test(value) ? translateInvalidFieldValueError() : NO_ERROR);

export const rxIPWithExtras = new RegExp(/^[A-Fa-f0-9.\-\%\/:*]+$/);

export const validIpWithExtras: ValidationUtil = (value, fields) =>
  !rxIPWithExtras.test(value) ? translateInvalidFieldValueError() : NO_ERROR;

export const isValidIpOrDomain: ValidationUtil = (value, ...formParams) => {
  const isValidIp = Boolean(validIpWithExtras(value, ...formParams) === NO_ERROR);

  const isValidDomainValue = Boolean(isValidDomain(value, ...formParams) === NO_ERROR);

  if (isValidIp || isValidDomainValue) {
    return NO_ERROR;
  }

  return translateInvalidFieldValueError();
};

export const validBase64Encode = (value) => {
  try {
    btoa(value);
  } catch (e) {
    return formatMessage({ id: 'translate.validations.invalid_input' });
  }
};

export const validPhpSettingValue: ValidationUtil = (value, { settingType }) => {
  switch (settingType) {
    case 'number':
      return /^\s*-?[0-9]+\s*$/.test(value) ? NO_ERROR : translateInvalidFieldValueError();
    case 'decimal':
      return /^\s*-?[0-9]+(\.[0-9]+)?\s*$/.test(value) ? NO_ERROR : translateInvalidFieldValueError();
    case 'size':
      return /^\s*[0-9]+[KMG]?\s*$/.test(value) ? NO_ERROR : translateInvalidFieldValueError();
    case 'color':
      return /^\s*#?[0-9A-Fa-f]{6}\s*$/.test(value) ? NO_ERROR : translateInvalidFieldValueError();
    case 'path':
      return /^\s*(\/?[a-zA-Z_\-=\.0-9]*)+\s*$/.test(value) ? NO_ERROR : translateInvalidFieldValueError();
    default:
      return NO_ERROR;
  }
};

export const minSearchLength: ValidationUtil = (value): string => {
  if (value.length < 3) {
    return formatMessage({ id: 'translate.validations.not.enough.symbols.for.search' });
  }

  return NO_ERROR;
};
