import * as React from 'react';
import { useIntl } from 'react-intl';
import { Notice } from '@siteground/styleguide';
import SecondLevelTitle from './titles/second-level-title';

export const EmailToolNotAvailable = () => {
  const intl = useIntl();
  return (
    <div>
      <SecondLevelTitle />

      <Notice type="warning" title={intl.formatMessage({ id: 'translate.page.dns.not-available-notice.title' })}>
        {intl.formatMessage({ id: 'translate.page.email.not-available-notice.text' })}
      </Notice>
    </div>
  );
};

export default EmailToolNotAvailable as () => any;
