import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';

import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

type Props = {
  children: any;
  intl: Intl;
};

const { resourceName } = API_RESOURCE.EMAIL_AUTORESPONDER;
const formName = REDUX_FORM.CREATE_EMAIL_AUTORESPONDER;

const CreateContainer = ({ children, intl }: Props) => (
  <VCS resourceName={resourceName} hasMethod="POST">
    <CreateBox
      title={intl.formatMessage({ id: 'translate.page.emailAutoresponder.create.title' })}
      resourceName={resourceName}
      formName={formName}
      resources={[
        {
          resourceName: API_RESOURCE.EMAIL_AUTORESPONDER.resourceName,
          methods: ['POST']
        }
      ]}
    >
      {children}
    </CreateBox>
  </VCS>
);

export default injectIntl(CreateContainer);
