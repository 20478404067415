import * as React from 'react';
import { Link } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import DateWithTime from '../../../components/date-with-time';
import indexWithCRUD from '../../../components/indexWithCRUD';
import SGTable from '../../../components/sg-table';
import { navigateToFMPath } from '../../../file-manager/core/actions/file-manager';
import { DEST_DIR, RESTORE_TYPE } from '../constants';

interface BackupRestoreHistoryProps {
  intl: any;
  items: any;
  navigateToFMPath: Function;
}

const restoreItemsMap = {
  full: 'translate.page.backup.restore.history.item.full',
  files: 'translate.page.backup.restore.history.item.files',
  emails: 'translate.page.backup.restore.history.item.emails',
  dbs: 'translate.page.backup.restore.history.item.dbs'
};

const formatFolderPath = (path = '', { prefix } = { prefix: '/' }) => {
  const formattedPath = path
    .replace(DEST_DIR, '')
    .split('/')
    .filter((str) => str !== '')
    .join('/');

  return `${prefix}${formattedPath}`;
};

const BackupRestoreHistoryPage = ({ items, intl, navigateToFMPath }: BackupRestoreHistoryProps) => {
  const restoreHistory = items[API_RESOURCE.BACKUP_RESTORE.resourceName];
  const columns = [
    {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restore.type' }),
      accessor: 'restore_type',
      render: (type) => intl.formatMessage({ id: restoreItemsMap[type] })
    },
    {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.backup.type' }),
      accessor: 'instant_backup',
      render: (backupType) =>
        backupType === 1
          ? intl.formatMessage({ id: 'translate.generic.manual' })
          : intl.formatMessage({ id: 'translate.generic.system' })
    },
    {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restored.to' }),
      accessor: 'restore_type',
      render: (cell, entity) => {
        if (cell === RESTORE_TYPE.FILE && entity.restore_data.files.dst_dir !== '/') {
          const folderPath = formatFolderPath(entity.restore_data.files.dst_dir);
          const folderPathToShow = formatFolderPath(entity.restore_data.files.dst_dir, { prefix: '' });

          return <Link onClick={() => navigateToFMPath(folderPath)}>{folderPathToShow}</Link>;
        }

        return intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restored.to.default.label' });
      }
    },
    {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restored.start.time' }),
      accessor: 'ts_start',
      mSize: '200px',
      render: (date, entity) => <DateWithTime date={date} />
    },
    {
      header: intl.formatMessage({ id: 'translate.page.backup.restore.history.list.restore.end.date' }),
      accessor: 'backup_id',
      mSize: '200px',
      render: (date, entity) => <DateWithTime date={parseInt(date, 10)} />,
      style: { textAlign: 'left', paddingLeft: '20px' }
    }
  ];

  return (
    <SGTable
      title={intl.formatMessage({ id: 'translate.page.backup.restore.history.list.title' })}
      data={restoreHistory}
      resources={[{ resourceName: API_RESOURCE.BACKUP_RESTORE.resourceName, methods: ['GET'] }]}
      columns={columns}
      noDataMessage="translate.page.backup.restore.history.no.data"
      disableMobileArrange
    />
  );
};

export default indexWithCRUD(undefined, { navigateToFMPath })(BackupRestoreHistoryPage, API_RESOURCE.BACKUP_RESTORE);
