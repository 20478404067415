import { getEntityPath } from '../utils';
import { getPrioritizedEntities } from './get-prioritized-entities';

export const codeEditor = (state) => {
  const prioritizedEntities = getPrioritizedEntities(state);
  const codeEditorFilesToClose = [];

  prioritizedEntities.forEach((entity) => {
    state.codeEditor.files.forEach((ent) => {
      if (getEntityPath(ent).indexOf(getEntityPath(entity)) !== -1) {
        codeEditorFilesToClose.push(ent);
      }
    });
  });

  return codeEditorFilesToClose;
};
