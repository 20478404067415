import { API_RESOURCE } from '../../../core/constants/api';
import { ENDPOINT } from '../../../core/constants/main-page-endpoint';
import { ToolId } from '../../../core/constants/route-info';
import { ROUTES } from '../../../core/constants/routes';
import { MenuGroupST, MenuItemST } from '../../../core/definitions/menu-items';
import { getToolKeywords } from '../../../core/utils/tool-keywords';
import { FILE_MANGER_ENDPOINTS } from '../../file-manager/core/constants/common';

const getEndpointName = (resource) => resource.endpoint.replace('/', '');

export const groups: MenuGroupST[] = [
  {
    toolId: ToolId.dashboard,
    items: [],
    route: ROUTES[ToolId.dashboard]
  },
  {
    toolId: ToolId.sites,
    items: [
      {
        toolId: ToolId.filemanager,
        route: ROUTES[ToolId.filemanager],
        pageEndpoint: ENDPOINT[ToolId.filemanager],
        apiEndpoints: FILE_MANGER_ENDPOINTS,
        keywords: getToolKeywords(ToolId.filemanager)
      },
      {
        toolId: ToolId.ftp,
        route: ROUTES[ToolId.ftp],
        pageEndpoint: ENDPOINT[ToolId.ftp],
        apiEndpoints: [],
        keywords: getToolKeywords(ToolId.ftp)
      },
      {
        toolId: ToolId.mysql,
        route: ROUTES[ToolId.mysql],
        pageEndpoint: ENDPOINT[ToolId.mysql],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.MYSQL_ACCESS),
          getEndpointName(API_RESOURCE.MYSQL_DB_GRANT),
          getEndpointName(API_RESOURCE.MYSQL_DB_USER)
        ],
        subStates: [ToolId.mysqlDatabase, ToolId.mysqlUser, ToolId.mysqlAccess, ToolId.mysqlPhpMyAdmin],
        keywords: getToolKeywords(ToolId.mysql)
      },
      {
        toolId: ToolId.pgsql,
        route: ROUTES[ToolId.pgsql],
        pageEndpoint: ENDPOINT[ToolId.pgsql],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.PGSQL_ACCESS),
          getEndpointName(API_RESOURCE.PGSQL_DB_GRANT),
          getEndpointName(API_RESOURCE.PGSQL_DB_USER)
        ],
        subStates: [ToolId.pgsqlDatabase, ToolId.pgsqlUser, ToolId.pgsqlAccess],
        keywords: getToolKeywords(ToolId.pgsql)
      }
    ]
  },
  {
    toolId: ToolId.security,
    items: [
      {
        toolId: ToolId.backup,
        route: ROUTES[ToolId.backup],
        pageEndpoint: ENDPOINT[ToolId.backup],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.BACKUP_RESTORE),
          getEndpointName(API_RESOURCE.BACKUP_RESTORE_DATABASE),
          getEndpointName(API_RESOURCE.BACKUP_RESTORE_EMAIL),
          getEndpointName(API_RESOURCE.BACKUP_RESTORE_FILES)
        ],
        keywords: getToolKeywords(ToolId.backup),
        subStates: [ToolId.backupCreate, ToolId.backupRestore]
      },
      {
        toolId: ToolId.ssl,
        route: ROUTES[ToolId.ssl],
        pageEndpoint: ENDPOINT[ToolId.ssl],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.SSL),
          getEndpointName(API_RESOURCE.SSL_LE),
          getEndpointName(API_RESOURCE.DOMAIN_ALL)
        ],
        localPageTaskLoaders: {
          createBox: [getEndpointName(API_RESOURCE.SSL), getEndpointName(API_RESOURCE.SSL_LE)]
        },
        keywords: getToolKeywords(ToolId.ssl)
      },
      {
        toolId: ToolId.httpsEnforce,
        route: ROUTES[ToolId.httpsEnforce],
        pageEndpoint: ENDPOINT[ToolId.httpsEnforce],
        apiEndpoints: [],
        keywords: getToolKeywords(ToolId.httpsEnforce)
      },
      {
        toolId: ToolId.protected,
        route: ROUTES[ToolId.protected],
        pageEndpoint: ENDPOINT[ToolId.protected],
        subStates: [ToolId.protectedUsers, ToolId.protectedURLs],
        apiEndpoints: [getEndpointName(API_RESOURCE.LOCATION_USER)],
        keywords: getToolKeywords(ToolId.protected)
      },
      {
        toolId: ToolId.blockIPs,
        route: ROUTES[ToolId.blockIPs],
        pageEndpoint: ENDPOINT[ToolId.blockIPs],
        apiEndpoints: [getEndpointName(API_RESOURCE.LOCATION_USER)],
        keywords: getToolKeywords(ToolId.blockIPs)
      },
      {
        toolId: ToolId.sitescanner,
        route: ROUTES[ToolId.sitescanner],
        pageEndpoint: ENDPOINT[ToolId.sitescanner],
        apiEndpoints: [getEndpointName(API_RESOURCE.DOMAIN)],
        keywords: []
      }
    ]
  },
  {
    toolId: ToolId.speed,
    items: [
      {
        toolId: ToolId.cacher,
        route: ROUTES[ToolId.cacher],
        pageEndpoint: ENDPOINT[ToolId.cacher],
        subStates: [ToolId.cacherMemcached, ToolId.cacherDynamic, ToolId.cacherStatic],
        apiEndpoints: [getEndpointName(API_RESOURCE.USERVICE)],
        keywords: getToolKeywords(ToolId.cacher)
      },
      {
        toolId: ToolId.cdn,
        route: ROUTES[ToolId.cdn],
        pageEndpoint: ENDPOINT[ToolId.cdn],
        apiEndpoints: [getEndpointName(API_RESOURCE.DOMAIN_CDN)],
        keywords: getToolKeywords(ToolId.cdn)
      },
      {
        toolId: ToolId.cloudflare,
        route: ROUTES[ToolId.cloudflare],
        pageEndpoint: ENDPOINT[ToolId.cloudflare],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.CLOUDFLARE_ZONE),
          getEndpointName(API_RESOURCE.CLOUDFLARE_ZONE_SETTINGS),
          getEndpointName(API_RESOURCE.CLOUDFLARE_FIREWALL)
        ],
        keywords: getToolKeywords(ToolId.cloudflare)
      }
    ]
  },
  {
    toolId: ToolId.wordpress,
    items: [
      {
        toolId: ToolId.wpManage,
        route: ROUTES[ToolId.wpManage],
        pageEndpoint: ENDPOINT[ToolId.wpManage],
        apiEndpoints: [getEndpointName(API_RESOURCE.APP), getEndpointName(API_RESOURCE.DOMAIN)],
        localPageTaskLoaders: {
          createBox: [getEndpointName(API_RESOURCE.WORDPRESS)]
        },
        keywords: getToolKeywords(ToolId.wpManage)
      },
      {
        toolId: ToolId.wpStaging,
        route: ROUTES[ToolId.wpStaging],
        pageEndpoint: ENDPOINT[ToolId.wpStaging],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.APP),
          getEndpointName(API_RESOURCE.APP_RESTORE_BACKUP),
          getEndpointName(API_RESOURCE.APP_STAGING)
        ],
        localPageTaskLoaders: {
          createBox: [getEndpointName(API_RESOURCE.APP_STAGING)]
        },
        keywords: getToolKeywords(ToolId.wpStaging)
      },
      {
        toolId: ToolId.wpTransfer,
        route: ROUTES[ToolId.wpTransfer],
        pageEndpoint: ENDPOINT[ToolId.wpTransfer],
        apiEndpoints: [getEndpointName(API_RESOURCE.DOMAIN_ALL)],
        keywords: getToolKeywords(ToolId.wpTransfer)
      },
      {
        toolId: ToolId.wpAutoupdate,
        route: ROUTES[ToolId.wpAutoupdate],
        pageEndpoint: ENDPOINT[ToolId.wpAutoupdate],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.DOMAIN),
          getEndpointName(API_RESOURCE.APP_LATEST),
          getEndpointName(API_RESOURCE.APP_UPDATE_CORE)
        ],
        keywords: getToolKeywords(ToolId.wpAutoupdate)
      },
      {
        toolId: ToolId.wpSearchAndReplace,
        route: ROUTES[ToolId.wpSearchAndReplace],
        pageEndpoint: ENDPOINT[ToolId.wpSearchAndReplace],
        apiEndpoints: [getEndpointName(API_RESOURCE.APP)],
        keywords: getToolKeywords(ToolId.wpSearchAndReplace)
      }
    ]
  },
  {
    toolId: ToolId.domains,
    items: [
      {
        toolId: ToolId.parkedDomains,
        route: ROUTES[ToolId.parkedDomains],
        pageEndpoint: ENDPOINT[ToolId.parkedDomains],
        apiEndpoints: [],
        keywords: getToolKeywords(ToolId.parkedDomains)
      },
      {
        toolId: ToolId.subdomain,
        route: ROUTES[ToolId.subdomain],
        pageEndpoint: ENDPOINT[ToolId.subdomain],
        apiEndpoints: [],
        keywords: getToolKeywords(ToolId.subdomain)
      },
      {
        toolId: ToolId.redirect,
        route: ROUTES[ToolId.redirect],
        pageEndpoint: ENDPOINT[ToolId.redirect],
        apiEndpoints: [getEndpointName(API_RESOURCE.DOMAIN)],
        keywords: getToolKeywords(ToolId.redirect)
      },
      {
        toolId: ToolId.dns,
        route: ROUTES[ToolId.dns],
        pageEndpoint: ENDPOINT[ToolId.dns],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.DNS_A),
          getEndpointName(API_RESOURCE.DNS_AAAA),
          getEndpointName(API_RESOURCE.DNS_CNAME),
          getEndpointName(API_RESOURCE.DNS_MX),
          getEndpointName(API_RESOURCE.DNS_SRV),
          getEndpointName(API_RESOURCE.DNS_TXT)
        ],
        keywords: getToolKeywords(ToolId.dns)
      }
    ]
  },
  {
    toolId: ToolId.mail,
    items: [
      {
        toolId: ToolId.email,
        route: ROUTES[ToolId.email],
        pageEndpoint: ENDPOINT[ToolId.email],
        apiEndpoints: [getEndpointName(API_RESOURCE.EMAIL)],
        keywords: getToolKeywords(ToolId.email)
      },
      {
        toolId: ToolId.emailForward,
        route: ROUTES[ToolId.emailForward],
        pageEndpoint: ENDPOINT[ToolId.emailForward],
        apiEndpoints: [getEndpointName(API_RESOURCE.EMAIL_FORWARD)],
        keywords: getToolKeywords(ToolId.email)
      },
      {
        toolId: ToolId.emailAutoresponder,
        route: ROUTES[ToolId.emailAutoresponder],
        pageEndpoint: ENDPOINT[ToolId.emailAutoresponder],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.EMAIL),
          getEndpointName(API_RESOURCE.DOMAIN),
          getEndpointName(API_RESOURCE.DOMAIN_ALIAS)
        ],
        keywords: getToolKeywords(ToolId.emailAutoresponder)
      },
      {
        toolId: ToolId.emailFilters,
        route: ROUTES[ToolId.emailFilters],
        pageEndpoint: ENDPOINT[ToolId.emailFilters],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.EMAIL),
          getEndpointName(API_RESOURCE.DOMAIN),
          getEndpointName(API_RESOURCE.DOMAIN_ALIAS)
        ],
        keywords: getToolKeywords(ToolId.emailFilters)
      },
      {
        toolId: ToolId.emailAuth,
        route: ROUTES[ToolId.emailAuth],
        pageEndpoint: ENDPOINT[ToolId.emailAuth],
        apiEndpoints: [getEndpointName(API_RESOURCE.DKIM), getEndpointName(API_RESOURCE.DOMAIN_ALL)],
        keywords: getToolKeywords(ToolId.emailAuth)
      },
      {
        toolId: ToolId.spamExperts,
        route: ROUTES[ToolId.spamExperts],
        pageEndpoint: ENDPOINT[ToolId.spamExperts],
        apiEndpoints: [getEndpointName(API_RESOURCE.SPAM_EXPERTS_LOGIN)],
        keywords: getToolKeywords(ToolId.spamExperts)
      },
      {
        toolId: ToolId.googleWorkspace,
        route: ROUTES[ToolId.googleWorkspace],
        pageEndpoint: ENDPOINT[ToolId.googleWorkspace],
        apiEndpoints: [getEndpointName(API_RESOURCE.GOOGLE_WORKSPACE)],
        keywords: getToolKeywords(ToolId.googleWorkspace)
      }
    ]
  },
  {
    toolId: ToolId.stats,
    items: [
      {
        toolId: ToolId.statistics,
        route: ROUTES[ToolId.statistics],
        pageEndpoint: ENDPOINT[ToolId.statistics],
        apiEndpoints: [],
        keywords: getToolKeywords(ToolId.statistics)
      },
      {
        toolId: ToolId.errorLog,
        route: ROUTES[ToolId.errorLog],
        pageEndpoint: ENDPOINT[ToolId.errorLog],
        apiEndpoints: [getEndpointName(API_RESOURCE.DOMAIN)],
        keywords: getToolKeywords(ToolId.statistics)
      },
      {
        toolId: ToolId.accessLog,
        route: ROUTES[ToolId.accessLog],
        pageEndpoint: ENDPOINT[ToolId.accessLog],
        apiEndpoints: [getEndpointName(API_RESOURCE.DOMAIN)],
        keywords: getToolKeywords(ToolId.accessLog)
      }
    ]
  },
  {
    toolId: ToolId.devs,
    items: [
      {
        toolId: ToolId.git,
        route: ROUTES[ToolId.git],
        pageEndpoint: ENDPOINT[ToolId.git],
        apiEndpoints: [getEndpointName(API_RESOURCE.APP_LATEST), getEndpointName(API_RESOURCE.APP_RESTORE_BACKUP)],
        localPageTaskLoaders: {
          createBox: [getEndpointName(API_RESOURCE.APP_REPO)]
        },
        keywords: getToolKeywords(ToolId.git)
      },
      {
        toolId: ToolId.cron,
        route: ROUTES[ToolId.cron],
        pageEndpoint: ENDPOINT[ToolId.cron],
        apiEndpoints: [getEndpointName(API_RESOURCE.CRON_NOTIFY)],
        subStates: [ToolId.cronNotification, ToolId.cronJob],
        keywords: getToolKeywords(ToolId.cron)
      },
      {
        toolId: ToolId.phpSettings,
        route: ROUTES[ToolId.phpSettings],
        pageEndpoint: ENDPOINT[ToolId.phpSettings],
        apiEndpoints: [
          getEndpointName(API_RESOURCE.PHP_EXTENSION),
          getEndpointName(API_RESOURCE.PHP_VARIABLE),
          getEndpointName(API_RESOURCE.PHP_VERSION),
          getEndpointName(API_RESOURCE.PHP_LOCATION_VERSION),
          getEndpointName(API_RESOURCE.DOMAIN),
          getEndpointName(API_RESOURCE.USERVICE)
        ],
        keywords: getToolKeywords(ToolId.phpSettings)
      },
      {
        toolId: ToolId.ssh,
        route: ROUTES[ToolId.ssh],
        pageEndpoint: ENDPOINT[ToolId.ssh],
        keywords: getToolKeywords(ToolId.ssh),
        apiEndpoints: [getEndpointName(API_RESOURCE.SSH_KEY)]
      },
      {
        toolId: ToolId.appManager,
        route: ROUTES[ToolId.appManager],
        pageEndpoint: ENDPOINT[ToolId.appManager],
        apiEndpoints: [getEndpointName(API_RESOURCE.WORDPRESS)],
        localPageTaskLoaders: {
          createBox: [getEndpointName(API_RESOURCE.WORDPRESS), getEndpointName(API_RESOURCE.AUTOINSTALL)]
        },
        keywords: getToolKeywords(ToolId.appManager)
      }
    ]
  }
];
