import * as React from 'react';
import { Field } from 'redux-form';
import { Grid, Section } from '@siteground/styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../components/dialogs';
import { SGDialogForm } from '../../components/sg-dialog';
import VCS from '../../components/visibility-control-service';
import { CreateBox, CreateForm } from './create';
import UpdateFieldsForm from './update/fields';
import { RootState } from '../../reducers';
import { filterStagingDomains } from '../../../core/selectors';

interface RedirectProps {
  actions: CrudActions;
  items: any;
  listedDomains: any[];
  location: any;
  intl: Intl;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
}

type State = {
  currentUpdatePayload: any;
  currentDeleteConformationDialogPayload: any;
};

const { endpoint, resourceName, resourceNameMetaApi } = API_RESOURCE.LOCATION_REDIRECT;

class RedirectPage extends React.Component<RedirectProps, State> {
  readonly state = {
    currentUpdatePayload: null,
    currentDeleteConformationDialogPayload: null
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_REDIRECT,
          success: {
            intlKey: 'translate.page.redirect.create.success.message',
            intlValues: { name: formData.domain_name + formData.path }
          },
          error: {
            intlKey: 'translate.page.redirect.create.error.message',
            intlValues: { name: formData.domain_name + formData.path }
          }
        }
      }
    });
  };

  render() {
    const { intl, items, listedDomains } = this.props;
    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.url' }),
        accessor: 'domain_name',
        render: (id, entity) => {
          return `${entity.domain_name}${entity.path}`;
        }
      },
      {
        header: intl.formatMessage({ id: 'translate.page.redirect.redirect.type' }),
        accessor: 'permanent',
        render: (id, entity) => {
          return entity.permanent
            ? intl.formatMessage({ id: 'translate.page.redirect.type_permanent' })
            : intl.formatMessage({ id: 'translate.page.redirect.type_temporary' });
        }
      },
      {
        header: intl.formatMessage({ id: 'translate.page.redirect.redirect.to' }),
        accessor: 'redirect_to'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <div>
        <PageHeader
          id={ToolId.redirect}
          instructions={intl.formatMessage({ id: 'translate.page.redirect.instructions' })}
        />
        <Section>
          <Grid>
            <CreateBox>
              <CreateForm
                domain={listedDomains}
                onSubmit={(data) =>
                  this.onCreateFormSubmit({
                    ...data,
                    path: data.path || '/'
                  })
                }
              />
            </CreateBox>

            {this.renderUpdateComponent()}
            {this.renderDeleteConformationDialogComponent()}

            <VCS resourceName={resourceNameMetaApi} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.redirect.list.title' })}
                data={items.redirect}
                columns={columns}
                resources={[{ resourceName: API_RESOURCE.LOCATION_REDIRECT.resourceName, methods: ['GET'] }]}
                noDataMessage="translate.page.redirect.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>
      </div>
    );
  }

  renderUpdateComponent = () => {
    const { intl, closeSGDialog } = this.props;
    const { currentUpdatePayload } = this.state;
    const url = currentUpdatePayload && `${currentUpdatePayload.domain_name}${currentUpdatePayload.path}`;

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_DOMAIN_REDIRECT_DIALOG}
        title={intl.formatMessage({ id: 'translate.page.redirect.update.title' }, { url })}
        resources={[
          {
            resourceName: API_RESOURCE.LOCATION_REDIRECT.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <UpdateFieldsForm
          initialValues={currentUpdatePayload}
          onSubmit={(data) =>
            this.props.actions.updateItem(data, () => closeSGDialog(REDUX_FORM.CHANGE_DOMAIN_REDIRECT_DIALOG))
          }
        />
      </SGDialogForm>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const url = deletePayload && deletePayload.url;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.redirect.delete.title' }, { url })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      url: `${entity.domain_name}${entity.path}`,
      _metaFields: { ...API_RESOURCE.LOCATION_REDIRECT },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.redirect.delete.success.message'
          },
          error: {
            intlKey: 'translate.page.redirect.delete.error.message'
          }
        }
      }
    };

    const updatePayload = {
      _metaFields: { ...API_RESOURCE.LOCATION_REDIRECT },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.redirect.update.success.message'
          },
          error: {
            intlKey: 'translate.page.redirect.update.error.message'
          }
        }
      },
      ...entity
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            icon: 'edit',
            label: intl.formatMessage({ id: 'translate.generic.edit' }),
            e2eAttr: 'table-action-edit',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentUpdatePayload: updatePayload }, () =>
                openSGDialog(REDUX_FORM.CHANGE_DOMAIN_REDIRECT_DIALOG)
              )
          },
          {
            vcsMethod: 'DELETE',
            icon: 'trash',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-delete',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
                openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };
}

const mapStateToProps = (store: RootState) => ({
  listedDomains: filterStagingDomains(store, API_RESOURCE.DOMAIN_ALL.resourceName)
});

export default indexWithCRUD(mapStateToProps, { ...sgDialogActions })(
  RedirectPage,
  API_RESOURCE.LOCATION_REDIRECT,
  API_RESOURCE.DOMAIN_ALL
);
