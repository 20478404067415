import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, Label } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import SettingListItem, { SettingListItemProps } from './setting-list-item';
import { CDN_SETTING_ID, DNS_CENTRAL_RECORDS } from '../constants';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { updateCDNItem, createCDNItem } from '../../../../core/actions/pages/cdn';

const { TTL } = CDN_SETTING_ID;

const notification = {
  [TTL]: {
    success: 'translate.page.cdn.ttl.success.message',
    error: 'translate.page.cdn.ttl.error.message'
  }
};

const getDropdownOptions = (id, intl) => {
  let options = [];

  if (id === TTL) {
    options = [
      {
        value: 21600,
        label: intl.formatMessage({ id: 'translate.page.cdn.tab.setting.ttl.6.hours' })
      },
      {
        value: 43200,
        label: intl.formatMessage({ id: 'translate.page.cdn.tab.setting.ttl.12.hour' })
      },
      {
        value: 86400,
        label: intl.formatMessage({ id: 'translate.page.cdn.tab.setting.ttl.24.hour' })
      }
    ];
  }

  return options;
};

const getDropdownMetaFields = (id) => {
  const metaFields: any = {
    ...API_RESOURCE.LOCATION
  };

  const result = {
    _meta: null,
    _metaFields: {
      ...metaFields
    }
  };

  if (!id) {
    return result;
  }

  result._meta = {
    notification: {
      type: 'generic',
      success: {
        intlKey: notification[id].success
      },
      error: {
        intlKey: notification[id].error
      }
    }
  };

  return result;
};

const getDropdownSettingsFields = ({ id, value, domain }) => {
  if (id !== TTL) {
    return;
  }

  return {
    default_cache_ttl: value,
    domain_id: domain.id,
    path: '/'
  };
};

type SettingListDropdownProps = SettingListItemProps & {
  updateCDNItem: Function;
  createCDNItem: Function;
  setting?: any;
  cdnDomain: any;
  domain: any;
  DNSRecords: any;
  domainLocation: any;
};

const SettingListDropdown = (props: SettingListDropdownProps) => {
  const {
    intl,
    updateCDNItem,
    createCDNItem,
    setting,
    DNSRecords,
    cdnDomain,
    domain,
    domainLocation,
    title,
    info,
    resources
  } = props;

  return (
    <SettingListItem title={title} info={info} resources={resources}>
      {DNSRecords !== DNS_CENTRAL_RECORDS || !cdnDomain.cdn_enabled ? (
        <Label type="link" color="light">
          {intl.formatMessage({ id: 'translate.generic.inactive' })}
        </Label>
      ) : (
        <div style={{ minWidth: '150px' }}>
          <Dropdown
            options={getDropdownOptions(setting.id, intl)}
            optionValue="value"
            optionLabel="label"
            selectedValue={setting.value}
            size="small"
            data-e2e={setting.id}
            onChange={(value) => {
              if (domainLocation) {
                return updateCDNItem(
                  {
                    id: domainLocation.id,
                    ...getDropdownMetaFields(setting.id),
                    ...getDropdownSettingsFields({ id: setting.id, value, domain })
                  },
                  {
                    requestTypeName: customRequestTypes.UPDATE_TTL
                  }
                );
              }

              return createCDNItem(
                {
                  ...getDropdownMetaFields(setting.id),
                  ...getDropdownSettingsFields({ id: setting.id, value, domain })
                },
                {
                  requestTypeName: customRequestTypes.UPDATE_TTL
                }
              );
            }}
          />
        </div>
      )}
    </SettingListItem>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createCDNItem: bindActionCreators(createCDNItem, dispatch),
  updateCDNItem: bindActionCreators(updateCDNItem, dispatch)
});

const mapStateToProps = (state) => ({});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl<any, any>(SettingListDropdown)) as any;
