import * as React from 'react';
import { Grid, Text } from '@siteground/styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { DeleteDialog } from '../../components/dialogs';
import indexWithCRUD from '../../components/indexWithCRUD';
import { SGDialogForm } from '../../components/sg-dialog';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../components/visibility-control-service';
import { parseCommandWithInterval } from './cron-parser';
import IntervalInfo from './interval-info';
import { CreateBox, CreateForm } from './job/create';
import { ChangeCron } from './job/update';

const { resourceNameMetaApi, endpoint, resourceName } = API_RESOURCE.CRON;

type Props = {
  actions: {
    createItem: CreateItem;
    updateItem: UpdateItem;
    deleteItem: DeleteItem;
  };
  items: any;
  location: any;
  openSGDialog: Function;
  closeSGDialog: Function;
  intl: Intl;
};

type State = {
  currentUpdatePayload: {
    cmd: string;
    _metaFields?: object;
    initialInterval?: string;
  };
  currentDeletePayload: DeleteItemPayload;
};

export const formatCronSubmitData = (data) => {
  const parser = parseCommandWithInterval(data.cmd);
  const cmd = parser.intervalStatus === 'valid' ? parser.parsedCommand : data.cmd;
  const dow = data.dow === '7' ? '0' : data.dow;

  return {
    ...data,
    cmd,
    dow
  };
};

class CronPage extends React.Component<Props, State> {
  readonly state: State = {
    currentUpdatePayload: null,
    currentDeletePayload: null
  };

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_ITEM_CRON,
          success: {
            intlKey: 'translate.page.cron.created_msg',
            intlValues: { name: formData.comment }
          },
          error: {
            intlKey: 'translate.page.cron.failed_create_msg',
            intlValues: { name: formData.comment }
          }
        }
      }
    });
  };

  render() {
    const { intl, items, actions } = this.props;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.page.cron.cmd' }),
        accessor: 'cmd',
        style: { height: 'auto', paddingTop: '15px', paddingBottom: '15px' },
        mSize: '70%',
        render: (cmd, entity) => <Text>{cmd}</Text>
      },
      {
        header: intl.formatMessage({ id: 'translate.page.cron.interval' }),
        accessor: 'interval',
        render: (a, entity) => <IntervalInfo intl={intl} entity={entity} />,
        style: { height: 'auto', paddingTop: '15px', paddingBottom: '15px' }
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu,
        style: { height: 'auto', paddingTop: '15px', paddingBottom: '15px' }
      }
    ];

    return (
      <Grid>
        <CreateBox>
          <CreateForm onSubmit={(data) => this.onCreateFormSubmit(formatCronSubmitData(data))} />
        </CreateBox>
        <VCS resourceName={resourceNameMetaApi} hasMethod="GET">
          <SGTable
            title={intl.formatMessage({ id: 'translate.page.cron.list.title' })}
            data={items[resourceName]}
            columns={columns}
            resources={[{ resourceName: API_RESOURCE.CRON.resourceName, methods: ['GET'] }]}
            noDataMessage="translate.page.cron.sg-table.no-data.message"
          />
        </VCS>

        {this.renderUpdateComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </Grid>
    );
  }

  renderUpdateComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentUpdatePayload } = this.state;

    const { initialInterval, ...initialValues } = { ...currentUpdatePayload };

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_CRON_DIALOG}
        title={intl.formatMessage(
          { id: 'translate.page.cron.update.title' },
          { name: currentUpdatePayload && currentUpdatePayload.cmd }
        )}
        resources={[{ resourceName, methods: ['PUT'] }]}
      >
        <ChangeCron
          initialInterval={initialInterval}
          initialValues={initialValues}
          onSubmit={(data) =>
            actions.updateItem(formatCronSubmitData(data), () => closeSGDialog(REDUX_FORM.CHANGE_CRON_DIALOG))
          }
        />
      </SGDialogForm>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl, actions } = this.props;
    const { currentDeletePayload } = this.state;

    return (
      <DeleteDialog
        title={intl.formatMessage(
          {
            id: 'translate.page.cron-job.delete.title'
          },
          {
            name: currentDeletePayload && currentDeletePayload.name
          }
        )}
        onSubmit={() => actions.deleteItem(currentDeletePayload)}
      />
    );
  };

  renderContextMenu = (id, entity: CronEntityValues) => {
    const { intl, openSGDialog } = this.props;
    const { cmd, min, hour, day, mon, dow } = entity;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name: cmd,
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.cron.deleted_msg',
            intlValues: { name: cmd }
          },
          error: {
            intlKey: 'translate.page.cron.failed_delete_msg',
            intlValues: { name: cmd }
          }
        }
      },
      _metaFields: {
        ...API_RESOURCE.CRON
      }
    };

    const updatePayload = {
      ...entity,
      cmd,
      initialInterval: `${min} ${hour} ${day} ${mon} ${dow}`,
      _metaFields: {
        ...API_RESOURCE.CRON
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.cron.updated_msg',
            intlValues: { name: cmd }
          },
          error: {
            intlKey: 'translate.page.cron.failed_updated_msg',
            intlValues: { name: cmd }
          }
        }
      }
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.generic.edit' }),
            e2eAttr: 'table-action-edit',
            icon: 'edit',
            visibleOnDesktop: true,
            onClick: () => {
              this.setState(
                {
                  currentUpdatePayload: updatePayload
                },
                () => openSGDialog(REDUX_FORM.CHANGE_CRON_DIALOG, updatePayload)
              );
            }
          },
          {
            vcsMethod: 'DELETE',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-delete',
            icon: 'trash',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentDeletePayload: deletePayload }, () =>
                openSGDialog(DIALOGS.GENERIC_DELETE, deletePayload)
              )
          }
        ]}
      />
    );
  };
}

export default indexWithCRUD(undefined, { ...sgDialogActions })(CronPage, API_RESOURCE.CRON);
