import * as React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

import { Icon, Link, Text } from '@siteground/styleguide/';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANAGER_DND_TYPE, FILE_MANAGER_ICONS } from '../core/constants/common';
import { getEntityReadableName, getEntityType, isEntityProtected } from '../core/utils';

import './draggable-entity.scss';

const dragItemSource = {
  beginDrag(props) {
    return { entity: props.entity };
  },
  canDrag(props) {
    return Boolean(
      !isEntityProtected(props.entity) && getEntityType(props.enitity) !== FILE_MANAGER_API_RESPONSE_DIR.SYMLINK
    );
  }
};

const dropItemSource = {
  canDrop(props, monitor, component) {
    const dragItem = monitor.getItem().entity;
    const isDragSourceFile = dragItem[FILE_MANAGER_API_RESPONSE_DIR.TYPE] === FILE_MANAGER_API_RESPONSE_DIR.FILE;
    const isDropTargetFile = props.entity.t === FILE_MANAGER_API_RESPONSE_DIR.FILE;

    if (isDragSourceFile && isDropTargetFile) {
      return false;
    }

    if (!isDragSourceFile && isDropTargetFile) {
      return false;
    }

    return true;
  },

  drop(props, monitor, component) {
    const dragSourceEntity = monitor.getItem().entity;
    const dropTargetEntity = props.entity;

    // Don't replace items with themselves
    if (dragSourceEntity === dropTargetEntity) {
      return;
    }

    return props.handleDrop({ dragSourceEntity, dropTargetEntity });
  }
};

const collectDrag = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
});

const collectDrop = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
});

const renderText = ({ isSymlink, label }) => (
  <React.Fragment>{isSymlink ? <Link>{label}</Link> : label}</React.Fragment>
);

export class DraggableEntity extends React.Component<any> {
  render() {
    const { connectDragSource, connectDropTarget, children } = this.props;

    return connectDragSource(
      connectDropTarget(
        <ul style={{ width: '100%' }}>
          <li className="sn-dragaggble">
            {this.renderLabel()}
            {children}
          </li>
        </ul>
      )
    );
  }

  renderLabel() {
    const { entity, isSelected, isHighlighted, onLabelClick, onDoubleClick, onContextMenu, isOver, canDrop } =
      this.props;

    const label = getEntityReadableName(entity);
    const isSymlink = entity[FILE_MANAGER_API_RESPONSE_DIR.TYPE] === FILE_MANAGER_API_RESPONSE_DIR.SYMLINK;

    const classes = [
      'sn-dragaggble-item',
      isSelected && 'sn-dragaggble-item--selected',
      isHighlighted && 'sn-dragaggble-item--highlighted',
      isOver && canDrop && 'sn-dragaggble-item--dropzone'
    ]
      .filter(Boolean)
      .join(' ');

    const isTextBold = isSelected || (isOver && canDrop);

    return (
      <span className={classes} onContextMenu={onContextMenu}>
        <span
          className="sn-dragaggble-item__label"
          onClick={onLabelClick}
          onDoubleClick={onDoubleClick}
          data-e2e="draggable-item"
        >
          <Icon name={FILE_MANAGER_ICONS[entity.t]} size="18" className="sn-dragaggble-item__label-icon" />

          <Text
            color={isSelected ? 'darkest' : 'dark'}
            weight={isTextBold ? 'bold' : 'medium'}
            className="sn-dragaggble-item__label-text"
          >
            {renderText({ isSymlink, label })}
          </Text>
        </span>
      </span>
    );
  }
}

let DragSideItem = DragSource(FILE_MANAGER_DND_TYPE.ENTITY, dragItemSource, collectDrag)(DraggableEntity);
DragSideItem = DropTarget(FILE_MANAGER_DND_TYPE.ENTITY, dropItemSource, collectDrop)(DragSideItem);

export default DragSideItem;
