import * as React from 'react';
import { Grid, Notice, Link, Text } from '@siteground/styleguide';
import { useIntl } from 'react-intl';

import { PhpVersionManageType, PhpManagerState } from '../types';
import { createManagedPhpNotification } from '../utils';

export const PhpManageVersionNotice = ({ environment, phpVersionManageType, domainData, updatePhpVersion }) => {
  const intl = useIntl();
  const { isPhone } = environment;

  return (
    <Grid padding={isPhone ? 'none' : 'responsive'}>
      <Notice
        type="instruction"
        title={
          phpVersionManageType === PhpVersionManageType.Auto
            ? intl.formatMessage({ id: 'translate.page.php-manager.php-auto.title' })
            : intl.formatMessage({ id: 'translate.page.php-manager.php-manually.title' })
        }
        shadow={false}
        background="light"
      >
        <Text padding={['small', 'none']}>
          {phpVersionManageType === PhpVersionManageType.Auto
            ? intl.formatMessage({ id: 'translate.page.php-manager.php-auto.text' })
            : intl.formatMessage({ id: 'translate.page.php-manager.php-manually.text' })}
        </Text>
        {phpVersionManageType === PhpVersionManageType.Manual && (
          <Link
            onClick={() =>
              updatePhpVersion(
                {
                  version_id: PhpVersionManageType.Auto
                },
                createManagedPhpNotification(domainData && domainData.name)
              )
            }
          >
            {intl.formatMessage({ id: 'translate.page.php-manager.php-enable-auto.link' })}
          </Link>
        )}
      </Notice>
    </Grid>
  );
};

export default PhpManageVersionNotice as typeof PhpManageVersionNotice;
