import * as React from 'react';
import { useIntl } from 'react-intl';

import { Text, textToHTML } from '@siteground/styleguide';

import { DIALOGS } from '../../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';

const CDNCannotBeActivatedCentralDNS: React.FC = () => {
  const intl = useIntl();

  return (
    <SGDialog
      id={DIALOGS.CDN_CANNOT_BE_ACTIVATED_CENTRAL_DNS}
      title={intl.formatMessage({ id: 'translate.page.cdn.cannot.be.activated' })}
      icon="warning"
      state="warning"
      footer={
        <SGDialogCancel
          id={DIALOGS.CDN_CANNOT_BE_ACTIVATED_CENTRAL_DNS}
          label={intl.formatMessage({ id: 'translate.generic.close' })}
        />
      }
    >
      <Text align="left">
        {textToHTML(intl.formatMessage({ id: 'translate.page.cdn.cannot.be.activated.description' }))}
      </Text>
    </SGDialog>
  );
};

export default CDNCannotBeActivatedCentralDNS;
