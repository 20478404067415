import { handleActions } from 'redux-actions';
import { STORE_SITESCANNER_DATA } from '../../constants/actions';
import * as sgSiteScannerTypes from '../../definitions/sg-sitescanner';

export type SgSiteScanner = {
  data: {
    [key: string]: any;
  };
};

export const initialState: SgSiteScanner = {
  data: {}
};

const reducerMap = {
  [STORE_SITESCANNER_DATA]: (state, { type, payload }) => ({
    data: {
      ...state.data,
      [payload.key]: payload.data
    }
  })
};

export default handleActions(reducerMap, initialState);
