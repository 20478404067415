import * as React from 'react';
import { useIntl } from 'react-intl';

import { SGDialogForm } from '../../../components/sg-dialog';
import { REDUX_FORM } from '../../../../core/constants/common';
import { API_RESOURCE } from '../../../../core/constants/api';

import { ChangePhpIniSetting } from '../update';
import { apiToFormValueConversion } from '../utils';

export const UpdatePhpSettingDialog = ({ selectedVariable, settingData, updatePhpSettings }) => {
  const intl = useIntl();
  const settingName = settingData && settingData.name;
  const settingType = settingData && settingData.type;
  const settingApiValue = selectedVariable && selectedVariable.value;

  return (
    <SGDialogForm
      name={REDUX_FORM.CHANGE_PHP_INI_SETTING}
      title={intl.formatMessage(
        {
          id: 'translate.page.phpSettings.edit_variable'
        },
        { variableName: settingName }
      )}
      resources={[
        {
          resourceName: API_RESOURCE.PHP_LOCATION_INI.resourceName,
          methods: ['PUT', 'POST', 'DELETE']
        }
      ]}
    >
      <ChangePhpIniSetting
        initialValues={{
          settingName,
          settingType,
          settingApiValue,
          settingFormValue: apiToFormValueConversion(settingApiValue, settingType)
        }}
        onSubmit={updatePhpSettings}
      />
    </SGDialogForm>
  );
};

export default UpdatePhpSettingDialog as typeof UpdatePhpSettingDialog;
