import { CustomDeployData, CustomDeployResponse } from './types';

export const defaultCustomDeployData = {
  data: {
    added: {
      dbs: [],
      files: []
    },
    changed: {
      dbs: [],
      files: []
    },
    deleted: {
      dbs: [],
      files: []
    },
    skipped: {
      dbs: [],
      files: []
    }
  },
  total: 0,
  loaded: false
};

export const emptyResponseData = {
  added: [],
  changed: [],
  deleted: [],
  skipped: []
};

export const formatCustomDeployData = (
  data: CustomDeployResponse = {
    dbs: emptyResponseData,
    files: emptyResponseData
  }
) =>
  Object.keys(data).reduce(
    (output, dataType) => {
      let updated = { ...output };
      // actionType => added, changed, deleted
      // dataType => dbs, files

      Object.keys(data[dataType]).forEach((actionType) => {
        const toAdd = data[dataType][actionType] || [];
        updated = {
          ...updated,
          data: {
            ...updated.data,
            [actionType]: {
              ...updated.data[actionType],
              [dataType]: [...updated.data[actionType][dataType], ...toAdd]
            }
          },
          total: updated.total + toAdd.length
        };
      });

      return updated;
    },
    {
      ...defaultCustomDeployData,
      loaded: true
    }
  );
