import punycode from 'punycode';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Filter, Flex, Grid, Label, Link, Section, Sort, Spacer, Text } from '@siteground/styleguide';
import { openNewTabAction } from '../../../core/actions/open-new-tab';
import { autoLoginInWebApp, requestAutoconfigureData } from '../../../core/actions/pages/email-accounts';
import { requestData, requestNemoData } from '../../../core/actions/request-data';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { transformDomainName } from '../../../core/api/utils';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { ToolId } from '../../../core/constants/route-info';
import { filterStagingDomains, findMainDomain, getCurrentSite, getCurrentSiteToken } from '../../../core/selectors';
import {
  ascendingSortByProperty,
  descendingSortByProperty,
  sortByPercentileUsageDescending,
  sortByPercentileUsageAscending
} from '../../../core/utils/sort';
import { bytesToMB } from '../../../core/utils/stats-format';
import { ChangePassword, ChangeQuota } from '../../components/common-forms';
import { DeleteDialog } from '../../components/dialogs';
import DomainSelect from '../../components/domain-select';
import EmailToolNotAvailable from '../../components/email-tool-not-available';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import QuotaProgress from '../../components/quota-progress/quota-progress';
import { SGDialogForm } from '../../components/sg-dialog';
import SGTable from '../../components/sg-table';
import { TableHeaderSort } from '../../components/sg-table-sort';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../components/visibility-control-service';
import { RootState } from '../../reducers';
import { isTemporaryDomain } from '../dns/utils';
import Autoconfigure from './autoconfigure/autoconfigure';
import { CreateBox, CreateForm } from './create';

const CLOUD_ACCOUNT = 'cloud_lxc';

type Props = {
  actions: {
    fetchItem: FetchItem;
    createItem: CreateItem;
    updateItem: UpdateItem;
    deleteItem: DeleteItem;
    changeQuotaItem: UpdateItem;
  };
  currentSite: SiteItem;
  siteToken: string;
  items: {
    email: any;
  };
  mainDomain: any;
  listedDomains: any[];
  intl: Intl;
  location: any;
  requestAutoconfigureData: Function;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  openNewTabAction: typeof openNewTabAction;
  requestNemoData: typeof requestNemoData;
  autoLoginInWebApp: typeof autoLoginInWebApp;
  requestData: RequestData;
};

export enum WebMail {
  Horde = 'horde',
  RoundCube = 'roundcube'
}

type EmailExtra = {
  webmail: WebMail;
};

const pickerEndpoints: any = [API_RESOURCE.DOMAIN, API_RESOURCE.DOMAIN_ALIAS];

type State = {
  currentChangePasswordPayload: any;
  currentChangeQuotaPayload: any;
  currentDeleteConformationDialogPayload: any;
  currentAutoconfigurePayload: {
    emailId: string;
    email: string;
    webmail: WebMail;
  };
  selectedDomain: any;
  emailAccountSearch: string;
};

class EmailPage extends React.Component<Props, State> {
  readonly state = {
    currentChangePasswordPayload: null,
    currentChangeQuotaPayload: null,
    currentDeleteConformationDialogPayload: null,
    currentAutoconfigurePayload: null,
    selectedDomain: null,
    emailAccountSearch: ''
  };

  getDomainConfig = () => {
    const config = this.props.mainDomain && this.props.mainDomain.config;

    return {
      imap_host: null,
      smtp_host: null,
      imap_port: 993,
      pop3_port: 995,
      smtp_port: 465,
      ...config
    };
  };

  /* event listeners */
  onDomainChanged = (domainId) => {
    const { listedDomains } = this.props;
    const domain = listedDomains.find(({ id }) => id === domainId);

    this.setState({ selectedDomain: domain });
  };

  onCreateFormSubmit = (formData) => {
    const { selectedDomain } = this.state;
    const account = `${formData.name}@${selectedDomain.name}`;

    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_EMAIL,
          success: {
            intlKey: 'translate.page.email.created_msg',
            intlValues: { account }
          },
          error: {
            intlKey: 'translate.page.email.failed_create_msg',
            intlValues: { account }
          }
        }
      }
    });
  };

  /* filters */
  arrangeOptionsData(data) {
    return data.sort((a, b) => {
      return a.id - b.id;
    });
  }

  pickerOptions() {
    const { listedDomains } = this.props;
    return this.arrangeOptionsData(listedDomains);
  }

  filterTabledData() {
    const { items } = this.props;
    const { selectedDomain, emailAccountSearch } = this.state;

    if (!items.email) {
      return;
    }

    const domainId = selectedDomain && selectedDomain.id;

    return items.email.filter((option) => {
      const emailName = option.name.toLowerCase();
      return option.domain_id === domainId && emailName.includes(emailAccountSearch.toLowerCase());
    });
  }

  getHasEmailAccountsData() {
    const { items } = this.props;
    return items.email !== undefined && this.state.emailAccountSearch === '' && !items.email.length;
  }

  loginWebmail = async (email) => {
    const { autoLoginInWebApp } = this.props;

    return await new Promise((resolve) =>
      autoLoginInWebApp(
        {
          email,
          _meta: {
            notification: {
              type: 'generic',
              error: {
                intlKey: 'translate.page.email.login-to-webmail.error.message'
              }
            }
          }
        },
        resolve
      )
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.name;

    return (
      <DeleteDialog
        title={this.props.intl.formatMessage({ id: 'translate.page.email.delete.dialog.title' }, { entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderChangePasswordComponent = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { currentChangePasswordPayload } = this.state;
    const entityName = currentChangePasswordPayload && currentChangePasswordPayload._metaFields.entityName;

    return (
      <SGDialogForm
        name={REDUX_FORM.GENERIC_PASSWORD}
        icon="lock"
        title={intl.formatMessage({ id: 'translate.dialog.title.change.password' }, { account: entityName })}
        resources={[
          {
            resourceName: API_RESOURCE.EMAIL.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <ChangePassword
          initialValues={currentChangePasswordPayload}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(REDUX_FORM.GENERIC_PASSWORD))}
        />
      </SGDialogForm>
    );
  };

  renderChangeQuotaComponent = () => {
    const { actions, intl, closeSGDialog } = this.props;
    const { currentChangeQuotaPayload } = this.state;
    const entityName = currentChangeQuotaPayload && currentChangeQuotaPayload._metaFields.entityName;

    return (
      <SGDialogForm
        name={REDUX_FORM.GENERIC_QUOTA}
        icon="settings"
        title={intl.formatMessage({ id: 'translate.dialog.title.change.quota' }, { account: entityName })}
        resources={[
          {
            resourceName: API_RESOURCE.EMAIL.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <ChangeQuota
          type="email"
          siteMetaApiKey="max_mail_quota_size"
          initialValues={currentChangeQuotaPayload}
          onSubmit={(data) => actions.changeQuotaItem(data, () => closeSGDialog(REDUX_FORM.GENERIC_QUOTA))}
        />
      </SGDialogForm>
    );
  };

  renderAutoconfigureDialogComponent = () => {
    const { requestAutoconfigureData, closeSGDialog } = this.props;
    const { currentAutoconfigurePayload } = this.state;
    const selectedEmail = currentAutoconfigurePayload && currentAutoconfigurePayload.email;
    const selectedWebMail = currentAutoconfigurePayload && currentAutoconfigurePayload.webmail;

    return (
      <Autoconfigure
        domainConfig={this.getDomainConfig()}
        userEmail={selectedEmail}
        defaultWebMail={selectedWebMail}
        onSubmit={(emailClientId: string) =>
          requestAutoconfigureData(
            {
              emailId: this.state.currentAutoconfigurePayload.emailId,
              emailClientId,
              onDataLoaded: (url) => this.props.openNewTabAction({ url }),
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.page.email.pop.imap.settings.success.message'
                  },
                  error: {
                    intlKey: 'translate.page.email.pop.imap.settings.error.message'
                  }
                }
              }
            },
            () => closeSGDialog(DIALOGS.EMAIL_AUTOCONFIGURE)
          )
        }
        onWebMailSelected={() => closeSGDialog(DIALOGS.EMAIL_AUTOCONFIGURE)}
      />
    );
  };

  resetEmailAccountSearch = () => this.setState({ emailAccountSearch: '' });

  renderFilterButton = () => (
    <Filter
      title={this.props.intl.formatMessage({ id: 'translate.generic.filter.accounts' })}
      config={{
        input: {
          groupTitle: this.props.intl.formatMessage({ id: 'translate.generic.filter.by.name' }),
          type: 'input',
          onChange: ({ input }) => this.setState({ emailAccountSearch: input.children[0].value }),
          children: [
            {
              placeholder: this.props.intl.formatMessage({ id: 'translate.generic.filter.type.keyword' }),
              value: this.state.emailAccountSearch
            }
          ]
        }
      }}
      translations={{
        closeBtnLabel: this.props.intl.formatMessage({ id: 'translate.generic.close' }),
        resetBtnLabel: this.props.intl.formatMessage({ id: 'translate.reset' })
      }}
      onResetFilter={this.resetEmailAccountSearch}
    />
  );

  renderPageContent = () => {
    const { intl } = this.props;
    const { selectedDomain } = this.state;

    const columns = [
      {
        header: () => {
          return (
            <Flex justify="flex-start" align="center">
              {intl.formatMessage({ id: 'translate.generic.account.name' })}
              <Spacer size="xx-small" />
              <Sort
                config={{
                  ascending: ascendingSortByProperty('name'),
                  descending: descendingSortByProperty('name')
                }}
              />
            </Flex>
          );
        },
        accessor: 'name',
        render: (email, entity) => {
          return `${email}@${entity.domain_name}`;
        }
      },
      {
        header: () => (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.page.email.quota' })}
            config={{
              ascending: sortByPercentileUsageAscending('used_size'),
              descending: sortByPercentileUsageDescending('used_size')
            }}
          />
        ),
        accessor: 'quota_size',
        render: (quota, entity) => {
          if (entity.quota_size === 0) {
            const usedQuotaSize = entity.used_size ? parseInt(entity.used_size, 10) : 0;
            const bytesToShow = usedQuotaSize && bytesToMB(usedQuotaSize).toFixed(0);

            return (
              <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                {bytesToShow}MB &nbsp;
                <Label color="ocean" type="outlined">
                  {intl.formatMessage({ id: 'translate.generic.quota.unlimited' })}
                </Label>
              </Flex>
            );
          }

          return <QuotaProgress quota={quota} max={entity.quota_size_max} usedQuota={entity.used_size} />;
        }
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    const hasEmailAccountsData = this.getHasEmailAccountsData();
    let propsToPropagate = {};

    if (hasEmailAccountsData) {
      propsToPropagate = {
        noDataProps: {
          icon: 'presentational-no-results-found',
          title: intl.formatMessage({ id: 'translate.no-results-found' }),
          message: (
            <FormattedMessage
              id="translate.php-variables.clear-filter"
              values={{
                link: (
                  <Link onClick={this.resetEmailAccountSearch}>
                    {intl.formatMessage({ id: 'translate.clear-filters' })}
                  </Link>
                )
              }}
            />
          )
        }
      };
    }

    return (
      <React.Fragment>
        <CreateBox domainConfig={this.getDomainConfig()}>
          <CreateForm
            domainName={selectedDomain && selectedDomain.name}
            onSubmit={(data) =>
              this.onCreateFormSubmit({
                ...data,
                domain_id: selectedDomain.id
              })
            }
          />
        </CreateBox>
        <VCS resourceName={API_RESOURCE.EMAIL.resourceName} hasMethod="GET">
          <SGTable
            title={
              <Flex align="center" justify="space-between">
                {intl.formatMessage({ id: 'translate.page.email.list.title' })}
                <Spacer size="xx-small" />
                {this.renderFilterButton()}
              </Flex>
            }
            data={this.filterTabledData()}
            showHeaderWithNoData={!hasEmailAccountsData}
            columns={columns}
            resources={[
              { resourceName: 'email', methods: ['GET'] },
              { requestTypeName: customRequestTypes.GET_WEBMAIL_LOGIN_DATA }
            ]}
            noDataMessage="translate.page.email.sg-table.no-data.message"
            {...propsToPropagate}
          />
        </VCS>
      </React.Fragment>
    );
  };

  renderNotAvailableNotice = () => {
    if (!this.state.selectedDomain) {
      return null;
    }

    return <EmailToolNotAvailable />;
  };

  render() {
    const { intl, mainDomain } = this.props;
    const { selectedDomain } = this.state;
    const isTempDomain = selectedDomain && isTemporaryDomain(selectedDomain.name);
    const pickerOptions = this.pickerOptions();

    return (
      <div>
        <PageHeader id={ToolId.email} instructions={intl.formatMessage({ id: 'translate.page.email.info' })} />
        <Section>
          <Grid>
            <DomainSelect
              mainDomain={mainDomain}
              options={pickerOptions}
              domainResourceName={API_RESOURCE.DOMAIN_ALL.resourceName}
              selectedValue={selectedDomain && selectedDomain.id}
              optionValue="id"
              optionLabel="name"
              onChange={this.onDomainChanged}
            />
            {!isTempDomain ? this.renderPageContent() : this.renderNotAvailableNotice()}
          </Grid>
        </Section>

        {this.renderChangePasswordComponent()}
        {this.renderChangeQuotaComponent()}
        {this.renderAutoconfigureDialogComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  }

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const domainName = this.state.selectedDomain.name;
    const account = `${entity.name}@${domainName}`;

    const entityInfo = {
      domainName,
      maxQuotaSize: entity.quota_size_max,
      entityName: account
    };

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name: account,
      ...entityInfo,
      _metaFields: { ...API_RESOURCE.EMAIL },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.email.deleted_msg',
            intlValues: { account }
          },
          error: {
            intlKey: 'translate.page.email.failed_delete_msg',
            intlValues: { account }
          }
        }
      }
    };

    const changePasswordPayload = {
      _metaFields: {
        ...API_RESOURCE.EMAIL,
        ...entityInfo
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.generic.password_changed',
            intlValues: { account }
          },
          error: {
            intlKey: 'translate.generic.password_failed',
            intlValues: { account }
          }
        }
      },
      id: entity.id
    };

    const changeQuotaPayload = {
      _metaFields: {
        ...API_RESOURCE.EMAIL,
        ...entityInfo
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.generic.quota_changed',
            intlValues: { account }
          },
          error: {
            intlKey: 'translate.generic.quota_failed',
            intlValues: { account }
          }
        }
      },
      quota_size: entity.quota_size,
      id: entity.id
    };

    const { currentAutoconfigurePayload } = this.state;
    const selectedEmail = currentAutoconfigurePayload && currentAutoconfigurePayload.email;

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.EMAIL.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            icon: 'login',
            label: intl.formatMessage({ id: 'translate.page.email.context-menu-item.login-to-webmail.label' }),
            e2eAttr: 'table-action-email-login-to-webmail',
            onClick: () => {
              const email = `${entity.name}@${transformDomainName(entity.domain_name, punycode.toASCII)}`;

              this.loginWebmail(email);
            }
          },
          {
            vcsMethod: 'PUT',
            icon: 'lock',
            label: intl.formatMessage({ id: 'translate.generic.change.password' }),
            e2eAttr: 'table-action-email-change-password',
            onClick: () =>
              this.setState({ currentChangePasswordPayload: changePasswordPayload }, () =>
                openSGDialog(REDUX_FORM.GENERIC_PASSWORD)
              )
          },
          {
            vcsMethod: 'PUT',
            icon: 'settings',
            label: intl.formatMessage({ id: 'translate.generic.change.quota' }),
            e2eAttr: 'table-action-email-change-quota',
            onClick: () =>
              this.setState({ currentChangeQuotaPayload: changeQuotaPayload }, () =>
                openSGDialog(REDUX_FORM.GENERIC_QUOTA)
              )
          },
          {
            vcsMethod: 'PUT',
            icon: 'mail-settings',
            label: intl.formatMessage({ id: 'translate.page.email.pop.imap.settings.title' }),
            e2eAttr: 'table-action-email-mail-config',
            onClick: () => {
              const hasDefaultWebMail = entity.extra && entity.extra.webmail;

              this.setState(
                {
                  currentAutoconfigurePayload: {
                    emailId: entity.id,
                    email: account,
                    webmail: hasDefaultWebMail ? entity.extra.webmail : WebMail.RoundCube
                  }
                },
                () => openSGDialog(DIALOGS.EMAIL_AUTOCONFIGURE)
              );
            }
          },
          {
            vcsMethod: 'DELETE',
            icon: 'trash',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-email-delete',
            onClick: () =>
              this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
                openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  currentSite: getCurrentSite(state),
  mainDomain: findMainDomain(state, API_RESOURCE.DOMAIN_ALL.resourceName),
  listedDomains: filterStagingDomains(state, API_RESOURCE.DOMAIN_ALL.resourceName),
  siteToken: getCurrentSiteToken(state)
});

export default indexWithCRUD(mapStateToProps, {
  ...sgDialogActions,
  autoLoginInWebApp,
  openNewTabAction,
  requestAutoconfigureData,
  requestNemoData,
  requestData
})(EmailPage, API_RESOURCE.EMAIL, API_RESOURCE.EXTAPP, API_RESOURCE.DOMAIN_ALL);
