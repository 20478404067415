import {
  FILE_MANGER_API_CHANGE_PERMISSIONS,
  FILE_MANGER_API_DIR,
  FILE_MANGER_API_DIR_ARCHIVE,
  FILE_MANGER_API_DIR_COPY,
  FILE_MANGER_API_DIR_EXTRACT,
  FILE_MANGER_API_DIR_MOVE,
  FILE_MANGER_API_DIR_RENAME,
  FILE_MANGER_API_FILE
} from './actions';

export const FILE_MANGER_ENDPOINTS = [
  FILE_MANGER_API_FILE,
  FILE_MANGER_API_DIR,
  FILE_MANGER_API_DIR_COPY,
  FILE_MANGER_API_DIR_MOVE,
  FILE_MANGER_API_DIR_RENAME,
  FILE_MANGER_API_DIR_ARCHIVE,
  FILE_MANGER_API_DIR_EXTRACT,
  FILE_MANGER_API_CHANGE_PERMISSIONS
].map((e) => e.replace('/', ''));

export const FILE_MANAGER_API_RESPONSE_DIR = {
  INFO_NUMBER: 'i',
  FILE: 'f',
  SYMLINK: 'l',
  DIRECTORY: 'd',
  NAME: 'n',
  TYPE: 't',
  MODIFIED_TIME: 'mt',
  SIZE: 's',
  INODE: 'in',
  PERMISSIONS: 'p'
};

export const FILE_MANAGER_ICONS = {
  [FILE_MANAGER_API_RESPONSE_DIR.FILE]: 'file',
  [FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY]: 'folder',
  [FILE_MANAGER_API_RESPONSE_DIR.SYMLINK]: 'symlink'
};

export const FILE_MANAGER_DND_TYPE = {
  ENTITY: 'ENTITY'
};

export const FILE_MANAGER_CONTEXT_MENU_TYPE = {
  BASE: 'BASE',
  CODE_EDITOR: 'CODE_EDITOR',
  ENTITY: 'ENTITY'
};

export const FILE_MANAGER_CODE_EDITOR_FORBIDDEN_FORMATS = {
  'tar.gz': 'archive',
  tgz: 'archive',
  zip: 'archive',
  rar: 'archive',
  iso: 'iso',
  tar: 'archive',
  webm: 'media',
  mkv: 'media',
  flv: 'media',
  vob: 'media',
  ogv: 'media',
  drc: 'media',
  gifv: 'media',
  mng: 'media',
  avi: 'media',
  mov: 'media',
  wmv: 'media',
  rm: 'media',
  asf: 'media',
  amv: 'media',
  mp4: 'media',
  mp3: 'media',
  mpg: 'media',
  mpeg: 'media',
  jpeg: 'media',
  jpe: 'media',
  gif: 'media',
  bmp: 'media',
  png: 'media'
};

// Adding new files here should be added in webpack.common.js as well!
export const FILE_MANAGER_CODE_EDITOR_SUPPORTED_FORMATS = {
  jsx: 'javascript',
  // Supported
  bat: 'bat',
  c: 'c',
  coffee: 'coffeescript',
  cpp: 'cpp',
  cs: 'csharp',
  'c#': 'csharp',
  css: 'css',
  dockerfile: 'dockerfile',
  fs: 'fsharp',
  go: 'go',
  handlebars: 'handlebars',
  html: 'html',
  html5: 'html',
  ini: 'ini',
  java: 'java',
  js: 'javascript',
  json: 'json',
  less: 'less',
  lua: 'lua',
  md: 'markdown',
  msdax: 'msdax',
  h: 'objective-c',
  m: 'objective-c',
  mm: 'objective-c',
  C: 'objective-c',
  php: 'php',
  txt: 'plaintext',
  ps1: 'powershell',
  pug: 'pug',
  py: 'python',
  r: 'r',
  cshtml: 'razor',
  vbhtml: 'razor',
  rb: 'ruby',
  sb: 'sb',
  scss: 'scss',
  sol: 'sol',
  sql: 'sql',
  swift: 'swift',
  ts: 'typescript',
  vb: 'vb',
  xml: 'xml',
  yaml: 'yaml',

  '!!!': 'plaintext',
  '1st': 'plaintext',
  abs: 'plaintext',
  actionScriptProperties: 'plaintext',
  ad2: 'plaintext',
  adt: 'plaintext',
  agtemplate: 'plaintext',
  ahtm: 'plaintext',
  ahtml: 'plaintext',
  ames: 'plaintext',
  aml: 'plaintext',
  amos: 'plaintext',
  android: 'plaintext',
  annot: 'plaintext',
  ans: 'plaintext',
  appcache: 'plaintext',
  apt: 'plaintext',
  asc: 'plaintext',
  ascii: 'plaintext',
  asm: 'plaintext',
  asp: 'plaintext',
  ass: 'plaintext',
  att: 'plaintext',
  au3: 'plaintext',
  aupreset: 'plaintext',
  axs: 'plaintext',
  ba1: 'plaintext',
  bal: 'plaintext',
  bbs: 'plaintext',
  bif: 'plaintext',
  blm: 'plaintext',
  bml: 'plaintext',
  bms: 'plaintext',
  bs2: 'plaintext',
  bsd: 'plaintext',
  bsdl: 'plaintext',
  bss: 'plaintext',
  cas: 'plaintext',
  cascii: 'plaintext',
  cc: 'plaintext',
  cfg: 'plaintext',
  cgi: 'plaintext',
  cgx: 'plaintext',
  chord: 'plaintext',
  chtml: 'plaintext',
  cl: 'plaintext',
  cm0013: 'plaintext',
  cmd: 'plaintext',
  cml: 'plaintext',
  cnn: 'plaintext',
  conf: 'plaintext',
  cor: 'plaintext',
  crd: 'plaintext',
  csproj: 'plaintext',
  csv: 'plaintext',
  csvx: 'plaintext',
  cue: 'plaintext',
  cwms: 'plaintext',
  cxe: 'plaintext',
  cxx: 'plaintext',
  darkest: 'plaintext',
  'db-journal': 'plaintext',
  db3: 'plaintext',
  dbk: 'plaintext',
  dbml: 'plaintext',
  dbp: 'plaintext',
  dbproj: 'plaintext',
  ddt: 'plaintext',
  dea: 'plaintext',
  desc: 'plaintext',
  dfe: 'plaintext',
  diz: 'plaintext',
  dkz: 'plaintext',
  dml: 'plaintext',
  dok: 'plaintext',
  drp: 'plaintext',
  dsd: 'plaintext',
  dsml: 'plaintext',
  dtd: 'plaintext',
  dvb: 'plaintext',
  eaf: 'plaintext',
  ecsv: 'plaintext',
  edt: 'plaintext',
  eml: 'plaintext',
  es: 'plaintext',
  etx: 'plaintext',
  ext: 'plaintext',
  extra: 'plaintext',
  faq: 'plaintext',
  fdml: 'plaintext',
  fdoc: 'plaintext',
  fff: 'plaintext',
  ffpreset: 'plaintext',
  fodg: 'plaintext',
  fodp: 'plaintext',
  fods: 'plaintext',
  fol: 'plaintext',
  fountain: 'plaintext',
  fpage: 'plaintext',
  frag: 'plaintext',
  fsa: 'plaintext',
  fsi: 'plaintext',
  fsproj: 'plaintext',
  fsscript: 'plaintext',
  fsx: 'plaintext',
  ftn: 'plaintext',
  gct: 'plaintext',
  gmanifest: 'plaintext',
  gpd: 'plaintext',
  gpl: 'plaintext',
  grxml: 'plaintext',
  gsc: 'plaintext',
  gyp: 'plaintext',
  hdt: 'plaintext',
  hex: 'plaintext',
  hht: 'plaintext',
  hs: 'plaintext',
  hsk: 'plaintext',
  htm: 'plaintext',
  hvc: 'plaintext',
  hxml: 'plaintext',
  ica: 'plaintext',
  id31: 'plaintext',
  id32: 'plaintext',
  ide: 'plaintext',
  ift: 'plaintext',
  iif: 'plaintext',
  il: 'plaintext',
  ill: 'plaintext',
  inf: 'plaintext',
  imp: 'plaintext',
  ino: 'plaintext',
  ini2: 'plaintext',
  inuse: 'plaintext',
  ion: 'plaintext',
  jacl: 'plaintext',
  jeb: 'plaintext',
  jgs: 'plaintext',
  jpgw: 'plaintext',
  jsonp: 'plaintext',
  kcl: 'plaintext',
  key: 'plaintext',
  kl3: 'plaintext',
  klg: 'plaintext',
  lcx: 'plaintext',
  lge: 'plaintext',
  lgm: 'plaintext',
  'library-ms': 'plaintext',
  lnc: 'plaintext',
  log: 'plaintext',
  lol: 'plaintext',
  lols: 'plaintext',
  lsp: 'plaintext',
  lst: 'plaintext',
  ltr: 'plaintext',
  ltx: 'plaintext',
  lvm: 'plaintext',
  m2r: 'plaintext',
  manifest: 'plaintext',
  map: 'plaintext',
  mar: 'plaintext',
  markdn: 'plaintext',
  markdown: 'plaintext',
  maxFR: 'plaintext',
  mcml: 'plaintext',
  mdle: 'plaintext',
  mdown: 'plaintext',
  mdtext: 'plaintext',
  mdtxt: 'plaintext',
  mdwn: 'plaintext',
  me: 'plaintext',
  med: 'plaintext',
  mhl: 'plaintext',
  mib: 'plaintext',
  mit: 'plaintext',
  mkd: 'plaintext',
  mkdn: 'plaintext',
  mkp: 'plaintext',
  mml: 'plaintext',
  modd: 'plaintext',
  mof: 'plaintext',
  morse: 'plaintext',
  mscx: 'plaintext',
  msha: 'plaintext',
  mshxml: 'plaintext',
  mus: 'plaintext',
  nbr: 'plaintext',
  nc1: 'plaintext',
  nclk: 'plaintext',
  nfo: 'plaintext',
  nhp: 'plaintext',
  now: 'plaintext',
  npdt: 'plaintext',
  nvi: 'plaintext',
  ocr: 'plaintext',
  oeaccount: 'plaintext',
  oogl: 'plaintext',
  opml: 'plaintext',
  oplm: 'plaintext',
  osc: 'plaintext',
  p3x: 'plaintext',
  pas: 'plaintext',
  pcf: 'plaintext',
  pdml: 'plaintext',
  pdu: 'plaintext',
  pgw: 'plaintext',
  php1: 'plaintext',
  pks: 'plaintext',
  plg: 'plaintext',
  pln: 'plaintext',
  plsx: 'plaintext',
  pml: 'plaintext',
  pnach: 'plaintext',
  pod: 'plaintext',
  poix: 'plaintext',
  pop: 'plaintext',
  ppml: 'plaintext',
  prm: 'plaintext',
  properties: 'plaintext',
  psml: 'plaintext',
  psp: 'plaintext',
  pt: 'plaintext',
  ptxml: 'plaintext',
  pvj: 'plaintext',
  pyt: 'plaintext',
  qdl: 'plaintext',
  qdt: 'plaintext',
  qud: 'plaintext',
  rdat: 'plaintext',
  rea: 'plaintext',
  readme: 'plaintext',
  reg: 'plaintext',
  report: 'plaintext',
  resmoncfg: 'plaintext',
  resp: 'plaintext',
  resx: 'plaintext',
  rgu: 'plaintext',
  rml: 'plaintext',
  rng: 'plaintext',
  rpym: 'plaintext',
  rrc: 'plaintext',
  rsl: 'plaintext',
  rtc: 'plaintext',
  rtl: 'plaintext',
  rtx: 'plaintext',
  ru: 'plaintext',
  rub: 'plaintext',
  rzb: 'plaintext',
  s: 'plaintext',
  s1p: 'plaintext',
  s2p: 'plaintext',
  s3p: 'plaintext',
  s4p: 'plaintext',
  saas: 'plaintext',
  sami: 'plaintext',
  sbml: 'plaintext',
  scf: 'plaintext',
  scr: 'plaintext',
  sct: 'plaintext',
  sfb: 'plaintext',
  sha1: 'plaintext',
  sha512: 'plaintext',
  sls: 'plaintext',
  soap: 'plaintext',
  soundscript: 'plaintext',
  spa: 'plaintext',
  srt: 'plaintext',
  srx: 'plaintext',
  ss: 'plaintext',
  struct: 'plaintext',
  sub: 'plaintext',
  t: 'plaintext',
  tea: 'plaintext',
  text: 'plaintext',
  tlx: 'plaintext',
  trc: 'plaintext',
  ts0: 'plaintext',
  tsv: 'plaintext',
  tt: 'plaintext',
  tur: 'plaintext',
  txe: 'plaintext',
  txh: 'plaintext',
  txml: 'plaintext',
  txt12: 'plaintext',
  uhtml: 'plaintext',
  uni: 'plaintext',
  user: 'plaintext',
  ver: 'plaintext',
  vih: 'plaintext',
  vmsg: 'plaintext',
  vpl: 'plaintext',
  wbf: 'plaintext',
  websettings: 'plaintext',
  win32manifest: 'plaintext',
  wmlsc: 'plaintext',
  ws: 'plaintext',
  wsdd: 'plaintext',
  wsf: 'plaintext',
  wsh: 'plaintext',
  wtl: 'plaintext',
  xfd: 'plaintext',
  xmla: 'plaintext',
  xpd: 'plaintext',
  xsl: 'plaintext',
  xsql: 'plaintext',
  xtd: 'plaintext',
  yab: 'plaintext',
  ybhtm: 'plaintext',
  yct: 'plaintext',
  yml: 'plaintext',
  ywl: 'plaintext',
  zeg: 'plaintext',
  _me: 'plaintext',

  htaccess: 'plaintext'
};

export const FILE_MANAGER_DEAFULT_OCTAL_PERMISSIONS = '755';

export const MAIN_DOMAIN = 2;

export const PRIORITY = {
  f: 0,
  d: 1
};
