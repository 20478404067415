import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Section } from '@siteground/styleguide';

import { createNotification } from '../../../core/actions/notifications';
import { API_RESOURCE } from '../../../core/constants/api';
import { ToolId } from '../../../core/constants/route-info';
import indexWithCRUD from '../../components/indexWithCRUD';
import NoWPApplicationNotice from '../../components/no-wordpress-app-notice';
import PageHeader from '../../components/page-header';
import { CreateBox, CreateForm } from './create';

const noMatchCondition = '0 replacements';

interface Props {
  actions: CrudActions;
  wordpressApps: WordpressApp[];
  createNotification: typeof createNotification;
}

class WPReplace extends React.Component<Props> {
  findAppUrlById(id) {
    const app = this.props.wordpressApps?.find((app) => app.id === id);

    return app?.app_url;
  }

  render() {
    const hasNoWPApps = this.props.wordpressApps?.length === 0;

    return (
      <div>
        <PageHeader
          id={ToolId.wpSearchAndReplace}
          instructions={<FormattedMessage id="translate.page.wp.replace.info" />}
        />
        <Section>{hasNoWPApps ? <NoWPApplicationNotice /> : this.renderCreateBox()}</Section>
      </div>
    );
  }

  renderCreateBox() {
    return (
      <CreateBox>
        <CreateForm
          apps={this.props.wordpressApps}
          onSubmit={(data) => {
            const createNotification = (response) => {
              const isNoMatch = response?.stdout?.includes(noMatchCondition);
              const isError = isNoMatch || response?.exit_code === 1;

              return this.props.createNotification({
                type: 'generic',
                state: isError ? 'error' : 'success',
                error: {
                  intlKey: isNoMatch
                    ? 'translate.page.wp.replace.no.match.notification'
                    : 'translate.page.wp.replace.fail.notification'
                },
                success: {
                  intlKey: 'translate.page.wp.replace.success.notification',
                  intlValues: { from: data.search, to: data.replace, url: this.findAppUrlById(data.id) }
                }
              });
            };

            this.props.actions.updateItem(
              {
                id: data.id,
                _metaFields: data._metaFields,
                wpcli_command: {
                  command: 'search-replace',
                  search: data.search,
                  replace: data.replace
                }
              },
              createNotification
            );
          }}
        />
      </CreateBox>
    );
  }
}

export default indexWithCRUD(
  (store) => ({
    wordpressApps: store.pageItems[API_RESOURCE.WORDPRESS.resourceName]
  }),
  (dispatch) => ({
    createNotification: bindActionCreators(createNotification, dispatch)
  })
)(WPReplace, API_RESOURCE.WORDPRESS);
