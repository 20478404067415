import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Context,
  getDate,
  getTime,
  IconButton,
  Loader,
  SortContext,
  SortProvider,
  Table,
  Text
} from '@siteground/styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import bytesToSize from '../../../core/common/size-converter';
import { DIALOGS } from '../../../core/constants/common';
import {
  ascendingSortByInodes,
  ascendingSortByProperty,
  descendingSortByInodes,
  descendingSortByProperty
} from '../../../core/utils/sort';
import { TableHeaderSort } from '../../components/sg-table-sort';
import { getTranslations } from '../../components/sg-table/translation';
import { LoaderContext } from '../../contexts';
import { RootState } from '../../reducers';
import * as fileManagerActions from '../core/actions/file-manager';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANAGER_CONTEXT_MENU_TYPE } from '../core/constants/common';
import {
  getEntityPath,
  getEntityReadableName,
  getEntityType,
  getSymlinkEntityDestination,
  shouldRenderMobile
} from '../core/utils';
import DropZone from '../dropzone';
import { CodeEditor, FileManagerProps, FMEntity } from '../types';
import './content.scss';
import DragContentItem from './draggable-entity';
import Entity from './entity';
import EntityPreview from './entity-preview';

type Props = {
  entities: FileManagerProps['entities'];
  selectedNavigationEntity: FMEntity;
  actions: {
    dragAndDropFileUpload: typeof fileManagerActions.dragAndDropFileUpload;
    moveByDragAndDrop: typeof fileManagerActions.moveByDragAndDrop;
    selectContentRows: typeof fileManagerActions.selectContentRows;
    navigateToFMPath: typeof fileManagerActions.navigateToFMPath;
    onEntityClick: typeof fileManagerActions.onEntityClick;
    fetchDir: typeof fileManagerActions.fetchDir;
    fetchDirWithFolderSizes: typeof fileManagerActions.fetchDirWithFolderSizes;
  };
  openSGDialog: Function;
  openContextMenu: Function;
  intl: Intl;
  environment: any;
  selectedContentEntities: FMEntity[];
  codeEditor: CodeEditor;
};

class FileManagerContent extends React.Component<Props> {
  openMobileContextMenuTimeout;

  getEntitiesForRender() {
    const { entities, selectedNavigationEntity } = this.props;
    const selectedPath = selectedNavigationEntity && getEntityPath(selectedNavigationEntity);
    return selectedPath ? entities[selectedPath] : entities['/'];
  }

  renderDropZone = ({ parentLoaderVisible }) => {
    const entitiesToRender = this.getEntitiesForRender();
    const hasEntitiesToRenderLength = entitiesToRender && entitiesToRender.length > 0;

    return (
      <DropZone
        handleDrop={this.props.actions.dragAndDropFileUpload}
        onUnsupportedBroserDrop={() =>
          this.props.openSGDialog(DIALOGS.FILE_MANAGER_MESSAGE, {
            title: 'translate.file.manager.drag.and.drop.not.supported'
          })
        }
      >
        {hasEntitiesToRenderLength && this.renderTable()}
        {!hasEntitiesToRenderLength && this.renderContentPreview(parentLoaderVisible)}
      </DropZone>
    );
  };

  render() {
    const { entities } = this.props;

    if (Object.keys(entities).length === 0) {
      return null;
    }

    return (
      <div
        className="content"
        data-e2e="file-manager-panel"
        onContextMenu={(event) => this.props.openContextMenu(event, FILE_MANAGER_CONTEXT_MENU_TYPE.BASE)}
      >
        <LoaderContext.Consumer children={this.renderDropZone} />
      </div>
    );
  }

  renderContentPreview(parentLoaderVisible: boolean) {
    const { entities, selectedNavigationEntity, intl } = this.props;
    const entityPath = getEntityPath(selectedNavigationEntity);
    const isEntityFolder = getEntityType(selectedNavigationEntity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY;
    const folderName = getEntityReadableName(selectedNavigationEntity);

    const isLoadingVisible = isEntityFolder && entities[entityPath] === undefined;

    if (isLoadingVisible && !parentLoaderVisible) {
      return (
        <Loader className="content__loader">
          {intl.formatMessage({ id: 'translate.generic.loading' })}
          <br />
          <strong>{folderName}</strong>
        </Loader>
      );
    }

    return <EntityPreview entity={selectedNavigationEntity} />;
  }

  /**
   * Render Table with content ↓
   */

  renderTable() {
    const { environment, selectedContentEntities, intl } = this.props;
    const entitiesToRender = this.getEntitiesForRender();

    const defaultProps = {
      className: 'content__table content__table--mobile',
      data: entitiesToRender || [],
      mobileLayout: 'row',
      rowKey: FILE_MANAGER_API_RESPONSE_DIR.INFO_NUMBER,
      onRowSelection: shouldRenderMobile(environment) ? undefined : this.onRowSelection,
      selectable: true,
      selectedRows: selectedContentEntities,
      translation: getTranslations(intl)
    };

    return shouldRenderMobile(environment)
      ? this.renderMobileTable(defaultProps)
      : this.renderDesktopTable(defaultProps);
  }

  renderMobileTable(props) {
    const { selectedContentEntities } = this.props;
    const mobileColumns = [
      {
        accessor: FILE_MANAGER_API_RESPONSE_DIR.NAME,
        render: (cell, entity) => <Entity entity={entity} />
      },
      {
        accessor: FILE_MANAGER_API_RESPONSE_DIR.NAME,
        style: { textAlign: 'right', width: '70px' },
        render: (cell, entity) => {
          if (selectedContentEntities && selectedContentEntities.length > 1) {
            return null;
          }

          if (getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.SYMLINK) {
            return null;
          }

          return (
            <IconButton
              icon="dots"
              data-e2e="row-selection-button"
              aria-label={this.props.intl.formatMessage({ id: 'translate.aria-label.actions' })}
              onClick={(event) => {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();

                if (this.openMobileContextMenuTimeout) {
                  clearTimeout(this.openMobileContextMenuTimeout);
                }

                this.onRowSelection([entity]);
                this.props.openContextMenu(event);
              }}
            />
          );
        }
      }
    ];

    return (
      <Table
        disableTableHead
        density="small"
        columns={mobileColumns}
        onRowTap={(row, event) => {
          this.openMobileContextMenuTimeout = setTimeout(() => {
            this.onRowSelection([row]);
            this.onNameDoubleClickHandler(row);
          }, 100);
        }}
        onRowPress={(row) => {
          // TODO talk with Niki and Bobi about multi delete ....
          const isRowSelected = selectedContentEntities.find(
            (entity) => JSON.stringify(entity) === JSON.stringify(row)
          );

          const newSelectedRows = isRowSelected
            ? selectedContentEntities.filter((entity) => JSON.stringify(entity) !== JSON.stringify(row))
            : selectedContentEntities.concat(row);

          this.onRowSelection(newSelectedRows);
        }}
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        {...props}
      />
    );
  }

  renderDesktopTable(props) {
    const { intl, actions } = this.props;
    const { data, ...tableProps } = props;

    const columns = [
      {
        header: (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            config={{
              ascending: ascendingSortByProperty(FILE_MANAGER_API_RESPONSE_DIR.NAME),
              descending: descendingSortByProperty(FILE_MANAGER_API_RESPONSE_DIR.NAME)
            }}
          />
        ),
        accessor: FILE_MANAGER_API_RESPONSE_DIR.NAME,
        render: this.renderNameColumn
      },
      {
        header: (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.generic.date.modified' })}
            config={{
              ascending: ascendingSortByProperty(FILE_MANAGER_API_RESPONSE_DIR.MODIFIED_TIME),
              descending: descendingSortByProperty(FILE_MANAGER_API_RESPONSE_DIR.MODIFIED_TIME)
            }}
          />
        ),
        accessor: FILE_MANAGER_API_RESPONSE_DIR.MODIFIED_TIME,
        render: this.renderModifiedDateColumn,
        style: {
          width: '200px'
        }
      },
      {
        header: (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.generic.permissions' })}
            config={{
              ascending: ascendingSortByProperty(FILE_MANAGER_API_RESPONSE_DIR.PERMISSIONS),
              descending: descendingSortByProperty(FILE_MANAGER_API_RESPONSE_DIR.PERMISSIONS)
            }}
          />
        ),
        accessor: FILE_MANAGER_API_RESPONSE_DIR.PERMISSIONS,
        render: this.renderPermissionsColumn,
        style: {
          width: '160px'
        }
      },
      {
        header: (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.generic.inode' })}
            config={{
              ascending: (a, b) =>
                ascendingSortByInodes(a[FILE_MANAGER_API_RESPONSE_DIR.INODE], b[FILE_MANAGER_API_RESPONSE_DIR.INODE]),
              descending: (a, b) =>
                descendingSortByInodes(a[FILE_MANAGER_API_RESPONSE_DIR.INODE], b[FILE_MANAGER_API_RESPONSE_DIR.INODE])
            }}
            onClick={() => actions.fetchDirWithFolderSizes()}
          />
        ),
        accessor: FILE_MANAGER_API_RESPONSE_DIR.INODE,
        render: this.renderInodeColumn,
        style: {
          width: '120px'
        }
      },
      {
        header: (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.generic.size' })}
            config={{
              ascending: ascendingSortByProperty(FILE_MANAGER_API_RESPONSE_DIR.SIZE),
              descending: descendingSortByProperty(FILE_MANAGER_API_RESPONSE_DIR.SIZE)
            }}
            onClick={() => actions.fetchDirWithFolderSizes()}
            flexProps={{
              justify: 'flex-end'
            }}
          />
        ),
        accessor: FILE_MANAGER_API_RESPONSE_DIR.SIZE,
        render: this.renderSizeColumn,
        style: {
          textAlign: 'right',
          width: '130px'
        }
      }
    ];

    return (
      <SortProvider data={data}>
        <SortContext.Consumer>
          {({ sortedData }) => (
            <Table
              data={sortedData}
              columns={columns}
              onRowContextMenu={({ event, selectedRows }) => {
                event.preventDefault();
                event.stopPropagation();
                this.onRowSelection(selectedRows);
                this.props.openContextMenu(event);
              }}
              {...tableProps}
            />
          )}
        </SortContext.Consumer>
      </SortProvider>
    );
  }

  renderNameColumn = (name, entity) => {
    return (
      <DragContentItem
        entity={entity}
        handleDrop={this.props.actions.moveByDragAndDrop}
        onDoubleClick={(event) => this.onNameDoubleClickHandler(entity)}
      />
    );
  };

  renderModifiedDateColumn(dm, entity) {
    return (
      <div className="content__row">
        <Text color="light">{`${getDate(dm)} ${getTime(dm)}`}</Text>
      </div>
    );
  }

  renderInodeColumn = (inode, entity) => {
    if (!inode) {
      return '-';
    }

    return (
      <div className="content__row" style={{ justifyContent: 'flex-end' }} aria-haspopup>
        <Text color="light">{inode}</Text>
      </div>
    );
  };

  renderSizeColumn = (size, entity) => {
    const { intl } = this.props;
    const isFile = getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;
    const renderInfo = isFile || (!isFile && size > 0) ? bytesToSize(size) : '-';

    return (
      <div className="content__row" style={{ justifyContent: 'flex-end' }}>
        <Text color="light">{renderInfo}</Text>
      </div>
    );
  };

  renderPermissionsColumn = (permissions, entity) => {
    return (
      <div className="content__row">
        <Text color="light">{permissions}</Text>
      </div>
    );
  };

  onRowSelection = (selectedContentEntities) => {
    const selectedRows = selectedContentEntities.filter((entity) =>
      Boolean(getEntityType(entity) !== FILE_MANAGER_API_RESPONSE_DIR.SYMLINK)
    );

    this.props.actions.selectContentRows(selectedRows);
  };

  onNameDoubleClickHandler = (entity) => {
    const { actions } = this.props;

    const entityType = getEntityType(entity);

    if (entityType === FILE_MANAGER_API_RESPONSE_DIR.SYMLINK) {
      return actions.navigateToFMPath(getSymlinkEntityDestination(entity));
    }

    if (entityType === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY) {
      this.openFolder(entity);
    }

    if (entityType === FILE_MANAGER_API_RESPONSE_DIR.FILE) {
      this.props.codeEditor.openFile(entity);
    }
  };

  openFolder(entity) {
    this.props.actions.onEntityClick({
      entity
    });

    this.props.actions.fetchDir({
      urlParams: {
        id: getEntityPath(entity)
      }
    });
  }
}

const ContentWithDevice = (props) => (
  <Context.Consumer>{({ device }) => <FileManagerContent device={device} {...props} />}</Context.Consumer>
);

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch),
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

const mapStateToProps = (state: RootState) => ({
  time: state.fileManager.time,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  selectedContentEntities: state.fileManager.selectedContentEntities,
  entities: state.fileManager.entities,
  environment: state.environment
});

export default connect<{}, {}, Partial<Props>>(mapStateToProps, mapDispatchToProps)(injectIntl(ContentWithDevice));
