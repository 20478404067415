import { handleActions } from 'redux-actions';
import * as Actions from '../../../../core/constants/actions';

import CodeEditorReducer from './code-editor';
import ContextMenuReducer from './context-menu';
import DialogsReducer from './dialogs';
import FetchEntitiesReducer from './fetch-entities';
import NavigationReducer from './navigation';
import OtherReducer from './others';
import ResetReducer from './reset';
import SearchViewReducer from './search-view';
import UploadReducer from './upload';

import { initialFileManagerState } from './utils/initial-state';

export default handleActions(
  {
    ...FetchEntitiesReducer,
    ...NavigationReducer,
    ...CodeEditorReducer,
    ...UploadReducer,
    ...DialogsReducer,
    ...OtherReducer,
    ...ResetReducer,
    ...ContextMenuReducer,
    ...SearchViewReducer,
    [Actions.LOGOUT]: () => initialFileManagerState
  },
  initialFileManagerState
);
