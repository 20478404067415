import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Flex, FormLabel, Grid, Spacer } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../../core/constants/common';
import FormInput from '../../../../components/form-input';
import FormRadioButton from '../../../../components/form-radio-button';
import { withSpanelForm } from '../../../../components/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  initialValues: {
    _metaFields: {
      domainName: string;
    };
  };
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CLOUDFLARE_ADD_IP_RANGE_DIALOG;

const FieldsForm = ({ initialValues, intl, validationUtils }: Props) => (
  <Grid>
    <Field
      name="value"
      label={intl.formatMessage({ id: 'translate.page.cloudflare.ip.range' })}
      validate={[validationUtils.required]}
      component={FormInput}
    />

    <div>
      <FormLabel>{intl.formatMessage({ id: 'translate.page.cloudflare.select.status' })}</FormLabel>

      <Flex align="flex-start">
        <Field name="fw_mode" type="radio" value="whitelist" component={FormRadioButton}>
          {intl.formatMessage({ id: 'translate.page.cloudflare.status.whitelist' })}
        </Field>

        <Spacer size="medium" />

        <Field name="fw_mode" type="radio" value="block" component={FormRadioButton}>
          {intl.formatMessage({ id: 'translate.page.cloudflare.status.block' })}
        </Field>
      </Flex>
    </div>
  </Grid>
);

export default withSpanelForm(reduxForm({ form: formName })(injectIntl(FieldsForm)));
