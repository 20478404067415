import * as React from 'react';
import { useIntl } from 'react-intl';
import { Column, Flex, Grid, Link, Text, Textarea } from '@siteground/styleguide';
import { DIALOGS } from '../../../core/constants/common';

import { SGDialog, SGDialogCancel } from '../../components/sg-dialog';
import RenderIf from '../../components/render-if';

type Props = {
  malwareReport: any[];
  domainName: string;
  handleCopyToClipboard: (value: string) => any;
};

export const MalwareDetails = ({ malwareReport, domainName, handleCopyToClipboard }: Props) => {
  const intl = useIntl();
  return (
    <SGDialog
      id={DIALOGS.SG_SITESCANNER_MALWARE_DETAILS}
      state="warning"
      icon="exclamation"
      size="large"
      title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.malware-found.title' }, { site: domainName })}
      footer={
        <SGDialogCancel
          id={DIALOGS.SG_SITESCANNER_MALWARE_DETAILS}
          label={intl.formatMessage({ id: 'translate.generic.close' })}
        />
      }
    >
      <Grid>
        {malwareReport &&
          malwareReport.map((r, i) => (
            <Column key={i}>
              <RenderIf condition={r.suspiciousFiles}>
                <Text weight="bold" align="left" color="dark">
                  {intl.formatMessage({ id: 'translate.page.sg-sitescanner.suspicious-files.label' })}:&nbsp;
                  <Text tag="span" weight="light">
                    {r.suspiciousFiles}
                  </Text>
                </Text>
              </RenderIf>

              <RenderIf condition={r.url}>
                <Text weight="bold" align="left" color="dark">
                  {intl.formatMessage({ id: 'translate.page.sg-sitescanner.url.label' })}:&nbsp;
                  <Text tag="span" weight="light">
                    {r.url}
                  </Text>
                </Text>
              </RenderIf>

              <RenderIf condition={r.threat}>
                <Text weight="bold" align="left" color="dark">
                  {intl.formatMessage({ id: 'translate.page.sg-sitescanner.threat.label' })}:&nbsp;
                  <Text tag="span" weight="light">
                    {r.threat}
                  </Text>
                </Text>
              </RenderIf>

              <Textarea
                readOnly
                rows="4"
                value={r.details}
                size="small"
                label={
                  <Flex align="center" justify="space-between" padding={['x-small', 'none', 'none', 'none']}>
                    {intl.formatMessage({ id: 'translate.page.sg-sitescanner.details.label' })}:&nbsp;
                    <Link role="button" onClick={() => handleCopyToClipboard(r.details)}>
                      {intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                    </Link>
                  </Flex>
                }
              />
            </Column>
          ))}
      </Grid>
    </SGDialog>
  );
};

export default MalwareDetails as (props: Props) => any;
