import { WmToolId } from '../../../core/constants/route-info';
import { WM_ROUTES } from './WM_ROUTES';
import { MenuGroupST } from '../../../core/definitions/menu-items';

export const groups: MenuGroupST[] = [
  {
    toolId: WmToolId.wmPages,
    items: [
      {
        toolId: WmToolId.wmLoginCredentials,
        route: WM_ROUTES[WmToolId.wmLoginCredentials]
      },
      {
        toolId: WmToolId.wmEmailConfig,
        route: WM_ROUTES[WmToolId.wmEmailConfig]
      },
      {
        toolId: WmToolId.wmEmailAutoresponder,
        route: WM_ROUTES[WmToolId.wmEmailAutoresponder]
      },
      {
        toolId: WmToolId.wmEmailForward,
        route: WM_ROUTES[WmToolId.wmEmailForward]
      },
      {
        toolId: WmToolId.wmEmailFilters,
        route: WM_ROUTES[WmToolId.wmEmailFilters]
      }
    ]
  }
];

export const rcmailItems = [
  {
    title: 'translate.wm.page.email.title',
    icon: 'mail',
    address: 'mail'
  },
  {
    title: 'translate.wm.page.contacts.title',
    icon: 'users',
    address: 'addressbook'
  },
  {
    title: 'translate.wm.page.settings.title',
    icon: 'settings',
    address: 'settings'
  }
];
