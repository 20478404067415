import * as React from 'react';
import { API_RESOURCE } from '../../../core/constants/api';
import indexWithCRUD from '../../components/indexWithCRUD';
import SGTable from '../../components/sg-table/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../components/visibility-control-service';
import { requestData } from '../../../core/actions/request-data';
import { getNonStagingDomains } from '../php-settings/utils';

type Props = {
  items: any;
  actions: CrudActions;
  requestData: typeof requestData;
  intl: Intl;
};

class DynamicCachePage extends React.Component<Props, any> {
  renderActionMenu = (name, entity) => {
    const { intl, actions } = this.props;

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.DOMAIN_ALL.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            icon: 'flush',
            label: intl.formatMessage({ id: 'translate.page.domainCache.flush_cache' }),
            e2eAttr: 'table-action-flush-cache',
            visibleOnDesktop: true,
            onClick: () =>
              actions.updateItem({
                _metaFields: {
                  ...API_RESOURCE.DOMAIN_ALL,
                  dontChangeItemsState: true
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.domainCache.cache_flushed',
                      intlValues: { entityName: entity.name }
                    },
                    error: {
                      intlKey: 'translate.page.domainCache.cache_flushed.failed',
                      intlValues: { entityName: entity.name }
                    }
                  }
                },
                flush_cache: 1,
                id: entity.id
              })
          }
        ]}
      />
    );
  };

  render() {
    const { intl, items } = this.props;

    if (!(items && items.domainAll)) {
      return null;
    }

    return (
      <VCS resourceName={API_RESOURCE.DOMAIN_ALL.resourceNameMetaApi} hasMethod="GET">
        <SGTable
          addOffsetOnMobile
          shadow={false}
          mobileLayout="card-flat"
          data={getNonStagingDomains({ domains: items.domainAll })}
          columns={[
            {
              header: intl.formatMessage({ id: 'translate.generic.website' }),
              accessor: 'name',
              mSize: '75%'
            },
            {
              header: intl.formatMessage({ id: 'translate.generic.actions' }),
              accessor: 'id',
              render: this.renderActionMenu
            }
          ]}
          rowResources={[{ resourceName: API_RESOURCE.DOMAIN_ALL.resourceName, methods: ['PUT'] }]}
        />
      </VCS>
    );
  }
}

export default indexWithCRUD(undefined, { requestData })(DynamicCachePage, API_RESOURCE.DOMAIN_ALL);
