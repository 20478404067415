import { UPDATE_EMAIL_FILTERS_ORDER } from '../../constants/actions';
import customRequestTypes from '../../constants/custom-request-types';

type UpdateEmailFilterOrder = {
  name: string;
  domain_id: number;
  filter_ids: number[];
  _meta: any;
};

export const updateEmailFilterOrder = (payload: UpdateEmailFilterOrder) => ({
  type: UPDATE_EMAIL_FILTERS_ORDER,
  payload,
  requestTypeName: customRequestTypes.EMAIL_FILTER_ORDER
});
