import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Column, Grid, Notice, Text } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import MobileBreakElement from '../../../components/break-element';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

const { resourceName, resourceNameMetaApi } = API_RESOURCE.PGSQL_DB;
const formName = REDUX_FORM.CREATE_PGSQL_DATABASE;

type Props = {
  router?: any;
  location?: any;
  dbPgsqlUser?: any[];
  onAddUserToDatabase: Function;
  onSubmit: Function;
};

type DispatchProps = {
  intl: Intl;
};

class Create extends React.Component<Props & DispatchProps> {
  public render() {
    const { intl, onAddUserToDatabase, onSubmit } = this.props;
    const { dbPgsqlUser, router, location } = this.props;

    return (
      <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
        <CreateBox
          title={intl.formatMessage({ id: 'translate.page.database.create.title' })}
          renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
            if (notification.state === 'error') {
              return renderDefaultNotificationTemplate();
            }

            return (
              <div style={{ textAlign: 'center' }}>
                {dbPgsqlUser.length ? (
                  <Button
                    type="outlined"
                    color="secondary"
                    data-e2e="add-to-database"
                    onClick={(event) => {
                      event.preventDefault();
                      onAddUserToDatabase(notification.responseData);
                    }}
                  >
                    {intl.formatMessage({ id: 'translate.page.database.create.button.add.user.to.database' })}
                  </Button>
                ) : (
                  <Button
                    type="outlined"
                    color="secondary"
                    data-e2e="create-user"
                    onClick={() => router.push(`${ROUTES[ToolId.pgsqlUser]}${location.search}`)}
                  >
                    {intl.formatMessage({ id: 'translate.page.database.create.button.create.user' })}
                  </Button>
                )}

                <MobileBreakElement />

                <Button type="outlined" color="secondary" data-e2e="create-new" onClick={removeNotification}>
                  {intl.formatMessage({ id: 'translate.page.database.create.button.create.new' })}
                </Button>
              </div>
            );
          }}
          resourceName={resourceName}
          formName={formName}
          resources={[
            {
              resourceName: API_RESOURCE.PGSQL_DB.resourceName,
              methods: ['POST']
            }
          ]}
        >
          <Grid sm="12" padding="responsive" gap="responsive">
            <Column smSpan="12">
              <Notice type="info" background="light" shadow={false}>
                <Text>{intl.formatMessage({ id: 'translate.page.database.create.notice.one.click' })}</Text>
              </Notice>
            </Column>
            <Column smSpan="12">
              <Button
                color="primary"
                action="button"
                data-e2e="create-box-submit"
                onClick={() =>
                  onSubmit({
                    _metaFields: {
                      ...API_RESOURCE.PGSQL_DB
                    }
                  })
                }
              >
                {intl.formatMessage({ id: 'translate.page.database.create.button.database' })}
              </Button>
            </Column>
          </Grid>
        </CreateBox>
      </VCS>
    );
  }
}

const mapStateToProps = (state) => ({
  dbPgsqlUser: state.pageItems.dbPgsqlUser || []
});

export default connect<{}, DispatchProps, Props>(mapStateToProps)(withRouter(injectIntl(Create)));
