export const LANGUAGE_STORAGE_KEY = 'spanel_i18n';

export const getSelectedLanguageFromLocalStorage = () => JSON.parse(localStorage.getItem(LANGUAGE_STORAGE_KEY));

export const getUserLanguageFilename = (language: string) => {
  const lang = language || (navigator && navigator.language);
  const prefix = lang && lang.split('-')[0];

  switch (prefix) {
    case LANGUAGES.ITALIAN:
      return 'it_IT';
    case LANGUAGES.SPANISH:
      return 'es_ES';
    case LANGUAGES.GERMAN:
      return 'de_DE';
    default:
      return 'en';
  }
};

export enum LANGUAGES {
  ENGLISH = 'en',
  ITALIAN = 'it',
  SPANISH = 'es',
  GERMAN = 'de'
}

export const preselectLanguage = () => {
  const localStorageLang = getSelectedLanguageFromLocalStorage().i18n.locale;
  if (localStorageLang) {
    return localStorageLang;
  }

  return LANGUAGES.ENGLISH;
};

export const languageToLocaleMapping = {
  en: 'en',
  es_ES: 'es',
  it_IT: 'it',
  de_DE: 'de'
};

export const getInitialLang = (props) => {
  const lang = props.location && new URLSearchParams(props.location.search).get('lang');
  let value = null;
  switch (lang) {
    case LANGUAGES.SPANISH:
      value = getUserLanguageFilename(LANGUAGES.SPANISH);
      break;
    case LANGUAGES.ITALIAN:
      value = getUserLanguageFilename(LANGUAGES.ITALIAN);
      break;
    case LANGUAGES.ENGLISH:
      value = getUserLanguageFilename(LANGUAGES.ENGLISH);
      break;
    default:
      value = preselectLanguage();
      break;
  }
  return value;
};
