import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Flex, Tab, Tabs, Text, TextProps } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import ListBox from '../../../components/list-box';
import PartialLoader from '../../../components/partial-loader';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import { SGSubmitButton } from '../../../components/sg-form';
import { WebMail } from '../index';
import { default as SelectDefaultWebmail } from '../select-default-webmail';
import './autoconfigure.scss';
import MailClientForm from './mail-client-form';

enum SettingsChoice {
  Autoconfigure = 'autoconfigure',
  Manual = 'manual',
  DefaultWebmail = 'defaultWebmail'
}

const SETTINGS_CHOICE_OPTIONS: SettingsChoice[] = [
  SettingsChoice.Autoconfigure,
  SettingsChoice.Manual,
  SettingsChoice.DefaultWebmail
];

type Props = {
  domainConfig: {
    imap_host: string;
    smtp_host: string;
    imap_port: number;
    pop3_port: number;
    smtp_port: number;
  };
  userEmail: string;
  defaultWebMail?: WebMail;
  onSubmit: Function;
  onWebMailSelected: (webmail: WebMail) => any;
};

type DispatchProps = {
  intl: Intl;
};

type State = {
  selectedIndex: 0 | 1;
  settingsChoice: SettingsChoice;
  selectedWebmail: WebMail;
};

const flexWrapperStyle = { maxWidth: '100%', width: '100%' };
const textProps: Partial<TextProps> = { size: 'medium', color: 'dark' };

const initialState: State = {
  selectedIndex: 0,
  settingsChoice: SettingsChoice.Autoconfigure,
  selectedWebmail: null
};

class Autoconfigure extends React.Component<Props & DispatchProps, State> {
  readonly state: State = initialState;

  componentDidUpdate(prevProps) {
    const { defaultWebMail } = this.props;

    const shouldSetWebMail = Boolean(
      (defaultWebMail && !this.state.selectedWebmail) || defaultWebMail !== prevProps.defaultWebMail
    );

    if (shouldSetWebMail) {
      this.setState({ selectedWebmail: defaultWebMail });
    }
  }

  toggleWebMail = (nextSelected: WebMail) => {
    const { selectedWebmail } = this.state;
    const webmail = nextSelected === selectedWebmail ? null : nextSelected;

    this.setState({ selectedWebmail: webmail });
  };

  renderFooter = () => {
    const { intl, onWebMailSelected } = this.props;
    const { settingsChoice, selectedWebmail } = this.state;

    switch (settingsChoice) {
      case SettingsChoice.Autoconfigure:
        return (
          <div>
            <SGDialogCancel id={DIALOGS.EMAIL_AUTOCONFIGURE} />
            <SGSubmitButton formName={REDUX_FORM.CHOOSE_MAIL_CLIENT_FORM}>
              {intl.formatMessage({ id: 'translate.generic.generate' })}
            </SGSubmitButton>
          </div>
        );
      case SettingsChoice.DefaultWebmail:
        return (
          <div>
            <SGDialogCancel id={DIALOGS.EMAIL_AUTOCONFIGURE} />
            <Button
              color="primary"
              disabled={!Boolean(selectedWebmail)}
              onClick={() => onWebMailSelected(selectedWebmail)}
            >
              {intl.formatMessage({ id: 'translate.generic.confirm' })}
            </Button>
          </div>
        );

      default:
        return (
          <div>
            <SGDialogCancel
              id={DIALOGS.EMAIL_AUTOCONFIGURE}
              label={intl.formatMessage({ id: 'translate.generic.close' })}
            />
          </div>
        );
    }
  };

  renderAutoconfigureSelect = () => (
    <React.Fragment>
      <Flex gutter="medium" margin="medium" style={flexWrapperStyle}>
        <Text align="center" {...textProps}>
          {this.props.intl.formatMessage({ id: 'translate.page.email.mail.config.desc' })}
        </Text>
      </Flex>
      <Flex gutter="medium" margin="medium" style={{ ...flexWrapperStyle, marginBottom: 0 }}>
        <MailClientForm onSubmit={({ emailClientId }) => this.props.onSubmit(emailClientId)} />
      </Flex>
    </React.Fragment>
  );

  renderManualSettings = () => {
    const { domainConfig } = this.props;

    return (
      <React.Fragment>
        <Flex gutter="medium" margin="medium" style={flexWrapperStyle}>
          <Text {...textProps}>
            {this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.use.the.settings.text' })}
          </Text>
        </Flex>

        <Flex gutter="medium" margin="medium" style={flexWrapperStyle}>
          <ListBox
            label={this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.label' })}
            data={[
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.username.label' }),
                value: this.props.userEmail
              },
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.password.label' }),
                value: this.props.intl.formatMessage({ id: 'translate.page.email.use.user.password' })
              },
              {
                label: this.props.intl.formatMessage({
                  id: 'translate.page.email.manual.settings.incoming.server.label'
                }),
                value: domainConfig.imap_host
              },
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.imap.port.label' }),
                value: domainConfig.imap_port
              },
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.pop3.port.label' }),
                value: domainConfig.pop3_port
              },
              {
                label: this.props.intl.formatMessage({
                  id: 'translate.page.email.manual.settings.outgoing.server.label'
                }),
                value: domainConfig.smtp_host
              },
              {
                label: this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.smtp.port.label' }),
                value: domainConfig.smtp_port
              }
            ]}
          />
        </Flex>

        <Flex gutter="medium" margin="medium" style={{ ...flexWrapperStyle, marginBottom: 0 }}>
          <Text {...textProps}>
            {this.props.intl.formatMessage({ id: 'translate.page.email.manual.settings.authentication.required.text' })}
          </Text>
        </Flex>
      </React.Fragment>
    );
  };

  render() {
    const { intl } = this.props;
    const { settingsChoice, selectedWebmail } = this.state;

    const buttons = [
      intl.formatMessage({ id: 'translate.page.email.settings.autoconfig' }),
      intl.formatMessage({ id: 'translate.page.email.settings.manual' })
    ];

    return (
      <SGDialog
        id={DIALOGS.EMAIL_AUTOCONFIGURE}
        icon="mail-settings"
        state="warning"
        size="x-large"
        title={intl.formatMessage({ id: 'translate.page.email.pop.imap.settings.title' })}
        footer={this.renderFooter()}
        onCloseHandler={() => this.setState({ ...initialState })}
        resources={[
          {
            resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
            methods: ['GET']
          }
        ]}
      >
        <PartialLoader
          resources={[
            {
              requestTypeName: customRequestTypes.EMAIL_AUTOCONFIGURE_DATA
            },
            {
              resourceName: API_RESOURCE.EMAIL.resourceName,
              methods: ['PUT']
            }
          ]}
        >
          <Flex justify="center">
            <Tabs border="light">
              {buttons.map((i18n, index) => (
                <Tab
                  key={`autoconfigure-${index}`}
                  active={SETTINGS_CHOICE_OPTIONS.indexOf(this.state.settingsChoice) === index}
                  onClick={() => this.setState({ settingsChoice: SETTINGS_CHOICE_OPTIONS[index] })}
                >
                  {i18n}
                </Tab>
              ))}
            </Tabs>
          </Flex>

          {settingsChoice === SettingsChoice.Autoconfigure && this.renderAutoconfigureSelect()}
          {settingsChoice === SettingsChoice.Manual && this.renderManualSettings()}
          {settingsChoice === SettingsChoice.DefaultWebmail && (
            <SelectDefaultWebmail selectedWebmail={selectedWebmail} onToggleWebmail={this.toggleWebMail} />
          )}
        </PartialLoader>
      </SGDialog>
    );
  }
}

export default injectIntl(Autoconfigure) as (props: Props) => any;
