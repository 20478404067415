import * as React from 'react';
import { useIntl } from 'react-intl';
import { Tabs, Tab, Padding } from '@siteground/styleguide';

export const TableTabs = ({ isPhone, tabActive, onTabChange }) => {
  const intl = useIntl();
  const phonePadding: Padding[] = ['none', 'small'];
  const tabsPadding = isPhone ? phonePadding : undefined;

  return (
    <Tabs size="medium" border="light">
      <Tab
        data-e2e="php-settings-tab-version"
        active={tabActive === 'version'}
        onClick={() => onTabChange('version')}
        padding={tabsPadding}
      >
        {intl.formatMessage({ id: 'translate.page.phpSettings.version.tab' })}
      </Tab>
      <Tab
        data-e2e="php-settings-tab-variables"
        active={tabActive === 'variables'}
        onClick={() => onTabChange('variables')}
        padding={tabsPadding}
      >
        {intl.formatMessage({ id: 'translate.page.phpSettings.variables.tab' })}
      </Tab>
      <Tab
        data-e2e="php-settings-tab-extensions"
        active={tabActive === 'extensions'}
        onClick={() => onTabChange('extensions')}
        padding={tabsPadding}
      >
        {intl.formatMessage({ id: 'translate.page.phpSettings.extensions.tab' })}
      </Tab>
    </Tabs>
  );
};

export default TableTabs as typeof TableTabs;
