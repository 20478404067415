import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, copyToClipboard, Grid, Input, Link, Section } from '@siteground/styleguide';
import { createNotification } from '../../../core/actions/notifications';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import { RootState } from '../../reducers';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import VCS from '../../components/visibility-control-service';
import { filterStagingDomains } from '../../../core/selectors';
import { CreateBox, CreateForm } from './create';

interface Props {
  actions: {
    createItem: CreateItem;
    fetchItems: FetchItems;
  };
  environment: {
    isPhone: boolean;
  };
  intl: Intl;
  wordpressTransfer: any[];
  domainAll: any[];
  getFormValue: Function;
  createNotification: typeof createNotification;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
}

class WPTransferPage extends React.Component<Props> {
  handleCopyToClipboard(value) {
    const { createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.page.wp.transfer.copy.migration.token.success'
        },
        error: {
          intlKey: 'translate.page.wp.transfer.copy.migration.token.error'
        }
      });
    });
  }

  onCreateFormSubmit = (formData) => {
    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_WP_TRANSFER,
          success: {
            intlKey: 'translate.page.wp.transfer.create.success.title',
            intlValues: { url: `${formData.domain_name}${formData.path}` }
          },
          error: {
            intlKey: 'translate.page.wp.transfer.create.error.title',
            intlValues: { url: `${formData.domain_name}${formData.path}` }
          }
        }
      }
    });
  };

  renderMigrationToken = (cell) => {
    const { intl } = this.props;

    return (
      <Input
        readOnly
        value={cell}
        suffix={
          <Button color="light" size="small" onClick={() => this.handleCopyToClipboard(cell)}>
            {intl.formatMessage({ id: 'translate.generic.copy' })}
          </Button>
        }
      />
    );
  };

  render() {
    const { domainAll, intl, wordpressTransfer } = this.props;
    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.domain' }),
        accessor: 'domain_name',
        render: (cell, entity) => `${entity.domain_name}${entity.path}`
      },
      {
        header: intl.formatMessage({ id: 'translate.page.wp.transfer.label.migration.token' }),
        accessor: 'transfer_token',
        render: this.renderMigrationToken,
        style: this.props.environment.isPhone ? undefined : { textAlign: 'left', maxWidth: '500px' }
      }
    ];

    return (
      <div>
        <PageHeader
          id={ToolId.wpTransfer}
          instructions={
            <FormattedMessage
              id="translate.page.wp.transfer.info"
              values={{
                link: (
                  <Link href="https://wordpress.org/plugins/siteground-migrator/" target="_blank">
                    <FormattedMessage id="translate.page.wp.transfer.info.link" />
                  </Link>
                )
              }}
            />
          }
        />

        <Section>
          <Grid>
            <CreateBox renderMigrationToken={this.renderMigrationToken}>
              <CreateForm domainAll={domainAll} onSubmit={(data) => this.onCreateFormSubmit({ path: '/', ...data })} />
            </CreateBox>

            <VCS resourceName={API_RESOURCE.WORDPRESS_TRANSFER.resourceName} hasMethod="GET">
              <SGTable
                hasMenu={false}
                disableMobileArrange
                title={intl.formatMessage({ id: 'translate.page.wp.transfer.sg-table.title' })}
                data={wordpressTransfer}
                columns={columns}
                resources={[{ resourceName: API_RESOURCE.WORDPRESS_TRANSFER.resourceName, methods: ['GET'] }]}
                noDataMessage="translate.page.wp.transfer.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  environment: store.environment,
  domainAll: filterStagingDomains(store, API_RESOURCE.DOMAIN_ALL.resourceName),
  wordpressTransfer: store.pageItems[API_RESOURCE.WORDPRESS_TRANSFER.resourceName]
});

export default indexWithCRUD(mapStateToProps, {
  ...sgDialogActions,
  createNotification
})(WPTransferPage, API_RESOURCE.DOMAIN_ALL, API_RESOURCE.WORDPRESS_TRANSFER);
