import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button, Grid, Text } from '@siteground/styleguide';

import { HostingTypes, planExipiresLessThan30Days } from '@siteground/styleguide/lib/utils';
import { DIALOGS } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { PLAN_CARD_ICON_PROPS } from '../../../../core/constants/icon-props';
import { PlanCard } from '../../../components/plan-card';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';

interface Props {
  upgradeResponse: OrderUpgradeData;
  onPlanConfirmed: ({ planType }) => void;
}

const getPlans = (upgradeResponse: OrderUpgradeData) => {
  if (!upgradeResponse) {
    return [];
  }

  const { shared_plus, shared_geek } = upgradeResponse.plans.shared;
  return [shared_plus, shared_geek];
};

const UpgradePlanDialog: React.FC<Props> = ({ onPlanConfirmed, upgradeResponse }) => {
  const [selectedPlan, setSelectedPlan] = React.useState<HostingTypes>(null);
  const intl = useIntl();

  const plans = getPlans(upgradeResponse);
  const toggleSelectedPlan = React.useCallback(
    (planType) => setSelectedPlan((state) => (state === planType ? null : planType)),
    [selectedPlan]
  );

  return (
    <SGDialog
      id={DIALOGS.PHP_MOD_UPGRADE_DIALOG}
      icon="warning"
      state="warning"
      title={intl.formatMessage({ id: 'translate.page.phpSettings.upgrade-plan-dialog.title' })}
      size="x-large"
      onCloseHandler={() => setSelectedPlan(() => null)}
      resources={[{ requestTypeName: customRequestTypes.REQUEST_ACCOUNT_UPGRADE_PLANS }]}
      footer={
        <React.Fragment>
          <SGDialogCancel
            id={DIALOGS.PHP_MOD_UPGRADE_DIALOG}
            label={intl.formatMessage({ id: 'translate.generic.cancel' })}
          />
          <Button
            data-e2e="mod-php-upgrade-dialog-submit"
            color="primary"
            disabled={selectedPlan === null}
            onClick={() => onPlanConfirmed({ planType: selectedPlan })}
          >
            {intl.formatMessage({ id: 'translate.generic.continue' })}
          </Button>
        </React.Fragment>
      }
    >
      <Text weight="bold">{intl.formatMessage({ id: 'translate.page.phpSettings.upgrade-plan-dialog.text' })}</Text>
      <br />
      {plans.length > 0 && (
        <PlanCards
          plans={plans}
          selectedPlan={selectedPlan}
          toggleSelectedPlan={toggleSelectedPlan}
          upgradeResponse={upgradeResponse}
        />
      )}
    </SGDialog>
  );
};

function PlanCards({
  plans,
  selectedPlan,
  toggleSelectedPlan,
  upgradeResponse
}: {
  plans: PlanDetails[];
  selectedPlan: string;
  toggleSelectedPlan: (plan: string) => void;
  upgradeResponse: any;
}) {
  const intl = useIntl();

  return (
    <Grid sm="2" gap="responsive">
      {plans.map(
        (plan: PlanDetails, index: number) =>
          plan && (
            <PlanCard
              key={index}
              plan={plan}
              intl={intl}
              iconProps={PLAN_CARD_ICON_PROPS[plan.name]}
              sharedPlanExpiresLessThan30Days={planExipiresLessThan30Days(upgradeResponse as any)}
              buttonProps={{
                text: intl.formatMessage({ id: 'translate.generic.select.plan.label' }),
                textSelected: intl.formatMessage({ id: 'translate.generic.selected' }),
                selected: plan.name === selectedPlan,
                onClick: () => toggleSelectedPlan(plan.name)
              }}
            />
          )
      )}
    </Grid>
  );
}

export default UpgradePlanDialog;
