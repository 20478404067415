import * as React from 'react';
import { Context, Grid, Table } from '@siteground/styleguide';
import Title from '@siteground/styleguide/lib/components/title/title';
import { StatsType } from '../../../../core/definitions/stats';
import { sortByValue } from '../../../../core/utils/stats-format';
import SGTable from '../../../components/sg-table';
import { ControlOptions, StatsViewIndex } from '../index';
import { TABLE_VIEW_PROPS } from '../utils';
import PieChartStatistics from './pie-charts';

type Props = {
  environment: {
    isPhone: boolean;
    isTablet: boolean;
  };
  getStats: (statsKey: StatsType) => any;
  statsMissing: boolean;
  renderControls: (option?: ControlOptions) => any;
  statisticViewIndex: StatsViewIndex;
  intl: Intl;
};

const appendChartLegendProps = (data = [], legendLabels) =>
  data.map((item) => {
    const total = data.map((d) => d.hits).reduce((acc, value) => acc + value, 0);
    const percentage = ((item.hits / total) * 100).toFixed(2);

    return {
      ...item,
      total,
      checked: true,
      osLegendLabel: `${item.id} <em>(${item.hits})</em>`,
      browserLegendLabel: `${item.browser} <em>(${item.hits})</em>`
    };
  });

const createChartArray = (data, key, legendLabels = {}) => sortByValue(appendChartLegendProps(data, legendLabels), key);

class Technology extends React.Component<Props> {
  getPercentage = (value, total) => {
    if (!value || !total) {
      return null;
    }

    return `${((value / total) * 100).toFixed(2)}%`;
  };

  renderContent = () => {
    const { getStats, intl, environment, statsMissing, statisticViewIndex } = this.props;
    const legendLabels = {
      hits: intl.formatMessage({ id: 'translate.page.stats.hits.label' })
    };

    const osStats = createChartArray(getStats('os'), 'hits', legendLabels);
    const browserStats = createChartArray(getStats('ua'), 'hits', legendLabels);

    if (statsMissing) {
      return (
        <SGTable
          data={[]}
          border="small"
          shadow={false}
          noDataMessage="translate.page.stats.no-stats.placeholder.message"
        />
      );
    }

    if (statisticViewIndex === StatsViewIndex.Table) {
      return (
        <Context.Consumer>
          {({ device }) => (
            <Grid gap="responsive">
              <div>
                {device.isPhone && (
                  <Title color="darker" density="cozy" level="3">
                    {intl.formatMessage({ id: 'translate.page.stats.os.label' })}
                  </Title>
                )}
                <Table
                  data={osStats}
                  columns={[
                    {
                      header: intl.formatMessage({ id: 'translate.page.stats.operating.system.label' }),
                      accessor: 'id'
                    },
                    {
                      header: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
                      accessor: 'hits'
                    },
                    {
                      header: intl.formatMessage({ id: 'translate.page.stats.percentage.label' }),
                      accessor: 'total',
                      render: (total, entity) => this.getPercentage(entity.hits, total)
                    }
                  ]}
                  {...TABLE_VIEW_PROPS}
                />
              </div>

              <div>
                {device.isPhone && (
                  <Title color="darker" density="cozy" level="3">
                    {intl.formatMessage({ id: 'translate.page.stats.browser.label' })}
                  </Title>
                )}
                <Table
                  data={browserStats}
                  columns={[
                    {
                      header: intl.formatMessage({ id: 'translate.page.stats.browser.label' }),
                      accessor: 'browser'
                    },
                    {
                      header: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
                      accessor: 'hits'
                    },
                    {
                      header: intl.formatMessage({ id: 'translate.page.stats.percentage.label' }),
                      accessor: 'total',
                      render: (total, entity) => this.getPercentage(entity.hits, total)
                    }
                  ]}
                  {...TABLE_VIEW_PROPS}
                />
              </div>
            </Grid>
          )}
        </Context.Consumer>
      );
    }

    return (
      <Grid gap="small" sm="2">
        <PieChartStatistics stats={osStats} type="os" environment={environment} />
        <PieChartStatistics stats={browserStats} type="browser" environment={environment} />
      </Grid>
    );
  };

  render() {
    return (
      <Grid gap="responsive">
        {this.props.renderControls()}
        {this.renderContent()}
      </Grid>
    );
  }
}

export default Technology;
