// https://gist.github.com/shiawuen/1534477

/**
 * Formalize file.slice
 */
function slice(file, start, end) {
  const slice = file.mozSlice
    ? file.mozSlice
    : file.webkitSlice
    ? file.webkitSlice
    : file.slice
    ? file.slice
    : () => console.warn('slice not implemented');

  return slice.bind(file)(start, end);
}

export const getChunks = (file: any) => {
  const sliceSize = 16 * 1024 * 1024;
  const allParts = file.size === 0 ? 1 : Math.ceil(file.size / sliceSize);
  const chunks = [];

  let start = 0;
  for (let i = 0; i < allParts; i = i + 1) {
    let end = start + sliceSize;

    if (file.size < end) {
      end = file.size;
    }

    const formData = new FormData();

    try {
      formData.append('file', slice(new File([file], name), start, end));
    } catch (e) {
      // Edge 16 ... but not IE 11 ...
      formData.append('file', slice(new Blob([file]), start, end));
    }

    chunks.push({
      start,
      end,
      formData
    });

    if (end < file.size) {
      start += sliceSize;
    }
  }

  return chunks;
};
