import * as React from 'react';
import { ButtonsGroup, Container, DatePicker, FormLabel, Grid, Section, Tab, Tabs } from '@siteground/styleguide';
import { getCountries } from '../../../core/actions/countries';
import { requestDomainStats } from '../../../core/actions/pages/resource-stats';
import { API_RESOURCE } from '../../../core/constants/api';

import customRequestTypes from '../../../core/constants/custom-request-types';
import { ToolId } from '../../../core/constants/route-info';
import { StatsType } from '../../../core/definitions/stats';
import { filterStagingDomains, getCountryNameByCode } from '../../../core/selectors';
import { areStatsMissing, getStats } from '../../../core/selectors/resource-stats';
import { getDateId } from '../../../core/utils/stats-format';
import DomainSelect from '../../components/domain-select';
import { getCalendarTranslations } from '../../components/form-date-picker/form-date-picker';
// COMPONENTS
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import PartialLoader from '../../components/partial-loader';
import { SecondLevelTitle } from '../../components/titles';

import { RootState } from '../../reducers';
import Audience from './audience';
import Behaviour from './behaviour';
import Sources from './sources';
import Technology from './technology';
import Traffic from './traffic';
import { formatDateToMonthYear } from './utils';

export enum StatsViewIndex {
  Chart = 0,
  Table = 1
}

type Props = {
  environment: {
    isPhone: boolean;
    isTablet: boolean;
  };
  listedDomains: any[];
  userLang: string;
  countries: RootState['countries'];
  requestDomainStats: typeof requestDomainStats;
  getCountries: typeof getCountries;
  getCountryNameByCode: (countryCode: string) => string;
  areStatsMissing: (dateId: string, domainName: string) => boolean;
  selectStats: (dateId: string, domainName: string, statsType: StatsType) => any;
  intl: Intl;
};

type State = {
  selectedDomain: {
    id: number;
    name: string;
  };
  activeTab: string;
  selectedDateInSeconds: number;
  statisticViewIndex: StatsViewIndex;
};

const TABS = [
  {
    id: 'traffic',
    label: 'translate.page.stats.traffic.summary.title'
  },
  {
    id: 'audience',
    label: 'translate.page.stats.traffic.audience.title'
  },
  {
    id: 'sources',
    label: 'translate.page.stats.traffic.sources.title'
  },
  {
    id: 'behaviour',
    label: 'translate.page.stats.behaviour.label'
  },
  {
    id: 'technology',
    label: 'translate.page.stats.technology.label'
  }
];

export type ControlOptions = {
  showViewChangeButtons?: boolean;
  renderAdditionalActions?: Function;
};

export const isMonthInTheFuture = ([firstDate, ...other]) => Boolean(firstDate.getTime() > new Date().getTime());

class ResourceStats extends React.Component<Props, State> {
  readonly state = {
    selectedDomain: null,
    activeTab: TABS[0].id,
    selectedDateInSeconds: +new Date() / 1000,
    statisticViewIndex: StatsViewIndex.Chart
  };

  onTabSelected = (id) => this.setState({ activeTab: id, statisticViewIndex: StatsViewIndex.Chart });

  renderPageContentItems = () => {
    const { activeTab, selectedDomain, statisticViewIndex } = this.state;
    const { requestDomainStats, getCountryNameByCode, selectStats, intl, environment, areStatsMissing } = this.props;
    const domainName = selectedDomain && selectedDomain.name;
    const currentDateId = getDateId(this.state.selectedDateInSeconds);

    const contentProps = {
      intl,
      environment,
      statsMissing: areStatsMissing(currentDateId, domainName),
      getStats: (statsType: StatsType) => selectStats(currentDateId, domainName, statsType),
      statisticViewIndex,
      renderControls: (options: ControlOptions = {}) => {
        const { showViewChangeButtons = true, renderAdditionalActions } = options;

        return (
          <Grid gap="responsive" sm="2" m="4">
            <DatePicker
              key="datepicker"
              value={this.state.selectedDateInSeconds}
              shouldDisableMonth={isMonthInTheFuture}
              label={intl.formatMessage({ id: 'translate.page.stats.select-period.label' })}
              size="small"
              disableDayPicker
              hideDayInHeader
              renderInputDateValue={(date) => formatDateToMonthYear(date, this.props.userLang)}
              onChange={(selectedDateInSeconds) => {
                this.setState({ selectedDateInSeconds });
                requestDomainStats(domainName, getDateId(selectedDateInSeconds));
              }}
              translation={getCalendarTranslations(intl)}
            />

            {renderAdditionalActions && renderAdditionalActions()}

            {showViewChangeButtons && (
              <div>
                <FormLabel>{intl.formatMessage({ id: 'translate.page.stats.change.view' })}</FormLabel>
                <ButtonsGroup
                  color="secondary"
                  size="small"
                  activeIndex={statisticViewIndex}
                  buttons={[
                    intl.formatMessage({ id: 'translate.page.stats.chart' }),
                    intl.formatMessage({ id: 'translate.page.stats.table' })
                  ]}
                  onChange={(index) => this.setState({ statisticViewIndex: index })}
                />
              </div>
            )}
          </Grid>
        );
      }
    };

    switch (activeTab) {
      case 'traffic':
        return <Traffic {...contentProps} />;
      case 'audience':
        return <Audience {...contentProps} getCountryNameByCode={getCountryNameByCode} />;
      case 'sources':
        return <Sources {...contentProps} />;
      case 'behaviour':
        return <Behaviour domain={domainName} {...contentProps} />;
      case 'technology':
        return <Technology {...contentProps} />;
      default:
        return null;
    }
  };

  render() {
    const { listedDomains, requestDomainStats, intl } = this.props;
    const { selectedDomain, activeTab } = this.state;

    return (
      <React.Fragment>
        <PageHeader
          id={ToolId.statistics}
          instructions={intl.formatMessage({ id: 'translate.page.stats.info-text' })}
        />
        <Section>
          <Grid>
            <DomainSelect
              options={listedDomains}
              selectedValue={selectedDomain && selectedDomain.id}
              optionValue="id"
              optionLabel="name"
              onChange={this.handleDomainSelect}
            />
            <div>
              <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.stats.statistics.title' })}</SecondLevelTitle>

              <Container padding="none">
                <Tabs border="light">
                  {TABS.map(({ id, label }) => (
                    <Tab
                      key={id}
                      active={activeTab === id}
                      data-e2e={`stats-tab-${id}`}
                      onClick={() => this.onTabSelected(id)}
                    >
                      {intl.formatMessage({ id: label })}
                    </Tab>
                  ))}
                </Tabs>
                <div style={{ width: '100%', position: 'relative' }}>
                  <PartialLoader
                    resources={[
                      {
                        requestTypeName: customRequestTypes.DOMAIN_STATS
                      }
                    ]}
                  >
                    <Grid padding="responsive">{this.renderPageContentItems()}</Grid>
                  </PartialLoader>
                </div>
              </Container>
            </div>
          </Grid>
        </Section>
      </React.Fragment>
    );
  }

  handleDomainSelect = (domainId: number) => {
    const { listedDomains, requestDomainStats } = this.props;
    const { name } = listedDomains.find(({ id }) => id === domainId);

    this.setState({
      selectedDomain: {
        id: domainId,
        name
      }
    });

    requestDomainStats(name, getDateId(this.state.selectedDateInSeconds));
  };

  componentDidMount() {
    this.props.getCountries();
  }
}

export default indexWithCRUD(
  ({ webstats, countries, environment, session, pageItems }: RootState) => ({
    countries,
    environment,
    listedDomains: filterStagingDomains({ pageItems }),
    userLang: session && session.user && session.user.language,
    getCountryNameByCode: (countryCode: string) => getCountryNameByCode({ countries }, countryCode),
    selectStats: getStats({ webstats }),
    areStatsMissing: areStatsMissing({ webstats })
  }),
  { requestDomainStats, getCountries }
)(ResourceStats, API_RESOURCE.DOMAIN);
