import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Button, Column, Dropdown, Flex, Grid, Input, Section, Icon } from '@siteground/styleguide';

import { loadTranslations } from '../../../core/actions/i18n';
import { requestNemoData } from '../../../core/actions/request-data';
import { updateUserLanguage } from '../../../core/actions/session';

import { closeSGDialog, openSGDialog } from '../../../core/actions/sg-dialog';
import { REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import CreateBox from '../../components/create-box';
import { SGDialogForm } from '../../components/sg-dialog';
import FirstLevelTitle from '../../components/titles/first-level-title';
import SecondLevelTitle from '../../components/titles/second-level-title';
import { getUserLanguageFilename, LANGUAGES } from '../resseller-login/utils';
import ChangeProfilePassword from './dialogs/change-profile-password';

type Props = {
  session: {
    user: {
      avatar: string;
      name: string;
      email: string;
      language: string;
    };
  };

  openSGDialog: typeof openSGDialog;
  closeSGDialog: typeof closeSGDialog;
  requestNemoData: typeof requestNemoData;
  loadTranslations: typeof loadTranslations;
  updateUserLanguage: typeof updateUserLanguage;
};

const Profile: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { session, openSGDialog, closeSGDialog, requestNemoData, loadTranslations, updateUserLanguage } = props;

  const languageOptions = React.useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: 'translate.english' }),
        value: getUserLanguageFilename(LANGUAGES.ENGLISH)
      },
      {
        label: intl.formatMessage({ id: 'translate.spanish' }),
        value: getUserLanguageFilename(LANGUAGES.SPANISH)
      },
      {
        label: intl.formatMessage({ id: 'translate.italian' }),
        value: getUserLanguageFilename(LANGUAGES.ITALIAN)
      },
      {
        label: intl.formatMessage({ id: 'translate.german' }),
        value: getUserLanguageFilename(LANGUAGES.GERMAN)
      }
    ];
  }, [intl]);

  return (
    <Section>
      <FirstLevelTitle>{intl.formatMessage({ id: 'translate.page.profile.title' })}</FirstLevelTitle>

      <Grid gap="responsive">
        <CreateBox
          title={intl.formatMessage({ id: 'translate.page.profile.login.information.title' })}
          renderTutorials={() => (
            <Flex align="center" justify="center">
              <Icon name="product-avatar" size="120" color="lighter" />
            </Flex>
          )}
        >
          <Grid sm="12" padding="responsive" gap="responsive">
            <Column smSpan="6">
              <Input
                readOnly
                label={intl.formatMessage({ id: 'translate.generic.username' })}
                value={session.user.email}
              />
            </Column>
            <Column smSpan="6">
              <Input
                readOnly
                label={intl.formatMessage({ id: 'translate.generic.password' })}
                value="**********"
                suffix={
                  <Button
                    action="button"
                    color="dark"
                    size="small"
                    onClick={() => openSGDialog(REDUX_FORM.CHANGE_PROFILE_PASSWORD_DIALOG)}
                  >
                    {intl.formatMessage({ id: 'translate.generic.change' })}
                  </Button>
                }
              />
            </Column>
          </Grid>
        </CreateBox>

        <div>
          <SecondLevelTitle>
            {intl.formatMessage({ id: 'translate.page.profile.language.information.title' })}
          </SecondLevelTitle>

          <Dropdown
            size="large"
            optionLabel="label"
            optionValue="value"
            options={languageOptions}
            selectedValue={session.user.language}
            template={(option) =>
              option.value === session.user.language
                ? intl.formatMessage({ id: 'translate.page.profile.current.language' }, { lang: option.label })
                : option.label
            }
            onChange={(language) => {
              requestNemoData(
                {
                  endpoint: '/user/language/change',
                  method: 'POST',
                  body: { language },
                  requestTypeName: customRequestTypes.SET_USER_LANGUAGE,
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.profile.change.language.success.text',
                      intlValues: { language }
                    },
                    error: {
                      intlKey: 'translate.page.profile.change.language.error.text'
                    }
                  }
                },
                () => {
                  updateUserLanguage(language);
                  loadTranslations({ fileName: language });
                }
              );
            }}
          />
        </div>

        <SGDialogForm
          icon="lock"
          name={REDUX_FORM.CHANGE_PROFILE_PASSWORD_DIALOG}
          title={intl.formatMessage({ id: 'translate.dialog.title.change.password' }, { account: session.user.email })}
          resources={[{ requestTypeName: customRequestTypes.CHANGE_USER_PASSWORD }]}
        >
          <ChangeProfilePassword
            onSubmit={(data) => {
              requestNemoData(
                {
                  endpoint: '/user/password/change',
                  method: 'POST',
                  body: { ...data },
                  requestTypeName: customRequestTypes.CHANGE_USER_PASSWORD,
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.profile.change.password.success.text'
                    },
                    error: {
                      intlKey: 'translate.page.profile.change.password.error.text'
                    }
                  }
                },
                () => closeSGDialog(REDUX_FORM.CHANGE_PROFILE_PASSWORD_DIALOG)
              );
            }}
          />
        </SGDialogForm>
      </Grid>
    </Section>
  );
};

const mapStateToProps = (state) => ({
  session: state.session
});

const mapDispatchToProps = {
  openSGDialog,
  closeSGDialog,
  requestNemoData,
  loadTranslations,
  updateUserLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
