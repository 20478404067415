import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { hasUserSession } from '../../core/selectors';
import { isResellerLoginRoute } from '../../core/utils/routes';

type StateProps = {
  sessionReady: boolean;
  siteTokenFailed: boolean;
};

type DispatchProps = {};

type OwnProps = {
  children: any;
  showOnSiteTokenFailed?: boolean;
  routes?: any;
};

interface Props extends StateProps, DispatchProps, OwnProps {}

export const SessionReady = ({ children, sessionReady, showOnSiteTokenFailed, siteTokenFailed, routes }: Props) => {
  if (sessionReady && !isResellerLoginRoute(routes)) {
    return children;
  }

  if (showOnSiteTokenFailed && siteTokenFailed) {
    return children;
  }

  return null;
};

export default connect<StateProps, DispatchProps, OwnProps>((store) => ({
  sessionReady: hasUserSession(store),
  siteTokenFailed: store.session.siteTokenFailed
}))(withRouter(SessionReady));
