import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import { comparisionOptions, matchOptions, subjectOptions, userActionOptions } from '../fields-options';
import FilterFields from '../filter-fields';

type Props = {
  form: string;
  change: Function;
  formValues: any;
  isDomainLevelFilter: boolean;
  validationUtils: any;
  selectedFilterEntity: any;
  sPanelFormSubmit: (formName) => null;
};

const formName = REDUX_FORM.CREATE_EMAIL_FILTER_ITEM;

const Form = ({
  form,
  change,
  formValues,
  validationUtils,
  selectedFilterEntity,
  sPanelFormSubmit,
  isDomainLevelFilter
}: Props) => {
  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)} gridProps={{ xs: '12', sm: '12', lg: '12' }}>
      <FilterFields
        isDomainLevelFilter={isDomainLevelFilter}
        validationUtils={validationUtils}
        selectedFilterEntity={selectedFilterEntity}
        values={formValues(formName)}
        change={change}
      />

      <Column xsSpan="12">
        <SGSubmitButton formName={formName} />
      </Column>
    </SGForm>
  );
};

const EmailFilterForm = reduxForm({
  form: formName,
  initialValues: {
    match_cond: matchOptions[0].value,
    conditions: [
      {
        field: subjectOptions[0].value,
        expr: comparisionOptions[0].value
      }
    ],
    actions: [
      {
        action: userActionOptions[0].value
      }
    ],
    _metaFields: {
      ...API_RESOURCE.EMAIL_FILTER,
      formName
    }
  }
})(Form);

export default withSpanelForm(EmailFilterForm);
