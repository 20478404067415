import * as React from 'react';
import { Button, Container, Grid, Link, Section, Tab, Tabs } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../core/constants/api';
import { ToolId } from '../../../core/constants/route-info';
import { RootState } from '../../reducers';
import { filterStagingDomains } from '../../../core/selectors';
import DomainSelect from '../../components/domain-select';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SecondLevelTitle from '../../components/titles/second-level-title';
import VCS from '../../components/visibility-control-service';
import EmailToolNotAvailable from '../../components/email-tool-not-available';
import DKIM from './dkim';
import SPF from './spf';
import { isTemporaryDomain } from '../dns/utils';

type Props = {
  intl: Intl;
  listedDomains: any[];
};

type State = {
  activeTab: string;
  selectedDomainId: number;
};

const TAB = {
  SPF: 'SPF',
  DKIM: 'DKIM'
};

class EmailAuthentication extends React.Component<Props, State> {
  readonly state = {
    activeTab: TAB.SPF,
    selectedDomainId: null
  };

  getSelectedDomainInfo() {
    const { listedDomains } = this.props;
    const { selectedDomainId } = this.state;
    const selectedDomain = listedDomains.find((d) => d.id === selectedDomainId);

    return {
      ...selectedDomain,
      link:
        selectedDomain && (selectedDomain.cert_id ? `https://${selectedDomain.name}` : `http://${selectedDomain.name}`)
    };
  }

  renderTabs() {
    const { activeTab } = this.state;

    return (
      <Tabs border="light">
        <Tab active={activeTab === TAB.SPF} data-e2e="settings" onClick={() => this.setState({ activeTab: TAB.SPF })}>
          SPF
        </Tab>

        <Tab
          active={activeTab === TAB.DKIM}
          data-e2e="ip-access-control"
          onClick={() => this.setState({ activeTab: TAB.DKIM })}
        >
          DKIM
        </Tab>
      </Tabs>
    );
  }

  renderTabContent() {
    const { activeTab } = this.state;
    const selectedDomainInfo = this.getSelectedDomainInfo();

    switch (activeTab) {
      case TAB.SPF:
        return <SPF selectedDomain={selectedDomainInfo} />;
      case TAB.DKIM:
        return <DKIM selectedDomain={selectedDomainInfo} />;
      default:
        return null;
    }
  }

  render() {
    const { listedDomains, intl } = this.props;
    const { selectedDomainId } = this.state;
    const selectedDomain = listedDomains.find((d) => d.id === selectedDomainId);
    const isTempDomain = selectedDomain && isTemporaryDomain(selectedDomain.name);

    return (
      <div>
        <PageHeader id={ToolId.emailAuth} instructions={intl.formatMessage({ id: 'translate.page.email.auth.info' })} />

        <Section>
          <Grid>
            <DomainSelect
              selectedValue={this.state.selectedDomainId}
              options={listedDomains}
              domainResourceName={API_RESOURCE.DOMAIN_ALL.resourceName}
              optionValue="id"
              optionLabel="name"
              onChange={(id) => this.setState({ selectedDomainId: id })}
            />
            {selectedDomain && isTempDomain ? (
              <EmailToolNotAvailable />
            ) : (
              <VCS resourceName={API_RESOURCE.SPF.resourceName} hasMethod="GET">
                <div>
                  <SecondLevelTitle>
                    {intl.formatMessage({ id: 'translate.page.email.auth.list.title' })}
                  </SecondLevelTitle>

                  <Container padding="none">
                    {this.renderTabs()}
                    {this.renderTabContent()}
                  </Container>
                </div>
              </VCS>
            )}
          </Grid>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = ({ pageItems }: RootState) => ({
  listedDomains: filterStagingDomains({ pageItems }, API_RESOURCE.DOMAIN_ALL.resourceName)
});

export default indexWithCRUD(mapStateToProps)(EmailAuthentication, API_RESOURCE.DOMAIN_ALL);
