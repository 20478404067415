import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { LoginCard } from '@siteground/styleguide/lib/composite';
import { isPasswordValid, textToHTML } from '@siteground/styleguide/lib/utils';

import { requestNemoData } from '../../../core/actions/request-data';
import * as sessionActions from '../../../core/actions/session';
import { RESSELLER_LOGIN_ROUTE } from '../../../core/constants/route-info';
import customRequestTypes from '../../../core/constants/custom-request-types';

import PartialLoader from '../../components/partial-loader';
import LoginFooter from './login-footer';
import withResellerLoginPage from './with-reseller-login-page';
import CreatePasswordForm from './forms/create-password-form';
import { createNotification } from '../../../core/actions/notifications';

type PasswordUpdateType = 'change' | 'create';

type Props = {
  intl: Intl;
  passwordUpdateType: PasswordUpdateType;
  requestNemoData?: typeof requestNemoData;
  createNotification: typeof createNotification;
  params?: {
    identification_hash: string;
  };
  router?: {
    push: Function;
  };
};

type State = {
  password: string;
  passwordInputMessage: string;
  passwordInputState: string;
};

class PasswordUpdate extends React.Component<Props> {
  readonly state = {
    password: '',
    passwordInputMessage: '',
    passwordInputState: null,
    domainName: null
  };

  componentDidMount() {
    const { params, requestNemoData, router, passwordUpdateType } = this.props;
    const { identification_hash } = params;

    requestNemoData(
      {
        endpoint: '/site/passwordexists/verify',
        method: 'POST',
        body: {
          token: identification_hash
        },
        requestTypeName: customRequestTypes.VERIFY_PASSWORD_HASH
      },
      ({ exist, site_domain_name }) => {
        if (site_domain_name) {
          this.setState({ domainName: site_domain_name });
        }

        switch (true) {
          case passwordUpdateType === 'change':
            return;
          case exist:
            // redirect to normal login if user wants to create pass, but it exists
            router.push(`/${RESSELLER_LOGIN_ROUTE}`);
            return;
          default:
            return;
        }
        // in case of request failure
      },
      (error) => {
        const message = error.validation?.token
          ? error.validation.token.intl_id
          : 'translate.reseller-login.site-panel.failed-to-validate-hash';

        this.props.createNotification({
          type: 'generic',
          state: 'error',
          error: {
            intlKey: message
          }
        });
        router.push(`/${RESSELLER_LOGIN_ROUTE}`);
      }
    );
  }

  onChange = ({ password }) => {
    const validation = isPasswordValid(password);

    this.setState({
      password,
      passwordInputState: validation.isPasswordValid ? null : 'error'
    });
  };

  onSubmit = () => {
    const { requestNemoData, params, router } = this.props;
    const { password } = this.state;
    const validation = isPasswordValid(password);
    const { identification_hash } = params;

    if (validation.isPasswordValid) {
      this.setState({
        passwordInputMessage: '',
        passwordInputState: null
      });

      requestNemoData(
        {
          endpoint: '/users/password/update',
          method: 'POST',
          body: {
            token: identification_hash,
            password
          },
          requestTypeName: customRequestTypes.UPDATE_USER_PASSWORD,
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.reseller-login.site-panel.password-updated.text'
            },
            error: {
              intlKey: 'translate.reseller-login.site-panel.password-updated-fail.text'
            }
          }
        },
        () => router.push(`/${RESSELLER_LOGIN_ROUTE}`)
      );
    } else {
      this.setState({ passwordInputState: 'error' });
    }
  };

  render() {
    const { intl, passwordUpdateType } = this.props;
    const { password, passwordInputMessage, passwordInputState, domainName } = this.state;
    const intlValues = { domainName };

    return (
      <React.Fragment>
        <PartialLoader
          initialLoadingState
          hideContent
          resources={[{ requestTypeName: customRequestTypes.VERIFY_PASSWORD_HASH }]}
        >
          <LoginCard
            title={
              passwordUpdateType === 'change'
                ? intl.formatMessage({ id: 'translate.reseller-login.site-panel.change-password.title' })
                : intl.formatMessage({ id: 'translate.reseller-login.site-panel.create-password.title' })
            }
            data-e2e={passwordUpdateType === 'change' ? 'pass-recover' : 'create-pass'}
            afterCardContent={<LoginFooter intl={intl} />}
          >
            <PartialLoader resources={[{ requestTypeName: customRequestTypes.UPDATE_USER_PASSWORD }]}>
              <CreatePasswordForm
                text={textToHTML(
                  intl.formatMessage(
                    {
                      id: 'translate.reseller-login.site-panel.create-password.text'
                    },
                    intlValues
                  )
                )}
                password={password}
                passwordInputState={passwordInputState}
                passwordInputMessage={passwordInputMessage}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
              />
            </PartialLoader>
          </LoginCard>
        </PartialLoader>
      </React.Fragment>
    );
  }
}

export default connect<any, any, Props>(undefined, { requestNemoData, createNotification })(
  withResellerLoginPage(injectIntl(PasswordUpdate))
);
