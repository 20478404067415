import { MenuItemST } from '../definitions/menu-items';
import { Colors } from '@siteground/styleguide/lib/components/with-color/types';

type PageInfo = {
  icon: string;
  iconColor: Colors;
  title: string;
  multicolor: boolean;
};

type NavigationInfo = {
  title: string;
  icon?: string;
};

export enum ResellerLoginRoutes {
  createPassword = 'create-password',
  changePassword = 'change-password',
  login = 'login',
  recoverPassword = 'recover-password'
}

export const RESSELLER_LOGIN_ROUTE = 'rlogin';

export enum WmToolId {
  wmPages = 'wmPages',
  wmLogin = 'wm-login',
  wmLoginCredentials = 'wm-loginCredentials',
  wmEmailConfig = 'wm-email-config',
  wmEmailAutoresponder = 'wm-email-autoresponder',
  wmEmailFilters = 'wm-email-filters',
  wmEmailForward = 'wm-email-forward',
  webmail = 'webmail'
}

export enum ToolId {
  dashboard = 'dashboard',

  // Site
  sites = 'sites',
  filemanager = 'filemanager',
  ftp = 'ftp',
  mysql = 'mysql',
  mysqlDatabase = 'mysqlDatabase',
  mysqlUser = 'mysqlUser',
  mysqlAccess = 'mysqlAccess',
  mysqlPhpMyAdmin = 'mysqlPhpMyAdmin',
  pgsql = 'pgsql',
  pgsqlDatabase = 'pgsqlDatabase ',
  pgsqlUser = 'pgsqlUser ',
  pgsqlAccess = 'pgsqlAccess ',

  // Security
  security = 'security',
  backup = 'backup',
  backupCreate = 'backup-create',
  backupRestore = 'backup-restore',
  ssl = 'ssl',
  httpsEnforce = 'https-enforce',
  protected = 'protected',
  protectedURLs = 'protectedURLs',
  protectedUsers = 'protectedUsers',
  blockIPs = 'block-ips',
  sitescanner = 'sitescanner',

  // Speed
  speed = 'speed',
  cloudflare = 'cloudflare',
  cdn = 'cdn',
  cacher = 'cacher',
  cacherStatic = 'cacher-static',
  cacherDynamic = 'cacher-dynamic',
  cacherMemcached = 'cacher-memcached',

  // Wordpress
  wordpress = 'wordpress',
  wpManage = 'wp-manage',
  wpStaging = 'wp-staging',
  wpTransfer = 'wp-transfer',
  wpAutoupdate = 'wp-autoupdate',
  wpSearchAndReplace = 'wp-search-and-replace',

  // Domain
  domains = 'domains',
  parkedDomains = 'parked-domains',
  subdomain = 'subdomain',
  redirect = 'redirect',
  dns = 'dns',

  // Email
  mail = 'mail',
  email = 'email',
  emailForward = 'email-forward',
  emailAutoresponder = 'email-autoresponder',
  emailFilters = 'email-filters',
  emailAuth = 'email-authentication',
  googleWorkspace = 'googleWorkspace',

  // Statistics
  stats = 'stats',
  spamExperts = 'spam-experts',
  statistics = 'statistics',

  // Dev
  appManager = 'app-manager',
  devs = 'devs',
  errorLog = 'error-log',
  accessLog = 'access-log',
  git = 'git',
  cron = 'cron',
  cronJob = 'cron-job',
  cronNotification = 'cron-notification',

  phpSettings = 'php-settings',
  ssh = 'ssh',

  // Other
  profile = 'profile',
  appearance = 'appearance'
}

type NavigationMapType = {
  [otherProps in ToolId & WmToolId]: NavigationInfo;
};

type PageMapType = {
  [otherProps in ToolId & WmToolId]: PageInfo;
};

const NAVIGATION_INFO: Partial<NavigationMapType> = {
  [ToolId.dashboard]: {
    title: 'translate.navigation.group.dashboard.label'
  },

  // Site
  [ToolId.sites]: {
    title: 'translate.navigation.group.site.label'
  },
  [ToolId.filemanager]: {
    title: 'translate.navigation.page.filemanager.label'
  },
  [ToolId.ftp]: {
    title: 'translate.navigation.page.ftp.label'
  },
  [ToolId.mysql]: {
    title: 'translate.navigation.page.mysql.label'
  },
  [ToolId.pgsql]: {
    title: 'translate.navigation.page.pgsql.label'
  },

  // Security
  [ToolId.security]: {
    title: 'translate.navigation.group.security.label'
  },
  [ToolId.backup]: {
    title: 'translate.navigation.page.backup_restore.label'
  },
  [ToolId.ssl]: {
    title: 'translate.navigation.page.ssl.label'
  },
  [ToolId.httpsEnforce]: {
    title: 'translate.navigation.page.https-enforce.label'
  },
  [ToolId.protected]: {
    title: 'translate.navigation.page.protected-urls.label'
  },
  [ToolId.blockIPs]: {
    title: 'translate.navigation.page.block-ip.label'
  },
  [ToolId.sitescanner]: {
    title: 'translate.navigation.page.sg-sitescanner.label'
  },

  // Speed
  [ToolId.speed]: {
    title: 'translate.navigation.group.speed.label'
  },
  [ToolId.cloudflare]: {
    title: 'translate.navigation.page.cloudflare.label'
  },
  [ToolId.cdn]: {
    title: 'translate.navigation.page.cdn.label'
  },
  [ToolId.cacher]: {
    title: 'translate.navigation.page.caching.label'
  },

  // WordPress
  [ToolId.wordpress]: {
    title: 'translate.navigation.group.wordpress.label'
  },
  [ToolId.wpManage]: {
    title: 'translate.navigation.page.wordrpess-manager.label'
  },
  [ToolId.wpStaging]: {
    title: 'translate.navigation.page.wordpress-staging.label'
  },
  [ToolId.wpTransfer]: {
    title: 'translate.navigation.page.wordpress-migrator.label'
  },
  [ToolId.wpAutoupdate]: {
    title: 'translate.navigation.page.wordpress-autoupdate.label'
  },
  [ToolId.wpSearchAndReplace]: {
    title: 'translate.navigation.page.wordpress-replace.label'
  },

  // Domain
  [ToolId.domains]: {
    title: 'translate.navigation.group.doamin.label'
  },
  [ToolId.parkedDomains]: {
    title: 'translate.navigation.page.parked-domains.label'
  },
  [ToolId.subdomain]: {
    title: 'translate.navigation.page.subdomains.label'
  },
  [ToolId.redirect]: {
    title: 'translate.navigation.page.redirects.label'
  },
  [ToolId.dns]: {
    title: 'translate.navigation.page.dns-zone-editor.label'
  },

  // Email
  [ToolId.mail]: {
    title: 'translate.navigation.group.email.label'
  },
  [ToolId.email]: {
    title: 'translate.navigation.page.email-accounts.label'
  },
  [ToolId.emailForward]: {
    title: 'translate.navigation.page.email-forwarders.label'
  },
  [ToolId.emailAutoresponder]: {
    title: 'translate.navigation.page.autoresponders.label'
  },
  [ToolId.emailFilters]: {
    title: 'translate.navigation.page.email-filters.label'
  },
  [ToolId.emailAuth]: {
    title: 'translate.navigation.page.authentication.label'
  },
  [ToolId.spamExperts]: {
    title: 'translate.navigation.page.spam.experts.label'
  },
  [ToolId.googleWorkspace]: {
    title: 'translate.navigation.page.google-workspace.label'
  },

  // Statistics
  [ToolId.stats]: {
    title: 'translate.navigation.group.stats.label'
  },
  [ToolId.statistics]: {
    title: 'translate.navigation.page.traffic-statistics.label'
  },

  // Dev
  [ToolId.devs]: {
    title: 'translate.navigation.group.devs.label'
  },
  [ToolId.errorLog]: {
    title: 'translate.navigation.page.error-logs.label'
  },
  [ToolId.accessLog]: {
    title: 'translate.navigation.page.access-logs.label'
  },
  [ToolId.git]: {
    title: 'translate.navigation.page.git.label'
  },
  [ToolId.cron]: {
    title: 'translate.navigation.page.cron-jobs.label'
  },
  [ToolId.phpSettings]: {
    title: 'translate.navigation.page.php-settings.label'
  },
  [ToolId.ssh]: {
    title: 'translate.navigation.page.ssh-key-manager.label'
  },
  [ToolId.appManager]: {
    title: 'translate.navigation.page.app.installer.label'
  },

  // Webmail pages
  [WmToolId.wmPages]: {
    title: null,
    icon: null
  },
  [WmToolId.wmLogin]: {
    title: null,
    icon: null
  },
  [WmToolId.webmail]: {
    title: null,
    icon: null
  },
  [WmToolId.wmLoginCredentials]: {
    title: 'translate.wm.navigation.login.credentials.title',
    icon: 'profile'
  },
  [WmToolId.wmEmailConfig]: {
    title: 'translate.wm.navigation.email-config.title',
    icon: 'mail-settings'
  },
  [WmToolId.wmEmailAutoresponder]: {
    title: 'translate.wm.navigation.email-autoresponder.title',
    icon: 'paper-plane'
  },
  [WmToolId.wmEmailForward]: {
    title: 'translate.wm.navigation.email-forwarder.title',
    icon: 'email-forward'
  },
  [WmToolId.wmEmailFilters]: {
    title: 'translate.wm.navigation.email-filters.title',
    icon: 'filter'
  }
};

const PAGE_INFO: Partial<PageMapType> = {
  // Site
  [ToolId.filemanager]: {
    icon: 'product-file-manager',
    iconColor: 'mint',
    title: 'translate.page.translate.file.manager.title'
  },
  [ToolId.ftp]: {
    icon: 'product-ftp',
    iconColor: 'mint',
    title: 'translate.page.ftp.title'
  },
  [ToolId.appManager]: {
    icon: 'product-autoinstaller',
    iconColor: 'mint',
    title: 'translate.page.app-installer.title'
  },
  [ToolId.mysql]: {
    iconColor: 'mint',
    icon: 'product-mysql-database',
    title: 'translate.page.mysql.title'
  },
  [ToolId.pgsql]: {
    icon: 'product-pgsql-database',
    iconColor: 'mint',
    title: 'translate.page.pgsql.title'
  },

  // Security
  [ToolId.backup]: {
    icon: 'product-backup-manager',
    iconColor: 'royal',
    title: 'translate.page.backup.restore.title'
  },
  [ToolId.ssl]: {
    icon: 'product-ssl',
    iconColor: 'royal',
    title: 'translate.page.ssl.title'
  },
  [ToolId.httpsEnforce]: {
    icon: 'product-https',
    iconColor: 'royal',
    title: 'translate.page.https-enforce.title'
  },
  [ToolId.protected]: {
    icon: 'product-password-protected-urls',
    iconColor: 'royal',
    title: 'translate.page.protected.title'
  },
  [ToolId.blockIPs]: {
    icon: 'product-ip-deny-manager',
    iconColor: 'royal',
    title: 'translate.page.locationIpBlock.title'
  },
  [ToolId.sitescanner]: {
    icon: 'product-sitescanner',
    iconColor: 'royal',
    title: 'translate.page.sg-sitescanner.tool.title'
  },

  // Speed
  [ToolId.cloudflare]: {
    icon: 'product-cloudflare',
    iconColor: 'salmon',
    title: 'translate.page.cloudflare.title'
  },
  [ToolId.cdn]: {
    icon: 'product-cloudflare',
    iconColor: 'salmon',
    title: 'translate.navigation.page.content.delivery.network'
  },
  [ToolId.cacher]: {
    icon: 'product-speed-caching',
    iconColor: 'salmon',
    title: 'translate.page.superCacher.title'
  },

  // Wordpress
  [ToolId.wpManage]: {
    icon: 'product-wordpress-manage',
    iconColor: 'ocean',
    title: 'translate.page.wp.manage.title'
  },
  [ToolId.wpStaging]: {
    icon: 'product-wordpress-staging',
    iconColor: 'ocean',
    title: 'translate.page.staging.title'
  },
  [ToolId.wpTransfer]: {
    icon: 'product-migrate-site',
    iconColor: 'ocean',
    title: 'translate.page.wp.transfer.title'
  },
  [ToolId.wpAutoupdate]: {
    icon: 'product-wordpress-autoupdate',
    iconColor: 'ocean',
    title: 'translate.page.wp.autoupdate.title'
  },
  [ToolId.wpSearchAndReplace]: {
    icon: 'product-window-search',
    iconColor: 'ocean',
    title: 'translate.page.wp.replace.title'
  },

  // Domain
  [ToolId.parkedDomains]: {
    icon: 'product-domain-parked',
    iconColor: 'grassy',
    title: 'translate.page.domainAlias.title'
  },
  [ToolId.subdomain]: {
    icon: 'product-subdomain',
    iconColor: 'grassy',
    title: 'translate.page.subdomain.title'
  },
  [ToolId.redirect]: {
    icon: 'product-domain-redirect',
    iconColor: 'grassy',
    title: 'translate.page.redirect.title'
  },
  [ToolId.dns]: {
    icon: 'product-dns-zone',
    iconColor: 'grassy',
    title: 'translate.page.dns.title'
  },

  // Email
  [ToolId.email]: {
    icon: 'product-email-account',
    iconColor: 'sunset',
    title: 'translate.page.email.title'
  },
  [ToolId.emailForward]: {
    icon: 'product-email-forwarder',
    iconColor: 'sunset',
    title: 'translate.page.emailForward.title'
  },
  [ToolId.emailAutoresponder]: {
    icon: 'product-email-autoresponder',
    iconColor: 'sunset',
    title: 'translate.page.emailAutoresponder.title'
  },
  [ToolId.emailFilters]: {
    icon: 'product-email-filter',
    iconColor: 'sunset',
    title: 'translate.page.emailFilter.title'
  },
  [ToolId.emailAuth]: {
    icon: 'product-email-authentication',
    iconColor: 'sunset',
    title: 'translate.page.email.auth.title'
  },
  [ToolId.googleWorkspace]: {
    icon: 'logo-google',
    multicolor: true,
    title: 'translate.page.google.workspace.title'
  },

  // Statistics
  [ToolId.spamExperts]: {
    icon: 'product-spam-expert',
    iconColor: 'sunset',
    title: 'translate.page.spam-experts.title'
  },
  [ToolId.statistics]: {
    icon: 'product-traffic-statistics',
    iconColor: 'mango',
    title: 'translate.page.stats.title'
  },

  // Dev
  [ToolId.errorLog]: {
    icon: 'product-error-logs',
    iconColor: 'mango',
    title: 'translate.page.error-logs.title'
  },
  [ToolId.accessLog]: {
    icon: 'product-access-logs',
    iconColor: 'mango',
    title: 'translate.page.access-logs.title'
  },
  [ToolId.git]: {
    icon: 'product-git',
    iconColor: 'bronze',
    title: 'translate.page.git.title'
  },
  [ToolId.cron]: {
    icon: 'product-cron-job',
    iconColor: 'bronze',
    title: 'translate.page.cron.title'
  },
  [ToolId.phpSettings]: {
    icon: 'product-php-manager',
    iconColor: 'bronze',
    title: 'translate.page.phpSettings.title'
  },
  [ToolId.ssh]: {
    icon: 'product-ssh-key-manager',
    iconColor: 'royal',
    title: 'translate.page.ssh.title'
  },
  // Webmail pages
  [WmToolId.wmPages]: {
    icon: null,
    iconColor: null,
    title: null
  },
  [WmToolId.webmail]: {
    icon: null,
    iconColor: null,
    title: null
  },
  [WmToolId.wmLoginCredentials]: {
    icon: null,
    iconColor: null,
    title: 'translate.wm.loginCredentials.title'
  },
  [WmToolId.wmEmailConfig]: {
    icon: 'product-gears',
    iconColor: 'sunset',
    title: 'translate.wm.page.email-config.title'
  },
  [WmToolId.wmEmailAutoresponder]: {
    icon: 'product-email-autoresponder',
    iconColor: 'sunset',
    title: 'translate.wm.page.email-autoresponder.title'
  },
  [WmToolId.wmEmailForward]: {
    icon: 'product-email-forwarder',
    iconColor: 'sunset',
    title: 'translate.wm.page.email-forwarder.title'
  },
  [WmToolId.wmEmailFilters]: {
    icon: 'product-email-filter',
    iconColor: 'sunset',
    title: 'translate.wm.page.email-filters.title'
  }
};

export const MENU_ITEMS_LABEL = {
  [ToolId.googleWorkspace]: {
    text: 'translate.navigation.page.new.label',
    color: 'ocean',
    startDate: 1642506191,
    endDate: 1648771199
  },
  [ToolId.cdn]: {
    text: 'translate.navigation.page.beta.label',
    color: 'ocean',
    startDate: 1642506191,
    endDate: 1653322820
  }
};

export const getPageInfo = (routeId: MenuItemST['toolId']): PageInfo => {
  const initial = {
    icon: null,
    iconColor: null,
    title: null,
    multicolor: null
  };

  if (!routeId) {
    return initial;
  }

  return PAGE_INFO[routeId] ? PAGE_INFO[routeId] : initial;
};

export const getNavigationInfo = (routeId: MenuItemST['toolId']): NavigationInfo => {
  const initial = {
    title: null
  };

  if (!routeId) {
    return initial;
  }

  return NAVIGATION_INFO[routeId] ? NAVIGATION_INFO[routeId] : initial;
};
