import * as React from 'react';
import { Field } from 'redux-form';
import { Column, Dropdown, Input, InputState } from '@siteground/styleguide';
import { formatMessage } from '../../../../core/translate';

import FormInput from '../../../components/form-input';
import {
  intervalInputOnChange,
  looksLikeInterval,
  originalColor,
  parseCommandWithInterval,
  translations
} from '../cron-parser';
import { fullfillCronFields } from '../utils';

const MANUAL_INTERVAL_SELECTION_ID = 0;

type OptionState = {
  color: string;
};

type Props = {
  validationUtils: ValidationUtils;
  intl: Intl;
  formName: string;
  submitFailed: boolean;
  pristine: boolean;
  change: (field: string, value: string) => void;
  formErrors: (formName: string) => object;
  initialInterval?: string;
  fieldsExpanded?: boolean;
};

type State = {
  selectedIntervalId: number;
  intervalInputValue: string;
  commandWithIntervalWasDetected: boolean;
  selectDisabled: boolean;
  inputDisabled: boolean;
  inputState: InputState | string;
  validationMessage: string;
  intervalTouched: boolean;
  minStyle: OptionState;
  hourStyle: OptionState;
  dayStyle: OptionState;
  monStyle: OptionState;
  dowStyle: OptionState;
};

const initalCronOptionsState = {
  minStyle: { color: originalColor },
  hourStyle: { color: originalColor },
  dayStyle: { color: originalColor },
  monStyle: { color: originalColor },
  dowStyle: { color: originalColor }
};

const INTERVAL_FIELDS = ['min', 'hour', 'day', 'mon', 'dow'];

const isValidCMD: ValidationUtil = function (value) {
  const invalidInterval = Boolean(
    value && looksLikeInterval(value.split(' ')) && intervalInputOnChange(value).inputState === 'error'
  );

  if (invalidInterval) {
    return 'Invalid interval';
  }
};

const validateInterval: ValidationUtil = function (value, { min, hour, day, mon, dow }) {
  const intervalState = intervalInputOnChange(`${min} ${hour} ${day} ${mon} ${dow}`).inputState;

  if (intervalState === 'error') {
    return 'Invalid interval';
  }
};

const initialState = {
  selectedIntervalId: MANUAL_INTERVAL_SELECTION_ID,
  intervalInputValue: '',
  commandWithIntervalWasDetected: false,
  selectDisabled: false,
  inputDisabled: false,
  inputState: 'none',
  validationMessage: '',
  intervalTouched: false,
  ...initalCronOptionsState
};

export const getInitialState = (props: Props) => ({
  ...initialState,
  ...intervalInputOnChange(props.initialInterval)
});

export class CronFields extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    initialInterval: ''
  };

  readonly state: State = getInitialState(this.props);

  fullfillCronFields = (fieldsArray?: string[]) => {
    const { change } = this.props;

    fullfillCronFields(fieldsArray, (field, value = '') => change(field, value));
  };

  onCmdChange = (e, value) => {
    const { intl } = this.props;
    const parser = parseCommandWithInterval(value);

    switch (parser.intervalStatus) {
      case 'valid':
      case 'invalid':
        this.fullfillCronFields(parser.intervalArray);

        const inputStateChanges: any = intervalInputOnChange(parser.intervalString, this.fullfillCronFields, intl);

        this.setState({
          selectedIntervalId: MANUAL_INTERVAL_SELECTION_ID,
          commandWithIntervalWasDetected: true,
          intervalInputValue: parser.intervalString,
          inputDisabled: true,
          selectDisabled: true,
          ...inputStateChanges
        });

        break;
      case 'none':
        if (this.state.commandWithIntervalWasDetected) {
          this.fullfillCronFields();

          this.setState({
            inputState: undefined,
            commandWithIntervalWasDetected: false,
            intervalInputValue: '',
            inputDisabled: false,
            selectDisabled: false,
            ...initalCronOptionsState
          });
        }
        break;
      default:
        return;
    }
  };

  onSelectInterval = (id) => {
    const { intl } = this.props;
    const intervalValueArr =
      id !== MANUAL_INTERVAL_SELECTION_ID
        ? translations(intl).intervals.find((interval) => interval.id === id).value
        : [];

    this.fullfillCronFields(intervalValueArr);

    this.setState({
      selectedIntervalId: id,
      intervalInputValue: intervalValueArr.join(' '),
      intervalTouched: false,
      validationMessage: '',
      inputState: 'none',
      ...initalCronOptionsState
    });
  };

  onIntervalChange = (ev?) => {
    const { intl } = this.props;
    const nextState: any = intervalInputOnChange(
      ev && ev.target.value,
      // prefills the hidden inputs (min, hour, day, mon, dow)
      this.fullfillCronFields,
      intl
    );

    this.setState({ ...nextState, intervalTouched: true });
  };

  getIntervalErrorState = () => {
    const { formErrors, submitFailed, formName } = this.props;
    const { intervalTouched } = this.state;

    if (intervalTouched || submitFailed) {
      const errors = formErrors(formName);

      for (const index in INTERVAL_FIELDS) {
        if (errors[INTERVAL_FIELDS[index]]) {
          return 'error';
        }
      }
    }
  };

  getIntervalErrorMessage = () => {
    const { formErrors, formName } = this.props;
    const errors = formErrors(formName);

    for (const index in INTERVAL_FIELDS) {
      if (errors[INTERVAL_FIELDS[index]]) {
        return errors[INTERVAL_FIELDS[index]];
      }
    }
  };

  render() {
    const { validationUtils, intl, formName, fieldsExpanded = false } = this.props;
    const { selectedIntervalId, intervalInputValue, inputDisabled } = this.state;
    const { required, validationWithMetaApi } = validationUtils;

    const intervalInputState: any = this.state.inputState || this.getIntervalErrorState();
    const intervalValidationMessage = this.state.validationMessage || this.getIntervalErrorMessage();

    // showing the message, only if the state is set and has error
    const intervalInputMessage = Boolean(intervalInputState && intervalValidationMessage)
      ? formatMessage({ id: intervalValidationMessage })
      : null;

    const userCanSetInterval = Boolean(Number(selectedIntervalId) === MANUAL_INTERVAL_SELECTION_ID);

    return (
      <React.Fragment>
        <Column smSpan="12">
          <Field
            name="cmd"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.cron.cmd' })}
            placeholder={intl.formatMessage({ id: 'translate.page.cron.cmd.label' })}
            onChange={this.onCmdChange}
            component={FormInput}
            validate={[required, isValidCMD, validationWithMetaApi]}
          />
        </Column>
        <Column smSpan={fieldsExpanded ? '12' : '6'}>
          <Dropdown
            disabled={this.state.selectDisabled}
            options={translations(intl).intervals}
            optionValue="id"
            optionLabel="name"
            selectedValue={this.state.selectedIntervalId}
            name="cron-interval-dropdown"
            label={intl.formatMessage({ id: 'translate.page.cron.interval' })}
            placeholder={intl.formatMessage({ id: 'translate.page.cron.interval.select_interval' })}
            onChange={this.onSelectInterval}
            data-e2e="dropdown-cron-intervals"
          />
        </Column>
        <Column smSpan={fieldsExpanded ? '12' : '6'}>
          <Input
            name="cron-interval-input"
            data-e2e="cron-interval-input"
            type="text"
            label={
              <React.Fragment>
                <span style={this.state.minStyle} data-e2e="cron-interval-label-min">
                  {intl.formatMessage({ id: 'translate.page.cron.interval.value.min' })}&nbsp;
                </span>
                <span style={this.state.hourStyle} data-e2e="cron-interval-label-hour">
                  {intl.formatMessage({ id: 'translate.page.cron.interval.value.hour' })}&nbsp;
                </span>
                <span style={this.state.dayStyle} data-e2e="cron-interval-label-day">
                  {intl.formatMessage({ id: 'translate.page.cron.interval.value.day' })}&nbsp;
                </span>
                <span style={this.state.monStyle} data-e2e="cron-interval-label-mon">
                  {intl.formatMessage({ id: 'translate.page.cron.interval.value.mon' })}&nbsp;
                </span>
                <span style={this.state.dowStyle} data-e2e="cron-interval-label-dow">
                  {intl.formatMessage({ id: 'translate.page.cron.interval.value.dow' })}&nbsp;
                </span>
              </React.Fragment>
            }
            placeholder={intl.formatMessage({ id: 'translate.page.cron.interval.label' })}
            disabled={inputDisabled}
            style={{ display: userCanSetInterval ? 'block' : 'none' }}
            value={intervalInputValue}
            state={intervalInputState}
            validationMessage={intervalInputMessage}
            onChange={this.onIntervalChange}
          />
        </Column>
        {INTERVAL_FIELDS.map((field: string, index) => (
          <Field
            style={{ display: 'none' }}
            key={`interval-field-${index}`}
            name={field}
            component={FormInput}
            validate={[required, validateInterval]}
          />
        ))}
      </React.Fragment>
    );
  }

  componentDidUpdate(prevProps: Props) {
    const formReset = Boolean(!prevProps.pristine && this.props.pristine);

    /* Needed, because there are form values depending on internalState,
      so we cannot rely only on the redux-form reset */
    if (formReset) {
      this.setState(getInitialState(this.props));
    }
  }
}

export default CronFields;
