import * as React from 'react';
import { ArchiveDialog } from './archive';
import { ChmodPermissions } from './chmod-permissions';
import { CloseEditorTabs } from './close-editor-tabs';
import { ConfirmDialog } from './confirm';
import { CopyPasteFilesConfirmationDialog } from './copy-paste-files-confirmation';
import { CreateDirDialog } from './create-dir';
import { CreateFileDialog } from './create-file';
import { DeleteDialog } from './delete';
import { FailedUploadFiles } from './failed-upload-files';
import { MessageDialog } from './message';
import { MoveDialog } from './move';
import { MoveFilesConfirmationDialog } from './move-files-confirmation';
import { RenameDialog } from './rename';
import { UnknownPathDialog } from './unknown-path';
import { UploadFilesConfirmationDialog } from './upload-files-confirmation';

const FMDialogs = () => {
  return (
    <React.Fragment>
      <CreateFileDialog />
      <CreateDirDialog />
      <ArchiveDialog />
      <ChmodPermissions />

      <CloseEditorTabs />

      <RenameDialog />
      <MoveDialog />
      <DeleteDialog />

      <ConfirmDialog />
      <MessageDialog />
      <UnknownPathDialog />
      <UploadFilesConfirmationDialog />
      <MoveFilesConfirmationDialog />
      <CopyPasteFilesConfirmationDialog />
      <FailedUploadFiles />
    </React.Fragment>
  );
};

export default FMDialogs;
