import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader, Tree } from '@siteground/styleguide';
import scrollIntoView from '@siteground/styleguide/lib/utils/scroll-to-element';
import { RootState } from '../../reducers';
import { LoaderContext } from '../../contexts';
import * as fileManagerActions from '../core/actions/file-manager';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANAGER_CONTEXT_MENU_TYPE } from '../core/constants/common';

import {
  getEntityInfoNumber,
  getEntityIsExpanded,
  getEntityName,
  getEntityPath,
  getEntityType,
  getSymlinkEntityDestination
} from '../core/utils';
import { CodeEditor, CommonFMProps, FileManagerProps, FMEntity } from '../types';
import { convertPathToFolderArray } from '../core/utils/convert-path-to-folder-array';

import DragSideNavigationItem from './draggable-entity';
import './side-navigation.scss';

type State = {
  expanded: any[];
};

interface Props extends CommonFMProps, WrappedComponentProps {
  time: number;
  actions: {
    fetchDir: typeof fileManagerActions.fetchDir;
    toggleNavigationList: typeof fileManagerActions.toggleNavigationList;
    selectContentRows: typeof fileManagerActions.selectContentRows;
    onEntityClick: typeof fileManagerActions.onEntityClick;
    navigateToFMPath: typeof fileManagerActions.navigateToFMPath;
    toggleCodeEditor: typeof fileManagerActions.toggleCodeEditor;
    moveByDragAndDrop: typeof fileManagerActions.moveByDragAndDrop;
    onContextNavigationEntityClick: typeof fileManagerActions.onContextNavigationEntityClick;
  };
  entities: FileManagerProps['entities'];
  contextNavigationEntity: FMEntity;
  codeEditor: CodeEditor;
  domains: any;
}

class SideNavigation extends React.Component<Props, State> {
  readonly state: State = {
    expanded: []
  };

  componentDidUpdate(prevProps, prevState) {
    this.expandNavigationOnUpdate(prevProps);
  }

  expandNavigationOnUpdate = (prevProps) => {
    const selectedNavigationEntity = this.props.selectedNavigationEntity;

    if (!prevProps.selectedNavigationEntity || !selectedNavigationEntity) {
      return;
    }

    const selectedNavigationEntityPath = getEntityPath(selectedNavigationEntity);

    if (!this.state.expanded.includes(selectedNavigationEntityPath)) {
      const folderToExpand = convertPathToFolderArray(selectedNavigationEntityPath);
      this.setState({ expanded: [...Array.from(new Set(this.state.expanded.concat(folderToExpand)))] }, () =>
        this.scrollToElement(selectedNavigationEntityPath)
      );
    }
  };

  scrollToElement = (path) => {
    const selectedNavigationElement = document.querySelector(`[data-component="${path}"]`);

    if (selectedNavigationElement) {
      try {
        // Old Safari
        scrollIntoView(selectedNavigationElement, { behavior: 'smooth', block: 'center', inline: 'nearest' });
      } catch (e) {
        console.warn('unable to auto scroll');
      }
    }
  };

  fetchDir({ id }) {
    this.props.actions.fetchDir({
      urlParams: {
        id
      }
    });
  }

  mapDataToTree = (entity, data) => {
    const entityPath = entity._meta.path;
    const items = data[entityPath];

    return {
      entity,
      value: entityPath,
      label: this.renderTreeNode,
      forceShowToggleIcon: getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY,
      showCheckbox: false,
      htmlAttributes: {
        'aria-haspopup': true,
        'data-component': entityPath
      },
      children: items && items.map((ent) => this.mapDataToTree(ent, data))
    };
  };

  toggleListItem = ({ entity }, event) => {
    event.stopPropagation();
    const entityPath = getEntityPath(entity);

    this.props.actions.toggleNavigationList({ entity });

    if (getEntityIsExpanded(entity)) {
      this.fetchDir({ id: entityPath });
    }
  };

  handleItemClick = ({ entity }) => {
    const entityPath = getEntityPath(entity);
    const entityType = getEntityType(entity);

    this.props.actions.selectContentRows([entity]);
    this.props.actions.onEntityClick({ entity });

    const NON_FOLDER_ELEMENTS = [FILE_MANAGER_API_RESPONSE_DIR.FILE, FILE_MANAGER_API_RESPONSE_DIR.SYMLINK];

    if (NON_FOLDER_ELEMENTS.includes(entityType)) {
      return;
    }

    this.fetchDir({ id: entityPath });

    this.setState({ expanded: this.state.expanded.concat(getEntityPath(entity)) });
  };

  handleEntityDoubleClick({ entity }, event) {
    const { actions } = this.props;
    const { navigateToFMPath } = actions;
    const entityType = getEntityType(entity);

    if (entityType === FILE_MANAGER_API_RESPONSE_DIR.SYMLINK) {
      return navigateToFMPath(getSymlinkEntityDestination(entity));
    }

    if (this.props.codeEditorIsVisible) {
      this.props.actions.toggleCodeEditor();
    }

    this.props.actions.selectContentRows([entity]);
    this.props.actions.onEntityClick({ entity });

    if (entityType === FILE_MANAGER_API_RESPONSE_DIR.FILE) {
      this.props.codeEditor.openFile(entity);
    }
  }

  onContextMenu = (event, { entity }) => {
    const domainName = getEntityName(entity);
    const entityPath = getEntityPath(entity);

    event.stopPropagation();
    event.preventDefault();

    if (getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.SYMLINK) {
      // TODO: handle with permissions in refactor branch
      return;
    }

    const contextType =
      entityPath === domainName ? FILE_MANAGER_CONTEXT_MENU_TYPE.BASE : FILE_MANAGER_CONTEXT_MENU_TYPE.ENTITY;

    this.props.actions.onContextNavigationEntityClick(entity);
    this.props.openContextMenu(event, contextType);
  };

  renderLoader = ({ parentLoaderVisible }) =>
    !parentLoaderVisible ? (
      <Loader className="content__loader">{this.props.intl.formatMessage({ id: 'translate.generic.loading' })}</Loader>
    ) : null;

  renderTreeNode = (entity) => {
    const { contextNavigationEntity, selectedNavigationEntity } = this.props;
    const entityPath = getEntityPath(entity);
    const isExpanded = getEntityIsExpanded(entity);

    const isSelected =
      selectedNavigationEntity && getEntityInfoNumber(entity) === getEntityInfoNumber(selectedNavigationEntity);
    let isHighlighted = false;

    if (contextNavigationEntity) {
      isHighlighted = entityPath === getEntityPath(contextNavigationEntity);
    }

    const sideNavigationLabelProps = {
      isExpanded,
      isSelected,
      isHighlighted,
      entity
    };

    return (
      <DragSideNavigationItem
        {...sideNavigationLabelProps}
        handleDrop={this.props.actions.moveByDragAndDrop}
        onContextMenu={(event) => this.onContextMenu(event, { entity })}
        onLabelClick={() => this.handleItemClick({ entity })}
        onDoubleClick={this.handleEntityDoubleClick.bind(this, { id: entityPath, entity })}
      />
    );
  };

  render() {
    const { entities } = this.props;

    if (!entities['/']) {
      return <LoaderContext.Consumer children={this.renderLoader} />;
    }

    const nodes = entities['/'].map((ent) => this.mapDataToTree(ent, entities));

    return (
      <nav className="side-navigation">
        <Tree
          nodes={nodes}
          expanded={this.state.expanded}
          onExpand={(expanded, data) => {
            this.toggleListItem({ entity: data.entity }, event);
            this.setState({ expanded });
          }}
          onCheck={(checked, data) => this.handleItemClick({ entity: data.entity })}
          style={{ overflowX: 'hidden' }}
        />
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...fileManagerActions }, dispatch)
});

const mapStateToProps = (state: RootState) => ({
  time: state.fileManager.time,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  contextNavigationEntity: state.fileManager.contextNavigationEntity,
  entities: state.fileManager.entities,
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible
});

export default connect<{}, any, Partial<Props>>(mapStateToProps, mapDispatchToProps)(injectIntl(SideNavigation));
