import * as React from 'react';
import { Grid } from '@siteground/styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import { EditDbAccess } from '../../../components/common-forms';
import indexWithCRUD from '../../../components/indexWithCRUD';
import SGTable from '../../../components/sg-table';
import TableContextMenu from '../../../components/table-context-menu/table-context-menu';
import { DeleteDialog } from '../../../components/dialogs';
import { SGDialogForm } from '../../../components/sg-dialog';
import VCS from '../../../components/visibility-control-service';
import { CreateBox, CreateForm } from './create';

type Props = {
  actions: CrudActions;
  items: any;
  location: any;
  intl: Intl;
  create: () => void;
  openSGDialog: Function;
  closeSGDialog: Function;
};

type State = {
  currentUpdatePayload: any;
  currentDeleteConformationDialogPayload: any;
};

class DbMysqlAccessPage extends React.Component<Props, State> {
  readonly state = {
    currentUpdatePayload: null,
    currentDeleteConformationDialogPayload: null
  };

  onCreateFormSubmit = (formData) => {
    const name = formData.addr;

    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: REDUX_FORM.CREATE_MYSQL_ACCESS,
          success: {
            intlKey: 'translate.page.database_access.created_msg',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_access.created.error.message',
            intlValues: { name }
          }
        }
      }
    });
  };

  render() {
    const { intl } = this.props;

    const columns = [
      { header: intl.formatMessage({ id: 'translate.generic.host' }), accessor: 'addr' },
      { header: intl.formatMessage({ id: 'translate.generic.label' }), accessor: 'label' },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <Grid>
        <CreateBox>
          <CreateForm onSubmit={this.onCreateFormSubmit} />
        </CreateBox>

        <VCS resourceName={API_RESOURCE.MYSQL_ACCESS.resourceNameMetaApi} hasMethod="GET">
          <SGTable
            title={intl.formatMessage({ id: 'translate.page.database_access.list.title' })}
            data={this.props.items.dbMysqlAccess}
            columns={columns}
            resources={[{ resourceName: API_RESOURCE.MYSQL_ACCESS.resourceName, methods: ['GET'] }]}
            noDataMessage="translate.page.database_access.sg-table.no-data.message"
          />
        </VCS>

        {this.renderUpdateComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </Grid>
    );
  }

  renderUpdateComponent = () => {
    const { intl, closeSGDialog } = this.props;

    return (
      <SGDialogForm
        name={REDUX_FORM.EDIT_DB_ACCESS}
        title={intl.formatMessage({ id: 'translate.page.database_access.edit.dialog.title' })}
        resources={[
          {
            resourceName: API_RESOURCE.MYSQL_ACCESS.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <EditDbAccess
          initialValues={this.state.currentUpdatePayload}
          onSubmit={(data) => this.props.actions.updateItem(data, () => closeSGDialog(REDUX_FORM.EDIT_DB_ACCESS))}
        />
      </SGDialogForm>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const name = deletePayload && deletePayload.name;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.database_access.delete.dialog.title' }, { name })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const name = entity.addr;

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name,
      _metaFields: { ...API_RESOURCE.MYSQL_ACCESS },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.database_access.deleted_msg',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_access.failed_delete_msg',
            intlValues: { name }
          }
        }
      }
    };

    const updatePayload: UpdateItemPayload = {
      _metaFields: { ...API_RESOURCE.MYSQL_ACCESS },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.database_access.edit_msg',
            intlValues: { name }
          },
          error: {
            intlKey: 'translate.page.database_access.failed_edit_msg',
            intlValues: { name }
          }
        }
      },
      ...entity
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.MYSQL_ACCESS.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            icon: 'edit',
            label: intl.formatMessage({ id: 'translate.page.database_access.manage.access' }),
            e2eAttr: 'table-action-edit',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentUpdatePayload: updatePayload }, () => openSGDialog(REDUX_FORM.EDIT_DB_ACCESS))
          },
          {
            vcsMethod: 'DELETE',
            icon: 'trash',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-delete',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
                openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };
}

export default indexWithCRUD(undefined, { ...sgDialogActions })(DbMysqlAccessPage, API_RESOURCE.MYSQL_ACCESS);
