import * as React from 'react';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { AlignType, Colors, DialogDensityType, DialogStateType, SizeType } from '@siteground/styleguide';
import { SGDialog, SGDialogCancel, SGDialogSubmit } from '.';
import { dispatchRemoteFormSubmit } from '../sg-form-util/remote-submit-util';

type Props = {
  name: string;
  // Dialog UI Props
  size?: SizeType;
  title?: any;
  subTitle?: any;
  state?: DialogStateType;
  icon?: string;
  iconColor?: Colors;
  iconSize?: string;
  align?: AlignType;
  density?: DialogDensityType;
  footer?: any;
  // other
  submitLabel?: string;
  children: any;
  resources?: LoaderResource[];
  onSubmit?: (e) => void;
  remoteFormSubmit?: (formName) => (e) => void;
  destroyForm?: (formName) => void;
  onCloseHandler?: Function;
};

const SGDialogForm = ({
  children,
  name,
  size,
  title,
  subTitle,
  state = 'warning',
  icon = 'edit',
  iconColor,
  iconSize,
  align,
  density,
  footer,
  resources = [],
  remoteFormSubmit,
  submitLabel,
  destroyForm,
  onCloseHandler
}: Props) => (
  <SGDialog
    id={name}
    size={size}
    title={title}
    subTitle={subTitle}
    state={state}
    icon={icon}
    iconColor={iconColor}
    iconSize={iconSize}
    align={align}
    density={density}
    resources={resources}
    footer={
      footer || (
        <React.Fragment>
          <SGDialogCancel id={name} />
          <SGDialogSubmit formName={name} label={submitLabel} />
        </React.Fragment>
      )
    }
    onCloseHandler={() => {
      destroyForm(name);

      if (onCloseHandler) {
        onCloseHandler();
      }
    }}
  >
    <form onSubmit={remoteFormSubmit(name)} name={name} data-e2e={`dialog-form-${name}`}>
      {children}
    </form>
  </SGDialog>
);

export default connect<any, any, Partial<Props>>(undefined, (dispatch) => ({
  remoteFormSubmit: dispatchRemoteFormSubmit(dispatch),
  destroyForm: (formName) => dispatch(destroy(formName))
}))(SGDialogForm);
