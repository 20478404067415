import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Label, Switch } from '@siteground/styleguide';
import { updateItem } from '../../../../core/actions/crud';
import { API_RESOURCE } from '../../../../core/constants/api';
import SettingListItem, { SettingListItemProps } from './setting-list-item';
import { CDN_SETTING_ID, DNS_CENTRAL_RECORDS } from '../constants';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { updateCDNItem, createCDNItem } from '../../../../core/actions/pages/cdn';

const { DEVELOPMENT_MODE, DYNAMIC_CACHE, ALWAYS_ONLINE } = CDN_SETTING_ID;

const notificationEnable = {
  [DEVELOPMENT_MODE]: {
    success: 'translate.page.cloudflare.development.mode.enable.success.message',
    error: 'translate.page.cloudflare.development.mode.enable.error.message'
  },
  [DYNAMIC_CACHE]: {
    success: 'translate.page.cdn.dynamic.cashing.enable.success.message',
    error: 'translate.page.cdn.dynamic.cashing.enable.error.message'
  },
  [ALWAYS_ONLINE]: {
    success: 'translate.page.cdn.always.online.enable.success.message',
    error: 'translate.page.cdn.always.online.enable.error.message'
  }
};

const notificationDeactivate = {
  [DEVELOPMENT_MODE]: {
    success: 'translate.page.cloudflare.development.mode.deactivate.success.message',
    error: 'translate.page.cloudflare.development.mode.deactivate.error.message'
  },
  [DYNAMIC_CACHE]: {
    success: 'translate.page.cdn.dynamic.cashing.deactivated.success.message',
    error: 'translate.page.cdn.dynamic.cashing.deactivated.error.message'
  },
  [ALWAYS_ONLINE]: {
    success: 'translate.page.cdn.always.online.deactivated.success.message',
    error: 'translate.page.cdn.always.online.deactivated.error.message'
  }
};

const getSwitchMetaFields = ({ id, isChecked }) => {
  const result = {
    _meta: {},
    _metaFields: {}
  };

  switch (id) {
    case DYNAMIC_CACHE:
      result._metaFields = {
        ...API_RESOURCE.DOMAIN,
        fetchItemsOnSuccess: [API_RESOURCE.DOMAIN_ALL]
      };
      break;
    case DEVELOPMENT_MODE:
    case ALWAYS_ONLINE:
      result._metaFields = {
        ...API_RESOURCE.LOCATION
      };
      break;
    default:
      break;
  }

  if (!id) {
    return result;
  }

  result._meta = {
    notification: {
      type: 'generic',
      success: {
        intlKey: isChecked ? notificationEnable[id].success : notificationDeactivate[id].success,
        intlValues: null
      },
      error: {
        intlKey: isChecked ? notificationEnable[id].error : notificationDeactivate[id].error,
        intlValues: null
      }
    }
  };

  return result;
};

const getSwitchSettingsFields = ({ id, isChecked, domain }) => {
  switch (id) {
    case DEVELOPMENT_MODE:
      return {
        development_mode: isChecked,
        domain_id: domain.id,
        path: '/'
      };
    case DYNAMIC_CACHE:
      return {
        settings: {
          ...domain.settings,
          dynamic_cache: isChecked
        }
      };
    case ALWAYS_ONLINE:
      return {
        use_stale_cache: isChecked,
        domain_id: domain.id,
        path: '/'
      };
  }
};

const getSwitchRequestTypes = (id) => {
  switch (id) {
    case DEVELOPMENT_MODE:
      return customRequestTypes.UPDATE_DEV_MODE;
    case DYNAMIC_CACHE:
      return {
        resourceName: API_RESOURCE.DOMAIN.resourceName,
        methods: ['PUT']
      };
    case ALWAYS_ONLINE:
      return customRequestTypes.UPDATE_ALWAYS_ONLINE;
  }
};

type Props = {
  updateItem: Function;
  createCDNItem: Function;
  updateCDNItem: Function;
  setting?: any;
  DNSRecords: any;
  cdnDomain: any;
  domainLocation: any;
  domain: any;
  items: any;
};

type SettingListSwitchProps = SettingListItemProps & Props;

const SettingListSwitch = (props: SettingListSwitchProps) => {
  const { intl, updateItem, createCDNItem, setting, DNSRecords, cdnDomain, title, info, resources, updateCDNItem } =
    props;

  return (
    <SettingListItem title={title} info={info} resources={resources}>
      {DNSRecords !== DNS_CENTRAL_RECORDS || !cdnDomain.cdn_enabled ? (
        <Label type="link" color="light">
          {intl.formatMessage({ id: 'translate.generic.inactive' })}
        </Label>
      ) : (
        <Switch
          checked={Boolean(setting.value)}
          onChange={(event) => {
            const { domain, domainLocation } = props;
            const isChecked = event.target.checked ? 1 : 0;

            if (setting.id === DYNAMIC_CACHE) {
              return updateItem({
                id: domain.id,
                ...getSwitchMetaFields({ id: setting.id, isChecked }),
                ...getSwitchSettingsFields({ id: setting.id, isChecked, domain })
              });
            }

            if (domainLocation) {
              return updateCDNItem(
                {
                  id: domainLocation.id,
                  ...getSwitchMetaFields({ id: setting.id, isChecked }),
                  ...getSwitchSettingsFields({ id: setting.id, isChecked, domain })
                },
                {
                  requestTypeName: getSwitchRequestTypes(setting.id)
                }
              );
            }

            return createCDNItem(
              {
                ...getSwitchMetaFields({ id: setting.id, isChecked: 1 }),
                ...getSwitchSettingsFields({ id: setting.id, isChecked: 1, domain })
              },
              {
                requestTypeName: getSwitchRequestTypes(setting.id)
              }
            );
          }}
        />
      )}
    </SettingListItem>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateItem: bindActionCreators(updateItem, dispatch),
  createCDNItem: bindActionCreators(createCDNItem, dispatch),
  updateCDNItem: bindActionCreators(updateCDNItem, dispatch)
});

const mapStateToProps = (state) => ({});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl<any, any>(SettingListSwitch)) as any;
