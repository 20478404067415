import * as React from 'react';
import { connect } from 'react-redux';
import { Grid, Table } from '@siteground/styleguide';
import { Days, StatsType } from '../../../../core/definitions/stats';
import { ControlOptions, StatsViewIndex } from '../index';
import { formatBandwidthLabel, formatDate, TABLE_VIEW_PROPS } from '../utils';
import StatisticsLineChart from './line-charts';

type Props = {
  getStats: (statsKey: StatsType) => Days[];
  renderControls: (option?: ControlOptions) => any;
  statisticViewIndex: StatsViewIndex;
  intl: Intl;
  userLang?: string;
};

export const Traffic = ({ getStats, renderControls, statisticViewIndex, intl, userLang }: Props) => {
  const stats = getStats('days');

  return (
    <Grid gap="responsive">
      <div>{renderControls()}</div>

      {statisticViewIndex === StatsViewIndex.Chart && (
        <React.Fragment>
          <StatisticsLineChart stats={stats} type="unique-visitors" />
          <StatisticsLineChart stats={stats} type="pageviews" />
          <StatisticsLineChart stats={stats} type="average-number-of-pages" />
          <StatisticsLineChart stats={stats} type="bandwidth" />
        </React.Fragment>
      )}

      {statisticViewIndex === StatsViewIndex.Table && (
        <Table
          data={stats}
          columns={[
            {
              header: intl.formatMessage({ id: 'translate.page.stats.date.label' }),
              accessor: 'date',
              render: (date) => formatDate(date, intl)
            },
            {
              header: intl.formatMessage({ id: 'translate.page.stats.unique-visitors.label' }),
              accessor: 'visits'
            },
            {
              header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
              accessor: 'pages'
            },
            {
              header: intl.formatMessage({ id: 'translate.page.stats.average-number-of-pages.label' }),
              accessor: 'pagesPerVisit'
            },
            {
              header: intl.formatMessage({ id: 'translate.page.stats.bandwidth.label' }),
              accessor: 'bwInMB',
              render: (bw) => formatBandwidthLabel(bw)
            }
          ]}
          {...TABLE_VIEW_PROPS}
        />
      )}
    </Grid>
  );
};

export default connect<any, any, Props>(({ session }) => ({
  userLang: session && session.user && session.user.language
}))(Traffic);
