import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column, Flex, FormLabel, Grid, Spacer } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import FormRadioButton from '../../../components/form-radio-button';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';
import { SettingApiType } from '../types';
import { API_BOOLEAN_TYPES } from '../utils';

type Props = {
  initialValues: {
    settingName: string;
    settingType: SettingApiType;
    settingApiValue: string;
    settingFormValue: string;
    _metaFields: any;
  };
  validationUtils: ValidationUtils;
  intl: Intl;
};

const formName = REDUX_FORM.CHANGE_PHP_INI_SETTING;

const RADIO_INPUT_LABELS = {
  noYes: ['translate.page.php-settings.no.label', 'translate.page.php-settings.yes.label'],
  offOn: ['translate.page.php-settings.off.label', 'translate.page.php-settings.on.label']
};

const FORM_BOOLEAN_VALUES = ['true', 'false'];

export class ChangePhpIniSetting extends React.Component<Props, any> {
  renderRadioButtons = (labels: string[] = RADIO_INPUT_LABELS.noYes) => {
    const { intl, initialValues } = this.props;

    return (
      <Flex direction="column">
        <FormLabel>{intl.formatMessage({ id: 'translate.generic.value' })}</FormLabel>

        <Flex>
          <Field name="settingFormValue" type="radio" value="true" component={FormRadioButton}>
            {intl.formatMessage({ id: labels[1] })}
          </Field>

          <Spacer size="medium" />

          <Field name="settingFormValue" type="radio" value="false" component={FormRadioButton}>
            {intl.formatMessage({ id: labels[0] })}
          </Field>
        </Flex>
      </Flex>
    );
  };

  renderInputChangeField = () => {
    const { intl, validationUtils } = this.props;
    const { validPhpSettingValue } = validationUtils;

    return (
      <Grid>
        <Column smSpan="12">
          <Field
            name="settingFormValue"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.value' })}
            component={FormInput}
            validate={[validPhpSettingValue]}
          />
        </Column>
      </Grid>
    );
  };

  render() {
    const { initialValues, intl } = this.props;
    const { settingName, settingType, settingApiValue, settingFormValue } = initialValues;

    if (FORM_BOOLEAN_VALUES.includes(settingFormValue) && API_BOOLEAN_TYPES.includes(settingType)) {
      if (RADIO_INPUT_LABELS.offOn.includes(settingApiValue)) {
        return this.renderRadioButtons(RADIO_INPUT_LABELS.offOn);
      }

      return this.renderRadioButtons(RADIO_INPUT_LABELS.noYes);
    }

    return this.renderInputChangeField();
  }
}

export default withSpanelForm(reduxForm({ form: formName })(injectIntl(ChangePhpIniSetting)));
