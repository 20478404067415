import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Box, Button, Column, Flex, Icon, Text, Title, Notice } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { APP_TYPES } from '../../../../core/constants/application';
import { REDUX_FORM, LocalTaskLoaderType } from '../../../../core/constants/common';
import FormDropdown from '../../../components/form-dropdown';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import { createTaskLoaderMetadata } from '../../../../core/common/create-metadata';
import { findAppIconByType } from '../../../../core/utils/app-names';

type Props = {
  app: any[];
  form: string;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (fieldName: string) => any;
  getFormValues: (formName: string) => any;
  intl: Intl;
};

const formName = REDUX_FORM.CREATE_GIT;

const Form = ({ sPanelFormSubmit, form, app, validationUtils, intl, getFormValues }: Props) => {
  const { required } = validationUtils;
  const formValues = getFormValues(form);
  const selectedAppId = formValues && formValues.id;
  const selectedApp = selectedAppId && app.find(({ id }) => Boolean(id === selectedAppId));

  return (
    <SGForm onSubmit={sPanelFormSubmit(form)} name={form}>
      {selectedApp && selectedApp.app === APP_TYPES.WORDPRESS && (
        <Column smSpan="12">
          <Notice type="warning" background="light" shadow={false}>
            {intl.formatMessage({ id: 'translate.page.git.create.wordpress-warning.message' })}
          </Notice>
        </Column>
      )}
      <Column smSpan="12">
        <Field
          label={intl.formatMessage({ id: 'translate.page.git.create.select-app.label' })}
          name="id"
          options={app}
          optionValue="id"
          optionLabel="app_url"
          validate={[required]}
          enableBackendValidation
          template={(options) => (
            <Flex align="center" wrap="nowrap">
              <Icon size="20" name={findAppIconByType(options.app)} multicolor />
              <Text truncate style={{ marginLeft: '10px', lineHeight: 'inherit' }}>
                {options.app_url}
              </Text>
            </Flex>
          )}
          component={FormDropdown}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={form} label={intl.formatMessage({ id: 'translate.generic.create' })} />
      </Column>
    </SGForm>
  );
};

const CreateApp = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.APP_REPO,
      formName
    },
    _meta: createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX)
  }
})(Form);

export default withSpanelForm(injectIntl(CreateApp));
