import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';
import { DNSTypes } from '../constants';

type Props = {
  apiResource: {
    resourceName: string;
    resourceNameMetaApi: string;
  };
  selectedDNSType: DNSTypes;
  children: any;
  intl: Intl;
  renderBeforeBoxChildren: any;
};

export const DNS_FORM_BY_TYPE = {
  [DNSTypes.DNS_A]: REDUX_FORM.CREATE_DNS_A,
  [DNSTypes.DNS_AAAA]: REDUX_FORM.CREATE_DNS_AAAA,
  [DNSTypes.DNS_CNAME]: REDUX_FORM.CREATE_DNS_CNAME,
  [DNSTypes.DNS_MX]: REDUX_FORM.CREATE_DNS_MX,
  [DNSTypes.DNS_SRV]: REDUX_FORM.CREATE_DNS_SRV,
  [DNSTypes.DNS_TXT]: REDUX_FORM.CREATE_DNS_TXT
};

const CreateContainer = ({ apiResource, children, intl, selectedDNSType, renderBeforeBoxChildren }: Props) => {
  const formName = DNS_FORM_BY_TYPE[selectedDNSType];

  return (
    <VCS resourceName={apiResource.resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.dns.create.title' })}
        resourceName={apiResource.resourceName}
        formName={formName}
        renderTutorials={({ DefaultRender }) => (
          <DefaultRender
            title={intl.formatMessage({ id: 'translate.tips_and_tutorials.dns.title' })}
            text={intl.formatMessage({ id: 'translate.tips_and_tutorials.dns.content' })}
          >
            {!CONFIG.IS_RESELLER && (
              <Link
                href="https://my.siteground.com/support/kb/point-website-domain-siteground"
                target="_blank"
                transform="uppercase"
                weight="bold"
              >
                {intl.formatMessage({ id: 'translate.tips_and_tutorials.dns.link' })}
              </Link>
            )}
          </DefaultRender>
        )}
        renderBeforeBoxChildren={renderBeforeBoxChildren}
        resources={[
          {
            resourceName: apiResource.resourceName,
            methods: ['POST']
          }
        ]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default injectIntl(CreateContainer);
