import { call, put, select, takeLatest } from 'redux-saga/effects';
import { WM_ROUTES } from '../../../webapp/core/constants/WM_ROUTES';
import { openNewTabAction } from '../../actions/open-new-tab';
import { clientApi } from '../../api/client';
import { siteApi } from '../../api/site';
import { REQUEST_AUTO_LOGIN_IN_WEBAPP, REQUEST_AUTOCONFIGURE_DATA } from '../../constants/actions';
import { API_RESOURCE } from '../../constants/api';
import { WmToolId } from '../../constants/route-info';
import { getClientToken, getCurrentSite, getCurrentSiteId } from '../../selectors';
import handleAvalonApiRequest from '../handle-avalon-api-request';
import { handleNemoApiRequest } from '../handle-nemo-api-request';

function* requestAutoconfigureData({ payload }) {
  const { emailId, emailClientId, onDataLoaded } = payload;
  const clientId = emailClientId.includes('outlook') ? 'outlook' : emailClientId;

  const state = yield select();

  const response = yield call(
    siteApi({
      endpoint: `${API_RESOURCE.EMAIL.endpoint}/${emailId}`,
      method: 'PUT',
      body: {
        config: clientId
      },
      state
    })
  );

  const hasValidResponse = Boolean(
    response.data &&
      Number(response.data.success) === 1 &&
      response.data.config_path &&
      response.data.config_path.length > 0
  );

  if (hasValidResponse) {
    onDataLoaded(response.data.config_path);
  }

  return response;
}

function* autoLoginToWebApp({ email }) {
  const siteId = yield select(getCurrentSiteId);
  const currentSite = yield select(getCurrentSite);
  const clientToken = yield select(getClientToken);

  const siteTokenResponse: APIResponseSiteToken = yield call(
    clientApi(`/auth/sites/${siteId}/token?_client_token=${clientToken}`, 'GET')
  );

  const token = siteTokenResponse.data.site_token;
  const response = yield call(
    clientApi(`/auth/webapp-autologin?_client_token=${encodeURIComponent(clientToken)}`, 'POST', {
      email,
      token,
      server_hostname: currentSite.api_url
    })
  );

  yield put(
    openNewTabAction({
      url: `https://${currentSite.api_url}${WM_ROUTES[WmToolId.webmail]}?token=${response.data.web_app_token}`
    })
  );
}

function* emailAccounts(): any {
  yield takeLatest(REQUEST_AUTOCONFIGURE_DATA, handleAvalonApiRequest(requestAutoconfigureData));
  yield takeLatest(REQUEST_AUTO_LOGIN_IN_WEBAPP, function* (action: NemoRequestAction) {
    yield call(handleNemoApiRequest(autoLoginToWebApp, action), action.payload);
  });
}

export default emailAccounts;
