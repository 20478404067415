import { getFormValues } from 'redux-form';
import { delay } from 'redux-saga';
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { uploadChunk } from '../../../../core/api/file-upload';
import { siteApi } from '../../../../core/api/site';
import * as BaseConstantsForActions from '../../../../core/constants/actions';
import { REDUX_FORM } from '../../../../core/constants/common';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import handleAvalonApiRequest from '../../../../core/sagas/handle-avalon-api-request';
import { getCurrentPathname } from '../../../../core/selectors/routing';
import { getSiteToken } from '../../../../core/selectors/session';
import { getCurrentSite } from '../../../../core/selectors/sites';
import getStore from '../../../../core/store';
import { getChunks } from '../../../../core/utils/files';
import { fetchDir, fetchDirs, transferSizeChange, uploadFileChunk } from '../actions/file-manager';
import { searchForInput } from '../actions/search-view';
import * as FileManagerConstants from '../constants/actions';
import { FILE_MANAGER_API_RESPONSE_DIR, FILE_MANGER_ENDPOINTS } from '../constants/common';

import {
  getEntityByPath,
  getEntityInfoNumber,
  getEntityParentPath,
  getEntityPath,
  getEntityType,
  getParentPath,
  isEntityKnownInCodeEditor
} from '../utils';

const Constants = { ...BaseConstantsForActions, ...FileManagerConstants };

interface UploadFileArgs {
  urlParams: {
    filename: string;
  };
  file: any;
  _meta: any;
}

interface FileManagerPostRequestArgs {
  payload: {
    body: object;
    clearFileManagerStore: object;
    endpoint: string;
    urlParams: {
      filename: string;
      entries: any[];
      dest: string;
    };
    entity?: any;
  };
}

function* handleUploadFileChunk(action) {
  const { payload } = action;
  const { chunk, urlParams, append, onProgress } = payload;

  const currentSite = yield select(getCurrentSite);
  const siteToken = yield select(getSiteToken, currentSite.id);

  const { response, cancelled } = yield race({
    response: call(uploadChunk, {
      apiUrl: currentSite.api_url,
      urlParams,
      siteToken,
      chunk,
      append,
      onProgress: ({ transferred }) => getStore().dispatch(transferSizeChange({ transferred }))
    }),
    cancelled: take(Constants.FILE_MANAGER_CLEAR_UPLOAD_PROGRESS)
  });

  return response;
}

function* uploadFileSaga(uploadFileData: UploadFileArgs) {
  const state = yield select();
  const { urlParams, file, _meta } = uploadFileData;

  const chunks = getChunks(file);
  yield put({ type: Constants.FILE_MANAGER_UPLOAD_FILE_PENDING, payload: { ...uploadFileData } });

  const CHUNK_COMPLETED = 'CHUNK_COMPLETED';
  const CHUNK_FAILED = 'CHUNK_FAILED';

  for (const chunk of chunks) {
    const randomString = String(+new Date() * Math.random());
    const chunkId = `${file.name}-chunk-${randomString}`;

    // dynamicly generate chunk success/faile actions
    // in order to queue all chunks for a given file
    const CHUNK_SUCCESS_ACTION = `${CHUNK_COMPLETED}-${chunkId}`;
    const CHUNK_FAILED_ACTION = `${CHUNK_FAILED}-${chunkId}`;

    const chunkIndex = chunks.indexOf(chunk);

    const chunkData = {
      urlParams,
      chunk,
      append: chunkIndex > 0 && Boolean(chunks.length),
      onProgress: ({ transferred }) => getStore().dispatch(transferSizeChange({ transferred }))
    };

    yield put(
      uploadFileChunk(
        chunkData,
        () => getStore().dispatch({ type: CHUNK_SUCCESS_ACTION }),
        () => getStore().dispatch({ type: CHUNK_FAILED_ACTION })
      )
    );

    const { succeeded, failed } = yield race({
      succeeded: take(CHUNK_SUCCESS_ACTION),
      failed: take(CHUNK_FAILED_ACTION)
    });

    if (succeeded) {
      // chunk is uploaded, ready for the next;
      // this includes re-auth (if needed), handling tasks and all default request behavior in handleAvalonApiRequest
      continue;
    }

    if (failed) {
      // notify file upload failure
      yield put({ type: Constants.FILE_MANAGER_UPLOAD_FILE_FAILED, payload: uploadFileData });
      return;
    }
  }

  // ALL chunks uploaded
  yield put({ type: Constants.FILE_MANAGER_UPLOAD_FILE_SUCCEEDED, payload: uploadFileData });

  // re-fetch dir after every file uploaded
  yield put({
    type: Constants.FILE_MANAGER_FETCH_DIR_REQUESTED,
    payload: {
      urlParams: {
        id: getEntityPath(state.fileManager.selectedNavigationEntity)
      }
    }
  });
}

function* handleUploadFiles(action) {
  const type = action.type;
  let state = yield select();

  while (state.fileManager.uploader.requested.length > 0) {
    const files = state.fileManager.uploader.requested.slice(0, 3);

    yield files.map((file) =>
      race({
        upload: call(uploadFileSaga, file),
        cancel: take(Constants.FILE_MANAGER_CLEAR_UPLOAD_PROGRESS)
      })
    );

    // Makes sure that the store is updated when the user cancels the requests.
    yield delay(200);
    state = yield select();
  }
}

function* saveFileSaga(action) {
  const state = yield select();
  const { entity, urlParams, file, _meta, autoOpen, autoClose } = action.payload;
  const formData = new FormData();

  try {
    formData.append('file', new File([file], _meta.name));
  } catch (e) {
    // Edge 16 ... but not IE 11 ...
    formData.append('file', new Blob([file]));
  }

  const response = yield call(
    siteApi({
      endpoint: '/file',
      method: 'POST',
      body: formData,
      state,
      urlParams,
      disableBodyStringify: true
    })
  );

  if (entity) {
    yield put({
      type: Constants.FILE_MANAGER_SAVE_FILE_SUCCEEDED,
      payload: { entity }
    });
  }

  const newFileParentPath = urlParams.filename.substr(0, urlParams.filename.lastIndexOf('/')) || '/';

  yield put(
    fetchDir({
      urlParams: {
        id: entity ? getEntityParentPath(entity) : newFileParentPath
      }
    })
  );

  const selectedNavigationEntity = state.fileManager.selectedNavigationEntity;
  if (getEntityType(selectedNavigationEntity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY) {
    yield put(
      fetchDir({
        urlParams: {
          id: getEntityPath(selectedNavigationEntity)
        }
      })
    );
  }

  yield take(Constants.FILE_MANAGER_FETCH_DIR_SUCCEEDED);

  const canOpenInCodeEditor = isEntityKnownInCodeEditor(urlParams.filename);
  if (autoOpen && canOpenInCodeEditor) {
    const newState = yield select();
    yield put({
      type: Constants.FILE_MANAGER_FETCH_FILE_REQUESTED,
      payload: {
        urlParams,
        entity: getEntityByPath(urlParams.filename, newState.fileManager)
      }
    });
  }

  if (autoOpen && !canOpenInCodeEditor) {
    yield put({
      type: Constants.FILE_MANAGER_SET_MESSAGE_DIALOG_CONTENT,
      payload: {
        title: 'translate.file.manager.file.created.but.not.allowed.for.editing'
      }
    });
  }

  if (autoClose) {
    yield put({
      type: Constants.FILE_MANAGER_CODE_EDITOR_ON_TAB_CLOSE,
      payload: { ...entity }
    });
  }

  return response;
}

/* tslint:disable:cyclomatic-complexity */
function* fileManagerPostRequestSaga(action: FileManagerPostRequestArgs) {
  const state = yield select();
  const { body = {}, endpoint, urlParams, entity, clearFileManagerStore = {} } = action.payload;

  const response = yield call(
    siteApi({
      endpoint,
      method: 'POST',
      body: {
        ...body,
        ...urlParams
      },
      state
    })
  );

  yield put({
    type: Constants.FILE_MANAGER_CLEAR_STORE_PROPERTIES,
    payload: clearFileManagerStore
  });

  if (endpoint === Constants.FILE_MANGER_API_DIR_MOVE) {
    yield put({
      type: Constants.FILE_MANAGER_UPDATE_AFTER_MOVE,
      payload: {
        oldInfoNumber: getEntityInfoNumber(entity),
        newInfoNumber: response.data.i
      }
    });
  }

  if (
    endpoint === Constants.FILE_MANGER_API_DIR_RENAME &&
    getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE
  ) {
    const updatedEntity = entity;
    const path = response.data.id;
    updatedEntity.n = path.substring(path.lastIndexOf('/') - 1);
    yield put({
      type: Constants.FILE_MANAGER_UPDATE_AFTER_RENAME,
      payload: [updatedEntity]
    });
  }

  /**
   * Update FM folders
   */

  const values = yield select(getFormValues(REDUX_FORM.FILE_MANAGER_SEARCH));
  if (values) {
    const root = values.root;
    const search = values.search;
    yield put(
      searchForInput({
        root,
        search,
        _meta: {
          notification: {
            type: 'generic',
            error: {
              intlKey: 'translate.file.manager.search.error.message'
            }
          }
        }
      })
    );

    return response;
  }

  const selectedNavigationEntity = state.fileManager.selectedNavigationEntity;
  const responseData = response.data;

  let entityPath;
  if (entity && getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY) {
    entityPath = getEntityPath(entity);
  }

  let entityParentPath;
  if (entity) {
    entityParentPath = getEntityParentPath(entity);
  }

  let selectedNavigationEntityPath;
  if (selectedNavigationEntity && getEntityType(selectedNavigationEntity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY) {
    selectedNavigationEntityPath = getEntityPath(selectedNavigationEntity);
  }

  let selectedNavigationEntityParentPath;
  if (selectedNavigationEntity) {
    selectedNavigationEntityParentPath = getEntityParentPath(selectedNavigationEntity);
  }

  const responseEntityPath = responseData && responseData.id;
  const responseEntity = responseEntityPath && getEntityByPath(responseEntityPath, state.fileManager);
  const isResponseEntityFile = responseEntity && getEntityType(responseEntity) === FILE_MANAGER_API_RESPONSE_DIR.FILE;

  const uniquePaths = new Set(
    [
      entityPath,
      entityParentPath,
      selectedNavigationEntityPath,
      selectedNavigationEntityParentPath,
      !isResponseEntityFile && responseEntityPath,
      responseEntity && getParentPath(responseEntityPath)
    ].filter(Boolean)
  );

  yield put(
    fetchDirs({
      entries: Array.from(uniquePaths)
    })
  );

  return response;
}

/* tslint:enable:cyclomatic-complexity */

function* checkAsyncTask(action) {
  const state = yield select();
  const tasks = state.tasks;

  try {
    const asyncTaskForFM = tasks.find((task) => FILE_MANGER_ENDPOINTS.includes(task.options.endpoint));
    const routePath = getCurrentPathname(state);
    const shouldUpdateFM = Boolean(asyncTaskForFM && routePath === ROUTES[ToolId.filemanager]);

    if (!shouldUpdateFM) {
      return;
    }

    const selectedNavigationEntityPath = getEntityPath(state.fileManager.selectedNavigationEntity);
    const selectedNavigationEntityParentPath = getEntityParentPath(state.fileManager.selectedNavigationEntity);

    const uniquePaths = new Set([selectedNavigationEntityPath, selectedNavigationEntityParentPath].filter(Boolean));

    yield put(
      fetchDirs({
        entries: Array.from(uniquePaths)
      })
    );
  } catch (e) {
    console.error(e);
  }
}

function* postRequest(): any {
  yield takeEvery(Constants.FILE_MANAGER_POST_REQUEST, handleAvalonApiRequest(fileManagerPostRequestSaga));
  yield takeEvery(Constants.FILE_MANAGER_SAVE_FILE, handleAvalonApiRequest(saveFileSaga));
  yield takeEvery(Constants.FILE_MANAGER_UPLOAD_FILES, handleUploadFiles);
  yield takeEvery(Constants.FILE_MANAGER_UPLOAD_FILE_CHUNK, handleAvalonApiRequest(handleUploadFileChunk));
  yield takeEvery(BaseConstantsForActions.PENDING_TASK_COMPLETED, checkAsyncTask);
}

export default postRequest;
