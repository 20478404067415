import * as React from 'react';
import { Button, Grid, Text, Textarea, Link } from '@siteground/styleguide';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DIALOGS } from '../../../core/constants/common';
import SGDialog from '../../components/sg-dialog/dialog';

export const MX_RECORDS = ['mx10.mailspamprotection.com', 'mx20.mailspamprotection.com', 'mx30.mailspamprotection.com'];

type Props = {
  domainName: string;
  onClose: Function;
  intl: Intl;
};

export const MxRecords = ({ onClose, intl, domainName }: Props) => (
  <SGDialog
    id={DIALOGS.MX_RECORDS}
    icon="warning"
    state="warning"
    density="small"
    title={intl.formatMessage({ id: 'translate.page.spam-experts.mx-records-dialog.title' })}
    footer={
      <Button onClick={onClose} color="primary">
        {intl.formatMessage({ id: 'translate.generic.close' })}
      </Button>
    }
  >
    <Grid padding="medium">
      <Text>
        <FormattedMessage
          id="translate.page.spam-experts.mx-records-dialog.text"
          values={{
            domain: (
              <Link target="_blank" href={`//${domainName}`}>
                {domainName}
              </Link>
            )
          }}
        />
      </Text>
      <Textarea
        readOnly
        rows="4"
        label={intl.formatMessage({ id: 'translate.page.spam-experts.mx-records-dialog.label' })}
      >
        {MX_RECORDS.map((record) => record).join('\n')}
      </Textarea>
    </Grid>
  </SGDialog>
);

export default injectIntl(MxRecords);
