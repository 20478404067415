import customRequestTypes from '../../../../core/constants/custom-request-types';
import {
  FILE_MANAGER_CLOSE_SEARCH_VIEW,
  FILE_MANAGER_OPEN_FILE_FROM_SEARCH_VIEW,
  FILE_MANAGER_OPEN_SEARCH_VIEW,
  FILE_MANAGER_SEARCH_FOR_INPUT,
  FILE_MANAGER_SHOW_ENTITY_IN_EXPLORER
} from '../constants/actions';

export const openSearchView = () => ({ type: FILE_MANAGER_OPEN_SEARCH_VIEW });
export const closeSearchView = () => ({ type: FILE_MANAGER_CLOSE_SEARCH_VIEW });

export type SearchForInputPayload = {
  root: string;
  search: string;
  _meta: Partial<TaskMeta>;
};

export const searchForInput = (payload: SearchForInputPayload, onComplete?: OnCompleteCallback) => ({
  type: FILE_MANAGER_SEARCH_FOR_INPUT,
  requestTypeName: customRequestTypes.FILE_MANAGER_REQUESTED_SEARCH,
  payload,
  onComplete
});

export const openFileFromSearchView = (entity) => ({
  type: FILE_MANAGER_OPEN_FILE_FROM_SEARCH_VIEW,
  requestTypeName: customRequestTypes.FILE_MANAGER_OPEN_ENTITY_IN_EXPLORER,
  payload: {
    entity
  }
});

export const showEntityInExplorer = (entity) => ({
  type: FILE_MANAGER_SHOW_ENTITY_IN_EXPLORER,
  requestTypeName: customRequestTypes.FILE_MANAGER_SHOW_ENTITY_IN_EXPLORER,
  payload: {
    entity
  }
});
