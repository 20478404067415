import { getChunks } from '../utils/files';
import { CustomError, NewTasks } from './site';
import { CANCEL } from 'redux-saga';

const GENERIC_FAILED_MESSAGE = 'Failed to upload.';
const NO_INTERNET_CONNECTION = 'No internet connection.';

export const uploadChunk = ({ apiUrl, urlParams, siteToken, chunk, append = false, onProgress }) => {
  const xhr = new XMLHttpRequest();

  const chunkPromise = new Promise((resolve, reject) => {
    const endpoint = '/file';
    const method = 'POST';
    const flag = append ? '&append=1' : '';
    const apiHost = `https://${apiUrl}/api-sgcp/v00`;
    const urlParamsStr =
      Object.keys(urlParams)
        .map((k) => `${k}=${encodeURIComponent(urlParams[k])}`)
        .join('&') || '';
    const xhrEndpoint = `${apiHost}${endpoint}?${urlParamsStr}&_site_token=${siteToken}`;

    let lastLoaded = 0;

    xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
      if (event.lengthComputable) {
        const transferred = event.loaded - lastLoaded;
        lastLoaded = event.loaded;
        onProgress({ transferred });
      }
    });

    xhr.upload.addEventListener('error', (e) => reject(new CustomError({ message: GENERIC_FAILED_MESSAGE })));
    xhr.upload.addEventListener('abort', (ab) => reject(new CustomError({ message: GENERIC_FAILED_MESSAGE })));

    xhr.onreadystatechange = (event) => {
      const { readyState, response } = xhr;
      const status = Number(xhr.status);

      // status 4 -> DONE (https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState)
      if (readyState === 4) {
        switch (status) {
          case 0:
            reject(new CustomError({ message: NO_INTERNET_CONNECTION }));
            break;
          case 202:
            reject(new NewTasks(JSON.parse(response)));
            break;
          case 200:
          case 201:
            resolve({ response: JSON.parse(response || null) });
            break;
          default:
            reject(new CustomError(JSON.parse(response)));
        }
      }
    };

    xhr.open(method, xhrEndpoint + flag, true);
    xhr.send(chunk.formData);
  });

  chunkPromise[CANCEL] = () => xhr.abort();
  return chunkPromise;
};
