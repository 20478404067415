import { call, put, race, take } from 'redux-saga/effects';
import * as fetchActions from '../actions/fetch';
import { NemoRequestData } from '../actions/nemo/types';
import * as sessionActions from '../actions/session';
import * as ACTIONS from '../constants/actions';
import * as NotificationActions from '../actions/notifications';

const INITIAL_RETRY_COUNT = 5;

export function handleNemoApiRequest(saga, action?: NemoRequestAction | NemoRequestData) {
  /* Counting request 401 failures (SPANEL-3944),
    counter is reset when the request succeeds or fails with different status than 401 */
  let retryRefreshTokentCount = INITIAL_RETRY_COUNT;

  return function* callSaga(...params) {
    if (action) {
      yield put(fetchActions.httpRequestStarted(action));
    }

    try {
      const response: APIResponse = yield call(saga, ...params);

      if (action) {
        yield put(fetchActions.httpRequestSucceeded(action, response));
      }

      retryRefreshTokentCount = INITIAL_RETRY_COUNT;

      return response;
    } catch (e) {
      /* tslint:disable:cyclomatic-complexity */
      if (e.status === 569) {
        location.reload();
        return null;
      }

      if (e.status !== 401) {
        retryRefreshTokentCount = INITIAL_RETRY_COUNT;
      }

      const retryAvailable = Boolean(retryRefreshTokentCount > 0);

      // handle unauthorized error from NEMO
      if (e.status === 401 && retryAvailable) {
        retryRefreshTokentCount = retryRefreshTokentCount - 1;

        yield put(sessionActions.refreshClientToken());

        const { tokenUpdated, tokenUpdateFailed } = yield race({
          tokenUpdated: take(ACTIONS.REFRESH_CLIENT_TOKEN_SUCCEEDED),
          tokenUpdateFailed: take(ACTIONS.REFRESH_CLIENT_TOKEN_FAILED)
        });

        if (tokenUpdated) {
          // Retry call after token is updated
          return yield call(callSaga, ...params);
        }

        if (action) {
          yield put(fetchActions.httpRequestFailed(action, new Error('Refresh client token failed.')));
        }

        return null;
      }

      if (e.status === 401 && !retryAvailable) {
        yield put(
          NotificationActions.createNotification({
            type: 'generic',
            state: 'error',
            error: {
              intlKey: 'translate.generic.notification.error.authorizing'
            }
          })
        );
      }

      // handled other errors from nemo
      if (action) {
        yield put(fetchActions.httpRequestFailed(action, e));
      }

      /* tslint:enable:cyclomatic-complexity */

      return null;
    }
  };
}
