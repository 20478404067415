import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Checkbox, Grid, RadioButton, Text } from '@siteground/styleguide';
import { Field, reduxForm } from 'redux-form';
import FormDropdown from '../../components/form-dropdown';
import { REDUX_FORM } from '../../../core/constants/common';
import { withSpanelForm } from '../sg-form-util/with-spanel-form';
import { initialFormState } from '../../../core/constants/db-forms';

type DBUser = {
  id: string;
  name: string;
  label: string;
};

type DBGrant = {
  dbuser_id: string;
  db_id: string;
};

type Props = {
  intl: Intl;
  dbUsers: DBUser[];
  dbGrant: DBGrant[];
  rowData: {
    id: string;
  };
  change: Function;
  getFormValues: Function;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.ADD_USER_TO_DB;

export class AddUserToDB extends React.Component<Props, any> {
  static defaultProps: Partial<Props> = {
    dbUsers: [],
    dbGrant: []
  };

  handleCheckboxChange = (event, index) => {
    const { change, getFormValues } = this.props;

    const { checkboxes } = getFormValues(formName);
    checkboxes[index].checked = event.target.checked;

    change('checkboxes', checkboxes);
  };

  handleRadioButtonsChange = (event) => {
    const { change, getFormValues } = this.props;

    const formValues = getFormValues(formName);

    const radiobuttons = formValues.radiobuttons.map((radiobutton) => {
      return {
        ...radiobutton,
        checked: radiobutton.value === event.target.value
      };
    });

    const checkboxes = formValues.checkboxes.map((checkbox) => {
      if (radiobuttons[0].checked) {
        return {
          ...checkbox,
          disabled: true,
          checked: true
        };
      } else if (radiobuttons[1].checked) {
        return {
          ...checkbox,
          disabled: true,
          checked: !!['SELECT'].includes(checkbox.label)
        };
      } else {
        return {
          ...checkbox,
          disabled: false,
          checked: false
        };
      }
    });

    change('radiobuttons', radiobuttons);
    change('checkboxes', checkboxes);
  };

  renderCheckboxes = () => {
    const { intl, getFormValues } = this.props;
    const { checkboxes = [] } = getFormValues(formName);

    return (
      <div>
        <Grid sm="3" gap="none">
          {checkboxes.map((c, i) => (
            <Checkbox
              key={c.label}
              name={c.label}
              data-e2e={`privileges-option-${c.label}`}
              onChange={(e) => this.handleCheckboxChange(e, i)}
              {...c}
            >
              {c.label}
            </Checkbox>
          ))}
        </Grid>
      </div>
    );
  };

  renderRadioButtons = () => {
    const { intl, getFormValues } = this.props;
    const { radiobuttons = [] } = getFormValues(formName);

    return (
      <div>
        <Text align="left" color="light" size="small">
          {intl.formatMessage({ id: 'translate.page.database.privileges' })}
        </Text>

        <Grid sm="3">
          {radiobuttons.map((radiobutton, i) => (
            <RadioButton
              key={radiobutton.value}
              name="privileges"
              onChange={this.handleRadioButtonsChange}
              value={radiobutton.value}
              data-e2e={`privileges-${radiobutton.value}`}
              {...radiobutton}
            >
              {intl.formatMessage({ id: radiobutton.label })}
            </RadioButton>
          ))}
        </Grid>
      </div>
    );
  };

  getUsersOptions = () => {
    const { dbUsers, dbGrant, rowData } = this.props;

    if (!rowData) {
      return [];
    }

    const userGrantId = dbGrant.filter((item) => item.db_id === rowData.id).map((item) => item.dbuser_id);

    const users = dbUsers.filter((user) => !userGrantId.includes(user.id));

    return users.map((user) => ({
      ...user,
      name: user.label.length > 0 ? `${user.name} (${user.label})` : user.name
    }));
  };

  render() {
    const { intl, validationUtils } = this.props;

    return (
      <React.Fragment>
        <Grid>
          <Field
            name="dbuser_id"
            label={intl.formatMessage({ id: 'translate.page.mysql.user.label' })}
            options={this.getUsersOptions()}
            optionValue="id"
            optionLabel="name"
            disableAutoSelect
            validate={[validationUtils.required]}
            component={FormDropdown}
          />
          {this.renderRadioButtons()}
          {this.renderCheckboxes()}
        </Grid>
      </React.Fragment>
    );
  }

  componentDidMount() {
    const { change } = this.props;
    change('radiobuttons', initialFormState.radiobuttons);
    change('checkboxes', initialFormState.checkboxes);
  }
}

export default withSpanelForm(
  reduxForm({
    form: formName
  })(injectIntl(AddUserToDB))
);
