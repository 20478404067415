import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../core/constants/common';
import FormInput from '../../components/form-input';
import { withSpanelForm } from '../sg-form-util/with-spanel-form';

type Props = {
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.EDIT_DB_ACCESS;

const EditDbAccess = ({ validationUtils }: Props) => {
  const intl = useIntl();
  return (
    <Grid>
      <Field
        name="addr"
        type="text"
        label={intl.formatMessage({ id: 'translate.generic.addr' })}
        validate={[validationUtils.required]}
        component={FormInput}
      />

      <Field
        name="label"
        type="text"
        label={intl.formatMessage({ id: 'translate.generic.label' })}
        component={FormInput}
      />
    </Grid>
  );
};

export default withSpanelForm(reduxForm({ form: formName })(EditDbAccess));
