import * as React from 'react';
import { injectIntl } from 'react-intl';

import {
  BorderType,
  Button,
  Card,
  Container,
  copyToClipboard,
  Grid,
  List,
  ListItem,
  Section,
  Spacer,
  Switch,
  Tab,
  Tabs,
  Text,
  Tile,
  Toolbar
} from '@siteground/styleguide';

import { navigateToUA } from '../../../core/actions/nemo-store';
import { createNotification } from '../../../core/actions/notifications';
import { openNewTabAction } from '../../../core/actions/open-new-tab';
import * as sgSiteScannerActions from '../../../core/actions/pages/sg-sitescanner';
import { requestNemoData } from '../../../core/actions/request-data';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { ToolId } from '../../../core/constants/route-info';
import { EMAIL_NOTIFICATIONS_ROW_ID, sgSiteScannerDataTypes } from '../../../core/constants/sg-sitescanner';
import {
  FilescanReportEntry,
  MalwareReportEntry,
  NormalizedReport,
  NormalizedReports
} from '../../../core/definitions/sg-sitescanner';
import { RootState } from '../../reducers';
import { findMainDomain, getCurrentSiteId } from '../../../core/selectors';
import { getToolMainEndpoint } from '../../../core/selectors/menu-items';
import { getMdetScansPerDay, isLastStatusClean, isReportStatusClean } from '../../../core/selectors/sg-sitescanner';
import { isACLActionEnabled } from '../../../core/selectors/site-meta-api';
import DateWithTime from '../../components/date-with-time';
import { HideOnMobile, ShowOnMobile } from '../../components/device';
import FeatureNotAvailable from '../../components/dialogs/feature-not-available';

import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import PartialLoader from '../../components/partial-loader';
import SGTable from '../../components/sg-table';
import { SecondLevelTitle } from '../../components/titles';
import CleanUp from './clean-up';
import { default as CreateBox, SITESCANNER_SCAN_FORM } from './create/box';
import MalwareDetailsDialog from './malware-details';
import WarningsList from './warnings-list';
import DomainSelect from '../../components/domain-select/domain-select';
import { QuotaReached } from './quota-reached';

const getHostname = (url): string => {
  if (!url) {
    return null;
  }

  const hasValidPrefix = ['https:', 'http:/'].includes(url.substring(0, 6));
  const validUrl = hasValidPrefix ? url : 'http://' + url;
  const hostname = new URL(validUrl).hostname;

  const hasWWWPrefix = 'www.' === hostname.substring(0, 4);
  return hasWWWPrefix ? hostname.substr(4) : hostname;
};

type Props = {
  currentSiteId: string;
  mainDomainName: string;
  isLastStatusClean: boolean;
  emailReportingEnabled: boolean;
  intl: Intl;
  entries: any[];
  environment: {
    isPhone: boolean;
  };
  isUAEnabled: boolean;
  reports: NormalizedReports;
  navigateToUA: typeof navigateToUA;
  requestNemoData: typeof requestNemoData;
  requestSgSitescannerData: typeof sgSiteScannerActions.requestSgSitescannerData;
  forceScan: typeof sgSiteScannerActions.forceScan;
  requestSgSitescannerReports: typeof sgSiteScannerActions.requestSgSitescannerReports;
  setSgSitescannerEmailReporting: typeof sgSiteScannerActions.setSgSitescannerEmailReporting;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  createNotification: typeof createNotification;
  openNewTabAction: typeof openNewTabAction;
  isScanInProgress: boolean;
  mdetScansPerDay: number;
};

type State = {
  isSiteScannerEnabled: boolean;
  upgradeId: string;
  tabActive: 'reports' | 'notifications' | string;
  selectedMalware: MalwareReportEntry[] | FilescanReportEntry[];
};

const SUCURI_URL = 'https://siteground.com/sucuri';

const getReportsFromToday = (reports) => {
  if (!reports) {
    return [];
  }

  return Object.values(reports).filter(({ date }) => {
    const today = new Date();
    const reportDate = new Date(date * 1000);

    return (
      reportDate.getDate() === today.getDate() &&
      reportDate.getMonth() === today.getMonth() &&
      reportDate.getFullYear() === today.getFullYear()
    );
  });
};

class SGSiteScanner extends React.Component<Props, State> {
  readonly state = {
    isSiteScannerEnabled: true,
    upgradeId: null,
    tabActive: 'reports',
    selectedMalware: null,
    scanMessage: ''
  };

  toggleEmailReporting = (enable: boolean) => {
    const { requestNemoData, setSgSitescannerEmailReporting, intl, mainDomainName } = this.props;
    const { upgradeId } = this.state;
    const endpointSuffix = enable ? 'enable' : 'disable';
    const notificationProps = { site: mainDomainName };

    const successMessage = enable
      ? 'translate.sg-sitescanner.reports.enabled.success'
      : 'translate.sg-sitescanner.reports.disabled.success';

    const errorMessage = enable
      ? 'translate.sg-sitescanner.reports.enabled.fail'
      : 'translate.sg-sitescanner.reports.disabled.fail';

    setSgSitescannerEmailReporting(upgradeId, enable, {
      type: 'generic',
      success: {
        intlKey: successMessage,
        intlValues: notificationProps
      },
      error: {
        intlKey: errorMessage,
        intlValues: notificationProps
      }
    });
  };

  handleCopyToClipboard = (value) => {
    const { intl, createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.generic.copied.to.clipboard'
        },
        error: {
          intlKey: 'translate.generic.failed.copied.to.clipboard'
        }
      });
    });
  };

  handleNavigateToUA = () => {
    const { navigateToUA, currentSiteId, isUAEnabled, openSGDialog } = this.props;

    if (isUAEnabled) {
      return navigateToUA({
        page: 'hackalert',
        siteId: currentSiteId
      });
    }

    openSGDialog(DIALOGS.FEATURE_NOT_AVAILABLE);
  };

  renderTabs = () => (
    <React.Fragment>
      <Tabs key="TABS" border="light">
        <Tab
          data-e2e="sitescaner-tab-reports"
          active={this.state.tabActive === 'reports'}
          onClick={() => this.setState({ tabActive: 'reports' })}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.sg-sitescanner.reports.tab' })}
        </Tab>
        <Tab
          data-e2e="sitescaner-tab-notifications"
          active={this.state.tabActive === 'notifications'}
          onClick={() => this.setState({ tabActive: 'notifications' })}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.sg-sitescanner.notifications.tab' })}
        </Tab>
      </Tabs>
    </React.Fragment>
  );

  renderDomainSelect = () => {
    const { entries } = this.props;

    if (entries.length < 2) {
      return;
    }

    return (
      <Grid>
        <DomainSelect
          title="translate.page.sg-sitescanner.select.url"
          selectedValue={entries[0].upgrade}
          options={entries}
          domainResourceName={API_RESOURCE.DOMAIN.resourceName}
          optionValue="upgrade"
          optionLabel="domain"
          onChange={(upgrade) => {
            if (upgrade) {
              this.props.requestSgSitescannerReports(upgrade);
            }
          }}
        />
      </Grid>
    );
  };

  renderPageContent = () => {
    const {
      isLastStatusClean,
      forceScan,
      openSGDialog,
      closeSGDialog,
      reports,
      intl,
      mainDomainName,
      environment,
      isScanInProgress,
      mdetScansPerDay
    } = this.props;

    const { upgradeId, tabActive } = this.state;
    const lastReport = Object.values(reports)[0];
    const data = reports ? Object.values(reports) : undefined;

    const reportsTableProps = {
      shadow: false,
      mobileLayout: 'card-flat',
      data,
      disableTableHead: true,
      columns: [
        {
          accessor: 'date',
          render: (date) => <DateWithTime date={date} />,
          style: !environment.isPhone ? { width: '20%' } : { width: '100%' }
        },
        {
          accessor: 'is_manual',
          render: (is_manual) =>
            intl.formatMessage({
              id: is_manual
                ? 'translate.page.sg-sitescanner.table.scan.type.manual'
                : 'translate.page.sg-sitescanner.table.scan.type.daily'
            }),
          style: !environment.isPhone ? { width: '20%' } : { width: '100%' }
        },
        {
          accessor: 'status',
          render: (status, entity: NormalizedReport) => {
            const reportStatusClean = isReportStatusClean(entity);

            if (status !== 'timeout' && reportStatusClean) {
              return (
                <List type="success">
                  <ListItem icon="success" iconSize="16">
                    {intl.formatMessage({ id: 'translate.page.sg-sitescanner.table.no-threats-found.label' })}
                  </ListItem>
                </List>
              );
            }

            return (
              <WarningsList
                icon="warning"
                iconSize="16"
                report={entity}
                onMalwareDetails={(threatReport: MalwareReportEntry[] | FilescanReportEntry[]) => {
                  this.setState(
                    {
                      selectedMalware: threatReport
                    },
                    () => openSGDialog(DIALOGS.SG_SITESCANNER_MALWARE_DETAILS)
                  );
                }}
              />
            );
          }
        },
        {
          render: () => null,
          style: { width: '0' }
        }
      ],
      resources: [
        {
          requestTypeName: customRequestTypes.GET_SITE_SCANNER_REPORTS
        },
        {
          requestTypeName: customRequestTypes.GET_SITE_SCANNER_UPGRADES
        }
      ]
    };

    const notificationsTableProps = {
      shadow: false,
      mobileLayout: 'card-flat',
      data: [{ id: EMAIL_NOTIFICATIONS_ROW_ID, notificationsEnabled: this.props.emailReportingEnabled }],
      disableTableHead: true,
      columns: [
        {
          accessor: 'elnotificationsEnabled',
          render: () => (
            <Text>{intl.formatMessage({ id: 'translate.page.sg-sitescanner.email-notifications.label' })}</Text>
          )
        },
        {
          accessor: 'notificationsEnabled',
          render: () => null,
          style: { display: environment.isPhone ? 'none' : 'table-cell' }
        },
        {
          accessor: 'notificationsEnabled',
          render: (value, entity) => (
            <Switch
              checked={value}
              data-e2e="toggle-email-reporting"
              onChange={() => this.toggleEmailReporting(!value)}
            />
          ),
          style: { textAlign: 'right' }
        }
      ],
      rowResources: [{ requestTypeName: customRequestTypes.REQUEST_SET_SG_SITESCANNER_NOTIFICATIONS }]
    };

    const tableProps = tabActive === 'reports' ? reportsTableProps : notificationsTableProps;
    const selectedEntry = this.props.entries.find((entry) => entry.upgrade === this.state.upgradeId);
    const canScanFiles: boolean = getHostname(selectedEntry?.domain) === mainDomainName;

    const forceScanOptions = {
      upgradeId,
      filescan: canScanFiles ? true : undefined
    };

    return (
      <Grid>
        {this.renderDomainSelect()}
        <CreateBox
          loaderMessage={
            isScanInProgress ? intl.formatMessage({ id: 'translate.page.sg-sitescanner.scan.spinner.message' }) : null
          }
          lastReport={lastReport}
          clean={isLastStatusClean}
          onMalwareDetails={(malware: MalwareReportEntry[]) =>
            this.setState(
              {
                selectedMalware: malware
              },
              () => openSGDialog(DIALOGS.SG_SITESCANNER_MALWARE_DETAILS)
            )
          }
          onForceScan={() => {
            if (getReportsFromToday(reports).length >= mdetScansPerDay) {
              openSGDialog(DIALOGS.SG_SITESCANNER_QUOTA_REACHED);
              return false;
            }

            forceScan(forceScanOptions, {
              type: 'form',
              formName: SITESCANNER_SCAN_FORM,
              error: {
                intlKey: 'translate.page.sg-sitescanner.scan-fail.text',
                intlValues: {
                  domain: mainDomainName
                }
              }
            });

            return true;
          }}
          onCleanUp={() => openSGDialog(DIALOGS.SG_SITESCANNER_CLEAN_UP)}
          canScanFiles={canScanFiles}
        />
        <div>
          <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.sg-sitescanner.table.title' })}</SecondLevelTitle>

          <Container padding="none">
            {this.renderTabs()}

            <Grid gap="responsive" padding="responsive">
              <Text>
                {this.props.intl.formatMessage({
                  id:
                    this.state.tabActive === 'reports'
                      ? 'translate.page.sg-sitescanner.reports.desc'
                      : 'translate.page.sg-sitescanner.notifications.desc'
                })}
              </Text>

              <ShowOnMobile>
                <SGTable {...tableProps} />
              </ShowOnMobile>
            </Grid>

            <HideOnMobile>
              <SGTable {...tableProps} />
            </HideOnMobile>
          </Container>
        </div>

        <CleanUp
          onProceed={() => {
            this.props.openNewTabAction({ url: SUCURI_URL });
            closeSGDialog(DIALOGS.SG_SITESCANNER_CLEAN_UP);
          }}
        />

        <MalwareDetailsDialog
          malwareReport={this.state.selectedMalware}
          domainName={mainDomainName}
          handleCopyToClipboard={this.handleCopyToClipboard}
        />

        <QuotaReached />
      </Grid>
    );
  };

  renderActivationCart = ({ icon, iconColor, title, text }) => {
    const { intl } = this.props;

    return (
      <Card
        iconName={icon}
        iconColor={iconColor}
        title={intl.formatMessage({ id: title })}
        text={intl.formatMessage({ id: text })}
        size="medium"
        padding="small"
        layout="vertical"
        outline="none"
        divided
      />
    );
  };

  renderActivation = () => {
    const { intl, environment } = this.props;

    const DESKTOP_TILE_BORDER: BorderType = ['none', 'small', 'none', 'none'];
    const MOBILE_TILE_BORDER: BorderType = ['none', 'none', 'small', 'none'];

    return (
      <React.Fragment>
        <FeatureNotAvailable />
        <div>
          <SecondLevelTitle>{intl.formatMessage({ id: 'translate.sg-sitescanner.activate.title' })}</SecondLevelTitle>
          <Container padding="none" tabIndex={0}>
            <Grid padding="responsive">
              <Text>{intl.formatMessage({ id: 'translate.page.sg-sitescanner.protect-your-site.desc' })}</Text>

              <Grid gap="none" sm="3">
                <Tile border={environment.isPhone ? MOBILE_TILE_BORDER : DESKTOP_TILE_BORDER} padding="none">
                  {this.renderActivationCart({
                    icon: 'product-window-search',
                    iconColor: 'royal',
                    title: 'translate.page.sg-sitescanner.daily-scans.label',
                    text: 'translate.page.sg-sitescanner.daily-scans.desc'
                  })}
                </Tile>
                <Tile border={environment.isPhone ? MOBILE_TILE_BORDER : DESKTOP_TILE_BORDER} padding="none">
                  {this.renderActivationCart({
                    icon: 'product-calendar',
                    iconColor: 'royal',
                    title: 'translate.page.sg-sitescanner.scheduled-reports.label',
                    text: 'translate.page.sg-sitescanner.scheduled-reports.desc'
                  })}
                </Tile>
                <Tile border="none" padding="none">
                  {this.renderActivationCart({
                    icon: 'product-notification-bell',
                    iconColor: 'royal',
                    title: 'translate.page.sg-sitescanner.immediate-alerts.label',
                    text: 'translate.page.sg-sitescanner.immediate-alerts.desc'
                  })}
                </Tile>
              </Grid>
            </Grid>

            <Toolbar>
              <Spacer />
              <Button color="primary" onClick={this.handleNavigateToUA}>
                {intl.formatMessage({ id: 'translate.page.sg-sitescanner.get-sitescanner.button.label' })}
              </Button>
            </Toolbar>
          </Container>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { intl } = this.props;
    const { isSiteScannerEnabled } = this.state;

    return (
      <React.Fragment>
        <PartialLoader
          hideContent
          resources={[
            { resourceName: API_RESOURCE.DOMAIN.resourceName, methods: ['GET'] },
            { requestTypeName: customRequestTypes.GET_SITE_SCANNER_UPGRADES }
          ]}
        >
          <PageHeader
            id={ToolId.sitescanner}
            instructions={intl.formatMessage({ id: 'translate.page.sg-sitescanner.tool.description' })}
          />
          <Section>{isSiteScannerEnabled ? this.renderPageContent() : this.renderActivation()}</Section>
        </PartialLoader>
      </React.Fragment>
    );
  }

  loadData = ({ siteId, mainDomainName }) => {
    this.props.requestSgSitescannerData({
      siteId,
      mainDomainName,
      onSiteScannerAvailabilityReady: (upgradeId) => {
        this.setState((state) => ({
          ...state,
          upgradeId,
          isSiteScannerEnabled: Boolean(upgradeId),
          scanMessage: ''
        }));
      }
    });
  };

  componentDidUpdate(prevProps: Props) {
    const { currentSiteId, mainDomainName } = this.props;

    const shouldRequestData = Boolean(
      currentSiteId &&
        mainDomainName &&
        (currentSiteId !== prevProps.currentSiteId || mainDomainName !== prevProps.mainDomainName)
    );

    if (shouldRequestData) {
      this.loadData({ siteId: currentSiteId, mainDomainName });
    }
  }

  componentDidMount() {
    const { mainDomainName, currentSiteId } = this.props;

    if (mainDomainName && currentSiteId) {
      this.loadData({ mainDomainName, siteId: currentSiteId });
    }
  }
}

export default indexWithCRUD(
  (state: RootState) => {
    const mainDomain = findMainDomain(state);
    const havePendingRequestsForGettingAScanStatus = (state) =>
      state.httpRequests.specified.some(
        (request) =>
          request.requestTypeName === customRequestTypes.GET_SITE_SCANNER_REPORTS_STATUS &&
          request.status === 'requested'
      );

    return {
      currentSiteId: getCurrentSiteId(state),
      mainDomainName: mainDomain && mainDomain.name,
      isLastStatusClean: isLastStatusClean(state),
      entries: state.sgSiteScanner.data[sgSiteScannerDataTypes.upgradeEntries] || [],
      reports: state.sgSiteScanner.data[sgSiteScannerDataTypes.reports] || {},
      emailReportingEnabled: Boolean(state.sgSiteScanner.data[sgSiteScannerDataTypes.emailReporting]),
      environment: state.environment,
      isUAEnabled: isACLActionEnabled(state, getToolMainEndpoint(ToolId.sitescanner), 'can_order_sitescanner'),
      isScanInProgress: havePendingRequestsForGettingAScanStatus(state),
      mdetScansPerDay: getMdetScansPerDay(state)
    };
  },
  {
    ...sgDialogActions,
    ...sgSiteScannerActions,
    navigateToUA,
    requestNemoData,
    createNotification,
    openNewTabAction
  }
)(injectIntl(SGSiteScanner), API_RESOURCE.DOMAIN);
