import * as React from 'react';
import { injectIntl } from 'react-intl';
import { SGDialog, SGDialogCancel } from '../sg-dialog';
import { DIALOGS } from '../../../core/constants/common';

export const FeatureNotAvailable = ({ intl }) => (
  <SGDialog
    id={DIALOGS.FEATURE_NOT_AVAILABLE}
    icon="warning"
    state="warning"
    title={intl.formatMessage({ id: 'translate.page.superCacher.feature-not-available-dialog.title' })}
    footer={
      <React.Fragment>
        <SGDialogCancel id={DIALOGS.FEATURE_NOT_AVAILABLE} label={intl.formatMessage({ id: 'translate.generic.ok' })} />
      </React.Fragment>
    }
  >
    {intl.formatMessage({ id: 'translate.page.superCacher.feature-not-available-dialog.text' })}
  </SGDialog>
);

export default injectIntl(FeatureNotAvailable);
