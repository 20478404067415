import { call, put, select, takeLatest } from 'redux-saga/effects';
import { siteApi } from '../../api/site';
import { ADD_SITE_MANUALLY, FETCH_ITEMS_REQUESTED } from '../../constants/actions';
import handleAvalonApiRequest from '../handle-avalon-api-request';
import { API_RESOURCE } from '../../constants/api';

function* addSiteManually(action) {
  const { path, domain_id } = action.payload;
  const state = yield select();

  const response = yield call(
    siteApi({
      endpoint: API_RESOURCE.APP.endpoint,
      method: 'POST',
      body: {
        path,
        domain_id
      },
      state
    })
  );

  yield put({
    type: FETCH_ITEMS_REQUESTED,
    payload: {
      ...API_RESOURCE.DOMAIN
    }
  });

  yield put({
    type: FETCH_ITEMS_REQUESTED,
    payload: {
      ...API_RESOURCE.DOMAIN_ALIAS
    }
  });

  return response;
}

function* WPAutoupdate(): any {
  yield takeLatest(ADD_SITE_MANUALLY, handleAvalonApiRequest(addSiteManually));
}

export default WPAutoupdate;
