import { RootState } from '../../web/reducers';
import { getCurrentSiteId } from './sites';

export const getUserLanguage = (state: RootState) =>
  state && state.session && state.session.user && state.session.user.language;

export const getTranslationsFileName = (state: RootState) => {
  const lang = getUserLanguage(state);

  switch (lang) {
    case 'it_IT':
    case 'es_ES':
    case 'de_DE':
      return lang;
    default:
      return 'en';
  }
};

export const getLanguagePrefix = (state: RootState) => {
  const lang = getUserLanguage(state);

  switch (lang) {
    case 'it_IT':
      return 'it';
    case 'es_ES':
      return 'es';
    case 'de_DE':
      return 'de';
    default:
      return 'en';
  }
};

export const getSiteToken = ({ session }: Partial<RootState>, siteId: string) =>
  session.siteTokens && session.siteTokens[siteId] && session.siteTokens[siteId].siteToken;

export const getClientToken = ({ session }: Partial<RootState>) => session.clientToken;

export const getCurrentSiteToken = (state: Partial<RootState>) => {
  if (!state) {
    return;
  }

  const siteId = getCurrentSiteId(state);

  return state.session.siteTokens && state.session.siteTokens[siteId] && state.session.siteTokens[siteId].siteToken;
};

export const userDaysAfterCreation = ({ session }: Partial<RootState>): number => {
  const user = session && session.user;

  if (!user || !user.created) {
    return 0;
  }

  const userCreation = parseInt(user.created, 10) * 1000;
  const currentTime = +new Date();

  return Math.floor((currentTime - userCreation) / 1000 / 60 / 60 / 24);
};

export const hasUserSession = (store: RootState): boolean => {
  const { session } = store;

  return Boolean(
    typeof session.clientToken === 'string' && session.clientToken.length > 0 && getCurrentSiteToken(store)
  );
};

export const getCurrencyData = ({ session }): ConnectedCurrencyProps => ({
  currencySymbol: session.user.currency_symbol,
  currencySymbolBefore: session.user.currency_symbol_before,
  taxName: session.user.tax_name,
  countryTax: session.user.countryTax,
  currency: session.user.currency
});

export const getUsername = ({ session }) => session && session.user && session.user.username;

export const getuserNames = ({ session }) => ({
  firstName: session && session.user && session.user.first_name,
  lastName: session && session.user && session.user.last_name
});
