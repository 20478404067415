import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';

const formName = REDUX_FORM.EMAIL_FOLDER_DISCARD_DIALOG;

const DiscardForm = () => {
  const intl = useIntl();
  return (
    <Field
      data-e2e="forward-update-discard_msg"
      name="discard_msg"
      type="text"
      label={intl.formatMessage({ id: 'translate.page.emailForward.discard_msg' })}
      placeholder={intl.formatMessage({ id: 'translate.page.emailForward.discard_msg' })}
      component={FormInput}
    />
  );
};

export default withSpanelForm(reduxForm({ form: formName })(DiscardForm));
