import * as React from 'react';
import { injectIntl } from 'react-intl';
import { DatePicker, Grid, TimePicker } from '@siteground/styleguide';

export const getCalendarTranslations = (intl) => ({
  backButton: intl.formatMessage({ id: 'translate.datepicker.button.back' }),
  cancelButton: intl.formatMessage({ id: 'translate.datepicker.button.cancel' }),
  okButton: intl.formatMessage({ id: 'translate.datepicker.button.ok' }),
  todayButton: intl.formatMessage({ id: 'translate.datepicker.button.today' }),
  abbreviatedDaysNames: [
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.days.name.monday' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.days.name.tuesday' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.days.name.wednesday' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.days.name.thursday' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.days.name.friday' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.days.name.saturday' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.days.name.sunday' })
  ],
  abbreviatedMonthsNames: [
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.january' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.february' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.march' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.april' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.may' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.june' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.july' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.august' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.september' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.october' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.november' }),
    intl.formatMessage({ id: 'translate.datepicker.abbreviated.months.name.december' })
  ],
  monthsName: [
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.january' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.february' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.march' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.april' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.may' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.june' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.july' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.august' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.september' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.october' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.november' }),
    intl.formatMessage({ id: 'translate.datepicker.full.months.name.december' })
  ]
});

/* tslint:disable:cyclomatic-complexity */
const FormDatePicker = ({ input, meta, placeholder, datePickerPlaceholder, timePickerPlaceholder, intl, ...props }) => {
  let status;
  let error;

  const touchedOrActive = meta.touched || meta.active;
  const touchedOrHasValue = meta.touched || Boolean(input.value);

  if (touchedOrActive && meta.valid && meta.dirty) {
    status = 'success';
  }

  if (!meta.valid && touchedOrActive && touchedOrHasValue) {
    status = 'error';
    error = meta.error;
  }

  return (
    <Grid sm="2" gap="x-small">
      <DatePicker
        state={status}
        validationMessage={error}
        placeholder={datePickerPlaceholder || intl.formatMessage({ id: 'translate.generic.date.picker.placeholder' })}
        translation={getCalendarTranslations(intl)}
        {...input}
        {...props}
        label={props.labelDate ? props.labelDate : props.label}
      />

      <TimePicker
        state={status}
        validationMessage={error}
        placeholder={timePickerPlaceholder || intl.formatMessage({ id: 'translate.generic.time.picker.placeholder' })}
        translation={{
          okButton: intl.formatMessage({ id: 'translate.generic.ok' }),
          cancelButton: intl.formatMessage({ id: 'translate.generic.cancel' })
        }}
        {...input}
        {...props}
        label={props.labelTime ? props.labelTime : props.label}
      />
    </Grid>
  );
};
/* tslint:enable:cyclomatic-complexity */

export default injectIntl(FormDatePicker);
