import { REFRESH_TOKEN_TTL_KEY } from '../constants/local-storage-keys';

export const clearTokenTTL = () => localStorage.removeItem(REFRESH_TOKEN_TTL_KEY);

export const setRefreshTokenTTLIntoLocalStorage = (expirationTimestamp) => {
  if (expirationTimestamp === undefined || expirationTimestamp === null) {
    return;
  }

  const tokenExpirationDate = +new Date(expirationTimestamp);

  localStorage.setItem(REFRESH_TOKEN_TTL_KEY, tokenExpirationDate.toString());
};
