import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ContextMenuItem, ContextMenuList, ContextMenuListProps, FocusScope } from '@siteground/styleguide';
import { openSGDialog } from '../../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { getFileMangerContextPermission, RootState } from '../../reducers';
import { triggerFileSave } from '../core/actions/code-editor';

import {
  browserFileUpload,
  clearContextEntities,
  copy,
  downloadSelectedFiles,
  extractDir,
  fetchDirWithFolderSizes,
  requestPaste
} from '../core/actions/file-manager';
import { showEntityInExplorer } from '../core/actions/search-view';
import { FILE_MANAGER_CONTEXT_MENU_TYPE } from '../core/constants/common';
import { CreateDirDialogPayload, getCreateDirDialogPayload } from '../core/selectors/get-create-dir-dialog-payload';
import { CreateFileDialogPayload, getCreateFileDialogPayload } from '../core/selectors/get-create-file-dialog-payload';
import { DeleteDialogPayload, getDeleteDialogPayload } from '../core/selectors/get-delete-dialog-payload';
import { getMoveDialogPayload, MoveDialogPayload } from '../core/selectors/get-move-dialog-payload';
import { getRenameDialogPayload, RenameDialogPayload } from '../core/selectors/get-rename-dialog-payload';
import { shouldRenderMobile } from '../core/utils';
import { CodeEditor, ContextMenuPermissions } from '../types';

type Props = {
  createDirDialogPayload: CreateDirDialogPayload;
  createFileDialogPayload: CreateFileDialogPayload;
  moveDialogPayload: MoveDialogPayload;
  deleteDialogPayload: DeleteDialogPayload;
  renameDialogPayload: RenameDialogPayload;

  browserFileUpload: typeof browserFileUpload;
  openSGDialog: typeof openSGDialog;
  copy: typeof copy;
  requestPaste: typeof requestPaste;
  extractDir: typeof extractDir;
  downloadSelectedFiles: typeof downloadSelectedFiles;
  triggerFileSave: typeof triggerFileSave;
  fetchDirWithFolderSizes: typeof fetchDirWithFolderSizes;
  showEntityInExplorer: typeof showEntityInExplorer;
  clearContextEntities: typeof clearContextEntities;
  contextContentEntities: any;

  clientX: number;
  clientY: number;
  onClose: Function;
  codeEditorFiles: any[];
  fileMangerContextPermission: ContextMenuPermissions;
  environment: any;
  codeEditor: CodeEditor;
  type: string;
};

type DispatchProps = {
  intl: Intl;
};

class FilemanagerContextMenu extends React.Component<Props & DispatchProps> {
  getContextMenuProps(): ContextMenuListProps {
    const { clientX, clientY, onClose } = this.props;

    return {
      onClose,
      position: 'fixed',
      tabIndex: 0,
      rect: {
        top: `${clientY}px`,
        left: `${clientX}px`,
        bottom: `${window.innerHeight - clientY}px`,
        right: `${window.innerWidth - clientX}px`
      }
    };
  }

  isVisible = () => {
    const { fileMangerContextPermission, environment } = this.props;
    const { isProtected } = fileMangerContextPermission;

    return environment.isDesktop ? true : !isProtected;
  };

  clearContextEntities = () => {
    if (this.props.environment.isDesktop) {
      this.props.clearContextEntities();
    }
  };

  onContextMenuClick =
    (callback) =>
    (...args) =>
      [callback(...args), this.clearContextEntities()];

  renderCreateFile = () => {
    const { createFileDialogPayload, intl, fileMangerContextPermission, openSGDialog } = this.props;
    const { canCreateFile } = fileMangerContextPermission;

    if (!canCreateFile) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="file-new"
        data-e2e="context-menu-new-file"
        onClick={this.onContextMenuClick(() =>
          openSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_FILE, createFileDialogPayload)
        )}
      >
        {intl.formatMessage({ id: 'translate.generic.new.file' })}
      </ContextMenuItem>
    );
  };

  renderCreateDir = () => {
    const { createDirDialogPayload, intl, fileMangerContextPermission, openSGDialog } = this.props;
    const { canCreateDir } = fileMangerContextPermission;

    if (!canCreateDir) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="folder-new"
        data-e2e="context-menu-new-folder"
        onClick={this.onContextMenuClick(() =>
          openSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_DIR, createDirDialogPayload)
        )}
      >
        {intl.formatMessage({ id: 'translate.generic.new.folder' })}
      </ContextMenuItem>
    );
  };

  renderUploadFile = () => {
    const { browserFileUpload, intl, fileMangerContextPermission } = this.props;
    const { canUploadFile } = fileMangerContextPermission;

    if (!canUploadFile) {
      return;
    }

    return (
      <ContextMenuItem
        icon="file-upload"
        data-e2e="context-menu-upload-file"
        onClick={this.onContextMenuClick(() => browserFileUpload({ multiple: false }))}
      >
        {intl.formatMessage({ id: 'translate.generic.file.upload' })}
      </ContextMenuItem>
    );
  };

  renderUploadFolder = () => {
    const { browserFileUpload, intl, fileMangerContextPermission } = this.props;
    const { canUploadDir } = fileMangerContextPermission;

    if (!canUploadDir) {
      return;
    }

    return (
      <ContextMenuItem
        icon="folder-upload"
        data-e2e="context-menu-upload-file"
        onClick={this.onContextMenuClick(() => browserFileUpload({ multiple: true }))}
      >
        {intl.formatMessage({ id: 'translate.generic.folder.upload' })}
      </ContextMenuItem>
    );
  };

  renderEdit = () => {
    const { intl, fileMangerContextPermission } = this.props;
    const { canEdit } = fileMangerContextPermission;

    if (!canEdit) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="edit"
        data-e2e="context-menu-edit"
        onClick={this.onContextMenuClick(() => this.props.codeEditor.openFile())}
      >
        {intl.formatMessage({ id: 'translate.generic.edit' })}
      </ContextMenuItem>
    );
  };

  renderDownload = () => {
    const { intl, fileMangerContextPermission, downloadSelectedFiles } = this.props;
    const { canDownload } = fileMangerContextPermission;

    if (!canDownload) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="download"
        data-e2e="context-menu-download"
        onClick={this.onContextMenuClick(downloadSelectedFiles)}
      >
        {intl.formatMessage({ id: 'translate.generic.download' })}
      </ContextMenuItem>
    );
  };

  renderRename = () => {
    const { intl, fileMangerContextPermission, renameDialogPayload, openSGDialog } = this.props;
    const { canRename, isProtected } = fileMangerContextPermission;

    if (!(canRename && this.isVisible())) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="rename"
        data-e2e="context-menu-rename"
        disabled={isProtected}
        onClick={this.onContextMenuClick(() =>
          openSGDialog(REDUX_FORM.FILE_MANAGER_RENAME_ENTITY, renameDialogPayload)
        )}
      >
        {intl.formatMessage({ id: 'translate.generic.rename' })}
      </ContextMenuItem>
    );
  };

  renderCopy = () => {
    const { intl, fileMangerContextPermission, copy } = this.props;
    const { canCopy } = fileMangerContextPermission;

    if (!canCopy) {
      return null;
    }

    return (
      <ContextMenuItem icon="copy" data-e2e="context-menu-copy" onClick={this.onContextMenuClick(copy)}>
        {intl.formatMessage({ id: 'translate.file.manager.copy' })}
      </ContextMenuItem>
    );
  };

  renderPaste = () => {
    const { intl, fileMangerContextPermission, requestPaste } = this.props;
    const { canPaste } = fileMangerContextPermission;

    if (!canPaste) {
      return null;
    }

    return (
      <ContextMenuItem icon="paste" data-e2e="context-menu-paste" onClick={this.onContextMenuClick(requestPaste)}>
        {intl.formatMessage({ id: 'translate.generic.paste' })}
      </ContextMenuItem>
    );
  };

  renderMove = () => {
    const { intl, moveDialogPayload, fileMangerContextPermission, openSGDialog } = this.props;
    const { canMove, isProtected } = fileMangerContextPermission;

    if (!(canMove && this.isVisible())) {
      return;
    }

    return (
      <ContextMenuItem
        icon="move"
        data-e2e="context-menu-move"
        disabled={isProtected}
        onClick={this.onContextMenuClick(() => openSGDialog(REDUX_FORM.FILE_MANAGER_MOVE_ENTITY, moveDialogPayload))}
      >
        {intl.formatMessage({ id: 'translate.generic.move' })}
      </ContextMenuItem>
    );
  };

  renderDelete = () => {
    const { intl, fileMangerContextPermission, deleteDialogPayload, openSGDialog } = this.props;
    const { canDelete, isProtected } = fileMangerContextPermission;

    if (!(canDelete && this.isVisible())) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="trash"
        data-e2e="context-menu-delete"
        disabled={isProtected}
        onClick={this.onContextMenuClick(() => openSGDialog(DIALOGS.FILE_MANAGER_DELETE, deleteDialogPayload))}
      >
        {intl.formatMessage({ id: 'translate.generic.delete' })}
      </ContextMenuItem>
    );
  };

  renderExtract = () => {
    const { intl, fileMangerContextPermission, extractDir } = this.props;
    const { canExtract } = fileMangerContextPermission;

    if (!canExtract) {
      return null;
    }

    return (
      <ContextMenuItem icon="extract" data-e2e="context-menu-extract" onClick={this.onContextMenuClick(extractDir)}>
        {intl.formatMessage({ id: 'translate.generic.extract' })}
      </ContextMenuItem>
    );
  };

  renderArchive = () => {
    const { intl, fileMangerContextPermission, openSGDialog } = this.props;
    const { canArchive } = fileMangerContextPermission;

    if (!canArchive) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="archive"
        data-e2e="context-menu-archive"
        onClick={this.onContextMenuClick(() => openSGDialog(REDUX_FORM.FILE_MANAGER_ARCHIVE))}
      >
        {intl.formatMessage({ id: 'translate.generic.archive' })}
      </ContextMenuItem>
    );
  };

  renderChangePermissions = () => {
    const { intl, environment, fileMangerContextPermission, openSGDialog } = this.props;
    const { canChangePermissions, isProtected } = fileMangerContextPermission;
    const isVisible = environment.isDesktop ? true : !isProtected;

    if (!(canChangePermissions && isVisible)) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="key"
        data-e2e="context-menu-permissions"
        disabled={isProtected}
        onClick={this.onContextMenuClick(() => openSGDialog(REDUX_FORM.FILE_MANAGER_PERMISSIONS))}
      >
        {intl.formatMessage({ id: 'translate.generic.change.permissions' })}
      </ContextMenuItem>
    );
  };

  renderShowFolderSizeItem = () => {
    const { environment, intl, fetchDirWithFolderSizes } = this.props;
    const { canShowFolderSize } = this.props.fileMangerContextPermission;

    if (shouldRenderMobile(environment) || !canShowFolderSize) {
      return null;
    }

    return (
      <ContextMenuItem icon="folder-info" data-e2e="context-show-folder-size" onClick={fetchDirWithFolderSizes}>
        {intl.formatMessage({ id: 'translate.generic.show.folder.details' })}
      </ContextMenuItem>
    );
  };

  renderShowInFolder = () => {
    const { environment, intl, contextContentEntities, showEntityInExplorer } = this.props;
    const { canShowEntityInExplorer } = this.props.fileMangerContextPermission;

    if (shouldRenderMobile(environment) || !canShowEntityInExplorer) {
      return null;
    }

    return (
      <ContextMenuItem
        icon="folder-outlined"
        data-e2e="context-show-in-explorer"
        onClick={() => showEntityInExplorer(contextContentEntities[0])}
      >
        {intl.formatMessage({ id: 'translate.generic.show.in.explorer' })}
      </ContextMenuItem>
    );
  };

  render() {
    const { clientX, clientY, type } = this.props;

    if (!clientX || !clientY) {
      return null;
    }

    let menuToRender;

    switch (type) {
      case FILE_MANAGER_CONTEXT_MENU_TYPE.BASE:
        menuToRender = this.renderBaseContextMenu();
        break;
      case FILE_MANAGER_CONTEXT_MENU_TYPE.CODE_EDITOR:
        menuToRender = this.renderCodeEditorContextMenu();
        break;
      default:
        menuToRender = this.renderSelectedEntityContextMenu();
    }

    return <FocusScope>{menuToRender}</FocusScope>;
  }

  renderBaseContextMenu() {
    return (
      <ContextMenuList {...this.getContextMenuProps()}>
        {this.renderCreateFile()}
        {this.renderCreateDir()}
        {this.renderUploadFile()}
        {this.renderUploadFolder()}
        {this.renderPaste()}
        {this.renderShowFolderSizeItem()}
      </ContextMenuList>
    );
  }

  renderCodeEditorContextMenu() {
    const { intl, codeEditor, codeEditorFiles, triggerFileSave } = this.props;

    return (
      <ContextMenuList {...this.getContextMenuProps()}>
        {this.renderCreateFile()}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem icon="save" data-e2e="context-menu-save" onClick={this.onContextMenuClick(triggerFileSave)}>
            {intl.formatMessage({ id: 'translate.generic.save' })}
          </ContextMenuItem>
        )}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem
            icon="tabs-close"
            data-e2e="context-menu-close-all-tabs"
            onClick={this.onContextMenuClick(codeEditor.closeTabsWithConfirmation)}
          >
            {intl.formatMessage({ id: 'translate.file.manager.close.all.tabs' })}
          </ContextMenuItem>
        )}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem
            icon="search"
            data-e2e="context-menu-search"
            onClick={this.onContextMenuClick(codeEditor.openFindPanel)}
          >
            {intl.formatMessage({ id: 'translate.generic.search' })}
          </ContextMenuItem>
        )}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem
            icon="replace"
            data-e2e="context-menu-replace"
            onClick={this.onContextMenuClick(codeEditor.openFindReplacePanel)}
          >
            {intl.formatMessage({ id: 'translate.generic.replace' })}
          </ContextMenuItem>
        )}

        {codeEditorFiles.length > 0 && (
          <ContextMenuItem
            icon="download"
            data-e2e="context-menu-download"
            onClick={this.onContextMenuClick(codeEditor.downloadActiveTabContent)}
          >
            {intl.formatMessage({ id: 'translate.generic.download' })}
          </ContextMenuItem>
        )}
      </ContextMenuList>
    );
  }

  renderSelectedEntityContextMenu() {
    return (
      <ContextMenuList {...this.getContextMenuProps()}>
        {this.renderEdit()}
        {this.renderDownload()}
        {this.renderRename()}
        {this.renderCopy()}
        {this.renderPaste()}
        {this.renderMove()}
        {this.renderDelete()}
        {this.renderExtract()}
        {this.renderArchive()}
        {this.renderChangePermissions()}
        {this.renderShowFolderSizeItem()}
        {this.renderShowInFolder()}
      </ContextMenuList>
    );
  }
}

const mapDispatchToProps = {
  browserFileUpload,
  clearContextEntities,
  openSGDialog,
  fetchDirWithFolderSizes,
  copy,
  requestPaste,
  triggerFileSave,
  downloadSelectedFiles,
  extractDir,
  showEntityInExplorer
};

const mapStateToProps = (state: RootState) => ({
  environment: state.environment,
  codeEditorFiles: state.fileManager.codeEditor.files,
  contextNavigationEntity: state.fileManager.contextNavigationEntity,
  contextContentEntities: state.fileManager.contextContentEntities,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  fileMangerContextPermission: getFileMangerContextPermission(state),

  createFileDialogPayload: getCreateFileDialogPayload(state),
  createDirDialogPayload: getCreateDirDialogPayload(state),
  moveDialogPayload: getMoveDialogPayload(state),
  deleteDialogPayload: getDeleteDialogPayload(state),
  renameDialogPayload: getRenameDialogPayload(state)
});

export default connect<{}, any, Partial<Props>>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FilemanagerContextMenu));
