import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, Field, FieldArray, getFormValues, reduxForm } from 'redux-form';
import { Context, ContextMenu, ContextMenuItem, Flex, Grid, IconButton, Spacer, Text } from '@siteground/styleguide';
import * as actions from '../../../../../core/actions/crud';
import { REDUX_FORM } from '../../../../../core/constants/common';
import FormDropdown from '../../../../components/form-dropdown';
import { withSpanelForm } from '../../../../components/sg-form-util/with-spanel-form';

const formName = REDUX_FORM.CHANGE_PROTECTED_USERS_DIALOG;

const attachFullPath = (items = []) =>
  items.map((obj) => ({
    fullPath: `${obj.domain_name}/${obj.path}`.replace('//', '/'),
    ...obj
  }));

class UpdateProtectedURLs extends React.Component<any, any> {
  fetchParams = {
    endpoint: '/location',
    itemName: 'location',
    resourceName: 'location'
  };

  componentDidMount() {
    this.props.actions.fetchItems(this.fetchParams);
  }

  getOptions() {
    const { getFormValues, items } = this.props;
    const formValues = getFormValues(formName);
    const { location_ids = [] } = formValues;

    if (Object.keys(formValues).length === 0) {
      return items.locationProtect;
    } else {
      const justSelectedFormValues = new Set(location_ids.filter((x) => x));

      return items.locationProtect.map((obj) => {
        obj.disabled = justSelectedFormValues.has(obj.id);
        return obj;
      });
    }
  }

  render() {
    return <FieldArray name="location_ids" component={this.renderMembers} />;
  }

  renderMembers = ({ fields }) => {
    const { intl, items } = this.props;
    const maxFieldsCount = items.locationProtect.length;
    const options = attachFullPath(this.getOptions());

    return (
      <React.Fragment>
        <Text size="small" color="light" weight="light" align="left">
          {intl.formatMessage({ id: 'translate.generic.multiple.urls' })}
        </Text>
        <Grid>
          {fields.map((locationId, index, array) => {
            return (
              <Flex key={locationId} wrap="nowrap" align="center">
                <Field
                  name={locationId}
                  type="text"
                  options={options}
                  optionValue="id"
                  optionLabel="fullPath"
                  optionDisabled="disabled"
                  searchable
                  disableAutoSelect
                  expand
                  component={FormDropdown}
                />

                <Spacer size="x-small" />

                <Context.Consumer>
                  {({ device }) =>
                    device.isPhone ? (
                      <React.Fragment>
                        <ContextMenu
                          opener={
                            <IconButton
                              aria-label={intl.formatMessage({ id: 'translate.aria-label.actions' })}
                              shape="circle"
                              icon="dots"
                            />
                          }
                        >
                          <ContextMenuItem
                            icon="circle-plus"
                            disabled={maxFieldsCount === array.length}
                            data-e2e="add"
                            onClick={() => fields.insert(index + 1, '')}
                          >
                            {intl.formatMessage({ id: 'translate.page.protected-users.manage.access.add.new' })}
                          </ContextMenuItem>

                          <ContextMenuItem
                            icon="circle-minus"
                            data-e2e="subtract"
                            onClick={() => {
                              if (index === 0 && array.length === 1) {
                                fields.insert(index + 1, '');
                              }

                              fields.remove(index);
                            }}
                          >
                            {intl.formatMessage({ id: 'translate.page.protected-users.manage.access.remove' })}
                          </ContextMenuItem>
                        </ContextMenu>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <IconButton
                          aria-label={intl.formatMessage({ id: 'translate.aria-label.minus' })}
                          icon="circle-minus"
                          shape="circle"
                          onClick={() => {
                            if (index === 0 && array.length === 1) {
                              fields.insert(index + 1, '');
                            }

                            fields.remove(index);
                          }}
                        />

                        <IconButton
                          aria-label={intl.formatMessage({ id: 'translate.aria-label.plus' })}
                          icon="circle-plus"
                          shape="circle"
                          disabled={maxFieldsCount === array.length}
                          onClick={() => fields.insert(index + 1, '')}
                        />
                      </React.Fragment>
                    )
                  }
                </Context.Consumer>
              </Flex>
            );
          })}
        </Grid>
      </React.Fragment>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions as any, dispatch)
});

const mapStateToProps = (state) => ({
  items: state.pageItems
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl(
    withSpanelForm(
      reduxForm({
        form: formName
      })(UpdateProtectedURLs)
    )
  )
) as any;
