import { RootState } from '../../../reducers';
import { getPrioritizedEntities } from './get-prioritized-entities';

export type MoveDialogPayload = {
  prioritizedEntities: any;
  selectedNavigationEntity: any;
  fileManagerState: any;
};

export const getMoveDialogPayload = (state: RootState): MoveDialogPayload => ({
  prioritizedEntities: getPrioritizedEntities(state.fileManager),
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  fileManagerState: state.fileManager
});
