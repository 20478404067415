import punycode from 'punycode';
import { formatMessage } from '../translate';
import getStore from '../store';
import { decodeJWT } from '@siteground/styleguide/lib/utils/index';

export const enum USER_ROLES {
  OWNER = 'owner',
  COLLABORATOR = 'collaborator',
  CLIENT = 'client'
}

export const getUserRole = (siteToken) => {
  const decodeToken = siteToken && decodeJWT(siteToken);
  const role = decodeToken && decodeToken.roles;

  if (role.includes(1)) {
    return USER_ROLES.OWNER;
  }

  if (role.includes(2)) {
    return USER_ROLES.COLLABORATOR;
  }

  if (role.includes(3)) {
    return USER_ROLES.CLIENT;
  }

  return null;
};

export const shouldTransformValue = ({ key, endpointName }) => {
  const store = getStore();
  const state = store?.getState();
  const punnycodeFields = state?.siteMetaApi?.types?.punnycode_fields?.fields;
  const endpoints = state?.siteMetaApi?.endpoints || {};
  const noPunyConversionForaSiteApi = endpoints[endpointName]?.input_fields[key]?.no_punny === 1;

  if (!punnycodeFields || noPunyConversionForaSiteApi) {
    return false;
  }

  return punnycodeFields.includes(key);
};

export const transformDomainName = (domain, callback) =>
  domain
    .split('.')
    .map((v) => callback(v))
    .join('.');

export const transformEmail = (domain: string, callback) =>
  domain
    .split('@')
    .map((d) => transformDomainName(d, callback))
    .join('@');

export const transformAppURL = (value, callback) => {
  const { protocol, host, pathname } = new URL(value);
  return `${protocol}//${transformDomainName(host, callback)}${pathname}`;
};

export const transformValue = ({ value, callback, key, endpointName }) => {
  if (value && value.intl_id) {
    return formatMessage({ id: value.intl_id }, value.params);
  }

  if (typeof value !== 'string') {
    return value;
  }

  if (key === 'app_url') {
    return transformAppURL(value, callback);
  }

  if (shouldTransformValue({ key, endpointName })) {
    return transformDomainName(value, callback);
  }

  return value;
};

export const traverseJSON = ({ target, callback, endpointName }) => {
  if (target === null) {
    return null;
  }

  const clonedTarget = Array.isArray(target) ? [] : {};

  Object.keys(target).forEach((key) => {
    const value = transformValue({
      value: target[key],
      key,
      callback,
      endpointName
    });

    clonedTarget[key] =
      typeof value === 'object'
        ? traverseJSON({
            target: value,
            callback,
            endpointName
          })
        : value;
  });

  return clonedTarget;
};

export function jsonResponse(response, endpointName = '') {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response
      .json()
      .then((json) => traverseJSON({ target: json, callback: punycode.toUnicode, endpointName }))
      .catch((error) => console.error(error));
  } else {
    throw Error('Backend issue occurred. Please try again later');
  }
}

export const formatRequestBodyNemo = (body: object = {}) => {
  if (Object.keys(body).length === 0) {
    return null;
  }

  const formattedBody = Object.keys(body).reduce((output, field) => {
    const isMetaField = field !== '_meta' && field.includes('_meta');

    if (isMetaField) {
      return output;
    }

    return {
      ...output,
      [field]: body[field]
    };
  }, {});

  return JSON.stringify(formattedBody);
};

export const formatRequestBodyAvalon = (body, endpointName) => {
  if (Object.keys(body).length === 0) {
    return null;
  }

  const formattedBody = Object.keys(body).reduce((output, field) => {
    const isMetaField = field !== '_meta' && field.includes('_meta');
    const modifiedOutput = traverseJSON({ target: output, callback: punycode.toASCII, endpointName });

    if (isMetaField) {
      return modifiedOutput;
    }

    return {
      ...modifiedOutput,
      [field]: body[field]
    };
  }, {});

  return JSON.stringify(formattedBody);
};
