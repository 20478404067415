import * as React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { Section } from '@siteground/styleguide';
import { ToolId } from '../../../core/constants/route-info';
import PageHeader from '../../components/page-header';
import PageTabs from '../../components/page-tabs';
import { ROUTES } from '../../../core/constants/routes';

type RedirectProps = {
  router: any;
  location: any;
  children: any;
};

export const ProtectedUrlsPage = ({ location, router, children }: RedirectProps) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <PageHeader
        id={ToolId.protected}
        instructions={intl.formatMessage({ id: 'translate.page.protected.instructions' })}
      />
      <Section>
        <PageTabs
          location={location}
          tabLabelsByTabId={{
            urls: intl.formatMessage({ id: 'translate.page.protected.urls' }),
            users: intl.formatMessage({ id: 'translate.page.protected.users' })
          }}
          routesByTabId={{
            urls: ROUTES[ToolId.protectedURLs],
            users: ROUTES[ToolId.protectedUsers]
          }}
          onNavigate={router.push}
        />
      </Section>
      <Section>{children}</Section>
    </React.Fragment>
  );
};

export default ProtectedUrlsPage;
