import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Icon, Link, Section } from '@siteground/styleguide';
import { openNewTab } from '@siteground/styleguide/lib/utils';
import { checkOrderStatus } from '../../../core/actions/nemo/actions';
import { createNotification } from '../../../core/actions/notifications';
import { requestData, requestNemoData } from '../../../core/actions/request-data';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { createTaskLoaderMetadata } from '../../../core/common/create-metadata';
import { API_RESOURCE } from '../../../core/constants/api';
import { APP_TYPES } from '../../../core/constants/application';
import { DIALOGS, LocalTaskLoaderType, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { ToolId } from '../../../core/constants/route-info';
import {
  getInstalledExtrasRequest,
  getWeeblyLoginLink,
  postOrderExtraRequest
} from '../../../core/sagas/nemo-api/requests';
import { filterStagingDomains, findMainDomain, getAutoinstalledApps, getCurrentSiteId } from '../../../core/selectors';
import { isACLActionEnabled } from '../../../core/selectors/site-meta-api';
import {
  ascendingSortByURLWithoutProtocol,
  ascendingSortByVersion,
  descendingSortByURLWithoutProtocol,
  descendingSortByVersion
} from '../../../core/utils/sort';
import DeleteInstalledApp from '../../components/common-forms/delete-installed-wp-app';
import { DeleteDialog } from '../../components/dialogs';
import AddExistingApp from '../../components/dialogs/add-existing-app';
import FeatureNotAvailable from '../../components/dialogs/feature-not-available';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../components/sg-dialog';
import SGTable from '../../components/sg-table';
import { TableHeaderSort } from '../../components/sg-table-sort';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../components/visibility-control-service';
import { RootState } from '../../reducers';
import { PLUGINS } from './constants';
import { CreateBox, CreateForm } from './create';
import { TableListedApp, WeeblyExtra } from './types';
import { formatWeeblyAppData, generateNotification, getAppName, getWeeblyAdminUrl, mapToWPFormData } from './utils';

type Props = {
  actions: CrudActions;
  intl: Intl;
  session: {
    user: { language: string };
  };
  isWeeblyUAEnabled: boolean;
  mainDomain: any;
  items: any[];
  appInstallDomains: any[];
  autoinstalledApps: any[];
  currentSiteId: string;
  requestData: typeof requestData;
  updatePageData: Function;
  requestNemoData: typeof requestNemoData;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  checkOrderStatus: typeof checkOrderStatus;
  createNotification: typeof createNotification;
};

type State = {
  selectedDomain: any;
  selectedAppType: string;
  selectedDeleteApp: {
    app: string;
    app_url: string;
    id: string;
  };
  weeblyExtra: WeeblyExtra;
};

const WORDPRESS_APP_TYPES = [APP_TYPES.WORDPRESS, APP_TYPES.WOO_COMMERCE];

class AppManager extends React.Component<Props, State> {
  readonly state = {
    selectedAppType: null,
    selectedDomain: null,
    selectedDeleteApp: null,
    weeblyExtra: null
  };

  onChangeSelectedAppType = (selectedAppType: string) => this.setState({ selectedAppType });

  onCreateFormSubmitted = ({ app, wp_starter, is_wpmu, ...formData }) => {
    const { updatePageData, actions } = this.props;
    const { selectedDomain } = this.state;

    if (formData.extra_content) {
      formData.extra_content = formData.extra_content ? 1 : 0;
    }

    formData.path_option = undefined;

    if (!formData.path) {
      formData.path = '/';
    }

    if (formData.path[0] !== '/') {
      formData.path = `/${formData.path}`;
    }

    const plugins = wp_starter ? [PLUGINS.WP_STARTER] : [];

    const meta = {
      ...createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX),
      notification: generateNotification({
        formName: REDUX_FORM.CREATE_APP_INSTALL,
        appType: app,
        domainName: selectedDomain,
        path: formData.path
      })
    };

    switch (app) {
      case APP_TYPES.WORDPRESS:
        return actions.createItem(
          {
            ...mapToWPFormData(formData),
            plugins,
            _meta: meta,
            _metaFields: {
              ...API_RESOURCE.WORDPRESS,
              formName: REDUX_FORM.CREATE_APP_INSTALL
            },
            is_wpmu: Number(Boolean(is_wpmu))
          },
          updatePageData as any
        );
      case APP_TYPES.WOO_COMMERCE:
        return actions.createItem(
          {
            ...mapToWPFormData(formData),
            plugins: [APP_TYPES.WOO_COMMERCE, ...plugins],
            theme: 'storefront',
            _meta: meta,
            _metaFields: {
              ...API_RESOURCE.WORDPRESS,
              formName: REDUX_FORM.CREATE_APP_INSTALL
            },
            is_wpmu: Number(Boolean(is_wpmu))
          },
          updatePageData as any
        );
      default:
        return actions.createItem(
          {
            ...formData,
            app,
            _meta: meta,
            _metaFields: {
              ...API_RESOURCE.AUTOINSTALL,
              formName: REDUX_FORM.CREATE_APP_INSTALL
            }
          },
          updatePageData as any
        );
    }
  };

  storeAddedWeebly = ({ added_extras = [] }) => {
    const { currentSiteId } = this.props;
    const weeblyExtra = added_extras.find(({ name }) => name === APP_TYPES.WEEBLY);

    const weeblyClassNumber = weeblyExtra?.additional_data?.class_number;

    if (weeblyClassNumber) {
      this.setState({
        weeblyExtra: {
          id: weeblyExtra.id,
          currentSiteId,
          plan: weeblyClassNumber,
          admin_url: getWeeblyAdminUrl(weeblyExtra)
        }
      });
    }
  };

  activateFreeWeebly = () => {
    const { currentSiteId, mainDomain, requestNemoData, checkOrderStatus } = this.props;

    const order = {
      order: {
        association: currentSiteId,
        class_number: 1,
        cycle: 12,
        product: APP_TYPES.WEEBLY
      }
    };

    const notification: SpanelNotification = {
      type: 'form',
      formName: REDUX_FORM.CREATE_APP_INSTALL,
      success: {
        intlKey: 'translate.page.app-installer.weebly-ordered.message'
      },
      error: {
        intlKey: 'translate.page.app-installer.weebly-order.failed',
        intlValues: {
          domain: mainDomain && mainDomain.name
        }
      }
    };

    requestNemoData(
      postOrderExtraRequest({ body: order, requestTypeName: customRequestTypes.REQUEST_INSTALL_WEEBLY }),
      (orderResponse: { id: string }) => {
        checkOrderStatus({
          orderId: orderResponse.id,
          getInstalledExtras: this.loadWeeblyData,
          clearState: () => this.setState({ ...this.state, selectedAppType: null }),
          notification
        });
      },
      (error) => {
        this.props.createNotification({
          type: 'generic',
          error: {
            intlKey: 'translate.order-weebly.failed.notification.description',
            intlValues: {
              domain: mainDomain && mainDomain.name
            }
          },
          responseError: {
            message: error.message
          }
        });
      }
    );
  };

  loadWeeblyData = () => {
    const { currentSiteId, requestNemoData } = this.props;

    requestNemoData(
      {
        ...getInstalledExtrasRequest({ siteId: currentSiteId, extraName: 'weebly_sitebuilder' }),
        requestTypeName: customRequestTypes.GET_INSTALLED_EXTRAS
      },
      this.storeAddedWeebly
    );
  };

  render() {
    const {
      intl,
      autoinstalledApps,
      appInstallDomains,
      openSGDialog,
      session,
      updatePageData,
      mainDomain,
      isWeeblyUAEnabled
    } = this.props;

    const { selectedAppType, selectedDomain, weeblyExtra } = this.state;
    const userLang = session.user && session.user.language;

    const createApiResource = WORDPRESS_APP_TYPES.includes(selectedAppType)
      ? API_RESOURCE.WORDPRESS
      : API_RESOURCE.AUTOINSTALL;

    const tableListedApps: TableListedApp[] = weeblyExtra
      ? [formatWeeblyAppData(weeblyExtra, mainDomain && mainDomain.name), ...autoinstalledApps]
      : autoinstalledApps;

    return (
      <React.Fragment>
        <PageHeader
          id={ToolId.appManager}
          instructions={
            <FormattedMessage
              id="translate.page.app-manager.info-text"
              values={{
                addExistingAppLink: (
                  <Link onClick={() => openSGDialog(REDUX_FORM.ADD_EXISTING_APP)}>
                    <FormattedMessage id="translate.generic.clicking.here" />
                  </Link>
                )
              }}
            />
          }
        />
        <Section>
          <Grid>
            <VCS resourceName={createApiResource.resourceNameMetaApi} hasMethods={['POST']}>
              <CreateBox apiResource={createApiResource}>
                <CreateForm
                  domains={appInstallDomains}
                  userLang={userLang}
                  selectedAppType={selectedAppType}
                  onSelectAppType={this.onChangeSelectedAppType}
                  onSelectDomain={(d) => this.setState({ selectedDomain: d })}
                  activeWeebly={weeblyExtra}
                  activateFreeWeebly={this.activateFreeWeebly}
                  onSubmit={this.onCreateFormSubmitted}
                />
              </CreateBox>
            </VCS>

            <SGTable
              title={intl.formatMessage({ id: 'translate.page.app-manager.table.title' })}
              resources={[
                { resourceName: API_RESOURCE.APP.resourceName, methods: ['GET'] },
                { requestTypeName: customRequestTypes.GET_INSTALLED_EXTRAS }
              ]}
              rowResources={[
                {
                  resourceName: API_RESOURCE.APP.resourceName,
                  methods: ['DELETE']
                },
                {
                  resourceName: API_RESOURCE.WORDPRESS.resourceName,
                  methods: ['DELETE']
                },
                {
                  requestTypeName: customRequestTypes.GET_WP_LOGIN_URL
                },
                {
                  requestTypeName: customRequestTypes.REQUEST_WEEBLY_LOGIN
                }
              ]}
              data={tableListedApps}
              columns={[
                {
                  header: () => (
                    <TableHeaderSort
                      label={intl.formatMessage({
                        id: 'translate.page.app-manager.manage-apps.table-head-website.title'
                      })}
                      config={{
                        ascending: (a, b) => ascendingSortByURLWithoutProtocol(a.app_url, b.app_url),
                        descending: (a, b) => descendingSortByURLWithoutProtocol(a.app_url, b.app_url)
                      }}
                    />
                  ),
                  accessor: 'app_url',
                  mSize: '55%',
                  render: (appUrl) => appUrl && appUrl.split('://').pop()
                },
                {
                  header: () => (
                    <TableHeaderSort
                      label={intl.formatMessage({
                        id: 'translate.page.app-manager.manage-apps.table-head-application.title'
                      })}
                      config={{
                        ascending: (a, b) => (getAppName(a) >= getAppName(b) ? 1 : -1),
                        descending: (a, b) => (getAppName(a) <= getAppName(b) ? 1 : -1)
                      }}
                    />
                  ),
                  accessor: 'app',
                  render: (appType: string, entity: Partial<TableListedApp>) => getAppName(entity)
                },
                {
                  header: () => (
                    <TableHeaderSort
                      label={intl.formatMessage({ id: 'translate.generic.app-manager-version' })}
                      config={{
                        ascending: (a, b) => ascendingSortByVersion(a.version, b.version),
                        descending: (a, b) => descendingSortByVersion(a.version, b.version)
                      }}
                    />
                  ),
                  accessor: 'version'
                },
                {
                  header: intl.formatMessage({ id: 'translate.generic.actions' }),
                  accessor: 'app',
                  render: (app, entity: Partial<TableListedApp>) => {
                    return (
                      <TableContextMenu
                        entity={entity}
                        resourceName={API_RESOURCE.APP.resourceName}
                        items={[
                          {
                            visibleOnDesktop: true,
                            label: intl.formatMessage({
                              id: 'translate.page.wp.manage.sg-table.action.login.admin.panel'
                            }),
                            e2eAttr: 'wp-manage-context-menu-item-log-in-admin',
                            icon: 'login',
                            shortcut: <Icon name="external-link" size="14" />,
                            onClick: () => {
                              if (WORDPRESS_APP_TYPES.includes(entity.app)) {
                                return this.props.requestData(
                                  {
                                    payload: {
                                      _metaFields: {
                                        endpoint: `${API_RESOURCE.WORDPRESS.endpoint}/${entity.id}`,
                                        method: 'PUT',
                                        itemId: entity.id
                                      },
                                      urlParams: {
                                        auto_login: 1
                                      }
                                    },
                                    requestTypeName: customRequestTypes.GET_WP_LOGIN_URL
                                  },
                                  ({ autologin_url }) => openNewTab({ url: autologin_url })
                                );
                              }

                              if (entity.isWeebly) {
                                return this.props.requestNemoData(
                                  getWeeblyLoginLink({
                                    siteId: this.props.currentSiteId,
                                    itemId: entity.id,
                                    requestTypeName: customRequestTypes.REQUEST_WEEBLY_LOGIN
                                  }),
                                  ({ loginLink }) => openNewTab({ url: loginLink })
                                );
                              }

                              openNewTab({ url: entity.admin_url });
                            }
                          },
                          {
                            vcsMethod: 'DELETE',
                            visibleOnDesktop: true,
                            label: intl.formatMessage({ id: 'translate.page.wp.manage.sg-table.action.delete' }),
                            e2eAttr: 'wp-manage-context-menu-item-delete',
                            icon: 'trash',
                            onClick: () => {
                              if (entity.isWeebly) {
                                if (isWeeblyUAEnabled) {
                                  return openSGDialog(DIALOGS.MESSAGE_DIALOG);
                                }

                                return openSGDialog(DIALOGS.FEATURE_NOT_AVAILABLE);
                              }

                              this.setState({
                                selectedDeleteApp: { app, id: entity.id, app_url: entity.app_url }
                              });

                              if (WORDPRESS_APP_TYPES.includes(entity.app)) {
                                return openSGDialog(REDUX_FORM.DELETE_INSTALLED_WP_APP);
                              }

                              openSGDialog(DIALOGS.GENERIC_DELETE);
                            }
                          }
                        ]}
                      />
                    );
                  }
                }
              ]}
              showSkeleton={this.props.items[API_RESOURCE.APP.resourceName] === undefined}
              noDataMessage="translate.page.app-manager.no-table-data.text"
            />
          </Grid>
        </Section>

        <AddExistingApp
          domain={appInstallDomains}
          title={intl.formatMessage({ id: 'translate.page.app-manager.add-existing-app.title' })}
          onApplicationAdded={updatePageData}
        />
        <FeatureNotAvailable />
        <SGDialog
          id={DIALOGS.MESSAGE_DIALOG}
          icon="warning"
          state="warning"
          title={intl.formatMessage({ id: 'translate.page.app-installer.delete-weebly.message' })}
          footer={
            <SGDialogCancel id={DIALOGS.MESSAGE_DIALOG} label={intl.formatMessage({ id: 'translate.generic.ok' })} />
          }
        />
        {this.renderAppDeleteConfirmation()}
        {this.renderDeleteWpAppConfirmation()}
      </React.Fragment>
    );
  }

  renderDeleteWpAppConfirmation = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { selectedDeleteApp } = this.state;

    const appType = selectedDeleteApp && selectedDeleteApp.app;
    const itemId = selectedDeleteApp && selectedDeleteApp.id;
    const appUrl = selectedDeleteApp && selectedDeleteApp.app_url;

    return (
      <SGDialogForm
        name={REDUX_FORM.DELETE_INSTALLED_WP_APP}
        icon="trash"
        state="inactive"
        title={intl.formatMessage({ id: 'translate.page.wp.manage.delete.title' }, { url: appUrl })}
        resources={[{ resourceName: API_RESOURCE.WORDPRESS.resourceName, methods: ['DELETE'] }]}
      >
        <DeleteInstalledApp
          selectedApplication={selectedDeleteApp}
          onSubmit={({ keep_db, keep_stagings, ...formData }: DeleteItemPayload) => {
            actions.deleteItem({
              ...formData,
              itemId,
              bodyParams: {
                delete_db: Number(!Boolean(keep_db)),
                full_delete: Number(!Boolean(keep_stagings))
              },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.page.wp.manage.delete.success.message'
                  },
                  error: {
                    intlKey: 'translate.page.wp.manage.delete.error.message'
                  }
                }
              }
            });

            closeSGDialog(REDUX_FORM.DELETE_INSTALLED_WP_APP);
          }}
        />
      </SGDialogForm>
    );
  };

  renderAppDeleteConfirmation = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { selectedDeleteApp } = this.state;

    const itemId = selectedDeleteApp && selectedDeleteApp.id;
    const appUrl = selectedDeleteApp && selectedDeleteApp.app_url;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.page.app-manager-app-delete-dialog.title' }, { site: appUrl })}
        onSubmit={() =>
          actions.deleteItem(
            {
              itemId,
              bodyParams: {
                full_delete: 1
              },
              _metaFields: { ...API_RESOURCE.APP },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.page.app-manager.delete-success.message'
                  },
                  error: {
                    intlKey: 'translate.page.app-manager.delete-fail.message',
                    intlValues: {
                      website: appUrl
                    }
                  }
                }
              }
            } as DeleteItemPayload,
            () => {
              actions.fetchItems(API_RESOURCE.APP);
              closeSGDialog(DIALOGS.GENERIC_DELETE);
            }
          )
        }
      >
        {intl.formatMessage({ id: 'translate.page.app-manager-app-delete-dialog.message' })}
      </DeleteDialog>
    );
  };

  componentDidMount() {
    this.loadWeeblyData();
  }
}

export default indexWithCRUD(
  (store: RootState) => ({
    isWeeblyUAEnabled: isACLActionEnabled(store, 'app', 'can_order'),
    currentSiteId: getCurrentSiteId(store),
    mainDomain: findMainDomain(store),
    session: store.session || {},
    appInstallDomains: filterStagingDomains(store),
    autoinstalledApps: getAutoinstalledApps(store)
  }),
  {
    ...sgDialogActions,
    requestNemoData,
    requestData,
    checkOrderStatus,
    createNotification
  }
)(AppManager, API_RESOURCE.DOMAIN, API_RESOURCE.APP);
