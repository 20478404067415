import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, getDate, getTime, Grid, Notice, Text } from '@siteground/styleguide';
import * as actions from '../../../../core/actions/crud';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS } from '../../../../core/constants/common';
import SettingListItem from '../../../components/setting-list-item';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';

class WPAutoupdateSettings extends React.Component<any, any> {
  getLastBackup = () => {
    const { appLatest } = this.props;

    const [latestApp, ...otherApps] = appLatest;

    if (latestApp) {
      const lastBackups = [...latestApp.available_backups];

      const [lastBackup, ...otherBackups] = lastBackups.sort(
        (a, b) => parseInt(b.timestamp, 10) - parseInt(a.timestamp, 10)
      );

      return lastBackup;
    }

    return null;
  };

  render() {
    const { selectedSite, intl, openSGDialog } = this.props;

    if (!selectedSite) {
      return null;
    }

    const lastBackup = this.getLastBackup();

    if (!lastBackup) {
      return null;
    }

    const timestamp = parseInt(lastBackup.timestamp, 10);

    return (
      <SettingListItem title={`${getDate(timestamp)} ${getTime(timestamp)}`} disableExpand>
        <Button
          color="secondary"
          type="outlined"
          size="small"
          onClick={() => openSGDialog(DIALOGS.WP_AUTOUPDATE_LAST_BACKUP)}
        >
          {intl.formatMessage({ id: 'translate.generic.restore' })}
        </Button>

        {this.renderRestoreDialog()}
      </SettingListItem>
    );
  }

  renderRestoreDialog() {
    const { actions, intl, selectedSite, closeSGDialog } = this.props;

    const title = intl.formatMessage(
      { id: 'translate.page.wp.autoupdate.restore.last.backup.title' },
      { domain: selectedSite.app_url }
    );

    return (
      <SGDialog
        id={DIALOGS.WP_AUTOUPDATE_LAST_BACKUP}
        title={title}
        icon="logo-wordpress"
        iconSize="60"
        onCloseHandler={() => this.setState({ restoreDialog: null })}
        resources={[
          {
            resourceName: API_RESOURCE.APP_RESTORE_BACKUP.resourceName,
            methods: ['PUT']
          }
        ]}
        footer={
          <React.Fragment>
            <SGDialogCancel id={DIALOGS.WP_AUTOUPDATE_LAST_BACKUP} />

            <Button
              color="primary"
              data-e2e="dialog-submit"
              onClick={() =>
                actions.updateItem(
                  {
                    id: selectedSite.id,
                    ...this.getLastBackup(),
                    _metaFields: {
                      ...API_RESOURCE.APP_RESTORE_BACKUP
                    },
                    _meta: {
                      notification: {
                        type: 'generic',
                        success: {
                          intlKey: 'translate.page.wp.autoupdate.restore.last.backup.success.message'
                        },
                        error: {
                          intlKey: 'translate.page.wp.autoupdate.restore.last.backup.error.message'
                        }
                      }
                    }
                  },
                  () => closeSGDialog(DIALOGS.WP_AUTOUPDATE_LAST_BACKUP)
                )
              }
            >
              {intl.formatMessage({ id: 'translate.generic.confirm' })}
            </Button>
          </React.Fragment>
        }
      >
        <Grid>
          <Notice background="light" type="warning" shadow={false} border={false}>
            {intl.formatMessage({ id: 'translate.page.wp.autoupdate.restore.last.backup.notice' })}
          </Notice>

          <Text align="left">
            {intl.formatMessage({ id: 'translate.page.wp.autoupdate.restore.last.backup.message' })}
          </Text>
        </Grid>
      </SGDialog>
    );
  }
}

const mapStateToProps = (store) => ({
  appLatest: store.pageItems.appLatest || []
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  ...bindActionCreators(sgDialogActions, dispatch)
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(WPAutoupdateSettings)) as any;
