import { createAction } from 'redux-actions';
import customRequestTypes from '../constants/custom-request-types';

import { GET_COUNTRIES, GET_COUNTRIES_SUCCEEDED } from '../constants/actions';

export const getCountries = (): NemoRequestAction => ({
  type: GET_COUNTRIES,
  requestTypeName: customRequestTypes.COUNTRIES
});

export const getCountriesSucceeded = createAction(GET_COUNTRIES_SUCCEEDED, (countries) => ({ countries }));
