import * as React from 'react';
import { Context, Dropdown, Grid, Link, Table, TableProps } from '@siteground/styleguide';
import { StatsType } from '../../../../core/definitions/stats';
import SGTable from '../../../components/sg-table';
import { getTranslations } from '../../../components/sg-table/translation';
import withLoadMore from '../../../components/with-load-more';

interface Props {
  domain: string;
  statsMissing: boolean;
  getStats: (statsKey: StatsType) => any;
  renderControls: (ControlOptions) => any;
  // with load more
  onLoadMore: (id: string) => any;
  getAllLoaded: (id: string, items: any[]) => any[];
  shouldShowLoadMore: (id: string, items: any[]) => boolean;
  intl: Intl;
}

type State = {
  selectedSource: string;
};

const SOURCES_LOAD_MORE = {
  pages: 'pages',
  errors: 'errors',
  filetypes: 'filetypes'
};

const LOAD_MORE_SETTINGS = {
  loadStep: 10,
  initialLength: 10
};

const ERRORS_MAPPING = {
  400: '400 Bad Request',
  401: '401 Unauthorized',
  402: '402 Payment Required',
  403: '403 Forbidden',
  404: '404 Not Found',
  405: '405 Method Not Allowed',
  406: '406 Not Acceptable',
  500: '407 Proxy Authentication Required',
  501: '501 Not Implemented',
  502: '502 Bad Gateway',
  503: '503 Service Unavailable',
  504: '504 Gateway Timeout',
  505: '505 HTTP Version Not Supported',
  506: '506 Variant Also Negotiates',
  507: '507 Insufficient Storage',
  508: '508 Loop Detected',
  510: '510 Not Extended',
  511: '511 Network Authentication Required'
};

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type SourceProps = Omit<Props, 'statsMissing' | 'getStats' | 'renderControls'>;

interface Data {
  isPhone: boolean;
  pagesStats: { bw: number; pages: number; url: string }[];
  errorsStats: { bw: number; error: string; hits: number }[];
  fileTypeStats: { bw: number; filetype: string; hits: number }[];
}

const SOURCES = <T extends SourceProps, K extends Data>(props: T, data: K): { [key: string]: Partial<TableProps> } => {
  const { intl, domain, shouldShowLoadMore, onLoadMore, getAllLoaded } = props;
  const { isPhone, pagesStats, errorsStats, fileTypeStats } = data;

  return {
    pages: {
      columns: [
        {
          header: intl.formatMessage({ id: 'translate.page.stats.page.label' }),
          accessor: 'url',
          render: (url) => (
            <Link href={`//${domain}${url}`} target="_blank">
              {`${domain}${url}`}
            </Link>
          )
        },
        {
          header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
          accessor: 'pages',
          style: { textAlign: isPhone ? 'left' : 'right' }
        }
      ],
      showLoadMore: shouldShowLoadMore(SOURCES_LOAD_MORE.pages, pagesStats),
      onLoadMore: () => onLoadMore(SOURCES_LOAD_MORE.pages),
      data: getAllLoaded(SOURCES_LOAD_MORE.pages, pagesStats)
    },
    errors: {
      columns: [
        {
          header: intl.formatMessage({ id: 'translate.page.stats.code.label' }),
          accessor: 'error',
          render: (errCode) => ERRORS_MAPPING[errCode] || errCode
        },
        {
          header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
          accessor: 'hits',
          style: { textAlign: isPhone ? 'left' : 'right' }
        }
      ],
      showLoadMore: shouldShowLoadMore(SOURCES_LOAD_MORE.errors, errorsStats),
      onLoadMore: () => onLoadMore(SOURCES_LOAD_MORE.errors),
      data: getAllLoaded(SOURCES_LOAD_MORE.errors, errorsStats)
    },
    filetypes: {
      columns: [
        {
          header: intl.formatMessage({ id: 'translate.page.stats.file-type.label' }),
          accessor: 'filetype',
          render: (visits) => visits
        },
        {
          header: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
          accessor: 'hits',
          style: { textAlign: isPhone ? 'left' : 'right' }
        },
        {
          header: intl.formatMessage({ id: 'translate.page.stats.bandwidth.label' }),
          accessor: 'bw',
          style: { textAlign: isPhone ? 'left' : 'right' }
        }
      ],
      showLoadMore: shouldShowLoadMore(SOURCES_LOAD_MORE.filetypes, fileTypeStats),
      onLoadMore: () => onLoadMore(SOURCES_LOAD_MORE.filetypes),
      data: getAllLoaded(SOURCES_LOAD_MORE.filetypes, fileTypeStats)
    }
  };
};

class Behaviour extends React.Component<Props, State> {
  readonly state = {
    selectedSource: 'pages'
  };

  renderContent() {
    const { getStats, intl } = this.props;

    const { selectedSource } = this.state;

    const pagesStats = getStats('pages');
    const errorsStats = getStats('errors');
    const fileTypeStats = getStats('filetypes');

    return (
      <Context.Consumer>
        {({ device }) => (
          <Table
            cellHeight="small"
            border={device.isPhone ? 'none' : 'small'}
            mobileLayout="card-flat"
            translation={getTranslations(intl)}
            {...SOURCES(this.props, {
              isPhone: device.isPhone,
              pagesStats,
              errorsStats,
              fileTypeStats
            })[selectedSource]}
          />
        )}
      </Context.Consumer>
    );
  }

  render() {
    const { renderControls, intl, statsMissing } = this.props;
    const { selectedSource } = this.state;

    const sourceOptions = [
      { label: intl.formatMessage({ id: 'translate.page.stats.most-visited-pages' }), value: 'pages' },
      { label: intl.formatMessage({ id: 'translate.page.stats.most-visited-file-type' }), value: 'filetypes' },
      { label: intl.formatMessage({ id: 'translate.page.stats.http-status-codes' }), value: 'errors' }
    ];

    return (
      <Grid gap="responsive">
        {renderControls({
          showViewChangeButtons: false,
          renderAdditionalActions: () => (
            <Dropdown
              label={intl.formatMessage({ id: 'translate.page.stats.select-source.label' })}
              size="small"
              options={sourceOptions}
              selectedValue={selectedSource}
              optionValue="value"
              optionLabel="label"
              onChange={(source) => this.setState({ selectedSource: source })}
            />
          )
        })}

        {statsMissing ? (
          <SGTable
            data={[]}
            border="small"
            shadow={false}
            noDataMessage="translate.page.stats.no-stats.placeholder.message"
          />
        ) : (
          this.renderContent()
        )}
      </Grid>
    );
  }
}

export default withLoadMore(
  Behaviour,
  {
    id: SOURCES_LOAD_MORE.errors,
    ...LOAD_MORE_SETTINGS
  },
  {
    id: SOURCES_LOAD_MORE.pages,
    ...LOAD_MORE_SETTINGS
  },
  {
    id: SOURCES_LOAD_MORE.filetypes,
    ...LOAD_MORE_SETTINGS
  }
);
