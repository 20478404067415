import { call, select, takeEvery } from 'redux-saga/effects';
import { siteApi } from '../api/site';
import { REQUEST_DATA, REQUEST_NEMO_DATA } from '../constants/actions';
import handleAvalonApiRequest from './handle-avalon-api-request';
import { handleNemoApiRequest } from './handle-nemo-api-request';
import { getNemoData } from './nemo-api/get-nemo-data';

function* handleRequestData(action: RequestDataAction) {
  const state = yield select();
  const { endpoint } = action.payload._metaFields;
  const { urlParams } = action.payload;
  const method = action.payload._metaFields.method || 'POST';

  return yield call(
    siteApi({
      endpoint,
      method,
      body: action.payload,
      state,
      urlParams
    })
  );
}

function* handleRequestNemoData(action: NemoRequestAction) {
  const { payload, onComplete } = action;
  const { endpoint, method, body } = payload;

  yield call(handleNemoApiRequest(getNemoData, action), { method, body, endpoint });
}

function* genericRequests(): any {
  yield takeEvery(REQUEST_DATA, handleAvalonApiRequest(handleRequestData));
  yield takeEvery(REQUEST_NEMO_DATA, handleRequestNemoData);
}

export default genericRequests;
