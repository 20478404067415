export const ascendingSortByProperty = (propertyToSort) => (a, b) => a[propertyToSort] >= b[propertyToSort] ? 1 : -1;
export const descendingSortByProperty = (propertyToSort) => (a, b) => a[propertyToSort] <= b[propertyToSort] ? 1 : -1;

export const sortByPercentileUsageDescending = (propertyToSort) => {
  return (a, b) => {
    const usedQuotaSizeA = a.used_size ? a.used_size : 1;
    const usedQuotaSizeB = b.used_size ? b.used_size : 1;

    if (usedQuotaSizeA || usedQuotaSizeB) {
      return Math.floor((usedQuotaSizeA / a.quota_size) * 100) <= Math.floor((usedQuotaSizeB / b.quota_size) * 100)
        ? 1
        : -1;
    }

    return 1;
  };
};

export const sortByPercentileUsageAscending = (propertyToSort) => {
  return (a, b) => {
    const usedQuotaSizeA = a.used_size ? a.used_size : 1;
    const usedQuotaSizeB = b.used_size ? b.used_size : 1;

    if (usedQuotaSizeA || usedQuotaSizeB) {
      return Math.floor((usedQuotaSizeA / a.quota_size) * 100) >= Math.floor((usedQuotaSizeB / b.quota_size) * 100)
        ? 1
        : -1;
    }

    return 1;
  };
};

export const ascendingSortByURLWithoutProtocol = (a, b) =>
  a.replace(/https:\/\/|http:\/\//gi, '') >= b.replace(/https:\/\/|http:\/\//gi, '') ? 1 : -1;
export const descendingSortByURLWithoutProtocol = (a, b) =>
  a.replace(/https:\/\/|http:\/\//gi, '') <= b.replace(/https:\/\/|http:\/\//gi, '') ? 1 : -1;

export const ascendingSortByVersion = (a, b) => {
  if (!b) {
    return 1;
  }

  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
};

export const descendingSortByVersion = (a, b) => {
  if (!b) {
    return 1;
  }

  return b.localeCompare(a, undefined, { numeric: true, sensitivity: 'base' });
};

export const ascendingSortByInodes = (a, b) => {
  const inodeA = a === undefined ? 0 : a;
  const inodeB = b === undefined ? 0 : b;

  return inodeA >= inodeB ? 1 : -1;
};

export const descendingSortByInodes = (a, b) => {
  const inodeA = a === undefined ? 0 : a;
  const inodeB = b === undefined ? 0 : b;

  return inodeA <= inodeB ? 1 : -1;
};

export const ascendingSortSubDomainNames = (a, b) => {
  if (b.indexOf('*.') === 0) {
    return 1;
  }

  return a >= b ? 1 : -1;
};

export const descendingSortSubDomainsNames = (a, b) => {
  if (b.indexOf('*.') === 0) {
    return 1;
  }

  return a <= b ? 1 : -1;
};
