import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Button, Column, Flex, Grid, Label, Notice, Text } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { DOMAIN_TYPES } from '../../../../core/constants/domains';
import FormDropdown from '../../../components/form-dropdown';
import FormTextarea from '../../../components/form-textarea';
import { SGForm } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';
import { PREMIUM_LABEL } from '../constants';

const PREMIUM_SSL = 'premium';
const PREMIUM_LETS_ENCRYPT_WILDCARD = 1;

type Domain = {
  id: string;
  name: string;
  sg_dns: boolean;
  domain_type: string;
  cert_id: number;
};

type Props = {
  intl: Intl;
  form: string;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: Function;
  selectedDomainId: string;
  selectedCertificates: {
    info: string;
    label: string;
  }[];
  mainDomainSelectedCertificates: {
    info: string;
    label: string;
  }[];
  ssl: any;
  domainSelectOptions: Domain[];
  formValues: Function;
  change: Function;
  onChangeDomain: (domainId: string) => any;
  openSGDialog: Function;
  goToUA: Function;
  getFormValues: Function;
  isUAEnabled: boolean;
};

const cleanDuplicates = (certs: any[]): any[] => Array.from(new Set(certs));

class Create extends React.Component<Props, any> {
  componentDidUpdate(prevProps) {
    const { formValues, selectedDomainId, change, form } = this.props;
    const values = formValues(form);

    if (form === REDUX_FORM.CREATE_INSTALL_SSL && !values.domain_id && selectedDomainId) {
      change('domain_id', selectedDomainId);
    }
  }

  hasCertLetsEncrypt(certificates) {
    if (!certificates) {
      return false;
    }

    return certificates.find((cert) => {
      if (!cert || !cert.info || !cert.info.cert) {
        return false;
      }

      return cert.info.cert.le === 1;
    });
  }

  hasCertLetsEncryptWildcard(certificates) {
    if (!certificates) {
      return false;
    }

    return certificates.find((cert) => {
      if (!cert || !cert.info || !cert.info.cert) {
        return false;
      }

      return cert.info.cert.wildcard === 1 && cert.label !== PREMIUM_LABEL;
    });
  }

  hasCertPremium(certificates) {
    if (!certificates) {
      return false;
    }

    return certificates.find((cert) => {
      if (!cert) {
        return false;
      }

      return cert.label === PREMIUM_LABEL;
    });
  }

  isSubDomain = (id) => {
    const { domainSelectOptions = [] } = this.props;

    return domainSelectOptions.find((d) => d.id === id && d.domain_type === DOMAIN_TYPES.sub_domain);
  };

  isSelectedDomainSub = () => {
    const { getFormValues, form } = this.props;
    const formValues = getFormValues(form);

    return this.isSubDomain(formValues.domain_id);
  };

  onChangeDomain = (ev, domainId) => {
    const { form, change, onChangeDomain } = this.props;

    if (form === REDUX_FORM.CREATE_INSTALL_SSL) {
      change('generate', null);
    }

    onChangeDomain(domainId);
  };

  renderSubmitInstall = ({ hasCertPremium, shouldShowNotHostedNotice, isPremiumSslSelected, selectedDomain }) => {
    const { intl, isUAEnabled, goToUA } = this.props;

    switch (true) {
      case shouldShowNotHostedNotice:
        return (
          <Notice
            type="info"
            title={intl.formatMessage({ id: 'translate.page.ssl.domain.not.hosted.title' })}
            background="light"
            shadow={false}
          >
            {intl.formatMessage(
              { id: 'translate.page.ssl.domain.not.hosted.message' },
              { domain: selectedDomain.name }
            )}
          </Notice>
        );
      case Boolean(hasCertPremium):
        return (
          <Notice type="info" background="light" shadow={false}>
            {intl.formatMessage({ id: 'translate.page.ssl.install.premium.text' })}
          </Notice>
        );
      case Boolean(!isUAEnabled && isPremiumSslSelected):
        return (
          <Notice type="info" background="light" shadow={false}>
            {intl.formatMessage({ id: 'translate.page.ssl.install.premium.no.ua.text' })}
          </Notice>
        );
      default:
        return (
          <Button
            color="primary"
            data-e2e={isPremiumSslSelected ? 'create-box-submit-upgrade-to-plus' : 'create-box-submit'}
            onClick={(e) => {
              if (isPremiumSslSelected) {
                e.preventDefault();
                goToUA();
              }
            }}
          >
            {intl.formatMessage({
              id: isPremiumSslSelected ? 'translate.page.ssl.order.ssl' : 'translate.page.ssl.get.ssl'
            })}
          </Button>
        );
    }
  };

  renderInstallBoxContent = () => {
    const {
      domainSelectOptions,
      form,
      intl,
      selectedCertificates,
      mainDomainSelectedCertificates,
      validationUtils,
      selectedDomainId,
      getFormValues,
      ssl
    } = this.props;
    const { required } = validationUtils;

    const formValues = getFormValues(form);
    const { generate } = formValues;
    const isPremiumSslSelected = generate === PREMIUM_SSL;

    const hasCertLetsEncrypt = this.hasCertLetsEncrypt(selectedCertificates);
    const hasCertWildCard = this.hasCertLetsEncryptWildcard(selectedCertificates);
    const mainDomainHasCertWildCard = this.hasCertLetsEncryptWildcard(mainDomainSelectedCertificates);
    const hasCertPremium = this.hasCertPremium(selectedCertificates);

    const selectedDomain = domainSelectOptions.find((d) => d.id === selectedDomainId);
    const isDomainHostedWithUs = selectedDomain && selectedDomain.sg_dns;
    const shouldShowNotHostedNotice = formValues.generate === PREMIUM_LETS_ENCRYPT_WILDCARD && !isDomainHostedWithUs;

    let dropdownOptions: any = [
      {
        label: intl.formatMessage({ id: 'translate.page.ssl.lets.encrypt' }),
        value: 0,
        current: hasCertLetsEncrypt,
        disabled: hasCertPremium || hasCertLetsEncrypt
      }
    ];
    const isSelectedDomainMain = selectedDomain && selectedDomain.domain_type === 'main_domain';
    const subDomainCertificates = ssl && selectedDomain && ssl.find((info) => info.id === selectedDomain.cert_id);
    const subDomainHasLE =
      subDomainCertificates &&
      subDomainCertificates.info &&
      subDomainCertificates.info.cert &&
      subDomainCertificates.info.cert.le;

    const wildCardCert = {
      label: intl.formatMessage({ id: 'translate.page.ssl.lets.encrypt.wildcard' }),
      value: PREMIUM_LETS_ENCRYPT_WILDCARD,
      current: hasCertWildCard,
      disabled: hasCertPremium || hasCertWildCard
    };

    if (!isSelectedDomainMain && mainDomainHasCertWildCard && !subDomainHasLE) {
      dropdownOptions = [
        {
          label: intl.formatMessage({ id: 'translate.page.ssl.lets.encrypt' }),
          value: 0,
          current: !hasCertWildCard,
          disabled: hasCertPremium || !hasCertWildCard
        },
        wildCardCert
      ];
    }

    if (!this.isSelectedDomainSub()) {
      dropdownOptions = cleanDuplicates([
        ...dropdownOptions,
        wildCardCert,
        {
          label: intl.formatMessage({ id: 'translate.page.ssl.premium.wildcard' }),
          value: PREMIUM_SSL,
          current: hasCertPremium,
          disabled: hasCertPremium
        }
      ]);
    }

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            disableAutoSelect
            name="domain_id"
            type="text"
            optionValue="id"
            optionLabel="name"
            options={domainSelectOptions}
            label={intl.formatMessage({ id: 'translate.page.ssl.select.domain' })}
            onChange={this.onChangeDomain}
            validate={[required]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="generate"
            label={intl.formatMessage({ id: 'translate.page.ssl.select.ssl' })}
            disableAutoSelect
            optionValue="value"
            optionLabel="label"
            optionDisabled="disabled"
            options={dropdownOptions}
            disabled={this.props.selectedDomainId === null || this.props.selectedDomainId === undefined}
            template={(option, { isOption }) => {
              if (!isOption) {
                return option.label;
              }

              return (
                <Flex align="center" justify="space-between" wrap="nowrap">
                  <Text truncate style={{ lineHeight: 'inherit' }}>
                    {option.label}
                  </Text>

                  {option.current && (
                    <Label color="royal">{intl.formatMessage({ id: 'translate.generic.current' })}</Label>
                  )}
                </Flex>
              );
            }}
            validate={[required]}
            component={FormDropdown}
          />
        </Column>

        <Column key="button" smSpan="12">
          {this.renderSubmitInstall({
            hasCertPremium,
            shouldShowNotHostedNotice,
            isPremiumSslSelected,
            selectedDomain
          })}
        </Column>
      </React.Fragment>
    );
  };

  renderImportBoxContent() {
    const { intl, validationUtils, sPanelFormSubmit, form } = this.props;
    const { required, validBase64Encode, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column key="content" smSpan="12">
          <Grid>
            <Field
              name="cert"
              type="text"
              label={intl.formatMessage({ id: 'translate.page.ssl.external.crt' })}
              rows="4"
              data-e2e="ssl-cert"
              validate={[required, validBase64Encode, validationWithMetaApi]}
              component={FormTextarea}
            />

            <Field
              name="key"
              type="text"
              label={intl.formatMessage({ id: 'translate.page.ssl.external.key' })}
              rows="4"
              data-e2e="ssl-key"
              validate={[required, validBase64Encode, validationWithMetaApi]}
              component={FormTextarea}
            />

            <Field
              name="cabundle"
              type="text"
              label={intl.formatMessage({ id: 'translate.page.ssl.external.cabundle' })}
              rows="4"
              data-e2e="ssl-cabundle"
              validate={[validBase64Encode, validationWithMetaApi]}
              component={FormTextarea}
            />
          </Grid>
        </Column>

        <Column key="button" smSpan="12">
          <Button
            color="primary"
            action="button"
            data-e2e="create-box-submit"
            onClick={(e) => sPanelFormSubmit(form)(e)}
          >
            {intl.formatMessage({ id: 'translate.page.ssl.import' })}
          </Button>
        </Column>
      </React.Fragment>
    );
  }

  renderCreateBoxTabContent() {
    const { form } = this.props;

    switch (form) {
      case REDUX_FORM.CREATE_INSTALL_SSL:
        return this.renderInstallBoxContent();
      case REDUX_FORM.CREATE_SSL_IMPORT:
        return this.renderImportBoxContent();
      default:
        return null;
    }
  }

  render() {
    const { sPanelFormSubmit, form } = this.props;

    return <SGForm onSubmit={sPanelFormSubmit(form)}>{this.renderCreateBoxTabContent()}</SGForm>;
  }
}

const createFormToExport = (formName: string) => {
  const API = formName === REDUX_FORM.CREATE_INSTALL_SSL ? API_RESOURCE.SSL_LE : API_RESOURCE.SSL;

  return withSpanelForm(
    reduxForm({
      form: formName,
      initialValues: {
        _metaFields: { ...API }
      }
    })(injectIntl<any, any>(Create))
  );
};

export const CreateFormInstall = createFormToExport(REDUX_FORM.CREATE_INSTALL_SSL);
export const CreateFormImport = createFormToExport(REDUX_FORM.CREATE_SSL_IMPORT);
