import * as React from 'react';
import { Switch } from '@siteground/styleguide';
import { disableAllDomainCache } from '../../../core/actions/domainCache';
import { requestData } from '../../../core/actions/request-data';
import { API_RESOURCE } from '../../../core/constants/api';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { RootState } from '../../reducers';
import indexWithCRUD from '../../components/indexWithCRUD';
import SGTable from '../../components/sg-table';
import VCS from '../../components/visibility-control-service';

interface DomainCacheProps {
  actions: CrudActions;
  disableAllDomainCache: typeof disableAllDomainCache;
  items: any;
  location: any;
  intl: Intl;
  requestData: typeof requestData;
}

class DomainCachePage extends React.Component<DomainCacheProps, any> {
  readonly state = {
    currentUpdatePayload: null
  };

  render() {
    if (!this.props.items.domainAll) {
      return null;
    }

    return this.renderDomainCacheItems();
  }

  renderDomainCacheItems = () => {
    const { intl, items } = this.props;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.host' }),
        accessor: 'name',
        mSize: '75%'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'settings',
        render: this.renderCacheToggle
      }
    ];

    return (
      <VCS resourceName={API_RESOURCE.DOMAIN_ALL.resourceNameMetaApi} hasMethod="GET">
        <SGTable
          addOffsetOnMobile
          shadow={false}
          mobileLayout="card-flat"
          data={items.domainAll}
          columns={columns}
          resources={[
            { resourceName: API_RESOURCE.DOMAIN_ALL.resourceName, methods: ['GET'] },
            { requestTypeName: customRequestTypes.REQUEST_DATA }
          ]}
          rowResources={[{ resourceName: API_RESOURCE.DOMAIN_ALL.resourceName, methods: ['PUT'] }]}
        />
      </VCS>
    );
  };

  renderCacheToggle = (_, entity) => {
    const { actions } = this.props;

    return (
      <VCS resourceName={API_RESOURCE.DOMAIN_ALL.resourceNameMetaApi} hasMethod="PUT">
        <Switch
          checked={entity.settings.static_direct}
          onChange={(event) => {
            const cacheEnabled = Boolean(event.target.checked);

            actions.updateItem({
              id: entity.id,
              settings: { static_direct: cacheEnabled ? 1 : 0 },
              _metaFields: {
                resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
                endpoint: API_RESOURCE.DOMAIN_ALL.endpoint
              },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: cacheEnabled
                      ? 'translate.page.superCacher.nginx.enable.success.notification'
                      : 'translate.page.superCacher.nginx.disable.success.notification',
                    intlValues: { domain: entity.name }
                  },
                  error: {
                    intlKey: cacheEnabled
                      ? 'translate.page.superCacher.nginx.enable.fail.notification'
                      : 'translate.page.superCacher.nginx.disable.fail.notification',
                    intlValues: { domain: entity.name }
                  }
                }
              }
            });
          }}
        />
      </VCS>
    );
  };
}

const mapStateToProps = (state: RootState) => ({});

export default indexWithCRUD(mapStateToProps, { requestData, disableAllDomainCache })(
  DomainCachePage,
  API_RESOURCE.DOMAIN_ALL
);
