import * as React from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@siteground/styleguide';

import { DIALOGS } from '../../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import { getUserRole, USER_ROLES } from '../../../../core/api/utils';

type Props = {
  siteToken: any;
};

const CNDActivatePaidCF: React.FC<Props> = ({ siteToken }) => {
  const intl = useIntl();

  const userRole = getUserRole(siteToken);

  let description;

  switch (userRole) {
    case USER_ROLES.OWNER:
      description = intl.formatMessage({ id: 'translate.page.cdn.cloudflare.cancellation.needed.description' });
      break;
    case USER_ROLES.COLLABORATOR:
      description = intl.formatMessage({ id: 'translate.page.cdn.cloudflare.cancellation.needed.collaborator' });
      break;
    case USER_ROLES.CLIENT:
      description = intl.formatMessage({ id: 'translate.page.cdn.cloudflare.cancellation.needed.contact.service' });
      break;
  }

  return (
    <SGDialog
      id={DIALOGS.CDN_ACTIVATE_PAID_CF}
      title={intl.formatMessage({ id: 'translate.page.cdn.cloudflare.cancellation.needed' })}
      icon="warning"
      state="warning"
      footer={
        <SGDialogCancel
          id={DIALOGS.CDN_ACTIVATE_PAID_CF}
          label={intl.formatMessage({ id: 'translate.generic.close' })}
        />
      }
    >
      <Text>{description}</Text>
    </SGDialog>
  );
};

export default CNDActivatePaidCF;
