import * as React from 'react';
import './setting-list.scss';

import { Flex, Text } from '@siteground/styleguide';

const SettingsList = ({ children, title }) => {
  return (
    <div data-e2e="settings-list">
      {title && (
        <Flex className="setting-list-title" align="center" gutter="medium" background="light">
          <Text color="dark" weight="bold">
            {title}
          </Text>
        </Flex>
      )}
      {children}
    </div>
  );
};

export default SettingsList;
