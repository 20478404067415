import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  Avatar,
  BannerNotification,
  Context,
  ContextMenu,
  ContextMenuItem,
  DrawerIcon,
  Header,
  Icon,
  Spacer,
  Text,
  Title
} from '@siteground/styleguide';

import { decodeJWT } from '@siteground/styleguide/lib/utils';
import * as sessionActions from '../../../../core/actions/session';
import { hasUserSession } from '../../../../core/selectors';
import { isResellerLoginRoute } from '../../../../core/utils/routes';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import { redirectToURL } from '../../../../core/utils/redirect-to-url';
import SessionReady from '../../../components/session-ready';
import SupportPopup from '../../../components/support-popup';
import SiteSelect from '../site-select';
import './sg-header.scss';

class SGHeader extends React.PureComponent<any, any> {
  renderHeaderContextMenuItems = () => {
    const { intl, logout } = this.props;

    return (
      <React.Fragment>
        {CONFIG.IS_RESELLER && (
          <ContextMenuItem icon="profile" onClick={() => redirectToURL({ pathname: ROUTES[ToolId.profile] })}>
            <Text>{intl.formatMessage({ id: 'translate.sg-header.profile' })}</Text>
          </ContextMenuItem>
        )}

        <ContextMenuItem icon="palette" onClick={() => redirectToURL({ pathname: ROUTES[ToolId.appearance] })}>
          <Text>{intl.formatMessage({ id: 'translate.sg-header.appearance' })}</Text>
        </ContextMenuItem>

        <ContextMenuItem icon="cross" onClick={logout}>
          <Text>{intl.formatMessage({ id: 'translate.sg-header.logout' })}</Text>
        </ContextMenuItem>
      </React.Fragment>
    );
  };

  renderAvatar = () => {
    const { session, device, sessionReady, intl, routes } = this.props;

    if (device.isPhone) {
      return null;
    }

    const staticURL = CONFIG.STATIC_URL + '/';
    const avatarURL = !session.user.avatar
      ? undefined
      : session.user.avatar.startsWith('https://')
      ? session.user.avatar
      : `${staticURL}${session.user.avatar}`;
    const firstName = session.user.first_name || '';
    const lastName = session.user.last_name || '';

    if (CONFIG.IS_RESELLER) {
      if (!isResellerLoginRoute(routes)) {
        return (
          <ContextMenu role={null} opener={<Icon name="product-avatar" size="40" className="sg-avatar" />}>
            {this.renderHeaderContextMenuItems()}
          </ContextMenu>
        );
      }

      return null;
    }

    return (
      <ContextMenu
        role={null}
        opener={
          <Avatar
            className="sg-avatar"
            firstName={firstName}
            lastName={lastName}
            src={avatarURL}
            role="menu"
            aria-label={intl.formatMessage({ id: 'translate.aria-label.profile.menu' })}
          />
        }
      >
        {this.renderHeaderContextMenuItems()}
      </ContextMenu>
    );
  };

  render() {
    const { device, intl, open, session, toggleDrawer, sessionReady, routes } = this.props;
    const isDemoClient = decodeJWT(session.clientToken).demo;

    const shouldRenderDrawer = Boolean(sessionReady || Boolean(device.isPhone && !isResellerLoginRoute(routes)));

    const getDemoBannerMessage = () =>
      device.isPhone ? 'translate.demo.account.mobile.message' : 'translate.demo.account.desktop.message';

    return (
      <React.Fragment>
        {isDemoClient && (
          <BannerNotification
            type="warning"
            title={intl.formatMessage({ id: getDemoBannerMessage() })}
            density="small"
          />
        )}
        <Header className="sp-header">
          <div className="sp-header-wrapper">
            <div className="sp-header-logo">
              <Spacer size="xx-small" />

              {shouldRenderDrawer && (
                <DrawerIcon className="sg-header-drawer-icon" open={open} onClick={toggleDrawer} />
              )}

              {CONFIG.IS_RESELLER ? (
                <React.Fragment>
                  {!device.isPhone && (
                    <Title color="white" weight="medium" style={{ marginLeft: '10px' }} level="3" density="none">
                      {intl.formatMessage({ id: 'translate.header.reseller-panel.title' })}
                    </Title>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Spacer size="x-small" />

                  <Icon
                    name={device.isPhone ? 'logo-siteground-compact' : 'logo-siteground'}
                    width={device.isPhone ? '30' : '140'}
                    height={device.isPhone ? '30' : '40'}
                    multicolor
                  />

                  <Spacer size="x-small" />
                </React.Fragment>
              )}
            </div>

            <SessionReady showOnSiteTokenFailed>
              <SiteSelect />
            </SessionReady>

            <Spacer />

            <SessionReady>
              <SupportPopup isReseller={CONFIG.IS_RESELLER} />
              <Spacer size="x-small" />
            </SessionReady>

            {this.renderAvatar()}
          </div>
        </Header>
      </React.Fragment>
    );
  }
}

const SGHeaderWithDeviceInfo = (props) => (
  <Context.Consumer>{({ device }) => <SGHeader device={device} {...props} />}</Context.Consumer>
);

const mapStateToProps = (state) => ({
  sessionReady: hasUserSession(state),
  session: state.session
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(sessionActions.logoutUser())
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(SGHeaderWithDeviceInfo)));
