import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';
import FilterFields from '../filter-fields';

type Props = {
  change: Function;
  getFormValues: (formName) => any;
  isDomainLevelFilter: boolean;
  selectedFilterEntity: any;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CHANGE_EMAIL_FILTERS_DIALOG;

const ChangeFields = ({ change, getFormValues, isDomainLevelFilter, selectedFilterEntity, validationUtils }: Props) => (
  <Grid>
    <FilterFields
      isDomainLevelFilter={isDomainLevelFilter}
      change={change}
      values={getFormValues(formName)}
      validationUtils={validationUtils}
      selectedFilterEntity={selectedFilterEntity}
    />
  </Grid>
);

export default withSpanelForm(reduxForm({ form: formName })(ChangeFields));
