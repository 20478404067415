import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

export const initialState: Sites = {
  items: [],
  loading: true,
  currentDomainName: null,
  currentSiteID: null
};

export default handleActions<any, any>(
  {
    [Actions.STORE_SITES]: (state, { payload }) => {
      const currentSite = payload.sites.find((item) => item.id === payload.siteId) || {};

      return {
        items: payload.sites,
        loading: false,
        currentDomainName: currentSite.domain,
        currentSiteID: currentSite.id
      };
    },
    [Actions.LOGOUT]: () => initialState
  },
  initialState
);
