import * as StatsTypes from '../definitions/stats';
import { formatUserAgentName } from './format-user-agent-name';
import { BYTES_IN_MB, BYTES_IN_KB } from '../constants/common';

const KNONW_ENGINES = {
  google: 'Google',
  yahoo: 'Yahoo',
  yandex: 'Yandex'
};

const VISIBLE_ITEMS_LIMIT = 8;

export const bytesToMB = (bytes: number) => divideNumbers(bytes, BYTES_IN_MB);
export const KBToMB = (bytes: number) => divideNumbers(bytes, BYTES_IN_KB);
export const divideNumbers = (a, b, parse = parseFloat) => parse((a / b).toFixed(2));

export const getDateId = (seconds: number): string => {
  const date = new Date(seconds * 1000);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return `${month}${date.getFullYear()}`;
};

export const formatCountryStats = (country: StatsTypes.Countries[] = []) => {
  const sorted = sortByValue(country, 'hits');

  if (sorted.length > VISIBLE_ITEMS_LIMIT) {
    const visible = sorted.splice(0, VISIBLE_ITEMS_LIMIT);

    const other = sorted.reduce(
      (output, item) => ({
        ...output,
        bw: output.bw + item.bw,
        hits: output.hits + item.hits,
        pages: output.pages + item.pages
      }),
      {
        country: 'other',
        bw: 0,
        hits: 0,
        pages: 0
      }
    );

    return [...visible, other];
  }

  return sorted;
};

export const sortTechData = (data = [], labelKey = 'id', valueKey = 'hits') => {
  const sorted = sortByValue(data, valueKey);

  if (sorted.length > VISIBLE_ITEMS_LIMIT) {
    const visible = sorted.splice(0, VISIBLE_ITEMS_LIMIT);

    const other = sorted.reduce(
      (output, item) => ({
        ...output,
        [valueKey]: output[valueKey] + item[valueKey]
      }),
      {
        [labelKey]: 'other',
        [valueKey]: 0
      }
    );

    return [...visible, other];
  }

  return sorted;
};

export const formatDaysStats = (days: StatsTypes.Days[] = []) =>
  days.map(({ bw, visits, pages, date, ...other }) => ({
    bw,
    visits,
    pages,
    date,
    bwInMB: bytesToMB(bw),
    pagesPerVisit: divideNumbers(pages, visits, parseInt),
    ...other
  }));

export const fulfillAllMonthDays = (days: StatsTypes.Days[] = [], dateId: string) => {
  const allDaysMap: { [dateId: string]: StatsTypes.Days } = {};

  days.forEach((day) => {
    const date = convertDateToApiFormat(new Date(day.date));
    allDaysMap[date] = day;
  });

  const dateUTC = getDateReadyFormatUTC(dateId);
  const numberOfDays = new Date(dateUTC.year, dateUTC.month, 0).getDate();

  const dates = Array(numberOfDays)
    .fill(0)
    .reduce((acc, item, index) => {
      acc.push(`${dateUTC.year}-${String(dateUTC.month).padStart(2, '0')}-${String(index + 1).padStart(2, '0')}`);
      return acc;
    }, []);

  dates.forEach((day) => {
    allDaysMap[day] = allDaysMap[day] || {
      bw: 0,
      hits: 0,
      visits: 0,
      pages: 0,
      bwInMB: 0,
      pagesPerVisit: 0,
      date: day
    };
  });

  return Object.keys(allDaysMap)
    .sort((dateA, dateB) => {
      if (new Date(dateA).getTime() > new Date(dateB).getTime()) {
        return 1;
      }

      return -1;
    })
    .map((date) => allDaysMap[date]);
};

export const combineSearchEngines = (data: StatsTypes.SearchEngines[] = []) => {
  const combinedEngines = data.reduce((output, engine: StatsTypes.SearchEngines) => {
    for (const engineId in KNONW_ENGINES) {
      if (engine.se.includes(engineId)) {
        return {
          ...output,
          [engineId]: output[engineId]
            ? {
                se: engineId,
                hits: output[engineId].hits + engine.hits,
                pages: output[engineId].pages + engine.pages
              }
            : {
                se: engineId,
                hits: engine.hits,
                pages: engine.pages
              }
        };
      }
    }

    return {
      ...output,
      [engine.se]: engine
    };
  }, {});

  return Object.values(combinedEngines);
};

export const formatProductEntityNames = (data = [], nameKey = 'id') =>
  data.map((productEntity) => {
    const name = productEntity[nameKey] || 'unknown';

    return {
      ...productEntity,
      [nameKey]: formatUserAgentName(name)
    };
  });

export const formatStatsData = (
  stats = {
    serefs: [],
    keywords: [],
    refs: [],
    country: [],
    days: [],
    os: [],
    ua: []
  },
  dateId: string
) => ({
  ...stats,
  serefs: sortByValue(combineSearchEngines(stats.serefs), 'hits'),
  keywords: sortByValue(stats.keywords, 'hits'),
  refs: sortByValue(stats.refs, 'hits'),
  country: formatCountryStats(stats.country),
  os: formatProductEntityNames(sortTechData(stats.os), 'id'),
  ua: formatProductEntityNames(sortTechData(stats.ua, 'browser', 'hits'), 'browser'),
  days: fulfillAllMonthDays(formatDaysStats(stats.days), dateId)
});

export const getDateReadyFormat = (dateId: string) => {
  const [m1, m2, ...year] = dateId.split('');
  const yearValue = parseInt(year.join(''), 10);
  return `${yearValue}-${m1}${m2}`;
};

export const convertDateToApiFormat = (date: Date) =>
  `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

export const sortDatesIds = (dates: string[]) =>
  dates.sort((dateIdA, dateIdB) => {
    const dateTimeA = new Date(getDateReadyFormat(dateIdA));
    const dateTimeB = new Date(getDateReadyFormat(dateIdB));

    if (dateTimeB.getTime() < dateTimeA.getTime()) {
      return 1;
    }

    return -1;
  });

export const sortByValue = (data = [], key: string) => data.sort((a, b) => (b[key] > a[key] ? 1 : -1));

export const getStatsParameterSum = (data = [], key: string) =>
  data.reduce((sum: number, item) => {
    const value = item[key];

    if (typeof value === 'number') {
      return sum + item[key];
    }

    return sum;
  }, 0);

export const getDateReadyFormatUTC = (dateId: string) => {
  const [m1, m2, ...year] = dateId.split('');
  const yearValue = parseInt(year.join(''), 10);

  return {
    year: yearValue,
    month: parseInt(`${m1}${m2}`)
  };
};
