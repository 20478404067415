const matchOptions = [
  { value: 'match_any_cond', label: 'translate.page.emailFilter.match.any' },
  { value: 'match_all_cond', label: 'translate.page.emailFilter.match.all' }
  // { id: 'match_any_message', value: 'any message' }
];

const subjectOptions = [
  {
    value: 'From',
    label: 'translate.page.emailFilter.condition.from'
  },
  {
    value: 'Subject',
    label: 'translate.page.emailFilter.condition.subject'
  },
  {
    value: 'To',
    label: 'translate.page.emailFilter.condition.to'
  },
  {
    value: 'Reply-To',
    label: 'translate.page.emailFilter.condition.reply.to'
  },
  {
    value: 'Body',
    label: 'translate.page.emailFilter.condition.body'
  },
  {
    value: 'AnyHeader',
    label: 'translate.page.emailFilter.condition.any.header'
  },
  {
    value: 'To_Cc',
    label: 'translate.page.emailFilter.condition.to_cc'
  },
  {
    value: 'is_err',
    label: 'translate.page.emailFilter.condition.is_err'
  }
];

const comparisionOptions = [
  {
    value: 'equals',
    label: 'translate.page.emailFilter.comparision.equals'
  },
  {
    value: 'regex_match',
    label: 'translate.page.emailFilter.comparision.regex_match'
  },
  {
    value: 'contains',
    label: 'translate.page.emailFilter.comparision.contains'
  },
  {
    value: 'not_contains',
    label: 'translate.page.emailFilter.comparision.not_contains'
  },
  {
    value: 'begins',
    label: 'translate.page.emailFilter.comparision.begins'
  },
  {
    value: 'ends',
    label: 'translate.page.emailFilter.comparision.ends'
  },
  {
    value: 'not_begins',
    label: 'translate.page.emailFilter.comparision.not_begins'
  },
  {
    value: 'not_ends',
    label: 'translate.page.emailFilter.comparision.not_ends'
  },
  {
    value: 'not_regex_match',
    label: 'translate.page.emailFilter.comparision.not_regex_match'
  }
];

const userActionOptions = [
  {
    value: 'discard',
    label: 'translate.page.emailFilter.action.discard'
  },
  {
    value: 'forward_to',
    label: 'translate.page.emailFilter.action.forward_to'
  },
  {
    value: 'discard_with_msg',
    label: 'translate.page.emailFilter.action.discard_with_msg'
  },
  {
    value: 'no_more_filters',
    label: 'translate.page.emailFilter.action.no_more_filters'
  },
  {
    value: 'move_to',
    label: 'translate.page.emailFilter.action.move_to'
  },
  {
    value: 'copy_to',
    label: 'translate.page.emailFilter.action.copy_to'
  },
  {
    value: 'pipe_to_program',
    label: 'translate.page.emailFilter.action.pipe_to_program'
  }
];

const domainActionOptions = [
  {
    value: 'discard',
    label: 'translate.page.emailFilter.action.discard'
  },
  {
    value: 'forward_to',
    label: 'translate.page.emailFilter.action.forward_to'
  },
  {
    value: 'discard_with_msg',
    label: 'translate.page.emailFilter.action.discard_with_msg'
  },
  {
    value: 'no_more_filters',
    label: 'translate.page.emailFilter.action.no_more_filters'
  },
  {
    value: 'pipe_to_program',
    label: 'translate.page.emailFilter.action.pipe_to_program'
  }
];

export { domainActionOptions, userActionOptions, comparisionOptions, matchOptions, subjectOptions };
