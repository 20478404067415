import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import FormInput from '../../../components/form-input';
import FormDropdown from '../../../components/form-dropdown';

type Props = {
  domain: any;
  intl: any;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => null;
};

const formName = REDUX_FORM.CREATE_REDIRECT;

const Form = ({ domain, intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required, url, validationWithMetaApi } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          name="domain_name"
          type="text"
          options={domain}
          optionValue="name"
          optionLabel="name"
          label={intl.formatMessage({ id: 'translate.generic.select.domain.dropdown.label' })}
          searchable
          validate={[required]}
          component={FormDropdown}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="path"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.path' })}
          placeholder={intl.formatMessage({ id: 'translate.page.redirect.path.placeholder' })}
          validate={[validationWithMetaApi]}
          normalize={(path) => (path.charAt(0) === '/' ? path : '/' + path)}
          component={FormInput}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="permanent"
          options={[
            { id: 1, type: intl.formatMessage({ id: 'translate.page.redirect.type_permanent' }) },
            { id: 0, type: intl.formatMessage({ id: 'translate.page.redirect.type_temporary' }) }
          ]}
          optionValue="id"
          optionLabel="type"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.redirect.redirect.type' })}
          validate={[required]}
          component={FormDropdown}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="redirect_to"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.redirect.redirect.to' })}
          validate={[required, url]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.create' })} />
      </Column>
    </SGForm>
  );
};

const RedirectForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.LOCATION_REDIRECT,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(RedirectForm));
