import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Column, Notice } from '@siteground/styleguide';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import FormDropdown from '../../../components/form-dropdown';
import FormInput from '../../../components/form-input';
import SelectPath from '../../../components/dialogs/select-path';
import { SGForm, SGSubmitButton } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-spanel-forms';

type Props = {
  intl: Intl;
  domainAll: any[];
  change: Function;
  sPanelFormSubmit: (formName) => null;
  validationUtils: ValidationUtils;
  getFormValues: Function;
  openSGDialog: Function;
};

const formName = REDUX_FORM.CREATE_WP_TRANSFER;
const DEFAULT_PATH = '/';

const Form = ({ domainAll, getFormValues, intl, change, openSGDialog, validationUtils, sPanelFormSubmit }: Props) => {
  const { required, validationWithMetaApi } = validationUtils;
  const formValues = getFormValues(formName);
  const selectedDomain = domainAll.find((domain) => formValues && domain.name === formValues.domain_name);
  const SelectPathInitialPath = selectedDomain && `${selectedDomain.name}/${selectedDomain.docroot}`;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="12">
        <Notice type="info" background="light" shadow={false}>
          {intl.formatMessage({ id: 'translate.page.wp.manage.create.notice' })}
        </Notice>
      </Column>

      <Column smSpan="6">
        <Field
          name="domain_name"
          label={intl.formatMessage({ id: 'translate.generic.domain' })}
          searchable={domainAll.length > 1}
          options={domainAll}
          optionValue="name"
          optionLabel="name"
          onChange={(ev, value) => change('path', DEFAULT_PATH)}
          component={FormDropdown}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="path"
          label={intl.formatMessage({ id: 'translate.generic.path' })}
          placeholder={intl.formatMessage({ id: 'translate.page.wp.manage.path.placeholder' })}
          validate={[validationWithMetaApi]}
          suffix={
            <Button action="button" color="dark" size="small" onClick={() => openSGDialog(DIALOGS.SELECT_DIRECTORY)}>
              {intl.formatMessage({ id: 'translate.generic.browse' })}
            </Button>
          }
          normalize={(path) => (path.charAt(0) === DEFAULT_PATH ? path : DEFAULT_PATH + path)}
          component={FormInput}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.generate' })} />
      </Column>

      <SelectPath
        initialPath={SelectPathInitialPath}
        expandedNodes={[DEFAULT_PATH]}
        nodesParentFolder={{
          name: DEFAULT_PATH,
          path: DEFAULT_PATH,
          value: DEFAULT_PATH
        }}
        onChange={(path) => change('path', path.replace(SelectPathInitialPath, ''))}
      />
    </SGForm>
  );
};

const WpMangeForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.WORDPRESS_TRANSFER,
      formName
    }
  }
})(Form);

const mapDispatchToProps = (dispatch) => ({
  openSGDialog: (id, payload) => dispatch(sgDialogActions.openSGDialog(id, payload))
});

export default connect<{}, {}, any>(undefined, mapDispatchToProps)(withSpanelForm(injectIntl(WpMangeForm)));
