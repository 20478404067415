import * as React from 'react';
import { useIntl } from 'react-intl';

import { API_RESOURCE } from '../../../../core/constants/api';
import TableContextMenu from '../../../components/table-context-menu/table-context-menu';

export const PhpExtensionContextMenu = ({ extension, onEditClicked }) => {
  const intl = useIntl();
  const disabled = extension.in === 'global-config';
  const contextMenuItems = [
    {
      vcsMethod: 'PUT',
      icon: 'edit',
      label: !disabled && intl.formatMessage({ id: 'translate.page.phpSettings.edit.tooltip' }),
      e2eAttr: 'table-action-edit',
      visibleOnDesktop: true,
      disabled,
      onClick: () => {
        onEditClicked(extension);
      }
    }
  ];

  return (
    <TableContextMenu
      entity={extension}
      resourceName={API_RESOURCE.PHP_LOCATION_INI.resourceName}
      items={contextMenuItems}
    />
  );
};

export default PhpExtensionContextMenu as typeof PhpExtensionContextMenu;
