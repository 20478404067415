import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { InputState } from '@siteground/styleguide';
import { LoginCard } from '@siteground/styleguide/lib/composite';
import * as i18nActions from '../../../core/actions/i18n';
import { createNotification } from '../../../core/actions/notifications';
import * as sessionActions from '../../../core/actions/session';
import { SESSION_EXPIRED_REASON } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { ResellerLoginRoutes } from '../../../core/constants/route-info';
import { ROUTES } from '../../../core/constants/routes';
import PartialLoader from '../../components/partial-loader';

import LoginForm, { LoginStep } from './forms/login-form';
import LoginFooter from './login-footer';
import withResellerLoginPage from './with-reseller-login-page';

type Props = {
  intl: Intl;
  router: {
    push: Function;
    goBack: Function;
  };
  location: {
    query: {
      redirect?: string;
      reason?: string;
    };
  };
  verifyUser: typeof sessionActions.verifyUser;
  signIn: typeof sessionActions.signIn;
  createNotification: typeof createNotification;
  i18nActions: typeof i18nActions;
};

type State = {
  step: LoginStep;
  usernameMessage: string;
  usernameInputState: InputState;
  passwordMessage: string;
  passwordInputState: InputState;
};

const initialState: State = {
  step: 'username',
  usernameMessage: '',
  usernameInputState: 'none',
  passwordMessage: '',
  passwordInputState: 'none'
};

type DispatchProps = {
  intl: Intl;
};

class Login extends React.Component<Props & DispatchProps, State> {
  readonly state: State = initialState;

  onVerifyUser = ({ username }) => {
    const { verifyUser, createNotification } = this.props;

    this.setState({ ...initialState });

    verifyUser({
      username,
      onComplete: () =>
        this.setState({
          step: 'password',
          usernameMessage: '',
          usernameInputState: 'none'
        }),
      onFailure: ({ validation }) => {
        if (validation) {
          const usernameMessage = validation && validation.username && validation.username.intl_id;

          return this.setState({
            usernameMessage,
            usernameInputState: 'error'
          });
        }

        createNotification({
          type: 'generic',
          state: 'error',
          error: {
            intlKey: 'translate.reseller-login.site-panel.user-verification.failed'
          }
        });
      }
    });
  };

  onLogin = ({ username, password, remember_me }) => {
    const { signIn, createNotification, location } = this.props;

    // if redirect param is missing, redirect to app location origin
    const redirectUrl = location.query.redirect || window.location.origin;

    this.setState({
      ...initialState,
      step: 'password',
      passwordMessage: '',
      passwordInputState: 'none'
    });

    signIn({
      username,
      password,
      remember_me,
      redirectUrl,
      onFailure: ({ validation, message, ...other }) => {
        if (validation) {
          const passwordMessage = validation && validation.password && validation.password.intl_id;
          return this.setState({ passwordMessage, passwordInputState: 'error' });
        }

        createNotification({
          type: 'generic',
          state: 'error',
          error: {
            intlKey: 'translate.reseller-login.site-panel.login-failed.message'
          }
        });
      }
    });
  };

  componentDidMount() {
    const { location, createNotification } = this.props;

    if (location.query.reason && location.query.reason === SESSION_EXPIRED_REASON) {
      createNotification({
        type: 'generic',
        state: 'error',
        error: {
          intlKey: 'translate.reseller-login.site-panel.session-expired.message'
        }
      });
    }
  }

  render() {
    const { intl, router } = this.props;
    const { step, usernameMessage, usernameInputState, passwordMessage, passwordInputState } = this.state;

    return (
      <React.Fragment>
        <LoginCard
          title={intl.formatMessage({ id: 'translate.reseller-login.site-panel.login.title' })}
          data-e2e="sign-in"
          handleBack={step === 'password' ? () => this.setState({ ...initialState }) : null}
          afterCardContent={<LoginFooter intl={intl} />}
        >
          <PartialLoader
            resources={[
              { requestTypeName: customRequestTypes.VERIFY_USER },
              { requestTypeName: customRequestTypes.SIGN_IN }
            ]}
          >
            <LoginForm
              step={step}
              subTitle={intl.formatMessage({ id: 'translate.reseller-login.site-panel.login.text' })}
              usernameMessage={usernameMessage}
              usernameInputState={usernameInputState}
              passwordMessage={passwordMessage}
              passwordInputState={passwordInputState}
              onVerifyUser={this.onVerifyUser}
              onLogin={this.onLogin}
              onRecoverPassword={() => router.push(ROUTES[ResellerLoginRoutes.recoverPassword])}
            />
          </PartialLoader>
        </LoginCard>
      </React.Fragment>
    );
  }
}

export default connect<any, DispatchProps, Props>(undefined, {
  ...sessionActions,
  createNotification
})(withResellerLoginPage(injectIntl(Login)));
