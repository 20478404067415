import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Notice, Text } from '@siteground/styleguide';

type Props = {
  intl: Intl;
};

export const DomainCacheNotice = ({ intl }: Props) => (
  <Notice
    shadow={false}
    type="instruction"
    background="light"
    title={intl.formatMessage({ id: 'translate.page.super-cacher.domain-cache-notice.title' })}
  >
    <Grid>
      <Text>{intl.formatMessage({ id: 'translate.page.super-cacher.domain-cache-notice.info' })}</Text>

      <Text>{intl.formatMessage({ id: 'translate.page.super-cacher.domain-cache-notice.info.tutorial' })}</Text>
    </Grid>
  </Notice>
);

export default injectIntl(DomainCacheNotice);
