import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Flex, Icon, Link, Switch, Text, Label } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import SettingList from '../../../components/setting-list';
import './settings.scss';
import { CDN_SETTING_ID, DNS_CENTRAL_RECORDS } from '../constants';
import { HideOnMobile, ShowOnMobile } from '../../../components/device';
import { DIALOGS } from '../../../../core/constants/common';
import { findDomainById } from '../../php-settings/utils';
import SettingListItem from '../components/setting-list-item';
import TableContextMenu from '../../../components/table-context-menu';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { SettingSwitch } from '../components';

type Props = {
  domain: any;
  items: any;
  domainLocation: any;
  openSGDialog: Function;
  actions: {
    updateItem: Function;
    deleteItem: Function;
    createItem: Function;
  };
  intl: Intl;
  setZoneDomain: Function;
  navigateToUA: Function;
};

class CDNSettingsBasic extends React.Component<Props, any> {
  renderNameColumn = (value): any => {
    const { intl, domain, openSGDialog, items } = this.props;
    const { domainCDN } = items;
    const DNSRecords = domain?.sg_dns;
    const cdnDomain = findDomainById({ domainId: domain?.id, domain: domainCDN });

    if (domain && DNSRecords !== DNS_CENTRAL_RECORDS && cdnDomain?.cdn_enabled) {
      return (
        <React.Fragment>
          <HideOnMobile>
            <Flex align="center">
              <Text>{value}</Text>
              &nbsp;&nbsp;
              <Icon name="warning" color="warning" size="14" />
              &nbsp;
              <Text color="warning" size="small">
                {intl.formatMessage({ id: 'translate.page.cdn.records.not.pointed' })}
              </Text>
              &nbsp;
              <Link color="ocean" onClick={() => openSGDialog(DIALOGS.CDN_NOT_WORKING_CENTRAL_DNS)}>
                <Text color="ocean" weight="bold" size="small">
                  {intl.formatMessage({ id: 'translate.generic.view.details' })}
                </Text>
              </Link>
            </Flex>
          </HideOnMobile>
          <ShowOnMobile>
            {value}
            <Flex align="center">
              <Icon name="warning" color="warning" size="14" />
              &nbsp;
              <Text color="warning" size="small">
                {intl.formatMessage({ id: 'translate.page.cdn.records.not.pointed' })}
              </Text>
              &nbsp;
              <Link color="ocean" onClick={() => openSGDialog(DIALOGS.CDN_NOT_WORKING_CENTRAL_DNS)}>
                <Text color="ocean" weight="bold" size="small">
                  {intl.formatMessage({ id: 'translate.generic.view.details' })}
                </Text>
              </Link>
            </Flex>
          </ShowOnMobile>
        </React.Fragment>
      );
    }

    return `${value}`;
  };

  renderCacheToggle = () => {
    const { intl, actions, items, domain } = this.props;
    const { domainCDN } = items;

    const DNSRecords = domain?.sg_dns;
    const cdnDomain = findDomainById({ domainId: domain?.id, domain: domainCDN });

    return (
      <TableContextMenu
        entity={domain}
        resourceName={API_RESOURCE.DOMAIN_CDN.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            icon: 'flush',
            label: intl.formatMessage({ id: 'translate.page.cdn.purge_cache' }),
            e2eAttr: 'table-action-flush-cache',
            visibleOnDesktop: true,
            disabled: !cdnDomain.cdn_enabled || DNSRecords !== DNS_CENTRAL_RECORDS,
            onClick: () =>
              actions.updateItem({
                _metaFields: {
                  ...API_RESOURCE.DOMAIN_CACHE,
                  dontChangeItemsState: true
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.cdn.cache_flushed',
                      intlValues: { entityName: domain.name }
                    },
                    error: {
                      intlKey: 'translate.page.cdn.cache_flushed.failed',
                      intlValues: { entityName: domain.name }
                    }
                  }
                },
                flush_cache: 1,
                id: domain.id
              })
          }
        ]}
      />
    );
  };

  render() {
    const { domain, intl, items, domainLocation } = this.props;
    const { domainCDN } = items;
    const cdnDomain = findDomainById({ domainId: domain?.id, domain: domainCDN });
    const DNSRecords = domain?.sg_dns;

    return (
      <SettingList title={intl.formatMessage({ id: 'translate.page.cdn.tab.setting.basic.features' })}>
        <SettingListItem
          title={this.renderNameColumn(intl.formatMessage({ id: 'translate.page.cdn.settings.cdn.title' }))}
          info={intl.formatMessage({ id: 'translate.page.cdn.settings.cdn.desc' })}
          resources={[
            {
              resourceName: API_RESOURCE.DOMAIN_CDN.resourceName,
              methods: ['PUT']
            }
          ]}
        >
          {DNSRecords !== DNS_CENTRAL_RECORDS && cdnDomain.cdn_enabled ? (
            <Label type="link" color="light">
              {intl.formatMessage({ id: 'translate.generic.pending' })}
            </Label>
          ) : (
            <Switch
              checked={cdnDomain.cdn_enabled}
              onChange={(event) => {
                const { domain, openSGDialog, items, setZoneDomain, actions } = this.props;
                const { cloudflareZone, domainCDN } = items;
                const cdnDomain = findDomainById({ domainId: domain?.id, domain: domainCDN });
                const zone = cloudflareZone;

                if (DNSRecords !== DNS_CENTRAL_RECORDS && !cdnDomain.cdn_enabled) {
                  return openSGDialog(DIALOGS.CDN_CANNOT_BE_ACTIVATED_CENTRAL_DNS);
                }

                const zoneDomain = zone.find((mainZone) => {
                  return mainZone.sub_domains.find((subdomain) => {
                    if (subdomain === 'www') {
                      return mainZone.name === domain.name;
                    }
                    return `${subdomain}.${mainZone.name}` === domain.name;
                  });
                });

                setZoneDomain(zoneDomain, domain);

                if (!cdnDomain.cdn_enabled && zoneDomain && !zoneDomain.cf_plus) {
                  return openSGDialog(DIALOGS.CDN_ACTIVATE_FREE_CF);
                }

                if (!cdnDomain.cdn_enabled && zoneDomain && zoneDomain.cf_plus) {
                  return openSGDialog(DIALOGS.CDN_ACTIVATE_PAID_CF);
                }

                const cacheEnabled = event.target.checked;
                const meta: { notification: SpanelNotification } = {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: cacheEnabled
                        ? 'translate.page.cdn.enable.success.notification'
                        : 'translate.page.cdn.disable.success.notification'
                    },
                    error: {
                      intlKey: cacheEnabled
                        ? 'translate.page.cdn.enable.fail.notification'
                        : 'translate.page.cdn.disable.fail.notification'
                    }
                  }
                };

                actions.updateItem({
                  id: domain.id,
                  _metaFields: {
                    ...API_RESOURCE.DOMAIN_CDN
                  },
                  _meta: meta,
                  cdn_enabled: Number(cacheEnabled)
                });
              }}
            />
          )}
        </SettingListItem>
        <SettingListItem
          title={intl.formatMessage({ id: 'translate.page.cdn.cache.purge.title' })}
          info={intl.formatMessage({ id: 'translate.page.cdn.cache.purge.desc' })}
          resources={[
            {
              resourceName: API_RESOURCE.DOMAIN_CACHE.resourceName,
              methods: ['PUT']
            }
          ]}
        >
          {this.renderCacheToggle()}
        </SettingListItem>
        <SettingSwitch
          title={intl.formatMessage({ id: 'translate.page.cdn.dev.mood.title' })}
          info={intl.formatMessage({ id: 'translate.page.cdn.dev.mood.desc' })}
          resources={[{ requestTypeName: customRequestTypes.UPDATE_DEV_MODE }]}
          setting={{ id: CDN_SETTING_ID.DEVELOPMENT_MODE, value: domainLocation?.development_mode }}
          DNSRecords={DNSRecords}
          cdnDomain={cdnDomain}
          domainLocation={domainLocation}
          domain={domain}
        />
      </SettingList>
    );
  }
}

export default injectIntl(CDNSettingsBasic) as any;
