import * as React from 'react';
import { Button, Text, Icon } from '@siteground/styleguide';
import { useIntl } from 'react-intl';
import { DIALOGS } from '../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../components/sg-dialog';

type Props = {
  onProceed: Function;
};

export const CleanUp = ({ onProceed }: Props) => {
  const intl = useIntl();
  return (
    <SGDialog
      id={DIALOGS.SG_SITESCANNER_CLEAN_UP}
      state="warning"
      size="medium"
      title={intl.formatMessage({ id: 'translate.page.sg-sitescanner.clean-up-dialog.title' })}
      footer={
        <React.Fragment>
          <SGDialogCancel id={DIALOGS.SG_SITESCANNER_CLEAN_UP} />
          <Button onClick={onProceed} color="primary">
            {intl.formatMessage({ id: 'translate.page.sg-sitescanner.continue-button.label' })}
          </Button>
        </React.Fragment>
      }
    >
      <Icon multicolor name="logo-sucuri" width="130px" height="30px" />
      <Text align="center" padding={['large', 'none', 'none', 'none']}>
        {intl.formatMessage({ id: 'translate.page.sg-sitescanner.clean-up-dialog.text' })}
      </Text>
    </SGDialog>
  );
};

export default CleanUp as (props: Props) => any;
