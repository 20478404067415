import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../core/actions/crud';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { REDUX_FORM } from '../../../core/constants/common';
import AddExistingApplicationForm from '../common-forms/add-existing-application';
import { SGDialogForm } from '../sg-dialog';

type Props = {
  domain: any[];
  title?: string;
  actions?: {
    createItem: CreateItem;
  };
  closeSGDialog?: Function;
  onApplicationAdded?: Function;
};

type DispatchProps = {
  intl: Intl;
};

export class AddExistingApp extends React.Component<Props & DispatchProps> {
  render() {
    const { domain, actions, closeSGDialog, onApplicationAdded = () => null, intl, title } = this.props;

    return (
      <SGDialogForm
        name={REDUX_FORM.ADD_EXISTING_APP}
        icon="new-staging"
        state="active"
        title={title || intl.formatMessage({ id: 'translate.page.wp.manage.add.application.title' })}
        submitLabel={intl.formatMessage({ id: 'translate.generic.add' })}
        resources={[{ resourceName: API_RESOURCE.APP.resourceName, methods: ['POST'] }]}
      >
        <AddExistingApplicationForm
          domain={domain}
          onSubmit={(data) => {
            const modifiedData = { ...data };
            const selectedDomain = domain.find((d) => d.id === modifiedData.domain_id);
            const url = selectedDomain ? selectedDomain.name : '';

            actions.createItem(
              {
                ...data,
                _metaFields: {
                  ...API_RESOURCE.APP
                },
                _meta: {
                  notification: {
                    type: 'generic',
                    success: {
                      intlKey: 'translate.page.wp.manage.add.application.success.message',
                      intlValues: { url }
                    },
                    error: {
                      intlKey: 'translate.page.wp.manage.add.application.error.message',
                      intlValues: { url }
                    }
                  }
                }
              },
              () => {
                closeSGDialog(REDUX_FORM.ADD_EXISTING_APP);
                onApplicationAdded();
              }
            );
          }}
        />
      </SGDialogForm>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  closeSGDialog: (id) => dispatch(sgDialogActions.closeSGDialog(id))
});

export default connect<any, any, Props>(undefined, mapDispatchToProps)(injectIntl(AddExistingApp));
