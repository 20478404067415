import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid, Notice, Text } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import { withSpanelForm } from '../../../components/sg-spanel-forms';

const formName = REDUX_FORM.CHANGE_WP_MANAGE_FIX_PERMISSIONS_DIALOG;

const FixPermissionsForm = () => {
  const intl = useIntl();

  return (
    <Grid>
      <Notice type="warning" background="light" border={false} shadow={false}>
        {intl.formatMessage({ id: 'translate.page.wp.manage.fix.permissions.notice' })}
      </Notice>
      <Text align="left">{intl.formatMessage({ id: 'translate.page.wp.manage.fix.permissions.text' })}</Text>
    </Grid>
  );
};

export default withSpanelForm(reduxForm({ form: formName })(FixPermissionsForm));
