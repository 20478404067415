import * as React from 'react';
import { Button, Flex, getDate, Grid, Label, Link, Section, Tab, Table, Tabs, Text } from '@siteground/styleguide';
import { navigateToUA } from '../../../core/actions/nemo-store';
import { createNotification } from '../../../core/actions/notifications';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { createTaskLoaderMetadata } from '../../../core/common/create-metadata';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, LocalTaskLoaderType, REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import { ROUTES } from '../../../core/constants/routes';
import { getCurrentSiteId } from '../../../core/selectors';
import { getToolMainEndpoint } from '../../../core/selectors/menu-items';
import { isACLActionEnabled } from '../../../core/selectors/site-meta-api';
import { redirectToURL } from '../../../core/utils/redirect-to-url';
import { DeleteDialog } from '../../components/dialogs';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import { SGDialog, SGDialogCancel } from '../../components/sg-dialog';
import SGTable from '../../components/sg-table';
import { getTranslations } from '../../components/sg-table/translation';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../components/visibility-control-service';
import { RootState } from '../../reducers';
import { CREATE_BOX_STATES, IMPORTED_LABEL, PREMIUM_LABEL } from './constants';
import { CreateBox, CreateFormImport, CreateFormInstall, FORM_NAME_BY_TAB_ID } from './create';
import { ViewCertificate } from './update';

const SSL_CERT_STORE_KEY = 'ssl-cert';

const CREATE_FORM_COMPONENT_BY_TAB_ID = {
  [CREATE_BOX_STATES.INSTALL]: CreateFormInstall,
  [CREATE_BOX_STATES.IMPORT]: CreateFormImport
};

type SSLProps = {
  actions: CrudActions;
  navigateToUA: Function;
  domainAll: any[];
  ssl: any;
  sslCertificates: any;
  location: any;
  intl: Intl;
  isUAEnabled: boolean;
  siteId: string;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  createNotification: typeof createNotification;
};

type State = {
  viewCertificateDomainsDialogPayload: any;
  renewCertificateDialogPayload: any;
  deleteCertificateDialogPayload: any;
  selectedDomainId: number;
  selectedTab: string;
};

class SSLPage extends React.Component<SSLProps, State> {
  readonly state = {
    viewCertificateDomainsDialogPayload: null,
    renewCertificateDialogPayload: null,
    deleteCertificateDialogPayload: null,
    selectedDomainId: null,
    selectedTab: CREATE_BOX_STATES.INSTALL
  };

  getCertificateLabel(cert) {
    const { intl } = this.props;

    if (!cert || !cert.issuer) {
      return null;
    }

    const { issuer } = cert;
    let certOrganization = '';

    if (cert.label === IMPORTED_LABEL) {
      certOrganization += `${intl.formatMessage({ id: 'translate.generic.imported' })} - `;
    }

    certOrganization += issuer.split('/C=US/').pop().split('/CN=').shift().split('=').pop();

    if (!certOrganization) {
      certOrganization = issuer;
    }

    if (!cert.info || !cert.info.cert) {
      return certOrganization;
    }

    if (cert.info.cert.wildcard) {
      certOrganization += ' Wildcard';
    } else if (cert.info.cert.self) {
      certOrganization += ' Self Signed';
    }

    return certOrganization;
  }

  getSelectedCertificates() {
    const { domainAll, ssl } = this.props;
    const { selectedDomainId } = this.state;

    const selectedDomain = domainAll.find((d) => d.id === selectedDomainId);

    if (!ssl) {
      return null;
    }

    if (!selectedDomain || ssl.length === 0) {
      return null;
    }

    return ssl.filter((cert) => {
      const installOn = []
        .concat(cert.installed_on_wild)
        .concat(cert.installed_on)
        .filter(Boolean)
        .map((c) => c.name);

      const domainsMatched = cert.domains_matched ? cert.domains_matched.map((domain) => domain.name) : [];

      return installOn.includes(selectedDomain.name) && domainsMatched.includes(selectedDomain.name);
    });
  }

  getCertificatesValues(selectedDomain) {
    const { ssl } = this.props;
    if (!ssl) {
      return null;
    }

    if (!selectedDomain || ssl.length === 0) {
      return null;
    }

    return ssl.filter((cert) => {
      const installOn = []
        .concat(cert.installed_on_wild)
        .concat(cert.installed_on)
        .filter(Boolean)
        .map((c) => c.name);

      const domainsMatched = cert.domains_matched ? cert.domains_matched.map((domain) => domain.name) : [];

      return installOn.includes(selectedDomain.name) && domainsMatched.includes(selectedDomain.name);
    });
  }

  getMainDomainSelectedCertificates() {
    const { domainAll } = this.props;
    const mainDomain = domainAll.find((d) => d.domain_type === 'main_domain');

    return this.getCertificatesValues(mainDomain);
  }

  onSSLCertSucceessResponse = ({ response, domain, ssl, formName }) => {
    const { createNotification, actions, intl } = this.props;
    const { fetchItems } = actions;

    fetchItems(API_RESOURCE.DOMAIN_ALL);
    fetchItems(API_RESOURCE.SSL);

    if (response.label === 'IMPORTED') {
      // handle case with importing cert, which doesnt match a domain form the site (not installed)

      const { installed_on, installed_on_wild } = response;

      if (!installed_on && !installed_on_wild) {
        return createNotification({
          type: 'form',
          state: 'error',
          formName,
          error: {
            intlKey: 'translate.page.ssl.failed.to.install.ssl'
          },
          // simultating BE error from Avalon (Difficult to implement there)
          responseError: {
            message: intl.formatMessage({ id: 'translate.page.ssl.domains.do.not.match' })
          }
        });
      }

      // IMPORT ssl succeeded
      return createNotification({
        type: 'form',
        state: 'success',
        formName,
        success: {
          intlKey: 'translate.page.ssl.create.external.success.message'
        }
      });
    }

    // Certificate generate succeeded
    createNotification({
      type: 'form',
      state: 'success',
      formName,
      success: {
        intlKey: 'translate.page.ssl.create.success.message',
        intlValues: {
          domain,
          ssl
        }
      }
    });
  };

  onCreateFormSubmitted = (data: CreateItemPayload) => {
    const { domainAll, intl } = this.props;
    const { selectedTab, selectedDomainId } = this.state;
    const selectedDomain = domainAll.find((d) => d.id === selectedDomainId);
    const formName = FORM_NAME_BY_TAB_ID[selectedTab];
    const modifiedData: any = { ...data, auto_install: 1 };
    const domain = selectedDomain && selectedDomain.name;

    const notification = {
      type: 'form',
      formName: FORM_NAME_BY_TAB_ID[selectedTab],
      success: null,
      error: null
    };

    let ssl = 'SSL'; // not used for import

    if (formName === REDUX_FORM.CREATE_INSTALL_SSL) {
      ssl = intl.formatMessage({
        id: modifiedData.wildcard ? 'translate.page.ssl.lets.encrypt.wildcard' : 'translate.page.ssl.lets.encrypt'
      });

      modifiedData.wildcard = modifiedData.generate;

      notification.success = {
        intlKey: 'translate.page.ssl.create.success.message',
        intlValues: {
          domain,
          ssl
        }
      };

      notification.error = {
        intlKey: 'translate.page.ssl.create.failed.message',
        intlValues: {
          domain,
          ssl
        }
      };

      delete modifiedData.generate;
    }

    if (formName === REDUX_FORM.CREATE_SSL_IMPORT) {
      notification.error = { intlKey: 'translate.page.ssl.create.external.failed.message' };
    }

    if (modifiedData.cert) {
      modifiedData.cert = btoa(modifiedData.cert);
    }

    if (modifiedData.key) {
      modifiedData.key = btoa(modifiedData.key);
    }

    if (modifiedData.cabundle) {
      modifiedData.cabundle = btoa(modifiedData.cabundle);
    }

    if (modifiedData.cert || modifiedData.key) {
      modifiedData.label = IMPORTED_LABEL;
    }

    this.props.actions.createItem(
      {
        ...modifiedData,
        _meta: {
          ...createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX),
          notification
        }
      },
      (response) => this.onSSLCertSucceessResponse({ response, domain, ssl, formName })
    );
  };

  goToUA = () => {
    const { domainAll, siteId } = this.props;
    const { selectedDomainId } = this.state;
    const selectedDomain = domainAll.find((d) => d.id === selectedDomainId);

    this.props.navigateToUA({
      page: 'ssl', //  TODO way to share constants between projects ?
      siteId,
      domainName: selectedDomain && selectedDomain.name
    });
  };

  renderSSLName = (cell, entity) => {
    const { intl, openSGDialog, ssl } = this.props;
    const { installed_on, installed_on_wild } = entity;
    const installedOn = []
      .concat(installed_on)
      .concat(installed_on_wild)
      .filter(Boolean)
      .filter((e) => e.name !== entity.name)
      .filter((e) => {
        const cert = ssl.find((s) => s.name == e.name);
        const isAlreadyInList = cert && cert.installed_on && cert.installed_on.length;
        return !isAlreadyInList;
      });

    if (installedOn.length === 0) {
      return cell;
    }

    return (
      <div>
        <Flex wrap="nowrap">
          <Text truncate>{cell}</Text>
          &nbsp;
          {Boolean(installedOn.length) && (
            <Text style={{ flexShrink: 0 }}>
              <Link
                onClick={() =>
                  this.setState({ viewCertificateDomainsDialogPayload: installedOn }, () =>
                    openSGDialog(REDUX_FORM.SSL_CERTIFICATE_DOMAINS_DIALOG)
                  )
                }
              >
                + {installedOn.length} {intl.formatMessage({ id: 'translate.generic.more' })}
              </Link>
            </Text>
          )}
        </Flex>
      </div>
    );
  };

  renderSSLStatus = (cell) => {
    const { intl } = this.props;

    return (
      <Label color={cell.err ? 'error' : 'success'} type="link" padding={['inherit', 'none']}>
        {intl.formatMessage({
          id: cell.err ? 'translate.generic.expired' : 'translate.generic.active'
        })}
      </Label>
    );
  };

  render() {
    const { intl, ssl, domainAll, openSGDialog } = this.props;
    const { selectedDomainId, selectedTab } = this.state;
    const CreateForm = CREATE_FORM_COMPONENT_BY_TAB_ID[selectedTab];

    const data = ssl && ssl.filter((s) => s.installed_on || s.installed_on_wild);

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.name' }),
        accessor: 'name',
        render: this.renderSSLName
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.certificate' }),
        accessor: 'info',
        render: (cell, entity) => this.getCertificateLabel(entity)
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.status' }),
        accessor: 'info',
        mSize: '130px',
        render: this.renderSSLStatus
      },
      {
        header: intl.formatMessage({ id: 'translate.page.ssl.expires.on' }),
        accessor: 'not_after',
        mSize: '130px',
        render: (cell) => getDate(cell)
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    const isInstallSelected = selectedTab === CREATE_BOX_STATES.INSTALL;

    const createFormDomainProps = isInstallSelected
      ? {
          domainSelectOptions: domainAll,
          selectedDomainId,
          onChangeDomain: (domainId: string) => this.setState({ selectedDomainId: parseInt(domainId, 10) }),
          selectedCertificates: this.getSelectedCertificates(),
          mainDomainSelectedCertificates: this.getMainDomainSelectedCertificates(),
          ssl: data
        }
      : {};

    return (
      <div>
        <PageHeader id={ToolId.ssl} instructions={intl.formatMessage({ id: 'translate.page.ssl.info' })} />

        <Section>
          <Grid>
            <CreateBox selectedTab={selectedTab} renderBeforeBoxChildren={this.renderCreateBoxTabs()}>
              <CreateForm
                {...createFormDomainProps}
                goToUA={this.goToUA}
                onSubmit={this.onCreateFormSubmitted}
                openSGDialog={openSGDialog}
                isUAEnabled={this.props.isUAEnabled}
              />
            </CreateBox>

            <VCS resourceName={API_RESOURCE.SSL.resourceName} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.ssl.list.title' })}
                data={data}
                columns={columns}
                rowKey="domain_name"
                resources={[{ resourceName: API_RESOURCE.SSL.resourceName, methods: ['GET'] }]}
                noDataMessage="translate.page.ssl.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>

        {this.renderViewCertificateDomainsDialog()}
        {this.renderViewCertificateDialog()}
        {this.renderRenewLECertificateDialog()}
        {this.renderRenewPremiumCertificateDialog()}
        {this.renderDeleteDialog()}
        {this.renderPremiumDeleteDialog()}
      </div>
    );
  }

  renderCreateBoxTabs = () => {
    const { intl } = this.props;
    const { selectedTab } = this.state;

    return (
      <Tabs border="light">
        <Tab
          active={selectedTab === CREATE_BOX_STATES.INSTALL}
          onClick={() => this.setState({ selectedTab: CREATE_BOX_STATES.INSTALL })}
          data-e2e="ssl-install"
        >
          {intl.formatMessage({ id: 'translate.page.ssl.tab.install' })}
        </Tab>

        <Tab
          active={selectedTab === CREATE_BOX_STATES.IMPORT}
          onClick={() => this.setState({ selectedTab: CREATE_BOX_STATES.IMPORT })}
          data-e2e="ssl-import"
        >
          {intl.formatMessage({ id: 'translate.page.ssl.tab.import' })}
        </Tab>
      </Tabs>
    );
  };

  renderViewCertificateDomainsDialog() {
    const { intl } = this.props;
    const { viewCertificateDomainsDialogPayload } = this.state;

    return (
      <SGDialog
        id={REDUX_FORM.SSL_CERTIFICATE_DOMAINS_DIALOG}
        icon="glob"
        state="warning"
        density="small"
        title={intl.formatMessage({ id: 'translate.page.ssl.domains.dialog.title' })}
        footer={
          <SGDialogCancel
            id={REDUX_FORM.SSL_CERTIFICATE_DOMAINS_DIALOG}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        }
        resources={[
          {
            resourceName: API_RESOURCE.SSL.resourceName,
            methods: ['GET']
          }
        ]}
      >
        <Table
          mobileLayout="row"
          data={viewCertificateDomainsDialogPayload}
          columns={[
            {
              accessor: 'name',
              render: (name) => <Text align="left">{name}</Text>
            }
          ]}
          translation={getTranslations(intl)}
        />
      </SGDialog>
    );
  }

  renderViewCertificateDialog() {
    const { intl, sslCertificates } = this.props;

    return (
      <SGDialog
        id={REDUX_FORM.SSL_CERTIFICATE_DIALOG}
        icon="ssl"
        state="warning"
        size="x-large"
        title={intl.formatMessage({ id: 'translate.page.ssl.view.certificate.title' })}
        footer={
          <SGDialogCancel
            id={REDUX_FORM.SSL_CERTIFICATE_DIALOG}
            label={intl.formatMessage({ id: 'translate.generic.close' })}
          />
        }
        resources={[{ resourceName: SSL_CERT_STORE_KEY, methods: ['GET'] }]}
      >
        <ViewCertificate sslCertificates={sslCertificates} />
      </SGDialog>
    );
  }

  renderRenewLECertificateDialog() {
    const { intl } = this.props;
    const { renewCertificateDialogPayload } = this.state;
    const entity = renewCertificateDialogPayload && renewCertificateDialogPayload.entity;

    return (
      <SGDialog
        id={REDUX_FORM.SSL_RENEW_LE_DIALOG}
        icon="renew"
        state="warning"
        title={intl.formatMessage({ id: 'translate.page.ssl.renew.le.dialog.title' })}
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={REDUX_FORM.SSL_RENEW_LE_DIALOG}
              label={intl.formatMessage({ id: 'translate.generic.close' })}
            />
            <Button
              color="primary"
              onClick={() =>
                this.props.actions.updateItem({
                  id: entity.id,
                  _metaFields: {
                    ...API_RESOURCE.SSL_LE
                  },
                  _meta: {
                    notification: {
                      type: 'generic',
                      success: {
                        intlKey: 'translate.page.ssl.renew.le.success'
                      },
                      error: {
                        intlKey: 'translate.page.ssl.renew.le.error'
                      }
                    }
                  }
                })
              }
            >
              {intl.formatMessage({ id: 'translate.generic.confirm' })}
            </Button>
          </React.Fragment>
        }
        resources={[{ resourceName: API_RESOURCE.SSL_LE.resourceName, methods: ['PUT'] }]}
      >
        <Text>{intl.formatMessage({ id: 'translate.page.ssl.renew.le.dialog.content' })}</Text>
      </SGDialog>
    );
  }

  renderRenewPremiumCertificateDialog() {
    const { intl } = this.props;
    const { renewCertificateDialogPayload } = this.state;
    const entity = renewCertificateDialogPayload && renewCertificateDialogPayload.entity;

    return (
      <SGDialog
        id={REDUX_FORM.SSL_RENEW_PREMIUM_DIALOG}
        icon="renew"
        state="warning"
        title={intl.formatMessage({ id: 'translate.page.ssl.renew.premium.dialog.title' })}
        footer={
          <React.Fragment>
            <SGDialogCancel
              id={REDUX_FORM.SSL_RENEW_PREMIUM_DIALOG}
              label={intl.formatMessage({ id: 'translate.generic.close' })}
            />
            <Button color="primary" onClick={this.goToUA}>
              {intl.formatMessage({ id: 'translate.generic.confirm' })}
            </Button>
          </React.Fragment>
        }
      >
        {intl.formatMessage({ id: 'translate.page.ssl.renew.premium.dialog.content' })}
      </SGDialog>
    );
  }

  renderDeleteDialog = () => {
    const { intl } = this.props;
    const deletePayload = this.state.deleteCertificateDialogPayload;
    const entity = deletePayload ? deletePayload.entity : {};
    const title = intl.formatMessage(
      { id: 'translate.page.ssl.delete.dialog.title' },
      { ssl: entity.name, domain: entity.domain_name }
    );

    return (
      <DeleteDialog title={title} onSubmit={() => this.props.actions.deleteItem(deletePayload)}>
        {intl.formatMessage({ id: 'translate.page.ssl.delete.dialog.content' })}
      </DeleteDialog>
    );
  };

  renderPremiumDeleteDialog = () => {
    const { intl } = this.props;
    const deletePayload = this.state.deleteCertificateDialogPayload;
    const entity = deletePayload ? deletePayload.entity : {};
    const title = intl.formatMessage(
      { id: 'translate.page.ssl.delete.dialog.title' },
      { ssl: this.getCertificateLabel(entity) }
    );

    return (
      <DeleteDialog
        id={DIALOGS.SSL_PREMIUM_DELETE}
        title={title}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      >
        {intl.formatMessage({ id: 'translate.page.ssl.delete.dialog.premium.content' })}
      </DeleteDialog>
    );
  };

  renderContextMenu = (id, entity: any) => {
    const { intl, openSGDialog } = this.props;
    const isPremium = entity.label === PREMIUM_LABEL;
    const isImported = entity.label === IMPORTED_LABEL;
    const canRenew = new Date(entity.not_after * 1000).setDate(-30) > new Date().getTime();

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      entity,
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.ssl.delete.success.message',
            intlValues: {
              ssl: this.getCertificateLabel(entity)
            }
          },
          error: {
            intlKey: 'translate.page.ssl.delete.failed.message',
            intlValues: {
              ssl: this.getCertificateLabel(entity)
            }
          }
        }
      },
      _metaFields: { ...API_RESOURCE.SSL }
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.SSL.resourceName}
        items={[
          {
            vcsMethod: 'PUT',
            icon: 'ssl',
            label: intl.formatMessage({ id: 'translate.page.ssl.view.certificate' }),
            e2eAttr: 'context-menu-view-certificate',
            onClick: () => {
              this.props.actions.clearResource(SSL_CERT_STORE_KEY);

              this.props.actions.fetchItem({
                itemId: entity.id,
                urlParams: {
                  cert_data: 1
                },
                ...API_RESOURCE.SSL,
                resourceName: SSL_CERT_STORE_KEY
              });

              openSGDialog(REDUX_FORM.SSL_CERTIFICATE_DIALOG);
            }
          },
          {
            vcsMethod: 'GET',
            icon: 'lock',
            label: intl.formatMessage({ id: 'translate.page.ssl.go.to.https.enforce' }),
            onClick: () => redirectToURL({ pathname: ROUTES[ToolId.httpsEnforce] })
          },
          {
            vcsMethod: 'PUT',
            icon: 'renew',
            label: intl.formatMessage({ id: 'translate.generic.renew' }),
            disabled: isImported || canRenew,
            onClick: () =>
              this.setState({ renewCertificateDialogPayload: { entity } }, () =>
                openSGDialog(isPremium ? REDUX_FORM.SSL_RENEW_PREMIUM_DIALOG : REDUX_FORM.SSL_RENEW_LE_DIALOG)
              )
          },
          {
            vcsMethod: 'DELETE',
            icon: 'trash',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'context-menu-delete-certificate',
            onClick: () =>
              this.setState({ deleteCertificateDialogPayload: deletePayload }, () =>
                isPremium ? openSGDialog(DIALOGS.SSL_PREMIUM_DELETE) : openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  domainAll: state.pageItems[API_RESOURCE.DOMAIN_ALL.resourceName] || [],
  ssl: state.pageItems[API_RESOURCE.SSL.resourceName],
  sslCertificates: state.pageItems[SSL_CERT_STORE_KEY] || [],
  isUAEnabled: isACLActionEnabled(state, getToolMainEndpoint(ToolId.ssl), 'can_order'),
  siteId: getCurrentSiteId(state)
});

export default indexWithCRUD(mapStateToProps, { navigateToUA, createNotification, ...sgDialogActions })(
  SSLPage,
  API_RESOURCE.SSL,
  API_RESOURCE.DOMAIN_ALL
);
