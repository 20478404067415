import { RootState } from '../../../reducers';
import { getPrioritizedEntities } from './get-prioritized-entities';
import { codeEditor } from './code-editor';

export type DeleteDialogPayload = {
  prioritizedEntities: any;
  codeEditorEntitiesThatNeedToBeClosed: any;
  selectedNavigationEntity: any;
  fileManagerState: any;
};

export const getDeleteDialogPayload = (state: RootState): DeleteDialogPayload => ({
  prioritizedEntities: getPrioritizedEntities(state.fileManager),
  codeEditorEntitiesThatNeedToBeClosed: codeEditor(state.fileManager),
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
  fileManagerState: state.fileManager
});
