import * as React from 'react';
import { injectIntl } from 'react-intl';
import { cn, Colors, Flex, Icon, IconButton, Text, Tile } from '@siteground/styleguide';
import KEYS from '@siteground/styleguide/lib/utils/keys';
import { getPageInfo, ToolId } from '../../../../core/constants/route-info';
import './dashboard-tile.scss';

type Props = {
  intl: Intl;
  isInEditView: boolean;
  isTilePinned: boolean;
  handlePinClick: (event) => void;
  onClick: (event) => void;
  item: {
    toolId: ToolId;
  };
};

type State = {
  triggerClose: boolean;
  didMount: boolean;
};

class DashboardTile extends React.Component<Props, State> {
  readonly state = {
    triggerClose: false,
    didMount: false
  };

  domRef = React.createRef() as any;

  componentDidMount() {
    this.setState({ didMount: true });
  }

  handleKeyDown = (event) => {
    const domRef = this.domRef && this.domRef.current;

    if (!domRef || event.target !== domRef.firstElementChild) {
      // TODO extend styleguide components to have refs
      return;
    }

    switch (event.key) {
      case KEYS.SPACE:
      case KEYS.ENTER:
        event.preventDefault();
        event.stopPropagation();

        (domRef.firstElementChild as any).click();
        break;
      default:
        break;
    }
  };

  renderTileContent = () => {
    const { item, isInEditView, handlePinClick, isTilePinned, intl } = this.props;
    if (!item.toolId) {
      return null;
    }
    const { title, icon, iconColor, multicolor } = getPageInfo(item.toolId);

    const iconClasses = cn('dashboard-tile__pin-icon', isTilePinned && 'dashboard-tile__pin-icon--pinned');

    return (
      <React.Fragment>
        {isInEditView && (
          <IconButton
            className={iconClasses}
            icon={isTilePinned ? 'pin-filled' : 'pin'}
            shape="circle"
            onClick={(event) => {
              event.stopPropagation();
              this.setState({ triggerClose: true }, () => setTimeout(handlePinClick, 300));
            }}
            aria-label={`${isTilePinned ? 'Unpin' : 'Pin'} ${intl.formatMessage({ id: title })}`}
          />
        )}

        <Flex direction="column" align="center">
          <Icon size="90" name={icon} color={iconColor as Colors} multicolor={multicolor} />

          {title && (
            <Text className="dashboard-tile__title" weight="bold" align="center">
              {intl.formatMessage({ id: title })}
            </Text>
          )}
        </Flex>
      </React.Fragment>
    );
  };

  render() {
    const { isInEditView, onClick } = this.props;
    const { didMount, triggerClose } = this.state;
    const classes = cn(
      'dashboard-tile',
      !isInEditView && 'dashboard-tile--clickable',
      isInEditView && didMount && 'dashboard-tile--animation-mount',
      isInEditView && triggerClose && 'dashboard-tile--animation-unmount'
    );

    return (
      <div ref={this.domRef} className={classes} onClick={onClick}>
        <Tile className="dashboard-tile__content" tabIndex={0} onKeyDown={this.handleKeyDown}>
          {this.renderTileContent()}
        </Tile>
      </div>
    );
  }
}

export default injectIntl(DashboardTile);
