import * as React from 'react';
import { useIntl } from 'react-intl';

import { SGDialog, SGDialogCancel, SGDialogForm } from '../../../components/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';

import { Button, FormLabel, Flex, RadioButton, Spacer } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';

export const UpdatePhpExtensionDialog = ({ extension, environment, onSubmit, closeSGDialog }) => {
  const intl = useIntl();
  const [enabled, setEnabled] = React.useState(null);
  React.useEffect(() => {
    setEnabled(extension?.enabled);
  }, [extension]);

  return (
    <SGDialog
      id={DIALOGS.CHANGE_PHP_EXTENSION_SETTING}
      icon="edit"
      state="warning"
      title={intl.formatMessage(
        { id: 'translate.page.phpSettings.extensions.change.status' },
        { extension: extension?.name }
      )}
      resources={[
        {
          resourceName: API_RESOURCE.PHP_EXTENSION.resourceName,
          methods: ['POST', 'DELETE']
        }
      ]}
      footer={
        <React.Fragment>
          <SGDialogCancel id={DIALOGS.CHANGE_PHP_EXTENSION_SETTING} />

          <Button
            data-e2e="dialog-submit"
            color="primary"
            onClick={() => {
              if (extension.enabled === enabled) {
                return closeSGDialog(DIALOGS.CHANGE_PHP_EXTENSION_SETTING);
              }
              onSubmit(extension, enabled);
            }}
          >
            {intl.formatMessage({ id: 'translate.generic.confirm' })}
          </Button>
        </React.Fragment>
      }
      onCloseHandler={() => {
        setEnabled(extension.enabled);
      }}
    >
      <FormLabel>{intl.formatMessage({ id: 'translate.generic.status' })}</FormLabel>
      <Flex>
        <RadioButton name="on" checked={enabled === 1} onChange={() => setEnabled(1)}>
          {intl.formatMessage({ id: 'translate.generic.on' })}
        </RadioButton>

        <Spacer size="medium" />

        <RadioButton name="pff" checked={enabled === 0} onChange={() => setEnabled(0)}>
          {intl.formatMessage({ id: 'translate.generic.off' })}
        </RadioButton>
      </Flex>
    </SGDialog>
  );
};

export default UpdatePhpExtensionDialog as typeof UpdatePhpExtensionDialog;
