import { RootState } from '../../../reducers';

export type CreateFileDialogPayload = {
  codeEditorIsVisible: boolean;
  selectedNavigationEntity: any;
};

export const getCreateFileDialogPayload = (state: RootState): CreateFileDialogPayload => ({
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible,
  selectedNavigationEntity: state.fileManager.selectedNavigationEntity
});
