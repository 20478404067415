import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button, Flex, Grid, Text } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import CreateBox from '../../../components/create-box';
import FormattedTranslation from '../../../components/formatted-translation';
import VCS from '../../../components/visibility-control-service';

type Props = {
  router: {
    location: {
      query: any;
    };
    push: Function;
  };
  handleTryAgain: () => void;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.APP_STAGING;
const formName = REDUX_FORM.CREATE_STAGING;

const CreateContainer: React.FC<Props> = ({ children, router, handleTryAgain }) => {
  const intl = useIntl();

  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.staging.create.title' })}
        resourceName={resourceName}
        formName={formName}
        resources={[
          {
            requestTypeName: customRequestTypes.REQUEST_UKNOWN_WP_FILES
          },
          {
            resourceName: API_RESOURCE.APP_STAGING.resourceName,
            methods: ['POST']
          },
          {
            requestTypeName: customRequestTypes.REQUEST_ACCOUNT_UPGRADE_PLANS
          }
        ]}
        renderCustomNotification={({ notification, removeNotification, renderDefaultNotificationTemplate }) => {
          switch (true) {
            case notification.responseData?.hasForeignKeyError:
              return (
                <Grid gap="responsive">
                  <Text align="center">
                    <FormattedTranslation id="translate.page.staging.notification.deploy.fail.description" />
                  </Text>

                  <Flex justify="center">
                    <Button
                      type="outlined"
                      color="secondary"
                      data-e2e="try-again"
                      onClick={() => {
                        removeNotification();
                        handleTryAgain();
                      }}
                    >
                      {intl.formatMessage({ id: 'translate.generic.try.again' })}
                    </Button>

                    {renderDefaultNotificationTemplate()}
                  </Flex>
                </Grid>
              );
            case notification.state === 'error':
              return renderDefaultNotificationTemplate();
            case notification.state === 'success':
              return (
                <Grid gap="responsive">
                  <Text align="center">
                    {intl.formatMessage({ id: 'translate.page.staging.create.success.message.text' })}
                  </Text>

                  <Flex justify="center">
                    <Button
                      type="outlined"
                      color="secondary"
                      data-e2e="create-new"
                      onClick={() =>
                        router.push({
                          pathname: `${ROUTES[ToolId.protectedURLs]}`,
                          query: router.location.query,
                          state: { domainId: notification.responseData.domain_id }
                        })
                      }
                    >
                      {intl.formatMessage({ id: 'translate.page.staging.go.to.protected.urls' })}
                    </Button>

                    {renderDefaultNotificationTemplate()}
                  </Flex>
                </Grid>
              );
            default:
              return null;
          }
        }}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default CreateContainer;
