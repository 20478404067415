import { RootState } from '../../web/reducers';

export const getCountryNameByCode = ({ countries }: Partial<RootState>, countryCode: string) => {
  const MAPPED_COUNTRIES_EXCEPTIONS = {
    eu: 'EU',
    other: 'Other'
  };

  if (countries[countryCode]) {
    return countries[countryCode].name;
  }

  return MAPPED_COUNTRIES_EXCEPTIONS[countryCode] || countryCode;
};
