import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Button, Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../../../core/constants/common';

import FormInput from '../../../../components/form-input';
import { SGForm, SGSubmitButton } from '../../../../components/sg-form';
import { withSpanelForm } from '../../../../components/sg-spanel-forms';
import FormPassword from '../../../../components/form-password';
import { SGDialog, SGDialogCancel } from '../../../../components/sg-dialog';

type Domains = {
  id: string;
  name: string;
};

type Props = {
  change: Function;
  closeSGDialog: Function;
  locationState: { domainId?: number };
  domainOptions: Domains[];
  getFormValues: Function;
  onSubmit: any;
  onChangeUsersDropdown: (value) => any;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => any;
  intl: Intl;
};

const formName = REDUX_FORM.CREATE_ITEM_LOCATION_USER_DIALOG;

const DialogForm = (props: Props) => {
  const { validationUtils, sPanelFormSubmit, intl, onSubmit, closeSGDialog, getFormValues } = props;

  const { required, validationWithMetaApi, password } = validationUtils;

  return (
    <SGDialog
      id={DIALOGS.USER_CREATE_NEW_PROTECTED_URL}
      state="active"
      icon="add-user"
      title={intl.formatMessage({ id: 'translate.page.protected.create.new.user' })}
      footer={
        <div>
          <SGDialogCancel id={DIALOGS.USER_CREATE_NEW_PROTECTED_URL} />
          <Button
            color="primary"
            action="reset"
            data-e2e="dialog-navigate"
            onClick={() => {
              const formValues = getFormValues(formName);
              onSubmit(formValues);
            }}
          >
            {intl.formatMessage({ id: 'translate.generic.confirm' })}
          </Button>
        </div>
      }
    >
      <SGForm onSubmit={sPanelFormSubmit(formName)}>
        <Column smSpan="12">
          <Field
            name="username"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            placeholder={intl.formatMessage({ id: 'translate.generic.name' })}
            component={FormInput}
            validate={[required, validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
          />
        </Column>
        <Column smSpan="12">
          <Field
            name="password"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.password' })}
            placeholder={intl.formatMessage({ id: 'translate.generic.password.label' })}
            component={FormPassword}
            validate={[required, password]}
          />
        </Column>
      </SGForm>
    </SGDialog>
  );
};

const CreateNewUserDialog = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.LOCATION_USER,
      formName
    }
  }
})(DialogForm);

export default withSpanelForm(injectIntl(CreateNewUserDialog));
