import * as React from 'react';
import { Context, Title, TitleProps } from '@siteground/styleguide';

const FirstLevelTitle = ({ children, ...props }: Partial<TitleProps>) => {
  return (
    <Title level="1" weight="bold" density="comfortable" {...props}>
      {children}
    </Title>
  );
};

export default FirstLevelTitle;
