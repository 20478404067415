import * as React from 'react';
import { Switch } from '@siteground/styleguide';
import { requestData } from '../../../core/actions/request-data';
import { API_RESOURCE } from '../../../core/constants/api';
import customRequestTypes from '../../../core/constants/custom-request-types';
import indexWithCRUD from '../../components/indexWithCRUD';
import SGTable from '../../components/sg-table';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../components/visibility-control-service';

const { endpoint, resourceName, resourceNameMetaApi } = API_RESOURCE.USERVICE;

type Props = {
  intl: Intl;
  items: {
    uservice: any[];
  };
  actions: {
    deleteItem: DeleteItem;
    createItem: CreateItem;
  };
  requestData: RequestData;
  isPhone: boolean;
};

class MemcachedPage extends React.Component<Props, any> {
  getMemcachedService = () => {
    const { items = {} } = this.props;
    const data = items[resourceName] || [];

    return data.find((uservice) => Boolean(uservice && uservice.type === 'memcached'));
  };

  getFeatureId = () => {
    const featureData = this.getMemcachedService();
    return featureData ? featureData.id : 'mock-id';
  };

  renderCacheToggle = (enabled: boolean, { id }) => {
    const { intl, actions } = this.props;

    return (
      <VCS resourceName={resourceNameMetaApi} hasMethod="PUT">
        <Switch
          checked={enabled}
          onChange={(event) => {
            const cacheEnabled = Boolean(event.target.checked);

            const meta: { notification: SpanelNotification } = {
              notification: {
                type: 'generic',
                success: {
                  intlKey: cacheEnabled
                    ? 'translate.page.superCacher.memcached.enable.success.notification'
                    : 'translate.page.superCacher.memcached.disable.success.notification'
                },
                error: {
                  intlKey: cacheEnabled
                    ? 'translate.page.superCacher.memcached.enable.fail.notification'
                    : 'translate.page.superCacher.memcached.disable.fail.notification'
                }
              }
            };

            if (cacheEnabled) {
              actions.createItem({
                _metaFields: {
                  ...API_RESOURCE.USERVICE,
                  itemId: id
                },
                _meta: meta,
                type: 'memcached',
                settings: {}
              });
            } else {
              actions.deleteItem({
                _meta: meta,
                _metaFields: { ...API_RESOURCE.USERVICE },
                itemId: id
              });
            }
          }}
        />
      </VCS>
    );
  };

  render() {
    const { intl, isPhone, actions, requestData } = this.props;
    const tableData = [{ enabled: this.getMemcachedService(), id: this.getFeatureId(), rowKeyId: 'allSites' }];
    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.generic.name' }),
        accessor: 'enabled',
        render: () => intl.formatMessage({ id: 'translate.page.superCacher.all.sites.label' }),
        style: !isPhone ? { width: '75%' } : null
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.static_cache_enabled' }),
        accessor: 'enabled',
        render: this.renderCacheToggle
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'enabled',
        render: () => (
          <TableContextMenu
            resourceName={resourceNameMetaApi}
            items={[
              {
                vcsMethod: 'PUT',
                icon: 'flush',
                label: intl.formatMessage({ id: 'translate.page.domainCache.flush_cache' }),
                e2eAttr: 'table-action-flush-cache',
                visibleOnDesktop: true,
                disabled: !this.getMemcachedService(),
                onClick: () =>
                  requestData({
                    requestTypeName: customRequestTypes.MEMCACHE_FLUSH_ALL,
                    payload: {
                      _metaFields: {
                        endpoint: `${API_RESOURCE.USERVICE.endpoint}/${this.getFeatureId()}`,
                        method: 'PUT'
                      },
                      _meta: {
                        notification: {
                          type: 'generic',
                          success: {
                            intlKey: 'translate.page.domainCache.cache_flushed_for_all'
                          },
                          error: {
                            intlKey: 'translate.page.domainCache.memcached_flushed_all.failed'
                          }
                        }
                      }
                    }
                  })
              }
            ]}
          />
        )
      }
    ];

    return (
      <VCS resourceName={API_RESOURCE.USERVICE.resourceNameMetaApi} hasMethod="GET">
        <SGTable
          addOffsetOnMobile
          shadow={false}
          mobileLayout="card-flat"
          rowKey="rowKeyId"
          resources={[{ resourceName, methods: ['GET'] }, { requestTypeName: customRequestTypes.MEMCACHE_FLUSH_ALL }]}
          rowResources={[
            {
              resourceName,
              methods: ['DELETE', 'POST']
            }
          ]}
          data={tableData}
          columns={columns}
        />
      </VCS>
    );
  }
}

export default indexWithCRUD(
  ({ environment, siteMetaApi }) => ({
    isPhone: environment.isPhone
  }),
  { requestData }
)(MemcachedPage, API_RESOURCE.USERVICE);
