import { ResellerLoginRoutes, RESSELLER_LOGIN_ROUTE, ToolId } from './route-info';

export const RESELLER_LOGIN_ROUTES = {
  [ResellerLoginRoutes.changePassword]: '/recover/pass/verify/:identification_hash',
  [ResellerLoginRoutes.createPassword]: '/login/:identification_hash',
  [ResellerLoginRoutes.login]: `/${RESSELLER_LOGIN_ROUTE}(/:redirectBack)`,
  [ResellerLoginRoutes.recoverPassword]: '/recover-password'
};

export const ROUTES = {
  // SiteTools ROUTES
  [ToolId.dashboard]: '/dashboard',
  [ToolId.backup]: '/backup-restore',
  [ToolId.backupCreate]: '/backup-restore-manage',
  [ToolId.backupRestore]: '/backup-restore-history',
  [ToolId.cloudflare]: '/cloudflare',
  [ToolId.email]: '/email',
  [ToolId.emailAuth]: '/emailauth',
  [ToolId.emailAutoresponder]: '/email-autoresponder',
  [ToolId.emailFilters]: '/email-filters',
  [ToolId.emailForward]: '/email-forward',
  [ToolId.spamExperts]: '/spam-experts',
  [ToolId.googleWorkspace]: '/google-workspace',
  [ToolId.ssh]: '/ssh',
  [ToolId.cron]: '/cron',
  [ToolId.cronJob]: '/cron-job',
  [ToolId.cronNotification]: '/cron-notification',
  [ToolId.phpSettings]: '/php-settings',
  [ToolId.blockIPs]: '/location-ip-block',
  [ToolId.redirect]: '/redirect',
  [ToolId.cacher]: '/cacher',
  [ToolId.cdn]: '/cdn',
  [ToolId.cacherStatic]: '/cacher-static-cache',
  [ToolId.cacherDynamic]: '/cacher-dynamic-cache',
  [ToolId.cacherMemcached]: '/cacher-memcached',
  [ToolId.dns]: '/dns',
  [ToolId.mysql]: '/mysql',
  [ToolId.mysqlDatabase]: '/mysql-database',
  [ToolId.mysqlUser]: '/mysql-user',
  [ToolId.mysqlAccess]: '/mysql-access',
  [ToolId.mysqlPhpMyAdmin]: '/mysql-phpmyadmin',
  [ToolId.pgsql]: '/pgsql',
  [ToolId.pgsqlDatabase]: '/pgsql-database',
  [ToolId.pgsqlUser]: '/pgsql-user',
  [ToolId.pgsqlAccess]: '/pgsql-access',
  [ToolId.protected]: '/protected',
  [ToolId.protectedURLs]: '/protected-urls',
  [ToolId.protectedUsers]: '/protected-users',
  [ToolId.subdomain]: '/subdomain',
  [ToolId.parkedDomains]: '/parked-domains',
  [ToolId.ftp]: '/ftp',
  [ToolId.filemanager]: '/filemanager',
  [ToolId.wpAutoupdate]: '/wp-autoupdate',
  [ToolId.wpManage]: '/wp-manage',
  [ToolId.wpStaging]: '/wp-staging',
  [ToolId.wpTransfer]: '/wp-transfer',
  [ToolId.wpSearchAndReplace]: '/wp-replace',
  [ToolId.ssl]: '/ssl',
  [ToolId.statistics]: '/resource-stats',
  [ToolId.httpsEnforce]: '/https-enforce',
  [ToolId.errorLog]: '/error-log',
  [ToolId.accessLog]: '/access-log',
  [ToolId.appManager]: '/app-installer',
  [ToolId.git]: '/git',
  [ToolId.sitescanner]: '/sg-sitescanner',
  [ToolId.profile]: '/profile',
  [ToolId.appearance]: '/appearance',

  ...RESELLER_LOGIN_ROUTES
};
