import * as React from 'react';
import { Accordion, cn, Context, Flex, Grid, Text, textToHTML } from '@siteground/styleguide';
import PartialLoader from '../partial-loader';
import './setting-list-item.scss';

type SettingListItemProps = {
  children: any;
  className?: string;
  disabled?: boolean;
  disableExpand?: boolean;
  title: any;
  info?: string;
  resources?: LoaderResource[];
};

const SettingListItem = (props: SettingListItemProps) => {
  const { children, className, disabled, disableExpand, title, info, resources } = props;

  const classes = ['settings-list-item-wrapper', disabled && 'settings-list-item-wrapper--disabled', className]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={classes} data-e2e="settings-list-item">
      <PartialLoader resources={resources} position="absolute" density="none" hideSpinner>
        <Context.Consumer>
          {({ device }) => (
            <Grid
              className={cn(!device.isPhone && 'settings-list-item__content')}
              gap="responsive"
              padding={['x-small', 'responsive', 'x-small', 'responsive']}
            >
              <Accordion
                className="settings-list-item"
                disableExpand={disableExpand}
                title={() => (
                  <Text key="title" weight="bold">
                    {title}
                  </Text>
                )}
                style={{ padding: '0' }}
              >
                {info && (
                  <Text size="small" color="dark" style={{ maxWidth: '800px' }}>
                    {textToHTML(info)}
                  </Text>
                )}
              </Accordion>

              <Flex align="center">{children}</Flex>
            </Grid>
          )}
        </Context.Consumer>
      </PartialLoader>
    </div>
  );
};

export default SettingListItem;
