import * as React from 'react';
import { reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../components/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../../components/sg-form';
import CronForm from '../cron-form';

type Props = {
  validationUtils: ValidationUtils;
  form: string;
  pristine: boolean;
  submitFailed: boolean;
  change: (field: string, value: string) => void;
  formErrors: (formName: string) => object;
  sPanelFormSubmit: (fieldName: string) => any;
  intl: Intl;
};

export class Form extends React.Component<Props, any> {
  render() {
    const { validationUtils, sPanelFormSubmit, intl, form, formErrors, submitFailed, pristine, change } = this.props;

    return (
      <SGForm onSubmit={sPanelFormSubmit(form)} name={form}>
        <CronForm
          validationUtils={validationUtils}
          intl={intl}
          formName={form}
          formErrors={formErrors}
          submitFailed={submitFailed}
          pristine={pristine}
          change={change}
        />
        <Column smSpan="12">
          <SGSubmitButton formName={form} />
        </Column>
      </SGForm>
    );
  }
}

const CreateCronJob = reduxForm({
  form: REDUX_FORM.CREATE_ITEM_CRON,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.CRON,
      formName: REDUX_FORM.CREATE_ITEM_CRON
    }
  }
})(Form);

export default withSpanelForm(injectIntl(CreateCronJob));
