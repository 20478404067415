import { RouteActions, routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import countries, { Countries } from '../core/reducers/countries';
import environment from '../core/reducers/environment';
import dir from '../core/reducers/fetch-dir-by-path';
import httpRequests, { HttpRequests } from '../core/reducers/http-requests';
import i18n from '../core/reducers/i18n';
import indexWithCrud, { IndexWithCrud } from '../core/reducers/index-with-crud';
import lastActions, { LastActions } from '../core/reducers/last-actions';
import siteMetaApi from '../core/reducers/meta-api';
import nemoStore, { NemoStore } from '../core/reducers/nemo-store/nemo-store';
import notifications, { NotificationsState } from '../core/reducers/notifications';
import pageItems from '../core/reducers/page-items';
import sgSiteScanner, { SgSiteScanner } from '../core/reducers/page/sg-sitescanner';
import ping, { PingBackend } from '../core/reducers/ping-backend';
import session from '../core/reducers/session';
import sgDialog from '../core/reducers/sg-dialog';
import sgTable from '../core/reducers/sg-table';
import sites from '../core/reducers/sites';
import support from '../core/reducers/support';
import tasks from '../core/reducers/tasks';
import webstats, { Webstats } from '../core/reducers/webstats';

import fileManager from './file-manager/core/reducers';

import * as fromFileManager from './file-manager/core/reducers/utils/permissions';

export interface RootState {
  environment: any;
  siteMetaApi: SiteMetaApi;
  httpRequest: any;
  routing: RouteActions;
  session: any;
  sites: Sites;
  form: any;
  pageItems: any;
  notifications: NotificationsState;
  fileManager: any;
  i18n: {
    showMessages: boolean;
    locale: string;
    messages: {};
  };
  tasks: Task[];
  webstats: Webstats;
  countries: Countries;
  dialog: any;
  table: any;
  nemoStore: NemoStore;
  httpRequests: HttpRequests;
  support: SupportPopupReducer;
  sgSiteScanner: SgSiteScanner;
  siteId: any;
  indexWithCrud: IndexWithCrud;

  ping: PingBackend;
  lastActions: LastActions;
}

export default combineReducers<RootState>({
  environment,
  siteMetaApi,
  routing,
  sites,
  session,
  pageItems,
  countries,
  webstats,
  notifications,
  form,
  i18n,
  fileManager,
  tasks,
  dir,
  nemoStore,
  dialog: sgDialog,
  table: sgTable,
  httpRequests,
  support,
  sgSiteScanner,
  indexWithCrud,

  ping,
  lastActions
});

// TODO: move it where it needs to be
export const getFileMangerContextPermission = (state) => fromFileManager.getFileMangerContextPermission(state);
export const getFileMangerToolbarPermission = (state) => fromFileManager.getFileMangerToolbarPermission(state);
