import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import {
  Container,
  Context,
  Flex,
  GetDeviceInformationType,
  Grid,
  Icon,
  Link,
  Text,
  Title
} from '@siteground/styleguide';
import { decodeJWT } from '@siteground/styleguide/lib/utils/';
import { navigateToUA } from '../../../../core/actions/nemo-store';
import bytesToSize from '../../../../core/common/size-converter';
import { getCurrentSiteAccountId, getSiteIp } from '../../../../core/selectors';
import { getCurrentSiteToken } from '../../../../core/selectors/session';
import { getNsInfo, getResourceUsage } from '../../../../core/selectors/site-meta-api';
import SecondLevelTitle from '../../../components/titles/second-level-title';
import { RootState } from '../../../reducers';
import './site-info-section.scss';

type StateProps = {
  accountId: string;
  ip: string;
  nsInfo: string[];
  resourceUsage: {
    inodes_used: number;
    disk_used: number;
    disk_used_bytes: number;
  };
  siteToken: string;
};

type DispatchProps = {
  navigateToUA: typeof navigateToUA;
} & WrappedComponentProps;

type OwnProps = {
  device?: GetDeviceInformationType;
};

interface Props extends StateProps, DispatchProps, OwnProps {}

const SiteInfoSection = (props: Props) => {
  const { device, nsInfo = [], ip, intl, siteToken, accountId, resourceUsage, navigateToUA } = props;
  const showIcon = device.width >= 1200;
  const diskUsed = resourceUsage && resourceUsage.disk_used_bytes;
  const usedInodes = resourceUsage && resourceUsage.inodes_used;

  const decodeToken = siteToken && decodeJWT(siteToken);
  const isOwner = decodeToken && decodeToken.roles.includes(1);

  let usedDiskSpaceToShow;
  if (diskUsed) {
    usedDiskSpaceToShow = diskUsed / 1024 / 1024 <= 1 ? '1 MB' : bytesToSize(diskUsed);
  }

  return (
    <div>
      <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.dashboard.site-info.title' })}</SecondLevelTitle>

      <Container padding="responsive">
        <Grid gap="responsive">
          <Grid sm="2" gap="responsive">
            <Grid className="sg-info-grid" gap="none" padding="none" border="small">
              <Flex padding={['none', 'responsive']} background="light">
                <Title level="5" density="cozy">
                  {intl.formatMessage({ id: 'translate.page.dashboard.disk.usage.title' })}
                </Title>
              </Flex>

              <Grid className="sg-info-grid__content" padding="responsive">
                <Flex tabIndex={0}>
                  {showIcon && (
                    <div className="sg-info-icon-wrapper">
                      <Icon color="grassy" name="product-web-space" size="40" />
                    </div>
                  )}

                  <Grid gap="xx-small">
                    <Text size="small">{intl.formatMessage({ id: 'translate.page.dashboard.web.space.title' })}</Text>

                    <Text weight="extra-bold">{usedDiskSpaceToShow}</Text>
                  </Grid>
                </Flex>

                <div className="sg-site-info-separator" />

                <Flex tabIndex={0}>
                  {showIcon && (
                    <div className="sg-info-icon-wrapper">
                      <Icon color="ocean" name="product-inodes" size="40" />
                    </div>
                  )}

                  <Grid gap="xx-small" style={{ alignContent: 'baseline' }}>
                    <Text size="small">{intl.formatMessage({ id: 'translate.page.dashboard.inodes.title' })}</Text>

                    <Text weight="extra-bold">{usedInodes}</Text>
                  </Grid>
                </Flex>
              </Grid>
            </Grid>

            <Grid className="sg-info-grid" gap="none" padding="none" border="small">
              <Flex padding={['none', 'responsive']} background="light">
                <Title level="5" density="cozy">
                  {intl.formatMessage({ id: 'translate.page.dashboard.location.title' })}
                </Title>
              </Flex>

              <Grid className="sg-info-grid__content" padding="responsive">
                <Flex tabIndex={0}>
                  {showIcon && (
                    <div className="sg-info-icon-wrapper">
                      <Icon color="ocean" name="product-ip-address" size="40" />
                    </div>
                  )}

                  <Grid gap="xx-small">
                    <Text size="small">{intl.formatMessage({ id: 'translate.page.dashboard.site.ip.title' })}</Text>

                    <Text weight="extra-bold">{ip}</Text>
                  </Grid>
                </Flex>

                <div className="sg-site-info-separator" />

                <Flex tabIndex={0}>
                  {showIcon && (
                    <div className="sg-info-icon-wrapper">
                      <Icon color="salmon" name="product-dedicated-ip" size="40" />
                    </div>
                  )}

                  <Grid gap="xx-small">
                    <Text size="small">
                      {intl.formatMessage({ id: 'translate.page.dashboard.name-servers.title' })}
                    </Text>

                    <div>
                      {nsInfo.map((ns) => (
                        <Text key={ns} weight="extra-bold">
                          {ns}
                        </Text>
                      ))}
                    </div>
                  </Grid>
                </Flex>
              </Grid>
            </Grid>
          </Grid>

          {isOwner && (
            <FormattedMessage
              tagName="span"
              id="translate.page.dashboard.site-info.owner.info.new"
              values={{
                a: (text) => (
                  <Link
                    onClick={() =>
                      navigateToUA({
                        page: 'services/hosting',
                        tab: 'statistics',
                        accountId
                      })
                    }
                  >
                    {text}
                  </Link>
                )
              }}
            />
          )}
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (store: RootState) => ({
  resourceUsage: getResourceUsage(store),
  siteToken: getCurrentSiteToken(store),
  accountId: getCurrentSiteAccountId(store),
  nsInfo: getNsInfo(store.siteMetaApi),
  ip: getSiteIp(store)
});

export default connect<{}, any, OwnProps>(mapStateToProps, {
  navigateToUA
})(
  injectIntl((props: any) => (
    <Context.Consumer>{({ device }) => <SiteInfoSection {...props} device={device} />}</Context.Consumer>
  ))
);
