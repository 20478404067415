import { TOGGLE_TRANSLATION_KEYS } from '../constants/i18n';
import { CHANGE_I18N, LOAD_TRANSLATIONS } from '../constants/actions';
import customRequestTypes from '../constants/custom-request-types';

export const toggleTranslationKeys = () => ({ type: TOGGLE_TRANSLATION_KEYS });

export const loadTranslations = ({ fileName }) => ({
  type: LOAD_TRANSLATIONS,
  payload: {
    fileName
  },
  requestTypeName: customRequestTypes.LOAD_TRANSLATIONS
});

export const changeI18N = ({ locale, messages }) => ({
  type: CHANGE_I18N,
  payload: {
    locale,
    messages
  }
});
