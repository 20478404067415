import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { closeSGDialog, openSGDialog } from '../../../../core/actions/sg-dialog';
import { REDUX_FORM } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { RootState } from '../../../reducers';
import { SGDialogForm } from '../../../components/sg-dialog';
import { clearFileManagerStore, requestFilesMove } from '../../core/actions/file-manager';
import * as Constants from '../../core/constants/actions';
import { initialFileManagerState } from '../../core/reducers/utils/initial-state';
import { MoveDialogPayload } from '../../core/selectors/get-move-dialog-payload';
import {
  getEntityByPath,
  getEntityName,
  getEntityParentPath,
  getEntityPath,
  getEntityReadableName
} from '../../core/utils';
import MoveForm from './move-form';

type StateProps = {
  moveDialogPayload: MoveDialogPayload;
};

type DispatchProps = {
  closeSGDialog: typeof closeSGDialog;
  clearFileManagerStore: typeof clearFileManagerStore;
  openSGDialog: typeof openSGDialog;
  requestFilesMove: typeof requestFilesMove;
};

interface Props extends StateProps, DispatchProps {}

const MoveDialog = (props: Props) => {
  const { closeSGDialog, clearFileManagerStore, moveDialogPayload, requestFilesMove, openSGDialog } = props;
  const { prioritizedEntities, selectedNavigationEntity, fileManagerState } = moveDialogPayload;

  const entities = prioritizedEntities || [];
  const entity = entities[0];
  const entityName = getEntityReadableName(entity);
  const entityPath = entity && getEntityPath(entity).replace(getEntityName(entity), '');
  const intl = useIntl();
  const title =
    entities.length > 1
      ? intl.formatMessage({ id: 'translate.file.manager.move.multiple.dialog.title' }, { count: entities.length })
      : intl.formatMessage({ id: 'translate.file.manager.move.dialog.title' }, { entityName });

  return (
    <SGDialogForm
      name={REDUX_FORM.FILE_MANAGER_MOVE_ENTITY}
      icon="move"
      title={title}
      resources={[
        { requestTypeName: customRequestTypes.FILE_MANAGER_POST },
        { requestTypeName: customRequestTypes.FILE_MANAGER_MOVE_REQUEST }
      ]}
    >
      {entityPath && (
        <MoveForm
          openSGDialog={openSGDialog}
          initialValues={{
            dest: entityPath,
            _metaFields: {
              resourceNameMetaApi: Constants.FILE_MANGER_API_DIR_MOVE.replace('/', ''),
              endpoint: Constants.FILE_MANGER_API_DIR_MOVE
            }
          }}
          onSubmit={({ dest }) => {
            const clearedStore: any = {};
            const entries = entities.map((e) => getEntityPath(e));
            const selectedNavigationEntityForDelete = entities.find(
              (e) => getEntityPath(e) === getEntityPath(selectedNavigationEntity)
            );

            if (selectedNavigationEntityForDelete) {
              const entityParentPath = getEntityParentPath(selectedNavigationEntityForDelete);
              clearedStore.selectedNavigationEntity = getEntityByPath(entityParentPath, fileManagerState);
            }

            requestFilesMove(
              {
                endpoint: Constants.FILE_MANGER_API_DIR_MOVE,
                urlParams: {
                  safe: 1,
                  entries,
                  dest
                },
                entity: entities[0],
                clearFileManagerStore: clearedStore
              },
              () => {
                clearFileManagerStore({ selectedContentEntities: initialFileManagerState.selectedContentEntities });
                closeSGDialog(REDUX_FORM.FILE_MANAGER_MOVE_ENTITY);
              }
            );
          }}
        />
      )}
    </SGDialogForm>
  );
};

const mapStateToProps = (state: RootState) => ({
  moveDialogPayload: state.dialog[REDUX_FORM.FILE_MANAGER_MOVE_ENTITY] || {}
});

const mapDispatchToProps = {
  closeSGDialog,
  openSGDialog,
  requestFilesMove,
  clearFileManagerStore
};

export default connect<StateProps, DispatchProps, any>(mapStateToProps, mapDispatchToProps)(MoveDialog);
