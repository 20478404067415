import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@siteground/styleguide';
import RemoteSubmit from '../sg-form-util/remote-submit';

type Props = {
  e2eAttr?: string;
  formName: string;
  label: string;
};

const SubmitButton = ({ e2eAttr = 'dialog-submit', formName, label }: Props) => {
  const intl = useIntl();
  return (
    <RemoteSubmit
      formName={formName}
      children={({ valid, pristine, spanelFormSubmit }) => (
        <Button color="primary" action="button" data-e2e={e2eAttr} onClick={spanelFormSubmit}>
          {label || intl.formatMessage({ id: 'translate.generic.confirm' })}
        </Button>
      )}
    />
  );
};

export default SubmitButton;
