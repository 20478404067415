import { call, put, select, takeLatest, take, fork } from 'redux-saga/effects';

import { clientApi } from '../api/client';
import * as sessionActions from '../actions/session';
import * as Actions from '../constants/actions';
import * as fetchActions from '../actions/fetch';
import { createNotification } from '../actions/notifications';
import { handleNemoApiRequest } from './handle-nemo-api-request';
import { redirectLocation } from '../utils/redirect-to-url';

export function* verifyUser({ username }) {
  return yield call(clientApi(CONFIG.NEMO_VERIFY_USER_API, 'POST', { username }));
}

export function* signIn({ username, password, remember_me, redirectUrl }) {
  const loginResponse = yield call(
    clientApi(`/auth/${CONFIG.NEMO_CLIENT_TOKEN_API}`, 'POST', { username, password, remember_me })
  );

  yield fork(function* () {
    // wait for sessionActions.storeSessionData
    yield take(Actions.STORE_SESSION_DATA);
    redirectLocation(redirectUrl);
  });

  yield put(sessionActions.storeSessionData({ data: loginResponse.data }));

  // waiting forever (it is not possible to check if redirect is finished)
  yield take('REDIRECT');
}

export function* loginSucceeded({ payload }) {
  const { loginResponse, redirectUrl } = payload;
}

export default function* login() {
  yield takeLatest(Actions.VERIFY_USER, function* (action: NemoRequestAction) {
    yield call(handleNemoApiRequest(verifyUser, action), action.payload);
  });

  yield takeLatest(Actions.SIGN_IN, function* (action: NemoRequestAction) {
    yield call(handleNemoApiRequest(signIn, action), action.payload);
  });
}
