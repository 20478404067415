import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Checkbox, Flex, Grid, Icon, Text, Tree } from '@siteground/styleguide';
import areEqual from '@siteground/styleguide/lib/utils/are-equal';
import * as actions from '../../../../../core/actions/crud';
import bytesToSize from '../../../../../core/common/size-converter';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import DateWithTime from '../../../../components/date-with-time';
import FormCheckbox from '../../../../components/form-checkbox';
import ErrorNotice from '../../../../components/form-error-notice';
import FormRadioButton from '../../../../components/form-radio-button';
import InformationIcon from '../../../../components/information-icon/information-icon';
import { withSpanelForm } from '../../../../components/sg-spanel-forms/index';
import { ENTITY_ICONS } from '../../../../core/constants/entity-icons';
import { DIALOG_LIST_STYLES } from './constants';
import './styles.scss';

const RESTORE_DATA_FIELD = 'restore_data';

const lastTreeColumnStyle: any = { minWidth: '70px', textAlign: 'right' };
const formName = REDUX_FORM.BACKUP_RESTORE_FILES_DIALOG;

class RestoreFilesForm extends React.Component<any, any> {
  readonly state = {
    checked: [],
    expanded: [],
    isAllTreeNodesChecked: false
  };

  componentDidMount() {
    const { actions, entity } = this.props;

    actions.fetchItems({
      ...API_RESOURCE.BACKUP_RESTORE_FILES,
      urlParams: {
        id: entity.id
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { isAllTreeNodesChecked } = this.state;
    const { backupFiles } = this.props;

    if (!areEqual(prevProps.backupFiles, backupFiles) && isAllTreeNodesChecked) {
      this.setState({ checked: this.getAllTreeNodesPath(backupFiles) });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearResource(API_RESOURCE.BACKUP_RESTORE_FILES.resourceName, { '/': [] });
  }

  fetchDirectory = (entity) => {
    const database = this.props.entity;

    this.props.actions.fetchItems({
      ...API_RESOURCE.BACKUP_RESTORE_FILES,
      urlParams: {
        id: database.id,
        dir: entity._meta.path
      }
    });
  };

  mapDataToTree = (entity, data) => {
    const entityPath = entity._meta.path;
    const items = data[entityPath];
    const isFile = entity.t === 'f';

    return {
      entity,
      value: entityPath,
      label: this.renderTreeNode,
      forceShowToggleIcon: !isFile,
      children: items && items.map((ent) => this.mapDataToTree(ent, data))
    };
  };

  getAllTreeNodesPath(files = this.props.backupFiles) {
    const backupFiles: any = Object.values(files);

    return backupFiles.reduce((a, b) => a.concat(b)).map((entity) => entity._meta.path);
  }

  getDdialogContentPadding = () => {
    return this.props.environment.isDesktop ? 'xx-large' : 'medium';
  };

  renderTreeNode = (entity) => {
    const { environment } = this.props;

    return (
      <Flex align="center" wrap="nowrap" style={{ width: '100%' }}>
        <Icon name={ENTITY_ICONS[entity.t]} color="lighter" size="18" />

        <div style={{ flex: '1 1 0', maxWidth: 'none', textAlign: 'left', position: 'relative', height: '22px' }}>
          <Text
            truncate
            style={{
              position: 'absolute',
              top: '0',
              left: '10px',
              width: 'calc(100% - 10px)',
              height: '100%'
            }}
          >
            {entity.n}
          </Text>
        </div>

        {!environment.isPhone && <DateWithTime date={entity.mt} />}

        {!environment.isPhone && (
          <div style={lastTreeColumnStyle}>{entity.t === 'f' ? '--' : bytesToSize(entity.s)}</div>
        )}
      </Flex>
    );
  };

  renderTree = () => {
    const { environment, intl, change, getFormErrors, backupFiles, submitFailed } = this.props;
    const formErrors = getFormErrors(formName);
    const horizontalPadding = this.getDdialogContentPadding();
    const nodes = backupFiles['/'].map((ent) => this.mapDataToTree(ent, backupFiles));
    const hasError = Boolean(formErrors[RESTORE_DATA_FIELD] && submitFailed);

    return (
      <React.Fragment>
        <Flex background="light" justify="flex-start" align="center" style={{ width: '100%' }}>
          <Grid padding={['none', 'none', 'none', horizontalPadding]} />

          <Grid padding={['none', 'none', 'none', 'small']} />

          <Checkbox
            checked={this.state.isAllTreeNodesChecked}
            aria-label={intl.formatMessage({ id: 'translate.aria-label.restore.files.select.all' })}
            onChange={(event) => {
              const checked = event.target.checked ? this.getAllTreeNodesPath() : [];

              this.setState({ checked, isAllTreeNodesChecked: event.target.checked }, () =>
                change(RESTORE_DATA_FIELD, checked)
              );
            }}
          />

          <Grid padding={['x-small', 'none', 'x-small', 'x-small']} style={{ flex: '1 1 0' }}>
            <Text color="dark" align="left">
              {intl.formatMessage({ id: 'translate.generic.name' })}
            </Text>
          </Grid>

          {!environment.isPhone && (
            <div>
              <Text color="dark">{intl.formatMessage({ id: 'translate.generic.last.modified' })}</Text>
            </div>
          )}

          {!environment.isPhone && (
            <Grid
              padding={['x-small', horizontalPadding, 'x-small', 'none']}
              style={{ ...lastTreeColumnStyle, boxSizing: 'content-box' }}
            >
              <Text color="dark">{intl.formatMessage({ id: 'translate.generic.size' })}</Text>
            </Grid>
          )}
        </Flex>

        <Grid padding={['none', horizontalPadding]} style={DIALOG_LIST_STYLES} tabIndex={-1}>
          <Flex justify="flex-start" style={{ width: '100%' }}>
            <Tree
              nodes={nodes}
              checked={this.state.checked}
              expanded={this.state.expanded}
              tabIndex={-1}
              onCheck={(checked, data) => this.setState({ checked }, () => change(RESTORE_DATA_FIELD, checked))}
              onExpand={(expanded, data) => {
                let checked = this.state.checked;
                if (data.expanded) {
                  checked = checked.filter((path) => path !== data.entity._meta.path);
                  this.fetchDirectory(data.entity);
                }

                change(RESTORE_DATA_FIELD, checked);
                this.setState({ expanded, checked });
              }}
            />
          </Flex>
        </Grid>

        <Flex border={['small', 'none', 'none', 'none']}>
          <Grid padding={['x-small', horizontalPadding]}>
            <Text
              align="left"
              color="light"
              transform="uppercase"
              size="small"
              weight="bold"
              className={hasError ? 'sg-text-label--error' : ''}
            >
              {intl.formatMessage(
                { id: 'translate.page.backup.restore.item.selected' },
                { count: this.state.checked.length }
              )}
            </Text>
          </Grid>
        </Flex>
      </React.Fragment>
    );
  };

  render() {
    const { intl, getFormValues, getFormErrors, submitFailed, validationUtils } = this.props;
    const formValues = getFormValues(formName);
    const isTermsVisible = formValues && formValues.custom_dest === 'false';
    const horizontalPadding = this.getDdialogContentPadding();
    const formErrors = getFormErrors(formName);
    const errors = Object.keys(formErrors).map((key) => ({
      field: key,
      label:
        key === 'terms'
          ? intl.formatMessage({ id: 'translate.page.backup.restore.files.error.notice.terms' })
          : intl.formatMessage({ id: 'translate.page.backup.restore.files.error.notice.restore_data' })
    }));

    return (
      <Grid>
        {submitFailed && (
          <Grid padding={['none', horizontalPadding]}>
            <ErrorNotice
              title={intl.formatMessage({ id: 'translate.page.backup.restore.error.notice.title' })}
              errors={errors}
            />
          </Grid>
        )}

        <Grid padding={['none', horizontalPadding]}>
          <Flex direction="column" style={{ textAlign: 'start' }}>
            <Field
              name="custom_dest"
              type="radio"
              value="false"
              validate={[validationUtils.required]}
              component={FormRadioButton}
            >
              {intl.formatMessage({ id: 'translate.page.backup.restore.files.restore.keep.original.false' })}
            </Field>

            <Field
              name="custom_dest"
              type="radio"
              value="true"
              validate={[validationUtils.required]}
              component={FormRadioButton}
            >
              {intl.formatMessage({ id: 'translate.page.backup.restore.files.restore.keep.original.true' })}
              &nbsp;
              <InformationIcon
                tooltip={intl.formatMessage({
                  id: 'translate.page.backup.restore.files.restore.keep.original.true.tooltip'
                })}
              />
            </Field>
          </Flex>
        </Grid>

        <Field name={RESTORE_DATA_FIELD} validate={[validationUtils.required]} component={() => null} />

        {this.renderTree()}

        {isTermsVisible && (
          <Grid padding={['none', horizontalPadding]}>
            <Flex>
              <Field expand name="terms" validate={[validationUtils.required]} component={FormCheckbox}>
                {intl.formatMessage({ id: 'translate.page.backup.restore.files.restore.terms' })}
              </Field>
            </Flex>
          </Grid>
        )}

        <Grid padding="xx-small" />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  backupFiles: state.pageItems[API_RESOURCE.BACKUP_RESTORE_FILES.resourceName] || { '/': [] },
  environment: state.environment
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withSpanelForm(reduxForm({ form: formName })(RestoreFilesForm))));
