import * as React from 'react';
import { useIntl } from 'react-intl';
import { Text } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';

import CreateBox from '../../../components/create-box';
import VCS from '../../../components/visibility-control-service';

type Props = {
  children: any;
  domainConfig: {
    imap_host: string;
    smtp_host: string;
    imap_port: number;
    pop3_port: number;
    smtp_port: number;
  };
};

const { resourceName } = API_RESOURCE.EMAIL;
const formName = REDUX_FORM.CREATE_EMAIL;

const CreateContainer = ({ children, domainConfig }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceName} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.email.create.title' })}
        resourceName={resourceName}
        formName={formName}
        renderTutorials={({ DefaultRender, textProps }) => (
          <DefaultRender
            title={intl.formatMessage({ id: 'translate.tips_and_tutorials.email.title' })}
            text={
              <div>
                <Text {...textProps}>
                  <Text {...textProps} tag="span" weight="extra-bold">
                    {intl.formatMessage({ id: 'translate.page.email.manual.settings.incoming.server.label' })}:&nbsp;
                  </Text>
                  {domainConfig.imap_host}
                </Text>

                <Text {...textProps}>
                  <Text {...textProps} tag="span" weight="extra-bold">
                    {intl.formatMessage({ id: 'translate.page.email.manual.settings.imap.port.label' })}:&nbsp;
                  </Text>
                  {domainConfig.imap_port}
                  &nbsp;/&nbsp;
                  <Text {...textProps} tag="span" weight="extra-bold">
                    {intl.formatMessage({ id: 'translate.page.email.manual.settings.pop3.port.label' })}:&nbsp;
                  </Text>
                  {domainConfig.pop3_port}
                </Text>

                <Text {...textProps}>
                  <Text {...textProps} tag="span" weight="extra-bold">
                    {intl.formatMessage({ id: 'translate.page.email.manual.settings.outgoing.server.label' })}:&nbsp;
                  </Text>
                  {domainConfig.smtp_host}
                </Text>

                <Text {...textProps} tag="span" weight="extra-bold">
                  {intl.formatMessage({ id: 'translate.page.email.manual.settings.smtp.port.label' })}:&nbsp;
                </Text>
                {domainConfig.smtp_port}
              </div>
            }
          />
        )}
        resources={[
          {
            resourceName: API_RESOURCE.EMAIL.resourceName,
            methods: ['POST']
          },
          {
            resourceName: API_RESOURCE.DOMAIN.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName,
            methods: ['GET']
          }
        ]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default CreateContainer;
