import { Dropdown, FormElement, Textarea, Toolbar } from '@siteground/styleguide';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import MonacoEditor from 'react-monaco-editor';

import { connect } from 'react-redux';
import { getUserTheme } from '../../../core/selectors/preferences';
import { getMonacoTheme } from '../../file-manager/core/utils';

import './form-type-editor.scss';

const FormTypeEditor = ({ input, meta, values, theme, changeFieldValue, intl }: any) => {
  const { value, onChange, onBlur, ...inputProps } = input;
  const touchedOrActive = meta.touched || meta.active;
  const touchedOrHasValue = meta.touched || Boolean(input.value);

  const HTML_TYPE_OPTIONS = [
    {
      label: intl.formatMessage({ id: 'translate.page.emailAutoresponder.send.as.plain.text' }),
      value: 0
    },
    {
      label: intl.formatMessage({ id: 'translate.page.emailAutoresponder.send.as.html.text' }),
      value: 1
    }
  ];

  let status;
  let error;

  if (touchedOrActive && meta.valid && meta.dirty) {
    status = 'success';
  }

  if (!meta.valid && touchedOrActive && touchedOrHasValue) {
    status = 'error';
    error = meta.error;
  }

  const options = { selectOnLineNumbers: true };
  const showEditor = values.html_email || 0;

  return (
    <FormElement tag="div" state={status} validationMessage={error} showValidation={meta.active} {...inputProps}>
      {() => (
        <div style={{ width: '100%' }}>
          <Toolbar background="light">
            <Dropdown
              name="html_email"
              selectedValue={values.html_email || 0}
              options={HTML_TYPE_OPTIONS}
              optionValue="value"
              optionLabel="label"
              onChange={(value) => {
                changeFieldValue('html_email', value);
              }}
            />
          </Toolbar>
          {Boolean(!showEditor) && (
            <Textarea
              data-e2e={`form-textarea-${input.name}`}
              className="form-type-editor"
              rows="8"
              value={value}
              onChange={(event) => onChange(event.target.value)}
            />
          )}
          {Boolean(showEditor) && (
            <div className="form-type-editor form-type-editor--monaco">
              <MonacoEditor
                theme={getMonacoTheme(theme)}
                language="html"
                value={values.email_body}
                options={options}
                onChange={onChange}
              />
            </div>
          )}
        </div>
      )}
    </FormElement>
  );
};

const mapStateToProps = (store) => {
  return {
    theme: getUserTheme(store)
  };
};

export default connect<{}, {}, any>(mapStateToProps, {})(injectIntl(FormTypeEditor));
