import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import { ResellerLoginRoutes, ToolId } from '../core/constants/route-info';
import { ROUTES } from '../core/constants/routes';
import RouteWrapper from './components/route-wrapper';
import AccessLogs from './pages/access-logs';
import App from './pages/app';
import AppManager from './pages/app-manager';
import Appearance from './pages/appearance';
import BackupRestorePage from './pages/backup';
import BackupRestoreCreateRestorePage from './pages/backup/create-restore';
import BackupRestoreHistoryPage from './pages/backup/restore-history';
import LocationIpBlockPage from './pages/block-ips';
import CloudflarePage from './pages/cloudflare';
import CDNPage from './pages/cdn';
import CronPage from './pages/cron';
import CronCronPage from './pages/cron/job';
import CronNotificationPage from './pages/cron/notification';
import Dashboard from './pages/dashboard';
import DnsPage from './pages/dns';
import DomainAliasPage from './pages/domain-alias';
import EmailPage from './pages/email';
import EmailAuthentication from './pages/email-authentication';
import EmailAutoresponderPage from './pages/email-autoresponder';
import EmailFilterPage from './pages/email-filters';
import EmailForwardPage from './pages/email-forward';
import ErrorLogs from './pages/error-logs';
import FileManagerPage from './pages/file-manager';
import FtpPage from './pages/ftp';
import Git from './pages/git';
import HttpsEnforce from './pages/https-enforce';
import InlineErrorPage from './pages/inline-error-page';
import MysqlPage from './pages/mysql';
import MysqlAccessPage from './pages/mysql/mysql-access';
import MysqlDbPage from './pages/mysql/mysql-db';
import MysqlUserPage from './pages/mysql/mysql-user';
import MysqlPHPMyAdmin from './pages/mysql/php-my-admin';
import PgsqlPage from './pages/pgsql';
import PgsqlAccessPage from './pages/pgsql/pgsql-access';
import PgsqlDbPage from './pages/pgsql/pgsql-db';
import PgsqlUserPage from './pages/pgsql/pgsql-user';
import PhpSettings from './pages/php-settings';
import Profile from './pages/profile';
import ProtectedUrlsPage from './pages/protected-url';
import ProtectedURLs from './pages/protected-url/protected-URLs';
import ProtectedUsers from './pages/protected-url/protected-users';
import RedirectPage from './pages/redirect';
import ResourceStats from './pages/resource-stats';
import { GoogleWorkspace } from './pages/google-workspace';

import { Login, PasswordUpdate, RecoverPassword } from './pages/resseller-login';
import SGSiteScanner from './pages/sitescanner';
import SpamExperts from './pages/spam-experts';
import SSHPage from './pages/ssh';
import SSL from './pages/ssl';
import SubdomainPage from './pages/subdomain';
import SuperCacher from './pages/super-cacher';
import WPAutoupdate from './pages/wp-autoupdate';
import WPManage from './pages/wp-manage';
import WPStaging from './pages/wp-staging';
import WPTransfer from './pages/wp-transfer';
import WPReplace from './pages/wp-replace';

const PageNotFound = () => <InlineErrorPage type="not-found" />;

const routeWrapper =
  ({ toolId, pageId = null, Component }) =>
  (props) =>
    <RouteWrapper toolId={toolId} pageId={pageId} Component={Component} props={props} />;

const SGRouter = ({ history }) => {
  return (
    <Router history={history}>
      <Route path="/" component={App}>
        <IndexRoute component={Dashboard} />

        <Route path={ROUTES[ToolId.dashboard]} component={Dashboard} />

        <Route
          path={ROUTES[ToolId.filemanager]}
          component={routeWrapper({ toolId: ToolId.filemanager, Component: FileManagerPage })}
        />

        <Route path={ROUTES[ToolId.ftp]} component={routeWrapper({ toolId: ToolId.ftp, Component: FtpPage })} />

        <Route path={ROUTES[ToolId.mysql]} component={routeWrapper({ toolId: ToolId.mysql, Component: MysqlPage })}>
          <IndexRoute component={routeWrapper({ toolId: ToolId.mysql, Component: MysqlDbPage })} />

          <Route
            path={ROUTES[ToolId.mysqlDatabase]}
            component={routeWrapper({ toolId: ToolId.mysql, Component: MysqlDbPage })}
          />

          <Route
            path={ROUTES[ToolId.mysqlUser]}
            component={routeWrapper({ toolId: ToolId.mysql, Component: MysqlUserPage })}
          />
          <Route
            path={ROUTES[ToolId.mysqlAccess]}
            component={routeWrapper({ toolId: ToolId.mysql, Component: MysqlAccessPage })}
          />
          <Route
            path={ROUTES[ToolId.mysqlPhpMyAdmin]}
            component={routeWrapper({ toolId: ToolId.mysql, Component: MysqlPHPMyAdmin })}
          />
        </Route>

        <Route path={ROUTES[ToolId.pgsql]} component={routeWrapper({ toolId: ToolId.pgsql, Component: PgsqlPage })}>
          <IndexRoute component={PgsqlDbPage} />

          <Route
            path={ROUTES[ToolId.pgsqlDatabase]}
            component={routeWrapper({ toolId: ToolId.pgsql, Component: PgsqlDbPage })}
          />

          <Route
            path={ROUTES[ToolId.pgsqlUser]}
            component={routeWrapper({ toolId: ToolId.pgsql, Component: PgsqlUserPage })}
          />

          <Route
            path={ROUTES[ToolId.pgsqlAccess]}
            component={routeWrapper({ toolId: ToolId.pgsql, Component: PgsqlAccessPage })}
          />
        </Route>

        <Route
          path={ROUTES[ToolId.backup]}
          component={routeWrapper({ toolId: ToolId.backup, Component: BackupRestorePage })}
        >
          <IndexRoute component={routeWrapper({ toolId: ToolId.backup, Component: BackupRestoreCreateRestorePage })} />

          <Route
            path={ROUTES[ToolId.backupCreate]}
            component={routeWrapper({ toolId: ToolId.backup, Component: BackupRestoreCreateRestorePage })}
          />

          <Route
            path={ROUTES[ToolId.backupRestore]}
            component={routeWrapper({ toolId: ToolId.backup, Component: BackupRestoreHistoryPage })}
          />
        </Route>

        <Route path={ROUTES[ToolId.ssl]} component={routeWrapper({ toolId: ToolId.ssl, Component: SSL })} />

        <Route
          path={ROUTES[ToolId.httpsEnforce]}
          component={routeWrapper({ toolId: ToolId.httpsEnforce, Component: HttpsEnforce })}
        />

        <Route
          path={ROUTES[ToolId.protected]}
          component={routeWrapper({ toolId: ToolId.protected, Component: ProtectedUrlsPage })}
        >
          <IndexRoute
            component={routeWrapper({
              toolId: ToolId.protected,
              pageId: ToolId.protectedURLs,
              Component: ProtectedURLs
            })}
          />

          <Route
            path={ROUTES[ToolId.protectedURLs]}
            component={routeWrapper({
              toolId: ToolId.protected,
              pageId: ToolId.protectedURLs,
              Component: ProtectedURLs
            })}
          />

          <Route
            path={ROUTES[ToolId.protectedUsers]}
            component={routeWrapper({
              toolId: ToolId.protected,
              pageId: ToolId.protectedUsers,
              Component: ProtectedUsers
            })}
          />
        </Route>

        <Route
          path={ROUTES[ToolId.blockIPs]}
          component={routeWrapper({ toolId: ToolId.blockIPs, Component: LocationIpBlockPage })}
        />

        <Route
          path={ROUTES[ToolId.sitescanner]}
          component={routeWrapper({ toolId: ToolId.sitescanner, Component: SGSiteScanner })}
        />

        <Route
          path={ROUTES[ToolId.cloudflare]}
          component={routeWrapper({ toolId: ToolId.cloudflare, Component: CloudflarePage })}
        />

        <Route path={ROUTES[ToolId.cdn]} component={routeWrapper({ toolId: ToolId.cdn, Component: CDNPage })} />

        <Route
          path={ROUTES[ToolId.cacher]}
          component={routeWrapper({ toolId: ToolId.cacher, Component: SuperCacher })}
        />

        <Route
          path={ROUTES[ToolId.wpManage]}
          component={routeWrapper({ toolId: ToolId.wpManage, Component: WPManage })}
        />

        <Route
          path={ROUTES[ToolId.wpStaging]}
          component={routeWrapper({ toolId: ToolId.wpStaging, Component: WPStaging })}
        />

        <Route
          path={ROUTES[ToolId.wpTransfer]}
          component={routeWrapper({ toolId: ToolId.wpTransfer, Component: WPTransfer })}
        />

        <Route
          path={ROUTES[ToolId.wpAutoupdate]}
          component={routeWrapper({ toolId: ToolId.wpAutoupdate, Component: WPAutoupdate })}
        />

        <Route
          path={ROUTES[ToolId.wpSearchAndReplace]}
          component={routeWrapper({ toolId: ToolId.wpSearchAndReplace, Component: WPReplace })}
        />

        <Route
          path={ROUTES[ToolId.parkedDomains]}
          component={routeWrapper({ toolId: ToolId.parkedDomains, Component: DomainAliasPage })}
        />

        <Route
          path={ROUTES[ToolId.subdomain]}
          component={routeWrapper({ toolId: ToolId.subdomain, Component: SubdomainPage })}
        />

        <Route
          path={ROUTES[ToolId.redirect]}
          component={routeWrapper({ toolId: ToolId.redirect, Component: RedirectPage })}
        />

        <Route path={ROUTES[ToolId.dns]} component={routeWrapper({ toolId: ToolId.dns, Component: DnsPage })} />

        <Route path={ROUTES[ToolId.email]} component={routeWrapper({ toolId: ToolId.email, Component: EmailPage })} />

        <Route
          path={ROUTES[ToolId.emailForward]}
          component={routeWrapper({ toolId: ToolId.emailForward, Component: EmailForwardPage })}
        />

        <Route
          path={ROUTES[ToolId.emailAutoresponder]}
          component={routeWrapper({ toolId: ToolId.emailAutoresponder, Component: EmailAutoresponderPage })}
        />

        <Route
          path={ROUTES[ToolId.emailFilters]}
          component={routeWrapper({ toolId: ToolId.emailFilters, Component: EmailFilterPage })}
        />

        <Route
          path={ROUTES[ToolId.emailAuth]}
          component={routeWrapper({ toolId: ToolId.emailAuth, Component: EmailAuthentication })}
        />

        <Route
          path={ROUTES[ToolId.spamExperts]}
          component={routeWrapper({ toolId: ToolId.spamExperts, Component: SpamExperts })}
        />

        <Route
          path={ROUTES[ToolId.googleWorkspace]}
          component={routeWrapper({ toolId: ToolId.googleWorkspace, Component: GoogleWorkspace })}
        />

        <Route
          path={ROUTES[ToolId.statistics]}
          component={routeWrapper({ toolId: ToolId.statistics, Component: ResourceStats })}
        />

        <Route
          path={ROUTES[ToolId.errorLog]}
          component={routeWrapper({ toolId: ToolId.errorLog, Component: ErrorLogs })}
        />

        <Route
          path={ROUTES[ToolId.accessLog]}
          component={routeWrapper({ toolId: ToolId.accessLog, Component: AccessLogs })}
        />

        <Route
          path={ROUTES[ToolId.appManager]}
          component={routeWrapper({ toolId: ToolId.appManager, Component: AppManager })}
        />

        <Route
          path={ROUTES[ToolId.git]}
          component={routeWrapper({
            toolId: ToolId.git,
            Component: Git
          })}
        />

        <Route path={ROUTES[ToolId.cron]} component={routeWrapper({ toolId: ToolId.cron, Component: CronPage })}>
          <IndexRoute component={CronCronPage} />

          <Route
            path={ROUTES[ToolId.cronJob]}
            component={routeWrapper({ toolId: ToolId.cron, Component: CronCronPage })}
          />

          <Route
            path={ROUTES[ToolId.cronNotification]}
            component={routeWrapper({ toolId: ToolId.cron, Component: CronNotificationPage })}
          />
        </Route>

        <Route
          path={ROUTES[ToolId.phpSettings]}
          component={routeWrapper({ toolId: ToolId.phpSettings, Component: PhpSettings })}
        />

        <Route path={ROUTES[ToolId.ssh]} component={routeWrapper({ toolId: ToolId.ssh, Component: SSHPage })} />

        <Route path={ROUTES[ToolId.appearance]} component={Appearance} />

        {CONFIG.IS_RESELLER && (
          <React.Fragment>
            <Route path={ROUTES[ToolId.profile]} component={Profile} />

            <Route
              path={ROUTES[ResellerLoginRoutes.createPassword]}
              component={(props) => <PasswordUpdate {...props} passwordUpdateType="create" />}
            />

            <Route
              path={ROUTES[ResellerLoginRoutes.changePassword]}
              component={(props) => <PasswordUpdate {...props} passwordUpdateType="change" />}
            />

            <Route path={ROUTES[ResellerLoginRoutes.login]} component={Login} />
            <Route path={ROUTES[ResellerLoginRoutes.recoverPassword]} component={RecoverPassword} />
          </React.Fragment>
        )}

        <Route path="*" component={PageNotFound} />
      </Route>
    </Router>
  );
};

export default SGRouter;
