import * as React from 'react';
import { useIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import CreateBox from '../../../../components/create-box';
import VCS from '../../../../components/visibility-control-service';
import RenderSSHTutorials from '../render-tutorials';

type Props = {
  children: any;
  credentialsInfo: any;
  renderCustomNotification: any;
  renderBeforeBoxChildren: any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.SSH_KEY;
const formName = REDUX_FORM.CREATE_SSH_KEY;

const CreateContainer = ({ children, renderCustomNotification, renderBeforeBoxChildren, credentialsInfo }: Props) => {
  const intl = useIntl();
  return (
    <VCS resourceName={resourceNameMetaApi} hasMethod="POST">
      <CreateBox
        title={intl.formatMessage({ id: 'translate.page.ssh.create.title' })}
        renderBeforeBoxChildren={renderBeforeBoxChildren}
        renderCustomNotification={renderCustomNotification}
        resourceName={resourceName}
        formName={formName}
        renderTutorials={(props) => <RenderSSHTutorials credentialsInfo={credentialsInfo} {...props} />}
        resources={[
          {
            resourceName: API_RESOURCE.SSH_KEY.resourceName,
            methods: ['POST']
          }
        ]}
      >
        {children}
      </CreateBox>
    </VCS>
  );
};

export default CreateContainer;
