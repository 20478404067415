import * as React from 'react';
import { connect } from 'react-redux';

const HideBelowTablet = ({ children, env }) => (env.isPhone ? null : children);

const HideBelowDesktop = ({ children, env }) => (env.isDesktop ? children : null);

const Show = ({ children, env }) => (env.isPhone ? children : null);

const Toggle = ({ children, env }) => (env.isPhone ? children[0] : children[1]);

const mapState = (state) => ({ env: state.environment });

export const HideOnMobile = connect(mapState)(HideBelowTablet);

export const HideOnTablet = connect(mapState)(HideBelowDesktop);

export const ShowOnMobile = connect(mapState)(Show);

export const ToggleOnMobile = connect(mapState)(Toggle);
