export const convertPathToFolderArray = (path) => {
  const stringSplits = path.split('/');

  return stringSplits
    .reduce((accumulator, currentValue, index) => {
      if (index === 0) {
        return [currentValue];
      }

      accumulator.push(accumulator[index - 1] + '/' + currentValue);

      return accumulator;
    }, [])
    .map((str) => (str === '' ? '/' : str));
};
