import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../../core/constants/api';
import { REDUX_FORM } from '../../../../../core/constants/common';
import { withSpanelForm } from '../../../../components/sg-spanel-forms';
import { SGForm, SGSubmitButton } from '../../../../components/sg-form';
import FormInput from '../../../../components/form-input';

type Props = {
  intl: Intl;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => void;
};

const formName = REDUX_FORM.CREATE_PGSQL_ACCESS;

const Form = ({ intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required, ipWithExtras, validationWithMetaApi } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          name="addr"
          type="text"
          label={intl.formatMessage({ id: 'translate.generic.ip' })}
          validate={[required, ipWithExtras]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name="label"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.database_access.label.field' })}
          validate={[validationWithMetaApi]}
          component={FormInput}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton
          formName={formName}
          label={intl.formatMessage({ id: 'translate.page.database_access.create.button' })}
        />
      </Column>
    </SGForm>
  );
};

const PGSQLAccessForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.PGSQL_ACCESS,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(PGSQLAccessForm));
