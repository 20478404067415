import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Icon, Link } from '@siteground/styleguide/';
import { isCmdCtrlKey } from '../../../../core/common/key-code-combinations';
import { KEY } from '../../../../core/constants/key-codes';
import './default-view.scss';

class EntityView extends React.Component<any, any> {
  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDownHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDownHandler);
  }

  onKeyDownHandler = (event) => {
    const { keyCode } = event;
    const isCmdCtrlKeyPressed = isCmdCtrlKey(event);

    if (isCmdCtrlKeyPressed && keyCode === KEY.b) {
      event.preventDefault();
      this.props.toggleCodeEditor();
    }

    // TODO other command ...
    if (isCmdCtrlKeyPressed && keyCode === KEY.n) {
      event.stopPropagation();
      event.preventDefault();
      this.props.createFile();
    }
  };

  render() {
    const { intl, createFile, toggleCodeEditor } = this.props;

    return (
      <div className="code-editor-default-view">
        <div className="code-editor-default-view__icon">
          <Icon color="ocean" name="product-new-file" size="60" />
        </div>

        <Grid>
          <Link align="center" onClick={createFile}>
            {intl.formatMessage({ id: 'translate.file.manager.create.new.file.shortcut' })}
          </Link>

          <Link align="center" onClick={toggleCodeEditor}>
            {intl.formatMessage({ id: 'translate.file.manager.back.to.explorer.shortcut' })}
          </Link>
        </Grid>
      </div>
    );
  }
}

export default injectIntl(EntityView);
