import * as Actions from '../constants/actions';
import { getEntityInfoNumber, getEntityPath } from '../utils';
import { FileManagerRootState } from './utils/internal-state-definition';

const CodeEditorReducer = {
  [Actions.FILE_MANAGER_CODE_EDITOR_TOGGLE]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      codeEditor: {
        ...state.codeEditor,
        isVisible: !state.codeEditor.isVisible
      }
    };
  },
  [Actions.FILE_MANAGER_CODE_EDITOR_CLOSE]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      codeEditor: {
        ...state.codeEditor,
        isVisible: false
      }
    };
  },
  [Actions.FILE_MANAGER_CODE_EDITOR_ON_FILE_CHANGE]: (state: FileManagerRootState, action: any) => {
    return {
      ...state,
      selectedContentEntities: [],
      codeEditor: {
        ...state.codeEditor,
        files: action.payload
      }
    };
  },
  [Actions.FILE_MANAGER_CODE_EDITOR_CHANGE_ACTIVE_TAB]: (state: FileManagerRootState, action: any) => {
    const newState = { ...state };
    newState.codeEditor.files = newState.codeEditor.files.map((file, index) => {
      file._meta.isActiveInCodeEditor = index === action.payload;

      return file;
    });

    return newState;
  },
  [Actions.FILE_MANAGER_CODE_EDITOR_FOCUS_ENTITY]: (state: FileManagerRootState, action: any) => ({
    ...state,
    codeEditor: {
      ...state.codeEditor,
      isVisible: true,
      files: state.codeEditor.files.map((entity) => ({
        ...entity,
        _meta: {
          ...entity._meta,
          isActiveInCodeEditor: Boolean(getEntityInfoNumber(entity) === action.payload.entityId)
        }
      }))
    }
  }),
  [Actions.FILE_MANAGER_CODE_EDITOR_ON_TAB_CLOSE]: (state: FileManagerRootState, action: any) => {
    const newState = { ...state };
    const entity = action.payload;
    const tabIndexForClose = newState.codeEditor.files.findIndex((file) => {
      return getEntityPath(file) === getEntityPath(entity);
    });

    let modifiedFiles = newState.codeEditor.files.filter((file, index) => index !== tabIndexForClose);
    const nextActiveTabIndex = modifiedFiles[tabIndexForClose] ? tabIndexForClose : tabIndexForClose - 1;

    if (modifiedFiles[nextActiveTabIndex]) {
      modifiedFiles = modifiedFiles.map((file, index) => {
        file._meta.isActiveInCodeEditor = index === nextActiveTabIndex;
        return file;
      });
    }

    newState.codeEditor.files = modifiedFiles;

    if (newState.codeEditor.files.length === 0) {
      newState.codeEditor.isVisible = false;
    }

    return newState;
  },
  [Actions.FILE_MANAGER_SAVE_FILE_SUCCEEDED]: (state: FileManagerRootState, action: any) => {
    const savedFile = action.payload.entity;

    const modifiedFiles = state.codeEditor.files.map((file) => {
      if (getEntityPath(file) === getEntityPath(savedFile)) {
        file._meta.content = file._meta.updatedContent;
      }

      return file;
    });

    return {
      ...state,
      codeEditor: {
        ...state.codeEditor,
        files: modifiedFiles
      }
    };
  },
  [Actions.FILE_MANAGER_SAVE_CURRENT_TAB_VIEW_STATE]: (state: FileManagerRootState, action) => {
    const { currentTabPath, currentViewState } = action.payload;

    return {
      ...state,
      codeEditor: {
        ...state.codeEditor,
        tabsViewState: {
          ...state.codeEditor.tabsViewState,
          [currentTabPath]: currentViewState
        }
      }
    };
  }
};
export default CodeEditorReducer;
