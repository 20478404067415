import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DIALOGS } from '../../../../core/constants/common';
import { RootState } from '../../../reducers';
import Entity from '../../content/entity/entity';
import { paste, uploadFiles } from '../../core/actions/file-manager';
import { getEntityPath } from '../../core/utils';
import { OverwriteFilesConfirmationDialog } from '../overwrite-files-confirmation';

type StateProps = {
  fileManager: RootState['fileManager'];
  dialogPayload: {
    dest: string;
    entries: string[];
    existingEntities: string[];
  };
};

type DispatchProps = {
  paste: typeof paste;
};

interface Props extends StateProps, DispatchProps {}

const CopyPasteFilesConfirmationDialog = (props: Props) => {
  const { dialogPayload, fileManager, paste } = props;
  const dest = dialogPayload && dialogPayload.dest;
  const entries = dialogPayload ? dialogPayload.entries : [];
  const existingEntities = dialogPayload ? dialogPayload.existingEntities : [];
  const intl = useIntl();

  const columns = [
    {
      header: intl.formatMessage({ id: 'generic.file-name.label' }),
      accessor: 'n',
      render: (n, entity) => <Entity entity={entity} />
    }
  ];

  return (
    <OverwriteFilesConfirmationDialog
      id={DIALOGS.FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_COPY}
      title={intl.formatMessage(
        {
          id: 'translate.file.manager.overwrite.confirmation.files.dialog.title'
        },
        { total: existingEntities.length }
      )}
      subTitle={intl.formatMessage({ id: 'translate.file.manager.overwrite.confirmation.files.dialog.subtitle' })}
      tableProps={{ data: existingEntities, columns }}
      handleOverwrite={() => {
        paste({
          urlParams: {
            dest,
            entries,
            safe: 0
          }
        });
      }}
      handleKeepExistingOverwrite={() => {
        if (entries.length === 0) {
          return;
        }

        const existingEntitiesNames = existingEntities.map((entity) => getEntityPath(entity).split('/').pop());
        const entitiesToCopy = entries.filter((path) => {
          const name = path.split('/').pop();
          return !existingEntitiesNames.includes(name);
        });

        if (entitiesToCopy.length === 0) {
          return;
        }

        paste({
          urlParams: {
            dest,
            entries: entitiesToCopy
          }
        });
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  dialogPayload: state.dialog[DIALOGS.FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_COPY],
  fileManager: state.fileManager
});

const mapDispatchToProps = {
  uploadFiles,
  paste
};

export default connect<StateProps, DispatchProps, any>(
  mapStateToProps,
  mapDispatchToProps
)(CopyPasteFilesConfirmationDialog);
