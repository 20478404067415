import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid, Flex, Notice } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../core/constants/common';
import { API_RESOURCE } from '../../../core/constants/api';
import FormCheckbox from '../../components/form-checkbox';
import { withSpanelForm } from '../sg-spanel-forms';

const formName = REDUX_FORM.DELETE_INSTALLED_WP_APP;

const DeleteWpForm = () => {
  const intl = useIntl();
  return (
    <Grid>
      <Notice type="error" background="light" border={false} shadow={false}>
        {intl.formatMessage({ id: 'translate.page.wp.manage.delete.notice' })}
      </Notice>

      <Flex direction="column" style={{ textAlign: 'start' }}>
        <Field name="keep_db" component={FormCheckbox}>
          {intl.formatMessage({ id: 'translate.page.wp.manage.delete.database.checkbox' })}
        </Field>

        <Field name="keep_stagings" component={FormCheckbox}>
          {intl.formatMessage({ id: 'translate.page.wp.manage.delete.staging.checkbox' })}
        </Field>
      </Flex>
    </Grid>
  );
};

export default withSpanelForm(
  reduxForm({
    form: formName,
    initialValues: {
      fetchAfterDelete: {
        ...API_RESOURCE.APP
      },
      _metaFields: {
        ...API_RESOURCE.WORDPRESS,
        formName
      }
    }
  })(DeleteWpForm)
);
