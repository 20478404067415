import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { HostingTypes } from '@siteground/styleguide/lib/utils';
import { createItem } from '../../actions/crud';
import { navigateToUA } from '../../actions/nemo-store';
import { fetchSiteMetaApi } from '../../actions/site-meta-api';
import { clientApi } from '../../api/client';

import {
  CANCEL_BACKUP_UPGRADE,
  CONFIRM_BACKUP_UPGRADE,
  CREATE_BACKUP,
  GET_BACKUP_ORDER_DATA
} from '../../constants/actions';
import customRequestTypes from '../../constants/custom-request-types';
import { ToolId } from '../../constants/route-info';
import { getCurrentSiteAccountId, getCurrentSiteId } from '../../selectors';
import { getToolMainEndpoint } from '../../selectors/menu-items';
import * as siteMetaApiSelectors from '../../selectors/site-meta-api';
import getStore from '../../store';
import { handleNemoApiRequest } from '../handle-nemo-api-request';
import { updateSiteMetaApi } from '../site-meta-api';

function* singleBackupOptions({ siteId }) {
  const { session } = yield select();

  return yield call(
    clientApi(`/order_backup/singlebackupoptions/${siteId}=?_client_token=${session.clientToken}`, 'GET')
  );
}

function* getBackupOrderData(action) {
  const state = yield select();
  const siteId = getCurrentSiteId(state);

  return yield call(handleNemoApiRequest(singleBackupOptions, action), { siteId });
}

const NEED_UPGRADE_PLAN_TYPES = [HostingTypes.START_UP];
const NO_NEED_UPGRADE_PLAN_TYPES = [
  HostingTypes.GROW_BIG,
  HostingTypes.GO_GEEK,
  HostingTypes.CLOUD,
  HostingTypes.CLOUD_DEDICATED
];

function* createBackup(action) {
  const { payload } = action;

  const { currentPlan, onUpgradeRequired, createFormData, onUANotAvailable } = payload;

  yield call(updateSiteMetaApi, { requestTypeName: customRequestTypes.BACKUP_FETCH_SITE_META_API });

  const state = yield select();
  const siteId = getCurrentSiteId(state);
  const availableSlots = siteMetaApiSelectors.getAvailableSlots(state);
  const userHasAvailableSlots: boolean = availableSlots > 0;

  switch (true) {
    case NO_NEED_UPGRADE_PLAN_TYPES.includes(currentPlan):
      if (userHasAvailableSlots) {
        return yield put(createItem(createFormData, () => getStore().dispatch(fetchSiteMetaApi())));
      }
      return;
    case NEED_UPGRADE_PLAN_TYPES.includes(currentPlan):
      if (userHasAvailableSlots) {
        return yield put(createItem(createFormData, () => getStore().dispatch(fetchSiteMetaApi())));
      }

      const isUAAvailable = yield select(
        siteMetaApiSelectors.isACLActionEnabled,
        getToolMainEndpoint(ToolId.backup),
        'can_order'
      );

      if (!isUAAvailable) {
        return onUANotAvailable();
      }

      // no available slots
      onUpgradeRequired();

      const { confirmed, rejected } = yield race({
        confirmed: take(CONFIRM_BACKUP_UPGRADE),
        rejected: take(CANCEL_BACKUP_UPGRADE)
      });

      if (confirmed) {
        const { planType } = confirmed.payload;
        const accountId = yield select(getCurrentSiteAccountId);

        yield put(
          navigateToUA({
            page: 'backup-order',
            siteId,
            planType,
            accountId
          })
        );
      }

      return;
    default:
      throw new Error(`Unknown plan type: ${currentPlan}`);
  }
}

function* backup(): any {
  yield takeLatest(GET_BACKUP_ORDER_DATA, getBackupOrderData);
  yield takeLatest(CREATE_BACKUP, createBackup);
}

export default backup;
