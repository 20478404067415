const determineExclIncl = (data: { showPricesWithVat: boolean; showFullText: boolean; intlProvider: Intl }) => {
  const { showPricesWithVat, showFullText, intlProvider } = data;

  return showPricesWithVat
    ? intlProvider.formatMessage({ id: showFullText ? 'translate.including' : 'translate.incl' })
    : intlProvider.formatMessage({ id: showFullText ? 'translate.excluding' : 'translate.excl' });
};

export const getVatText = (data: {
  showPricesWithVat: boolean;
  taxName: string;
  showFullText?: boolean;
  intlProvider: Intl;
}) => {
  const { taxName = 'VAT', showPricesWithVat, showFullText, intlProvider } = data;

  const exclIncl = determineExclIncl({ showFullText, showPricesWithVat, intlProvider });

  return intlProvider.formatMessage({ id: 'translate.tax-label' }, { exclIncl, taxName });
};

export const composeFeatures = <T extends { name: string; value: string }>(features: T[], intlProvider: Intl) =>
  features.map((f) => {
    if (f.value === 'Unlimited') {
      f.value = intlProvider.formatMessage({ id: 'translate.unlimited' });
    }

    return { text: f.name.replace('[value]', f.value) };
  });
