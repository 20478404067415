import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid, Notice, Text, textToHTML } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import SGTable from '../../../components/sg-table';
import './settings.scss';
import { findDomainById } from '../../php-settings/utils';
import CDNSettingsBasic from './settings-basic';
import CDNSettingsPremium from './settings-premium';
import { getCDNRenewPrice } from './../constants';

type Props = {
  domain: any;
  items: any;
  intl: Intl;
  navigateToUA: Function;
  language: string;
  currency: any;
  openSGDialog: string;
  setZoneDomain: Function;
  actions: any;
};

class CDNSettings extends React.Component<Props, any> {
  render() {
    const { domain, intl, items, navigateToUA, language, currency } = this.props;
    const { domainCDN, location } = items;
    const cdnDomain = findDomainById({ domainId: domain?.id, domain: domainCDN });

    if (!cdnDomain || !domain.name || !location) {
      return (
        <SGTable
          data={[]}
          shadow={false}
          resources={[
            {
              resourceName: API_RESOURCE.DOMAIN_CDN.resourceName,
              methods: ['GET']
            }
          ]}
        />
      );
    }

    const domainLocation = location.find((item) => item.domain_id === domain?.id && item.path === '/');

    return (
      <React.Fragment>
        <CDNSettingsBasic {...this.props} domainLocation={domainLocation} />
        <CDNSettingsPremium {...this.props} domainLocation={domainLocation} />

        <Grid className="more-settings-notice" padding="responsive">
          <Notice
            title={intl.formatMessage({ id: 'translate.page.cdn.new.feature.beta.title' })}
            background="light"
            type="instruction"
            shadow={false}
            border={false}
          >
            <Grid gap="responsive" justifyItems="flex-start">
              <Text>{textToHTML(intl.formatMessage({ id: 'translate.page.cdn.new.feature.beta.desc' }))}</Text>
              <Button
                type="outlined"
                color="secondary"
                onClick={() =>
                  navigateToUA({
                    page: 'cdn-beta'
                  })
                }
              >
                {intl.formatMessage({ id: 'translate.page.cdn.new.feature.beta.button' })}
              </Button>
              <Text italic>
                {textToHTML(
                  intl.formatMessage(
                    { id: 'translate.page.cdn.new.feature.beta.desc.footer' },
                    { price: getCDNRenewPrice(currency) }
                  )
                )}
              </Text>
            </Grid>
          </Notice>
        </Grid>
      </React.Fragment>
    );
  }
}

export default injectIntl(CDNSettings) as any;
