import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../core/constants/common';
import FormPassword from '../../components/form-password';
import { withSpanelForm } from '../sg-form-util/with-spanel-form';

type Props = {
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.GENERIC_PASSWORD;

export const ChangePassword = ({ validationUtils }: Props) => {
  const intl = useIntl();
  return (
    <Field
      name="password"
      type="password"
      label={intl.formatMessage({ id: 'translate.generic.new.password' })}
      validate={[validationUtils.required, validationUtils.password]}
      component={FormPassword}
    />
  );
};

export default withSpanelForm(reduxForm({ form: formName })(ChangePassword));
