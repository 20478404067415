import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Spacer, Table } from '@siteground/styleguide';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';
import { RootState } from '../../../reducers';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import { getTranslations } from '../../../components/sg-table/translation';
import Entity from '../../content/entity/entity';
import { uploadFiles } from '../../core/actions/file-manager';
import { UploadingFileObjectType } from '../../core/sagas/browser-file-upload';
import { ConfirmFilesUploadDialog } from '../../core/selectors/get-confirm-files-upload-dialog-payload';

type Props = {
  dialogPayload: ConfirmFilesUploadDialog;
  closeSGDialog: typeof closeSGDialog;
  uploadFiles: typeof uploadFiles;
};

type DispatchProps = {
  intl: Intl;
};

const UploadFilesConfirmationDialog = (props: Props & DispatchProps) => {
  const { closeSGDialog, dialogPayload = {}, intl, uploadFiles } = props;
  const { files = [] } = dialogPayload;

  const validFiles = files.filter((file: UploadingFileObjectType) => file._meta.isValid);
  const invalidFiles = files.filter((file: UploadingFileObjectType) => file._meta.isValid === false);

  const columns = [
    {
      header: intl.formatMessage({ id: 'generic.file-name.label' }),
      accessor: 'file',
      render: (file, entity) => {
        return <Entity entity={entity} />;
      }
    }
  ];

  return (
    <SGDialog
      id={DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD}
      icon="warning"
      density="small"
      size="large"
      state="inactive"
      title={intl.formatMessage(
        {
          id: 'translate.file.manager.upload.confirmation.existing.files.dialog.title'
        },
        { total: invalidFiles.length }
      )}
      subTitle={intl.formatMessage({ id: 'translate.file.manager.upload.confirmation.existing.files.dialog.subtitle' })}
      footer={
        <React.Fragment>
          <SGDialogCancel id={DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD} />

          <Spacer />

          <Button
            color="primary"
            type="outlined"
            data-e2e="footer-button-overwrite"
            onClick={() => {
              const modifiedFiles = files.map((file: UploadingFileObjectType) => ({
                ...file,
                urlParams: {
                  ...file.urlParams,
                  safe: 0
                }
              }));

              uploadFiles(modifiedFiles);
              closeSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD);
            }}
          >
            {intl.formatMessage({ id: 'translate.file.manager.upload.confirmation.overwrite' })}
          </Button>

          <Button
            color="primary"
            data-e2e="footer-button-confirm"
            onClick={() => {
              uploadFiles(validFiles);
              closeSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD);
            }}
          >
            {intl.formatMessage({ id: 'translate.file.manager.upload.confirmation.keep.existing' })}
          </Button>
        </React.Fragment>
      }
    >
      <Table columns={columns} data={invalidFiles} density="large" translation={getTranslations(intl)} />
    </SGDialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  dialogPayload: state.dialog[DIALOGS.FILE_MANAGER_CONFIRM_FILE_UPLOAD] || {}
});

const mapDispatchToProps = {
  closeSGDialog,
  uploadFiles
};

export default connect<{}, any, Partial<Props>>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UploadFilesConfirmationDialog));
