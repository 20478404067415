import * as React from 'react';
import { useIntl } from 'react-intl';

import { SGDialogForm } from '../../../components/sg-dialog';
import { REDUX_FORM } from '../../../../core/constants/common';
import { API_RESOURCE } from '../../../../core/constants/api';

import { ChangePhpVersion } from '../update';
import { PhpVersionManageType, PhpManagerState } from '../types';
import { createManagedPhpNotification } from '../utils';
import { deprecatedPHPVersion } from '../update/php-version';

export const UpdatePhpVersionDialog = ({
  isPhone,
  domainData,
  domainId,
  versionType,
  phpVersions,
  pageState,
  phpVersionData,
  updatePhpVersion,
  closeSGDialog
}) => {
  const intl = useIntl();
  const tabsPadding = isPhone ? ['none', 'small'] : undefined;
  const phpVersionId = phpVersionData && phpVersionData.id;

  const updateVersionNotification: SpanelNotification = {
    type: 'generic',
    success: {
      intlKey: 'translate.page.phpSettings.version_updated',
      intlValues: { domain: domainData && domainData.name }
    },
    error: {
      intlKey: 'translate.page.phpSettings.failed_update_php_version',
      intlValues: { domain: domainData && domainData.name }
    }
  };

  let availablePhpVersions = phpVersions;

  if (pageState === PhpManagerState.ModPhpMainSelected) {
    availablePhpVersions = phpVersions.filter(({ mod_php }) => Boolean(mod_php));
  }

  return (
    <SGDialogForm
      name={REDUX_FORM.CHANGE_PHP_VERSION}
      title={intl.formatMessage(
        { id: 'translate.page.phpSettings.change_version.title' },
        { siteName: domainData && domainData.name }
      )}
      resources={[
        {
          resourceName: API_RESOURCE.PHP_LOCATION_VERSION.resourceName,
          methods: ['PUT', 'POST', 'DELETE']
        },
        {
          resourceName: API_RESOURCE.USERVICE.resourceName,
          methods: ['POST', 'PUT']
        }
      ]}
    >
      <ChangePhpVersion
        phpVersions={availablePhpVersions}
        initialValues={{
          _metaFields: API_RESOURCE.PHP_LOCATION_VERSION,
          version_id: phpVersionId,
          version_type: versionType,
          id: domainId
        }}
        onSubmit={({ version_type, version_id }) => {
          if (deprecatedPHPVersion.includes(version_id)) {
            return closeSGDialog(REDUX_FORM.CHANGE_PHP_VERSION);
          }

          const versionTypeManaged = version_type === PhpVersionManageType.Auto;

          if (versionType === version_type && versionType === PhpVersionManageType.Auto) {
            closeSGDialog(REDUX_FORM.CHANGE_PHP_VERSION);
            return;
          }

          updatePhpVersion(
            {
              version_id: versionTypeManaged ? PhpVersionManageType.Auto : version_id
            },
            versionTypeManaged
              ? createManagedPhpNotification(domainData && domainData.name)
              : updateVersionNotification,
            () => closeSGDialog(REDUX_FORM.CHANGE_PHP_VERSION)
          );
        }}
      />
    </SGDialogForm>
  );
};

export default UpdatePhpVersionDialog as typeof UpdatePhpVersionDialog;
