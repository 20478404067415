import * as React from 'react';
import { useIntl } from 'react-intl';

import { Text, textToHTML } from '@siteground/styleguide';

import { DIALOGS } from '../../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';

const CDNNotWorkingCentralDNS: React.FC = () => {
  const intl = useIntl();

  return (
    <SGDialog
      id={DIALOGS.CDN_NOT_WORKING_CENTRAL_DNS}
      title={intl.formatMessage({ id: 'translate.page.cdn.not.working.popup.title' })}
      icon="warning"
      state="warning"
      footer={
        <SGDialogCancel
          id={DIALOGS.CDN_NOT_WORKING_CENTRAL_DNS}
          label={intl.formatMessage({ id: 'translate.generic.close' })}
        />
      }
    >
      <Text align="left">{textToHTML(intl.formatMessage({ id: 'translate.page.cdn.not.working.popup.info' }))}</Text>
    </SGDialog>
  );
};

export default CDNNotWorkingCentralDNS;
