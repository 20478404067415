import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormEmailDropdown from '../../../components/form-email-dropdown';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';

type Props = {
  intl: Intl;
  initialValues: { destination: any };
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.EMAIL_FOLDER_FORWARD_DIALOG;

const ForwardForm = ({ initialValues, validationUtils }: Props) => {
  const intl = useIntl();
  return (
    <Field
      data-e2e="forward-update-email_select_visual"
      name="destination"
      selectedValue={initialValues.destination}
      label={intl.formatMessage({ id: 'translate.page.emailForward.destination' })}
      placeholder={intl.formatMessage({ id: 'translate.page.emailForward.destination' })}
      validate={[validationUtils.required, validationUtils.emailsPunycode]}
      component={FormEmailDropdown}
    />
  );
};

export default withSpanelForm(reduxForm({ form: formName })(ForwardForm));
