import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  Context,
  GetDeviceInformationType,
  Navigation,
  NavigationList,
  NavigationListItem,
  NavigationSeparator
} from '@siteground/styleguide';

import { navigateToUA } from '../../../../core/actions/nemo-store';
import * as sessionActions from '../../../../core/actions/session';
import * as menuItems from '../../../core/constants/menu-items';
import { getNavigationInfo, MENU_ITEMS_LABEL, ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import { RootState } from '../../../reducers';
import { hasCRUDPermission, isACLActionEnabled } from '../../../../core/selectors/site-meta-api';
import SGLink from '../../../components/sg-link';
import SessionReady from '../../../components/session-ready';
import { SCREEN_WIDTH_FOR_MOBILE_DRAWER } from '../../../components/with-drawer';
import './navigation.scss';

type Props = {
  environment?: GetDeviceInformationType;
  location?: any;
  router?: { push: Function };
  routing?: any;
  siteMetaApi?: any;
  toggleDrawer: Function;
  navigateToUA?: Function;
  logout?: Function;
  i18n?: any;
  tabIndex?: number;
};

type DispatchProps = {
  intl: Intl;
};

type State = {
  selectedGroups: string[];
};

const DASHBOARD_GROUP_ID = 'dashboard';

const ICONS = {
  dashboard: 'dashboard',
  sites: 'websites',
  security: 'lock',
  speed: 'rocket',
  wordpress: 'wordpress',
  domains: 'domain',
  mail: 'mail',
  stats: 'statistics',
  devs: 'devs'
};

class SGNavigation extends React.Component<Props & DispatchProps, State> {
  readonly state = {
    selectedGroups: []
  };

  componentDidMount() {
    this.expandNavigation();
  }

  componentDidUpdate() {
    this.expandNavigation();
  }

  getPathName() {
    const { routing } = this.props;

    if (!routing || !routing.locationBeforeTransitions || !routing.locationBeforeTransitions.pathname) {
      return;
    }

    return routing.locationBeforeTransitions.pathname;
  }

  getSubStatesRoutes = (subStates) => {
    return subStates ? subStates.map((toolId) => ROUTES[toolId]) : [];
  };

  expandNavigation = () => {
    const selectedGroup = menuItems.groups.find((group: any) =>
      group.items.find((item) => {
        const path = this.getPathName();
        return ROUTES[item.toolId] === path || this.getSubStatesRoutes(item.subStates).includes(path);
      })
    );

    if (!selectedGroup) {
      return;
    }

    const shouldExpandNavigation = !this.state.selectedGroups.includes(selectedGroup.toolId);

    if (shouldExpandNavigation) {
      this.selectNavigationGroup();
    }
  };

  shouldShowLabel(label) {
    if (!label) {
      return false;
    }

    const currentDate = new Date();
    const currentTimestamp = currentDate.getTime() / 1000;

    return currentTimestamp >= label.startDate && currentTimestamp <= label.endDate;
  }

  selectNavigationGroup() {
    const activeGroup = menuItems.groups.find((group: any) => {
      if (!group.items) {
        return false;
      }

      return group.items.find((item) => {
        const pathsToCheck = [ROUTES[item.toolId]].concat(this.getSubStatesRoutes(item.subStates));
        return Boolean(pathsToCheck.find(this.shouldSelectNavigationEntity));
      });
    });

    if (activeGroup) {
      const selectedGroups = [...Array.from(new Set(this.state.selectedGroups.concat(activeGroup.toolId)))];
      this.setState({ selectedGroups });
    }
  }

  handleNavigationClick = (toolId) => {
    const { selectedGroups } = this.state;
    const isItemSelected = selectedGroups.find((item) => item === toolId);

    if (toolId === DASHBOARD_GROUP_ID) {
      this.onNavigationLinkClicked();
    }

    if (isItemSelected) {
      return this.setState({ selectedGroups: selectedGroups.filter((items) => items !== toolId) });
    }

    return this.setState({ selectedGroups: selectedGroups.concat(toolId) });
  };

  shouldSelectNavigationEntity = (path) => {
    const { routing } = this.props;

    if (!routing || !routing.locationBeforeTransitions || !routing.locationBeforeTransitions.pathname || !path) {
      return false;
    }

    const pathname = routing.locationBeforeTransitions.pathname;

    const isDefaultPageSelected = Boolean((!pathname || pathname === '/') && path === ROUTES[ToolId.dashboard]);

    return path === pathname || isDefaultPageSelected;
  };

  onNavigationLinkClicked = () => {
    if (this.props.environment.width <= SCREEN_WIDTH_FOR_MOBILE_DRAWER) {
      this.props.toggleDrawer();
    }
  };

  render() {
    const { intl, navigateToUA, tabIndex } = this.props;

    return (
      <Context.Consumer>
        {({ device }) => (
          <Navigation tabIndex={tabIndex}>
            {!CONFIG.IS_RESELLER && (
              <SessionReady>
                <NavigationList
                  key="ua-redirect-link"
                  className="ua-redirect-link"
                  title={
                    <span className="ua-redirect-link__text">
                      {intl.formatMessage({ id: 'translate.navigate-to-ua-link.label' })}
                    </span>
                  }
                  icon="arrow-back"
                  iconSize="12"
                  onClick={navigateToUA}
                  active={false}
                />
                <NavigationSeparator />
              </SessionReady>
            )}

            <SessionReady>
              {this.renderNavigation()}
              <NavigationSeparator />
            </SessionReady>

            {device.isPhone && (
              <React.Fragment>
                {CONFIG.IS_RESELLER && (
                  <NavigationListItem visible>
                    <SGLink
                      to={ROUTES[ToolId.profile]}
                      text={intl.formatMessage({ id: 'translate.sg-header.profile' })}
                      data-e2e={`navigation-list-item-${ToolId.profile}`}
                      onClick={this.onNavigationLinkClicked}
                      tabIndex={-1}
                    />
                  </NavigationListItem>
                )}

                <NavigationListItem visible>
                  <SGLink
                    to={ROUTES[ToolId.appearance]}
                    text={intl.formatMessage({ id: 'translate.sg-header.appearance' })}
                    data-e2e={`navigation-list-item-${ToolId.appearance}`}
                    onClick={this.onNavigationLinkClicked}
                    tabIndex={-1}
                  />
                </NavigationListItem>
                <NavigationListItem visible>
                  <a href="" onClick={() => this.props.logout()}>
                    {intl.formatMessage({ id: 'translate.sg-header.logout' })}
                  </a>
                </NavigationListItem>

                <NavigationSeparator />
              </React.Fragment>
            )}
          </Navigation>
        )}
      </Context.Consumer>
    );
  }

  renderNavigation() {
    const { intl } = this.props;

    return menuItems.groups.map((group) => {
      const { title } = getNavigationInfo(group.toolId);

      const itemTranslatedTitle = intl.formatMessage({ id: title });

      const groupChildren = this.renderNavigationListItem(group);

      const shouldRenderGroup = Boolean(group.toolId === ToolId.dashboard || groupChildren.filter(Boolean).length > 0);

      return shouldRenderGroup ? (
        <NavigationList
          key={group.toolId}
          title={itemTranslatedTitle}
          icon={ICONS[group.toolId]}
          open={group.items && group.items.length > 0 && this.state.selectedGroups.includes(group.toolId)}
          data-e2e={`navigation-group-${group.toolId}`}
          onClick={() => {
            this.handleNavigationClick(group.toolId);
            if (group.route) {
              // TODO use util
              this.props.router.push(`${group.route}${this.props.location.search}`);
            }
          }}
          active={this.shouldSelectNavigationEntity(ROUTES[group.toolId])}
        >
          {groupChildren}
        </NavigationList>
      ) : null;
    });
  }

  renderNavigationListItem(group) {
    const { intl, siteMetaApi } = this.props;

    return group.items.map((item) => {
      const { title } = getNavigationInfo(item.toolId);
      const itemTranslatedTitle = title && intl.formatMessage({ id: title });
      const label = this.shouldShowLabel(MENU_ITEMS_LABEL[item.toolId]) && MENU_ITEMS_LABEL[item.toolId];

      const pathsToCheck = [ROUTES[item.toolId]].concat(this.getSubStatesRoutes(item.subStates));
      const isActive = Boolean(pathsToCheck.find(this.shouldSelectNavigationEntity));
      const hasPerm = hasCRUDPermission(siteMetaApi, item.pageEndpoint);
      const canView = isACLActionEnabled({ siteMetaApi }, item.pageEndpoint, 'can_view');

      if (!hasPerm && !canView) {
        return null;
      }

      return (
        <NavigationListItem key={item.toolId} active={isActive}>
          <SGLink
            to={item.route}
            text={itemTranslatedTitle}
            data-e2e={`navigation-list-item-${item.toolId}`}
            onClick={this.onNavigationLinkClicked}
            label={label}
            tabIndex={-1}
          />
        </NavigationListItem>
      );
    });
  }
}

const mapStateToProps = (state: RootState) => ({
  environment: state.environment,
  siteMetaApi: state.siteMetaApi,
  routing: state.routing,
  i18n: state.i18n // needed for show/hide translations logic
});

export default connect<{}, {}, Props>(mapStateToProps, (dispatch) => ({
  navigateToUA: () => dispatch(navigateToUA({ command: 'redirect' })),
  logout: () => dispatch(sessionActions.logoutUser())
}))(withRouter(injectIntl(SGNavigation)));
