import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../reducers';

interface VCSProps {
  siteMetaApi?: any;
  hasTag?: string;
  hasMethod?: string;
  hasMethods?: string[];
  hasOneOfMethods?: string[];
  resourceName: string;

  // unit testing
  store?: any;
}

const { Fragment } = React;

class VCS extends React.Component<VCSProps, any> {
  constructor(props) {
    super(props);
  }

  private camelCaseToDash(myStr: string) {
    return myStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  private hasTag(tag: string) {
    const { siteMetaApi } = this.props;
    const resourceName = this.camelCaseToDash(this.props.resourceName);

    return siteMetaApi.endpoints[resourceName].meta[tag] === 1;
  }

  private hasMethod(method: string) {
    const { siteMetaApi } = this.props;
    const resourceName = this.camelCaseToDash(this.props.resourceName);

    return siteMetaApi.endpoints[resourceName].methods[method] === 1;
  }

  private hasOneOfMethods(methods: string[]) {
    return methods.filter(this.hasMethod.bind(this)).length > 0;
  }

  private hasMethods(methods: string[]) {
    return methods.filter(this.hasMethod.bind(this)).length === methods.length;
  }

  public render() {
    const { children, hasMethod, hasMethods, hasOneOfMethods, siteMetaApi, hasTag } = this.props;

    if (siteMetaApi.loading) {
      return null;
    }

    const shoudRenderChildren =
      (hasMethod && this.hasMethod(hasMethod)) ||
      (hasTag && this.hasTag(hasTag)) ||
      (hasMethods && this.hasMethods(hasMethods)) ||
      (hasOneOfMethods && this.hasOneOfMethods(hasOneOfMethods));

    if (shoudRenderChildren) {
      return <Fragment>{children}</Fragment>;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  siteMetaApi: state.siteMetaApi
});

export default connect<{}, {}, VCSProps>(mapStateToProps)(VCS);
