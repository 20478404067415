import * as React from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@siteground/styleguide';

import { DIALOGS } from '../../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';

const CloudflareCannotBeActivatedDialog: React.FC = () => {
  const intl = useIntl();

  return (
    <SGDialog
      id={DIALOGS.CLOUDFLARE_CANNOT_BE_ACTIVATED}
      title={intl.formatMessage({ id: 'translate.page.cloudflare.cannot.be.activated' })}
      icon="warning"
      state="warning"
      footer={
        <SGDialogCancel
          id={DIALOGS.CLOUDFLARE_CANNOT_BE_ACTIVATED}
          label={intl.formatMessage({ id: 'translate.generic.close' })}
        />
      }
    >
      <Text>{intl.formatMessage({ id: 'translate.page.cloudflare.cannot.be.activated.description' })}</Text>
    </SGDialog>
  );
};

export default CloudflareCannotBeActivatedDialog;
