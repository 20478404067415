import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../../core/constants/api';
import FormDropdown from '../../../../components/form-dropdown';

import FormInput from '../../../../components/form-input';
import { SGForm, SGSubmitButton } from '../../../../components/sg-form';
import { withSpanelForm } from '../../../../components/sg-spanel-forms';
import { Domains } from '../../types';
import { CREATE_USER_FORM_NAME, DOMAIN_ID_FORM_NAME, USERNAME_FORM_NAME } from '../../constants';
import { normalizePathInput } from '../../utils';

type Props = {
  change: Function;
  domainOptions: Domains[];
  users: any;
  onChangeUsersDropdown: (value) => any;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => any;
  intl: Intl;
};

type State = {
  selectedUser: number;
};

class FormAddExistingUser extends React.Component<Props, State> {
  readonly state = {
    selectedUser: 0
  };

  componentDidMount() {
    this.props.change(USERNAME_FORM_NAME, this.props.users[0].id);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevState !== this.state) {
      this.props.change(USERNAME_FORM_NAME, this.state.selectedUser);
    }

    if (prevProps.users !== this.props.users) {
      this.props.change(USERNAME_FORM_NAME, this.props.users[0].id);
    }
  }

  render() {
    const { change, validationUtils, sPanelFormSubmit, domainOptions, intl, users, onChangeUsersDropdown } = this.props;
    const { required, validationWithMetaApi, password } = validationUtils;
    return (
      <SGForm onSubmit={sPanelFormSubmit(CREATE_USER_FORM_NAME)}>
        <Column smSpan="6">
          <Field
            name={DOMAIN_ID_FORM_NAME}
            type="text"
            options={domainOptions}
            optionValue="id"
            optionLabel="name"
            label={intl.formatMessage({ id: 'translate.page.protected.domain' })}
            placeholder={intl.formatMessage({ id: 'translate.page.protected.domain' })}
            searchable
            component={FormDropdown}
            validate={[required]}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="path"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.protected.path' })}
            placeholder={intl.formatMessage({ id: 'translate.page.protected.path' })}
            normalize={(path) => normalizePathInput(path, domainOptions)}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name={USERNAME_FORM_NAME}
            type="text"
            options={users}
            optionValue="id"
            optionLabel="username"
            label={intl.formatMessage({ id: 'translate.generic.user' })}
            placeholder={intl.formatMessage({ id: 'translate.generic.user' })}
            searchable
            component={FormDropdown}
            onChange={(event, newValue, prevValue) => {
              onChangeUsersDropdown(newValue);
              if (newValue === 0) {
                this.setState({ selectedUser: prevValue });
                this.props.change(USERNAME_FORM_NAME, prevValue);
              }
            }}
            validate={[required]}
          />
        </Column>
        <Column smSpan="12">
          <SGSubmitButton
            label={intl.formatMessage({ id: 'translate.page.protected.protect.button' })}
            formName={CREATE_USER_FORM_NAME}
          />
        </Column>
      </SGForm>
    );
  }
}

const ProtectedUrlsCreateForm = reduxForm({
  form: CREATE_USER_FORM_NAME,
  initialValues: {
    realm: 'Protected folder',
    path: '/',
    _metaFields: {
      ...API_RESOURCE.LOCATION_PROTECT,
      fetchItemsOnSuccess: [API_RESOURCE.LOCATION_PROTECT],
      formName: CREATE_USER_FORM_NAME
    }
  }
})(FormAddExistingUser);

export default withSpanelForm(injectIntl(ProtectedUrlsCreateForm));
