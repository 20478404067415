import { UpdatedIconProps } from '@siteground/styleguide/lib/composite/hosting-plan-card/types';

export const PLAN_CARD_ICON_PROPS: { [planName in PlanNames]: UpdatedIconProps } = {
  shared: {
    name: 'product-house',
    color: 'grassy',
    size: '72',
    fill: 'light'
  },
  shared_plus: {
    name: 'product-star',
    color: 'ocean',
    size: '72',
    fill: 'light'
  },
  shared_geek: {
    name: 'product-crown',
    color: 'sunset',
    size: '72',
    fill: 'light'
  }
};
