import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  copyToClipboard,
  Dropdown,
  Flex,
  Grid,
  IconButton,
  Link,
  Notice,
  Text,
  Textarea
} from '@siteground/styleguide';
import * as actions from '../../../../core/actions/crud';
import { createNotification } from '../../../../core/actions/notifications';
import { updateSPF } from '../../../../core/actions/pages/email-authentication';
import * as sgDialogActions from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import PartialLoader from '../../../components/partial-loader';
import SettingList from '../../../components/setting-list';
import SettingListItem from '../../../components/setting-list-item';
import { SGDialogForm } from '../../../components/sg-dialog';
import {
  UpdateApprovedHostsForm,
  UpdateApprovedIncludeListForm,
  UpdateApprovedIPBlocksForm,
  UpdateApprovedMXRecordsForm
} from '../update/form';
import { SettingListItemTitle } from './setting-list-item-title';
import './spf.scss';

type SPFType = {
  spf_host?: [string];
  spf_include?: [string];
  spf_all_quantifier?: '~' | '?' | '-';
  spf_mx?: [string];
  spf_addr?: [string];
  spf_txt?: string;
};

type Props = {
  actions: {
    fetchItems: FetchItems;
  };
  createNotification: Function;
  updateSPF: Function;
  intl: Intl;
  openSGDialog: Function;
  closeSGDialog: Function;
  selectedDomain: {
    id: number;
    link: string;
    name: string;
  };
  spf: [
    {
      domain_name?: string;
      sg_dns?: boolean;
      local_spf: SPFType;
      remote_spf: SPFType;
      recommended_spf: SPFType;
    }
  ];
};

type State = {
  isUpdateSPFSettingVisible: boolean;
};

class SPF extends React.Component<Props, State> {
  readonly state = {
    isUpdateSPFSettingVisible: false
  };

  componentDidMount() {
    if (this.props.selectedDomain) {
      this.fetchSPF();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDomain.id !== this.props.selectedDomain.id) {
      this.fetchSPF();
    }
  }

  fetchSPF() {
    if (!this.props.selectedDomain.id) {
      return;
    }

    this.props.actions.fetchItems({
      ...API_RESOURCE.SPF,
      urlParams: {
        id: this.props.selectedDomain.id
      }
    });
  }

  getSPFDomainName() {
    const { spf } = this.props;

    return spf.length && spf[0].domain_name;
  }

  getSGDNS() {
    const { spf } = this.props;

    return spf.length && spf[0].sg_dns;
  }

  getLocalSPF(): SPFType {
    const { spf } = this.props;

    if (!spf.length || !spf[0].local_spf) {
      return {};
    }

    return spf[0].local_spf;
  }

  getLocalSPFForRequestToBackend = () => ({
    ...this.getLocalSPF(),
    spf_txt: undefined
  });

  getRemoteSPF(): SPFType {
    const { spf } = this.props;

    if (!spf.length || !spf[0].remote_spf) {
      return {};
    }

    return spf[0].remote_spf;
  }

  getRecommendedSPF(): SPFType {
    const { spf } = this.props;

    if (!spf.length || !spf[0].recommended_spf) {
      return {};
    }

    return spf[0].recommended_spf;
  }

  handleCopyToClipboard(value) {
    const { createNotification } = this.props;

    copyToClipboard(value, (isSuccessful) => {
      createNotification({
        type: 'generic',
        state: isSuccessful ? 'success' : 'error',
        success: {
          intlKey: 'translate.generic.copied.to.clipboard'
        },
        error: {
          intlKey: 'translate.generic.failed.copied.to.clipboard'
        }
      });
    });
  }

  shouldShowSPFSettings() {
    const { spf_txt } = this.getLocalSPF();
    const sgGns = this.getSGDNS();

    return this.state.isUpdateSPFSettingVisible || sgGns;
  }

  renderRecordsNotice() {
    const { intl, selectedDomain } = this.props;
    const sgDns = this.getSGDNS();
    const remoteSPFTxt = this.getRemoteSPF().spf_txt;
    const recommendedSPFTxt = this.getRecommendedSPF().spf_txt;
    const hasSPF = Boolean(remoteSPFTxt);

    if (sgDns) {
      return null;
    }

    return (
      <Notice
        title={intl.formatMessage({ id: 'translate.page.email.auth.spf.records.notice.title' })}
        type="warning"
        background="light"
        shadow={false}
      >
        <Grid gap="small">
          <FormattedMessage
            tagName="span"
            id={
              hasSPF
                ? 'translate.page.email.auth.spf.records.notice.info'
                : 'translate.page.email.auth.spf.records.notice.info.has.spf'
            }
            values={{
              domain: (
                <Link href={selectedDomain.link} target="_blank">
                  {selectedDomain.name}
                </Link>
              )
            }}
          />

          {hasSPF && (
            <Textarea
              label={intl.formatMessage({ id: 'translate.page.email.auth.spf.records.notice.current.record' })}
              value={remoteSPFTxt}
              readOnly
            />
          )}

          {hasSPF && (
            <Text>{intl.formatMessage({ id: 'translate.page.email.auth.spf.records.notice.recommended.info' })}</Text>
          )}

          {recommendedSPFTxt && (
            <Textarea
              readOnly
              background="light"
              label={
                <Flex align="center" justify="space-between">
                  {intl.formatMessage({ id: 'translate.page.email.auth.spf.recommended.record' })}

                  <Link role="button" onClick={() => this.handleCopyToClipboard(recommendedSPFTxt)}>
                    {intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                  </Link>
                </Flex>
              }
              value={recommendedSPFTxt}
            />
          )}

          {!this.state.isUpdateSPFSettingVisible && (
            <FormattedMessage
              tagName="span"
              id="translate.page.email.auth.spf.records.generate.new"
              values={{
                clickHere: (
                  <Link onClick={() => this.setState({ isUpdateSPFSettingVisible: true })}>
                    <FormattedMessage id="translate.generic.click.here" />
                  </Link>
                )
              }}
            />
          )}
        </Grid>
      </Notice>
    );
  }

  renderSPFSettings() {
    const { intl, openSGDialog } = this.props;
    const { spf_host, spf_mx, spf_addr, spf_include, spf_all_quantifier, spf_txt } = this.getLocalSPF();
    const sgDns = this.getSGDNS();
    const hasSPF = Boolean(spf_txt);

    if (!this.shouldShowSPFSettings()) {
      return null;
    }

    return (
      <React.Fragment>
        <SettingList title={intl.formatMessage({ id: 'translate.page.email.auth.spf.approved' })}>
          <SettingListItem
            className="spf-settings-list-item"
            title={
              <SettingListItemTitle
                title={intl.formatMessage({ id: 'translate.page.email.auth.spf.approved.hosts' })}
                entities={spf_host}
                pluralLabel={intl.formatMessage({ id: 'translate.page.email.auth.spf.hosts.label' })}
                onLinkClick={() => openSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_HOSTS)}
              />
            }
            disableExpand
          >
            <IconButton
              aria-label={intl.formatMessage({ id: 'translate.aria-label.edit' })}
              className="spf-settings-list-item__icon"
              icon="edit"
              shape="circle"
              onClick={() => openSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_HOSTS)}
            />
          </SettingListItem>

          <SettingListItem
            className="spf-settings-list-item"
            title={
              <SettingListItemTitle
                title={intl.formatMessage({ id: 'translate.page.email.auth.spf.approved.mx.records' })}
                entities={spf_mx}
                pluralLabel={intl.formatMessage({ id: 'translate.page.email.auth.spf.mx.records.label' })}
                onLinkClick={() => openSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_MX_RECORDS)}
              />
            }
            disableExpand
          >
            <IconButton
              aria-label={intl.formatMessage({ id: 'translate.aria-label.edit' })}
              className="spf-settings-list-item__icon"
              icon="edit"
              shape="circle"
              onClick={() => openSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_MX_RECORDS)}
            />
          </SettingListItem>

          <SettingListItem
            className="spf-settings-list-item"
            title={
              <SettingListItemTitle
                title={intl.formatMessage({ id: 'translate.page.email.auth.spf.approved.ip.blocks' })}
                entities={spf_addr}
                pluralLabel={intl.formatMessage({ id: 'translate.page.email.auth.spf.ip.blocks.label' })}
                onLinkClick={() => openSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_IP_BLOCKS)}
              />
            }
            disableExpand
          >
            <IconButton
              aria-label={intl.formatMessage({ id: 'translate.aria-label.edit' })}
              className="spf-settings-list-item__icon"
              icon="edit"
              shape="circle"
              onClick={() => openSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_IP_BLOCKS)}
            />
          </SettingListItem>

          <SettingListItem
            className="spf-settings-list-item"
            title={
              <SettingListItemTitle
                title={intl.formatMessage({ id: 'translate.page.email.auth.spf.include.list' })}
                entities={spf_include}
                pluralLabel={intl.formatMessage({ id: 'translate.page.email.auth.spf.include.list.label' })}
                onLinkClick={() => openSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_INCLUDE_LIST)}
              />
            }
            disableExpand
          >
            <IconButton
              aria-label={intl.formatMessage({ id: 'translate.aria-label.edit' })}
              className="spf-settings-list-item__icon"
              icon="edit"
              shape="circle"
              onClick={() => openSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_INCLUDE_LIST)}
            />
          </SettingListItem>
        </SettingList>

        <SettingList title={intl.formatMessage({ id: 'translate.page.email.auth.spf.approved.policy' })}>
          <SettingListItem
            title={intl.formatMessage({ id: 'translate.page.email.auth.spf.approved.info' })}
            disableExpand
          >
            <Dropdown
              options={[
                {
                  label: intl.formatMessage({ id: 'translate.page.email.auth.spf.approved.label.soft' }),
                  value: '~'
                },
                {
                  label: intl.formatMessage({ id: 'translate.page.email.auth.spf.approved.label.neutral' }),
                  value: '?'
                },
                {
                  label: intl.formatMessage({ id: 'translate.page.email.auth.spf.approved.label.Fail' }),
                  value: '-'
                }
              ]}
              optionValue="value"
              optionLabel="label"
              selectedValue={spf_all_quantifier || '~'}
              data-e2e="spf-approved-policy"
              onChange={(value) =>
                this.props.updateSPF({
                  ...this.getLocalSPFForRequestToBackend(),
                  domain_name: this.getSPFDomainName(),
                  spf_all_quantifier: value,
                  _metaFields: {
                    ...API_RESOURCE.SPF
                  },
                  _meta: {
                    notification: {
                      type: 'generic',
                      success: {
                        intlKey: 'translate.page.email.auth.spf.approved.label.success.message'
                      },
                      error: {
                        intlKey: 'translate.page.email.auth.spf.approved.label.error.message'
                      }
                    }
                  }
                })
              }
            />
          </SettingListItem>
        </SettingList>

        <Grid padding="responsive">
          {hasSPF && (
            <Textarea
              readOnly
              label={
                <Flex align="center" justify="space-between">
                  {intl.formatMessage({ id: 'translate.page.email.auth.spf.generated.record' })}

                  <Link role="button" onClick={() => this.handleCopyToClipboard(spf_txt)}>
                    {intl.formatMessage({ id: 'translate.generic.copy.to.clipboard' })}
                  </Link>
                </Flex>
              }
              value={spf_txt}
            />
          )}

          <Flex justify="flex-end">
            <Button
              color="secondary"
              type="outlined"
              onClick={() =>
                this.props.updateSPF({
                  domain_name: this.getSPFDomainName(),
                  ...this.getRecommendedSPF(),
                  _metaFields: {
                    ...API_RESOURCE.SPF
                  },
                  _meta: {
                    notification: {
                      type: 'generic',
                      success: {
                        intlKey: 'translate.page.email.auth.spf.reset.to.default.success.message'
                      },
                      error: {
                        intlKey: 'translate.page.email.auth.spf.reset.to.default.error.message'
                      }
                    }
                  }
                })
              }
            >
              {intl.formatMessage({ id: 'translate.page.email.auth.spf.reset.to.default' })}
            </Button>
          </Flex>

          {!sgDns && (
            <Notice type="warning" background="light" shadow={false}>
              <Text>{intl.formatMessage({ id: 'translate.page.email.auth.spf.bear.in.mind.notice.title' })}</Text>
            </Notice>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <PartialLoader
          resources={[
            { resourceName: API_RESOURCE.SPF.resourceName, methods: ['GET', 'DELETE'] },
            { resourceName: API_RESOURCE.DOMAIN_ALL.resourceName, methods: ['GET'] },
            { resourceName: API_RESOURCE.DOMAIN.resourceName, methods: ['GET'] },
            { requestTypeName: customRequestTypes.EMAIL_AUTHENTICATION_SPF_APPROVAL_POLICY }
          ]}
        >
          <Grid padding="responsive">
            <Text>{intl.formatMessage({ id: 'translate.page.email.auth.spf.info' })}</Text>

            {this.renderRecordsNotice()}
          </Grid>

          {this.renderSPFSettings()}

          {this.renderApprovedHostDialog()}
          {this.renderApprovedMXRecordsDialog()}
          {this.renderApprovedIPBlocksDialog()}
          {this.renderIncludeListDialog()}
        </PartialLoader>
      </div>
    );
  }

  renderApprovedHostDialog() {
    const { intl, updateSPF, closeSGDialog } = this.props;
    const { spf_host } = this.getLocalSPF();
    const domainName = this.getSPFDomainName();

    return (
      <SGDialogForm
        name={REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_HOSTS}
        icon="glob"
        title={intl.formatMessage({ id: 'translate.page.email.auth.spf.dialog.hosts.title' }, { domain: domainName })}
        resources={[{ requestTypeName: customRequestTypes.EMAIL_AUTHENTICATION_SPF }]}
      >
        <UpdateApprovedHostsForm
          info={intl.formatMessage({ id: 'translate.page.email.auth.spf.dialog.hosts.info' })}
          fieldName="spf_host"
          fieldLabel={intl.formatMessage({ id: 'translate.page.email.auth.spf.dialog.hosts.label' })}
          initialValues={{
            domain_name: domainName,
            spf_host: spf_host || [''],
            _metaFields: {
              ...API_RESOURCE.SPF
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.email.auth.spf.dialog.hosts.success.message'
                },
                error: {
                  intlKey: 'translate.page.email.auth.spf.dialog.hosts.error.message'
                }
              }
            }
          }}
          onSubmit={(data) =>
            updateSPF(
              {
                ...this.getLocalSPFForRequestToBackend(),
                ...data,
                spf_host: data.spf_host.filter((value) => value !== '')
              },
              () => closeSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_HOSTS)
            )
          }
        />
      </SGDialogForm>
    );
  }

  renderApprovedMXRecordsDialog() {
    const { intl, updateSPF, closeSGDialog } = this.props;
    const { spf_mx } = this.getLocalSPF();
    const domainName = this.getSPFDomainName();

    return (
      <SGDialogForm
        name={REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_MX_RECORDS}
        icon="glob"
        title={intl.formatMessage(
          { id: 'translate.page.email.auth.spf.dialog.mx.records.title' },
          { domain: domainName }
        )}
        resources={[{ requestTypeName: customRequestTypes.EMAIL_AUTHENTICATION_SPF }]}
      >
        <UpdateApprovedMXRecordsForm
          info={intl.formatMessage({ id: 'translate.page.email.auth.spf.dialog.mx.records.info' })}
          fieldName="spf_mx"
          fieldLabel={intl.formatMessage({ id: 'translate.page.email.auth.spf.dialog.mx.records.label' })}
          initialValues={{
            domain_name: domainName,
            spf_mx: spf_mx || [''],
            _metaFields: {
              ...API_RESOURCE.SPF
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.email.auth.spf.dialog.mx.records.success.message'
                },
                error: {
                  intlKey: 'translate.page.email.auth.spf.dialog.mx.records.error.message'
                }
              }
            }
          }}
          onSubmit={(data) =>
            updateSPF(
              {
                ...this.getLocalSPFForRequestToBackend(),
                ...data,
                spf_mx: data.spf_mx.filter((value) => value !== '')
              },
              () => closeSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_MX_RECORDS)
            )
          }
        />
      </SGDialogForm>
    );
  }

  renderApprovedIPBlocksDialog() {
    const { intl, updateSPF, closeSGDialog } = this.props;
    const { spf_addr } = this.getLocalSPF();
    const domainName = this.getSPFDomainName();

    return (
      <SGDialogForm
        name={REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_IP_BLOCKS}
        icon="location-tree"
        title={intl.formatMessage(
          { id: 'translate.page.email.auth.spf.dialog.ip.blocks.title' },
          { domain: domainName }
        )}
        resources={[{ requestTypeName: customRequestTypes.EMAIL_AUTHENTICATION_SPF }]}
      >
        <UpdateApprovedIPBlocksForm
          info={intl.formatMessage({ id: 'translate.page.email.auth.spf.dialog.ip.blocks.info' })}
          fieldName="spf_addr"
          fieldLabel={intl.formatMessage({ id: 'translate.page.email.auth.spf.dialog.ip.blocks.label' })}
          initialValues={{
            domain_name: domainName,
            spf_addr: spf_addr || [''],
            _metaFields: {
              ...API_RESOURCE.SPF
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.email.auth.spf.dialog.ip.blocks.success.message'
                },
                error: {
                  intlKey: 'translate.page.email.auth.spf.dialog.ip.blocks.error.message'
                }
              }
            }
          }}
          onSubmit={(data) =>
            updateSPF(
              {
                ...this.getLocalSPFForRequestToBackend(),
                ...data,
                spf_addr: data.spf_addr.filter((value) => value !== '')
              },
              () => closeSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_IP_BLOCKS)
            )
          }
        />
      </SGDialogForm>
    );
  }

  renderIncludeListDialog() {
    const { intl, updateSPF, closeSGDialog } = this.props;
    const { spf_include } = this.getLocalSPF();
    const domainName = this.getSPFDomainName();

    return (
      <SGDialogForm
        name={REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_INCLUDE_LIST}
        icon="location-tree"
        title={intl.formatMessage(
          { id: 'translate.page.email.auth.spf.dialog.include.list.title' },
          { domain: domainName }
        )}
        resources={[{ requestTypeName: customRequestTypes.EMAIL_AUTHENTICATION_SPF }]}
      >
        <UpdateApprovedIncludeListForm
          info={intl.formatMessage({ id: 'translate.page.email.auth.spf.dialog.include.list.info' })}
          fieldLabel={intl.formatMessage({ id: 'translate.page.email.auth.spf.dialog.include.list.label' })}
          fieldName="spf_include"
          initialValues={{
            domain_name: domainName,
            spf_include: spf_include || [''],
            _metaFields: {
              ...API_RESOURCE.SPF
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.email.auth.spf.dialog.include.list.success.message'
                },
                error: {
                  intlKey: 'translate.page.email.auth.spf.dialog.include.list.error.message'
                }
              }
            }
          }}
          onSubmit={(data) =>
            updateSPF(
              {
                ...this.getLocalSPFForRequestToBackend(),
                ...data,
                spf_include: data.spf_include.filter((value) => value !== '')
              },
              () => closeSGDialog(REDUX_FORM.EMAIL_AUTH_MANAGE_APPROVED_INCLUDE_LIST)
            )
          }
        />
      </SGDialogForm>
    );
  }
}

const mapStateToProps = (state) => ({
  spf: state.pageItems.spf || []
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  ...bindActionCreators(
    {
      createNotification,
      updateSPF,
      ...sgDialogActions
    },
    dispatch
  )
});

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl<any, any>(SPF)) as any;
