import * as React from 'react';

import {
  Button,
  Container,
  Dropdown,
  Flex,
  Grid,
  HostingTypes,
  Icon,
  Link,
  Notice,
  Section,
  Tab,
  Tabs,
  Text,
  textToHTML
} from '@siteground/styleguide';

import { navigateToUA } from '../../../core/actions/nemo-store';
import { createNotification } from '../../../core/actions/notifications';
import { openNewTabAction } from '../../../core/actions/open-new-tab';
import { requestUnknownFiles } from '../../../core/actions/pages/wp-staging';
import { requestData, requestNemoData } from '../../../core/actions/request-data';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { createTaskLoaderMetadata } from '../../../core/common/create-metadata';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, LocalTaskLoaderType, REDUX_FORM } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { PLAN_CARD_ICON_PROPS } from '../../../core/constants/icon-props';
import { ToolId } from '../../../core/constants/route-info';
import { getCurrentSite, getStagingApps, getWordpressApps } from '../../../core/selectors';
import { ascendingSortByProperty, descendingSortByProperty } from '../../../core/utils/sort';
import DateWithTime from '../../components/date-with-time';
import indexWithCRUD from '../../components/indexWithCRUD';
import NoWPApplicationNotice from '../../components/no-wordpress-app-notice';
import PageHeader from '../../components/page-header';
// COMPONENTS
import PartialLoader from '../../components/partial-loader';
import { PlanCard } from '../../components/plan-card';
import { SGDialog, SGDialogCancel, SGDialogForm } from '../../components/sg-dialog';
import SGLink from '../../components/sg-link';
import SGTable from '../../components/sg-table';
import { TableHeaderSort } from '../../components/sg-table-sort';
import TableContextMenu from '../../components/table-context-menu';
import { SecondLevelTitle } from '../../components/titles';
import VCS from '../../components/visibility-control-service';
import { CreateBox, CreateForm } from './create';
import CustomDeploy, { CustomDeploySubmitData } from './custom-deploy';
import IncludeFilesSelector from './include-files-selector';
import RenameStaging from './rename/rename-staging';
import ReplicateStaging from './replicate/replicate-staging';
import { CustomDeployResponse, OptionsStruct } from './types';
import { defaultCustomDeployData, formatCustomDeployData } from './utils';

type Props = {
  connectedCurrencyProps: ConnectedCurrencyProps;
  wordpressApps: WordpressApp[];
  getStagingApps: (sourceAppId: number) => WordpressApp[];
  actions: CrudActions;
  stagingApps: WordpressApp[];
  shouldRenderSalesPage: boolean;
  environment: { isPhone: boolean };
  locationQuery: any;
  router: {
    location: {
      query: any;
    };
    push: Function;
  };
  items: {
    app: any[];
  };
  intl: Intl;
  requestUnknownFiles: typeof requestUnknownFiles;
  requestData: typeof requestData;
  openSGDialog: typeof sgDialogActions.openSGDialog;
  closeSGDialog: typeof sgDialogActions.closeSGDialog;
  updatePageData: Function;
  requestNemoData: typeof requestNemoData;
  selectedSite: SiteItem;
  canOrder: boolean;
  openNewTabAction: typeof openNewTabAction;
  navigateToUA: typeof navigateToUA;
  createNotification: typeof createNotification;
};

type State = {
  selectedApp: WordpressApp;
  tableTab: TableTabs;
  stagingItemSelected: WordpressApp;
  stagingUnknownFiles: string[];
  createSubmitData: CreateItemPayload;
  availableBackups: AvailableBackup[];
  customDeployData: {
    data: {
      added: OptionsStruct;
      changed: OptionsStruct;
      deleted: OptionsStruct;
      skipped: OptionsStruct;
    };
    total: number;
    loaded: boolean;
  };
  upgradePlanData: OrderUpgradeData;
  selectedSharedPlan: HostingTypes;
};

type AvailableBackup = {
  timestamp: string;
  tool: 'staging' | 'backup';
};

enum TableTabs {
  Staging = 'STAGING',
  Backup = 'BACKUP'
}

export const PROTECTED_URL_LINK_PLACEHOLDER = '__protectedUrlLink__';
export const FOREIGN_KEY_MESSAGE = 'foreign key constraint';

class WpStaging extends React.Component<Props, State> {
  readonly state: State = {
    selectedApp: null,
    tableTab: TableTabs.Staging,
    availableBackups: [],
    stagingItemSelected: null,
    stagingUnknownFiles: null,
    createSubmitData: null,
    customDeployData: null,
    upgradePlanData: null,
    selectedSharedPlan: null
  };

  componentDidMount() {
    const { selectedSite, requestNemoData, shouldRenderSalesPage } = this.props;

    if (shouldRenderSalesPage) {
      requestNemoData(
        {
          endpoint: `/order_upgrade/${selectedSite.account_id}`,
          method: 'GET',
          requestTypeName: customRequestTypes.REQUEST_ACCOUNT_UPGRADE_PLANS
        },
        (upgradeResponse: OrderUpgradeData) => this.setState({ upgradePlanData: upgradeResponse })
      );
    }
  }

  componentDidUpdate() {
    const { wordpressApps, actions } = this.props;
    const { selectedApp } = this.state;

    if (!selectedApp && wordpressApps[0]) {
      this.selectApp(wordpressApps[0]);
    }
  }

  renderContextMenu = (_, entity) => {
    const { intl } = this.props;

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.APP.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.page.staging.table.action.full.deploy' }),
            e2eAttr: 'wp-staging-context-menu-item-full-deploy',
            icon: 'deploy',
            onClick: () => this.onStagingAction('full-deploy', entity)
          },
          {
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.page.staging.table.action.custom.deploy' }),
            e2eAttr: 'wp-staging-context-menu-item-custom-deploy',
            icon: 'custom-deploy',
            onClick: () => this.onStagingAction('custom-deploy', entity)
          },
          {
            vcsMethod: 'DELETE',
            label: intl.formatMessage({ id: 'translate.page.staging.table.action.destroy' }),
            e2eAttr: 'wp-staging-context-menu-item-destroy',
            icon: 'destroy',
            onClick: () => this.onStagingAction('destroy', entity)
          },
          {
            vcsMethod: 'POST',
            label: intl.formatMessage({ id: 'translate.page.staging.table.action.replicate' }),
            e2eAttr: 'wp-staging-context-menu-item-replicate',
            icon: 'replicate',
            onClick: () => this.onStagingAction('replicate', entity)
          },
          {
            label: intl.formatMessage({ id: 'translate.page.staging.table.action.login.admin.panel' }),
            e2eAttr: 'wp-staging-context-menu-item-log-in-admin',
            visibleOnDesktop: true,
            icon: 'login',
            shortcut: <Icon color="dark" name="external-link" size="14" />,
            onClick: () => this.onStagingAction('log-in-to-admin', entity)
          },
          {
            label: intl.formatMessage({ id: 'translate.page.staging.table.action.rename' }),
            e2eAttr: 'wp-staging-context-menu-item-rename',
            icon: 'rename',
            onClick: () => this.onStagingAction('rename', entity)
          }
        ]}
      />
    );
  };

  selectApp = (app: WordpressApp) => {
    const { actions, wordpressApps } = this.props;
    this.setState({ selectedApp: app }, this.updateBackups);
  };

  updateBackups = () =>
    this.props.actions.fetchItem(
      {
        ...API_RESOURCE.APP_LATEST,
        itemId: this.state.selectedApp && this.state.selectedApp.id
      },
      ({ available_backups }) => {
        this.setState({
          availableBackups: available_backups || []
        });
      }
    );

  onStagingAction = (actionId: string, app: WordpressApp) => {
    const { openSGDialog, requestData } = this.props;

    this.setState({ stagingItemSelected: app });

    switch (actionId) {
      case 'log-in-to-admin':
        return requestData(
          {
            payload: {
              _metaFields: {
                endpoint: `${API_RESOURCE.WORDPRESS.endpoint}/${app.id}`,
                method: 'PUT',
                itemId: app.id
              },
              urlParams: {
                auto_login: 1
              }
            },
            requestTypeName: customRequestTypes.GET_WP_LOGIN_URL
          },
          ({ autologin_url }) => this.props.openNewTabAction({ url: autologin_url })
        );
      case 'full-deploy':
        return openSGDialog(DIALOGS.WP_STAGING_FULL_DEPLOY, app);
      case 'custom-deploy':
        return this.requestCustomDeploy(app);
      case 'destroy':
        return openSGDialog(DIALOGS.WP_STAGING_DESTROY, app);
      case 'replicate':
        return openSGDialog(REDUX_FORM.REPLICATE_WP_APP_STAGING, app);
      case 'rename':
        return openSGDialog(REDUX_FORM.CHANGE_WP_APP_STAGING_NAME, app);
      default:
        return;
    }
  };

  requestFullDeploy = (force = false) => {
    const { actions } = this.props;
    const app = this.state.stagingItemSelected;

    const intlValues = {
      siteName: app && app.label
    };

    const updatePayload: UpdateItemPayload = {
      id: app && app.id,
      push_to_live: 1,
      skip_foreign_key_check: Number(force),
      _metaFields: {
        ...API_RESOURCE.APP_STAGING
      }
    };

    const onSuccess = () => {
      actions.fetchItems(API_RESOURCE.APP);
      this.updateBackups();
      this.props.createNotification({
        type: 'generic',
        state: 'success',
        success: {
          intlKey: 'translate.page.staging.notification.deploy.success',
          intlValues
        }
      });
    };

    const onError = (response) => {
      const { message, err_fields } = response;
      const hasForeignKeyError = JSON.stringify(err_fields)?.includes(FOREIGN_KEY_MESSAGE);

      if (hasForeignKeyError) {
        return this.props.createNotification({
          type: 'form',
          formName: REDUX_FORM.CREATE_STAGING,
          state: 'error',
          error: {
            intlKey: 'translate.page.staging.notification.deploy.fail.title',
            intlValues
          },
          responseError: {
            message
          },
          responseData: {
            hasForeignKeyError
          }
        });
      }

      this.props.createNotification({
        type: 'generic',
        state: 'error',
        error: {
          intlKey: 'translate.page.staging.notification.deploy.fail',
          intlValues: {
            ...intlValues,
            error: message
          }
        }
      });
    };

    actions.updateItem(updatePayload, onSuccess, onError);
  };

  requestCustomDeploy = (app: WordpressApp) => {
    const { actions, requestData, openSGDialog, createNotification } = this.props;
    const appId = app && app.id;

    requestData(
      {
        payload: {
          _metaFields: {
            method: 'GET',
            itemId: appId,
            endpoint: `${API_RESOURCE.APP_STAGING.endpoint}/${encodeURIComponent(String(appId))}`
          },
          urlParams: {
            _timeout: 20
          },
          _metaResourceName: API_RESOURCE.CUSTOM_DEPLOY_STAGING.resourceName
        },
        requestTypeName: customRequestTypes.REQUEST_CUSTOM_DEPLOY_STAGING
      },
      (data: CustomDeployResponse) => {
        this.setState({ customDeployData: formatCustomDeployData(data) }, () =>
          openSGDialog(DIALOGS.WP_STAGING_CUSTOM_DEPLOY, app)
        );
      },
      () => {
        createNotification({
          type: 'generic',
          state: 'error',
          error: {
            intlKey: 'translate.page.wp-staging.request-custom-deploy.failed-request'
          }
        });
      }
    );
  };

  onCreateFormSubmit = (appId: number, formData: CreateItemPayload) => (filesData) => {
    const { actions, openSGDialog } = this.props;
    const stagingUnknownFiles = filesData.unknown_files || [];

    const notification: SpanelNotification = {
      type: 'form',
      formName: REDUX_FORM.CREATE_STAGING,
      success: {
        intlKey: 'translate.page.staging.create.success.message',
        intlValues: {
          name: formData.label
        }
      },
      error: {
        intlKey: 'translate.page.staging.create.failure.message',
        intlValues: {
          name: formData.label
        }
      }
    };

    if (stagingUnknownFiles.length === 0) {
      actions.createItem({
        ...formData,
        app_id: appId,
        extra_files: [],
        _meta: {
          ...createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX),
          notification
        }
      });
    } else {
      this.setState(
        {
          stagingUnknownFiles,
          createSubmitData: {
            ...formData,
            app_id: appId,
            _meta: {
              ...createTaskLoaderMetadata(LocalTaskLoaderType.CREATE_BOX),
              notification
            }
          }
        },
        () => openSGDialog(DIALOGS.WP_STAGING_INCLUDE_FILES)
      );
    }
  };

  restoreBackup = ({ timestamp, tool }: AvailableBackup) => {
    const { actions, intl } = this.props;
    const { selectedApp } = this.state;

    actions.updateItem({
      _timeout: 0,
      _metaFields: {
        ...API_RESOURCE.APP_RESTORE_BACKUP
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.staging.restore.success.notification'
          },
          error: {
            intlKey: 'translate.page.staging.restore.fail.notification'
          }
        }
      },
      id: selectedApp && selectedApp.id,
      timestamp,
      tool
    });
  };

  render() {
    const { shouldRenderSalesPage } = this.props;

    return <React.Fragment>{shouldRenderSalesPage ? this.renderSalesPage() : this.renderStagingTool()}</React.Fragment>;
  }

  renderPageHeader = () => {
    const { intl } = this.props;

    return (
      <PageHeader
        id={ToolId.wpStaging}
        instructions={intl.formatMessage(
          { id: 'translate.page.staging.info.text' },
          { protectedUrlLink: PROTECTED_URL_LINK_PLACEHOLDER }
        )}
        renderNoticeText={(text: string) => {
          const textParts = text.split(PROTECTED_URL_LINK_PLACEHOLDER);
          return (
            <Text>
              {textToHTML(textParts[0])}
              <SGLink
                to="/protected-urls"
                text={<Link>{intl.formatMessage({ id: 'translate.page.protected.title' })}</Link>}
              />
              {textToHTML(textParts[1])}
            </Text>
          );
        }}
      />
    );
  };

  renderStagingTool = () => {
    const { wordpressApps, items } = this.props;
    const hasNoWPApps = items[API_RESOURCE.APP.resourceName] && wordpressApps.length === 0;

    return (
      <React.Fragment>
        {this.renderPageHeader()}
        <Section>{hasNoWPApps ? <NoWPApplicationNotice /> : this.renderPageContent()}</Section>
        {this.renderIncludeFilesDialog()}
        {this.renderFullDeployDialog()}
        {this.renderCustomDeployDialog()}
        {this.renderDestroyDialog()}
        {this.renderRenameDialog()}
        {this.renderReplicateDialog()}
      </React.Fragment>
    );
  };

  renderSalesPage = () => {
    const { intl, selectedSite, navigateToUA } = this.props;
    const { upgradePlanData, selectedSharedPlan } = this.state;

    return (
      <Grid gap="x-large">
        {this.renderPageHeader()}
        <Section>
          <Grid gap="xx-large">
            <Notice type="info">
              {intl.formatMessage({ id: 'translate.page.wp-staging.sales-page.info-box.text' })}
            </Notice>
            <PartialLoader resources={[{ requestTypeName: customRequestTypes.REQUEST_ACCOUNT_UPGRADE_PLANS }]}>
              <div>
                {upgradePlanData && upgradePlanData.plans && this.renderPlanCards(upgradePlanData)}
                {selectedSharedPlan && (
                  <Flex gutter="medium" margin="medium" justify="center" padding={['medium', 'none']}>
                    <Button
                      color="primary"
                      size="large"
                      onClick={() =>
                        navigateToUA({
                          page: 'upgrade-order',
                          siteId: selectedSite.id,
                          planType: selectedSharedPlan,
                          accountId: selectedSite.account_id
                        })
                      }
                    >
                      {intl.formatMessage({ id: 'translate.generic.continue' })}
                    </Button>
                  </Flex>
                )}
              </div>
            </PartialLoader>
          </Grid>
        </Section>
      </Grid>
    );
  };

  renderTabs = () => (
    <Tabs border="light">
      <VCS resourceName={API_RESOURCE.APP.resourceNameMetaApi} hasMethod="GET">
        <Tab
          data-e2e="wp-staging-tab-staging"
          active={this.state.tableTab === TableTabs.Staging}
          onClick={() => this.setState({ tableTab: TableTabs.Staging })}
        >
          {this.props.intl.formatMessage({ id: 'translate.page.staging.tab.staging.label' })}
        </Tab>
      </VCS>
      <Tab
        data-e2e="wp-staging-tab-backup"
        active={this.state.tableTab === TableTabs.Backup}
        onClick={() => this.setState({ tableTab: TableTabs.Backup })}
      >
        {this.props.intl.formatMessage({ id: 'translate.page.staging.tab.backup.label' })}
      </Tab>
    </Tabs>
  );

  renderPageContent() {
    const { intl, wordpressApps, getStagingApps, requestUnknownFiles, environment } = this.props;

    const { selectedApp, tableTab, availableBackups } = this.state;

    const backupsList = availableBackups
      .map(({ timestamp, ...other }) => ({ id: timestamp, timestamp, ...other }))
      .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));

    const appId = selectedApp && selectedApp.id;
    const stagingApps = getStagingApps(appId);

    const stagingSiteTableProps = {
      data: stagingApps,
      showSkeleton: this.props.items[API_RESOURCE.APP.resourceName] === undefined,
      resources: [
        {
          resourceName: API_RESOURCE.APP.resourceName,
          methods: ['GET']
        }
      ],
      rowResources: [
        {
          resourceName: API_RESOURCE.APP.resourceName,
          methods: ['PUT', 'DELETE']
        },
        {
          resourceName: API_RESOURCE.APP_STAGING.resourceName,
          methods: ['POST', 'PUT', 'DELETE']
        },
        {
          requestTypeName: customRequestTypes.GET_WP_LOGIN_URL
        },
        {
          requestTypeName: customRequestTypes.REQUEST_CUSTOM_DEPLOY_STAGING
        }
      ],
      columns: [
        {
          header: () => (
            <TableHeaderSort
              label={intl.formatMessage({ id: 'translate.page.staging.table.column.site' })}
              config={{
                ascending: ascendingSortByProperty('label'),
                descending: descendingSortByProperty('label')
              }}
            />
          ),
          accessor: 'label',
          render: (value, { app_url }) => (
            <Link style={{ fontWeight: 'normal' }} target="_blank" href={app_url} title={app_url}>
              {value}
            </Link>
          )
        },
        {
          header: () => (
            <TableHeaderSort
              label={intl.formatMessage({ id: 'translate.page.staging.table.column.created' })}
              config={{
                ascending: ascendingSortByProperty('created'),
                descending: descendingSortByProperty('created')
              }}
            />
          ),
          accessor: 'created',
          render: (value) => <DateWithTime date={value} />
        },
        {
          header: () => (
            <TableHeaderSort
              label={intl.formatMessage({ id: 'translate.page.staging.table.last.deploy' })}
              config={{
                ascending: ascendingSortByProperty('last_deploy'),
                descending: descendingSortByProperty('last_deploy')
              }}
            />
          ),
          accessor: 'last_deploy',
          render: (value) =>
            value ? (
              <DateWithTime date={value} />
            ) : (
              <Text weight="bold">{intl.formatMessage({ id: 'translate.page.staging.table.never.label' })}</Text>
            )
        },
        {
          header: intl.formatMessage({ id: 'translate.generic.actions' }),
          accessor: 'actions',
          render: this.renderContextMenu
        }
      ]
    };

    const backupTableProps = {
      hasMenu: false,
      data: backupsList,
      resources: [
        {
          resourceName: API_RESOURCE.APP_LATEST.resourceName,
          methods: ['GET']
        },
        {
          resourceName: API_RESOURCE.APP_RESTORE_BACKUP.resourceName,
          methods: ['PUT']
        },
        {
          resourceName: API_RESOURCE.APP.resourceName,
          methods: ['GET']
        }
      ],
      columns: [
        {
          header: intl.formatMessage({ id: 'translate.page.staging.table.column.created' }),
          accessor: 'timestamp',
          render: (value) => value && <DateWithTime date={value} />
        },
        {
          // Dummy column to prevent resizing of the table from tab change
          accessor: 'timestamp',
          header: ' ',
          style: environment.isPhone ? { display: 'none' } : undefined,
          render: () => null
        },
        {
          // Dummy column to prevent resizing of the table from tab change
          accessor: 'timestamp',
          header: ' ',
          style: environment.isPhone ? { display: 'none' } : undefined,
          render: () => null
        },
        {
          header: intl.formatMessage({ id: 'translate.generic.actions' }),
          accessor: 'tool',
          render: (tool, availableBackup: AvailableBackup) => (
            <Button type="outlined" color="secondary" onClick={() => this.restoreBackup(availableBackup)}>
              {intl.formatMessage({ id: 'translate.generic.restore' })}
            </Button>
          )
        }
      ]
    };

    return (
      <Grid>
        <div>
          <SecondLevelTitle>
            {intl.formatMessage({ id: 'translate.page.wp-staging.select.domain.label' })}
          </SecondLevelTitle>
          <Dropdown
            options={wordpressApps}
            selectedValue={wordpressApps && wordpressApps[0] && wordpressApps[0].id}
            optionValue="id"
            optionLabel="app_url"
            size="x-large"
            enableShadow
            onChange={(id, app) => this.selectApp(app)}
          />
        </div>
        <CreateBox router={this.props.router} handleTryAgain={() => this.requestFullDeploy(true)}>
          <CreateForm
            apps={wordpressApps}
            onSubmit={(data: CreateItemPayload) => requestUnknownFiles(appId, this.onCreateFormSubmit(appId, data))}
          />
        </CreateBox>

        <div>
          <SecondLevelTitle>{intl.formatMessage({ id: 'translate.page.staging.manage.sites.title' })}</SecondLevelTitle>

          <Container padding="none">
            {this.renderTabs()}

            <Grid padding={environment.isPhone ? 'medium' : 'none'}>
              {tableTab === TableTabs.Staging ? (
                <SGTable key="staging" shadow={false} mobileLayout="card-flat" {...stagingSiteTableProps} />
              ) : (
                <SGTable key="backup" shadow={false} mobileLayout="card-flat" {...backupTableProps} />
              )}
            </Grid>
          </Container>
        </div>
      </Grid>
    );
  }

  renderIncludeFilesDialog = () => {
    const { actions, closeSGDialog } = this.props;
    const { stagingUnknownFiles, createSubmitData } = this.state;

    return (
      <IncludeFilesSelector
        items={stagingUnknownFiles}
        onSubmit={(files: string[]) => {
          closeSGDialog(DIALOGS.WP_STAGING_INCLUDE_FILES);

          actions.createItem({
            ...createSubmitData,
            extra_files: files
          });
        }}
        onCloseHandler={() =>
          this.setState({
            stagingUnknownFiles: null,
            createSubmitData: null
          })
        }
      />
    );
  };

  renderFullDeployDialog = () => {
    const { intl } = this.props;
    const app = this.state.stagingItemSelected;

    return (
      <SGDialog
        id={DIALOGS.WP_STAGING_FULL_DEPLOY}
        icon="deploy"
        state="warning"
        title={intl.formatMessage({ id: 'translate.page.staging.dialog.deploy.title' }, { name: app && app.label })}
        footer={this.renderConfirmDialogFooter(
          DIALOGS.WP_STAGING_FULL_DEPLOY,
          intl.formatMessage({ id: 'translate.page.staging.dialog.deploy.button' }),
          this.requestFullDeploy
        )}
      >
        <Text>{intl.formatMessage({ id: 'translate.page.staging.dialog.deploy.message' })}</Text>
      </SGDialog>
    );
  };

  renderCustomDeployDialog = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const { customDeployData, stagingItemSelected } = this.state;

    const id = stagingItemSelected && stagingItemSelected.id;
    const label = stagingItemSelected && stagingItemSelected.label;

    const intlValues = {
      siteName: label
    };

    const updatePayload: UpdateItemPayload = {
      id,
      push_to_live: 1,
      advanced: 1,
      _metaFields: {
        ...API_RESOURCE.APP_STAGING
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.staging.notification.deploy.success',
            intlValues
          },
          error: {
            intlKey: 'translate.page.staging.notification.deploy.fail',
            intlValues
          }
        }
      }
    };

    const deployData = customDeployData || defaultCustomDeployData;

    return (
      <CustomDeploy
        name={label}
        customDeployData={deployData.data}
        dataLoaded={deployData.loaded}
        total={deployData.total}
        onCloseHandler={() => {
          this.setState({
            customDeployData: null
          });
        }}
        onSubmit={(data: CustomDeploySubmitData) => {
          closeSGDialog(DIALOGS.WP_STAGING_CUSTOM_DEPLOY);

          actions.updateItem(
            {
              ...updatePayload,
              ...data
            },
            () => {
              actions.fetchItems(API_RESOURCE.APP);
              this.updateBackups();
            }
          );
        }}
      />
    );
  };

  renderDestroyDialog = () => {
    const { intl, actions, closeSGDialog, updatePageData } = this.props;
    const app = this.state.stagingItemSelected;

    const intlValues = {
      siteName: app && app.label
    };

    const deletePayload: DeleteItemPayload = {
      itemId: app && app.id,
      _metaFields: {
        ...API_RESOURCE.APP_STAGING
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.staging.notification.destroy.success',
            intlValues
          },
          error: {
            intlKey: 'translate.page.staging.notification.destroy.fail',
            intlValues
          }
        }
      }
    };

    return (
      <SGDialog
        id={DIALOGS.WP_STAGING_DESTROY}
        icon="destroy"
        state="warning"
        title={intl.formatMessage(
          { id: 'translate.page.staging.dialog.destroy.confirm.title' },
          { name: app && app.label }
        )}
        footer={this.renderConfirmDialogFooter(
          DIALOGS.WP_STAGING_DESTROY,
          intl.formatMessage({ id: 'translate.generic.confirm' }),
          () => actions.deleteItem(deletePayload, updatePageData as OnCompleteCallback)
        )}
      >
        <Text>{intl.formatMessage({ id: 'translate.page.staging.dialog.destroy.confirm.message' })}</Text>
      </SGDialog>
    );
  };

  renderRenameDialog = () => {
    const { intl, actions, closeSGDialog } = this.props;
    const app = this.state.stagingItemSelected;
    const id = app && app.id;
    const label = app && app.label;

    const intlValues = {
      siteName: label
    };

    const initialValues: UpdateItemPayload = {
      id,
      label,
      _metaFields: {
        ...API_RESOURCE.APP
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.staging.notification.rename.success',
            intlValues
          },
          error: {
            intlKey: 'translate.page.staging.notification.rename.fail',
            intlValues
          }
        }
      }
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.CHANGE_WP_APP_STAGING_NAME}
        icon="rename"
        title={intl.formatMessage({ id: 'translate.page.staging.dialog.rename.message' }, { name: label })}
        resources={[
          {
            resourceName: API_RESOURCE.APP.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <RenameStaging
          initialValues={initialValues}
          onSubmit={(data: UpdateItemPayload) =>
            actions.updateItem(data, () => closeSGDialog(REDUX_FORM.CHANGE_WP_APP_STAGING_NAME))
          }
        />
      </SGDialogForm>
    );
  };

  renderReplicateDialog = () => {
    const { closeSGDialog, requestData, intl } = this.props;
    const app = this.state.stagingItemSelected;

    const initialValues: RequestDataPayload = {
      app_id: app && app.id,
      _metaFields: {
        endpoint: `${API_RESOURCE.APP_STAGING.endpoint}`,
        method: 'POST'
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.replicate.success.message'
          }
        }
      }
    };

    return (
      <SGDialogForm
        name={REDUX_FORM.REPLICATE_WP_APP_STAGING}
        icon="replicate"
        title={intl.formatMessage({ id: 'translate.page.staging.dialog.replicate.title' })}
        resources={[
          {
            resourceName: API_RESOURCE.APP_STAGING.resourceName,
            methods: ['POST']
          }
        ]}
      >
        <ReplicateStaging
          initialValues={initialValues}
          onSubmit={(data) => {
            requestData({
              payload: { ...data },
              requestTypeName: customRequestTypes.REQUEST_REPLICATE_STAGING
            });
            closeSGDialog(REDUX_FORM.REPLICATE_WP_APP_STAGING);
          }}
        />
      </SGDialogForm>
    );
  };

  renderConfirmDialogFooter = (dialogId: string, confirmLabel: string, onConfirm: Function) => (
    <React.Fragment>
      <SGDialogCancel id={dialogId} />
      <Button
        color="primary"
        onClick={() => {
          this.props.closeSGDialog(dialogId);
          onConfirm();
        }}
      >
        {confirmLabel}
      </Button>
    </React.Fragment>
  );

  renderPlanCards = (upgradePlanData: OrderUpgradeData) => {
    const { intl } = this.props;

    return (
      <Grid sm="3" gap="responsive">
        {Object.values(upgradePlanData.plans.shared).map((plan: PlanDetails, index: number) => (
          <PlanCard
            key={index}
            plan={plan}
            intl={intl}
            iconProps={PLAN_CARD_ICON_PROPS[plan.name]}
            buttonProps={{
              text: intl.formatMessage({ id: 'translate.generic.select.plan.label' }),
              textSelected: intl.formatMessage({ id: 'translate.generic.selected' }),
              selected: plan.name === this.state.selectedSharedPlan,
              onClick: () => this.setState({ selectedSharedPlan: plan.name as any })
            }}
          />
        ))}
      </Grid>
    );
  };
}

export default indexWithCRUD(
  (store) => ({
    wordpressApps: getWordpressApps(store),
    environment: store.environment,
    getStagingApps: (sourceAppId?) => getStagingApps(store, API_RESOURCE.APP.resourceName, sourceAppId),
    selectedSite: getCurrentSite(store)
  }),
  {
    ...sgDialogActions,
    requestUnknownFiles,
    requestData,
    requestNemoData,
    navigateToUA,
    openNewTabAction,
    createNotification
  }
)(WpStaging, API_RESOURCE.APP);
