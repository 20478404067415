import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Icon } from '@siteground/styleguide';
import { KEYS } from '@siteground/styleguide/lib/utils';
import { isApple } from '@siteground/styleguide/lib/utils/get-device-util';

import './tool-finder-button.scss';

const ToolFinderButton = ({ intl, onClick }) => {
  return (
    <div
      className="tool-finder"
      data-e2e="tool-finder"
      data-component="tool-finder"
      tabIndex={0}
      role="search-box"
      onClick={onClick}
      onKeyDown={(event) => {
        if (!onClick) {
          return;
        }

        if (event.key === KEYS.SPACE || event.key === KEYS.ENTER) {
          event.preventDefault();
          event.stopPropagation();

          onClick(event);
        }
      }}
    >
      <Icon size="14" name="search" />
      &nbsp;
      {intl.formatMessage({ id: isApple() ? 'translate.tool.finder.text.mac.os' : 'translate.tool.finder.text' })}
    </div>
  );
};

export default injectIntl(ToolFinderButton);
