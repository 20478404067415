import * as React from 'react';
import { Grid, List, ListItem, Notice, Section, Text, textToHTML } from '@siteground/styleguide';
import * as sgDialogActions from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { ToolId } from '../../../core/constants/route-info';
import { ascendingSortByProperty, descendingSortByProperty } from '../../../core/utils/sort';
import { DeleteDialog } from '../../components/dialogs';
import indexWithCRUD from '../../components/indexWithCRUD';
import PageHeader from '../../components/page-header';
import SGTable from '../../components/sg-table';
import { TableHeaderSort } from '../../components/sg-table-sort';
import TableContextMenu from '../../components/table-context-menu/table-context-menu';
import VCS from '../../components/visibility-control-service';
import { CreateBox, CreateForm } from './create';
import { createNotification } from '../../../core/actions/notifications';
import { requestNemoData } from '../../../core/actions/request-data';
import { getCurrentSiteId } from '../../../core/selectors';
import customRequestTypes from '../../../core/constants/custom-request-types';

type DomainAliasProps = {
  actions: CrudActions;
  items: any;
  location: any;
  currentDomainName: any;
  currentSiteId: string;
  intl: Intl;
  openSGDialog: Function;
  closeSGDialog: Function;
  requestNemoData: typeof requestNemoData;
  createNotification: typeof createNotification;
};

class DomainAliasPage extends React.Component<DomainAliasProps, any> {
  readonly state = {
    currentDeleteConformationDialogPayload: null
  };

  onCreateFormSubmit = (formData) => {
    this.props.requestNemoData(
      {
        endpoint: `/sites/${this.props.currentSiteId}/domain-alias`,
        method: 'POST',
        requestTypeName: customRequestTypes.DOMAIN_ALIAS,
        body: {
          domain: formData.name,
          alias_to: formData.alias_to
        }
      },
      (data) => {
        this.props.actions.fetchItems(
          {
            ...API_RESOURCE.DOMAIN_ALIAS
          },
          (data) => {
            this.props.createNotification({
              type: 'form',
              state: 'success',
              formName: REDUX_FORM.CREATE_DOMAIN_ALIAS,
              success: {
                intlKey: 'translate.page.domainAlias.create.success.message',
                intlValues: { domain: formData.name }
              }
            });
          }
        );
      },
      (error) => {
        this.props.createNotification({
          type: 'form',
          formName: REDUX_FORM.CREATE_DOMAIN_ALIAS,
          error: {
            intlKey: error.validation.domain.intl_id,
            intlValues: error.validation.domain.params
          },
          responseError: {
            message: error.message
          }
        });
      }
    );
  };

  render() {
    const { currentDomainName, intl } = this.props;
    const columns = [
      {
        header: () => (
          <TableHeaderSort
            label={intl.formatMessage({ id: 'translate.generic.domain' })}
            config={{
              ascending: ascendingSortByProperty('name'),
              descending: descendingSortByProperty('name')
            }}
          />
        ),
        accessor: 'name'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <div>
        <PageHeader
          id={ToolId.parkedDomains}
          instructions={intl.formatMessage({ id: 'translate.page.domainAlias.instructions' })}
        />
        <Section>
          <Grid>
            <CreateBox>
              <CreateForm
                onSubmit={(data) =>
                  this.onCreateFormSubmit({
                    ...data,
                    alias_to: currentDomainName
                  })
                }
              />
            </CreateBox>

            <VCS resourceName={API_RESOURCE.DOMAIN_ALIAS.resourceName} hasMethod="GET">
              <SGTable
                title={intl.formatMessage({ id: 'translate.page.domainAlias.list.title' })}
                data={this.props.items.domainAlias}
                columns={columns}
                resources={[{ resourceName: API_RESOURCE.DOMAIN_ALIAS.resourceName, methods: ['GET'] }]}
                noDataMessage="translate.page.domainAlias.sg-table.no-data.message"
              />
            </VCS>
          </Grid>
        </Section>

        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  }

  renderDeleteConformationDialogComponent = () => {
    const { intl } = this.props;
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.name;

    const title = intl.formatMessage({ id: 'translate.page.domainAlias.delete.dialog.title' }, { domain: entityName });

    return (
      <DeleteDialog title={title} size="large" onSubmit={(data) => this.props.actions.deleteItem(deletePayload)}>
        <Grid gap="responsive">
          <Notice
            type="warning"
            background="light"
            shadow={false}
            title={intl.formatMessage({ id: 'translate.page.domainAlias.delete.dialog.notice.title' })}
          >
            <List>
              <ListItem icon="dot">
                {textToHTML(
                  intl.formatMessage(
                    { id: 'translate.page.domainAlias.delete.dialog.notice.text.email.warning' },
                    { domain: entityName }
                  )
                )}
              </ListItem>
              <ListItem icon="dot">
                {textToHTML(
                  intl.formatMessage(
                    { id: 'translate.page.domainAlias.delete.dialog.notice.text.services.warning' },
                    { domain: entityName }
                  )
                )}
              </ListItem>
            </List>
          </Notice>

          <Text align="left">{intl.formatMessage({ id: 'translate.conformation.dialog.message' })}</Text>
        </Grid>
      </DeleteDialog>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog } = this.props;
    const deletePayload: DeleteItemPayload = {
      itemId: id,
      name: entity.name,
      _metaFields: { ...API_RESOURCE.DOMAIN_ALIAS },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.page.domainAlias.delete.success.message'
          },
          error: {
            intlKey: 'translate.page.domainAlias.delete.error.message'
          }
        }
      }
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.DOMAIN_ALIAS.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'DELETE',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-delete',
            icon: 'trash',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
                openSGDialog(DIALOGS.GENERIC_DELETE)
              )
          }
        ]}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  currentDomainName: state.sites.currentDomainName,
  currentSiteId: getCurrentSiteId(state)
});

export default indexWithCRUD(mapStateToProps, {
  ...sgDialogActions,
  requestNemoData,
  createNotification
})(DomainAliasPage, API_RESOURCE.DOMAIN_ALIAS);
