import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers';

import customRequestTypes from '../../../../core/constants/custom-request-types';
import { SGDialogForm } from '../../../components/sg-dialog';
import { REDUX_FORM } from '../../../../core/constants/common';

import { getPrioritizedEntities } from '../../core/selectors/get-prioritized-entities';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../../core/constants/common';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { fetchDir, fileManagerPostRequest } from '../../core/actions/file-manager';
import * as Constants from '../../core/constants/actions';
import { getEntityType, getEntityReadableName, getEntityPath } from '../../core/utils';
import { FileManagerProps } from '../../types';

import ChmodPermissionsForm from './chmod-permissions-form';

type Props = {
  entities: FileManagerProps['entities'];
  fetchDir: typeof fetchDir;
  fileManagerPostRequest: typeof fileManagerPostRequest;
  closeSGDialog: typeof closeSGDialog;
  intl: Intl;
};

export const ChmodPermissions = ({ entities, fetchDir, fileManagerPostRequest, closeSGDialog, intl }: Props) => {
  const selectedEntitiesCount = entities.length;

  const title =
    selectedEntitiesCount === 1
      ? intl.formatMessage(
          { id: 'translate.file.manager.permissions.dialog.title.single.entity' },
          { entityName: getEntityReadableName(entities[0]) }
        )
      : intl.formatMessage(
          { id: 'translate.file.manager.permissions.dialog.title.multiple.entities' },
          { entitiesCount: selectedEntitiesCount }
        );

  const entries = entities.map((key) => getEntityPath(key));

  return (
    <SGDialogForm
      name={REDUX_FORM.FILE_MANAGER_PERMISSIONS}
      icon="key"
      title={title}
      resources={[
        {
          requestTypeName: customRequestTypes.FILE_MANAGER_POST
        },
        {
          requestTypeName: customRequestTypes.FILE_MANAGER_FETCH_DIR
        }
      ]}
    >
      <ChmodPermissionsForm
        fetchDir={fetchDir}
        initialValues={{
          _metaFields: {
            resourceNameMetaApi: 'dir-perms',
            endpoint: Constants.FILE_MANGER_API_CHANGE_PERMISSIONS
          }
        }}
        entities={entities}
        onSubmit={(data) => {
          const hasOnlyFoldersSelected = entities.every(
            (entity) => getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.DIRECTORY
          );
          const hasOnlyFilesSelected = entities.every(
            (entity) => getEntityType(entity) === FILE_MANAGER_API_RESPONSE_DIR.FILE
          );

          const params: any = {
            endpoint: Constants.FILE_MANGER_API_CHANGE_PERMISSIONS,
            urlParams: {
              entries,
              file_perms: data.file_perms,
              dir_perms: data.dir_perms,
              recursive: Number(Boolean(data.recursive))
            },
            entity: entities[0],
            clearFileManagerStore: {
              // should be clear, because selectedContentEntities is not updated with dir-fetch
              selectedContentEntities: []
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.file.manager.change.permissions.success.message',
                  intlValues: { total: entities.length }
                },
                error: {
                  intlKey: 'translate.file.manager.change.permissions.error.message',
                  intlValues: { total: entities.length }
                }
              }
            }
          };

          if (hasOnlyFilesSelected) {
            delete params.urlParams.dir_perms;
          }

          if (hasOnlyFoldersSelected && !data.recursive) {
            delete params.urlParams.file_perms;
          }

          fileManagerPostRequest(params, () => closeSGDialog(REDUX_FORM.FILE_MANAGER_PERMISSIONS));
        }}
      />
    </SGDialogForm>
  );
};

export default connect<any, any, any>(
  (state: RootState) => ({
    entities: getPrioritizedEntities(state.fileManager)
  }),
  {
    closeSGDialog,
    fileManagerPostRequest,
    fetchDir
  }
)(injectIntl<any, any>(ChmodPermissions));
