import * as React from 'react';
import { connect } from 'react-redux';
import { Colors, Dialog, SizeType, DialogStateType, AlignType, DialogDensityType } from '@siteground/styleguide';

import {
  closeSGDialog,
  subscribeSGDialogClosed,
  subscribeSGDialogOpened,
  unmountDialog
} from '../../../core/actions/sg-dialog';

import { RootState } from '../../reducers';
import { getBlockingRequests } from '../../../core/selectors/partial-loader';
import PartialLoader from '../partial-loader';

type Props = {
  id: string;
  // ui props
  size?: SizeType;
  title?: any;
  subTitle?: any;
  state?: DialogStateType;
  icon?: string;
  iconColor?: Colors;
  iconSize?: string;
  align?: AlignType;
  density?: DialogDensityType;
  disableClose?: boolean;
  footer?: any;
  children?: any;
  onCloseHandler?: () => void;
  // connected props
  initialOpenedState?: boolean;
  requested?: number;
  resources?: LoaderResource[];
  closeSGDialog?: (id) => void;
  subscribeSGDialogOpened?: (id: string, onOpened: Function) => void;
  subscribeSGDialogClosed?: (id: string, onClosed: Function) => void;
  unmountDialog?: (id: string) => void;
};

type State = {
  opened: boolean;
  startedClosing: boolean;
};

class SGDialog extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    resources: [],
    onCloseHandler: () => null
  };

  readonly state = {
    opened: this.props.initialOpenedState,
    startedClosing: false
  };

  render() {
    const {
      align,
      children,
      closeSGDialog,
      density,
      disableClose,
      footer,
      icon,
      iconColor,
      iconSize,
      id,
      onCloseHandler,
      resources,
      requested,
      size,
      state,
      title,
      subTitle
    } = this.props;

    const { opened, startedClosing } = this.state;
    const shouldDisableClose = requested > 0 && !startedClosing;

    if (opened || startedClosing) {
      return (
        <Dialog
          align={align}
          density={density}
          disableClose={disableClose || shouldDisableClose}
          footer={footer}
          icon={icon}
          iconColor={iconColor}
          iconSize={iconSize}
          size={size}
          state={state}
          title={title}
          subTitle={subTitle}
          triggerClose={startedClosing}
          data-e2e={`sg-dialog-${id}`}
          onCloseHandler={() => {
            closeSGDialog(id);
            onCloseHandler();
          }}
        >
          <PartialLoader resources={resources} closeDelay={500}>
            {children}
          </PartialLoader>
        </Dialog>
      );
    }

    return null;
  }

  componentDidMount() {
    const { id, subscribeSGDialogOpened, subscribeSGDialogClosed, unmountDialog } = this.props;

    subscribeSGDialogOpened(id, () =>
      this.setState({
        opened: true,
        startedClosing: false
      })
    );

    subscribeSGDialogClosed(id, () => {
      const previouslyOpened = this.state.opened;

      this.setState({
        opened: false,
        startedClosing: previouslyOpened
      });
    });
  }

  componentWillUnmount() {
    const { id, unmountDialog } = this.props;
    unmountDialog(id);
  }
}

const mapStateToProps = (store: RootState, { id, resources = [] }: Props): Partial<Props> => {
  const { requested } = getBlockingRequests(store, resources);

  return {
    initialOpenedState: Boolean(store.dialog[id]),
    requested: requested.length
  };
};

export default connect<{}, {}, Props>(mapStateToProps, {
  subscribeSGDialogOpened,
  subscribeSGDialogClosed,
  unmountDialog,
  closeSGDialog
})(SGDialog);
