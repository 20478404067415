import * as React from 'react';
import { connect } from 'react-redux';
import { ENDPOINT } from '../../core/constants/main-page-endpoint';
import { hasCRUDPermission, isACLActionEnabled } from '../../core/selectors/site-meta-api';
import { ToolWrapper } from '../contexts';
import InlineErrorPage from '../pages/inline-error-page';

type Props = {
  props: any;
  Component: any;
  toolId: string;
  pageId: string;
  hasPermission?: boolean;
  canOrder?: boolean;
};

const RouteWrapper = ({ Component, toolId, pageId, hasPermission, canOrder, props }: Props) => {
  const pageEndpoint = ENDPOINT[toolId];

  /*
    when React hits here, PageLoad has finished,
    site data and metaApi are ready.
    In DidMount of Component, shouldRenderSalesPage is always ready (no updates)
  */

  if (!hasPermission && !canOrder) {
    return <InlineErrorPage type="no-access" />;
  }

  return (
    <ToolWrapper.Provider value={{ toolId, pageId }}>
      <Component shouldRenderSalesPage={!hasPermission && canOrder && !CONFIG.IS_RESELLER} {...props} />
    </ToolWrapper.Provider>
  );
};

export default connect<any, any, Props>((state, { toolId }: Partial<Props>) => ({
  hasPermission: hasCRUDPermission(state.siteMetaApi, ENDPOINT[toolId]),
  canOrder: isACLActionEnabled(state, ENDPOINT[toolId], 'can_order')
}))(RouteWrapper);
