import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Accordion, Button, Checkbox, Container, Grid, Placeholder, Spacer, Toolbar } from '@siteground/styleguide';
import { requestNemoData } from '../../../../core/actions/request-data';
import { updateUserPreferences } from '../../../../core/actions/session';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import { getNavigationInfo, ToolId } from '../../../../core/constants/route-info';
import { ROUTES } from '../../../../core/constants/routes';
import { getUserDashboardTiles } from '../../../../core/selectors/preferences';
import PartialLoader from '../../../components/partial-loader';
import SecondLevelTitle from '../../../components/titles/second-level-title';
import * as menuItems from '../../../core/constants/menu-items';
import { RootState } from '../../../reducers';
import DashboardTile from '../dashboard-tile';

import './tile-section.scss';

type Props = {
  intl: Intl;
  tiles: ToolId[];
  router: any;
  locationSearch: string;
  requestNemoData: typeof requestNemoData;
  updateUserPreferences: typeof updateUserPreferences;
};

type State = {
  isInEditView: boolean;
  pinnedItems: string[];
  itemsBeforeEdit: string[];
};

class TileSection extends React.Component<Props, State> {
  readonly state = {
    isInEditView: false,
    itemsBeforeEdit: [],
    pinnedItems: this.props.tiles
  };

  getAllMenuItems() {
    return menuItems.groups.map((group) => group.items).reduce((a: any, b: any) => a.concat(b), []);
  }

  getPinnedTiles() {
    const { pinnedItems } = this.state;

    return pinnedItems;
  }

  openEditView = () => {
    this.setState({ isInEditView: true, itemsBeforeEdit: this.state.pinnedItems });
  };

  closeEditView = () => {
    this.setState({ isInEditView: false });
  };

  pinTileToFavorites = (toolId) => {
    this.setState({
      pinnedItems: this.state.pinnedItems.concat(toolId)
    });
  };

  unPinTileToFavorites = (toolId) => {
    this.setState({
      pinnedItems: this.getPinnedTiles().filter((state) => state !== toolId)
    });
  };

  toggleAllItemsToFavorite = (shouldSelectAll) => {
    const menuItems = this.getAllMenuItems();

    this.setState({
      pinnedItems: shouldSelectAll ? menuItems.map((tool) => tool.toolId) : []
    });
  };

  savePinnedTilesToFavorites = () => {
    const { pinnedItems } = this.state;

    this.props.requestNemoData(
      {
        endpoint: '/user/set/preferences',
        method: 'POST',
        body: {
          sitetools: {
            dashboard: {
              tiles: pinnedItems
            }
          }
        },
        requestTypeName: customRequestTypes.SET_DASHBOARD_PIN_TILES
      },
      (data) => {
        this.props.updateUserPreferences(data);
        this.closeEditView();
      }
    );
  };

  dismissPinnedTileToFavorites = () => {
    this.setState({ pinnedItems: this.state.itemsBeforeEdit, isInEditView: false });
  };

  navigateToPage = (toolId) => {
    const { router, locationSearch } = this.props;

    router.push(`${ROUTES[toolId]}${locationSearch}`);
  };

  isTilePinned = (toolId) => {
    return this.getPinnedTiles().includes(toolId);
  };

  renderHeaderToolbar = () => {
    const { intl } = this.props;
    const { isInEditView } = this.state;

    if (!isInEditView) {
      return null;
    }

    const areAllTilesPinned = this.state.pinnedItems.length === this.getAllMenuItems().length;

    return (
      <Container className="sg-tile-container-header" padding="responsive" elevation="none">
        <Checkbox checked={areAllTilesPinned} onChange={(event) => this.toggleAllItemsToFavorite(event.target.checked)}>
          {intl.formatMessage({
            id: areAllTilesPinned
              ? 'translate.page.dashboard.tiles.deselect.all'
              : 'translate.page.dashboard.tiles.select.all'
          })}
        </Checkbox>
      </Container>
    );
  };

  renderNoPinnedTilesPlaceholder() {
    const { intl } = this.props;

    return (
      <Placeholder
        icon="presentational-no-data-dotted"
        title={intl.formatMessage({ id: 'translate.page.dashboard.tiles.no.pinned.tiles.message' })}
      >
        <Button color="primary" onClick={() => this.openEditView()}>
          {intl.formatMessage({ id: 'translate.page.dashboard.tiles.no.pinned.tiles.button.label' })}
        </Button>
      </Placeholder>
    );
  }

  renderTile = (item) => {
    const { isInEditView } = this.state;

    if (!item) {
      return null;
    }

    const { toolId } = item;
    const isTilePinned = this.isTilePinned(toolId);

    return (
      <DashboardTile
        key={toolId}
        item={item}
        isInEditView={isInEditView}
        isTilePinned={isTilePinned}
        handlePinClick={() => (isTilePinned ? this.unPinTileToFavorites(toolId) : this.pinTileToFavorites(toolId))}
        onClick={() => !isInEditView && this.navigateToPage(toolId)}
      />
    );
  };

  renderPinnedTiles = () => {
    const { isInEditView } = this.state;
    const shouldRenderEmptyPlaceholder =
      !isInEditView && this.getPinnedTiles().filter((t: any) => Object.values(ToolId).includes(t)).length === 0;

    if (shouldRenderEmptyPlaceholder) {
      return this.renderNoPinnedTilesPlaceholder();
    }

    const items = this.getAllMenuItems();
    const tiles = this.state.pinnedItems.map((pin) => {
      const itemToRender = items.find((item) => item.toolId === pin);
      return this.renderTile(itemToRender);
    });

    return (
      <Grid sm="2" m="4" lg="6" gap="responsive">
        {tiles}
      </Grid>
    );
  };

  renderNonPinnedTiles = () => {
    const { intl } = this.props;

    return (
      <div>
        <SecondLevelTitle>
          {intl.formatMessage({ id: 'translate.page.dashboard.tiles.non-selected-tiles-title' })}
        </SecondLevelTitle>

        {menuItems.groups.map((group: any) => {
          if (!group.items || group.items.length === 0) {
            return null;
          }
          const { title } = getNavigationInfo(group.toolId);

          return (
            <Accordion key={title} title={intl.formatMessage({ id: title })} padding="none" initialState="expanded">
              <Grid sm="2" m="4" lg="6" gap="responsive">
                {group.items.filter((item) => !this.isTilePinned(item.toolId)).map(this.renderTile)}
              </Grid>
            </Accordion>
          );
        })}
      </div>
    );
  };

  renderFooterButtons() {
    const { intl } = this.props;
    const { isInEditView } = this.state;

    if (isInEditView) {
      return (
        <div>
          <Button onClick={() => this.dismissPinnedTileToFavorites()}>
            {intl.formatMessage({ id: 'translate.generic.cancel' })}
          </Button>

          <Button color="primary" onClick={() => this.savePinnedTilesToFavorites()}>
            {intl.formatMessage({ id: 'translate.generic.save' })}
          </Button>
        </div>
      );
    }

    return (
      <Button color="secondary" type="link" onClick={() => this.openEditView()}>
        {intl.formatMessage({ id: 'translate.page.dashboard.edit.tiles.button' })}
      </Button>
    );
  }

  renderTitle() {
    const { intl } = this.props;
    const { isInEditView } = this.state;

    return (
      <SecondLevelTitle>
        {intl.formatMessage({
          id: isInEditView ? 'translate.page.dashboard.tiles.title.edit' : 'translate.page.dashboard.tiles.title'
        })}
      </SecondLevelTitle>
    );
  }

  renderAllTiles() {
    return (
      <Container padding="responsive" elevation="none">
        <Grid style={{ position: 'relative' }} gap="responsive">
          <PartialLoader
            resources={[
              {
                requestTypeName: customRequestTypes.SET_DASHBOARD_PIN_TILES
              }
            ]}
          >
            {this.renderPinnedTiles()}

            {this.state.isInEditView && this.renderNonPinnedTiles()}
          </PartialLoader>
        </Grid>
      </Container>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <div>
        {this.renderTitle()}

        <Container padding="none">
          {this.renderHeaderToolbar()}

          {this.renderAllTiles()}

          <Container padding="none" elevation="none">
            <Toolbar style={{ marginTop: '0' }}>
              <Spacer />

              {this.renderFooterButtons()}
            </Toolbar>
          </Container>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (store: RootState) => ({
  tiles: getUserDashboardTiles(store),
  locationSearch: store.routing.locationBeforeTransitions.search
});

const mapDispatchToProps = {
  requestNemoData,
  updateUserPreferences
};

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl<any, any>(TileSection));
