import { RootState } from '../../web/reducers';
import { sgSiteScannerDataTypes } from '../constants/sg-sitescanner';
import * as sgSiteScannerTypes from '../definitions/sg-sitescanner';

export const isReportStatusClean = (lastReport: sgSiteScannerTypes.NormalizedReport) => {
  if (!lastReport) {
    return true;
  }

  const { blacklist, malware, filescan } = lastReport;

  return Boolean(blacklist.length === 0 && malware.length === 0 && filescan.length === 0);
};

export const isLastStatusClean = (state: RootState) => {
  const { sgSiteScanner } = state;
  const reports: sgSiteScannerTypes.NormalizedReports = sgSiteScanner.data[sgSiteScannerDataTypes.reports];

  if (!reports) {
    return true;
  }

  return isReportStatusClean(Object.values(reports)[0]);
};

export const getMdetScansPerDay = (store: RootState) => store?.siteMetaApi?.features['max_site:mdet_scans_per_day'];
