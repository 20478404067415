import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dropdown } from '@siteground/styleguide';
import areEqual from '@siteground/styleguide/lib/utils/are-equal';

import { RootState } from '../../reducers';
import { findMainDomain } from '../../../core/selectors';
import SecondLevelTitle from '../titles/second-level-title';

type Props = {
  domain?: any;
  mainDomain?: any;
  title?: string;
  domainResourceName?: string;
  options: any;
  selectedValue: any;
  onChange: Function;
  [propageteProps: string]: any;
};

type DispatchProps = {
  intl: Intl;
};

class DomainSelect extends React.Component<Props & DispatchProps, any> {
  static defaultProps: Partial<Props> = {
    onChange: () => null
  };

  componentDidMount() {
    if (!this.props.mainDomain) {
      return;
    }

    this.props.onChange(this.props.mainDomain[this.props.optionValue]);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.mainDomain && !areEqual(prevProps.mainDomain, this.props.mainDomain)) {
      this.props.onChange(this.props.mainDomain[this.props.optionValue]);
    }
  }

  render() {
    const { intl, mainDomain, title = 'translate.generic.select.domain' } = this.props;

    if (!mainDomain) {
      return null;
    }

    const toPropagate = {
      ...this.props,
      selectedValue: this.props.selectedValue !== null ? this.props.selectedValue : this.props.mainDomain.id,
      onChange: (v) => {
        this.props.onChange(v);
      }
    };

    const { options } = this.props;

    return (
      <div className="domain-select">
        <SecondLevelTitle>{intl.formatMessage({ id: title })}</SecondLevelTitle>

        <Dropdown
          data-e2e="domain-select"
          options={options}
          size="x-large"
          searchable={options.length > 1}
          enableShadow
          {...toPropagate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, { domainResourceName }: Props) => ({
  domain: state.pageItems.domain,
  mainDomain: findMainDomain(state, domainResourceName)
});

export default connect<any, DispatchProps, Props>(mapStateToProps, undefined)(injectIntl(DomainSelect));
