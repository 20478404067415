import {
  PHPVariable,
  PhpVersionManageType,
  SettingApiType,
  PhpManagerState,
  SiteTypes,
  PhpManagerDomainTypes,
  PhpManagerStateInput,
  PhpVersion
} from './types';

import { DOMAIN_TYPES } from '../../../core/constants/domains';
import { sortAscendingByid } from '../../../core/common/sort-by-id';

export const getPhpManagerState = ({ phpMod, domainType }: PhpManagerStateInput): PhpManagerState => {
  switch (true) {
    case Boolean(!domainType || !phpMod):
      // incomplete data
      return null;
    case domainType === PhpManagerDomainTypes.Staging:
      // domain is staging
      return PhpManagerState.StandardPhpStagingSelected;
    case phpMod === 'mod_php':
      // site has mod_php service
      return PhpManagerState.ModPhpMainSelected;
    default:
      // default case with standard php and main/sub_domain
      return domainType === PhpManagerDomainTypes.Main
        ? PhpManagerState.StandardPhpMainSelected
        : PhpManagerState.StandardPhpSubDomainSelected;
  }
};

/*
  API types and values
  ______________________________

  API setting type bool
    - '0', '1' values (No, Yes);
  ______________________________

  API setting type number ->
    - number fields;
  ______________________________

  API setting type string ->
    - string fields;
    - 'On', 'Off' values;
*/

const onOffValuesTypes = ['string']; // number in some cases (BUG in the api)
export const API_BOOLEAN_TYPES = ['bool', 'string'];

export const formToApiValueConversion = (value: string, apiType: SettingApiType) => {
  switch (value) {
    case 'true':
      return onOffValuesTypes.includes(apiType) ? 'On' : '1';
    case 'false':
      return onOffValuesTypes.includes(apiType) ? 'Off' : '0';
    default:
      return value;
  }
};

export const apiToFormValueConversion = (value: string | number, apiType: SettingApiType) => {
  if (API_BOOLEAN_TYPES.includes(apiType)) {
    switch (value) {
      case 'On':
      case '1':
      case 1:
        return 'true';
      case 'Off':
      case '0':
      case 0:
        return 'false';
      default:
        return value;
    }
  }

  return value;
};

export const filterPHPVariables = (data: PHPVariable[], searchString: string): PHPVariable[] =>
  Object.values(data).filter(({ id }) => id.toLowerCase().includes(searchString.toLowerCase()));

export const isPhpVersionManageTypeAuto = (domainSelectedVersion) => {
  if (!domainSelectedVersion) {
    return true;
  }

  if (domainSelectedVersion.version_id === PhpVersionManageType.Auto) {
    return true;
  }

  if (domainSelectedVersion.version_id === '' || domainSelectedVersion.version_id === 'none') {
    return true;
  }

  return false;
};

export const isDomainStaging = ({ settings }) => Boolean(settings && settings.staging);

export const getStagingDomains = ({ domains }) => domains.filter(isDomainStaging);

// main domain and sub domains, which are not staging sites
export const getLiveSiteDomains = ({ domains }) =>
  domains.filter(
    ({ main, domain_type, settings }) =>
      Boolean(main === 1 || domain_type === DOMAIN_TYPES.sub_domain) && !isDomainStaging({ settings })
  );

export const getDomainType = (domain) => {
  if (!domain) {
    return null;
  }

  const { main, domain_type, settings } = domain;

  switch (true) {
    case Boolean(main):
      return PhpManagerDomainTypes.Main;
    case Boolean(settings && settings.staging):
      return PhpManagerDomainTypes.Staging;
    case Boolean(domain_type === DOMAIN_TYPES.sub_domain):
      return PhpManagerDomainTypes.SubDomain;
    default:
      return null;
  }
};

export const findDomainById = ({ domainId, domain = [] }) => domain.find(({ id }) => id === domainId);

export const findDomainByName = ({ domainName, domains = [] }) =>
  domains.find(({ name, domain_type }) => {
    if (domain_type === 'main_domain') {
      return false;
    }
    const domain = name.split('.')[0];
    return domain === domainName || `www.${domain}` === domainName;
  });

export const findSubDomainByName = ({ domainName, domains = [], mainDomain }) =>
  domains.find(({ name, domain_type }) => {
    if (domain_type === 'main_domain') {
      return false;
    }

    const domain = name.split(`.${mainDomain}`)[0];
    return domain === domainName;
  });

export const findSubDomainByNameWithWWW = ({ domainName, domains = [], mainDomain }) =>
  domains.find(({ name, domain_type }) => {
    if (domain_type === 'main_domain') {
      return false;
    }

    const domain = name.split(`.${mainDomain}`)[0];
    return `www.${domain}` === domainName;
  });

export const createManagedPhpNotification = (domainName: string): SpanelNotification => ({
  type: 'generic',
  success: {
    intlKey: 'translate.page.php-manager.php-enable-auto.success',
    intlValues: {
      site: domainName
    }
  },
  error: {
    intlKey: 'translate.page.php-manager.php-enable-auto.fail',
    intlValues: {
      site: domainName
    }
  }
});

export const getNonStagingDomains = ({ domains }) =>
  domains.filter(({ settings }) => Boolean(settings && !settings.staging));
