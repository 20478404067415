import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { siteApi } from '../../../../core/api/site';
import handleAvalonApiRequest from '../../../../core/sagas/handle-avalon-api-request';
import { getExistingFilesResponse, getExistingFilesResponseSucceeded } from '../actions/file-manager';
import {
  FILE_MANAGER_GET_EXISTING_FILES_RESPONSE,
  FILE_MANAGER_GET_EXISTING_FILES_RESPONSE_SUCCEEDED
} from '../constants/actions';

function* requestGetExistingFiles(action) {
  const { entries } = action.payload;
  const state = yield select();

  const getExistingFilesResponse = yield call(
    siteApi({
      endpoint: '/dir',
      method: 'POST',
      body: {
        stat_only: 1,
        entries
      },
      urlParams: {},
      state
    })
  );

  yield put(getExistingFilesResponseSucceeded(getExistingFilesResponse));

  return getExistingFilesResponse;
}

export function* loadExistingFilesData(entries) {
  yield put(getExistingFilesResponse({ entries }));

  const { payload } = yield take(FILE_MANAGER_GET_EXISTING_FILES_RESPONSE_SUCCEEDED);

  return payload.response;
}

function* browserUpload(): any {
  yield takeLatest(FILE_MANAGER_GET_EXISTING_FILES_RESPONSE, handleAvalonApiRequest(requestGetExistingFiles));
}

export default browserUpload;
