import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as i18nActions from '../../../core/actions/i18n';
import {
  getInitialLang,
  getUserLanguageFilename,
  LANGUAGES,
  languageToLocaleMapping,
  preselectLanguage
} from './utils';

export const LanguageContext = React.createContext({
  selectedLanguage: LANGUAGES.ENGLISH,
  setLanguage: (lang) => {
    return null;
  }
});

const LanguageSwitcher: React.FunctionComponent<any> = (props) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState(getInitialLang(props));
  const setLanguage = (lang) => {
    if (selectedLanguage !== lang) {
      props.i18nActions.loadTranslations({ fileName: getUserLanguageFilename(lang) });
      setSelectedLanguage(lang);

      window.history.replaceState(null, null, `?lang=${lang}`);
    }
  };

  React.useEffect(() => {
    setSelectedLanguage(languageToLocaleMapping[preselectLanguage()]);
  });

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setLanguage }}>{props.children}</LanguageContext.Provider>
  );
};

export default connect<{}, {}, any>(undefined, (dispatch) => ({
  i18nActions: bindActionCreators(i18nActions as any, dispatch)
}))(LanguageSwitcher);
