export const getPrioritizedEntities = (state) => {
  const { contextContentEntities, contextNavigationEntity, selectedContentEntities, selectedNavigationEntity } = state;

  if (contextContentEntities.length) {
    return contextContentEntities;
  }

  if (contextNavigationEntity) {
    return [contextNavigationEntity];
  }

  if (selectedContentEntities.length) {
    return selectedContentEntities;
  }

  if (selectedNavigationEntity) {
    return [selectedNavigationEntity];
  }

  return [];
};
