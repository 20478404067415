import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Button, Column, Notice, Text, textToHTML } from '@siteground/styleguide';
import { API_RESOURCE } from '../../../../core/constants/api';
import { REDUX_FORM } from '../../../../core/constants/common';
import MobileBreakElement from '../../../components/break-element';
import FormDropdown from '../../../components/form-dropdown';
import FormFieldWrapper from '../../../components/form-field-wrapper/form-field-wrapper';
import { SGForm } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-spanel-forms';

import { CREATE_BOX_DROPDOWN_VALUE_KEY, WP_SITE_STATUS } from '../constants';

const formName = REDUX_FORM.CREATE_WP_AUTOUPDATE;

class CreateZone extends React.Component<any, any> {
  renderWPVersionNotice() {
    const { intl, selectedSite, wordpressInfo } = this.props;

    if (!selectedSite || !wordpressInfo) {
      return null;
    }

    if (typeof wordpressInfo.version.latest !== 'string') {
      return null;
    }

    if (wordpressInfo.version.latest <= selectedSite.version) {
      return null;
    }

    return (
      <Column smSpan="12">
        <Notice background="light" type="instruction" border={false} shadow={false}>
          {textToHTML(
            intl.formatMessage(
              { id: 'translate.page.wp.autoupdate.version.notice' },
              { version: wordpressInfo.version.latest }
            )
          )}
        </Notice>
      </Column>
    );
  }

  renderWPStatus() {
    const { intl, selectedSiteLabel } = this.props;

    if (!selectedSiteLabel) {
      return null;
    }

    return (
      <FormFieldWrapper label={intl.formatMessage({ id: 'translate.generic.status' })}>
        {selectedSiteLabel}
      </FormFieldWrapper>
    );
  }

  renderWPVersion() {
    const { intl, selectedSite } = this.props;
    if (!selectedSite) {
      return null;
    }
    return (
      <FormFieldWrapper label={intl.formatMessage({ id: 'translate.generic.version' })}>
        <Text>
          {intl.formatMessage(
            { id: 'translate.page.wp.autoupdate.wordpress.version' },
            { version: selectedSite.version }
          )}
        </Text>
      </FormFieldWrapper>
    );
  }

  renderUpdateNowButton() {
    const { updateItem, intl, selectedSite, wordpressInfo } = this.props;

    return (
      <Button
        key="update-now"
        action="button"
        color="primary"
        data-e2e="update-now"
        onClick={(value) =>
          updateItem({
            id: selectedSite.id,
            update_to: wordpressInfo.version.latest,
            _metaFields: {
              ...API_RESOURCE.APP_UPDATE_CORE
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.wp.autoupdate.update.now.success.message',
                  intlValues: { domain: selectedSite.app_url }
                },
                error: {
                  intlKey: 'translate.page.wp.autoupdate.update.now.error.message',
                  intlValues: { domain: selectedSite.app_url }
                }
              }
            }
          })
        }
      >
        {intl.formatMessage({ id: 'translate.page.wp.autoupdate.create.button.update.now' })}
      </Button>
    );
  }

  renderEnableAutoupdateButton() {
    const { updateItem, intl, selectedSite } = this.props;

    return (
      <Button
        key="enable-autoupdate"
        action="button"
        color="primary"
        data-e2e="enable-autoupdate"
        onClick={(value) =>
          updateItem({
            id: selectedSite.id,
            scheduled_for_update: 1,
            _metaFields: {
              ...API_RESOURCE.APP
            },
            _meta: {
              notification: {
                type: 'generic',
                success: {
                  intlKey: 'translate.page.wp.autoupdate.enable.autoupdate.success.message',
                  intlValues: { domain: selectedSite.app_url }
                },
                error: {
                  intlKey: 'translate.page.wp.autoupdate.enable.autoupdate.error.message',
                  intlValues: { domain: selectedSite.app_url }
                }
              }
            }
          })
        }
      >
        {intl.formatMessage({ id: 'translate.page.wp.autoupdate.create.button.enable.autoupdate' })}
      </Button>
    );
  }

  renderSkipUpdateButton() {
    const { updateItem, intl, selectedSite, wordpressInfo } = this.props;

    return (
      <Button
        key="skip-update"
        action="button"
        color="primary"
        type="outlined"
        data-e2e="skip-update"
        onClick={() =>
          updateItem(
            {
              id: 'skip_version',
              app_id: selectedSite.id,
              value: wordpressInfo.version.latest,
              _metaFields: {
                ...API_RESOURCE.APP_SETTINGS
              },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.page.wp.autoupdate.skip.update.success.message',
                    intlValues: { domain: selectedSite.app_url }
                  },
                  error: {
                    intlKey: 'translate.page.wp.autoupdate.skip.update.error.message',
                    intlValues: { domain: selectedSite.app_url }
                  }
                }
              }
            },
            this.props.onSkipVersion
          )
        }
      >
        {intl.formatMessage({ id: 'translate.page.wp.autoupdate.create.button.skip.update' })}
      </Button>
    );
  }

  renderActionButtons() {
    const { getSiteStatus, selectedSite } = this.props;
    const status = getSiteStatus(selectedSite);

    switch (status) {
      case WP_SITE_STATUS.UP_TO_DATE_AUTOUPDATE_DISABLED:
        return <React.Fragment>{this.renderEnableAutoupdateButton()}</React.Fragment>;
      case WP_SITE_STATUS.UP_TO_DATE:
        return null;
      case WP_SITE_STATUS.UPDATE_SCHEDULED:
        return (
          <React.Fragment>
            {this.renderUpdateNowButton()}
            <MobileBreakElement />
            {this.renderSkipUpdateButton()}
          </React.Fragment>
        );
      case WP_SITE_STATUS.AUTOUPDATE_DISABLED:
        return (
          <React.Fragment>
            {this.renderEnableAutoupdateButton()}
            <MobileBreakElement />
            {this.renderUpdateNowButton()}
          </React.Fragment>
        );
      case WP_SITE_STATUS.SKIP_UPDATE:
        return this.renderUpdateNowButton();
      default:
        return null;
    }
  }

  render() {
    const { intl, actions, wordpressApps, onWPAppChanged } = this.props;
    const wpStatus = this.renderWPStatus();
    const wpVersion = this.renderWPVersion();
    const actionButtons = this.renderActionButtons();

    return (
      <SGForm>
        {this.renderWPVersionNotice()}

        <Column smSpan="6">
          <Field
            name={CREATE_BOX_DROPDOWN_VALUE_KEY}
            label={intl.formatMessage({ id: 'translate.generic.site' })}
            options={wordpressApps}
            optionValue={CREATE_BOX_DROPDOWN_VALUE_KEY}
            optionLabel="app_url"
            onChange={(event, value) => onWPAppChanged(value)}
            component={FormDropdown}
          />
        </Column>

        {wpStatus && <Column smSpan="3">{wpStatus}</Column>}

        {wpVersion && <Column smSpan="3">{wpVersion}</Column>}

        {actionButtons && <Column smSpan="12">{actionButtons}</Column>}
      </SGForm>
    );
  }
}

const createFormRedux = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.APP,
      formName,
      disableAutoReset: true
    }
  }
})(CreateZone);

export default withSpanelForm(injectIntl(createFormRedux));
