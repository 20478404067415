import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useIntl } from 'react-intl';

import { Grid } from '@siteground/styleguide';

import { REDUX_FORM } from '../../../../core/constants/common';
import FormPassword from '../../../components/form-password';
import { withSpanelForm } from '../../../components/sg-spanel-forms';
import FormInput from '../../../components/form-input';

type Props = {
  validationUtils: ValidationUtils;
};

const ChangeProfilePassword: React.FC<Props> = ({ validationUtils }) => {
  const intl = useIntl();

  return (
    <Grid padding="responsive" gap="responsive">
      <Field
        name="old_password"
        type="password"
        label={intl.formatMessage({ id: 'translate.generic.current.password' })}
        validate={[validationUtils.required]}
        component={FormInput}
      />

      <Field
        name="password"
        label={intl.formatMessage({ id: 'translate.generic.new.password' })}
        validate={[validationUtils.required, validationUtils.password]}
        component={FormPassword}
      />
    </Grid>
  );
};

export default withSpanelForm(
  reduxForm({
    form: REDUX_FORM.CHANGE_PROFILE_PASSWORD_DIALOG
  })(ChangeProfilePassword)
);
