import * as React from 'react';
import { Section, ErrorPageType } from '@siteground/styleguide';
import { ErrorPage } from '@siteground/styleguide/lib/composite';

type Props = {
  type: ErrorPageType;
  [other: string]: any;
};

const InlineErrorPage = ({ type, zIndex, ...otherProps }: Props) => {
  return (
    <Section
      style={{
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        zIndex
      }}
    >
      <ErrorPage type={type} {...otherProps} />
    </Section>
  );
};

export default InlineErrorPage;
