import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Spacer, Table, TableProps } from '@siteground/styleguide';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import { getTranslations } from '../../../components/sg-table/translation';

type StateProps = {};

type DispatchProps = {
  closeSGDialog: typeof closeSGDialog;
};

type OwnProps = {
  id: string;
  title: string;
  subTitle: string;
  tableProps: Partial<TableProps>;
  handleOverwrite: Function;
  handleKeepExistingOverwrite: Function;
};

interface Props extends StateProps, DispatchProps, OwnProps {}

const OverwriteFilesConfirmationDialog = (props: Props) => {
  const { id, title, subTitle, tableProps, handleOverwrite, handleKeepExistingOverwrite, closeSGDialog } = props;
  const intl = useIntl();

  return (
    <SGDialog
      id={id}
      icon="warning"
      density="small"
      size="large"
      state="inactive"
      title={title}
      subTitle={subTitle}
      footer={
        <React.Fragment>
          <SGDialogCancel id={id} />

          <Spacer />

          <Button
            color="primary"
            type="outlined"
            data-e2e="footer-button-overwrite"
            onClick={() => {
              handleOverwrite();
              closeSGDialog(id);
            }}
          >
            {intl.formatMessage({ id: 'translate.file.manager.upload.confirmation.overwrite' })}
          </Button>

          <Button
            color="primary"
            data-e2e="footer-button-confirm"
            onClick={() => {
              handleKeepExistingOverwrite();
              closeSGDialog(id);
            }}
          >
            {intl.formatMessage({ id: 'translate.file.manager.upload.confirmation.keep.existing' })}
          </Button>
        </React.Fragment>
      }
    >
      <Table density="large" translation={getTranslations(intl)} {...tableProps} />
    </SGDialog>
  );
};

export default connect<StateProps, DispatchProps, OwnProps>(undefined, {
  closeSGDialog
})(OverwriteFilesConfirmationDialog);
