import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { IconButton, IconButtonProps } from '@siteground/styleguide/';
import { openSGDialog } from '../../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../../core/constants/common';
import { getFileMangerToolbarPermission, RootState } from '../../reducers';
import { triggerFileSave } from '../core/actions/code-editor';
import {
  browserFileUpload,
  copy,
  downloadSelectedFiles,
  extractDir,
  fetchDirWithFolderSizes,
  requestPaste
} from '../core/actions/file-manager';

import { closeSearchView, openSearchView } from '../core/actions/search-view';
import { CreateDirDialogPayload, getCreateDirDialogPayload } from '../core/selectors/get-create-dir-dialog-payload';
import { CreateFileDialogPayload, getCreateFileDialogPayload } from '../core/selectors/get-create-file-dialog-payload';
import { DeleteDialogPayload, getDeleteDialogPayload } from '../core/selectors/get-delete-dialog-payload';
import { getMoveDialogPayload, MoveDialogPayload } from '../core/selectors/get-move-dialog-payload';
import { getRenameDialogPayload, RenameDialogPayload } from '../core/selectors/get-rename-dialog-payload';

import './action-toolbar.scss';
import DynamicToolbar from './dynamic-toolbar';

const iconsDefaultProps: Partial<IconButtonProps> = {
  size: 'large',
  shape: 'circle'
};

type Props = {
  createDirDialogPayload: CreateDirDialogPayload;
  createFileDialogPayload: CreateFileDialogPayload;
  moveDialogPayload: MoveDialogPayload;
  deleteDialogPayload: DeleteDialogPayload;
  copy: typeof copy;
  requestPaste: typeof requestPaste;
  downloadSelectedFiles: typeof downloadSelectedFiles;
  triggerFileSave: typeof triggerFileSave;
  fetchDirWithFolderSizes: typeof fetchDirWithFolderSizes;
  extractDir: typeof extractDir;
  browserFileUpload: typeof browserFileUpload;
  renameDialogPayload: RenameDialogPayload;
  openSGDialog: typeof openSGDialog;

  isSearchVisible: boolean;
  openSearchView: typeof openSearchView;
  closeSearchView: typeof closeSearchView;

  [otherProps: string]: any;
};

class FileManagerActionButtons extends React.Component<Props> {
  renderCreateFile = () => {
    const { createFileDialogPayload, intl, fileMangerActionPermission, openSGDialog } = this.props;
    const { canCreateFile } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.new.file' });

    return (
      <IconButton
        icon="file-new"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canCreateFile}
        tooltip={tooltipTranslate}
        data-e2e="header-new-file"
        onClick={() => openSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_FILE, createFileDialogPayload)}
        disabled={!canCreateFile}
        {...iconsDefaultProps}
      />
    );
  };

  renderCreateDir = () => {
    const { createDirDialogPayload, intl, fileMangerActionPermission, openSGDialog } = this.props;
    const { canCreateDir } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.new.folder' });

    return (
      <IconButton
        icon="folder-new"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canCreateDir}
        tooltip={tooltipTranslate}
        data-e2e="header-new-folder"
        onClick={() => openSGDialog(REDUX_FORM.FILE_MANAGER_CREATE_DIR, createDirDialogPayload)}
        disabled={!canCreateDir}
        {...iconsDefaultProps}
      />
    );
  };

  renderFileUpload() {
    const { intl, fileMangerActionPermission, browserFileUpload } = this.props;
    const { canCreateFile } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.file.upload' });

    return (
      <IconButton
        icon="file-upload"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canCreateFile}
        tooltip={tooltipTranslate}
        data-e2e="header-upload-file"
        onClick={() => browserFileUpload({ multiple: false })}
        disabled={!canCreateFile}
        {...iconsDefaultProps}
      />
    );
  }

  renderFolderUpload() {
    const { intl, fileMangerActionPermission, browserFileUpload } = this.props;
    const { canCreateDir } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.folder.upload' });

    return (
      <IconButton
        icon="folder-upload"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canCreateDir}
        tooltip={tooltipTranslate}
        data-e2e="header-upload-folder"
        onClick={() => browserFileUpload({ multiple: true })}
        disabled={!canCreateDir}
        {...iconsDefaultProps}
      />
    );
  }

  renderEdit() {
    const { intl, fileMangerActionPermission } = this.props;
    const { canEdit } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.edit' });

    return (
      <IconButton
        icon="edit"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canEdit}
        tooltip={tooltipTranslate}
        data-e2e="header-edit"
        onClick={() => this.props.codeEditor.openFile()}
        disabled={!canEdit}
        {...iconsDefaultProps}
      />
    );
  }

  renderRename = () => {
    const { intl, fileMangerActionPermission, openSGDialog, renameDialogPayload } = this.props;
    const { canRename, isProtected } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.rename' });

    return (
      <IconButton
        icon="rename"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={isProtected || !canRename}
        tooltip={tooltipTranslate}
        data-e2e="header-rename"
        onClick={() => openSGDialog(REDUX_FORM.FILE_MANAGER_RENAME_ENTITY, renameDialogPayload)}
        disabled={isProtected || !canRename}
        {...iconsDefaultProps}
      />
    );
  };

  renderCopy = () => {
    const { intl, copy, fileMangerActionPermission } = this.props;
    const { canCopy } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.file.manager.copy' });

    return (
      <IconButton
        icon="copy"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canCopy}
        tooltip={tooltipTranslate}
        data-e2e="header-copy"
        onClick={() => copy()}
        disabled={!canCopy}
        {...iconsDefaultProps}
      />
    );
  };

  renderPaste = () => {
    const { intl, requestPaste, fileMangerActionPermission } = this.props;
    const { canPaste } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.paste' });

    return (
      <IconButton
        icon="paste"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canPaste}
        tooltip={tooltipTranslate}
        data-e2e="header-paste"
        onClick={() => requestPaste()}
        disabled={!canPaste}
        {...iconsDefaultProps}
      />
    );
  };

  renderMove = () => {
    const { moveDialogPayload, intl, fileMangerActionPermission, openSGDialog } = this.props;
    const { canMove, isProtected } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.move' });

    return (
      <IconButton
        icon="move"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={isProtected || !canMove}
        tooltip={tooltipTranslate}
        data-e2e="header-move"
        onClick={() => openSGDialog(REDUX_FORM.FILE_MANAGER_MOVE_ENTITY, moveDialogPayload)}
        disabled={isProtected || !canMove}
        {...iconsDefaultProps}
      />
    );
  };

  renderDownload = () => {
    const { intl, fileMangerActionPermission, downloadSelectedFiles } = this.props;
    const { canDownload } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.download' });

    return (
      <IconButton
        icon="download"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canDownload}
        tooltip={tooltipTranslate}
        data-e2e="header-download"
        onClick={() => downloadSelectedFiles()}
        disabled={!canDownload}
        {...iconsDefaultProps}
      />
    );
  };

  renderDelete = () => {
    const { intl, fileMangerActionPermission, openSGDialog, deleteDialogPayload } = this.props;
    const { canDelete, isProtected } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.delete' });

    return (
      <IconButton
        icon="trash"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={isProtected || !canDelete}
        tooltip={tooltipTranslate}
        data-e2e="header-delete"
        onClick={() => openSGDialog(DIALOGS.FILE_MANAGER_DELETE, deleteDialogPayload)}
        disabled={isProtected || !canDelete}
        {...iconsDefaultProps}
      />
    );
  };

  renderArchive = () => {
    const { intl, fileMangerActionPermission, openSGDialog } = this.props;
    const { canArchive } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.archive' });

    return (
      <IconButton
        icon="archive"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canArchive}
        tooltip={tooltipTranslate}
        data-e2e="header-archive"
        onClick={() => openSGDialog(REDUX_FORM.FILE_MANAGER_ARCHIVE)}
        disabled={!canArchive}
        {...iconsDefaultProps}
      />
    );
  };

  renderExtract = () => {
    const { intl, fileMangerActionPermission, extractDir } = this.props;
    const { canExtract } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.extract' });

    return (
      <IconButton
        icon="extract"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canExtract}
        tooltip={tooltipTranslate}
        data-e2e="header-extract"
        onClick={() => extractDir()}
        disabled={!canExtract}
        {...iconsDefaultProps}
      />
    );
  };

  renderPermissions = () => {
    const { intl, fileMangerActionPermission, openSGDialog } = this.props;
    const { canChangePermissions, isProtected } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.change.permissions' });

    return (
      <IconButton
        icon="key"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={isProtected || !canChangePermissions}
        tooltip={tooltipTranslate}
        data-e2e="header-change-permissions"
        onClick={() => openSGDialog(REDUX_FORM.FILE_MANAGER_PERMISSIONS)}
        disabled={isProtected || !canChangePermissions}
        {...iconsDefaultProps}
      />
    );
  };

  renderFolderInfo = () => {
    const { intl, fileMangerActionPermission, fetchDirWithFolderSizes } = this.props;
    const { canShowFolderSize } = fileMangerActionPermission;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.show.folder.details' });

    return (
      <IconButton
        icon="folder-info"
        aria-hidden
        aria-label={tooltipTranslate}
        aria-disabled={!canShowFolderSize}
        tooltip={tooltipTranslate}
        data-e2e="header-show-folder-size"
        onClick={() => fetchDirWithFolderSizes()}
        disabled={!canShowFolderSize}
        {...iconsDefaultProps}
      />
    );
  };

  renderSearch = () => {
    const { intl, isSearchVisible, closeSearchView, openSearchView } = this.props;
    const tooltipTranslate = intl.formatMessage({ id: 'translate.generic.show.search' });

    return (
      <IconButton
        icon="search"
        aria-hidden
        aria-label={tooltipTranslate}
        tooltip={tooltipTranslate}
        data-e2e="header-search"
        color={isSearchVisible ? 'secondary' : undefined}
        onClick={() => (isSearchVisible ? closeSearchView() : openSearchView())}
        {...iconsDefaultProps}
      />
    );
  };

  render() {
    const { codeEditorIsVisible } = this.props;

    return codeEditorIsVisible ? this.renderCodeEditorHeader() : this.renderMainActionButton();
  }

  renderMainActionButton() {
    return (
      <DynamicToolbar>
        {this.renderCreateFile()}
        {this.renderCreateDir()}

        <div className="file-manager-action-separator" />

        {this.renderFileUpload()}
        {this.renderFolderUpload()}

        <div className="file-manager-action-separator" />

        {this.renderEdit()}
        {this.renderRename()}
        {this.renderCopy()}
        {this.renderPaste()}
        {this.renderMove()}
        {this.renderDownload()}
        {this.renderDelete()}

        <div className="file-manager-action-separator" />

        {this.renderArchive()}
        {this.renderExtract()}

        <div className="file-manager-action-separator" />

        {this.renderPermissions()}

        <div className="file-manager-action-separator" />

        {this.renderFolderInfo()}

        <div className="file-manager-action-separator" />

        {this.renderSearch()}
      </DynamicToolbar>
    );
  }

  renderCodeEditorHeader() {
    const { intl, codeEditorFiles, codeEditor, triggerFileSave } = this.props;
    const tooltipTranslateSave = intl.formatMessage({ id: 'translate.generic.save' });
    const tooltipTranslateCloseTabs = intl.formatMessage({ id: 'translate.file.manager.close.all.tabs' });
    const tooltipTranslateSearch = intl.formatMessage({ id: 'translate.generic.search' });
    const tooltipTranslateCloseReplace = intl.formatMessage({ id: 'translate.generic.replace' });
    const tooltipTranslateDownload = intl.formatMessage({ id: 'translate.generic.download' });

    return (
      <DynamicToolbar>
        {this.renderCreateFile()}

        <IconButton
          icon="save"
          aria-label={tooltipTranslateSave}
          aria-disabled={codeEditorFiles.length === 0}
          tooltip={tooltipTranslateSave}
          data-e2e="header-save"
          onClick={() => triggerFileSave()}
          disabled={codeEditorFiles.length === 0}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="tabs-close"
          aria-label={tooltipTranslateCloseTabs}
          aria-disabled={codeEditorFiles.length === 0}
          tooltip={tooltipTranslateCloseTabs}
          data-e2e="header-close-all-tabs"
          onClick={() => codeEditor.closeTabsWithConfirmation()}
          disabled={codeEditorFiles.length === 0}
          {...iconsDefaultProps}
        />

        <div className="file-manager-action-separator" />

        <IconButton
          icon="search"
          aria-label={tooltipTranslateSearch}
          aria-disabled={codeEditorFiles.length === 0}
          tooltip={tooltipTranslateSearch}
          data-e2e="header-search"
          disabled={codeEditorFiles.length === 0}
          onClick={codeEditor.openFindPanel}
          {...iconsDefaultProps}
        />

        <IconButton
          icon="replace"
          aria-label={tooltipTranslateCloseReplace}
          aria-disabled={codeEditorFiles.length === 0}
          tooltip={tooltipTranslateCloseReplace}
          data-e2e="header-replace"
          disabled={codeEditorFiles.length === 0}
          onClick={codeEditor.openFindReplacePanel}
          {...iconsDefaultProps}
        />

        <div className="file-manager-action-separator" />

        <IconButton
          icon="download"
          aria-label={tooltipTranslateDownload}
          aria-disabled={codeEditorFiles.length === 0}
          tooltip={tooltipTranslateDownload}
          data-e2e="header-download"
          disabled={codeEditorFiles.length === 0}
          onClick={codeEditor.downloadActiveTabContent}
          {...iconsDefaultProps}
        />
      </DynamicToolbar>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  codeEditorIsVisible: state.fileManager.codeEditor.isVisible,
  codeEditorFiles: state.fileManager.codeEditor.files,
  fileMangerActionPermission: getFileMangerToolbarPermission(state),

  isSearchVisible: state.fileManager.search.isSearchVisible,

  createFileDialogPayload: getCreateFileDialogPayload(state),
  createDirDialogPayload: getCreateDirDialogPayload(state),
  moveDialogPayload: getMoveDialogPayload(state),
  deleteDialogPayload: getDeleteDialogPayload(state),
  renameDialogPayload: getRenameDialogPayload(state)
});

const mapDispatchToProps = {
  browserFileUpload,
  openSGDialog,
  fetchDirWithFolderSizes,
  copy,
  requestPaste,
  triggerFileSave,
  downloadSelectedFiles,
  extractDir,

  openSearchView,
  closeSearchView
};

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(injectIntl(FileManagerActionButtons));
