import afterNavigationHandling from '../core/sagas/after-navigation-handling';
import changeSite from '../core/sagas/change-site';
import countries from '../core/sagas/countries';
import createItem from '../core/sagas/crud/create-item';
import deleteItem from '../core/sagas/crud/delete-item';
import fetchItem from '../core/sagas/crud/fetch-item';
import fetchItems from '../core/sagas/crud/fetch-items';
import updateItem from '../core/sagas/crud/update-item';
import updateCDNItem from '../core/sagas/pages/cdn';
import fetchDir from '../core/sagas/fetch-dir-by-path';
import formSubmission from '../core/sagas/form-submission';
import genericRequests from '../core/sagas/generic-requests';
import login from '../core/sagas/login';
import logout from '../core/sagas/logout';
import { nemoApiCalls } from '../core/sagas/nemo-api';
import nemoStore from '../core/sagas/nemo-store';
import notifications from '../core/sagas/notifications';
import openNewTab from '../core/sagas/open-new-tab';
import pageLoad from '../core/sagas/page-load';
import backup from '../core/sagas/pages/backup';
import emailAccounts from '../core/sagas/pages/email-accounts';
import emailAuthentication from '../core/sagas/pages/email-authentication';
import emailFilters from '../core/sagas/pages/email-filters';
import resourceStats from '../core/sagas/pages/resource-stats';
import sgSiteScanner from '../core/sagas/pages/sg-sitescanner';
import wpAutoupdate from '../core/sagas/pages/wp-autoupdate';
import wpStaging from '../core/sagas/pages/wp-staging';
import pingBackend from '../core/sagas/ping-backend';
import requestAuthorization from '../core/sagas/request-authorization';
import requestsCompletionHandling from '../core/sagas/requests-completion';
import sgDialog from '../core/sagas/sg-dialog';
import siteMetaApi from '../core/sagas/site-meta-api';
import spanelForms from '../core/sagas/spanel-forms';
import support from '../core/sagas/support';
import tasks from '../core/sagas/tasks';
import translations from '../core/sagas/translations';
import fileManagerBrowserUpolaod from './file-manager/core/sagas/browser-file-upload';
import fileManagerCheckForExistingFiles from './file-manager/core/sagas/check-for-existing-files';
import fileManagerCodeEditor from './file-manager/core/sagas/code-editor';
import fileManagerCopyPaste from './file-manager/core/sagas/copy-paste';
import fileManagerDeleteDirs from './file-manager/core/sagas/delete';
import fileManagerDragAndDrop from './file-manager/core/sagas/drag-and-drop';
import fileManagerExtractDir from './file-manager/core/sagas/extract';
import fileManagerfetchDir from './file-manager/core/sagas/fetch';
import fileManagerMoveFiles from './file-manager/core/sagas/move-files';
import fileManagerNavigate from './file-manager/core/sagas/navigate';
import fileManagerPostRequests from './file-manager/core/sagas/post';
import fileManagerSearchView from './file-manager/core/sagas/search-view';

export default function* sagas() {
  yield [
    createItem(),
    updateItem(),
    updateCDNItem(),
    deleteItem(),
    fetchItem(),
    fetchItems(),
    changeSite(),
    login(),
    logout(),
    pageLoad(),
    requestAuthorization(),
    emailFilters(),
    tasks(),
    wpStaging(),
    emailAccounts(),
    emailAuthentication(),
    wpAutoupdate(),
    spanelForms(),
    formSubmission(),
    resourceStats(),
    countries(),
    fetchDir(),
    nemoStore(),
    sgDialog(),
    backup(),
    genericRequests(),
    support(),
    sgSiteScanner(),
    notifications(),
    requestsCompletionHandling(),
    siteMetaApi(),
    openNewTab(),
    pingBackend(),
    translations(),
    afterNavigationHandling(),

    // FM
    fileManagerNavigate(),
    fileManagerDragAndDrop(),
    fileManagerExtractDir(),
    fileManagerCodeEditor(),
    fileManagerfetchDir(),
    fileManagerDeleteDirs(),
    fileManagerPostRequests(),
    fileManagerMoveFiles(),
    fileManagerCheckForExistingFiles(),
    fileManagerCopyPaste(),
    fileManagerBrowserUpolaod(),
    fileManagerSearchView(),
    // NEMO
    nemoApiCalls()
  ];
}
