import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormDropdown from '../../../components/form-dropdown';
import { SGForm } from '../../../components/sg-form';
import { withSpanelForm } from '../../../components/sg-spanel-forms';

type Props = {
  intl: Intl;
  sPanelFormSubmit: (formName) => null;
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CHOOSE_MAIL_CLIENT_FORM;

const MAIL_CLIENT_OPTIONS = [
  {
    label: 'translate.page.email.autoconfigure.option.ios.label',
    value: 'ios'
  },
  {
    label: 'translate.page.email.autoconfigure.option.macmail.label',
    value: 'macmail'
  },
  {
    label: 'translate.page.email.autoconfigure.option.macmail_prior.label',
    value: 'macmail_prior'
  },
  {
    label: 'translate.page.email.autoconfigure.option.outlook.live.mail.label',
    value: 'outlook'
  },
  {
    label: 'translate.page.email.autoconfigure.option.outlook.windows.label',
    value: 'outlook_windows'
  },
  {
    label: 'translate.page.email.autoconfigure.option.outlook.express.windows.label',
    value: 'outlook_express'
  }
];

const Form = ({ intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)} gridProps={{ padding: 'none' }}>
      <Column smSpan="12">
        <Field
          name="emailClientId"
          type="text"
          label={intl.formatMessage({ id: 'translate.page.email.autoconfigure.select.client' })}
          placeholder={intl.formatMessage({ id: 'translate.generic.please_select' })}
          options={MAIL_CLIENT_OPTIONS}
          optionValue="value"
          optionLabel="label"
          template={({ label }) => intl.formatMessage({ id: label })}
          validate={[required]}
          expand
          disableAutoSelect
          component={FormDropdown}
        />
      </Column>
    </SGForm>
  );
};

const MailClientForm = reduxForm({ form: formName })(Form);

export default withSpanelForm(injectIntl(MailClientForm));
