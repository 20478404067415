import { call, put, select, takeEvery } from 'redux-saga/effects';
import { closeSGDialog, openSGDialog } from '../../../../core/actions/sg-dialog';
import { DIALOGS, REDUX_FORM } from '../../../../core/constants/common';
import handleAvalonApiRequest from '../../../../core/sagas/handle-avalon-api-request';
import { fileManagerPostRequest, moveFiles } from '../actions/file-manager';
import { FILE_MANAGER_FILES_MOVE, FILE_MANAGER_REQUEST_FILES_MOVE } from '../constants/actions';
import { getEntityByPath, getEntityReadableName, getEntityReadableNameByPath } from '../utils';
import { loadExistingFilesData } from './check-for-existing-files';

function* moveFilesRequest({ payload, onComplete }) {
  const entries = payload.urlParams.entries;
  const dest = payload.urlParams.dest;

  yield put(
    fileManagerPostRequest(
      {
        ...payload,
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.file.manager.move.dir.success.message',
              intlValues: { total: entries.length, destination: dest }
            },
            error: {
              intlKey: 'translate.file.manager.move.dir.error.message',
              intlValues: { total: entries.length, destination: dest }
            }
          }
        }
      },
      onComplete
    )
  );
}

function* handleFileMove(action) {
  const { fileManager } = yield select();

  const { payload, onComplete } = action;
  const payloadEntries = payload.urlParams && payload.urlParams.entries && payload.urlParams.entries;
  const dest = payload.urlParams && payload.urlParams.dest;
  const entityNames = payloadEntries ? payloadEntries.map((e) => e.split('/').pop()) : [];
  const entries = entityNames.map((e) => `${dest}/${e}`);

  const getExistingFilesResponse = yield call(loadExistingFilesData, entries);

  const existingFiles = getExistingFilesResponse.data
    ? getExistingFilesResponse.data.map((f) => getEntityReadableNameByPath(f.n))
    : [];

  if (existingFiles.length > 0) {
    const storeEntities = payloadEntries.map((entry) => getEntityByPath(entry, fileManager));
    const existingFilesFromStore = storeEntities.filter((entity) =>
      existingFiles.includes(getEntityReadableName(entity))
    );

    yield put(closeSGDialog(REDUX_FORM.FILE_MANAGER_MOVE_ENTITY));
    yield put(
      openSGDialog(DIALOGS.FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_MOVE, {
        requestPayload: payload,
        existingFiles: existingFilesFromStore
      })
    );
  } else {
    yield put(moveFiles(payload, onComplete));
  }
}

function* postRequest(): any {
  yield takeEvery(FILE_MANAGER_FILES_MOVE, handleAvalonApiRequest(moveFilesRequest));
  yield takeEvery(FILE_MANAGER_REQUEST_FILES_MOVE, handleAvalonApiRequest(handleFileMove));
}

export default postRequest;
