import * as React from 'react';
import { getDeviceInformation } from '@siteground/styleguide/lib/utils';

export const MIN_WIDTH_WHEN_CONTENT_IS_NOT_TRANSLATED = 1500;
export const SCREEN_WIDTH_FOR_MOBILE_DRAWER = 1024;
const deviceInformation = getDeviceInformation();

export type WithDrawerInjectedProps = {
  toggleDrawer: () => any;
  drawerIsOpen: boolean;
};

const withDrawer = (Component) => {
  return (props) => {
    const [drawerState, setDrawerState] = React.useState({
      drawerIsOpen: !Boolean(deviceInformation.width <= SCREEN_WIDTH_FOR_MOBILE_DRAWER)
    });

    React.useEffect(() => {
      const updateDrawerState = () => {
        setDrawerState(({ drawerIsOpen }) => {
          if (window.innerWidth > MIN_WIDTH_WHEN_CONTENT_IS_NOT_TRANSLATED && drawerIsOpen === false) {
            return { drawerIsOpen: true };
          }

          if (window.innerWidth <= SCREEN_WIDTH_FOR_MOBILE_DRAWER) {
            setDrawerState({ drawerIsOpen: false });
          }

          return { drawerIsOpen };
        });
      };

      updateDrawerState();

      window.addEventListener('resize', updateDrawerState);

      return () => {
        window.removeEventListener('resize', updateDrawerState);
      };
    }, []);

    return (
      <Component
        {...props}
        toggleDrawer={() => setDrawerState({ drawerIsOpen: !drawerState.drawerIsOpen })}
        drawerIsOpen={drawerState.drawerIsOpen}
      />
    );
  };
};

export default withDrawer;
