import { RootState } from '../../web/reducers';
import { API_RESOURCE } from '../constants/api';

export const WORDPRESS_IDENTIFICATOR = 'wordpress';

/* WP Autoupdate selectors */
export const getLatestWordpressInfo = ({ pageItems }: RootState, resourceName = API_RESOURCE.APP_LATEST.resourceName) =>
  pageItems[resourceName] ? pageItems[resourceName].find((app) => app.id === WORDPRESS_IDENTIFICATOR) : null;

/* Autoinstall selectors */
export const getAutoinstalledApps = ({ pageItems }: Partial<RootState>, resourceName = API_RESOURCE.APP.resourceName) =>
  pageItems[resourceName] ? pageItems[resourceName].filter(({ is_staging }) => !Boolean(is_staging)) : [];

/* Domain selectors */
export const getDomains = ({ pageItems }: Partial<RootState>, resourceName = API_RESOURCE.DOMAIN.resourceName) =>
  pageItems[resourceName] && pageItems[resourceName].length > 0
    ? pageItems.domain.filter(({ settings }) => !settings.staging)
    : [];

/* WP Staging selectors */
export const getWordpressApps = ({ pageItems }: Partial<RootState>, resourceName = API_RESOURCE.APP.resourceName) =>
  pageItems[resourceName] && pageItems[resourceName].length > 0
    ? pageItems.app.filter(({ app, is_staging }) => app === WORDPRESS_IDENTIFICATOR && is_staging === 0)
    : [];

export const getAllWordpressApps = ({ pageItems }: Partial<RootState>, resourceName = API_RESOURCE.APP.resourceName) =>
  pageItems[resourceName] && pageItems[resourceName].length > 0
    ? pageItems.app.filter(({ app, is_staging }) => app === WORDPRESS_IDENTIFICATOR && !is_staging)
    : [];

export const getStagingApps = (
  { pageItems }: Partial<RootState>,
  resourceName = API_RESOURCE.APP.resourceName,
  sourceAppId = null
) =>
  pageItems[resourceName] && pageItems[resourceName].length > 0
    ? pageItems.app.filter(({ app, is_staging, source_app_id }) =>
        Boolean(app === WORDPRESS_IDENTIFICATOR && is_staging === 1 && source_app_id === sourceAppId)
      )
    : [];
