import * as React from 'react';
import { Context, Dropdown, Grid, Link, Table } from '@siteground/styleguide';
import { BarChart, ChartContainer, ChartLegend, ChartTooltip } from '@siteground/styleguide/lib/components/charts';

import { Keywords, Refs, SearchEngines, StatsType } from '../../../../core/definitions/stats';
import SGTable from '../../../components/sg-table';
import { getTranslations } from '../../../components/sg-table/translation';
import withLoadMore from '../../../components/with-load-more';
import { ControlOptions, StatsViewIndex } from '../index';
import { TABLE_VIEW_PROPS } from '../utils';

const SOURCES_LOAD_MORE = {
  refs: 'refs',
  keyword: 'keywords'
};

const LOAD_MORE_SETTINGS = {
  loadStep: 10,
  initialLength: 10
};

const getSearchEngineLabel = (se: string) => {
  switch (true) {
    case se.includes('google'):
      return 'Google';
    case se.includes('yahoo'):
      return 'Yahoo';
    case se.includes('bing'):
      return 'Bing';
    case se.includes('duckduckgo'):
      return 'DuckDuckGo';
    case se.includes('yandex'):
      return 'Yandex';
    default:
      return se;
  }
};

type Props = {
  statsMissing: boolean;
  getStats: (statsKey: StatsType) => SearchEngines[] | Refs[] | Keywords[];
  renderControls: (option?: ControlOptions) => any;
  // with load more
  onLoadMore: (id: string) => any;
  getAllLoaded: (id: string, items: any[]) => any[];
  shouldShowLoadMore: (id: string, items: any[]) => boolean;
  intl: Intl;
  statisticViewIndex: StatsViewIndex;
};

type State = {
  selectedSource: 'serefs' | 'refs' | 'keywords' | string;
};

class Sources extends React.Component<Props, State> {
  readonly state = {
    selectedSource: 'serefs'
  };

  render() {
    const { intl, statsMissing } = this.props;

    const sourceOptions = [
      { label: intl.formatMessage({ id: 'translate.page.stats.search-engines.label' }), value: 'serefs' },
      { label: intl.formatMessage({ id: 'translate.page.stats.referrers.label' }), value: 'refs' },
      { label: intl.formatMessage({ id: 'translate.page.stats.keywords.label' }), value: 'keywords' }
    ];

    return (
      <Grid gap="responsive">
        {this.props.renderControls({
          showViewChangeButtons: this.state.selectedSource === 'serefs',
          renderAdditionalActions: () => (
            <Dropdown
              label={intl.formatMessage({ id: 'translate.page.stats.select-source.label' })}
              size="small"
              options={sourceOptions}
              selectedValue={this.state.selectedSource}
              optionValue="value"
              optionLabel="label"
              onChange={(selectedSource) => this.setState({ selectedSource })}
            />
          )
        })}

        {statsMissing ? this.renderEmptyStats() : this.renderStats()}
      </Grid>
    );
  }

  renderEmptyStats = () => (
    <SGTable
      data={[]}
      border="small"
      shadow={false}
      noDataMessage="translate.page.stats.no-stats.placeholder.message"
    />
  );

  renderSearchEngineRefs = () => {
    const { getStats, intl, statisticViewIndex } = this.props;
    const enginesStats = getStats('serefs');
    const legendElements = [
      {
        id: 'pages',
        label: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
        checked: true
      },
      {
        id: 'hits',
        label: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
        checked: true
      }
    ];
    const elementsIds = ['pages', 'hits'];

    if (enginesStats.length === 0) {
      return (
        <Context.Consumer>
          {({ device }) => (
            <Table border={device.isPhone ? 'none' : 'small'} data={[]} translation={getTranslations(intl)} />
          )}
        </Context.Consumer>
      );
    }

    if (statisticViewIndex === StatsViewIndex.Table) {
      return (
        <Table
          data={enginesStats}
          columns={[
            {
              header: intl.formatMessage({ id: 'translate.page.stats.search-engines.label' }),
              accessor: 'se',
              render: (se) => getSearchEngineLabel(se)
            },
            {
              header: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
              accessor: 'hits'
            },
            {
              header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
              accessor: 'pages'
            }
          ]}
          {...TABLE_VIEW_PROPS}
        />
      );
    }

    return (
      <ChartContainer
        legendCols="2"
        chartCols="10"
        title={intl.formatMessage({ id: 'translate.page.stats.search-engines.label' })}
        renderCustomLegendContent={() => <ChartLegend data={legendElements} checkboxIcon="empty" labelKey="label" />}
        renderChildren={() => {
          return (
            <BarChart
              data={enginesStats}
              yAxisWidth={90}
              axisData={{ y: 'se' }}
              elementsIds={elementsIds}
              filteredElementsIds={elementsIds}
              tickFormatterY={getSearchEngineLabel}
              renderTooltip={({ payload }: { payload: SearchEngines }) => (
                <ChartTooltip
                  elementsIds={elementsIds}
                  filteredElementsIds={elementsIds}
                  payload={payload}
                  title={getSearchEngineLabel(payload.se)}
                  elementLabels={{
                    pages: `${intl.formatMessage({ id: 'translate.page.stats.pageviews.label' })}: `,
                    hits: `${intl.formatMessage({ id: 'translate.page.stats.hits.label' })}: `
                  }}
                />
              )}
            />
          );
        }}
      />
    );
  };

  renderStats = () => {
    const { getStats, onLoadMore, getAllLoaded, shouldShowLoadMore, intl } = this.props;

    const enginesStats = getStats('serefs');
    const refsStats = getStats('refs');
    const keywordsStats = getStats('keywords');

    const legendElements = [
      {
        id: 'pages',
        label: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
        checked: true
      },
      {
        id: 'hits',
        label: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
        checked: true
      }
    ];

    switch (this.state.selectedSource) {
      case 'serefs':
        return this.renderSearchEngineRefs();
      case 'refs':
        return (
          <Context.Consumer>
            {({ device }) => (
              <Table
                cellHeight="small"
                translation={getTranslations(intl)}
                border={device.isPhone ? 'none' : 'small'}
                mobileLayout="card-flat"
                showLoadMore={shouldShowLoadMore(SOURCES_LOAD_MORE.refs, refsStats)}
                onLoadMore={() => onLoadMore(SOURCES_LOAD_MORE.refs)}
                data={getAllLoaded(SOURCES_LOAD_MORE.refs, refsStats)}
                columns={[
                  {
                    header: intl.formatMessage({ id: 'translate.page.stats.page.label' }),
                    accessor: 'url',
                    render: (url) => (
                      <Link href={url} target="_blank">
                        {url}
                      </Link>
                    )
                  },
                  {
                    header: intl.formatMessage({ id: 'translate.page.stats.hits.label' }),
                    accessor: 'hits',
                    mSize: '150px',
                    style: { textAlign: device.isPhone ? 'left' : 'center' }
                  },
                  {
                    header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
                    accessor: 'pages',
                    mSize: '150px',
                    style: { textAlign: device.isPhone ? 'left' : 'right' }
                  }
                ]}
              />
            )}
          </Context.Consumer>
        );
      case 'keywords':
        return (
          <Context.Consumer>
            {({ device }) => (
              <Table
                cellHeight="small"
                translation={getTranslations(intl)}
                border={device.isPhone ? 'none' : 'small'}
                mobileLayout="row"
                showLoadMore={shouldShowLoadMore(SOURCES_LOAD_MORE.keyword, keywordsStats)}
                onLoadMore={() => onLoadMore(SOURCES_LOAD_MORE.keyword)}
                data={getAllLoaded(SOURCES_LOAD_MORE.keyword, keywordsStats)}
                columns={[
                  {
                    header: intl.formatMessage({ id: 'translate.page.stats.keyword.label' }),
                    accessor: 'keyword'
                  },
                  {
                    header: intl.formatMessage({ id: 'translate.page.stats.pageviews.label' }),
                    accessor: 'hits',
                    style: { textAlign: device.isPhone ? 'left' : 'right' }
                  }
                ]}
              />
            )}
          </Context.Consumer>
        );
      default:
        return null;
    }
  };
}

export default withLoadMore(
  Sources,
  {
    id: SOURCES_LOAD_MORE.refs,
    ...LOAD_MORE_SETTINGS
  },
  {
    id: SOURCES_LOAD_MORE.keyword,
    ...LOAD_MORE_SETTINGS
  }
);
