import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button, Grid, RadioButton, Notice, Text } from '@siteground/styleguide';

import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import { DIALOGS } from '../../../../core/constants/common';
import { API_RESOURCE } from '../../../../core/constants/api';
import { PhpModType } from '../../../../core/selectors/php-settings';

export const ChangePhpModDialog = ({ phpMod, environment, domainName, onSubmit }) => {
  const intl = useIntl();
  const [selectedPhpMod, setSelectedPhpMod] = React.useState(phpMod as PhpModType);
  React.useEffect(() => setSelectedPhpMod(phpMod), [phpMod]);

  const modPhpCompatibleVersion = null;

  return (
    <SGDialog
      id={DIALOGS.PHP_CHANGE_MOD}
      icon="edit"
      state="warning"
      size="large"
      title={intl.formatMessage({ id: 'translate.page.php-manager.change-php-mod-dialog.title' }, { domainName })}
      resources={[{ resourceName: API_RESOURCE.USERVICE.resourceName, methods: ['GET', 'POST', 'DELETE'] }]}
      onCloseHandler={() => setSelectedPhpMod(phpMod) /* returning to default value */}
      footer={
        <React.Fragment>
          <SGDialogCancel id={DIALOGS.PHP_CHANGE_MOD} />

          <Button data-e2e="dialog-submit" color="primary" onClick={() => onSubmit({ phpMod: selectedPhpMod })}>
            {intl.formatMessage({ id: 'translate.generic.confirm' })}
          </Button>
        </React.Fragment>
      }
    >
      <Grid>
        <Grid sm={environment.isDesktop ? '2' : '3'}>
          <RadioButton
            name="mod_php"
            checked={selectedPhpMod === 'mod_php'}
            onChange={() => setSelectedPhpMod('mod_php')}
          >
            {intl.formatMessage({ id: 'translate.page.php-manager.ultrafast-php-mod.label' })}
          </RadioButton>

          <RadioButton
            name="standard"
            checked={selectedPhpMod === 'standard'}
            onChange={() => setSelectedPhpMod('standard')}
          >
            {intl.formatMessage({ id: 'translate.page.php-manager.standard-php-mod.label' })}
          </RadioButton>
        </Grid>

        <Notice type="info" background="light" shadow={false}>
          <Text>{intl.formatMessage({ id: 'translate.page.php-manager-php-mod-notice-php_mod.txt.paragraph1' })}</Text>
        </Notice>
      </Grid>
    </SGDialog>
  );
};

export default ChangePhpModDialog as typeof ChangePhpModDialog;
