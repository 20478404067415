import * as React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers';

import customRequestTypes from '../../../../core/constants/custom-request-types';
import { SGDialogForm } from '../../../components/sg-dialog';
import { REDUX_FORM } from '../../../../core/constants/common';
import { FileManagerProps, FMEntity } from '../../types';
import ArchiveForm from './archive-form';
import { getPrioritizedEntities } from '../../core/selectors/get-prioritized-entities';
import { FILE_MANAGER_API_RESPONSE_DIR } from '../../core/constants/common';
import { closeSGDialog } from '../../../../core/actions/sg-dialog';
import { fileManagerPostRequest } from '../../core/actions/file-manager';
import * as Constants from '../../core/constants/actions';
import { getEntityType, getEntityReadableName, getEntityPath } from '../../core/utils';

type Props = {
  entities: FileManagerProps['entities'];
  selectedNavigationEntity: FMEntity;
  fileManagerPostRequest: typeof fileManagerPostRequest;
  closeSGDialog: typeof closeSGDialog;
};

export const ArchiveDialog = ({ entities, selectedNavigationEntity, fileManagerPostRequest, closeSGDialog }: Props) => {
  const intl = useIntl();
  const [fileCount, folderCount] = entities.reduce(
    ([files, folders], enitity) => {
      const isFile = Boolean(getEntityType(enitity) === FILE_MANAGER_API_RESPONSE_DIR.FILE);

      return [isFile ? files + 1 : files, !isFile ? folders + 1 : folders];
    },
    [0, 0]
  );

  const entries = entities.map((entity) => getEntityReadableName(entity));
  const entityPath = getEntityPath(selectedNavigationEntity);

  return (
    <SGDialogForm
      name={REDUX_FORM.FILE_MANAGER_ARCHIVE}
      icon="archive"
      title={intl.formatMessage({ id: 'translate.file.manager.archive.dialog.title' }, { fileCount, folderCount })}
      submitLabel={intl.formatMessage({ id: 'translate.generic.confirm' })}
      resources={[{ requestTypeName: customRequestTypes.FILE_MANAGER_POST }]}
    >
      <ArchiveForm
        onSubmit={({ filename }) => {
          fileManagerPostRequest(
            {
              endpoint: Constants.FILE_MANGER_API_DIR_ARCHIVE,
              urlParams: {
                entries,
                start_dir: entityPath,
                output_file: `${entityPath}/${filename}.zip`
              },
              _meta: {
                notification: {
                  type: 'generic',
                  success: {
                    intlKey: 'translate.file.manager.archive.dir.success.message',
                    intlValues: { name: `${filename}.zip` }
                  },
                  error: {
                    intlKey: 'translate.file.manager.archive.dir.error.message',
                    intlValues: { name: `${filename}.zip` }
                  }
                }
              }
            },
            () => closeSGDialog(REDUX_FORM.FILE_MANAGER_ARCHIVE)
          );
        }}
      />
    </SGDialogForm>
  );
};

export default connect(
  (state: RootState) => ({
    selectedNavigationEntity: state.fileManager.selectedNavigationEntity,
    entities: getPrioritizedEntities(state.fileManager)
  }),
  {
    closeSGDialog,
    fileManagerPostRequest
  }
)(ArchiveDialog);
