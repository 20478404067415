import { RESELLER_LOGIN_ROUTES } from '../constants/routes';

export type MatchedRoute = {
  path: string;
};

export const isResellerLoginRoute = (matchedRoutes: MatchedRoute[]) => {
  const matchedPaths = matchedRoutes.map(({ path }) => path);
  const resellerRoutes = Object.values(RESELLER_LOGIN_ROUTES);

  const matcherResellerPath = matchedPaths.find((path) => {
    return Boolean(resellerRoutes.find((route) => route === path));
  });

  return Boolean(matcherResellerPath);
};
