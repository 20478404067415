import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Placeholder } from '@siteground/styleguide';
import InlineErrorPage from '../../inline-error-page';
import { navigateToUA } from '../../../../core/actions/nemo-store';
import { PAGE_LOAD_REQUESTS } from '../../../../core/constants/common';
import PartialLoader from '../../../components/partial-loader';

type Props = {
  navigateToUA?: Function;
  children?: any;
};

const NoSitePlaceholder = ({ navigateToUA }: Props) => {
  const intl = useIntl();
  const isReseller = CONFIG.IS_RESELLER;

  return (
    <PartialLoader hideContent resources={PAGE_LOAD_REQUESTS}>
      <InlineErrorPage
        type="no-active-sites"
        title={intl.formatMessage({ id: 'translate.no.site.placeholder.title' })}
        message={intl.formatMessage({
          id: isReseller ? 'translate.no.site.placeholder.reseller.message' : 'translate.no.site.placeholder.message'
        })}
      >
        {!isReseller && (
          <Button color="secondary" onClick={navigateToUA}>
            {intl.formatMessage({ id: 'translate.no.site.placeholder.redirect.button' })}
          </Button>
        )}
      </InlineErrorPage>
    </PartialLoader>
  );
};

const mapStateToProps = (store) => ({
  i18n: store.i18n // rerender when the i18n is ready
});

const mapDispatchToProps = (dispatch) => ({
  navigateToUA: () => dispatch(navigateToUA({ command: 'redirect' }))
});

export default connect<{}, {}, Props>(mapStateToProps, mapDispatchToProps)(NoSitePlaceholder);
