import * as React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Button, DialogSearch, GetDeviceInformationType, IconButton, Spacer, Text } from '@siteground/styleguide';
import { changeCurrentSite } from '../../../../core/actions/session';
import { RootState } from '../../../reducers';
import { getCurrentSite } from '../../../../core/selectors';

import './site-select.scss';

interface SiteSelectProps {
  environment?: GetDeviceInformationType;
  sites?: any;
  currentSite?: {
    domain: string;
    id: string;
  };
  changeCurrentSite?: (value) => void;
  className?: string;
}

const SiteSelect = ({ environment, currentSite, changeCurrentSite, sites }: SiteSelectProps) => {
  const intl = useIntl();
  const [isDialogVisible, setIsDialogVisible] = React.useState(false);

  if (!currentSite) {
    return null;
  }

  const sortedData = sites.items.sort((a, b) => {
    if (a.domain < b.domain) {
      return -1;
    }

    if (a.domain > b.domain) {
      return 1;
    }

    return 0;
  });

  return (
    <React.Fragment>
      <div className="st-site-select">
        <Text color="white" truncate>
          {currentSite.domain}
        </Text>

        <Spacer size="x-small" />

        {environment.isPhone ? (
          <IconButton
            aria-label="Change Site Select"
            className="st-site-select__change-button"
            icon="edit"
            shape="circle"
            onClick={() => setIsDialogVisible(true)}
          />
        ) : (
          <Button
            className="st-site-select__change-button"
            size="small"
            data-e2e="change-site-button"
            onClick={() => setIsDialogVisible(true)}
          >
            {intl.formatMessage({ id: 'translate.generic.change' })}
          </Button>
        )}
      </div>

      <Spacer size="x-small" />

      {isDialogVisible && (
        <DialogSearch
          open
          showDataByDefault
          data={sortedData}
          placeholder={intl.formatMessage({ id: 'translate.site.select.placeholder' })}
          indexKey="domain"
          onCloseHandler={() => setIsDialogVisible(false)}
          handleEnter={(row) => changeCurrentSite(row.id)}
          template={(data) => (
            <div
              onClick={() => {
                if (data.id === currentSite.id) {
                  setIsDialogVisible(false);
                  return;
                }

                changeCurrentSite(data.id);
              }}
            >
              <Text align="left" size="large" weight="bold">
                {data.domain}
              </Text>
            </div>
          )}
          noResultsText={intl.formatMessage({ id: 'translate.site.select.no.data' })}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  environment: state.environment,
  currentSite: getCurrentSite(state),
  sites: state.sites
});

const mapDispatchToProps = (dispatch) => ({
  changeCurrentSite: (siteId: string) => dispatch(changeCurrentSite(siteId))
});

export default connect<any, any, SiteSelectProps>(mapStateToProps, mapDispatchToProps)(SiteSelect);
