import * as React from 'react';
import { useIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { REDUX_FORM } from '../../../../core/constants/common';
import FormInput from '../../../components/form-input';
import { withSpanelForm } from '../../../components/sg-form-util/with-spanel-form';

type Props = {
  initialValues: {
    _metaFields: {
      domainName: string;
    };
  };
  validationUtils: ValidationUtils;
};

const formName = REDUX_FORM.CHANGE_NAME_DIALOG;

const ChangeName = ({ initialValues, validationUtils }: Props) => {
  const intl = useIntl();
  return (
    <Field
      name="name"
      type="text"
      label={intl.formatMessage({ id: 'translate.generic.account.name' })}
      placeholder={intl.formatMessage({ id: 'translate.generic.account.name' })}
      suffix={`@${initialValues && initialValues._metaFields.domainName}`}
      validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
      component={FormInput}
      normalize={(value) => value.toLowerCase()}
    />
  );
};

export default withSpanelForm(reduxForm({ form: formName })(ChangeName));
