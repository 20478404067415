import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import Router from '../../routes';

interface RootPros extends React.Props<any> {
  history: any;
  store: any;
}

class Root extends React.Component<RootPros, any> {
  render() {
    return (
      <Provider store={this.props.store}>
        <Router history={this.props.history} />
      </Provider>
    );
  }
}

export default hot(Root);
