import { RootState } from '../../../reducers';
import { getPrioritizedEntities } from './get-prioritized-entities';

export type RenameDialogPayload = {
  prioritizedEntities: any;
};

export const getRenameDialogPayload = (state: RootState): RenameDialogPayload => ({
  prioritizedEntities: getPrioritizedEntities(state.fileManager)
});
