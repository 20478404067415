import * as React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Notice, Text } from '@siteground/styleguide';

const RestoreFullSiteForm = () => {
  const intl = useIntl();
  return (
    <Grid>
      <Notice background="light" type="warning" shadow={false} border={false} tabIndex={0}>
        {intl.formatMessage({ id: 'translate.page.backup.restore.full.site.restore.notice' })}
      </Notice>

      <Text align="left">{intl.formatMessage({ id: 'translate.page.backup.restore.full.site.restore.message' })}</Text>
    </Grid>
  );
};

export default RestoreFullSiteForm;
