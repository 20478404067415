import * as React from 'react';
import { Field } from 'redux-form';
import { Column } from '@siteground/styleguide';
import FormDropdown from '../../components/form-dropdown';
import FormInput from '../../components/form-input';
import { DNSTypes } from './constants';
import { generateTTLOptions } from './utils';
import DnsSRVField from './dns-srv-field';

type Props = {
  dnsType: DNSTypes;
  validationUtils: ValidationUtils;
  getFormValues: Function;
  domainName: string;
  formName: string;
  listedDomains: any[];
  intl: Intl;
};

export class DNSFieldsEditRecord extends React.Component<Props> {
  renderAField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="recordName"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            normalize={(value) => value.toLowerCase()}
            disabled
            component={FormInput}
          />
        </Column>

        <React.Fragment>
          <Column smSpan="6">
            <Field
              name="ttl"
              label={intl.formatMessage({ id: 'translate.generic.ttl' })}
              options={generateTTLOptions(this.props)}
              optionValue="seconds"
              optionLabel="label"
              validate={[required, validationWithMetaApi]}
              component={FormDropdown}
            />
          </Column>

          <Column smSpan="6">
            <Field
              name="value"
              type="text"
              label={intl.formatMessage({ id: 'translate.page.dns.field.ipv4-address.label' })}
              validate={[required, validationWithMetaApi]}
              normalize={(value) => value.toLowerCase()}
              component={FormInput}
            />
          </Column>
        </React.Fragment>
      </React.Fragment>
    );
  }

  renderAAAAField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="recordName"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
            disabled
            component={FormInput}
          />
        </Column>

        <React.Fragment>
          <Column smSpan="6">
            <Field
              name="ttl"
              label={intl.formatMessage({ id: 'translate.generic.ttl' })}
              options={generateTTLOptions(this.props)}
              optionValue="seconds"
              optionLabel="label"
              validate={[required, validationWithMetaApi]}
              component={FormDropdown}
            />
          </Column>
          <Column smSpan="6">
            <Field
              name="value"
              type="text"
              label={intl.formatMessage({ id: 'translate.page.dns.field.ipv6-address.label' })}
              validate={[required, validationWithMetaApi]}
              normalize={(value) => value.toLowerCase()}
              component={FormInput}
            />
          </Column>
        </React.Fragment>
      </React.Fragment>
    );
  }

  renderCNAMEField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi, domain } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="recordName"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[required, validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
            disabled
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={generateTTLOptions(this.props)}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.dns.field.resolves-to.label' })}
            validate={[required, validationWithMetaApi, domain]}
            normalize={(value) => value.toLowerCase()}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  renderMXField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="recordName"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
            disabled
            component={FormInput}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={generateTTLOptions(this.props)}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="prio"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.prio' })}
            validate={[required, validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
            component={FormInput}
          />
        </Column>
        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.page.dns.field.destination.label' })}
            validate={[required, validationWithMetaApi]}
            normalize={(value) => value.toLowerCase()}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  renderSRVField() {
    return <DnsSRVField props={this.props} />;
  }

  renderTXTField() {
    const { intl, validationUtils } = this.props;
    const { required, validationWithMetaApi } = validationUtils;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="recordName"
            placeholder={intl.formatMessage({ id: 'translate.page.dns.field.name.label' })}
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.name' })}
            validate={[validationWithMetaApi]}
            disabled
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="ttl"
            label={intl.formatMessage({ id: 'translate.generic.ttl' })}
            options={generateTTLOptions(this.props)}
            optionValue="seconds"
            optionLabel="label"
            validate={[required, validationWithMetaApi]}
            component={FormDropdown}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="value"
            type="text"
            label={intl.formatMessage({ id: 'translate.generic.value' })}
            validate={[required, validationWithMetaApi]}
            component={FormInput}
          />
        </Column>
      </React.Fragment>
    );
  }

  render() {
    const { dnsType } = this.props;

    switch (dnsType) {
      case DNSTypes.DNS_A:
        return this.renderAField();
      case DNSTypes.DNS_AAAA:
        return this.renderAAAAField();
      case DNSTypes.DNS_CNAME:
        return this.renderCNAMEField();
      case DNSTypes.DNS_MX:
        return this.renderMXField();
      case DNSTypes.DNS_SRV:
        return this.renderSRVField();
      case DNSTypes.DNS_TXT:
        return this.renderTXTField();
      default:
        return this.renderAField();
    }
  }
}

export default DNSFieldsEditRecord;
