import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';
import { TOGGLE_TRANSLATION_KEYS } from '../constants/i18n';

export const initialState: any = {
  locale: 'en',
  showMessages: true,
  messages: {
    'translate.tool.finder.text': 'Tool Finder Ctrl + K',
    'translate.tool.finder.text.mac.os': 'Tool Finder Cmd + K',
    'translate.no.site.placeholder.title': 'You have no active sites.',
    'translate.no.site.placeholder.message': 'Please log in to your User Area to create one',
    'translate.no.site.placeholder.reseller.message': 'Please contact your hosting provider for further assistance.',
    'translate.no.site.placeholder.redirect.button': 'Log in'
  }
};

export default handleActions<any, any>(
  {
    [Actions.CHANGE_I18N]: (state, action: any) => {
      const { payload } = action;

      return {
        ...state,
        locale: payload.locale,
        messages: {
          ...initialState.messages,
          ...payload.messages
        }
      };
    },
    [TOGGLE_TRANSLATION_KEYS]: (state) => {
      return {
        ...state,
        showMessages: !state.showMessages
      };
    }
  },
  initialState
);
